import React from 'react';
import {IntervalRadioSelect} from '../date/IntervalRadioSelect';
import {styles} from './CustomLegend.styles';

/**
 * Hook that provides Recharts Legend that toggles data columns
 *
 * EXAMPLE USAGE:
 * const {
 *   legendData,
 *   legendClickHandler,
 *   hiddenColumnMap,
 *   CustomLegend
 * } = useToggleColumnLegend(data);
 *
 * <Legend
 *   onClick={legendClickHandler}
 *   hiddenColumnMap={hiddenColumnMap}
 *   content={CustomLegend}
 * />
 * @param data Source data whose columns are to be cleared on toggle
 * @param filterKey (Optional) Column key that matches the Legend value
 * @param dataKeys (Optional) Keys of the values to clear out when toggled to hidden
 * @returns Parsed Data and Legend content for chart
 */
export const useToggleColumnLegend = (
  data=[], filterKey, ...dataKeys
) => {
  const hiddenColumnMap = React.useRef({});
  const [count, setCount] = React.useState(0);

  const legendClickHandler = (columnName) => {
    if(!columnName) {
      return;
    }

    if(typeof columnName !== 'string') {
      return;
    }

    // Set map column status 
    hiddenColumnMap.current[columnName] = !hiddenColumnMap.current[columnName];
    setCount(count + 1);
  };

  const legendData = clearHiddenColumns(
    data,
    hiddenColumnMap.current,
    filterKey,
    ...dataKeys
  );
  return {
    legendData,
    legendClickHandler,
    hiddenColumnMap: hiddenColumnMap.current,
    CustomLegend
  };
};

/**
 * Hidden Columns will replace data with empty string "" so they don't display in graph
 * @param data Source data array
 * @param hiddenColumnMap Map of columns to hide
 * @param filterKey (Optional) Column key that matches the Legend value
 * @param dataKeys Keys of the values to clear out when set to hidden
 * @returns Data object array with hidden columns cleared
 */
const clearHiddenColumns = (data=[], hiddenColumnMap={}, filterKey,  ...dataKeys) => {
  const mapKeys = Object.keys(hiddenColumnMap);

  // If no filterKey, just clear out value
  if(!filterKey) {
    return data.map(d => {
      const row = {...d};
      mapKeys.forEach(key => {
        // If column is in the hiddenMap, set to empty string to hide in chart
        if(hiddenColumnMap[key]) {
          row[key] = '';
        }
      });
      return row;
    });
  }

  const dataClearObj = dataKeys.reduce((prev, current) => {
    return {
      ...prev,
      [current]: ''
    };
  }, {});

  return data.map(d => {
    const label = d[filterKey];
    if(!hiddenColumnMap[label]) {
      return d;
    }
    return {
      ...d,
      ...dataClearObj
    };
  });
};

/**
 * Custom Rechart legend to allow action on click
 * https://recharts.org/en-US/api/Legend#content
 * @param payload Created by Recharts contains Data and Legend Information
 * @param hiddenColumnMap Map that shows columns to hide
 * @param extendedPayload Payload extension for ReferenceArea Configs
 * @param onClick Custom onClick handler
 * @param isBroadcastDates Displays radio buttons for selecting broadcast range
 * @param TopLeftContent Component to display to the left of the legend
 * @returns Customized Legend to add to Recharts Component
 */
export const CustomLegend = ({
  payload=[],
  hiddenColumnMap={},
  extendedPayload=[],
  disableLegendColumns=[],
  onClick,
  isBroadcastDates=false,
  dateSpan,
  setDateSpan,
  TopLeftContent=null
}) => {
  const legendPayload = payload
        .filter(p => p.value)
        .filter(p => !disableLegendColumns.includes(p.value));

  const fullPayload = [
    ...legendPayload,
    ...extendedPayload.map(ex => ({...ex, isExtended: true}))
  ];

  let cursor = 'default';
  let getClickHandler = () => {};
  const broadcastCSS = (isBroadcastDates) ? 'broadcast' : '';
  const TopLeftContentCSS = TopLeftContent ? 'top-left-css' : '';

  if(typeof onClick === 'function') {
    cursor = 'pointer';
    getClickHandler = (labelEntry={}) => {
      return () => (onClick(labelEntry.value));
    };
  }

  const classes = [
    'custom-legend-container',
    styles.container,
    broadcastCSS,
    TopLeftContentCSS
  ].filter(f => !!f).join(' ');

  return (
    <div className={classes}>

      {TopLeftContent}

      <ul className='recharts-default-legend'>
        {
          fullPayload.map((entry={}, index) => {
            let labelCursor = cursor;
            let labelOnClick = getClickHandler(entry);

            return (
              <li
                onClick={labelOnClick}
                className='recharts-legend-item-text'
                datacolumnname={entry.value}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  userSelect: 'none',
                  cursor: labelCursor,
                  flexDirection: 'column-reverse',
                  marginRight: '8px',
                  ...getCustomStyles(entry, hiddenColumnMap)
                }}
                key={`item-${index}`}>

                <LegendIcon entry={entry}/>

                <span>
                  {entry.value}
                </span>

              </li>
            );
          })
        }
      </ul>

      <BroadcastRadioGroup
        isBroadcastDates={isBroadcastDates}
        dateSpan={dateSpan}
        setDateSpan={setDateSpan}
      />

    </div>
  );
};

/**
 * Recharts Legend Icon
 * @param entry Object from Recharts
 * @returns div legend shape
 */
export const LegendIcon = ({entry={}}) => {
  return (
    <div
      style={{
        backgroundColor: entry.color || '#FF7863',
        width: '100%',
        height: '2px',
        borderRadius: '2px'
      }}
    />
  );
};

/**
 * Broadcast Radio Buttons to display in legend
 * @param isBroadcastDates Bool whether to show this component or not
 * @param dateSpan Broadcast Span Contant EX(WEEKLY, MONTHLY)
 * @param setDateSpan Function runs on checked
 */
const BroadcastRadioGroup = ({
  isBroadcastDates,
  dateSpan,
  setDateSpan=()=>{}
}) => {
  if(!isBroadcastDates) {
    return null;
  }

  return (
    <IntervalRadioSelect
      isHorizontal={true}
      hasNoneButton={true}
      dateSpan={dateSpan}
      onChecked={setDateSpan}
    />
  );
};

/**
 * Return CSS based on current entry
 * @param columnName Column Key
 * @param hiddenMap Map of hidden columns
 * @returns CSS based on chart type and hidden columns
 */
const getCustomStyles = (entry={}, hiddenMap={}) => {
  const columnName = entry.value;
  const customCss = {};
  if(hiddenMap[columnName]) {
    customCss.opacity = '0.5';
  }
  return customCss;
};
