import React from 'react';
import {useInsightsQuery} from "./useInsightsQuery";
import {usePostTriggerDownloadUrl} from "../../../hooks/useTriggerDownloadUrl";
import {urlWithDomain, useGet} from "../../../hooks/useRest";
import {urlBuilders} from "../../../js/constants";


export const useInsightDownload = ({
                                       mcaRunId,
                                       insightType,
                                       chartType,
                                       filters,
                                       scoringId,
                                       downloadOptions
                                   }) => {
    const {
        reportMetadata,
        fileName
    } = downloadOptions

    let urlFunc;
    if (insightType) {
        urlFunc = urlBuilders.getInsightUrl;
    } else if (chartType) {
        urlFunc = urlBuilders.getChartDataUrl;
    } else {
        throw new Error('Invalid insightType or chartType');
    }

    let payloadReportMetadata;
    if (reportMetadata) {
        payloadReportMetadata = [
            {
                key: 'Client Name',
                value: reportMetadata.clientName
            },
            {
                key: 'Campaign Name',
                value: reportMetadata.campaignName
            },
            {
                key: 'Run Name',
                value: reportMetadata.runName
            }
        ]
    }

    const {
        results,
        isLoading: queryLoading,
    } = useInsightsQuery({
        mcaRunId,
        insightType,
        chartType,
        filters,
        scoringId,
        manual: false,
        extraPayload: {
            returnResults: false,
            downloadOptions: {
                reportMetadata: payloadReportMetadata,
                fileName
            }
        }
    });

    const {
        triggerDownload,
        loading: downloadLoading
    } = usePostTriggerDownloadUrl({
        restHook: useGet,
        urlObjectPath: 'url'
    });

    const triggerDownloadWrapper = () => {
        triggerDownload({
            url: urlWithDomain(urlFunc(results?.queryId))
        })
    }

    const isLoading = [queryLoading, downloadLoading].some(s => s);

    return {
        isLoading,
        triggerDownload: triggerDownloadWrapper
    }

}
