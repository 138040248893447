import React from 'react';
import {SelectLV} from '../form/select/SelectLV';
import PropTypes from 'prop-types';
import {styles} from './GraphSelect.styles';

export const GraphSelect = ({
  options=[], defaultValue, onChange, className , ...props
}) => {
  if(options.length <= 1) {
    return null;
  }
  const defaultSelectedValue = defaultValue || options[0];
  return (
    <div className={className}>
      <SelectLV
        className={styles.optionOverrides}
        options={options}
        defaultValue={defaultSelectedValue}
        onChange={onChange}
        isDark={false}
        isSearchable={false}
        width={0}
        {...props}
      />
    </div>
  );
};

GraphSelect.propTypes = {
  /**
   * GRAPH_OPTIONS options from GraphContainerLV
   */
  option: PropTypes.array,
  /**
   * Selected option of this drop-down
   * It is up to the parent to keep track of current
   * selected option
   */
  defaultValue: PropTypes.object,
  /**
   * Drop-down handler event function
   */
  onChange: PropTypes.func
};
