// actions
export const CHANGE_EDIT_STEP = `app/edit/change-step`;
export const SET_SELECTED_TYPE = `app/edit/set-selected-type`;

// Constants
export const EDIT_FORM = 1;
export const DELETE_COMPLETE = 2;

// selectors
const ID = 'edit';
const local = (state) => state['platform'][ID];
export const getEditStep = (state) => local(state).step;
export const getSelectedType = (state) => local(state).selectedType;

export const initialState = {
    step: EDIT_FORM,
    selectedType: 'None'
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_EDIT_STEP:
            return Object.assign({}, state, {step: action.step});
        case SET_SELECTED_TYPE:
            return Object.assign({}, state, {
                selectedType: action.selectedType
            });
        default:
            return state;
    }
}

// Action Creaters
export function changeEditStep(step) {
    return {
        type: CHANGE_EDIT_STEP,
        step
    };
}

export function setSelectedType(type) {
    return {
        type: SET_SELECTED_TYPE,
        selectedType: type
    };
}
