import React from 'react';
import {SelectLV} from '../../../components/form/select/SelectLV';
import {GraphContainerLV} from '../../../components/dashboards/GraphContainerLV';
import {useGet} from '../../../hooks/useRest';
import {urlBuilders} from '../../../js/constants';
import {GraphConfig} from '../../../components/dashboards/GraphConfig';
import {Chiclet} from '../../../components/dashboards/Chiclet';
import {GRAPH_OPTIONS} from '../../../components/dashboards/graph-options.constant';
import {CHART_FORMAT} from '../../../components/charts/ChartFormat.constant';
import {camelToNormal} from '../../../js/utils.js';
import {styles} from './AdImpactChart.styles';
import useDeepCompareEffect from 'use-deep-compare-effect';

export const AdImpactChart = ({
  adImpactRun={},
  isLoading,
  selectedTab=0,
  imageFileName,
  csvFileName
}) => {
  const dropdownOptions = createDropdownOptions(adImpactRun);
  const [rollUpColumn, setRollUpColumn] = React.useState();
  const axisOptions = createAxisOptions(rollUpColumn, adImpactRun);
  const [variableX, setVariableX] = React.useState(axisOptions[0]);
  const [variableY, setVariableY] = React.useState(axisOptions[1]);
  const query = {
    rollUpColumn: rollUpColumn ? rollUpColumn.value : undefined,
    variableX: variableX ? variableX.value : undefined,
    variableY: variableY ? variableY.value : undefined
  };
  const [{data: wavecastResults, loading}, getResults] = useGet({
    url: urlBuilders.getWavecastAnalysisResults(adImpactRun.wavecast_id, query),
    manual: true
  });

  React.useEffect(() => {
    // Execute GET when all values are available
    if(adImpactRun.wavecast_id && query.rollUpColumn && query.variableX
       && query.variableY) {
      getResults();
    }
  }, [
    query.rollUpColumn,
    query.variableX,
    query.variableY,
    adImpactRun.wavecast_id,
    getResults
  ]);

  // Populate first Media Variable Rollup on page load
  React.useEffect(() => {
    if(!rollUpColumn && adImpactRun.wavecast_id && Array.isArray(dropdownOptions)) {
      setRollUpColumn(dropdownOptions[0]);      
    }
  }, [adImpactRun.wavecast_id, setRollUpColumn, dropdownOptions, rollUpColumn]);

  // Reset Rollup on Tab Change
  React.useEffect(() => {
    setRollUpColumn(undefined);
  }, [selectedTab, setRollUpColumn]);

  // Clear the X and Y column options when changing report and set default values
  useDeepCompareEffect(() => {
    setVariableX(axisOptions[0]);
    setVariableY(axisOptions[1]);
  }, [rollUpColumn, setVariableX, setVariableY, axisOptions]);

  const chartLoading = [loading, isLoading].some(s=>s);

  // If there is only one rollup option and is selected, hide the dropdown
  let isHideRollup = (dropdownOptions.length === 1 && rollUpColumn);

  const dropDowns = [
    (<SelectLV
       name='rollUpColumn'
       label='Media Variable'
       description='Model Fit Range'
       options={dropdownOptions}
       defaultValue={rollUpColumn}
       onChange={setRollUpColumn}
       isDark={false}
       width={0}
       isClearable={false}
       isHide={isHideRollup}
       isLoading={chartLoading}
     />),
    (<SelectLV
       name='variableY'
       label='Measure / Metric'
       description='y-axis'
       options={axisOptions}
       defaultValue={variableY}
       onChange={setVariableY}
       isDark={false}
       width={0}
       isLoading={chartLoading}
     />),
    (<SelectLV
       name='variableX'
       label='Measure / Metric'
       description='x-axis'
       options={axisOptions}
       defaultValue={variableX}
       onChange={setVariableX}
       isDark={false}
       width={0}
       isLoading={chartLoading}
     />)
  ];

  const xLabel = variableX ? variableX.label : '';
  const yLabel = variableY ? variableY.label : '';

  // Map value format based on selection
  const formatMap = {
    x: variableX ? variableX.format : '',
    y: variableY ? variableY.format : ''
  };

  // Prevents showing chart data from previous tabs
  let chartData;
  if(adImpactRun.wavecast_id && rollUpColumn) {
    chartData = wavecastResults;
  }

  const config = new GraphConfig({
    data: chartData,
    filterKey: 'action',
    selected: GRAPH_OPTIONS.SCATTER.value,
    xAxisLabel: xLabel,
    yAxisLabel: yLabel,
    xAxisKey: 'x',
    yAxisKey: 'y',
    dropdowns: dropDowns,
    isLoading: chartLoading,
    imageElementId: 'adimpact-chart-chiclet-id',
    imageFileName,
    csvFileName,
    formatMap: formatMap,
    includeGraphCandyBarInDownload: true,
    colorMap: {
      test: '#FFDD5B',
      invest: '#8ADA7C',
      cut: '#FF7863'
    }
  });

  let chartClassName = '';
  if(!chartLoading && !adImpactRun.wavecast_id) {
    chartClassName = 'no-run';
  }

  return (
    <Chiclet
      className={styles.container}
      id='adimpact-chart-chiclet-id'>

      <GraphContainerLV
        className={chartClassName}
        graphConfig={config}/>

    </Chiclet>
  );
};

function createDropdownOptions(adImpactRun={}) {
  if(!adImpactRun['wavecast_id']) {
    return [];
  }

  let filterArray = [];

  if (adImpactRun.analysis_type === 'tv_with_custom') {
    filterArray.push(
      { label: 'Station/Daypart', value: 'flat_station_daypart'},
      { label: 'Aggregate Ad Length: Spend', value: 'aggregate_ad_length_spend'},
      { label: 'Aggregate DayPart: Spend', value: 'aggregate_daypart_spend'},
      { label: 'Placement Custom', value:'placement_custom'}
    );
  } else if (adImpactRun.analysis_type === 'tv_no_custom' || adImpactRun.analysis_type === 'terrestrial_radio') {
    filterArray.push(
      { label: 'Station/Daypart', value: 'flat_station_daypart'},
      { label: 'Aggregate Ad Length: Spend', value: 'aggregate_ad_length_spend'},
      { label: 'Aggregate DayPart: Spend', value: 'aggregate_daypart_spend'}
    );
  } else {
    filterArray.push({ label: camelToNormal(adImpactRun.analysis_type), value: adImpactRun.analysis_type});
  }

  return filterArray;
}

function createAxisOptions(rollupColumn={}, adImpactRun={}) {
  const runType = rollupColumn.value;
  const customRunTypes = ['placement_custom', 'ott', 'facebook', 'digital_video', 'digital_audio'];
  let optionsArray = [];
  if (runType === 'station') {
    optionsArray.push(
      { label: 'KPI', value: 'contributions_refit', format: CHART_FORMAT.INTEGER},
      { label: 'Impact Index', value: 'impact_index', format: CHART_FORMAT.INTEGER});
  } else if (customRunTypes.includes(runType)) {
    const runTypeFormat = adImpactRun.measure === 'spend' ? CHART_FORMAT.CURRENCY : CHART_FORMAT.INTEGER;
    optionsArray.push(
      { label: camelToNormal(adImpactRun.measure), value: adImpactRun.measure, format: runTypeFormat},
      { label: 'Impact Index', value: 'impact_index', format: CHART_FORMAT.INTEGER},
      { label: 'Confidence %', value: 'confidence', format: CHART_FORMAT.INTEGER}
    );
  } else if (runType !== '') {
    optionsArray.push(
      { label: 'Media Cleared', value: 'media_cost', format: CHART_FORMAT.CURRENCY},
      { label: 'Impact Index', value: 'impact_index', format: CHART_FORMAT.INTEGER},
      { label: 'Impressions', value: 'impressions', format: CHART_FORMAT.INTEGER},
      { label: 'Ratings', value: 'rating', format: CHART_FORMAT.INTEGER},
      { label: 'Spots', value: 'spots', format: CHART_FORMAT.INTEGER},
      { label: 'Confidence %', value: 'confidence', format: CHART_FORMAT.INTEGER}
    );
  }
  return optionsArray;
}
