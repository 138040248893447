import React from 'react';
import domToImage from 'dom-to-image-more';
import {saveAs} from 'file-saver';
import DownloadCSVFactory from '../../js/download-csv-factory';
import {DownloadImageIcon} from './icons/DownloadImageIcon';
import {DownloadFileIcon} from './icons/DownloadFileIcon';
import {ChicletTitle} from './ChicletTitle';
import {styles} from './Chiclet.styles';

/**
 * Rounded grey box containers for dashboards
 * @param id css element id to download as image - will not show image/file download icon if not set
 * @param title Add title to the chiclet
 * @param data Data to turn into CSV on download icon click
 * @param imageTooltip Tooltip title to display on image icon hover
 * @param fileTooltip Tooltip title to display on file icon hover
 * @param imageFileName Name of image file to download - will not show image icon if not set
 * @param csvFileName Name of csv file to download - will not show download icon if not set
 * @param children Child elements to display within Chiclet
 */
export const Chiclet = ({
  id,
  title,
  data, 
  imageTooltip,
  fileTooltip,
  imageFileName,
  csvFileName,
  children,
  className=''
}) => {

  let Title = null;

  if(title) {
    Title = (
      <ChicletTitle display={title}>
        {title}
      </ChicletTitle>
    );
  }

  return (
    <div id={id} data-test='chiclet' className={`${styles.container} ${className}`}>
      <div className={'chiclet-box'}>

        {Title}

        <DownloadCandyPanel
          chicletId={id}
          data={data}
          imageTooltip={imageTooltip}
          fileTooltip={fileTooltip}
          imageFileName={imageFileName}
          csvFileName={csvFileName}
        />

        {children}

      </div>
    </div>
  );
};

/**
 * Displays a candy bar with icons to download Image and CSV files for a Chiclet
 */
export const DownloadCandyPanel = ({
  chicletId,
  data,
  imageTooltip='Download Image',
  fileTooltip='Download CSV',
  imageFileName,
  csvFileName
}) => {
  if(!chicletId) {
    return null;
  }

  const hasData = (Array.isArray(data) && data.length > 0) && csvFileName;
  const fullFileName = csvFileName ? `${csvFileName}.csv` : undefined;

  // Use JSON data array for grids to create a Downloadable CSV file
  if(hasData) {
    DownloadCSVFactory.createDownload(chicletId, data, fullFileName);
  }

  if(!hasData && !imageFileName) {
    return null;
  }

  return (
    <div className='download-candy-panel'>
      <div
        className={'download-image-container icon'}
        style={{
          display: imageFileName ? 'flex' : 'none'
        }}>

        <DownloadImageIcon
          className='download-image-icon'
          title={imageTooltip}
          onClick={()=> downloadImage(chicletId, imageFileName)}
        />
      </div>

      <div
        className={'download-file-container icon'}
        style={{
          display: hasData ? 'flex' : 'none'
        }}>

        <DownloadFileIcon
          className='download-file-icon'
          data={data}
          title={fileTooltip}
          onClick={()=> downloadCsv(chicletId)}
        />
      </div>
    </div>
  );
};

const downloadImage = async(id, fileName) => {
  if(!id || !fileName) {
    return;
  }
  const element = document.getElementById(id);
  if(element.style) {
    element.style.background = 'transparent';
  }
  
  const chicletElements = document.querySelectorAll('.chiclet-box');
  chicletElements.forEach(element => element.style.background = 'transparent');
  const blob = await domToImage.toBlob(element, {
    filter: filterElements,
    bgcolor: 'transparent'
  });

  if(element.style) {
    element.style.background = '#FFF';
  }
  chicletElements.forEach(element => element.style.background = '#FFF');

  saveAs(blob, fileName);
};

/**
 * Filter out certain html node elements when taking snapshot for download
 */
const filterElements = (node={}) => {
  const filterArray = [
    node.className === 'download-candy-panel',
    node.type === 'button'
  ];

  return !filterArray.some(s => s);
};

const downloadCsv = (elementId) => {
  const report = DownloadCSVFactory.getDownload(elementId);
  report.download();
};
