import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import Dialog from '@mui/material/Dialog';
import withStyles from '@mui/styles/withStyles';
import {styles} from './dialog.styles';

import ApproveModelDialog from '../../pages/mca/mca-models-comparison/mca-models-components/approve-model';
import CancelRunDialog from '../../pages/mca/mca-run-table/cancel-run/cancel-run-dialog';

import {
    getOpen,
    getContext,
    getParams,
    closeDialog
} from '../../modules/platform/dialog';

const DialogContainer = ({open, context, params, dispatch}) => {
    const handleClose = () => {
        dispatch(closeDialog())
    };

    const getContext = context => {
      switch (context) {
        case 'Approve':
            return <ApproveModelDialog modelId={params.modelId} dispatch={dispatch}/>
        case 'CancelRun':
            return <CancelRunDialog 
                        runId={params.runId} 
                        runName={params.runName}
                        runVersion={params.runVersion}
                        getRefreshClickHandler={params.getRefreshClickHandler}
                        dispatch={dispatch} />;
        default:
            break;
      }
    }

    return (
        <Dialog onClose={handleClose} open={open} classes={{paper: styles.container}} scroll="paper">
            <div>
                {getContext(context)}
            </div>
        </Dialog>
    );
}

Dialog.propTypes = {
    open: PropTypes.bool,
    context: PropTypes.string
};

const mapStateToProps = state => {
    return {
        open: getOpen(state),
        context: getContext(state),
        params: getParams(state)
    }
};

export default withStyles(styles)(connect(mapStateToProps)(DialogContainer))
