import {css} from 'emotion';
import {commonStyles} from '../../styles/commonStyles';

export const styles = {
  box: css`
    background: #304658;
    border-radius: 10px;
    padding: 14px;
    ${commonStyles.fontFamily}
    font-size: 12px;
    letter-spacing: 0.4px;
    color: #FFF;
    display: flex;

      .tool-key {
        text-align: right;
        max-width: 120px;
        color: rgba(255, 255, 255, 0.7);;
        margin-right: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .tool-value {
        border-left: 1px solid rgba(255, 255, 255, 0.6);
        padding-left: 10px;
        max-width: 300px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
  `
};
