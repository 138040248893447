export const required = (value) => (value ? undefined : 'Required');
export const minValue = (min) => (value) =>
    value && value < min ? `Must be at least ${min}` : undefined;
export const maxValue = (max) => (value) =>
    value && value > max ? `Must be less than ${max}` : undefined;
export const beforeDate = (date) => (value) =>
    value && date && value < date ? `Must be after ${date}` : undefined;
export const minValue0 = minValue(0);
export const maxValue100 = maxValue(100);
export const maxValue100000000 = maxValue(100000000);
export const maxValue1 = maxValue(1);
export const maxValue4 = maxValue(4);

export const maxLength = (maxLength) => (value) =>
    value && value.length > maxLength
        ? `Must be less than or equal to ${maxLength} characters`
        : undefined;

export const filterRange = {
    minRsq: 0,
    maxRsq: 100,
    minDurbinWatson: 0.0,
    maxDurbinWatson: 4.0,
    intercept: true, //boolean
    minConfidence: 0,
    maxConfidence: 100,
    minContributions: -100.0,
    maxContributions: 100.0
};

export const dateFormat = 'YYYY-MM-DD';

/**
 * formats Filters
 *
 * takes the confidence filter and changes it from a percent representation to a decimal representation
 * @param  {object} values the filters
 * @return {object}        formatted filters
 */
export function formatFilters(values, mcaVariables = []) {
    let filters = Object.assign({}, values);

    Object.keys(filters).forEach(key => {
        if (!filters[key] || !filters[key].toString().length) {
            delete filters[key];
        }
    });

    if (filters.minDurbinWatson && (Number(filters.minDurbinWatson) === filterRange.minDurbinWatson)) {
            delete filters.minDurbinWatson;
    }
    if (filters.maxDurbinWatson && (Number(filters.maxDurbinWatson) === filterRange.maxDurbinWatson)) {
            delete filters.maxDurbinWatson;
    }

    if (!Object.entries(filters).length) return {};

    if (filters.rsq) {
        filters.rsq = filters.rsq.toString().replace('%', '');
        filters = Object.assign({}, filters, {
            rsq: (filters.rsq / 100).toFixed(4)
        });
    }
    if (filters.confidence) {
        filters.confidence = filters.confidence.toString().replace('%', '');
        filters = Object.assign({}, filters, {
            confidence: (filters.confidence / 100).toFixed(2)
        });
    }

    if (mcaVariables.length > 0) {
        filters = getVariables(
            filters,
            'negativeDrivers',
            values.negativeDrivers,
            mcaVariables
        );

        filters = getVariables(
            filters,
            'positiveDrivers',
            values.positiveDrivers,
            mcaVariables
        );

        filters = getVariables(
            filters,
            'mandatoryVariables',
            values.mandatoryVariables,
            mcaVariables
        );

        filters = getVariables(
            filters,
            'excludeVariables',
            values.excludeVariables,
            mcaVariables
        );

        filters = getVariables(
            filters,
            'confidenceVariables',
            values.confidenceExceptions,
            mcaVariables
        );
    }

    if (filters.variableGroups) {
        filters.variableGroups = filters.variableGroups.filter(group => 
            group.decays || group.saturations || group.minContributions || group.maxContributions
        );

        if (!filters.variableGroups.length) {   
            delete filters.variableGroups;
        } else {
            filters.variableGroups = filters.variableGroups.map(value => ({
                variableNames: value.variableNames,
                decays: !value.decays ? [] : value.decays.map(decay => decay.toLowerCase()),
                saturations: !value.saturations ? [] : value.saturations.map(saturation => saturation === 'NO' ? 'no' : saturation),
                minContributions: value.minContributions ? (value.minContributions /100).toFixed(3) : null,
                maxContributions: value.maxContributions ? (value.maxContributions /100).toFixed(3) : null
            }));
        }
    }

    return filters;
}

/**
 * Curried function that retrieve the list of variables from mca variable groups.
 *
 * @param filters The filters object containing the selected filters values.
 * @param filterName The name of the filter to be added to the filters object.
 * @param variableGroups The selected list of variable groups.
 * @param mcaVariables The list of mca variables for mca run containing list of variable groups and variable names.
 * @return object of updated filters values with new added filter
 */
export function getVariables(
    filters,
    filterName,
    variablesGroups,
    mcaVariables
) {
    if (variablesGroups && variablesGroups.length > 0) {
        let groupCount = [];
        if (filterName === 'mandatoryVariables' || filterName === 'excludeVariables') {
            groupCount = [variablesGroups.length];
        }

        // Convert object array to object for fast key lookup
        const mcaObject = mcaVariables.reduce((obj, mcaVar) => {
            obj[mcaVar.name] = mcaVar.variables;
            return obj;
        }, {});

        // Get group variableHeaders and flatten
        const groupsInMca = variablesGroups.filter((group) => mcaObject[group]);
        const groupSet = groupsInMca.map((group) =>
            mcaObject[group].map((v) => v.variableHeader)
        );
        const variables = groupSet.reduce(
            (flat, next) => flat.concat(next),
            groupCount
        );

        //remove duplicates
        const filter = variables.filter(
            (variable, index, self) => self.indexOf(variable) === index
        );

        return Object.assign({}, filters, {[filterName]: filter});
    }
    return filters;
}


/**
 * Function that reformat models data by adding different variables to the details of the model
 * @param models The filters object containing the selected filters values.
 * @return array of objects of parsed models
 */
export const parseModels = (models=[]) => {
    let parsedModels = [];
    for (const model of models) {
        const newModel = parsedModels.find(parsedModel => Number(parsedModel.modelid) === Number(model.modelid));
        let index = parsedModels.indexOf(newModel);
        if (index < 0) {
            parsedModels.push({modelid: model.modelid, details: []});
            index = parsedModels.length -1;
        }
        
        let details = parsedModels[index].details.slice();
        details.push({
            variable: model.variablename || model.independentvariable,
            decay: model.variabledecay,
            saturation: model.variablesaturation,
            confidence: Math.round((1 - Number(model.pval)) * 100),
            vif: Number(model.vif).toFixed(2),
            contribution: Math.round(Number(model.contributions)),
            prctContribution: (Number(model.prctcontributions) * 100).toFixed(1)
        });

        parsedModels[index] = {...parsedModels[index],
            durbinwatson: Number(model.durbinwatson).toFixed(2),
            intercept: Number(model.intercept).toFixed(5),
            rsq: (model.rsq * 100).toFixed(2),
            mape: Math.round(model.mape * 100),
            status: 'In Review',
            details
        };
    };
    return parsedModels.sort((a, b) => Number(a.modelid) > Number(b.modelid) ? 1: -1);
}

/**
 * Curried function that determines list uniqueness.
 *
 * @param field The field on allValues that has the list to check against.
 * @param value The value to check if it already exists in the list.
 * @param allValues Specifies all the values in a form. Must be an object containing the field given.
 */
export const unique = (field) => (value, allValues) => {
    if (
        value != null &&
        allValues[field] != null &&
        allValues[field].findIndex(
            (item) => value.toLowerCase() === item.toLowerCase()
        ) > -1
    ) {
        return 'Must be unique';
    }
};

/**
 * Function that formats roi data by tactic name for models
 * @param roiData The row roi data from outputs and insights
 * @return array of formatted roi data for models
 */
export const formatRoiResults = (roiData) => {
    if (!roiData || !roiData.length) {
        return {};
    }
    const roi = roiData.reduce((prev, current) => {
        return {
            ...prev,
            [current.modelId]: current.data.reduce((prevData, currentData) => {
                return {
                    ...prevData,
                    [currentData.tactic]: currentData
                }
            }, {})
        };
    }, {});
    return roi;
};

/**
 * Function that determines if string is valid UUID
 *
 * @param uuid The string to be checked.
 * @return boolean value determining the uuid check result
 */
export function isUUID(uuid) {
    let uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return uuid.match(uuidRegex) === null ? false : true;
}

/**
 * Functionthat determines if string is valid email address
 *
 * @param email The string to be checked.
 * @return boolean value determining the uuid check result
 */
export function isEmail(email) {
    // eslint-disable-next-line
    let emailRegex = /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
  return email.toLowerCase().match(emailRegex) !== null;
}
