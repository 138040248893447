// actions
export const OPEN_SNACKBAR = `platform/snackbar/open`;
export const CLOSE_SNACKBAR = `platform/snackbar/close`;

// selectors
const ID = `snackbar`;
const local = (state) => state['platform'][ID];
export const getOpen = (state) => local(state).open;
export const getMessage = (state) => local(state).message;
export const getVariant = (state) => local(state).variant;

export const initialState = {
    open: false,
    message: '',
    variant: 'info'
};

export default function snackbarReducer(state = initialState, action) {
    switch (action.type) {
        case OPEN_SNACKBAR:
            return Object.assign({}, state, {
                open: true,
                variant: action.variant,
                message: action.message
            });
        case CLOSE_SNACKBAR:
            return Object.assign({}, state, {open: false});
        default:
            return state;
    }
}

// action creators
export function snackbar(variant, message) {
    return {
        type: OPEN_SNACKBAR,
        variant,
        message
    };
}

export function closeSnackbar() {
    return {
        type: CLOSE_SNACKBAR
    };
}
