/**
 * Function that downloads a file from a given URL
 *
 * @param url The url to fetch the download.
 */
 export function downloadFromURL(url) {
    var downloadFrame = document.createElement("iframe"); 
    downloadFrame.setAttribute('src', url);
    downloadFrame.setAttribute('style', 'display:none');
    document.body.appendChild(downloadFrame); 
    downloadFrame.click();
}
