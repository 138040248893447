import {css} from 'emotion';

export const styles = {
    modelChipContainer: css`
        margin-left: 0.5em;
    `,
    modelsChip: css`
        margin-top: 0.5em;
        margin-bottom: 0.5em;
        margin-left: 0.5em;
        background-color: rgba(255, 255, 255, 0.5) !important;
    `,
    fieldBox: css`
        display: flex;
    `,
    selectFieldContainer: css`
        width: 165px;
        height: 39px;
        margin-right: 12px;
        border-radius: 10px;
        background: rgba(255, 255, 255, 0.2) !important;
        padding-left: 10px;
        padding-right: 10px;
    `,
    selectField: css`
        width: 165px !important;
        font-family: 'Roboto', sans-serif !important;
        padding-top: 2px;
        font-size: 12px !important;
    `,
    addButton: css`
        background: #a9d980;
        min-width: 68px;
        color: #FFF;
        outline: none;
        z-index: 10;
    `,
    italicMenuItem: css`
        font-style: italic;
        padding-bottom: 3px;
        border-bottom: 1px solid #BDBDBD;
    `
};

export const customStyle =  {
    control: (provided) => 
    ({
        ...provided,
        '&:hover': {borderColor: 'none'},
        boxShadow: 'none',
        border: 0,
        background: 'transparent',
        borderBottom: '0.5px solid #FFF',
        borderRadius: 0,
        minHeight: '24px',
    }),
    indicatorSeparator: () => ({
        display: 'none'
    }),
    clearIndicator: () => ({
        display: 'none'
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#FFF',
        padding: 0
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#FFF',
    }),
    menu: (provided) => ({
        ...provided,
        margin: 0,
        padding: '4px 0',
        background: '#FFF',
        borderRadius: '10px',
        color: '#374F63',
        zIndex: '10 !important',
    }),
    option: (provided) => ({
        ...provided,
        padding: '0 0 12px 16px',
    })

};