import React from 'react';
import { Config } from '../../config/ConfigFactory';
import { MMMRunTable } from './mca-run-table/MMMRunTable';
import { useUserRoleFromGroup } from '../../hooks/useUserRoleFromGroup';
import { useGetUploadedFiles } from '../../hooks/useGetUploadedFiles';
import { useMcaRuns } from '../../hooks/useMcaRuns';
import { FeatureLV } from '../../components/pages/FeatureLV';
import { Typography } from '@mui/material';
import { R1Button } from '@rainagency/rain-one-soggy-loaf';
import { useFeatureSelect } from '../../hooks/useFeatureSelect';
import { FEATURES } from '../../constants/features';
import { ArrowForward } from '@mui/icons-material';

export const McaContainer = () => {
  const maxTrialRuns = Config.getConfig().MAX_TRIAL_RUNS;

  const { isTrial, isAdmin, isAdvanced } = useUserRoleFromGroup();
  const {getMcaRuns, loading: mcaLoading, mcaRuns=[], magnifaiRuns=[]} = useMcaRuns();
  const { allUploadedFiles = [], loading: filesLoading } = useGetUploadedFiles();

  const trialRunCount = React.useMemo(() => 
    mcaRuns.filter(run => run.runStatus !== 'ERROR' && run.runStatus !== 'CANCELLED').length
    , [mcaRuns]);

  const isTrialMaxed = isTrial && trialRunCount >= maxTrialRuns;
  const canStartAnalysis = [isAdmin, isAdvanced, isTrial].some(s => s);
  const isLoading = [mcaLoading, filesLoading].some(s => s);

  // Display overlay if user has uploaded no files
  const noFilesUploaded = (!isLoading && !allUploadedFiles.length);
  let Overlay;
  if (noFilesUploaded) {
    Overlay = <NoDataOverlay />;
  }

  return (
    <FeatureLV
      title='MMM Analysis Runs'
      description='Select or create an analysis'
      loading={isLoading}
      Overlay={Overlay}
      CallToAction={
        <MMMCallToAction
          hide={(isTrialMaxed || !canStartAnalysis)}
        />
      }>
      <MMMRunTable
        mmmRuns={mcaRuns}
        loading={isLoading}
        getRuns={getMcaRuns}
        magnifaiRuns={magnifaiRuns}
      />
    </FeatureLV>
  );

};

const SelectAnalysis = ({}) => {
  const { onFeatureSelect } = useFeatureSelect();

  const clickHandler = () => {
    onFeatureSelect({
      feature: FEATURES.SELECT_ANALYSIS
    });
  };

  return (
    <R1Button
      onClick={clickHandler}
      color='secondary'>
      New Analysis
    </R1Button>
  );
}

const MMMCallToAction = ({hide}) => 
  hide ? null :  <SelectAnalysis/>;

const NoDataOverlay = ({}) => {
  const { onFeatureSelect } = useFeatureSelect();

  const clickHandler = () => {
    onFeatureSelect({
      feature: FEATURES.MANAGE_DATA
    });
  };

  return (
    <>

      <Typography variant={'h3'}>
        No Data Files Uploaded
      </Typography>

      <Typography variant={'h6'}>
        Upload data files to create a new analysis
      </Typography>

      <R1Button
        endIcon={<ArrowForward/>}
        onClick={clickHandler}
        color='secondary'>
        Go To Manage Data
      </R1Button>

    </>
  );
};
