import React from 'react';
import {IconLV} from './IconLV';
import deleteForeverIcon from '../../../assets/delete_forever_24px.svg';
import PropTypes from 'prop-types';

export default function DeleteCell({cell, onClick, dataTest}) {
  const icon = deleteForeverIcon;

  return(
    <IconLV
      cell={cell}
      onClick={onClick}
      dataTest={dataTest}
      src={icon}/>
  );
}
export {DeleteCell}

DeleteCell.propTypes = {
  /**
   * React-Table cell object
   */
  cell: PropTypes.object,
  /**
   * Handle onClick and add cell value to click args
   */
  onClick: PropTypes.func,
};
