import {sortByColumnBetter} from '../../js/utils.js';
import queryString from 'query-string';
import {CLIENT_ID_URL_PARAM} from '../../hooks/useClient';

// actions
export const LOAD_ALL_CLIENTS = `platform/client/load-all-clients`;
export const SORT_CLIENTS = `platform/client/sort-clients`;
export const FETCH_ALL_CLIENTS = `platform/client/fetch-clients`;
export const SET_CREATE_CLIENT_ROUTE = `platform/client/set-create-client-route`;
export const CREATE_CLIENT = `platform/client/create`;
export const RESET_CREATE_CLIENT = `platform/client/reset-create`;
export const LOAD_CLIENT_ERRORS = `platform/client/load-errors`;
export const SET_SELECTED_CLIENT = `platform/client/set-selected-client`;
export const UPDATE_CLIENT = `platform/client/update-client`;
export const DELETE_CLIENT = `platform/client/delete-client`;

export const initialState = {
    byId: {},
    allIds: [],
    columnToSortBy: '',
    ascendingSort: '',
    errors: [],
    selectedClient: ''
};

// selectors
const ID = `client`;
const local = (state) => state['platform'][ID];

// Hack to get ClientId from url instead of store in React.Component uses
// TODO: Remove
//export const getSelectedClient = (state) => local(state).selectedClient;
export const getSelectedClient = () => {
  const query = queryString.parse(window.location.search);
  return query[CLIENT_ID_URL_PARAM];
};

export const getClients = (state) => local(state).allIds;
export const getClientById = (state, id) => local(state).byId[id];
export const getClientAscendingSort = (state) => local(state).ascendingSort;
export const getClientsByPartnerId = (state, id) =>
    getClientsByPartnerIdCore(local(state), id);
export const getClientErrors = (state) => local(state).errors;
export const getSelectedClientName = (state) =>
    getSelectedClientNameCore(local(state));

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_ALL_CLIENTS:
            let newById = {};
            let newAllIds = action.clients.map((client) => {
                newById[client.id] = client;
                return client.id;
            });

            return Object.assign({}, state, {
                byId: newById,
                allIds: newAllIds,
                selectedClient: state.selectedClient || newAllIds[0]
            });
        case SORT_CLIENTS:
            let ascendingSort = state.ascendingSort;
            if (state.columnToSortBy === action.columnToSortBy) {
                if (ascendingSort === false) {
                    ascendingSort = true;
                } else {
                    ascendingSort = false;
                }
            } else {
                ascendingSort = true;
            }
            const sortedIds = sortByColumnBetter(
                state.allIds,
                state.byId,
                action.columnToSortBy,
                ascendingSort
            );
            return Object.assign({}, state, {
                ascendingSort,
                columnToSortBy: action.columnToSortBy,
                allIds: sortedIds
            });
        case LOAD_CLIENT_ERRORS:
            return Object.assign({}, state, {errors: action.errors});
        case RESET_CREATE_CLIENT:
            return Object.assign({}, state, {errors: []});
        case SET_SELECTED_CLIENT:
            sessionStorage.setItem(
                `platform/client/selectedClient`,
                action.selectedClient
            );
            return Object.assign({}, state, {
                selectedClient: action.selectedClient
            });
        default:
            return state;
    }
}

// action creators
export function loadAllClients(clients) {
    return {
        type: LOAD_ALL_CLIENTS,
        clients
    };
}

export function sortClients(columnToSortBy) {
    return {
        type: SORT_CLIENTS,
        columnToSortBy
    };
}

export function fetchAllClients() {
    return {
        type: FETCH_ALL_CLIENTS
    };
}

export function resetCreateClient() {
    return {
        type: RESET_CREATE_CLIENT
    };
}

export function loadClientErrors(errors) {
    return {
        type: LOAD_CLIENT_ERRORS,
        errors
    };
}

export function setSelectedClient(selectedClient) {
    return {
        type: SET_SELECTED_CLIENT,
        selectedClient
    };
}

// helper functions
function getClientsByPartnerIdCore(state, id) {
    return state.allIds.filter((client) => {
        return state.byId[client].partnerId === id;
    });
}

/**
 * returns the name of the selected client
 * if there is no selected client, or no list of clients return an empty string
 * @param  {object} state the client state
 * @return {string}       the current selected client name
 */
function getSelectedClientNameCore(state) {
    if (!state.selectedClient || !state.byId[state.selectedClient]) {
        return '';
    } else {
        return state.byId[state.selectedClient].companyName;
    }
}
