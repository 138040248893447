import {css} from 'emotion';

export const checkboxStyles = {
    enabledFieldDark: css`
        font-family: 'Roboto', sans-serif;
        font-weight: normal !important;
        font-size: 12px !important;
        color: #FFFFFF !important;
    `,
    enabledField: css`
        font-family: 'Roboto', sans-serif;
        font-weight: normal !important;
        font-size: 12px !important;
    `,
    disabledField: css`
        font-family: 'Roboto', sans-serif;
        font-size: 12px !important;
        color: rgba(255, 255, 255, 0.5) !important;
    `,
    checked: css`
        color: #a7da7b !important;
    `,
    unchecked: css`
        color: rgba(255, 255, 255, 0.5) !important;
    `
};