import React from 'react';
import {Typography} from '@mui/material';
import moment from 'moment';
import {R1AutoComplete} from '@rainagency/rain-one-soggy-loaf';
import {DateRangePickerLV} from '../../../components/form/date/DateRangePickerLV';
import {SelectLV} from '../../../components/form/select/SelectLV';
import {urlBuilders} from '../../../js/constants';
import {getMinMaxDates} from '../../../js/utils';
import {useGet} from '../../../hooks/useRest';
import {styles} from './ScoringScenarioPicker.styles';
import {ScoringDashboardContext} from "../ScoringDashboardContext";

export const ScoringScenarioPicker = ({
                                          scoresByMcaRunId,
                                      }) => {

    const {
        selectedMcaRun,
        scoring1,
        scoring2,
        setScoring1,
        setScoring2,
        region,
        setRegion,
        setStartDate,
        setEndDate,
    } = React.useContext(ScoringDashboardContext);

    const mcaRunId = selectedMcaRun.id;
    const scoringOptions = scoresByMcaRunId
        .filter((score) => score.scoringStatus === 'COMPLETE')
        .map(({id, display}) => ({label: display, value: id}));

    const [{
        data: daily1,
        loading: dailyLoading1
    }, fetch1] = useGet({
        url: urlBuilders.getInsightMetadata(mcaRunId, scoring1?.value),
        manual: true
    });

    const [{
        data: daily2,
        loading: dailyLoading2
    }, fetch2] = useGet({
        url: urlBuilders.getInsightMetadata(mcaRunId, scoring2?.value),
        manual: true
    });

    const {
        minDate,
        maxDate,
        regions
    } = React.useMemo(() => {
        if (daily1 && daily2) {
            const {
                minDate: minDate1,
                maxDate: maxDate1,
                regions: regions1
            } = daily1;

            const {
                minDate: minDate2,
                maxDate: maxDate2,
                regions: regions2
            } = daily2;

            const dateList = [minDate1, minDate2, maxDate1, maxDate2].map((date) => ({date}));
            const regions = [...new Set([...regions1, ...regions2])]

            const {start: minDate, end: maxDate} = getMinMaxDates(dateList);

            return {
                minDate,
                maxDate,
                regions
            };
        }
        return {};
    }, [daily1, daily2]);

    const regionOptions = React.useMemo(() => {
        if (regions) {
            return [
                {
                    label: 'All',
                    value: 'All'
                },
                ...regions.map(region => ({label: region, value: region}))
            ];
        }
        return [];
    }, [regions]);

    const regionVal = React.useMemo(() => {
        if (region) {
            return {
                label: region,
                value: region
            };
        }
        return {};
    }, [region]);

    const onScenarioChange = (option, name) => {
        if (name === 'scoring_1') {
            if (option?.value === scoring2?.value) {
                setScoring2();
            }
            setScoring1(option);
        } else {
            if (option?.value === scoring1?.value) {
                setScoring1();
            }
            setScoring2(option);
        }
    }

    React.useEffect(() => {
        if (mcaRunId && scoring1?.value) {
            fetch1();
        }
    }, [scoring1]);

    React.useEffect(() => {
        if (mcaRunId && scoring2?.value) {
            fetch2();
        }
    }, [scoring2]);

    const optionDisableHandler = (option, name) => {
        return name === 'scoring_1' ? option.value === scoring2?.value : option?.value === scoring1?.value;
    };

    const FormContainer = () => (
        <div className={styles.content}>
            <Typography
                variant='h4'
                color='primary'
                className={styles.title}
                component='div'>
                Scenario Comparison
            </Typography>

            <R1AutoComplete
                label='Scenario 1'
                data-test='scenario1-autoComplete'
                className={styles.scoringPicker}
                disabled={scoresByMcaRunId?.length < 1}
                minWidth={360}
                renderOption={(props, option) => {
                    return (
                        <li {...props} key={option.value}>
                            {option.label}
                        </li>
                    );
                }}
                options={scoringOptions}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                defaultValue={scoring1}
                onChange={option => onScenarioChange(option, 'scoring_1')}
                getOptionDisabled={option => optionDisableHandler(option, 'scoring_1')}
            />
            <R1AutoComplete
                label='Scenario 2'
                className={styles.scoringPicker}
                disabled={!scoring1 || (scoresByMcaRunId?.length < 1)}
                minWidth={360}
                renderOption={(props, option) => {
                    return (
                        <li {...props} key={option.value}>
                            {option.label}
                        </li>
                    );
                }}
                options={scoringOptions}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                defaultValue={scoring2}
                onChange={option => onScenarioChange(option, 'scoring_2')}
                getOptionDisabled={option => optionDisableHandler(option, 'scoring_2')}
            />
        </div>
    );

    const RegionContainer = () => {
        return (
            <div className={styles.regionContainer}>
                <SelectLV
                    label='Region'
                    isDark={false}
                    width={0}
                    isClearable={false}
                    options={regionOptions}
                    value={regionVal}
                    onChange={setRegion}
                />
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <FormContainer/>
            <div className={styles.dateContainer}>
                <DateRangePickerLV
                    isDark={false}
                    startLabel={'Date Selector'}
                    startDescription='From'
                    endLabel='&nbsp;'
                    endDescription='To'
                    spaceBetween={8}
                    minDate={moment(minDate)}
                    maxDate={moment(maxDate)}
                    defaultStartDate={minDate?.toString()}
                    defaultEndDate={maxDate?.toString()}
                    customSetStartDate={setStartDate}
                    customSetEndDate={setEndDate}
                    loading={dailyLoading1 || dailyLoading2}
                />
                <RegionContainer/>
            </div>
        </div>
    );
}