import {css} from 'emotion';

export const styles = {
  svg: css`
    cursor: pointer;
    width: 24px;
    height: 24px;
    text-align: center;
    :hover {
      path {
        fill: #FF6B57;
      }
      rect {
        fill: #FF6B57;
      }
    }
  `
};
