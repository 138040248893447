import { useRef, useState, useEffect } from 'react';
import {useQueryRequest} from '../../hooks/useQueryRequest';

import {urlBuilders} from '../../js/constants';
import {downloadFromURL} from '../../js/download';

//Getting the count of models and models (if less than model limit) for the set filters
export default function useMcaModels(mcaRun={}, filters, fetchMcaModels, modelIds=[], 
    downloadSelectedModels, downloadAllModels) {
    const runFetchDownloadModels = useRef(modelIds.length > 0 && downloadSelectedModels);
    const runFetchDownloadAllModels = useRef(downloadAllModels);

    const mcaRunId = mcaRun.id;
    const partition = mcaRun.partition;
    const encoding = mcaRun.encoding;
    const payload = Object.assign({}, filters, {mcaRunId, partition, encoding});

    useEffect(() => {
        runFetchDownloadModels.current = downloadSelectedModels;
        runFetchDownloadAllModels.current = downloadAllModels;
    }, [fetchMcaModels, modelIds, downloadSelectedModels, downloadAllModels]);

    /** (1) GET MODELS */
    const modelsQueryUrl = urlBuilders.getMcaModels(mcaRunId);
    const modelsResultUrl = urlBuilders.getResultRows('query_uuid');
    const [{result: modelsResult={data:[]}, query, loading: modelsLoading, error: modelsError}, fetchModels] =
            useQueryRequest(modelsQueryUrl, modelsResultUrl, payload, 'Models');
    
    useEffect(() => {
        if (fetchMcaModels) {
            fetchModels();
        }
    }, [fetchMcaModels, fetchModels]);

    /** (2) Download single, or multiple selected models */
    const downloadModelsQueryUrl = urlBuilders.getMcaModelsByModelId(mcaRunId, {});

    const downloadModelName = (modelIds.length === 1) ? 
        (mcaRun.name + "_Model" + modelIds[0] + "_results.csv") : 
        (mcaRun.name + "_results.csv");

    const downloadModelsResultUrl = urlBuilders.exportMca('query_uuid', 'csv', downloadModelName);
    const downloadModelsPayload = {
        mcaRunId,
        modelIds,
        partition, 
        encoding
    };
    const [{result: downloadResult = {url: null}, loading: downloadModelsLoading, error: downloadModelsError}, fetchDownloadModels] = 
        useQueryRequest(downloadModelsQueryUrl, downloadModelsResultUrl, downloadModelsPayload, 'Downloading Selected File');

    useEffect(() => {
        if (runFetchDownloadModels.current) {
            runFetchDownloadModels.current = false;
            fetchDownloadModels();
        }
    }, [downloadSelectedModels, fetchDownloadModels]);

    useEffect(() => {
        if (downloadResult.url) {
            downloadFromURL(downloadResult.url);
        }
    }, [downloadResult.url])

    /** (3) Download all models with a specified file name */
    const downloadAllModelsQueryUrl = urlBuilders.getMcaModels(mcaRunId);
    const downloadAllModelsResultUrl = urlBuilders.exportMca('query_uuid', 'csv', mcaRun.name + "_results.csv");
    const downloadAllModelsPayload = {
        mcaRunId,
        partition,
        encoding,
        ...filters
    };
    const [{result: downloadAllResult = {url: null}, loading: downloadAllModelsLoading, error: downloadAllError}, fetchDownloadAllModels] = 
        useQueryRequest(downloadAllModelsQueryUrl, downloadAllModelsResultUrl, downloadAllModelsPayload, 'Download All Models');

    useEffect(() => {
        if (runFetchDownloadAllModels.current) {
            runFetchDownloadAllModels.current = false;
            fetchDownloadAllModels();
        }
    }, [downloadAllModels,fetchDownloadAllModels]);

    useEffect(() => {
        if (downloadAllResult.url) {
            downloadFromURL(downloadAllResult.url);
        }
    }, [downloadAllResult.url]);

    /** (4) Set Selected model id for navigating across models */
    const [selectedModelId, setSelectedModelId] = useState();

    /** Return values for models and loading status and selected modelid */
    return {
            models: modelsResult.data || [], modelsLoading,
            queryId: query ? query.queryId : undefined,
            downloadModelsLoading,
            downloadAllModelsLoading,
            setSelectedModelId,
            selectedModelId,
            modelsError,
            downloadModelsError,
            downloadAllError
        };
                
}