import {call, put, takeEvery} from 'redux-saga/effects';

import {urlBuilders} from '../../../js/constants';

import {UPLOAD_FILE} from '../campaign.js';
import {changeUploadStep, updateUploadedFiles} from '../uploadedFiles.js';

import {post} from '../../../js/server.js';
import {uploadFileToS3} from '../../../js/storage.js';

export function* uploadFile(action) {
    const fileUploadResponse = yield call(uploadFileToS3, action.payload.file);
    if (fileUploadResponse.error) {
        // TODO error handling
    } else {
        const payload = {
            key: fileUploadResponse,
            dataType: action.params.type,
            campaignId: action.params.campaignId
        };

        const completeUploadResponse = yield call(
            post,
            action.token,
            action.baseUrl + urlBuilders.completeUpload(),
            payload
        );
        if (completeUploadResponse.error) {
            // TODO error handling
        } else {
            yield put(changeUploadStep(2));
            yield put(updateUploadedFiles(true));
        }
    }
}

export function* watchUploadFile() {
    yield takeEvery(UPLOAD_FILE, uploadFile);
}
