import {css} from 'emotion';

export const styles = {
    dialogStyle: css`
        width: 500px;
    `,
    title: css`
        text-align: center !important;
        margin-top: 25px !important;
        font-size: 35px;
    `,
    waitCursor: css`
        cursor: wait;
    `,
    buttonContainer: css`
        display: flex;
        justify-content: center;
        margin-top: 24px;
        margin-bottom: 55px;
    `,
    button: css`
        width: 160px;
        height: 48px;
        border: 1px solid rgba(126, 146, 146, 0.25);
        box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.15);
        font-size: 14px;
        text-align: center;
        line-height: 20px;
    `,
    submitButton: css`
        background: #ff7762 !important;
        color: #ffffff !important;
    `
};
