import {sortByColumnBetter} from '../../js/utils.js';
import moment from 'moment';

// Run Statuses
import {RUN_STATUS} from '../../constants/mca-run-statuses.js';

// import core helper functions
import {
    setSelectedMcaRunToMostRecentCore,
    getMcaRunStatusCore
} from './core/run-core.js';

// actions
export const MCA_RUN_REFRESH = `mca/run/refresh`;
export const SORT_MCA_RUN = `mca/run/sort`;
export const SET_SELECTED_MCA_RUN = `mca/run/set-selected`;
export const SET_NEW_MCA_RUN_NAME = `mca/run/set-new-name`;
export const RUN_MCA = `mca/run/run-mca`;
export const EXPORT_MCA_RUN = `mca/run/export`;
export const SET_SELECTED_RUN_TO_MOST_RECENT = `mca/run/set-selected-run-to-most-recent`;
export const FETCH_ERRORS_CSV = `mca/run/fetch-errors-csv`;
export const SET_ERRORS = `mca/run/set-errors`;
export const SET_TRANSFORM_URLS = `mca/run/set-transform-urls`;
export const SET_LOADING = `mca/run/set_loading`;

export const FETCH_MCA_RUNS_BY_CAMPAIGN = `mca/runs/fetch-by-campaign`;
export const FETCH_MCA_RUNS_BY_CAMPAIGN_REQUEST = `mca/runs/fetch-by-campaign-request`;
export const FETCH_MCA_RUNS_BY_CAMPAIGN_SUCCESS = `mca/runs/fetch-by-campaign-success`;

// Selectors
const ID = 'run';
const local = (state) => state['mca'][ID];
export const getSelectedMcaRun = (state) => local(state).selectedMcaRun;
export const getMcaRuns = (state) => local(state).allIds;
export const getSortColumn = (state) => local(state).tableSortColumn;
export const getSortAscending = (state) => local(state).tableSortAscending;
export const getNewMcaRunName = (state) => local(state).newMcaRunName;
export const getLastFetched = (state) => local(state).lastFetched;
export const getMcaRunById = (state, id) => local(state).byId[id];
export const getPartitionById = (state, id) =>
    checkObjectHasKeys(local(state).byId)
        ? local(state).byId[id].partition
        : '';
export const getPartitionForSelectedMcaRun = (state) =>
    checkObjectHasKeys(local(state).byId)
        ? local(state).byId[getSelectedMcaRun(state)].partition
        : '';
export const getSelectedMcaRunName = (state) =>
    getSelectedMcaRunNameCore(local(state));
export const getMcaRunName = (state, id) => local(state).byId[id].name;
export const getMcaRunStatus = (state, id) =>
    getMcaRunStatusCore(local(state), id);
export const getErrors = (state) => local(state).errors;
export const getLoading = (state) => local(state).loading;

export const initialState = {
    byId: {},
    allIds: [],
    tableSortColumn: '',
    tableSortAscending: true,
    selectedMcaRun: '',
    newMcaRunName: '',
    lastFetched: {},
    errors: [],
    loading: false
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_MCA_RUNS_BY_CAMPAIGN_SUCCESS:
            let newById = {};
            let newAllIds = action.mcaRuns.map((mcaRun) => {
                mcaRun.runStatus = translateStatus(mcaRun.runStatus);
                mcaRun.hasTransformUrl = false;
                newById[mcaRun.id] = mcaRun;
                return mcaRun.id;
            });
            if (state.tableSortColumn !== '') {
                newAllIds = sortByColumnBetter(
                    newAllIds,
                    newById,
                    state.tableSortColumn,
                    state.tableSortAscending
                );
            }
            return Object.assign({}, state, {
                byId: newById,
                allIds: newAllIds,
                lastFetched: moment()
            });
        case SORT_MCA_RUN:
            let ascendingSort = state.tableSortAscending;
            if (state.tableSortColumn === action.columnToSortBy) {
                if (ascendingSort === false) {
                    ascendingSort = true;
                } else {
                    ascendingSort = false;
                }
            } else {
                ascendingSort = true;
            }
            const sortedIds = sortByColumnBetter(
                state.allIds,
                state.byId,
                action.columnToSortBy,
                ascendingSort
            );
            sessionStorage.setItem(`run/columnToSortBy`, action.columnToSortBy);
            sessionStorage.setItem(`run/ascendingSort`, ascendingSort);
            return Object.assign({}, state, {
                tableSortAscending: ascendingSort,
                tableSortColumn: action.columnToSortBy,
                allIds: sortedIds
            });
        case SET_SELECTED_MCA_RUN:
            sessionStorage.setItem(
                `mca/run/selectedMcaRun`,
                action.selectedMcaRun
            );
            return Object.assign({}, state, {
                selectedMcaRun: action.selectedMcaRun
            });
        case SET_NEW_MCA_RUN_NAME:
            return Object.assign({}, state, {newMcaRunName: action.name});
        case MCA_RUN_REFRESH:
            return Object.assign({}, state, {
                tableSortColumn: action.tableSortColumn,
                ascendingSort: action.ascendingSort
            });
        case SET_SELECTED_RUN_TO_MOST_RECENT:
            return setSelectedMcaRunToMostRecentCore(state);
        case SET_ERRORS:
            return Object.assign({}, state, {errors: action.errors});
        case SET_TRANSFORM_URLS:
            for (let mcaRun of action.mcaRuns) {
                state.byId[mcaRun.id].hasTransformUrl = mcaRun.hasTransformUrl;
            }
            return Object.assign({}, state, {});
        case SET_LOADING:
                return Object.assign({}, state, {loading: action.loading});
        default:
            return state;
    }
}

// action creators
export function fetchMcaRunsByCampaignSuccess(mcaRuns) {
    return {
        type: FETCH_MCA_RUNS_BY_CAMPAIGN_SUCCESS,
        mcaRuns
    };
}

export function refresh(tableSortColumn, ascendingSort) {
    return {
        type: MCA_RUN_REFRESH,
        tableSortColumn,
        ascendingSort
    };
}

export function sortMcaRun(columnToSortBy) {
    return {
        type: SORT_MCA_RUN,
        columnToSortBy
    };
}

export function setSelectedMcaRun(selectedMcaRun) {
    return {
        type: SET_SELECTED_MCA_RUN,
        selectedMcaRun
    };
}

export function setNewMcaRunName(name) {
    return {
        type: SET_NEW_MCA_RUN_NAME,
        name
    };
}

export function setSelectedRunToMostRecent() {
    return {
        type: SET_SELECTED_RUN_TO_MOST_RECENT
    };
}

export function setErrors(errors) {
    return {
        type: SET_ERRORS,
        errors
    };
}

export function setLoading(loading) {
    return {
        type: SET_LOADING,
        loading
    };
}

export function updateMcaRunsWithTransformUrls(mcaRuns) {
    return {
        type: SET_TRANSFORM_URLS,
        mcaRuns
    };
}
// helper functions

export function checkObjectHasKeys(obj) {
    return Object.keys(obj).length !== 0;
}

function translateStatus(status) {
    switch (status) {
        case 'RUN_COMPLETE':
            return RUN_STATUS.SELECT_MODEL;
        case 'RUNNING':
            return RUN_STATUS.PROCESSING;
        case 'ERROR':
            return RUN_STATUS.ERROR;
        case 'FINAL':
            return RUN_STATUS.COMPLETED;
        case 'CANCELLED':
            return RUN_STATUS.CANCELLED;
        case 'FINALIZING':
            return RUN_STATUS.FINALIZING;
        default:
            return RUN_STATUS.UNKNOWN;
    }
}

/**
 * returns the name of the selected run
 * if there is no selected run, or no list of runs return an empty string
 * @param  {object} state the run state
 * @return {string}       the current selected run name
 */
function getSelectedMcaRunNameCore(state) {
    if (!state.selectedMcaRun || !state.byId[state.selectedMcaRun]) {
        return '';
    } else {
        return state.byId[state.selectedMcaRun].name;
    }
}
