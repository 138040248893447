import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import {FormUtilLV} from '../util/FormUtilLV';
import {FieldErrorLV} from '../wrapper/FieldErrorLV';
import {styles} from './SelectFieldLV.styles';

const selectStyle = theme => ({
  select: {
      '&:before': {
          border: 0,
      },
      '&:after': {
          border: 0,
      }
  },
  icon: {
      fill: '#ffffff',
  },
});

/**
 * Form text input field for Leavened
 */
class SelectFieldLV extends React.Component {
  
  render() {
    const {name, value, items, onChange, className, classes, dirty, errors, disabled=false} = this.props;
    const menuItems = items.map((item, index) => <MenuItem key={index}
                                                           value={item}
                                                           aria-label={'menu-item-lv'}
                                                           className={styles.menuItem}>
      {FormUtilLV.titleCase(item)}</MenuItem>);
    const parentClassName = [classes.select, className].join(' ');

    return (
      <div>
        <Select name={name} value={value} onChange={onChange} displayEmpty={true} disabled={disabled}
          className={`select-field-lv ${parentClassName} ${styles.selectRoot}`}
          variant="standard"
          inputProps={{
            classes: {
              select: styles.selectField,
              icon: classes.icon,
            },
          }}
        >
          {menuItems}
        </Select>
        <div>
          <FieldErrorLV
            name={name}
            dirty={dirty}
            errors={errors}
          />
        </div>
      </div>
    );
  }
}

SelectFieldLV.propTypes = {
  /**
   * Field name which will be key for form errors
   */
  name: PropTypes.string.isRequired,
  /**
   * The initial selected value
   */
  value: PropTypes.string,
  /** 
   * Array of menu items
   */
  items: PropTypes.array,
  /** 
   * Function for onChange event
   */
  onChange: PropTypes.func
};

export default withStyles(selectStyle)(SelectFieldLV);
