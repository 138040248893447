import { makeUseAxios } from 'axios-hooks';
import axios from 'axios';
import PropTypes from 'prop-types';
import {AuthToken} from './AuthFactory';
import {REST} from './REST.constant';
import {Config} from '../config/ConfigFactory';

axios.interceptors.request.use(AuthToken.attachBearerToken);

/**
 * Custom react hook that makes REST Calls
 * Uses the axios-hooks library
 * Added as a custom hook so they it can be extended by other custom hooks
 * https://github.com/simoneb/axios-hooks
 */
export const useRest = ({
  url, method, data, headers,
  manual=true, useCache=false
}) => {

  const allHeaders = {...DEFAULT_HEADERS, ...headers};

  // Make the actual hook call after we have an Auth token
  const fullUrl = urlWithDomain(url);

  const useAxios = makeUseAxios({
    axios
  });

  const axiosResponse =  useAxios({
    url: fullUrl,
    method: method,
    data,
    headers: allHeaders,
  },{
    manual,
    useCache
  });

  /**
   * There is an edge case when leavened Config object has not been loaded ... where
   * <html/> markup is returned as response which kills the app
   * Return a [{loading=true}] status in this condition
   * Url will be called again when config is populated
   */
  const [{data: responseData}] = axiosResponse;
  if(responseData && typeof responseData === 'string' && responseData.startsWith('<')) {
    return NO_CONFIG_AXIOS_RESPONSE;
  }

  return axiosResponse;
};

export const useAuthHeader = () => {
  const token = AuthToken.getAuthToken().token;
  return {
    authorization: token ? `Bearer ${token}` : ''
  };
};

export const NO_CONFIG_AXIOS_RESPONSE = [{loading: true}, () => {}];

// Add the Base Url if a full address is not supplied
export const urlWithDomain = (url='') => {
  const baseUrl = Config.getConfig().BASE_URL;
  return url.startsWith('http') ? url : `${baseUrl}${url}`;
};

// Standard REST method types
export const useGet = ({manual=false, ...props}) => (
  useRest({method: REST.METHOD.GET, manual, ...props})
);

export const usePost = props => (
  useRest({method: REST.METHOD.POST, ...props})
);

export const usePut = props => (
  useRest({method: REST.METHOD.PUT, ...props})
);

export const usePatch = props => (
  useRest({method: REST.METHOD.PATCH, ...props})
);

export const useDelete = props => (
  useRest({method: REST.METHOD.DELETE, ...props})
);

export const DEFAULT_HEADERS = {
  'cache-control': 'no-cache',
  'Content-Type': 'application/json',
  'accept': '*/*',
};

useRest.PropTypes = {
  /**
   * Url to send request to
   * Can be domain/path or just path
   * If no domain is supplied, the BASE_URL will be used
   */
  url: PropTypes.string,
  /**
   * GET/POST/PUT/DELETE etc...
   */
  method: PropTypes.string,
  /**
   * Data body to send with request
   */
  data: PropTypes.obj,
  /**
   * Additional headers to send
   */
  headers: PropTypes.obj,
  /**
   * FALSE: Request will be made on load
   * TRUE: Request will only be made when callback is used
   */
  manual: PropTypes.bool,
  /**
   * If true, duplicate requests will return previous result
   * without making another call
   */
  useCache: PropTypes.bool
};
