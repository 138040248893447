import React from 'react';
import {DownloadFileIcon} from './icons/DownloadFileIcon';
import {DownloadImageIcon} from './icons/DownloadImageIcon';
import DownloadCSVFactory from '../../js/download-csv-factory';
import domToImage from 'dom-to-image-more';
import {saveAs} from 'file-saver';
import {styles} from './GraphCandyBar.styles';
import {GraphSelect} from './GraphSelect';
import {GRAPH_OPTIONS} from './graph-options.constant';
import {usePostTriggerDownloadUrl} from "../../hooks/useTriggerDownloadUrl";

/**
 * Control Panel for graph or graphs
 * Handles graph selection, image/csv download
 */
export const GraphCandyBar = ({
                                  data, fileData, children, elementId, includeInDownload, csvFileName,
                                  TopDropdown, selectedGraph, setSelectedGraph, imageFileName = 'image',
                                  graphOptions, ...props
                              }) => {
    const selectGraph = ({value}) => {
        setSelectedGraph(value);
    };
    const includeInDownloadText = includeInDownload ? 'include-in-download-' : '';

    // Use JSON data array for grids to create a Downloadable CSV file
    const csvFullName = (csvFileName) ? `${csvFileName}.csv` : undefined;
    DownloadCSVFactory.createDownload(elementId, data, csvFullName);

    if (fileData) {
        fileData.forEach(elem => {
            if (elem.data) {
                DownloadCSVFactory.createDownload(elem.id, elem.data, `${elem.fileName}.csv`);
            }
        });
    }

    // Download this entire element as an image
    // Ignore the graph control panel
    const downloadThisElement = async () => {
        const filter = (node) => {
            return (node.id !== `downArrow` &&
                node.id !== `graph-control-panel-icon-${elementId}`);
        };
        const element = document.getElementById(elementId);
        if (element.style) {
            element.style.background = 'transparent';
        }

        const chicletElements = document.querySelectorAll('.chiclet-box');
        chicletElements.forEach(element => element.style.background = 'transparent');

        const graphElements = document.querySelectorAll('.graph-panel');
        graphElements.forEach(element => element.style.background = 'transparent');

        let imageContainer = element;
        if (props.imageHeader) {
            imageContainer = element.cloneNode(true);
            imageContainer.style.padding = '24px';
            imageContainer.style.width = element.clientWidth + 'px';
            imageContainer.innerHTML = props.imageHeader + imageContainer.innerHTML;
            document.body.appendChild(imageContainer);
        }
        const blob = await domToImage.toBlob(imageContainer, {filter: filter, bgcolor: 'transparent'});

        if (element.style) {
            element.style.background = '#FFF';
        }
        chicletElements.forEach(element => element.style.background = '#FFF');
        graphElements.forEach(element => element.style.background = '#FFF');

        saveAs(blob, imageFileName + '.png');
    };

    const downloadCsv = () => {
        let report;
        if (fileData) {
            fileData.forEach(elem => {
                console.log(elem)
                if (elem.data) {
                    report = DownloadCSVFactory.getDownload(elem.id);
                    report.download();
                } else if (typeof elem.triggerDownload === 'function') {
                    elem.triggerDownload()
                }
            });
        } else {
            report = DownloadCSVFactory.getDownload(elementId);
            report.download();
        }
    };

    return (
        <div
            id={`${includeInDownloadText}graph-control-panel-${elementId}`}
            className={styles.controlPanel}
            data-test='graph-control-panel'>

            {TopDropdown}

            <div className={styles.icons} id={`graph-control-panel-icon-${elementId}`}>
                <DownloadFileIcon
                    {...props}
                    title={csvFullName}
                    data={data}
                    className='graph-control-icon'
                    onClick={downloadCsv}
                />

                <div
                    className='graph-control-icon'>
                    <DownloadImageIcon
                        {...props}
                        title={`${imageFileName}.png`}
                        onClick={downloadThisElement}
                    />
                </div>
            </div>
            <GraphSelect
                className='graph-control-icon'
                onChange={selectGraph}
                options={graphOptions}
                defaultValue={GRAPH_OPTIONS[selectedGraph]}/>
        </div>
    );
};
