import {css} from 'emotion';
import {baseFont} from '../../../styles/commonStyles';

/**
 * Custom Styles for React-Select
 * https://react-select.com/styles
 * @param width width override for container
 * @returns Customized styles for react-select
 */
export const customStyles = ({isDark=true}) => {
  const mainColors = getThemeColors(isDark);
  return {
    control: provided => {
      return {
        ...provided,
        '&:hover': {borderColor: 'none'},
        boxShadow: 'none',
        border: 0,
        background: 'transparent',
        borderBottom: `0.5px solid ${mainColors.border}`,
        borderRadius: 0,
        minHeight: '24px'
      };
    },
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none'
    }),
    clearIndicator: (provided) => ({
      ...provided,
      padding: 0,
      marginRight: '8px'
    }),
    valueContainer: (provided) => {
      return {
        ...provided,
        padding: 0,
        fontFamily: baseFont,
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '0.4px',
      };
    },
    noOptionsMessage: (provided) => {
      return {
        ...provided,
        fontFamily: baseFont,
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '0.4px'
      };
    },
    singleValue: (provided) => {
      return {
        ...provided,
        color: mainColors.textColor,
        padding: 0
      };
    },
    multiValueLabel: (provided) => ({
      ...provided,
      color: 'rgba(0, 0, 0, 0.87)',
      borderRadius: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.26)'
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      borderRadius: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.26)'
    }),
    menu: (provided) => {
      return {
        ...provided,
        margin: 0,
        padding: '4px 0',
        background: mainColors.optionBackground,
        borderRadius: '10px',
      };
    },
    option: (provided) => {
      return {
        ...provided,
        border: 0,
        color: mainColors.optionText,
        fontFamily: baseFont,
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '20px',
        letterSpacing: '0.25px',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      };
    }
  };
};

/**
 * Custom theme for React-Select
 * https://react-select.com/styles#overriding-the-theme
 */
export const customTheme = ({isDark=true}) => {
  const mainColors = getThemeColors(isDark);
  return theme => ({
    ...theme,
    colors: {
      ...theme.colors,
      neutral0: mainColors.optionsBackground,
      neutral80: mainColors.textColor,
      primary: mainColors.optionSelected,
      primary25: mainColors.optionHover,
      primary50: 'rgba(255, 255, 255, 0.3)',
    }
  });
};

export const svgIcon = ({isDark=true})=> {
  const mainColors = getThemeColors(isDark);
  return {
    svg: css`
      fill: ${mainColors.border};
      width: 18px;
      height: 18px;
      margin-top: 4px;
      display: inline-block;
      transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      flex-shrink: 0;
      user-select: none;
      top: calc(50% - 12px);
      color: ${mainColors.border};
      right: 0;
      position: absolute;
      pointer-events: none;
    `
  };
};

const getThemeColors = isDark => {
  return isDark ? darkTheme : lightTheme;;
};

const darkTheme = {
  border: '#FFF',
  text: '#FFF',
  selectedText: '#333',
  optionBackground: '#374f63',
  optionText: '#FFF',
  optionSelected: 'rgba(255, 255, 255, 0.2)',
  optionHover: 'rgba(255, 255, 255, 0.1)'
};

const lightTheme = {
  border: '#828282',
  text: '#828282',
  selectedText: '#333',
  optionBackground: '#FAFAFA',
  optionText: 'rgba(0, 0, 0, 0.87)',
  optionSelected: '#EDEDED',
  optionHover: '#F2F2F2'
};

/**
 * Adds hover for 'Select All' option
 * Disables hover for other options
 */
export const getSelectAllStyle = (isDark, isHover) => {
  const theme = getThemeColors(isDark);
  const focusBackground = isHover ? theme.optionBackground : theme.optionHover;
  return css`
    .select-all-option {
      margin-bottom: 8px !important;
    }

    .select-all-option:hover {
      background-color: ${theme.optionHover} !important;
    }

    .select-all-spacer {
      padding-bottom: 8px;
      border-top: 0.5px solid #FFF !important;
      margin: 0 12px;
    }

    .LV__option--is-focused {
      background-color: ${focusBackground} !important;
    }
  `;
};
