import {css} from 'emotion';

export const styles = {
    goodChip: css`
        background: rgba(55, 79, 99, 0.05) !important;
        color: #333333 !important;
        height: 23px;
        border-radius: 160px;
    `,
    errorChip: css`
        background: rgba(55, 79, 99, 0.05) !important;
        color: #333333 !important;
        border-color: red !important;
        height: 23px;
        border-radius: 160px;
    `
};
