import React from 'react';
import {useGet, usePost} from './useRest';
import {urlBuilders} from '../js/constants';
import {useCampaignManagement} from './useCampaignManagement';
import moment from 'moment';
import _ from 'lodash';

/**
 * Get all MCA runs for a particular campaign
 * v1/mca-run/?campaignId={campaignId}
 */
export const useMcaRuns = () => {
  const {campaignId, mcaRunId} = useCampaignManagement();

  // GET mca run by the campaign
  const [{
    data: dataMcaRuns,
    loading
  }, getMcaRuns] = useGet({
    url: urlBuilders.getMcaRunByCampaignId(campaignId),
    manual: true // manual: true will not run on load
  });

  // GET All MCA Runs
  React.useEffect(() => {
    if(campaignId) {
      getMcaRuns();
    }
  }, [campaignId, getMcaRuns]);

  // Sort by run start date
  const mmmRuns = useSortListByDate(dataMcaRuns);

  const mcaRuns = React.useMemo(()=> {
      let parentsWithChildren = {};
      return mmmRuns.map(
        mmmRun => {
          mmmRun.path = [mmmRun.id];
          if (mmmRun.parentId) {
            mmmRun.path.splice(0,0,mmmRun.parentId);
            mmmRun.isChild = true;
            parentsWithChildren[mmmRun.parentId] = parentsWithChildren[mmmRun.parentId] ? [...parentsWithChildren[mmmRun.parentId], mmmRun.id] : [mmmRun.id];
          }
          mmmRun.childrenId = parentsWithChildren[mmmRun.id] ? parentsWithChildren[mmmRun.id] : null;
          return mmmRun;
        }
      );
    }
    ,[mmmRuns]);

  // Get the specific run that is selected from the list of runs
  const selectedMcaRun = mcaRuns.find(
    run => (run.id === mcaRunId));

  const mcaRunIdList = mcaRuns.map(run => run.id);
  const [{data: magnifaiRuns}, getMagnifaiRuns] = usePost({
    url: urlBuilders.getMagnifAIRunStatus(),
    data: {mcaRunIdList}
  });

  React.useEffect(() => {
    if(dataMcaRuns){
      getMagnifaiRuns();
    }
  }, [dataMcaRuns, getMagnifaiRuns])
  
  return {
    mcaRuns,
    selectedMcaRun,
    loading,
    getMcaRuns,
    magnifaiRuns,
    getMagnifaiRuns
  };
};

/**
 * Get MCA runs by provided campaignId
 */
export const useMcaRunsByCampaignId = (campaignId) => {

  // GET mca run by the campaign
  const [{
    data: dataMcaRuns,
    loading,
    error,
    response
  }, getMcaRuns] = useGet({
    url: urlBuilders.getMcaRunByCampaignId(campaignId),
    manual: true // manual: true will not run on load
  });

  // GET All MCA Runs
  React.useEffect(() => {
    if(campaignId) {
      getMcaRuns();
    }
  }, [campaignId, getMcaRuns]);

  // Sort by run start date
  const mcaRuns = useSortListByDate(dataMcaRuns);

  return {
    mcaRuns,
    loading,
    getMcaRuns,
    error,
    response
  };
};

const useSortListByDate = (
  mcaRuns
) => {
  return React.useMemo(() => {
    const runsTimeStampAdded = mcaRuns?.map(
      m => ({
        runTimestamp: moment(m.runStartDate).format('X'),
        ...m
      })
    );

    // Sort by timestamp and remove timestamp property
    return _.sortBy(runsTimeStampAdded, 'runTimestamp')
      .reverse()
      .map(({runTimestamp, ...m}) => ({...m}));

  }, [mcaRuns]);
};
