// actions
export const LOAD_ADD_NEW_ERRORS = `app/add-new/load-errors`;
export const CLEAR_ADD_NEW_ERRORS = `app/add-new/clear-errors`;
export const CHANGE_ADD_NEW_STEP = `app/add-new/change-step`;

// Constants
export const ADD_NEW_FORM = 1;
export const ADD_NEW_COMPLETE = 2;

// selectors
const ID = 'addNew';
const local = (state) => state['platform'][ID];
export const getAddNewStep = (state) => local(state).step;

export const initialState = {
    step: ADD_NEW_FORM
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_ADD_NEW_STEP:
            return Object.assign({}, state, {step: action.step});
        default:
            return state;
    }
}

export function changeAddNewStep(step) {
    return {
        type: CHANGE_ADD_NEW_STEP,
        step
    };
}
