import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import {getDummyData} from '../../../components/data-table/getDummyData';
import {ScoringSpendChiclet} from '../chiclets/ScoringSpendChiclet';
import {ScoringContributionsChiclet} from '../chiclets/ScoringContributionsChiclet';
import {ScoringDashboardContext} from "../ScoringDashboardContext";
import {useScoringFlightingComparisonDashboard} from "./hooks/useScoringFlightingComparisonDashboard";

export const FlightingComparison = ({}) => {

    const {
        selectedMcaRun,
        startDate,
        endDate,
        region,
        scoring1
    } = React.useContext(ScoringDashboardContext);

    const {
        contributions1,
        contributions2,
        meanCenter1,
        meanCenter2,
        mediaGrouping,
        isLoading
    } = useScoringFlightingComparisonDashboard({});

    const kpiType = selectedMcaRun?.kpiType;

    const [costPerValue, setCostPerValue] = React.useState({});

    const hasScoring2 = React.useMemo(() => {
        return !!contributions1?.length && !!contributions2?.length;
    }, [contributions1, contributions2]);

    const filteredSpendData1 = !!scoring1 && filterSpendData(mediaGrouping, meanCenter1, region, startDate, endDate) || [];
    const filteredSpendData2 = hasScoring2 && filterSpendData(mediaGrouping, meanCenter2, region, startDate, endDate) || [];
    const spendTableData = mapVariableMeasures(mediaGrouping, filteredSpendData1, filteredSpendData2);
    const spendVariables = spendTableData.map(spend => spend.variable);
    const filteredContributionData1 = !!scoring1 && filterContributionData(spendVariables, contributions1, startDate, endDate) || [];
    const filteredContributionData2 = hasScoring2 && filterContributionData(spendVariables, contributions2, startDate, endDate) || [];

    return (
        <div>
            <ScoringSpendChiclet
                spendTableData={spendTableData}
                hasData={!!filteredSpendData1.length}
                hasScoring2={hasScoring2}
                costPerValue={costPerValue}
                setCostPerValue={setCostPerValue}
                isLoading={isLoading}
            />
            <ScoringContributionsChiclet
                kpiType={kpiType}
                spendTableData={spendTableData}
                dailyContributionsData1={contributions1}
                dailyContributionsData2={contributions2}
                contributionsData1={filteredContributionData1}
                contributionsData2={filteredContributionData2}
                hasScoring2={hasScoring2}
                costPerValue={costPerValue}
                isLoading={isLoading}
            />
        </div>
    );
}


const filterSpendData = (mediaGrouping = [], data = [], region, startDate, endDate) => {
    const filteredData = startDate && endDate ? data.filter((row) => {
        const date = moment(row?.Date, 'YYYY-MM-DD');
        return date.isValid && date.isBetween(moment(startDate), moment(endDate), undefined, '[]');
    }) : data;

    const variables = mediaGrouping.map(variable => variable.VariableName + '.x');
    const mappedData = filteredData.map(row => _.pick(row, 'Date', 'Region', variables));
    return (mappedData.map(row =>
        Object.fromEntries(Object.entries(row).map(([key, value]) => {
            const newKey = key.endsWith('.x') ? key.slice(0, -2) : key;
            return [newKey, value];
        }))
    ));
}

const mapVariableMeasures = (mediaGrouping = [], data1, data2) => {
    if (!data1.length && !data2.length) {
        const dummySchema = {
            variable: '',
            pricingStructure: '',
            costPer1: '',
            spend1: '',
            costPer2: '',
            spend2: '',
            change: ''
        };
        return getDummyData(dummySchema, 4);
    }

    const unitPricing = {'Impressions': 'CPM', 'Clicks': 'CPC', 'Ratings': 'CPP'};
    const unitMeasures = Object.keys(unitPricing);
    const variables = mediaGrouping.map(data => ({variable: data.VariableName, measure: data.Measure}));
    const spends = variables.map(({variable, measure}) => {
        const pricingStructure = unitPricing[measure];
        let costPer1;
        let costPer2;
        if (unitMeasures.includes(measure)) {
            costPer1 = 1;
            costPer2 = 1;
        }

        let volume1 = data1.reduce((sum, current) => sum += Number(current[variable]), 0);
        volume1 = Math.round(measure === 'Impressions' ? volume1 / 1000 : volume1);
        let volume2 = data2.reduce((sum, current) => sum += Number(current[variable]), 0);
        volume2 = Math.round(measure === 'Impressions' ? volume2 / 1000 : volume2);

        let item = {
            variable,
            pricingStructure,
            costPer1,
            volume1,
            spend1: volume1,
        };
        if (data2.length) {
            item = {
                ...item,
                costPer2,
                volume2,
                spend2: volume2,
                change: volume1 - volume2
            };
        }
        return item;
    });
    return spends.sort((spend1, spend2) => spend1.variable.localeCompare(spend2.variable));
};

const filterContributionData = (spendVariables = [], data = [], startDate, endDate) => {
    const filteredData = startDate && endDate ? data.filter((row) => {
        const date = moment(row?.date, 'YYYY-MM-DD');
        return date.isValid && date.isBetween(moment(startDate), moment(endDate), undefined, '[]');
    }) : data;

    if (!spendVariables.length || !filteredData.length) {
        return [];
    }

    let contributions = Object.keys(filteredData[0]);

    return contributions
        .filter(contribution =>
            spendVariables.includes(contribution))
        .map(contribution => {
            const sum = filteredData.reduce((sum, current) => sum += Number(current[contribution]), 0)
            return {
                variable: contribution,
                scenario: Math.round(sum)
            };
        });
};
