import React from 'react';

import {GraphContainerLV} from '../../../components/dashboards/GraphContainerLV';
import {GraphConfig} from '../../../components/dashboards/GraphConfig';
import {GRAPH_OPTIONS} from '../../../components/dashboards/graph-options.constant';

export const OptimizerGraphContainer = ({
  data=[], isLoading=false
}) => {

  const spendData = React.useMemo(() => {
    return data.map(d => ({
      name: d.baseVariable,
      value: d.mediaValue * 1
    })).filter(f => f.value >= 0);
  }, [data]);

  const contributionData = React.useMemo(() => {
    return data.map(d => ({
      name: d.baseVariable,
      value: d.simulatedContribution * 1
    })).filter(f => f.value >= 0);
  }, [data]);

  const spendConfig = new GraphConfig({
    data: spendData,
    title: 'Percent of Spend',
    imageFileName: 'optimizer-percent-of-spend',
    graphOptions: [
      GRAPH_OPTIONS.PIE
    ],
    xAxisKey: 'name',
    yAxisKey: 'value',
    isLoading: isLoading
  });

  const contributionConfig = new GraphConfig({
    data: contributionData,
    title: 'Percent of Contribution',
    imageFileName: 'optimizer-percent-of-contribution',
    graphOptions: [
      GRAPH_OPTIONS.PIE
    ],
    xAxisKey: 'name',
    yAxisKey: 'value',
    isLoading: isLoading
  });

  return (
    <GraphContainerLV
      graphConfig={[spendConfig, contributionConfig]}
    />
  );
};
