import React from 'react';
import {
  R1ActionIcon,
} from '@rainagency/rain-one-soggy-loaf';
import { urlBuilders } from '../../../../js/constants';
import { usePut } from '../../../../hooks/useRest';
import { DoDisturbOffOutlined } from '@mui/icons-material';

/**
 * Cancel a run
 * Action icon in data grid popover
 */
export const CancelActionIcon = ({
  cell,
  hide,
  getRuns,
  closePopover
}) => {

  let url = urlBuilders.updateV2McaRunStatus(cell.row.id);
  if(cell.row.runVersion === 'V3') {
    url = urlBuilders.updateV3McaRunStatus(cell.row.id);
  }

  const runName = cell.row.name;

  const [{loading, response}, putCancel] = usePut({
    url
  });

  // refresh table when response is returned
  React.useEffect(() => {
    if(!response) {
      return;
    }

    closePopover();
    getRuns();
  }, [response]);

  const handleCancelRun = () => {
    putCancel({
      data: {
        runStatus: 'CANCELLED'
      }
    });
  };

  return (
    <R1ActionIcon
      label='Cancel Run'
      icon={DoDisturbOffOutlined}
      hide={hide}
      onDialogConfirm={handleCancelRun}
      DialogProps={{
        title: `Are you sure you want to cancel run ${runName}?`,
        description: 'The processing of this run will stop, and cannot be restarted.',
        cancelLabel: 'Keep Running',
        confirmLabel: 'Cancel Run',
        onCancel: closePopover,
        customStyles: {
          cursor: loading ? 'wait' : 'pointer'
        }
      }}
    />
  );
};
