import { R1Switch } from '@rainagency/rain-one-soggy-loaf';

export const InterceptField = ({disabled, selectedAnalysisType, includeIntercept}) => {
  if (selectedAnalysisType === 'AdImpact MMM') {
      return null;
  }
  return (
      <R1Switch
          name='includeIntercept'
          data-testid={`include-intercept-switcher`}
          label='Include Intercept'
          disabled={ disabled }
          defaultValue={ includeIntercept } 
          checked={ includeIntercept } />
  );
}