import React from 'react';
import {OptimizerForm} from './OptimizerForm';
import {OptimizerReport} from './OptimizerReport';

export const ScenarioOptimizerTab = ({
  scenarios,
  formData,
  setFormData,
  selectedMcaDashboardRun,
  loading,
  isOptimized,
  setIsOptimized,
  optimizerMap,
  optimizedSelection,
  setOptimizedSelection
}) => {

  if(!isOptimized) {
    return (
      <OptimizerForm
        data={scenarios}
        formData={formData}
        setFormData={setFormData}
        isLoading={loading}
        selectedMcaDashboardRun={selectedMcaDashboardRun}
        setIsOptimized={setIsOptimized}
        optimizerMap={optimizerMap}
        optimizedSelection={optimizedSelection}
        setOptimizedSelection={setOptimizedSelection}
      />
    );
  }

  return (
    <OptimizerReport
      scenarios={scenarios}
      isLoading={loading}
      selectedMcaDashboardRun={selectedMcaDashboardRun}
      setIsOptimized={setIsOptimized}
      optimizerMap={optimizerMap}
      optimizedSelection={optimizedSelection}
    />
  );
};
