import React from 'react';
import PropTypes from 'prop-types';
import SelectFieldLV from './SelectFieldLV';
/**
 * Form text input field for Leavened
 * Uses react-hook-form for text field validation
 */
class SelectYesNoLV extends React.Component {
  
  render() {
    const {name, value, errors, onChange, disabled, className} = this.props;

    return (
        <SelectFieldLV
          name={name}
          items={['Yes', 'No']}
          value={value.trim().toLowerCase() === 'yes' ? 'Yes' : 'No'}
          errors={errors}
          onChange={onChange}
          disabled={disabled}
          className={className}
        />
    );
  }
}

SelectYesNoLV.propTypes = {
  /**
   * Field name to be reflected in form object
   */
  name: PropTypes.string.isRequired,
  /**
   * The initial selected value
   */
  value: PropTypes.string,
  /** 
   * Function for onChange event
   */
  onChange: PropTypes.func
};

export {SelectYesNoLV};
