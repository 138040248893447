import {call, put, takeEvery} from 'redux-saga/effects';
import {downloadFromURL} from '../../../js/download';

import {urlBuilders} from '../../../js/constants';

import {
    FETCH_MCA_RUNS_BY_CAMPAIGN_REQUEST,
    FETCH_ERRORS_CSV,
    fetchMcaRunsByCampaignSuccess,
    RUN_MCA,
    setNewMcaRunName,
    EXPORT_MCA_RUN
} from '../run';
import {closeModal} from '../../platform/modal';
import {snackbar} from '../../platform/snackbar';
import {getData, post} from '../../../js/server';

import {checkQueryStatus} from '../../../js/server-helpers';

export function* fetchMcaRunsByCampaign(action) {
    const mcaRuns = action.params.campaignId === null ? [] : yield call(
        getData,
        action.token,
        action.baseUrl +
            urlBuilders.getMcaRunByCampaignId(action.params.campaignId)
    );
    yield put(fetchMcaRunsByCampaignSuccess(mcaRuns));
}

export function* watchFetchMcaRunsByCampaign() {
    yield takeEvery(FETCH_MCA_RUNS_BY_CAMPAIGN_REQUEST, fetchMcaRunsByCampaign);
}

export function* runMca(action) {
    yield put(closeModal());
    yield put(setNewMcaRunName(''));
    const response = yield call(
        post,
        action.token,
        action.baseUrl + urlBuilders.runMca(),
        action.payload
    );
    if (response.error) {
        yield put(
            snackbar(
                'error',
                'ERROR: run with name, ' + action.payload.runName + ', failed.'
            )
        );
    } else {
        yield put(
            snackbar(
                'info',
                'Run with name, ' + action.payload.runName + ', started.'
            )
        );
        yield call(fetchMcaRunsByCampaign, action);
    }
}

export function* watchRunMca() {
    yield takeEvery(RUN_MCA, runMca);
}

/**
 * given an mcaRunId and the partition that run is on, will return all models associated with that run
 * @param  {object}    action the action containing the mcarunid and partition
 * @return {Generator}
 */
export function* exportMcaRun(action) {
    const queryIdResponse = yield call(
        post,
        action.token,
        action.baseUrl + urlBuilders.getMcaModels(action.params.mcaRunId),
        {
            mcaRunId: action.params.mcaRunId,
            partition: '' + action.params.partition
        }
    );

    const queryId = queryIdResponse.body.queryId;

    const queryStatus = yield call(
        checkQueryStatus,
        action.token,
        action.baseUrl,
        queryId
    );

    if (queryStatus === 'Success') {
        const exportResponse = yield call(
            getData,
            action.token,
            action.baseUrl + urlBuilders.exportMca(queryId, 'csv')
        );
        if (!exportResponse.error) {
            downloadFromURL(exportResponse.url);
        } else {
            yield put(snackbar('error', 'error downloading mca run results'));
        }
    } else {
        yield put(snackbar('error', 'error downloading mca run results'));
    }
}

export function* watchExportMcaRun() {
    yield takeEvery(EXPORT_MCA_RUN, exportMcaRun);
}

export function* fetchErrorsCsv(action) {
    const exportErrorsUrl = urlBuilders.exportErrorsCsv(
        action.params.linkingId
    );
    const fileLocation = yield call(
        post,
        action.token,
        action.baseUrl + exportErrorsUrl,
        action.payload
    );
    if (fileLocation.error) {
        yield put(snackbar('error', 'ERROR: Could not retrieve errors'));
    } else {
        downloadFromURL(fileLocation.body.data.url);
    }
}

export function* watchFetchErrorsCsv() {
    yield takeEvery(FETCH_ERRORS_CSV, fetchErrorsCsv);
}
