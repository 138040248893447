import React from 'react';
import {
  Apps,
  Home,
  Folder,
  Dashboard,
  DonutLarge,
  FactCheck,
  TrackChanges,
  Assessment,
  Assignment
} from '@mui/icons-material';
import {
  Tooltip,
  Divider
} from '@mui/material';
import { styled } from '@mui/system';
import {
  R1Button
} from '@rainagency/rain-one-soggy-loaf';
import {
  ProductToggle
} from './components/ProductToggle';
import {
  FEATURES,
  PRODUCT
} from '../../../constants/features';
import {
  getProductByFeatureKey
} from '../../../hooks/useProductFeatures';
import {Config} from '../../../config/ConfigFactory';

/**
 * Display navigation buttons on Left Navigation
 *
 * @param open - Open state of navigation drawer
 * @param products - List of products current user has access to
 * @param selectedFeature - Currently selected feature
 * @param setSelectedFeature - Handle button click and tell app what feature was selected
 */
export const NavigationMenu = ({
  open,
  isHidden,
  products = {},
  selectedFeature,
  setSelectedFeature = () => {}
}) => {
  const scenarioOptimizer = Config.getConfig()?.FEATURE_FLAGS?.SCENARIO_OPTIMIZER;

  const allowedProducts = [PRODUCT.MMM.key, PRODUCT.ADIMPACT.key];
  const [selectedProduct, setSelectedProduct] = React.useState(PRODUCT.MMM.key);

  // Update toggle state based on feature changes from parent
  React.useEffect(() => {
    if (!selectedFeature) {
      return;
    }

    const parentProduct = getProductByFeatureKey(selectedFeature.key);
    if (!parentProduct || !allowedProducts.includes(parentProduct.key)) {
      return;
    }

    setSelectedProduct(parentProduct.key);
  }, [selectedFeature]);


  // Update selected product default to ad-impact if only ad-impact user
  React.useEffect(() => {
    if(!products[PRODUCT.MMM.key] && products[PRODUCT.ADIMPACT.key]) {
      setSelectedProduct(PRODUCT.ADIMPACT.key);
    }
  }, [products]);

  return (
    <NavMenuStyled
      className='lv-navigation-button-container'>

      <NavigationButton
        isHome={true}
        startIcon={<Home/>}
        tooltip='Home'
        selectedFeature={selectedFeature}
        setSelectedFeature={setSelectedFeature}
        open={open}>
        Home
      </NavigationButton>

      <Divider
        flexItem={true}
        light={true}
        sx={{
          borderColor: 'rgba(224, 224, 224, 0.3)',
          minWidth: '40px'
        }}
      />

      <NavigationButton
        feature={FEATURES.MANAGE_CAMPAIGNS}
        startIcon={<Apps/>}
        tooltip='Campaigns'
        selectedFeature={selectedFeature}
        setSelectedFeature={setSelectedFeature}
        selectedProduct={PRODUCT.CAMPAIGN_MANAGEMENT.key}
        products={products}
        open={open}>
        Campaigns
      </NavigationButton>

      <NavigationButton
        feature={FEATURES.MANAGE_DATA}
        startIcon={<Folder/>}
        tooltip='Manage Data'
        selectedFeature={selectedFeature}
        setSelectedFeature={setSelectedFeature}
        selectedProduct={selectedProduct}
        products={products}
        open={open}
        isHidden={isHidden}>
        Manage Data
      </NavigationButton>

      <ProductToggle
        open={open}
        isHidden={isHidden}
        selectedProduct={selectedProduct}
        setSelectedProduct={setSelectedProduct}
        setSelectedFeature={setSelectedFeature}
        products={products}
        selectedFeature={selectedFeature}
      />

      <NavigationButton
        feature={FEATURES.ADIMPACT_ANALYSIS}
        startIcon={<TrackChanges/>}
        tooltip='AdImpact Analysis'
        selectedFeature={selectedFeature}
        setSelectedFeature={setSelectedFeature}
        selectedProduct={selectedProduct}
        products={products}
        open={open}
        isHidden={isHidden}>
        Analysis
      </NavigationButton>

      <NavigationButton
        feature={FEATURES.MMM_ANALYSIS}
        startIcon={<DonutLarge/>}
        tooltip='MMM Analysis'
        selectedFeature={selectedFeature}
        setSelectedFeature={setSelectedFeature}
        selectedProduct={selectedProduct}
        products={products}
        open={open}
        isHidden={isHidden}>
        Analysis
      </NavigationButton>

      <NavigationButton
        feature={FEATURES.MMM_DASHBOARDS}
        startIcon={<Dashboard/>}
        tooltip='MMM Dashboards'
        selectedFeature={selectedFeature}
        setSelectedFeature={setSelectedFeature}
        selectedProduct={selectedProduct}
        products={products}
        open={open}
        isHidden={isHidden}>
        Dashboards
      </NavigationButton>

      <NavigationButton
        feature={FEATURES.ADIMPACT_DASHBOARDS}
        startIcon={<Dashboard/>}
        tooltip='AdImpact Dashboards'
        selectedFeature={selectedFeature}
        setSelectedFeature={setSelectedFeature}
        selectedProduct={selectedProduct}
        products={products}
        open={open}
        isHidden={isHidden}>
        Dashboards
      </NavigationButton>

      <NavigationButton
        feature={FEATURES.SCENARIO_PLANNER}
        startIcon={<Assignment/>}
        tooltip='Scenario Planner'
        selectedFeature={selectedFeature}
        setSelectedFeature={setSelectedFeature}
        selectedProduct={selectedProduct}
        products={products}
        open={open}
        isHidden={isHidden}>
        Scenario Planner
      </NavigationButton>

      <NavigationButton
        feature={FEATURES.SCENARIO_OPTIMIZER}
        startIcon={<Assessment/>}
        tooltip='Scenario Optimizer'
        selectedFeature={selectedFeature}
        setSelectedFeature={setSelectedFeature}
        selectedProduct={selectedProduct}
        products={products}
        open={open}
        isHidden={isHidden || !scenarioOptimizer}>
        Scenario Optimizer
      </NavigationButton>

      <NavigationButton
        feature={FEATURES.SCORING}
        startIcon={<FactCheck/>}
        tooltip='Scoring'
        selectedFeature={selectedFeature}
        setSelectedFeature={setSelectedFeature}
        selectedProduct={selectedProduct}
        products={products}
        open={open}
        isHidden={isHidden}>
        Scoring
      </NavigationButton>

    </NavMenuStyled>
  );
};

/**
 * Standard button to use in navigation
 *
 * @param open - Open state of navigation drawer
 * @param feature - Which product feature this button represents
 * @param startIcon - Mui Icon to display
 * @param children - Child contents, usually text
 * @param tooltip - Text to display on hover
 * @param selectedFeature - Currently selected feature
 * @param setSelectedFeature - Handle button click and tell app what feature was selected
 * @param selectedProduct - Current selected product key from the toggle in navigation
 * @param products - List of products current user has access to
 * @param isHome - flag telling if this is the home button
 * @param isHidden - hide this component
 */
const NavigationButton = ({
  open,
  feature = {},
  startIcon,
  children,
  tooltip,
  selectedFeature,
  setSelectedFeature = () => {},
  selectedProduct,
  products = {},
  isHome,
  isHidden
}) => {

  if(isHidden) {
    return null;
  }

  const EXCLUSIONS = {
    [PRODUCT.MMM.key]: [
      FEATURES.ADIMPACT_DASHBOARDS.key,
      FEATURES.ADIMPACT_ANALYSIS.key
    ],
    [PRODUCT.ADIMPACT.key]: [
      FEATURES.SCORING.key,
      FEATURES.SCENARIO_PLANNER.key,
      FEATURES.SCENARIO_OPTIMIZER.key,
      FEATURES.MMM_ANALYSIS.key,
      FEATURES.MMM_DASHBOARDS.key,
    ]
  };

  let selected = false;
  const title = open ? '' : tooltip;
  const excludedArr = EXCLUSIONS[selectedProduct] || [];

  // Exit if this button is not part of the toggled product
  if(selectedProduct && excludedArr.includes(feature.key)) {
    return null;
  }

  // Not a member of this product
  if(selectedProduct && !products[selectedProduct]) {
    return null;
  }

  // Not allowed this product feature
  if(selectedProduct && !products[selectedProduct].features.some(s => s.key === feature.key)) {
    return null;
  }

  // No feature selected means home button is selected
  if(isHome && !selectedFeature) {
    selected = true;
  }

  // This feature is selected
  if(selectedFeature && selectedFeature.key === feature.key) {
    selected = true;
  }

  const classNames = [];
  if(selected) {
    classNames.push('nav-selected');
  }

  const clickHandler = () => {
    if (selected) {
      return;
    }

    setSelectedFeature({feature});
  };

  return (
    <ButtonStyled
      data-testid={`nav-button-${feature.key}`}
      aria-label={feature.ariaLabel || children}
      className={classNames.join(' ')}
      open={open}
      selected={selected}
      onClick={clickHandler}
      startIcon={
        <Tooltip title={title} placement='right'>
          {startIcon}
        </Tooltip>
      }
      color={selected ? 'secondary' : 'primary'}
      variant={selected ? 'contained' : 'text'}>

      <span className='nav-button-text'>
        {children}
      </span>

    </ButtonStyled>
  );
};

const NavMenuStyled = styled('div')(({
  theme,
}) => ({
  display: 'grid',
  gridTemplateRows: 36,
  gap: 12,
  justifyContent: 'flex-start',
  minWidth: '40px'
}));

const ButtonStyled = styled(R1Button)(({
  theme,
  open,
  selected
}) => ({
  textTransform: 'none',
  width: open ? 170 : 36,
  height: 36,
  justifyContent: 'flex-start',
  minWidth: 36,
  color: selected ? '#FFF' : 'rgba(255, 255, 255, 0.6)',
  whiteSpace: 'nowrap',
  '& .MuiButton-startIcon': {
    marginLeft: selected ? '-8px' : 0
  },
  transition: theme.transitions.create(['width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  '&.nav-selected': {
    background: theme.palette.secondary.main
  },
  '& .nav-button-text': {
    opacity: 0,
  }, 
  '&[open] .nav-button-text': {
    transition: theme.transitions.create(['opacity'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.complex,
    }),
    opacity: 1
  }
}));
