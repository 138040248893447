import React from 'react';
import {styles} from './StickyTableLV.styles';
import {TableLV} from './TableLV';

/**
 * Standard table that sticks to top of container
 * with css: {position: 'sticky'}
 */
export const StickyTableLV = ({
  className, ...props
}) => {
  return (
    <TableLV
      className={[styles.container, className].join(' ')}
      {...props}
    />
  );
};
