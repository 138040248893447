import { PRODUCT, FEATURES } from '../constants/features';
import { USER_GROUPS } from '../constants/userGroups';

/**
 * Get the feature by the key
 * @param key Feature key
 * @returns Feature
 */
export const getFeatureByKey = (key) => {
  return FEATURES[key];
};

/**
 * Get the feature by a provided url path
 * @param path Current url path
 * @returns Feature with provided path
 */
export const getFeatureByPath = (path) => {
  return Object.values(FEATURES).find(f => f.path === path);
};

/**
 * Determine if a feature is allowed with the given user groups
 * @param feature product feature selected
 * @param group List of groups permission for a user
 * @returns Boolean if feature is allowed
 */
export const isFeatureAllowed = (feature = {}, group = []) => {
  if(!feature.allowedGroups) {
    return false;
  }
  return checkAllowedGroups(feature.allowedGroups, group);
};

/**
 * Get product by a feature's path
 * @param path Feature key to see which product it is in
 * @returns Product the feature is in
 */
export const getProductByFeaturePath = (path) => {
  const feature = getFeatureByPath(path);
  return getProductByFeatureKey(feature?.key);
};

/**
 * Get product by feature key
 * @param key Feature key to see which product it is in
 * @returns Product the feature is in
 */
export const getProductByFeatureKey = (key) => {
  return Object.values(PRODUCT).find(
    p => p.features.some(
      f => f.key === key
    )
  );
};

/**
 * Function to return available product features from supplied cognito user group
 * @param group List of groups permission for a user
 * @returns Map of all product features
 */
export const getProductFeatures = (groupList = []) => {
  return Object.values(PRODUCT).reduce((
    previousValue = {}, currentValue = {}
  ) => {

    // Allow "All" groups to represent admin for storybook
    // "Admin" gets stripped from url in storybook
    const group = groupList.map(g => {
      if(g === USER_GROUPS.All) {
        return USER_GROUPS.Admin_Group;
      }
      return g;
    });

    // Does this user have product access?
    const hasAccess = checkAllowedGroups(
      currentValue.allowedGroups,
      group
    );

    if(!hasAccess) {
      return {
        ...previousValue
      };
    }

    // Remove allowed groups
    const { allowedGroups, ...prod } = currentValue;
    prod.features = getAllowedFeatures(
      prod.features,
      group
    );

    return {
      ...previousValue,
      [prod.key]: prod
    };
  }, {});
};

const getAllowedFeatures = (
  features,
  group
) => {
  return features.map(f => {
    const hasAccess = checkAllowedGroups(
      f.allowedGroups,
      group
    );
    
    if(!hasAccess) {
      return undefined;
    }

    // Remove allowed groups
    const { allowedGroups, ...feat } = f;

    return feat;
  }).filter(f => f);
};

const checkAllowedGroups = (allowedGroups = [], group = []) => {
  return allowedGroups.some(g => {
    return group.includes(g);
  });
};
