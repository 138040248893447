export const ADMIN = {
  label: "Admin",
  value: "Admin_Group"
};

export const ADVANCED_USER = {
  label: "Advanced User",
  value: "Advanced_Group"
};

export const WAVECAST_USER = {
  label: "Wavecast User",
  value: "Wavecast_Group"
};

export const REGULAR_USER = {
  label: "Regular User",
  value: "Regular_Group"
};

export const TRIAL_USER = {
  label: "Trial User",
  value: "Trial_Group"
}

export const ROLES = {ADMIN, ADVANCED_USER, WAVECAST_USER, REGULAR_USER, TRIAL_USER};

export const isTrialRole = roles => !!(roles?.find(role => role?.endsWith(TRIAL_USER.value)));
export const isRegularRole = roles => !!(roles?.find(role => role?.endsWith(REGULAR_USER.value)));
export const isAdvancedRole = roles => !!(roles?.find(role => role?.endsWith(ADVANCED_USER.value)));
export const isAdminRole = roles => !!(roles?.find(role => role?.endsWith(ADMIN.value)));
