import React from 'react';
import {useSortBy, useTable} from 'react-table';
import {createHeaderColumnsFromData} from './createHeaderColumnsFromData';
import {TableCellLV} from './TableCellLV';
import {TableHeaderLV} from './TableHeaderLV';
import {styles} from './TableLV.styles';
import {useWindowResize} from '../../hooks/useWindowResize';

/**
 * Use React-Table to build us a data-grid from our dataset
 * Simple Example: https://codesandbox.io/s/github/tannerlinsley/react-table/tree/master/examples/basic
 * @param data Dataset to reflect in a table
 *
 * @param override {object} whose key represent data columns to customize
 *                 EXAMPLE: {
 *                   column1: {
 *                     name: 'Column 1 Custom Name' // Will display custom text in the table header for key 'column1'
 *                   }
 *                 }
 * @param override.key.name Custom text display in column header
 * @param override.key.component Add a custom component to every cell in this column
 * @param override.key.hidden Hides entire column when true
 * @param override.key.disableSorting Do not allow this column to sort
 * @param override.key.colSpan number of columns for header to span
 * @param override.key.align = 'center' align value in every column header and cell
 * @param override.key.headerClassName = className to add to <th className='headerClassName'/> element
 * @param override.key.isEditable = Adds 'editable' class to cell that clears all default paddings and margins
 *                                  so child component has full control over cell
 * @param override.key.headerStyles Custom React styles set to to <th/> in the column
 * @param override.key.style Custom React styles set to each <td/> in the column
 * @param override.key.percentWidth Sets the column width/max-width based on percent of total table width
 * @param override.key.separator {Boolean} Adds css to disable content/borders for column to appear like a divider
 * @param override.key.hideHeader {Boolean} Hides the <th/> header cell for cases like colSpan
 *
 * @param groups {array} Allow 2 rows of headers with one row stacked above the other
 *               REFERENCE: http://storybook.leavened.io/?path=/story/table--multiple-header-rows
 *               EXAMPLE: [{
 *                 name: 'Scenario1,'  // Header text to display
 *                 columns: [scenario1CostPer, scenario1Volume] // Column keys to put below Header display text
 *               }, {
 *                 name: 'Scenario2',
 *                 align: 'right', // Align group header cell
 *                 columns: [scenario2CostPer, scenario2Volume]
 *               }]
 * @param hasData {bool} will display "No Data" overlay if false
 * @param isLoading {bool} will display loading spinner overlay if true
 * @param headersOnly {bool} Will not display body content, only headers
 * @param rowsToHide {object} Hide full row of table with given column name as the key
 *                            and that row's column values to hide
 *                           EXAMPLE: {'columnName': 'variableName', 'rowValues': ['Total Spend', 'Holidays']}
 * @param className Add css class to the <table className='customClassName'/>
 * @param disableTableSort {bool} Disable sort of all table columns
 * @param setSortedData {function} Callback function to pass the current sorted rows from the table to parent component
 * @param hasBorder {bool} Add a common border around this table element
 * @return <table/> component based off of react-table
 */
export const TableLV = ({
                            data = [],
                            override,
                            hasData = true,
                            isLoading = false,
                            headersOnly = false,
                            rowsToHide = {},
                            className,
                            disableTableSort = false,
                            groups,
                            setSortedData = () => {
                            },
                            dataTest
                        }) => {
    const columns = React.useMemo(() => createHeaderColumnsFromData(data, groups), [data, groups]);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data: data
    }, useSortBy);

    // Get entire table width to table cells have a reference
    const tableRef = React.useRef();
    const [totalWidth, setTotalWidth] = React.useState();
    const {windowWidth} = useWindowResize();

    const finalOverride = React.useMemo(() => {
        return {
            id: {
                hidden: true
            },
            ...override
        }
    }, [override]);

    React.useEffect(() => {
        if (!tableRef.current) {
            return;
        }

        setTotalWidth(tableRef.current.clientWidth);
    }, [setTotalWidth, windowWidth]);

    // Display No Rows message
    if (!Array.isArray(rows) || rows.length === 0) {
        return noRowDataElem;
    }

    const loading = isLoading ? 'loading' : '';
    let statusCss = '';

    if (!hasData) {
        statusCss = 'no-data';
    }

    if (isLoading) {
        statusCss = loading;
    }

    const classes = [styles.table, statusCss, className].join(' ');
    setSortedData(rows);

    return (
        <table
            cellSpacing='0'
            cellPadding='0'
            className={classes}
            ref={tableRef}
            data-test={dataTest}
            {...getTableProps()}>
            <thead>
            {headerGroups.map(headerGroup => (
                <tr
                    {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, index) => {
                        // Draw Column Header
                        return TableHeaderLV({
                            column,
                            override: finalOverride,
                            groups,
                            index,
                            disableTableSort,
                            totalWidth
                        });
                    })}
                </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {headersOnly ? null : rows.map(
                (row, i) => {
                    let hide = false;
                    if (rowsToHide.rowValues?.length > 0 && rowsToHide.rowValues.includes(row.values[rowsToHide.columnName])) {
                        hide = true;
                    }
                    prepareRow(row);
                    return (
                        <tr
                            data-test={dataTest + '-row-' + i}
                            className={hide ? [styles.tableRow, 'hidden'].join(' ') : styles.tableRow}
                            {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                // Draw Table Cell
                                return TableCellLV({cell, override: finalOverride, totalWidth});
                            })}
                        </tr>
                    );
                }
            )}
            </tbody>
        </table>
    );
};

export const noRowDataElem = (<div>No Row Data</div>);

/**
 * Use optional dummy data to load into React-Table
 * Return table data, dummy data, or default[]
 */
export const parseOptionalTableData = (data, dummyData) => {
    const hasData = (Array.isArray(data) && data.length > 0);
    if (hasData) {
        return data;
    }

    const hasDummyData = (Array.isArray(dummyData)
        && dummyData.length > 0);

    if (!hasDummyData) {
        return [];
    }

    return dummyData;
};
