import { R1AutoComplete } from '@rainagency/rain-one-soggy-loaf';
import { FormRowLV } from '../../../../components/form/row/FormRowLV';

const KPI_TYPE_OPTIONS = ['Revenue', 'Units'];

export const KpiFields = ({
  disabled,
  selectedAnalysisType,
  kpiOptions,
  independentVariables,
  kpiType,
  kpi,
  kpiVariable
}) => {
  if (selectedAnalysisType === 'AdImpact MMM') {
    return (
      <FormRowLV expand={ true }>
        <R1AutoComplete
            name='kpiVariable'
            data-testid={"dependent-variable-dropdown"}
            disabled={ disabled }
            label='Dependent Variable'
            aria-label='Dependent Variable'
            placeholder='Select Dependent Variable'
            options={ independentVariables }
            isOptionEqualToValue= {(option, value) => !value.length || option.value === value.value}
            rules={{ required: true }}
            defaultValue={ kpiVariable } />
      </FormRowLV>
    );
  }

  return (
    <FormRowLV expand={ true }>
      <R1AutoComplete
        name='kpi'
        data-testid={"dependent-variable-dropdown"}
        disabled={ disabled }
        label='Dependent Variable'
        aria-label='Dependent Variable'
        placeholder='Select Dependent Variable'
        options={ kpiOptions }
        isOptionEqualToValue= { (option, value) => !value.length || option.value === value.value || option === value.label }
        rules={{ required: true }}
        defaultValue={ kpi } />
          
      <R1AutoComplete
        name='kpiType'
        data-testid={"dependent-variable-type-dropdown"}
        disabled={ disabled }
        label='Dependent Variable Type'
        aria-label='Dependent Variable Type'
        placeholder='Select Dependent Variable Type'
        options={ KPI_TYPE_OPTIONS }
        rules={{ required: true }}
        defaultValue={ kpiType } />
    </FormRowLV>
  );
}
