/**
 * Types of numeric value format to display to user
 * $, %, .00
 */
export const CHART_FORMAT = {
  PERCENT: 'PERCENT',
  CURRENCY: 'CURRENCY',
  INTEGER: 'INTEGER',
  INTEGER_CURRENCY: 'INTEGER_CURRENCY',
  DATE: 'DATE',
  HIDDEN: 'HIDDEN',
  STRING: 'STRING'
};
