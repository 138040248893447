import React from 'react';
import {useGet} from './useRest';
import {urlBuilders} from '../js/constants';

export const useGetMcaModels = (selectedMcaRunId) => {
  let model = {};
  const [{data: modelList=[], loading}, fetchModels] = useGet({
    url: urlBuilders.getSelectedModel(selectedMcaRunId),
    manual: true
  });

  React.useEffect(() => {
    if(!selectedMcaRunId) {
      return;
    }
    fetchModels();
  },[selectedMcaRunId, fetchModels]);


  if(Array.isArray(modelList) && modelList.length > 0) {
    model = Object.assign({},modelList[0]);
    model.pval = modelList.reduce((prev, curr) => Number(prev.pval) > Number(curr.pval) ? prev : curr, 0).pval;
  }

  return {
    model,
    modelList,
    loading,
    fetchModels
  };
};
