import { R1Button } from '@rainagency/rain-one-soggy-loaf';
import {
  CheckCircleOutline,
  AddCircleOutline,
  HistoryToggleOff,
  ErrorOutline,
  DoNotDisturbOffOutlined,
  HelpOutlineOutlined,
} from '@mui/icons-material';
import { Tooltip } from '@mui/material';

import _ from 'lodash';

/**
 * Display a button in a MUI Grid Pro status cell
 * Button is colored based on status
 *
 * @param id Unique row id used for classname selector
 * @param status Status value from back-end
 *               Update STATUS_VALUE_MAP to match status value with properties
 * @param name label for the row used for classname selector
 * @param tooltip Optional tooltip on hover
 * @param onClick Button click handler
 * @param customStyles Extend R1Button styles
 * @param dataTest Test Attribute
 */
export const GridStatusCell = ({
  id,
  status,
  name,
  tooltip = '',
  onClick,
  customStyles = {},
  dataTest
}) => {
  if (!status || !id) {
    return (<div data-test={'no-status'} />);
  }

  const statusProperties = STATUS_VALUE_MAP[status] || STATUS_PROPERTIES.UNKNOWN;
  const uniqueClassname = `status-${id}-${name}-${status}`;
  const classNames = ['status-cell', uniqueClassname].join(' ');

  return (
    <Tooltip title={tooltip}>
      <div
        className='status-button-wrapper-lv'
        style={{
          width: '100%'
        }}>

        <R1Button
          data-test={dataTest}
          startIcon={statusProperties.icon}
          className={classNames}
          onClick={onClick}
          customStyles={{
            height: '32px',
            width: '100%',
            justifyContent: 'flex-start',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: statusProperties.color,
              opacity: '1'
            },
            backgroundColor: statusProperties.color,
            ...statusProperties.styles,
            ...customStyles
          }}>
          {statusProperties.label}
        </R1Button>

      </div>
    </Tooltip>
  );
};

const STATUS_PROPERTIES = {
  ERROR: {
    label: 'Error',
    color: '#F44336',
    icon: <ErrorOutline />,
    styles: {}
  },
  SELECT_MODEL: {
    label: 'Select Model',
    color: '#2196F3',
    icon: <AddCircleOutline />,
    styles: {}
  },
  SUBMITTED: {
    label: 'Submitted',
    color: '#FF9800',
    icon: <HistoryToggleOff />,
    styles: {
      animationIterationCount: 'infinite',
      animationDuration: '3s',
      animationName: 'pulsate',
      cursor: 'default'
    }
  },
  QUEUED: {
    label: 'Queued',
    color: '#FF9800',
    icon: <HistoryToggleOff />,
    styles: {
      animationIterationCount: 'infinite',
      animationDuration: '3s',
      animationName: 'pulsate',
      cursor: 'default'
    }
  },
  PROCESSING: {
    label: 'Processing',
    color: '#FF9800',
    icon: <HistoryToggleOff />,
    styles: {
      animationIterationCount: 'infinite',
      animationDuration: '3s',
      animationName: 'pulsate',
      cursor: 'default'
    }
  },
  FINALIZING: {
    label: 'Finalizing',
    color: '#FF9800',
    icon: <HistoryToggleOff />,
    styles: {
      animationIterationCount: 'infinite',
      animationDuration: '3s',
      animationName: 'pulsate',
      cursor: 'auto'
    }
  },
  COMPLETE: {
    label: 'Final',
    color: '#4CAF50',
    icon: <CheckCircleOutline />,
    styles: {}
  },
  COMPLETE_V2: {
    label: 'Complete',
    color: '#4CAF50',
    icon: <CheckCircleOutline />,
    styles: {}
  },
  FILE_SUCCESS: {
    label: 'Success',
    color: '#4CAF50',
    icon: <CheckCircleOutline />,
    styles: {}
  },
  FILE_PENDING: {
    label: 'Pending',
    color: '#2196F3',
    icon: <AddCircleOutline />,
    styles: {}
  },
  SCORING_COMPLETE: {
    label: 'Complete',
    color: '#4CAF50',
    icon: <CheckCircleOutline />,
    styles: {}
  },
  CANCELED: {
    label: 'Canceled',
    color: '#ACB8B8',
    icon: <DoNotDisturbOffOutlined />,
    styles: {
      cursor: 'auto',
      // Overwrite hover to disable
      '&:hover': {
        backgroundColor: '#ACB8B8',
        opacity: '1'
      },
    }
  },
  ARCHIVED: {
    label: 'Archived',
    color: '#141414',
    icon: <DoNotDisturbOffOutlined />,
    styles: {
      cursor: 'auto',
      // Overwrite hover to disable
      '&:hover': {
        backgroundColor: '#ACB8B8',
        opacity: '1'
      },
    }
  },
  FAILED: {
    label: 'Failed',
    color: '#ACB8B8',
    icon: <DoNotDisturbOffOutlined />,
    styles: {
      cursor: 'auto',
      // Overwrite hover to disable
      '&:hover': {
        backgroundColor: '#ACB8B8',
        opacity: '1'
      },
    }
  },
  UNKNOWN: {
    label: 'Unknown',
    color: '#ACB8B8',
    icon: <HelpOutlineOutlined />,
    styles: {
      cursor: 'auto',
      // Overwrite hover to disable
      '&:hover': {
        backgroundColor: '#ACB8B8',
        opacity: '1'
      },
    }
  },
  MAGNIFAI_TUNING: {
    label: 'Tuning Model',
    color: '#FF9800',
    icon: <HistoryToggleOff />,
    styles: {
      animationIterationCount: 'infinite',
      animationDuration: '3s',
      animationName: 'pulsate',
      cursor: 'default'
    }
  },
  MAGNIFAI_ERROR_MODEL: {
    label: 'Error Tuning Model',
    color: '#F44336',
    icon: <ErrorOutline />,
    styles: {}
  },
};

/**
 * Map value from backend to Status Button Properties
 */
export const STATUS_VALUE_MAP = {
  Errors: STATUS_PROPERTIES.ERROR,
  Error: STATUS_PROPERTIES.ERROR,
  ERROR: STATUS_PROPERTIES.ERROR,
  'Select Model': STATUS_PROPERTIES.SELECT_MODEL,
  RUN_COMPLETE: STATUS_PROPERTIES.SELECT_MODEL,
  Completed: STATUS_PROPERTIES.COMPLETE,
  COMPLETE: STATUS_PROPERTIES.SCORING_COMPLETE,
  SUCCESS: STATUS_PROPERTIES.COMPLETE,
  FINAL: STATUS_PROPERTIES.COMPLETE,
  FINALIZING: STATUS_PROPERTIES.FINALIZING,
  Processing: STATUS_PROPERTIES.PROCESSING,
  RUNNING: STATUS_PROPERTIES.PROCESSING,
  Canceled: STATUS_PROPERTIES.CANCELED,
  CANCELLED: STATUS_PROPERTIES.CANCELED,
  Unknown: STATUS_PROPERTIES.UNKNOWN,
  'Scoring Errors': STATUS_PROPERTIES.ERROR,
  Initiated: STATUS_PROPERTIES.PROCESSING,
  PENDING: STATUS_PROPERTIES.PROCESSING,
  Finalizing: STATUS_PROPERTIES.FINALIZING,
  WARNING: STATUS_PROPERTIES.ERROR,
  FAILED: STATUS_PROPERTIES.FAILED,
  MAGNIFAI_TUNING_MODEL: STATUS_PROPERTIES.MAGNIFAI_TUNING,
  MAGNIFAI_ERROR_MODEL: STATUS_PROPERTIES.MAGNIFAI_ERROR_MODEL,
  SUBMITTED: STATUS_PROPERTIES.SUBMITTED,
  QUEUED: STATUS_PROPERTIES.QUEUED,
  STARTING: STATUS_PROPERTIES.PROCESSING,
  TRANSFORMING: STATUS_PROPERTIES.PROCESSING,
  BATCHING: STATUS_PROPERTIES.PROCESSING,
  PROCESSING: STATUS_PROPERTIES.PROCESSING,

  // Scoring Status
  SCORING_FAILED: STATUS_PROPERTIES.ERROR,
  SCORING_COMPLETE: STATUS_PROPERTIES.SCORING_COMPLETE,

  // MMM Status
  ARCHIVED: STATUS_PROPERTIES.ARCHIVED,

  // File Status
  FILE_SUCCESS: STATUS_PROPERTIES.FILE_SUCCESS,
  FILE_PENDING: STATUS_PROPERTIES.FILE_PENDING,
  FILE_ERROR: STATUS_PROPERTIES.ERROR,

  // Ad Impact
  AD_IMPACT_COMPLETED: STATUS_PROPERTIES.COMPLETE_V2,
  AD_IMPACT_ERRORS: STATUS_PROPERTIES.ERROR,
  AD_IMPACT_INITIATED: STATUS_PROPERTIES.PROCESSING
};
