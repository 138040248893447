import {css} from 'emotion';
import downloadIcon from '../../assets/download.svg';

export const styles = {
  dropdown: css`
    .LV__control {
      border-bottom: none;
    }

    .LV__menu {
      width: 200px;
    }
  `,
  option: css`
    display: flex;
    align-items: center;
    width: 190px;

    .label {
      margin-left: 12px;
    }
  `,
  indicator: css`
    display: flex;
    align-items: center;
    border-width: 1px !important;
    border-style: solid !important;
    border-color: rgba(126, 146, 146, 0.25) !important;
    padding: 2px 16px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 5px 0px !important;
    cursor: pointer;

    .download-icon {
      background-image: url('${downloadIcon}');
      height: 24px;
      width: 26px;
    }

    .label {
      margin-left: 12px;
      font-weight: 500;
      line-height: 1.75;
      text-transform: uppercase;
      font-size: 12px;
      color: rgb(55, 79, 99);
      width: 130px;
      letter-spacing: 0.02857em;
      text-align: center;
    }
  `
};
