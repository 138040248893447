/**
 * Utility junk drawer for grid function
 */

import moment from 'moment';
import {capitalCase} from "change-case";

/**
 * Formats a date with no timezone
 * @param dateStr Date string with no timezone, format: YYYY-MM-DD
 *                EX 2019-09-30
 */
export const formatDateWithNoTimezone = (dateStr) => {
  return moment(
    new Date(dateStr + 'T00:00:00')
  ).format('MM/DD/YYYY');
};

/**
 * Formats a date with timezone
 * @param dateStr Date string with timezone
 *                EXAMPLE: 2022-04-26T23:37:39.444
 */
export const formatDate = (
  dateStr,
  format = 'MM/DD/YYYY hh:mmA'
) => {
  return moment(
    new Date(dateStr)
  ).format(format);
};

/**
 * Formats a date with timezone offset
 * @param dateStr Date string with timezone
 *                EXAMPLE: 2022-04-26T23:37:39.444
 * @param offset  Date object offset used for calculating local time 
 * @param format  String that represents how the incoming date string should be parsed 
 */
export const formatDateWithTimezoneOffset = (
  dateStr,
  offset = 0,
  format = 'MM/DD/YYYY hh:mmA'
) => {
  return moment(
    new Date(dateStr)
  ).utcOffset(0-offset).format(format);
};

/**
 * Formats user names to capital case and extracts initials
 * @param value cell contents with user name
 */
export const formatUserName = (
  value={ id: '', api: {getRow: () => {}} }
) => {
  const row = value.api.getRow(value.id);

  return formatUserNameV2(row);
};

export const formatUserNameV2 = ({
    firstName = '',
    lastName = ''
                                 } = {}) => {

  const first = firstName && firstName.length ? capitalCase(firstName) : '';
  const last = lastName && lastName.length ? capitalCase(lastName) : '';

  const  initials = first.charAt(0) + last.charAt(0);

  return {
    first,
    last,
    initials
  };
};