export const AD_INSTANCE = 'adInstance';
export const TERRESTIAL_RADIO = 'terrestrialRadio';


export const FILE_TYPE_MAP = {
  KPI: 'kpi',
  MEDIA_VEHICLE: 'mediaVehicle',
  MEDIA_GROUPING: 'mediaGrouping',
  PLACEMENT_CUSTOM: 'placementCustom',
  AD_INSTANCE: AD_INSTANCE,
  DIGITAL_VIDEO: 'digitalVideo',
  OTT: 'ott',
  FACEBOOK: 'facebook',
  TERRESTIAL_RADIO: 'terrestrialRadio',
  DIGITAL_AUDIO: 'digitalAudio',
  SCORING_MEDIA_VEHICLE: 'scoringMediaVehicle',
  SCORING_KPI: 'scoringKpi'
};

export const FILE_TYPES = [
    {
        value: FILE_TYPE_MAP.KPI,
        label: 'Dependent Variable',
        template: 'KPI template.csv'
    },
    {
        value: FILE_TYPE_MAP.MEDIA_VEHICLE,
        label: 'Business Drivers',
        template: 'Business Drivers template.csv'
    },
    {
        value: FILE_TYPE_MAP.MEDIA_GROUPING,
        label: 'Analysis Configuration MMM',
        template: 'Analysis Configuration MMM template.csv'
    },
    {
        value: FILE_TYPE_MAP.PLACEMENT_CUSTOM,
        label: 'Linear TV Custom AdImpact',
        template: 'Custom AdImpact template.csv'
    },
    {
        value: FILE_TYPE_MAP.AD_INSTANCE,
        label: 'Linear TV Ad Instance AdImpact',
        template: 'Ad Instance AdImpact template.csv'
    },
    {
        value: FILE_TYPE_MAP.DIGITAL_VIDEO,
        label: 'Digital Video AdImpact',
        template: 'Custom AdImpact template.csv'
    },
    {
        value: FILE_TYPE_MAP.OTT,
        label: 'OTT AdImpact',
        template: 'Custom AdImpact template.csv'
    },
    {
        value: FILE_TYPE_MAP.FACEBOOK,
        label: 'Social Media AdImpact',
        template: 'Custom AdImpact template.csv'
    },
    {
        value: FILE_TYPE_MAP.TERRESTIAL_RADIO,
        label: 'Terrestrial Radio AdImpact',
        template: 'Custom AdImpact template.csv'
    },
    {
        value: FILE_TYPE_MAP.DIGITAL_AUDIO,
        label: 'Digital Audio AdImpact',
        template: 'Custom AdImpact template.csv'
    },
    {
        value: FILE_TYPE_MAP.SCORING_MEDIA_VEHICLE,
        label: 'Business Drivers for Scoring Model',
        template: 'Business Drivers template.csv'
    }
];
