import {css} from 'emotion';

export const styles = {
  container: css`
    width: 100%;
    
    .optimizer-form-button-row {
      width: inherit;
      display: flex;
      gap: 24px;
      margin-top: 24px;
    }

    .optimizer-selection {

      background: #F2F2F2;
      border-radius: 8px;

      .LV__value-container {
        height: 28px;
        font-size: 14px;
        padding-left: 4px;
      }

      .LV__control {
        border-bottom: none;
      }

      .LV__control--is-focused {
        .LV__clear-indicator {
          path {
            fill: #C0C1C0;
          }
        }
      }
    }

  `
};
