import {css} from 'emotion';

export const styles = {
  tabContent: css`
    .tab-content {
      overflow-y: auto;
    }
  `,
  runNameContainer: css`
    display: flex;
    min-width: 136px;
  `,
  runName: css`
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;     
  `,
  output: css`
    width: 60px;
    text-align: center;
    padding: 0;
  `,
  editIconContainer: css`
    float: right;
    opacity: 0;
    :hover {
      opacity: 1;
      }
    }
  `
};
