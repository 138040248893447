import React from 'react';
import {
  R1ActionIcon,
} from '@rainagency/rain-one-soggy-loaf';
import { urlBuilders } from '../../../../js/constants';
import { useDelete } from '../../../../hooks/useRest';
import { useLoggedInUser } from '../../../../hooks/useLoggedInUser';
import { useSnackbarLV } from '../../../../components/snackbar/useSnackbarLV';
import { USER_GROUPS } from '../../../../constants/userGroups';

/**
 * Delete a run
 * Displays on Data Grid Action Popover
 */
export const DeleteRunActionIcon = ({
  cell,
  closePopover,
  getRuns,
  hide
}) => {

  const runName = cell.row.name;
  let isTrial = false;

  const [{loading, response, error}, deleteMMMRun] = useDelete({
    url: urlBuilders.deleteMcaRun(cell.row.id),
  });

  // Do not allow trial users to delete runs
  const { group } = useLoggedInUser() || {};
  if(group.includes(USER_GROUPS.Company_Trial_Group)) {
    isTrial = true;
  }

  const {
    enqueueSnackbar,
    enqueueCloseSnackbarLV,
  } = useSnackbarLV();

  // refresh table when response is returned
  React.useEffect(() => {
    if (!response) {
      return;
    }

    if(response.status === 202) {
      enqueueSnackbar(
        `Successfully deleted analysis: ${cell.row.name}`,
        {
          variant: 'success'
        }
      );
    }

    closePopover();
    getRuns();
  }, [response]);

  // Display error
  React.useEffect(() => {
    if(!error) {
      return;
    }

    enqueueCloseSnackbarLV(
      `Error deleting analysis: ${cell.row.name}, please contact Technical Support`,
      {
        variant: 'error',
        persist: false,
        autoHideDuration: 10000
      }
    );

    closePopover();

  }, [error]);

  return (
    <R1ActionIcon
      iconType='Delete'
      label='Delete Analysis'
      hide={(hide || isTrial)}
      onDialogConfirm={deleteMMMRun}
      DialogProps={{
        title: `Delete ${runName}?`,
        description: `Are you sure you want to delete ${runName}? All data will be deleted forever.`,
        cancelLabel: 'Cancel',
        confirmLabel: 'Delete Forever',
        onCancel: closePopover,
        customStyles: {
          cursor: loading ? 'wait' : 'pointer'
        }
      }}
    />
  );


};
