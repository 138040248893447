import React from 'react';
import {Controller} from 'react-hook-form';
import PropTypes from 'prop-types';
import Select, {components} from 'react-select';
import {customStyles, customTheme, svgIcon} from './SelectLV.styles';
import {FormElementWrapper} from '../wrapper/FormElementWrapper';
import {getSelectAllMenu} from './getSelectAllMenu';
import Tooltip from '@mui/material/Tooltip';

/**
 * React-Select is a robust drop-down with features like search and multi-select
 * https://github.com/JedWatson/react-select
 * Extensive react-select api options to customize behavior:
 * https://react-select.com/props#select-props
 */
export default function SelectLV({isHide, ...props}) {
  if(isHide) {
    return null;
  }

  let isDisabled = false;
  if(props.isLoading) {
    isDisabled = true;
  }

  return(
    <FormElementWrapper {...props}>
      <SelectElement
        isDisabled={isDisabled}
        {...props} />
    </FormElementWrapper>
  );
}

/**
 * Return our Select Element
 * Wrap in a React Hook Form Controller if using React Hook Form
 */
function SelectElement(props) {
  const DownArrow = () => (
    <svg id='downArrow' className={svgIcon(props).svg}
         focusable="false"
         viewBox="0 0 24 24">
      <path d="M7 10l5 5 5-5z"></path>
    </svg>
  );

  // React-Select Dropdown Override
  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        {props.isDisabled ? <div/> : <DownArrow/>}
      </components.DropdownIndicator>
    );
  };

  const SelectElem = elemProps => {
    const customThemeProp = elemProps.theme || customTheme(elemProps);
    const customStyleProp = elemProps.styles || customStyles(elemProps);
    const isClearable = elemProps.isClearable || true;

    // Populate Custom drop-down overrides
    let customComponents = {DropdownIndicator};
    if(elemProps.isMulti && elemProps.hasSelectAll) {
      customComponents.MenuList = getSelectAllMenu(elemProps);
    }

    return (
      <div data-test={elemProps.dataTest}>
        <Select
          components={customComponents}
          name={elemProps.name}
          classNamePrefix={'LV'}
          styles={customStyleProp}
          theme={customThemeProp}
          isClearable={isClearable}
          {...elemProps}
        />
      </div>
    );
  };

  if(!props.control) {
    return <SelectElem {...props} />;
  }

  return (
    <Controller
      as={<SelectElem />}
      name={props.name}
      {...props}
      onChange={reactHookOnChange(props.onChange)}
    />
  );
}

/**
 * When react-select is a Controller for React-Hook-Form, 
 * we must return the "selected" property for proper React-Select behavior
 * This is a wrapper function that returns "selected" so that our components don't have to
 */
const reactHookOnChange = (parentOnChange) => {
  if(typeof parentOnChange !== 'function') {
    return undefined;
  }

  return ([selected]) => {
    parentOnChange(selected);
    return selected;
  };
};

/**
 * Utility to populate options
 */
export const mapToOptions = (list, valueField, labelField, includeTooltips = false) => {
  if(typeof list === 'string' && list.length > 0) {
    return {
      label: list,
      value: list
    };
  }

  if(!Array.isArray(list)) {
    return [];
  }

  return list.map(item => {
    if(typeof item === 'string' || typeof item === 'number') {
      return {
        label: item,
        value: item
      };
    }

    if(valueField && includeTooltips) {
      const labelValue = item[labelField] || item[valueField];
      return {
        label: <Tooltip title={labelValue} placement={'bottom-start'}>
                  <span>{labelValue}</span>
               </Tooltip>,
        value: item[valueField]
      };
    } else if(valueField) {
      return {
        label: item[labelField] || item[valueField],
        value: item[valueField]
      };
    }

    return {
      label: '',
      value: ''
    } ;
  });
};

export const mapToOptionsWithTooltip = (list, valueField, labelField) => {
  return mapToOptions(list, valueField, labelField, true);
};

SelectLV.propTypes = {
  /**
   * Field name to be reflected in form object
   */
  name: PropTypes.string,
  /**
   * Label to display for the text input element
   */
  label: PropTypes.string,
  /**
   * Description to display below element
   */
  description: PropTypes.string,
  /**
   * Array of all the selectable values
   */
  options: PropTypes.array,
  /**
   * Placeholder text
   */
  placeholder: PropTypes.string,
  /**
   * Selected option value
   */  
  defaultValue: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.array,
  ]),
  /**
   * Disables the field and shows default indicator of 'Loading' status
   */
  isLoading: PropTypes.bool,
  /**
   * Creates a "Select All" option that selects all other options
   */
  hasSelectAll: PropTypes.bool,
  /**
   * React Hook Form integration
   * https://react-hook-form.com/api/#Controller
   */
  control: PropTypes.object,
  /**
   * Registration to form, and validation of form element
   * https://react-hook-form.com/api/#Controller
   */
  rules: PropTypes.object,
  /**
   * All form errors from parent form
   * https://react-hook-form.com/api#errors
   */
  errors: PropTypes.object,
  /**
   * Override standard width of element
   * Clears width css prop if width = 0
   */
  width: PropTypes.number
};

export {SelectLV};
