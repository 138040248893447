import React from 'react';
import {styles} from './TableLV.styles';
import _ from 'lodash';

/**
 * Standard Table Cell used with React-Table
 * Displays cell content in a table cell, or passes cell info to a 
 * custom component in the 'override' object
 */
export const TableHeaderLV = ({column, override, groups, index, disableTableSort=false, totalWidth}) =>  {
  let rowSpan = 1;
  let colSpan = 1;

  // Group child columns with no parent will not be displayed
  if(column && !column.placeholderOf && column.isSingleColumnGroup) {
    return null;
  }

  // Group columns with no children will display the placeholder column which allows sorting
  if(column && column.placeholderOf && !column.isSingleColumnGroup) {
    column = column.placeholderOf;
  }

  // Group header cells can not sort
  if(column && column.isMultiColumnGroup) {
    disableTableSort = true;
    colSpan = column.colSpan;
  }

  const content = getContent(column, override, disableTableSort);
  const className = getClassNames(column, override, groups, disableTableSort);
  const headerProps = getHeaderProps(column, override, disableTableSort, totalWidth) || {};

  // Hide column header in cases of colspan
  if(headerProps.hideHeader) {
    return null;
  }

  // Ensure group column with no children spans 2 rows
  if(column && column.isSingleColumnGroup) {
    rowSpan = 2;
  }

  return (<th
            key={index}
            rowSpan={rowSpan}
            colSpan={colSpan}
            className={className}
            {...headerProps}>

            <div className='header-cell-container'>
              <div className='header-cell-text'>
                {content}
              </div>

              <div className='column-sort'>
                <SortSVG/>
              </div>
            </div>
          </th>);
};

// Use Custom Component to render this header
export const getContent = (column, override) => {
  let content = '';
  if(!column) {
    return content;
  }

  // Standard Column Render
  if(column.render) {
    content = column.render('Header');
  }

  // Group headers have a different display
  if(column.isMultiColumnGroup && column.headerGroupDisplay) {
    content = column.headerGroupDisplay;
  }

  // We have a cell override after this check
  if(!override || !override[column.id]) {
    return content;
  }

  const customOverride = override[column.id];
  
  // Return no content if this is a separator column
  if(customOverride.separator) {
    return null;
  }

  // Display override name
  if(customOverride.name) {
    return customOverride.name;
  }

  return content;
};

// Header properties and overrides
const getHeaderProps = (column, override, disableTableSort, totalWidth) => {
  if(!column) {
    return {};
  }

  let headerProps = {
    style: {}
  };
  const hasOverride = (override && override[column.id]);
  let defaultSortToggle = column.getHeaderProps(column.getSortByToggleProps());
  if(disableTableSort) {
    defaultSortToggle = {};
  }

  // Exit if no overrides
  if(!hasOverride) {
    return defaultSortToggle;
  }

  const customOverride = override[column.id] || {};

  // Merge Column Headers
  if(customOverride.colSpan) {
    headerProps = {...headerProps, 'colSpan': customOverride.colSpan};
  }

  // Add column sort if not explicity disabled
  if(!customOverride.disableSorting) {
    headerProps = {...headerProps, ...defaultSortToggle};
  }

  const percentWidth = Number(customOverride.percentWidth);
  if(totalWidth && !isNaN(percentWidth)) {
    headerProps.style.width = (percentWidth / 100) * totalWidth;
    headerProps.style.maxWidth = headerProps.style.width;
  }

  // Hide Header for cases such as colspan
  if(customOverride.hideHeader) {
    headerProps.hideHeader = true;
  }

  // Add standard React custom style to <th/>
  if(typeof customOverride.headerStyle === 'object') {
    headerProps.style = {
      ...headerProps.style,
      ...customOverride.headerStyle,
    };
  }

  return headerProps;
};

// Get css for table cell and append possible overrides
export const getClassNames = (column={}, override, groups, disableTableSort) => {
  let classNames = [styles.tableHeaderCell];

  // This is a top group header cell
  if(column.isMultiColumnGroup) {
    classNames.push('group-top');
  }

  // Add alignment to top group header cell
  if(column.isMultiColumnGroup && column.groupAlign) {
    classNames.push(column.groupAlign);
  }

  // Header group will not be in override, but name should contain "Separator"
  if(getIsSeparatorColumn(column)) {
    classNames.push('separator');
  }

  // Bottom group header cell 
  if(Array.isArray(groups) && getLeadingGroupColumns(groups).includes(column.id)) {
    classNames.push('group-base');
  }

  if(!disableTableSort) {
    classNames.push('sort');
  }

  if(column && column.isSorted) {
    classNames.push('is-sorted');
  }

  // We have a cell override after this check
  if(!override || !override[column.id]) {
    return classNames.join(' ');
  }

  const customOverride = override[column.id];
  classNames.push(customOverride.headerClassName);

  // Center column
  if(customOverride.align) {
    classNames.push(customOverride.align);
  }

  // Separator css to display a divider
  if(customOverride.separator) {
    classNames.push('separator');
  }

  // Hide Column Header and Column cells
  if(customOverride.hidden) {
    classNames.push('hidden');
    column.isVisible = false;
  }

  // Remove sort class if disabled in override or this column is a separator
  if(customOverride.disableSorting || customOverride.separator) {
    classNames = classNames.filter(clss => clss !== 'sort');
  }

  return classNames.join(' ');
};

/**
 * Gets the first column within our group columns
 * This is used to add a css border to the first column in a group
 */
const getLeadingGroupColumns = (groups) => {
  if(!Array.isArray(groups)) {
    return [];
  }

  return groups
    .filter(g => typeof g === 'object')
    .filter(g => Array.isArray(g.columns))
    .map(g => g.columns[0]);
};

/**
 * If columnid contains "Separator" is is a separator column
 */
const getIsSeparatorColumn = (column={}) => {
  const id = _.get(column, 'id', '');
  return id.toLowerCase().includes('separator');
};

const SortSVG = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd"
          clipRule="evenodd"
          d="M4.87994 5.12083L7.20494 2.79583C7.36327 2.63749 7.62994 2.63749 7.78827 2.79583L10.1133 5.12083C10.3799 5.37916 10.1966 5.82916 9.82161 5.82916H8.32994V10.8375C8.32994 11.2958 7.95494 11.6708 7.49661 11.6708C7.03827 11.6708 6.66327 11.2958 6.66327 10.8375V5.82916H5.17161C4.79661 5.82916 4.61327 5.37916 4.87994 5.12083ZM13.3299 9.17083V14.1792H14.8299C15.1966 14.1792 15.3883 14.6292 15.1216 14.8875L12.7966 17.2042C12.6299 17.3625 12.3716 17.3625 12.2049 17.2042L9.87994 14.8875C9.61327 14.6292 9.79661 14.1792 10.1716 14.1792H11.6633V9.17083C11.6633 8.7125 12.0383 8.3375 12.4966 8.3375C12.9549 8.3375 13.3299 8.7125 13.3299 9.17083Z"
          fill="black"
          fillOpacity="0.54"/>
  </svg>
);
