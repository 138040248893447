import {css} from 'emotion';

export const styles = {
  tableContainer: css`
    overflow-y: scroll;
    td {
      height: 74px;
    }

    // Keep loading spinner at top of page on large table
    .loading::after {
      top: 10vmin;
    }
  `,
  indexCells: css`
    height: 76px;
    text-align: center;
    min-width: 36px;
  `,
  email: css`
    max-width: 300px;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;     
  `,
  editIcon: css`
    cursor: pointer;
    vertical-align: middle;
    min-width: 50px;
  `,
  deleteIcon: css`
    cursor: pointer;
    vertical-align: middle;
    padding-right: 12px;
    padding-left: 12px;
    width: 50px;
  `,
  checkIcon: css`
    text-align: center;
    vertical-align: middle;
    min-width: 120px;
  `,
};
