// actions
export const CHANGE_EDIT_STEP = `app/edit/change-step`;

// Constants
export const EDIT_FORM = 1;
export const EDIT_IN_PROGRESS = 2;
export const EDIT_COMPLETE = 3;
export const DELETE_COMPLETE = 4;

// selectors
const ID = 'edit';
const local = (state) => state['mca'][ID];
export const getEditStep = (state) => local(state).step;

export const initialState = {
    step: EDIT_FORM
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_EDIT_STEP:
            return Object.assign({}, state, {step: action.step});
        default:
            return state;
    }
}

// Action Creaters
export function changeEditStep(step) {
    return {
        type: CHANGE_EDIT_STEP,
        step
    };
}
