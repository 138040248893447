import React from 'react';
import {IconLV} from './IconLV';
import editIcon from '../../../assets/edit_24px.svg';
import PropTypes from 'prop-types';

export default function EditCell({cell, onClick, dataTest}) { 
  return(
    <IconLV
      cell={cell}
      onClick={onClick}
      dataTest={dataTest}
      src={editIcon}/>
  );
}
export {EditCell}

EditCell.propTypes = {
  /**
   * React-Table cell object
   */
  cell: PropTypes.object,
  /**
   * Handle onClick and add cell value to click args
   */
  onClick: PropTypes.func,
};
