import DownloadCSV from './download-csv';
import JSZip from 'jszip';
import {saveAs} from 'file-saver';
import * as XLSX from 'xlsx';
import moment from "moment";

/**
 * Factory to create multiple CSV Download objects
 */
class DownloadCsvFactory {
    downloads = {};
    clientName = '';
    campaignName = '';
    runName = '';

    /**
     * Add properties that are shared by all downloads
     * @param clientName Client Name
     * @param campaignName Campaign Name
     * @param runName Name of run
     */
    setReportProperties(clientName = undefined, campaignName = undefined, runName = undefined) {
        this.clientName = clientName;
        this.campaignName = campaignName;
        this.runName = runName;
    }

    /**
     * Add all report data on initialize
     * @param key What id to name the location of the downloadCsv
     * @param jsonReport JSON object used for graph
     * @param fileName Name of file
     * @return DownloadCSV object instance
     */
    createDownload(key, jsonReport, fileName) {
        // No action if report is not present
        if (!Array.isArray(jsonReport) || !jsonReport.length) {
            return {};
        }

        if(!fileName) {
            return {};
        }

        this.downloads[key] = new DownloadCSV(
            jsonReport,
            fileName,
            this.clientName,
            this.campaignName,
            this.runName
        );
        return this.downloads[key];
    }

    /**
     * Get a previous created downloadCsv by key
     * @param key identifier for downloadCsv object
     * @return downloadCsv object by key
     */
    getDownload(key) {
        if (!this.downloads[key]) {
            const errMsg = `Report for ${key} is not available for download`;
            throw new Error(errMsg);
        }
        return this.downloads[key];
    }

    /**
     * Download a set of CSVs into a zip file
     */
    downloadZip(fileName, reportKeys) {
        const zip = new JSZip();
        reportKeys.forEach((key) => {
            const download = this.getDownload(key);
            zip.file(download.fileName, download.reportToCSV());
        });

        // Trigger browser zip download
        zip.generateAsync({type: 'blob'}).then((content) => {
            saveAs(content, fileName);
        });
    }

    /**
     * Download JSON object from graph as Excel workbook
     * @param jsonReport JSON object used for graph
     * @param fileName Name of file
     */
    downloadExcel(jsonReport, fileName) {
        // No action if report is not present
        if (!Array.isArray(jsonReport) || !jsonReport.length) {
            return {};
        }

        if(!fileName) {
            return {};
        }

        const headers = Object.keys(jsonReport[0]);
        let formattedDates = jsonReport;
        if(headers.includes('date')){
            formattedDates = jsonReport.map(({date, ...v}) => ({
                  date: moment(date, "YYYY-MM-DD").format("M/D/YYYY"),
                  ...v
            }));
        }

        const sheet = XLSX.utils.json_to_sheet(formattedDates);
        const book = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(book, sheet);
        XLSX.writeFile(book, fileName);
    }
}

export default new DownloadCsvFactory();
