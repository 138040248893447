import { VALIDATIONS } from '@leavened/leavened-library-constants';

export const VALIDATION_OPTIONS_MAP = {
  ONE_OR_ZERO: {
    label: 'One(1) or Zero(0)',
    value: VALIDATIONS.ONE_OR_ZERO.key,
  },
  BOOLEAN: {
    label: 'True/False or 1/0',
    value: VALIDATIONS.BOOLEAN.key,
  },
  NUMBER: {
    label: 'Number',
    value: VALIDATIONS.NUMERIC.key
  },
  POSITIVE_NUMBER:   {
    label: 'Positive Number',
    value: VALIDATIONS.POSITIVE_NUMBER.key
  },
  INTEGER: {
    label: 'Integer',
    value: VALIDATIONS.INTEGER.key
  },
  POSITIVE_INTEGER:   {
    label: 'Positive Integer',
    value: VALIDATIONS.POSITIVE_INTEGER.key
  },
  TEXT: {
    label: 'Text',
    value: VALIDATIONS.STRING_REQUIRED.key
  },
  TEXT_NO_SPECIAL: {
    label: 'Text - No Special Characters',
    value: [
      VALIDATIONS.STRING_REQUIRED.key,
      VALIDATIONS.INVALID_CHARACTERS.key
    ].join(',')
  },
  DATE: {
    label: 'Date',
    value: VALIDATIONS.DATE_STRING.key
  },
  TIME: {
    label: 'Time',
    value: VALIDATIONS.TIME_FORMAT.key
  },
  DURATION: {
    label: 'Duration',
    value: VALIDATIONS.DURATION_FORMAT.key
  },
  SATURATION: {
    label: 'Saturation',
    value: VALIDATIONS.SATURATION_FORMAT.key
  },
  DECAY: {
    label: 'Decay',
    value: VALIDATIONS.DECAY_FORMAT.key
  },
  YES_NO: {
    label: 'Yes/No',
    value: VALIDATIONS.YES_NO.key
  },
  MEASURE: {
    label: 'Measure (Impressions, Clicks, etc.)',
    value: VALIDATIONS.MEASURE.key
  }
}

export const DEFAULT_VALIDATION_OPTIONS = [
    VALIDATION_OPTIONS_MAP.NUMBER,
    VALIDATION_OPTIONS_MAP.POSITIVE_NUMBER,
    VALIDATION_OPTIONS_MAP.INTEGER,
    VALIDATION_OPTIONS_MAP.POSITIVE_INTEGER,
    VALIDATION_OPTIONS_MAP.TEXT,
    VALIDATION_OPTIONS_MAP.TEXT_NO_SPECIAL,
    VALIDATION_OPTIONS_MAP.BOOLEAN,
    VALIDATION_OPTIONS_MAP.ONE_OR_ZERO,
    VALIDATION_OPTIONS_MAP.DATE
];

