import {call, put, takeEvery} from 'redux-saga/effects';
import { downloadFromURL } from '../../../js/download.js';
import {urlBuilders} from '../../../js/constants.js';
import {getData} from '../../../js/server.js';

import {snackbar} from '../../platform/snackbar.js';

import {DOWNLOAD_FILES, spinner} from '../downloadFiles.js';

export function* downloadFiles(action) {
    yield put(spinner(true));
    const response = yield call(
        getData,
        action.token,
        action.baseUrl +
            urlBuilders.getFileLocation(
                action.params.campaignId,
                action.params.type
            )
    );
    if (response.error) {
        yield put(
            snackbar(
                'error',
                'ERROR: \n Error while retrieving location of file for: ' +
                    action.params.campaignId +
                    '\\' +
                    action.params.type +
                    '. \n' +
                    response.message
            )
        );
    } else {
        const fileBucket = response.fileLocation.bucket;
        const fileKey = response.fileLocation.location;
        const fileUrl = yield call(
            getData,
            action.token,
            action.baseUrl + urlBuilders.getFileUrl(fileBucket, fileKey)
        );
        if (fileUrl.error) {
            yield put(
                snackbar(
                    'error',
                    'ERROR: \n Error while retrieving URL of file for: ' +
                        fileBucket +
                        '\\' +
                        fileKey +
                        '. \n' +
                        fileUrl.message
                )
            );
        } else {
            downloadFromURL(fileUrl.url);
        }
    }
    yield put(spinner(false));
}

export function* watchDownloadFiles() {
    yield takeEvery(DOWNLOAD_FILES, downloadFiles);
}
