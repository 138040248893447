/**
 * Congnito user groups
 */
export const USER_GROUPS = {
  AdImpact_Group: 'AdImpact_Group',
  AdResponse_Group: 'AdResponse_Group',
  Admin_Group: 'Admin_Group',
  Advanced_Group: 'Advanced_Group',
  Company_Admin_Group: 'Company_Admin_Group',
  Company_Advanced_Group: 'Company_Advanced_Group',
  Company_Regular_Group: 'Company_Regular_Group',
  Company_Trial_Group: 'Company_Trial_Group',
  Delete_Partner_Group: 'Delete_Partner_Group',
  MMM_Group: 'MMM_Group', 
  Partner_Admin_Group: 'Partner_Admin_Group',
  Partner_Advanced_Group: 'Partner_Advanced_Group', 
  Partner_Regular_Group: 'Partner_Regular_Group', 
  Super_Group: 'Super_Group', 
  Trial_Advanced_Group: 'Trial_Advanced_Group', 
  Wavecast_Group: 'Wavecast_Group',
};
