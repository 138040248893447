import React from 'react';
import {FormRowLV} from '../../components/form/row/FormRowLV';
import {ButtonLV} from '../../components/form/buttons/ButtonLV';
import {OrangeButtonLV} from '../../components/form/buttons/OrangeButtonLV';
import {useUserManagementRest} from './useUserManagementRest';
import {styles} from './DeleteUserForm.styles';
import _ from 'lodash';

export const DeleteUserForm = ({cell={}, refetchUsers=()=>{}, closeModal=()=>{}}) => {
  const email = _.get(cell, 'row.values.email');
  const username = _.get(cell, 'row.values.username');
  const {deleteUserResponse={}, deleteUser} = useUserManagementRest(username);
  const {loading, response={}} = deleteUserResponse;

  React.useEffect(() => {
    if(!response.status) {
      return;
    }
    refetchUsers();
    closeModal();
  }, [response.status, closeModal, refetchUsers]);
  
  const classNames = [styles.deleteUserModal];
  if(loading) {
    classNames.push('loading');
  }
  return (
    <div className={classNames.join(' ')}>
      <FormRowLV className={'title'} center={true} margin={0}>
        Are you sure?
      </FormRowLV>

      <FormRowLV className={'message'} center={true}>
        {`This will delete ${email}`}
      </FormRowLV>

      <FormRowLV center={true}>
        <OrangeButtonLV
          dataTest='userManagement-disableUserDialog-deleteButton'
          onClick={deleteUser}
          isDisabled={loading}>
          DELETE
        </OrangeButtonLV>

        <ButtonLV
          onClick={closeModal}
          dataTest='userManagement-disableUserDialog-cancelButton'
          isDisabled={loading}>
          CANCEL
        </ButtonLV>
      </FormRowLV>
    </div>
  );
};
