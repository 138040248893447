import {
  GridStatusCell,
  STATUS_VALUE_MAP
} from '../../../components/data-grid/GridStatusCell';
import {
  usePostTriggerDownloadUrl,
} from '../../../hooks/useTriggerDownloadUrl';
import {useGetPolling, usePostPolling} from '../../../hooks/usePolling';
import { urlBuilders } from '../../../js/constants';
import React from 'react';

export const MMMGridStatusCell = ({
  cell,
  getRuns,
  magnifaiRuns
}) => {
  const {
    triggerDownload,
    loading
  } = usePostTriggerDownloadUrl({
    method: 'POST',
    requestUrl: urlBuilders.exportErrorsCsv(cell.row.id),
    urlObjectPath: 'data.url'
  });

  let tooltip = '';
  let cursorStyle = 'pointer';
  let onClick;
  const isErrored = cell.row.runStatus === 'ERROR';
  const canRunMagnifai = cell.row.runStatus === 'FINAL';
  const isMagnifaiRunning = magnifaiRuns?.filter(run => {
    return run.mca_run_id === cell.row.id && run.run_status === 'Running'}).length > 0;
  let pollingUrl;
  let magnifaiPollingUrl;

  if (isErrored) {
    tooltip = 'Export MCA Errors';
    onClick = triggerDownload;
  }

  if (loading) {
    cursorStyle = 'wait';
  }

  let magnifaiRunStatus;
  if(canRunMagnifai) {
    let matchingMagnifaiRun = magnifaiRuns?.filter(run => {return run.mca_run_id === cell.row.id});
    if(matchingMagnifaiRun){
      matchingMagnifaiRun = matchingMagnifaiRun[0];
    }
    if(matchingMagnifaiRun?.run_status === 'Running'){
      magnifaiRunStatus = 'MAGNIFAI_TUNING_MODEL';
    }
    else if(matchingMagnifaiRun?.run_status === 'Success'){
      magnifaiRunStatus = 'Select Model';
    }
    else if(matchingMagnifaiRun?.run_status === 'Error'){
      magnifaiRunStatus = 'MAGNIFAI_ERROR_MODEL';
    }
    else{
      magnifaiRunStatus = cell.row.runStatus;
    }
    cursorStyle = 'default';
  }

  if(isMagnifaiRunning){
    magnifaiPollingUrl = urlBuilders.getMagnifAIRunStatus();
    cursorStyle = 'default';
  }

  if(cell?.row?.shouldPoll) {
    pollingUrl = urlBuilders.getMcaRun(cell.row.id);
    cursorStyle = 'default';
  }

  /**
   * Poll for update when status is processing
   * Populating pollingUrl will trigger update
   * onPoll function determines if pulling should continue
   * onPollingStopped runs after polling is complete
   */
  useGetPolling({
    url: pollingUrl,
    onPoll: (pollingResponse) => {
      // Continue to poll until run is complete
      if (!pollingResponse?.shouldPoll) {
        return true;
      }

      // Will update UI to show its new status while still polling
      if (STATUS_VALUE_MAP[pollingResponse?.runStatus] !== STATUS_VALUE_MAP[cell?.row?.runStatus]) {
        getRuns();
      }
      return false;
    },
    onPollingStopped: getRuns
  });

  //For MagnifAI
  usePostPolling({
    url: magnifaiPollingUrl,
    postData: {mcaRunIdList: [cell.row.id]},
    interval: 30000,
    onPoll: (pollingResponse) => (pollingResponse[0].run_status !== 'Running'),
    onPollingStopped: getRuns
  });

  return (
    <GridStatusCell
      dataTest={`mmm-run-table-status-cell-${cell.row.name}`}
      id={cell.row.id}
      status={canRunMagnifai ? magnifaiRunStatus : cell.value}
      name={cell.row.name}
      tooltip={tooltip}
      onClick={onClick}
      customStyles={{
        cursor: cursorStyle
      }}
    />
  );
}