import {injectGlobal} from 'emotion';
import 'typeface-roboto';

export const globalStyles = () => {
    injectGlobal`
        @font-face {
            font-family: Roboto, sans-serif;
        }
        * {
            font-family: Roboto, sans-serif;
        }
        h1 {
            font-face: 'Roboto, sans-serif';
            font-size: 96px;
        }
        h2 {
            font-face: 'Roboto, sans-serif';
            font-size: 60px;
        }
        h3 {
            font-face: 'Roboto, sans-serif';
            font-size: 48px;
        }
        h4 {
            font-face: 'Roboto, sans-serif';
            font-size: 34px;
        }
        h5 {
            font-face: 'Roboto, sans-serif';
            font-size: 24px;
        }
        h6 {
            font-face: 'Roboto, sans-serif';
            font-size: 20px;
        }
    `;
};
