import {css} from 'emotion';

export const styles = {
  label: css`
    display: flex;
    align-items: center;
    font-size: 13px;
    cursor: pointer;
  `,
  form: css`
    width: 350px;

    .delete-text {
      display: block;
      text-align: center;
    }
  `,
  greyButton: css`
    min-width: 100px;
  `,
};
