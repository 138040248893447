import React from 'react';
import {GraphContainerLV} from '../../../components/dashboards/GraphContainerLV';
import {GRAPH_OPTIONS} from '../../../components/dashboards/graph-options.constant';
import {SelectLV} from '../../../components/form/select/SelectLV';
import {GraphConfig} from '../../../components/dashboards/GraphConfig';
import {CHART_FORMAT} from '../../../components/charts/ChartFormat.constant';
import {Chiclet} from '../../../components/dashboards/Chiclet';
import {DashboardContext} from '../DashboardContext';
import {useMcaRuns} from '../../../hooks/useMcaRuns';
import _ from 'lodash';
import moment from 'moment';
import {styles} from '../dashboards.styles';
import {useInsightsQuery} from "../hooks/useInsightsQuery";
import {variableNameToRaw} from "../../../js/utils";

const xTicks = 20;

export const MediaVehicleDashboard = ({selectedMcaRunId}) => {

    const {filterByDateRange, dashboardRegion, insightMetadata} = React.useContext(DashboardContext);

    const [mediaVehicle, setMediaVehicle] = React.useState('');
    const [dropdownOptions, setDropdownOptions] = React.useState([]);

    // REST calls
    const {selectedMcaRun={}} = useMcaRuns();
    const variableType = selectedMcaRun.kpiType;
    const {
        results: saturationResponse,
        fetch: fetchSaturation,
        isLoading: saturationLoading
    } = useInsightsQuery({
        mcaRunId: selectedMcaRunId,
        chartType: 'Saturation',
    });

    const {
        results: efficiencyResponse,
        fetch: fetchEfficiency,
        isLoading: efficiencyLoading
    } = useInsightsQuery({
        mcaRunId: selectedMcaRunId,
        chartType: 'Efficiency',
    });

    const {
        results: thresholdsResponse,
        fetch: fetchThresholds,
        isLoading: thresholdsLoading
    } = useInsightsQuery({
        mcaRunId: selectedMcaRunId,
        insightType: 'Thresholds',
    });

    const {
        results: diminishingReturnsModeledResponse,
        fetch: fetchDiminishingReturnsModeled,
        isLoading: diminishingReturnsModeledLoading
    } = useInsightsQuery({
        mcaRunId: selectedMcaRunId,
        chartType: 'DiminishingReturnsModeled',
    });

    React.useEffect(() => {
        if (dashboardRegion && mediaVehicle && selectedMcaRunId) {
            const filters = {
                regions: [dashboardRegion],
                baseVariable: mediaVehicle.value
            }
            fetchSaturation(filters);
            fetchEfficiency(filters);
            fetchThresholds(filters);
            fetchDiminishingReturnsModeled(filters);
        }
    }, [dashboardRegion, mediaVehicle, selectedMcaRunId]);

    React.useEffect(() => {
        if (insightMetadata) {
            const {
                variables
            } = insightMetadata

            const options = Object.entries(variables)
                .filter(([key, value]) => value.saturation !== 'NO')
                .map(([key, value]) => ({
                    label: value.name,
                    value: variableNameToRaw(key)
                }));

            setDropdownOptions(options);
            if (options.length > 0) {
                setMediaVehicle(options[0]);
            }
        }
    }, [insightMetadata, setMediaVehicle, setDropdownOptions]);

    const isLoading = [efficiencyLoading, saturationLoading, thresholdsLoading, diminishingReturnsModeledLoading].some(isLoad => isLoad);

    const {
        saturationGraphData,
        maxSaturation,
        minFitRange,
        maxFitRange
    } = React.useMemo(() => {
        const results = saturationResponse?.results || [];
        const maxSaturation = results.reduce((max, data) => (data.simulatedValue > max ? data.simulatedValue : max), 0);
        const satTimestamps = results.map(x => moment(x.Date).format('X'));
        const minFitRange = parseDate(_.min(satTimestamps));
        const maxFitRange = parseDate(_.max(satTimestamps));

        return {
            saturationGraphData: results,
            maxSaturation,
            minFitRange,
            maxFitRange
        }
    }, [saturationResponse]);

    const {
        efficiencyGraphData,
        minEffRange,
        maxEffRange
    } = React.useMemo(() => {
        const results = filterByDateRange(efficiencyResponse?.results || []);

        const effTimestamps = results.map(x => moment(x.Date).format('X'));
        const minEffRange = parseDate(_.min(effTimestamps));
        const maxEffRange = parseDate(_.max(effTimestamps));

        return {
            efficiencyGraphData: results,
            minEffRange,
            maxEffRange
        }
    }, [efficiencyResponse]);

    const {
        minOptimal,
        maxOptimal
    } = React.useMemo(() => {
        if (thresholdsResponse?.results && mediaVehicle) {
            const schema = thresholdsResponse.results?.[0] || {};
            return {
                minOptimal: schema.minthreshold,
                maxOptimal: schema.maxthreshold
            }
        }
        return {};
    }, [thresholdsResponse, mediaVehicle]);

    const {
        diminishingReturnsModeledData,
        maxDiminishingReturn
    } = React.useMemo(() => {
            if (diminishingReturnsModeledResponse?.results) {
                const filteredResults = filterByDateRange(diminishingReturnsModeledResponse.results) || [];
                return {
                    diminishingReturnsModeledData: filteredResults,
                    maxDiminishingReturn: filteredResults.reduce((max, data) => (data.simulatedValue > max ? data.simulatedValue : max), 0)
                };
            }
            return {};
        }, [diminishingReturnsModeledResponse]);


    const {
        saturationReferenceAreas,
        ticks
    } = React.useMemo(() => {
            if(isLoading) {
                return {};
            }

            const maxBoxRange = Math.ceil(Math.max(maxSaturation, maxDiminishingReturn, maxOptimal));

            return {
                saturationReferenceAreas: getSaturationReferenceAreas(minOptimal, maxOptimal, maxSaturation) || [],
                ticks: Array(xTicks).fill().map((_, index) => (index * maxBoxRange/xTicks)) || []
            };
        }, [minOptimal, maxOptimal, maxSaturation, maxDiminishingReturn, isLoading]);

    const timeScale = selectedMcaRun.timeScale ?
        selectedMcaRun.timeScale.charAt(0).toUpperCase() + selectedMcaRun.timeScale.slice(1).toLowerCase() : 'Daily';

    const saturationXAxisLabel = mediaVehicle ? `${timeScale} ${mediaVehicle.value} Level` : 'Daily Level';

    const saturationHeader = `<div>
                              <div style="${styles.imageHeader}">Media Saturation Curve</div>
                              <div style="${styles.rangeHeader}">Model Fit Range: ${minFitRange} - ${maxFitRange}</div>
                            </div>`;

    const efficiencyHeader =  `<div>
                              <div style="${styles.imageHeader}">Media Saturation Curve - Efficiency</div>
                              <div style="${styles.rangeHeader}">Model Fit Range: ${minEffRange} - ${maxEffRange}</div>
                            </div>`;

    const MediaVehicleDropDown = () => (
        <SelectLV
            label='Media Vehicle'
            options={dropdownOptions}
            isDark={false}
            width={0}
            isClearable={false}
            onChange={setMediaVehicle}
            defaultValue={mediaVehicle}
        />);

    let saturationFormatMap = {
        Variable: CHART_FORMAT.HIDDEN,
        Region: CHART_FORMAT.STRING,
        keys: {
            simulatedValue: 'Spend',
            Modeled: 'Contribution',
            Observed: 'Contribution'
        }
    };

    if(variableType !== 'Revenue') {
        saturationFormatMap = {
            ...saturationFormatMap,
            Value: CHART_FORMAT.INTEGER,
            simulatedValue: CHART_FORMAT.INTEGER
        };
    }

    const saturationConfig = new GraphConfig({
        title: 'Diminishing Returns',
        data: saturationGraphData,
        fileData: [{
            data: saturationGraphData,
            fileName: 'DiminishingReturns_Observed'
        },
            {
                data: diminishingReturnsModeledData,
                fileName: 'DiminishingReturns_Modeled'
            }],
        graphOptions: [
            GRAPH_OPTIONS.SCATTER
        ],
        xAxisLabel: saturationXAxisLabel,
        xAxisKey: 'simulatedValue',
        ticks: ticks,
        yAxisLabel: 'Media Vehicle Contribution',
        yAxisKey: 'Observed',
        dropdowns: <MediaVehicleDropDown/>,
        isLoading: isLoading,
        csvFileName: 'DiminishingReturns',
        imageFileName: 'DiminishingReturns',
        imageHeader: saturationHeader,
        formatMap: saturationFormatMap,
        columnChartTypeMap: {
            Modeled: diminishingReturnsModeledData
        },
        referenceAreaConfigs: saturationReferenceAreas,
        colorMap: {
            Observed: '#828282',
            Modeled: '#557999'
        },
        toggleYValues: true
    });

    const efficiencyConfig = new GraphConfig({
        title: 'Flighting Optimization',
        data: efficiencyGraphData,
        graphOptions: [
            GRAPH_OPTIONS.COMB,
        ],
        xAxisKey: 'date',
        yAxisKey: 'value',
        filterKey: 'Flighting Optimization Level',
        yTicks: 0,
        dropdowns: <MediaVehicleDropDown/>,
        isLoading: isLoading,
        csvFileName: 'FlightingOptimization',
        imageFileName: 'FlightingOptimization',
        imageHeader: efficiencyHeader,
        formatMap: {
            date: CHART_FORMAT.DATE,
            Build: CHART_FORMAT.HIDDEN,
            Optimal: CHART_FORMAT.HIDDEN,
            Saturated: CHART_FORMAT.HIDDEN,
            value: CHART_FORMAT.INTEGER
        },
        colorMap: {
            Build: '#DCDDC0',
            Optimal: '#ABDA7C',
            Saturated: '#FFDD5B'
        },
        scrollBar: {
            minLength: (efficiencyGraphData.length) ? efficiencyGraphData.length - 1 : 30
        }

    });

    return (
        <Chiclet>
            <GraphContainerLV
                graphConfig={[saturationConfig, efficiencyConfig]}
            />
        </Chiclet>
    );
};

/**
 * Return ReChart Reference Boxes based on efficiency and saturation data
 * @param minOptimal The minimum threshold value
 * @param maxOptimal The maximum threshold value
 * @param maxSaturation The maximum value for the saturation data
 */
export const getSaturationReferenceAreas = (minOptimal, maxOptimal, maxSaturation) => {
    let referenceAreaConfigs = [
        {
            x1: undefined,  // undefined on x1 ensures box will expand to left
            x2: minOptimal,
            label: 'Build',
            color: '#DCDDC0'
        }
    ];

    // Add the Saturation box only if saturation extends beyond maxOptimal
    if(maxSaturation >= maxOptimal) {
        referenceAreaConfigs.push({
                x1: minOptimal,
                x2: maxOptimal,
                label: 'Optimal',
                color: '#ABDA7C'
            },
            {
                x1: maxOptimal,
                x2: undefined,  // undefined on x2 ensures box will expand to right
                label: 'Saturated',
                color: '#FFDD5B'
            });
    } else {
        referenceAreaConfigs.push({
            x1: minOptimal,
            x2: undefined,
            label: 'Optimal',
            color: '#ABDA7C'
        });
    }

    return referenceAreaConfigs;
};

const parseDate = (timestamp) => {
    return moment.unix(timestamp).format('MM/DD/YYYY');
};
