import React from 'react';
import {useGet} from './useRest';
import {urlBuilders} from '../js/constants';
import {useCampaignManagement} from './useCampaignManagement';

export const CLIENT_ID_URL_PARAM = 'clientId';

/**
 * Return the currently selected client's information
 * @return GET response from Rest Hook
 */
export const useClient = () => {
  const {clientId, setClientId} = useCampaignManagement();
  const url = urlBuilders.getClientById(clientId);
  const [{
    data={},
    loading,
    error,
    response={}
  }, fetch] = useGet({
    url,
    manual: true
  });

  React.useEffect(() => {
    if(!clientId) {
      return;
    }
    fetch();
  },[clientId, fetch]);

  // Clear clientId from url on error
  React.useEffect(() => {
    if(loading || !clientId) {
      return;
    }

    // Reset clientId on error, unless response does not return a status
    // Unauthorized errors occur when rolling cognito token, but will work on next call
    if(error && response.status) {
      setClientId(undefined);
    }
  },[loading, error, clientId, setClientId, response.status]);

  return data;
};
