import React from "react";
import Chatbot from 'react-chatbot-kit'
import 'react-chatbot-kit/build/main.css'
import ActionProvider from "./ActionProvider";
import MessageParser from "./MessageParser";

import config from "./config";
import {Popover} from "@mui/material";
import {YeastyContext} from "./context/YeastyContext";
import DoughyCTA from "./componenets/DoughyCTA";
import {Config} from "../../config/ConfigFactory";

export const Yeasty = ({isOpen, close, open}) => {

    const shouldShow = Config.getConfig()?.FEATURE_FLAGS?.YEASTY;

    if (!shouldShow) {
        return null;
    }

    const {
        saveMessages,
        messageHistory
    } = React.useContext(YeastyContext);

    return (
        <div>
            <Popover
                open={isOpen}
                onClose={close}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >

                {getContents({
                    saveMessages,
                    messageHistory
                })}
            </Popover>

            <DoughyCTA onClick={open}/>
        </div>
    );
};

function getContents({
    saveMessages,
    messageHistory
                     }) {
    return (
        <span>
            <Chatbot
                config={config}
                actionProvider={ActionProvider}
                messageParser={MessageParser}
                saveMessages={saveMessages}
                messageHistory={messageHistory}
            />
        </span>
    )
}

