import React from 'react';
import Avatar from '../../../assets/BLOBBY_LeavOr.png';
import Tooltip from "@mui/material/Tooltip";

const DoughyCTA = ({onClick}) => {

    const imgStyles = {
        width: 70,
        height: 70,
        cursor: 'pointer'
    }

    const divStyles = {
        position: 'absolute',
        bottom: 10,
        left: 12,
    }

    return (
        <Tooltip title={'Doughy Chatbot'}>
            <div style={divStyles} onClick={onClick}>
                <img src={Avatar} alt="DoughyCallToAction" style={imgStyles} />
            </div>
        </Tooltip>

    );
};

export default DoughyCTA;