// actions
export const FETCH_KPIS_BY_CAMPAIGN = `mca/kpis/fetch-kpis-by-campaign`;
export const LOAD_KPIS = `mca/kpis/load-kpis`;
export const SET_LOADING = `mca/kpis/set-loading`;
export const SET_ERRORS = `mca/kpis/set-errors`;

// Selectors
const ID = 'kpi';
const local = (state) => state['mca'][ID];
export const getKpis = (state) => local(state).kpis;
export const getKpisErrors = (state) => local(state).errors;
export const getKpisLoading = (state) => local(state).loading

export const initialState = {
    kpis: [],
    errors: '',
    loading: false
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_KPIS:
            return Object.assign({}, state, {kpis: action.kpis});
        case SET_ERRORS:
            return Object.assign({}, state, {errors: action.errors});
        case SET_LOADING:
                return Object.assign({}, state, {loading: action.loading});
        default:
            return state;
    }
}

// action creators
export function loadKpis(kpis) {
    return {
        type: LOAD_KPIS,
        kpis
    };
}

export function setLoading(loading) {
    return {
        type: SET_LOADING,
        loading
    };
}

export function setErrors(errors) {
    return {
        type: SET_ERRORS,
        errors
    };
}