import {css} from 'emotion';

export const styles = {
  container: css`
    display: flex;

    .dollar {
      width: 20px;
    }

    .number {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
    }

    .center {
      justify-content: center;
    }

    .left {
      justify-content: flex-start;
    }

    .right {
      justify-content: flex-end;
    }
  `
};
