import React from 'react';
import {useGet} from '../../hooks/useRest';
import {DashboardDatePickerBar} from '../../components/dashboards/DashboardDatePickerBar';
import {urlBuilders} from "../../js/constants";

/**
 * Form within dashboard that contains Date Selector for all Dashboard Tabs
 * {Children} will display when a user clicks the "3 Dots" on the right
 */
export const McaDashboardDatePickerBar = ({selectedMcaRunId, defaultRegion, setInsightMetadata, ...props}) => {

    const [{
        data: results,
    }, fetch] = useGet({
        url: urlBuilders.getInsightMetadata(selectedMcaRunId),
        manual: true
    });

    React.useEffect(() => {
        if (!selectedMcaRunId) {
            return;
        }
        fetch();
    }, [selectedMcaRunId]);

    React.useEffect(() => {
        if (results) {
            setInsightMetadata(results)
        }
    }, [results]);

    const {
        dependentVariable,
        rSquared,
        mape,
        confidence,
        regions,
        minDate,
        maxDate
    } = React.useMemo(() => {
        if (!results) {
            return {};
        }

        return {
            ...results,
            minDate: new Date(results.minDate).toLocaleDateString("en-US", {
                timeZone: 'UTC'
            }),
            maxDate: new Date(results.maxDate).toLocaleDateString("en-US", {
                timeZone: 'UTC'
            })
        };
    }, [results]);

    const defaultRegionProp = React.useMemo(() => {
        if (defaultRegion) {
            return defaultRegion;
        }

        if (regions && regions.length) {
            return regions[0];
        }
    }, [regions, defaultRegion]);

    return (
        <DashboardDatePickerBar
            regions={regions}
            defaultRegion={defaultRegionProp}
            dependentVariable={dependentVariable}
            rsq={rSquared}
            mape={mape}
            confidence={confidence}
            {...props}
            defaultStartDate={minDate}
            defaultEndDate={maxDate}
        />
    );
};
