import React from 'react';
import {numberWithCommas} from '../../../js/utils';
import _ from 'lodash';

export const ObservedRange = ({cell={}}) => {
  const min = Number(_.get(cell, 'row.values.minObserved')).toFixed(0);
  const max = Number(_.get(cell, 'row.values.maxObserved')).toFixed(0);

  if(isNaN(min) || isNaN(max)) {
    return null;
  }
  const minObserved = numberWithCommas(min);
  const maxObserved = numberWithCommas(max);

  let range = `${minObserved} - ${maxObserved}`;

  // No runs should have variables with the same min and max,
  // so this is only needed for old runs that don't have the min and max values
  if((min === max)){
    range = '';
  }

  return (
    <div style={{
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    }}>
      {range}
    </div>
  );
};
