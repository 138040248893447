import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FeatureLV } from '../../components/pages/FeatureLV';
import { R1Button } from '@rainagency/rain-one-soggy-loaf';

import {getUsername} from '../../modules/platform/cognito';

import withHTTP from '../../hoc/with-http';
import {RUN_WAVECAST, getWavecastResults} from '../../modules/wavecast/wavecast.js';

import {styles} from './wavecast.styles.js';

// export unconnected class for testing
export class Wavecast extends Component {
    constructor(props) {
        super(props);
        this.state = {wavecastResults: []};
        this.runWavecast = this.runWavecast.bind(this);
    }

    runWavecast() {
        this.props.httpCall(
            RUN_WAVECAST,
            {username: this.props.username},
            {}
        );
    }

    renderWavecastResults(){
        const results = this.props.wavecastResults.map((result, index) => {
                let response = result.error ? moment().format('MMMM Do YYYY, h:mm:ss a') + ' ERROR [' + result.message + ']' :
                    moment().format('MMMM Do YYYY, h:mm:ss a') + ' Success';

                return (<h2 key={index}>{response}</h2>);
            })
        return results;
    }

    render () {
        return (

          <FeatureLV
            title='Maslow'
            description='Perform a Maslow run on your selected campaign'
            CallToAction={
              <R1Button
                className={styles.run_button}
                aria-label='maslow-run-button'
                variant='contained'
                color='secondary'
                onClick={this.runWavecast}> Run
              </R1Button>
            }
          >
            
            {this.renderWavecastResults()}

          </FeatureLV>

        );
    }
}

Wavecast.propTypes = {
    httpCall: PropTypes.func,
    username: PropTypes.string,
    wavecastResults: PropTypes.array
};

const mapStateToProps = state => {
    return {
        username: getUsername(state),
        wavecastResults: getWavecastResults(state)
    }
};

export default withHTTP(connect(mapStateToProps)(Wavecast), true);
