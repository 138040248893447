import {call, put, takeEvery, select} from 'redux-saga/effects';

import {urlBuilders} from '../../../js/constants.js';
import {post} from '../../../js/server.js';

import {
    fetchMcaRunVariableNameTranslatorSuccess,
    FETCH_MCA_RUN_VARIABLE_NAME_TRANSLATOR_REQUEST
} from '../variable-name-translator.js';

import {loadSaturatedMediaVehicles} from '../media-vehicle-dashboard.js';
import {getSelectedModel} from '../models.js';

export function* fetchMcaRunVariableNameTranslator(action) {
    const modelVariables = yield select(getSelectedModel);
    const payload = [];
    modelVariables.details.forEach((item) => {
        payload.push(item.variable);
    });
    const translatorMap = yield call(
        post,
        action.token,
        action.baseUrl + urlBuilders.getGroupMap(action.runId),
        {readableVariableNames: payload}
    );
    yield put(fetchMcaRunVariableNameTranslatorSuccess(translatorMap.body.map));
    yield put(loadSaturatedMediaVehicles(translatorMap.body.map));

}

export function* watchFetchMcaRunVariableNameTranslator() {
    yield takeEvery(
        FETCH_MCA_RUN_VARIABLE_NAME_TRANSLATOR_REQUEST,
        fetchMcaRunVariableNameTranslator
    );
}
