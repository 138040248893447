import React from 'react';
import {BarChartLV} from '../../components/charts/BarChartLV';
import {FormRowLV} from '../../components/form/row/FormRowLV';
import {CHART_FORMAT} from '../../components/charts/ChartFormat.constant';

export const BarChartRow = ({
  data=[],
  kpiType,
  loading
}) => {

  const excludedMeasure = ['Base', 'Binary', 'Control'];
  const filteredData = data.filter(tactic => !excludedMeasure.includes(tactic.measure));
  const mappedData = getChartData(filteredData);

  const incrementalTitle = 'Incremental Spend';
  let marginalTitle = 'Marginal ROI';
  const marginalFormatMap = {
    value: CHART_FORMAT.CURRENCY
  };

  if(kpiType === 'Units') {
    marginalTitle = 'Marginal CPA';
    marginalFormatMap.value = CHART_FORMAT.INTEGER;
  }

  return (
    <FormRowLV expand={true}>
      <div
        style={{
          margin: '-12px -32px -108px -42px',
        }}
        className='row'>

        <div
          className='column1'
          style={{
            width: '100%'
          }}>

          <div className='chart-title'>
            {incrementalTitle}
          </div>
          <BarChartLV
            data={mappedData.incrementalData}
            uniformColor={'#FF7863'}
            isVertical={true}
            hasData={filteredData.length}
            isLoading={loading}
            displayXValues={false}
          />        
        </div>

        <div
          className='column2'
          style={{
            width: '100%'
          }}>
          <div className='chart-title'>
            {marginalTitle}
          </div>
          <BarChartLV
            data={mappedData.marginalData}
            uniformColor={'#2A5588'}
            isVertical={true}
            formatMap={marginalFormatMap}
            hasData={filteredData.length}
            isLoading={loading}
            displayXValues={false}
          />        
        </div>
      </div>
    </FormRowLV>
  );
};

const getChartData = (data=[]) => {

  const incrementalData = data.map(m => ({
    name: m.variableTactic,
    value: m.incrementalSpend
  }));

  const marginalData = data.map(m => ({
    name: m.variableTactic,
    value: m.marginal
  }));

  return {
    incrementalData,
    marginalData
  };
};
