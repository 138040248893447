import {sortByColumnBetter} from '../../js/utils.js';

// actions
export const LOAD_ALL_PARTNERS = `platform/partner/load-all-partners`;
export const FETCH_ALL_PARTNERS = `platform/partner/fetch-partners`;
export const SORT_PARTNERS = `platform/partner/sort-partners`;
export const SET_SELECTED_PARTNER = `platform/partner/set-selected-partner`;
export const CREATE_PARTNER = `platform/partner/create-partner`;
export const LOAD_PARTNER_ERRORS = `platform/partner/load-partner-errors`;
export const RESET_PARTNER_ERRORS = `platform/partner/reset-partner-errors`;

export const initialState = {
    byId: {},
    allIds: [],
    columnToSortBy: '',
    ascendingSort: '',
    selectedPartner: '',
    errors: []
};

// selectors
const ID = `partner`;
const local = (state) => state['platform'][ID];
export const getPartners = (state) => local(state).allIds;
export const getPartnerById = (state, id) => local(state).byId[id];
export const getSelectedPartner = (state) => local(state).selectedPartner;
export const getSortColumn = (state) => local(state).columnToSortBy;
export const getPartnerAscendingSort = (state) => local(state).ascendingSort;
export const getPartnerErrors = (state) => local(state).errors;

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_ALL_PARTNERS:
            let newById = {};
            let newAllIds = action.partners.map((partner) => {
                newById[partner.id] = partner;
                return partner.id;
            });
            return Object.assign({}, state, {
                byId: newById,
                allIds: newAllIds,
                selectedPartner: state.selectedPartner || newAllIds[0]
            });
        case SORT_PARTNERS:
            let ascendingSort = state.ascendingSort;
            if (state.columnToSortBy === action.columnToSortBy) {
                if (ascendingSort === false) {
                    ascendingSort = true;
                } else {
                    ascendingSort = false;
                }
            } else {
                ascendingSort = true;
            }
            const sortedIds = sortByColumnBetter(
                state.allIds,
                state.byId,
                action.columnToSortBy,
                ascendingSort
            );
            return Object.assign({}, state, {
                ascendingSort,
                columnToSortBy: action.columnToSortBy,
                allIds: sortedIds
            });
        case SET_SELECTED_PARTNER:
            sessionStorage.setItem(
                `platform/partner/selectedPartner`,
                action.selectedPartner
            );
            return Object.assign({}, state, {
                selectedPartner: action.selectedPartner
            });
        case LOAD_PARTNER_ERRORS:
            return Object.assign({}, state, {errors: action.errors});
        case RESET_PARTNER_ERRORS:
            return Object.assign({}, state, {errors: []});
        default:
            return state;
    }
}

// action creators
export function loadAllPartners(partners) {
    return {
        type: LOAD_ALL_PARTNERS,
        partners
    };
}

export function sortPartners(columnToSortBy) {
    return {
        type: SORT_PARTNERS,
        columnToSortBy
    };
}

export function fetchAllPartners() {
    return {
        type: FETCH_ALL_PARTNERS
    };
}

export function setSelectedPartner(selectedPartner) {
    return {
        type: SET_SELECTED_PARTNER,
        selectedPartner
    };
}

export function loadPartnerErrors(errors) {
    return {
        type: LOAD_PARTNER_ERRORS,
        errors
    };
}

export function resetPartnerErrors(errors) {
    return {
        type: RESET_PARTNER_ERRORS
    };
}
