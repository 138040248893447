import queryString from 'query-string';
import {useLocation, useHistory} from 'react-router-dom';
import {
  PARTNER_ID_URL_PARAM,
  CLIENT_ID_URL_PARAM,
  CAMPAIGN_ID_URL_PARAM
} from './useCampaignManagement';

/**
 * Redirect to any Leavened feature
 * @returns function that will redirect to a feature
 */
export const useFeatureSelect = () => {
  const history = useHistory();
  const location = useLocation() || {};

  /**
   * Redirect url to a given feature
   *
   * @param feature Feature available from `./src/constants/features`
   * @param query Optional URL params to add to the url
   * @param partner Partner object
   * @param client Client object
   * @param campaign Campaign object
   */
  const onFeatureSelect = ({
    feature = {},
    partner,
    client,
    campaign,
    query = {}, 
    ...props
  }) => {
    const campaignParams = {};

    if (!feature.path) {
      history.push(`/${location.search}`);
      return;
    }

    const urlParams = queryString.parse(location.search) || {};

    if(partner) {
      campaignParams[PARTNER_ID_URL_PARAM] = partner?.id;
    }

    if(client) {
      campaignParams[CLIENT_ID_URL_PARAM] = client?.id;
    }

    if(campaign) {
      campaignParams[CAMPAIGN_ID_URL_PARAM] = campaign?.id;
    }

    const search = queryString.stringify({
      ...urlParams,
      ...campaignParams,
      ...query
    });

    // No url query to append
    if(!search){
      history.push(feature.path);
      return;
    }

    history.push(
      `${feature.path}?${search}`, 
      props
    );
  };

  return {
    onFeatureSelect
  };

};
