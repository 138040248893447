import {css} from 'emotion';

export const styles = {
  container: css`
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.3);
    background: #ffffff;
    width: 100%;
    flex-wrap: wrap;
    align-content: center;
  `,
  modelCalc: css`
    font-size: 10px;
    color: #7e9292;
    text-align: left;
    padding-left: 12px;
    border-left: 1px solid rgba(126, 146, 146, 0.25);
    display: flex;
    align-items: flex-end;
    width: 150px;
    height: 90px;

    .calculation {
      font-size: 14px;
      color: rgb(55, 79, 99);
      inline-size: 110px;
      overflow-wrap: break-word;
    }
  `,
  dropdown: css`
    display: flex;
    align-items: flex-end;
    margin-right: 50px;
    width: 200px;
  `,
  topBar: css`
    display: flex;
    padding-bottom: 24px;
    position: sticky;
    top: 0;
    background: #FFF;
    z-index: 1;
  `,
  modelTitle: css`
    font-size: 24px;
    color: #374f63;
    align-self: flex-end;
    min-width: 200px;
    height: 90px;
    padding-left: 24px;
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
  `,
  tableStyles: css`
    th:first-child {
      padding-left: 12px;
    }
    th {
      color: rgba(0, 0, 0, 0.87);
      font-weight: 500;
      text-transform: uppercase;
      font-size: 12px;
      top: 114px;
    }

    td:first-child {
      padding-left: 12px;
    }
    td {
      height: 90px;
      background: #F4F4F4;
      color: rgb(55, 79, 99);
      font-size: 18px;
      border-bottom: 1px solid rgba(224, 224, 224, 1);
      width: 200px;
    }
  `
};
