import React from 'react';
import {TextField} from '@mui/material';

import {usePost} from '../../../../hooks/useRest';
import {useMcaRuns} from '../../../../hooks/useMcaRuns';

import {urlBuilders} from '../../../../js/constants';
import {closeDialog} from '../../../../modules/platform/dialog';

import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import {styles} from './approve-model.styles.js';
import {useSetPath} from "../../../../hooks/useProduct";

export default function ApproveModelDialog(props) {
    const {dispatch, modelId} = props;

    const {selectedMcaRun={}} = useMcaRuns();
    const {
        id: mcaRunId,
        partition='',
        encoding='',
        name=''
    } = selectedMcaRun;

    /** Start approve model */
    const [{loading}, approveModel] = usePost({
        url: urlBuilders.approveModel(mcaRunId, partition, modelId, encoding, name),
        manual: true
    });

    const setPath = useSetPath();
    const [notes, setNotes] = React.useState();

    const startApproveModel = async () => {
        await approveModel({data: {
            notes
        }});
        dispatch(closeDialog());
        setPath('/campaign/mca');
    };

    const submitButtonStyle = `${styles.submitButton} ${styles.activeButton}`
    const disabled = loading || !mcaRunId || !partition || !encoding || !name
    const dialogStyle = disabled ? `${styles.dialogStyle} ${styles.waitCursor}` : styles.dialogStyle;

    return (
        <div className={dialogStyle}>
            <div>
                <DialogTitle classes={{root:styles.title}}>Approve Model #{modelId}</DialogTitle>
            </div>
            <div className={styles.body}>
                Approving this model will erase all other models from this data set. You can always re-run the analysis to view the full model set again.
            </div>
            <div className={styles.textBoxContainer}>
                <TextField
                    name='notes'
                    label='Notes'
                    sx={{ width: '100%' }}
                    multiline
                    rows={4}
                    onChange={({target}) => setNotes(target.value)}
                />
            </div>
            <div className={styles.buttonContainer}>
                <Button data-test='cancel-approveModelDialog-mcaRunTable' 
                    variant='contained' disabled={disabled} className={styles.cancelButton} onClick={() => dispatch(closeDialog())}> CANCEL </Button>
                <Button data-test='approve-approveModelDialog-mcaRunTable'
                    variant='contained' disabled={disabled} className={submitButtonStyle} onClick={startApproveModel}> APPROVE &amp; ERASE </Button>
            </div>
        </div>
    );

}

export {ApproveModelDialog};
