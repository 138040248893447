import React from 'react';
import {ScenarioResultCharts} from './ScenarioResultCharts';

export const Scenario1 = ({data=[], kpiType='', timeScale, loading}) => {

  const scenario1Data = data.map(row => {
      const tactic = row.variableTactic;
      const spend = row.scenario1Spend || 0;
      const revenue = row.scenario1Contribution;
      const roi = row.scenario1Ratio;
      const cpa = row.scenario1Ratio;
      return {tactic, spend, revenue, roi, cpa};
  });
    
  return (
      <ScenarioResultCharts
        data={scenario1Data}
        kpiType={kpiType}
        loading={loading}
        timeScale={timeScale}
      />
  );
};