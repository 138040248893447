import {css} from 'emotion';

export const styles = {
    kabobMenuIcon: css`
        color: black !important;
    `,
    kabobMenu: css``,
    menuItem: css`
        font-size: 11pt;
        color: #374f63;
        padding-left: 1.09em;
    `,
    menuIcon: css`
        vertical-align: bottom;
    `,
    constMenuItem: css`
        font-size: 9pt !important;
        font-style: italic;
        align-contents: center;
    `
};
