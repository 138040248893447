import {call, put, takeEvery} from 'redux-saga/effects';

import {urlBuilders} from '../../../js/constants';
import {getData} from '../../../js/server';

import {snackbar} from '../../platform/snackbar';

import {
    FETCH_UPLOADED_FILES,
    spinner,
    loadUploadedFiles,
    loadUploadedFilesErrors
} from '../uploadedFiles';

export function* fetchUploadedFiles(action) {
    yield put(spinner(true));
    let response = yield call(
        getData,
        action.token,
        action.baseUrl +
            urlBuilders.getUploadedCampaignFilesMetaData(
                action.params.campaignId
            )
    );

    yield put(spinner(false));
    if (response.error && response.message) {
        yield put(loadUploadedFilesErrors(response.message));
        yield put(
            snackbar(
                'error',
                'ERROR: \n Error while retrieving list of uploaded files for selected campaign: ' +
                    action.params.campaignId +
                    '. \n' +
                    response.message
            )
        );
    } else {
        yield put(loadUploadedFiles(response.data));
    }
}

export function* watchFetchUploadedFiles() {
    yield takeEvery(FETCH_UPLOADED_FILES, fetchUploadedFiles);
}
