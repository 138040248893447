import React from 'react';
import {ScenarioResultCharts} from './ScenarioResultCharts';

export const Scenario2 = ({data=[], kpiType='', timeScale, loading}) => {

  const scenario2Data = data.map(row => {
      const tactic = row.variableTactic;
      const spend = row.scenario2Spend || 0;
      const revenue = row.scenario2Contribution;
      const roi = row.scenario2Ratio;
      const cpa = row.scenario2Ratio;
      return {tactic, spend, revenue, roi, cpa};
  });
  
  return (
      <ScenarioResultCharts
        data={scenario2Data}
        kpiType={kpiType}
        loading={loading}
        timeScale={timeScale}
      />
  );
};
