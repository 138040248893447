import React from 'react';
import PropTypes from 'prop-types';

import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

import {getRadioStyles} from './RadioButtonLV.styles';

export const RadioButtonLV = ({
  value,
  label,
  checked,
  disabled,
  dataTest,
  darkTheme,
  style={},
  isHidden=false
}) => {
  if(isHidden) {
    return null;
  }

  const radioStyles = getRadioStyles(darkTheme);
  return (
    <div>
      <FormControlLabel
        style={style}
        value={value}
        classes={{label: disabled ? radioStyles.disabledRadio : radioStyles.radioButton}}
        control={
          <Radio 
            classes={{root: checked ? radioStyles.checkedRadio : radioStyles.uncheckedRadio}}
            data-test={dataTest}
          />
        }
        label={label}
        labelPlacement='end'
        checked={checked}
        disabled={disabled}
      />
    </div>
  );
};

RadioButtonLV.propTypes = {
    value: PropTypes.string,
    label: PropTypes.string,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    darkTheme: PropTypes.bool
}
