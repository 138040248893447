import React from 'react';

import DialogTitle from '@mui/material/DialogTitle';

import {ButtonLV} from '../../components/form/buttons/ButtonLV';
import {OrangeButtonLV} from '../../components/form/buttons/OrangeButtonLV';

import {styles} from './ScenarioWarningDialog.styles';

export const ScenarioWarningDialog = ({
    closeModal,
    setIsSubmitted=()=>{},
    setWarningOpen=()=>{},
    postScenarios=()=>{},
    data = {}
  }) => {

  const approveScenario = () => {
    setIsSubmitted(true);
    postScenarios(data);
    closeModal();
  };

  const handleCancel = () => {
    setIsSubmitted(false);
    setWarningOpen(false);
    closeModal();
  };

  return (
    <div data-test='scenario-warning-dialog'>
      <div>
        <DialogTitle classes={{root:styles.title}} data-test='warning-title'>
          Run Scenario?
        </DialogTitle>
      </div>
      <div className={styles.body}>
        The scenario you are attempting to run has values outside of observed range. Continue?
      </div>

      <div className={styles.buttonContainer}>
        <div className={styles.approveButton}>
          <OrangeButtonLV
            dataTest='approve-scenario-button'
            onClick={approveScenario}>
            CONTINUE
          </OrangeButtonLV>
        </div>
        <div className={styles.cancelButton}>
          <ButtonLV
            onClick={handleCancel}
            dataTest='close-button'>
            CANCEL
          </ButtonLV>
        </div>
      </div>

    </div>
  );
}