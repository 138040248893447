import {css} from 'emotion';

export const styles = {
  chickletContainer: css`
    display: flex;
    .graph-control-icon {
      display: none !important;
    }
  `,
  tableContainer: css`
    display: block;
    max-height: 600px;
    overflow-y: scroll;
    width: 100%;
    .scoring-spend-table {
      max-height: 600px;
      .header-cell-container {
        text-align: center !important;
      }
      th {
        min-width: 150px;
      }
      tr {
        height: 30px;
      }
      td {
        padding: 20px;
        color: grey;
      }
    }
  `,
  changeChartContainer: css`
    display: block;
    .recharts-legend-wrapper {
      display: none;
    }
    [id^="graph-control-panel"] {
      display: none;
    }
  `,
  contributionChartContainer: css`
    display: block;
    margin-bottom: 250px;

    [id^="graph-control-panel"] {
      display: none;
    }
    [class^="interval-radio-button-container"] {
      margin-right: 0;
      margin-left: auto;
    }
  `,
}