import {css} from 'emotion';
import {commonStyles} from '../../styles/commonStyles';

export const styles = {
  waterfallBarChart: css`
    .bar-start {
      path {
        fill: #FF0000;
      }
    }
    .bar-positive {
      path {
        fill: #8884D8;
      }
    }
    .bar-negative {
      path {
        fill: #FFF;
      }
    }
  `,
  chart: css`
    text {
      ${commonStyles.fontFamily}
      font-size: 12px;
      color: #828282;
    }

    tspan {
      fill: #828282;
    }

    .recharts-legend-wrapper {
      ${commonStyles.fontFamily}
      font-size: 12px;
      color: #828282;
    }

    .broadcast-reference-lv {
      line {
        stroke-width: 2;
      }
    }

  `,
  loading: css`
    .recharts-surface {
      opacity: 0.5;
    }

    .recharts-tooltip-wrapper {
      display: none;
    }

    .recharts-default-legend {
      opacity: 0.5;
      pointer-events: none;
      display: none;
    }

    .recharts-wrapper::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 32%;
      left: 50%;
      ${commonStyles.fontFamily}
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 42px;
      color: #828282;
      ${commonStyles.loadingSpinner}
    }
  `,
  hidden: css`
    display: none;
  `,
};

/**
 * Add content before the legend
 */
export const legendContent = content => {
  const marginRight = content ? '8px' : 0;
  return css`
    .recharts-legend-wrapper {
      left: -10px !important;
      margin: 0;
      padding: 0;

      .recharts-legend-item {
        white-space: nowrap;
      }

      ul::before {
        content: "${content}";
        font-size: 12px;
        font-weight: bold;
        margin-right: ${marginRight};
      }
    }
  `;
};

/**
 * Return CSS for grid data loading
 */
export const getChartStateCss = (
  data, isLoading=false, message
) => {
  if (isLoading) {
    return [styles.loading, 'chart-loading'].join(' ');
  }

  if(Array.isArray(data) && data.length > 0) {
    return '';
  }

  const noDataStyle = getNoChartData(message);

  return `${noDataStyle.noChartData} no-data`;
};

const getNoChartData = (message='No Data') => {
  return {
    noChartData: css`

    .recharts-default-legend {
      opacity: 0.5;
      pointer-events: none;
      display: none;
    }

    .recharts-surface {
      opacity: 0.5;
    }

    .recharts-tooltip-wrapper {
      display: none;
    }

    .recharts-legend-wrapper {
      display: auto;
    }

    .recharts-wrapper::before {
      content: '${message}';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      ${commonStyles.fontFamily}
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 42px;
      color: #828282;
    }
  `,
  };
};
