import React from 'react';
import PropTypes from 'prop-types';
import small from '../../assets/leavened-no-name-logo.svg';
import large from '../../assets/leavened-logo.svg';
import {FormUtilLV} from '../form/util/FormUtilLV';
import {styles} from './LogoLV.styles.js';

/**
 * LogoLV displays a Leavened Logo
 */
export default class LogoLV extends React.Component {

  render() {
    const {clickHandler, isLarge, className} = this.props;
    let imageCSS;

    if(clickHandler) {
      imageCSS = styles.pointer;
    }

    return (
      <div className={className}>
        <img
          style={FormUtilLV.addWidth(this.props.width)}
          onClick={clickHandler}
          className={imageCSS}
          src={small}
          hidden={isLarge}
          alt='Leavened Logo' />

        <img
          style={FormUtilLV.addWidth(this.props.width)}
          onClick={clickHandler}
          className={imageCSS}
          src={large}
          hidden={!isLarge}
          alt='Leavened Logo' />
      </div>
    );
  }
}

LogoLV.propTypes = {
  /**
   * Will execute when onClick image
   */
  clickHandler: PropTypes.func,
  /**
   * Shows the Leavened large logo if true
   */
  isLarge: PropTypes.bool,
  /**
   * Custom css added from parent <div>
   */
  className: PropTypes.string,
  /**
   * Override width of logo
   */
  width: PropTypes.number
};

export {LogoLV};
