
import {css} from 'emotion';

export const styles = {
    container: css`
        display: flex;
        vertical-align: top;
        justify-content: center;
        padding: 32px 50px;
        margin-bottom: 50px;
        background: #fff;
        border-bottom: 2px solid rgba(126, 146, 146, 0.25);
   `,
    summaryLabel: css`
        font-weight: bold;
        color: #ff6b57;
        padding-bottom: 30px;
        text-align: center;
    `,
    summaryValue: css`
        font-weight: bold;
        color: #000;
        font-size: 1.5em;
        text-align: center;
    `,
    summaryItem: css`
        height:50px;
        padding: 20px 50px 10px 50px;
        text-align: center;
        &:not(:first-of-type){
            border-left: 1px solid rgba(126, 146, 146, 0.25);
        }
    `,
};
