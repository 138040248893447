import {useUsersList} from '../pages/user-management/useUsersList';
import {useUserRoleFromGroup} from './useUserRoleFromGroup';

/**
 * Determine the User's default partner and client IDs without using the URL
 * @param partners Array of partners from partners endpoint
 * @returns Object with user's default/assigned partner and client IDs
 */
export const useDefaultIds = (partners=[]) => {
  const { data=[] } = useUsersList();
  const { username } = useUserRoleFromGroup();
  
  let defaultIds = {
    defaultPartnerId: undefined,
    defaultClientId: undefined
  };

  if(!Array.isArray(partners) || partners.length === 0) {
    return defaultIds;
  }

  const userData = data.find(user => user.username === username) || { partnerIds: [], clientIds: [] };
  defaultIds.defaultPartnerId = userData.partnerIds.length ? userData.partnerIds[0] : partners[0].id;

  // There should always be at least 1 partner for any user
  // Exactly 1 Partner exists on non-system users
  const defaultPartner = partners.find(partner => partner.id === defaultIds.defaultPartnerId) || {};
  const companies = defaultPartner.companies;

  if(!Array.isArray(companies)) {
    return defaultIds;
  }

  // Partners are not guaranteed to have a company, but once they do, the default client is the first
  // Client users should have exactly 1 company
  if(companies.length >= 1) {
    defaultIds.defaultClientId = userData.clientIds.length ? userData.clientIds[0] : companies[0].id;
  }

  return defaultIds;
};
