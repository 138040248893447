import {css} from 'emotion';
import {commonStyles} from '../../styles/commonStyles';

export const styles = {
  container: css`
    display: flex;
    justify-content: center;
    padding: 32px 50px;
    background: #F2F2F2;
    .chiclet-box {
      background: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 42px;
      border-radius: 20px;
      width: 100%;

      .download-candy-panel {
        display: flex;
        justify-content: space-evenly;
        width: 96px;
        padding-bottom: 24px;

        .icon {
          width: 48px;
          height: 48px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .icon:nth-child(2) {
          border-left: 1px solid rgba(0,0,0,0.12);
        }

      }

    }
  `,
  title: css`
    text-align: center;
    margin-bottom: 18px;
    ${commonStyles.fontFamily}
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
    white-space: nowrap;
  `,
};
