import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '@mui/material/Tooltip';

import leftArrow from '../../../../assets/arrow_left_24px.svg';
import rightArrow from '../../../../assets/arrow_right_24px.svg';
import {ButtonLV} from '../../../../components/form/buttons/ButtonLV';

import {styles} from './mca-details-nav.styles.js';

export default function McaDetailsNav(props) {
    const selectedModelId = props.selectedModelId;

    const inReviewModels = props.models;
    const modelIds = inReviewModels.map(model => model.modelid);
        
    const selectModel = (modelIndex) => {
        props.setModelId(inReviewModels[modelIndex].modelid);
    }

    const previousIndex = modelIds.indexOf(selectedModelId) -1;
    const nextIndex = modelIds.indexOf(selectedModelId) +1;
    const disableLeft = previousIndex < 0;
    const disableRight = nextIndex > modelIds.length -1;
    if (props.side === 'Left' && !disableLeft) {
        return   (<ButtonLV className={styles.navButton} onClick={() => selectModel(previousIndex)} data-test='leftNav-mcaModelsComparison'>
                    {disableLeft ? '' : <Tooltip title="Previous model" placement="top"><img src={leftArrow} alt="Left-arrow"/></Tooltip>}
                </ButtonLV>);
    } else if (props.side === 'Right' && !disableRight) { 
        return  (<ButtonLV className={styles.navButton} onClick={() => selectModel(nextIndex)} data-test='rightNav-mcaModelsComparison'>
                    {disableRight ? '' : <Tooltip title="Next model" placement="top"><img src={rightArrow} alt="Right-arrow"/></Tooltip>}
                </ButtonLV>);
    }
    return null;
}

McaDetailsNav.propTypes = {
    models:PropTypes.array,
    selectedModelId: PropTypes.string,
    setModelId: PropTypes.func
};
export {McaDetailsNav};
