import {css} from 'emotion';

export const styles = {
  container: css`

  &.broadcast {
    display: grid;
    grid-template-columns: 1fr 350px;

    .interval-radio-button-container {
      align-self: end;
      justify-self: right;
      margin-bottom: 6px;
    }
  }

  .recharts-default-legend {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 4px;
    margin-left: 70px;
    li {
      padding: 4px 0;
    }
  }

  &.top-left-css {
    margin-left: 108px;
    padding-bottom: 28px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    align-items: center;

    .recharts-default-legend {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 4px;
      margin-right: -18px;
      justify-content: flex-end;
      padding: 0;

    }

  }

  `
};
