import React from 'react';
import {PieChart, Pie, Cell, Tooltip} from 'recharts';
import {getChartColorAtIndex} from './chartColors';
import {CustomTooltip} from './CustomTooltip';
import {useValidateChartData} from './useValidateChartData';
import {styles, getChartStateCss} from './charts.styles';

import PropTypes from 'prop-types';

/**
 * React Pie Chart Wrapper
 * http://recharts.org/en-US/api/PieChart
 *
 * EXAMPLE INPUT:
 * [{name: 'Slice1', value: 1234.0345, percentage: 45.456}]
 */
export const PieLV = ({data=[], isLoading=false, formatMap={}}) => {

  let chartData = useValidateChartData(data, 8, 'value', 'y');
  const chartStateCss = getChartStateCss(data, isLoading);

  // remove rows of 0 values to prevent most cases of repeat colors
  chartData = chartData.filter(row => {
    return row.value !== 0
  });

  // Color the slices of our pie
  // http://recharts.org/en-US/api/Cell
  const getCellWithColor = (row, index) => (
    <Cell
      key={`cell-${index}`}
      fill={getChartColorAtIndex(index)}
    />
  );
  const PieSlices = chartData.map(getCellWithColor);
  const classNames = [styles.chart, chartStateCss].join(' ');

  return (
    <div
      className={classNames}>

      <PieChart
        width={420} height={400}
        margin={{top: 0, right: 0, left: 0, bottom: 0}} >

        <Pie
          data={chartData}
          cx={'50%'}
          cy={'50%'}
          isAnimationActive={false}
          outerRadius={160}
          fill="#8884d8"
          dataKey="value" >

          {PieSlices}

        </Pie>
        <Tooltip content={<CustomTooltip map={formatMap}/>}/>
      </PieChart>
    </div>
  );
};

PieLV.propTypes = {
  /**
   * Needs fields {name, value, percentage}
   *   percentage should be > 1
   * EXAMPLE DATA:
   * [{name: 'Slice1', value: 1234.0345, percentage: 45.456}]
   */
  data: PropTypes.array,
  /**
   * Map of keys and the desired format the values will be in
   */
  formatMap: PropTypes.object
};
