import {
    R1Modal,
    R1Button,
    R1Form,
    R1AutoComplete,
    R1SubmitButton,
    useR1Form
} from '@rainagency/rain-one-soggy-loaf';
import React from "react";
import { useHotkeys } from 'react-hotkeys-hook'
import {styles} from "../../pages/mca/select-analysis/configure/create-analysis-form.styles";
import {Box} from "@mui/material";
import {useUserManagementRest} from '../../pages/user-management/useUserManagementRest';
import {useLoggedInUser} from "../../hooks/useLoggedInUser";
import {AuthToken} from "../../hooks/AuthFactory";
import {useUserRoleFromGroup} from "../../hooks/useUserRoleFromGroup";

const ImpersonateUserModal = ({}) => {

    const [open, setOpen] = React.useState(false);
    const { currentUser } = useLoggedInUser();
    const { isAdmin, userType} = useUserRoleFromGroup();
    const { users, putUpdateUser, updateUserResponse } = useUserManagementRest(currentUser?.username);

    useHotkeys('ctrl+shift+i', () => {
        if (isAdmin && userType === 'System') {
            setOpen(!open)
        }
    }, [open, isAdmin])

    const closeModal = () => {
        setOpen(false);
    }

    const onSubmit = (value) => {
        const {
            clientIds,
            email,
            ...userToSend
        } = {
            ...currentUser,
            companyIds: currentUser?.clientIds,
            emailAddress: currentUser?.email,
            impersonate: {
                user: value?.user?.value?.username,
                minutes: value?.minutes
            }
        }
        putUpdateUser({
            data: userToSend
        });
    }

    React.useEffect(() => {
        const refreshToken = async () => {
            if (updateUserResponse?.data) {
                await AuthToken.forceRenewToken();
                location.reload();
            }
        }

        refreshToken();
    }, [updateUserResponse])

    const userOptions = React.useMemo(() => {
        return users.map(user => {
            return {
                label: user.username,
                value: user
            }
        })
    }, [users]);

    return (
        <R1Modal
            ActionComponent={<span></span>}
            BodyComponent={(
                <ImpersonateUserModalBody
                    closeModal={closeModal}
                    onSubmit={onSubmit}
                    userOptions={userOptions}
                />
            )}
            customStyles={{
                width: '50%',
            }}
            open={open}
        />
    );
};

const ImpersonateUserModalBody = ({
    closeModal = () => {},
    onSubmit = () => {},
    userOptions = []
                                  }) => {

    const { methods } = useR1Form();

    return (
        <Box sx={{ padding: '24px'}}>
            <R1Form
                methods={methods}
                onSubmit={onSubmit} >
                <div className={styles.form}>
                    <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-end',
                            gap: '24px',
                            marginBottom: '24px'
                        }}>
                        <R1AutoComplete
                            label={'User to Impersonate'}
                            isOptionEqualToValue={(option, value) => {
                                return option.value === value?.value;
                            }}
                            name={'user'}
                            size={'small'}
                            options={userOptions}
                            fullWidth={true}
                        />
                        <R1AutoComplete
                            label={'Time in Minutes'}
                            isOptionEqualToValue={(option, value) => {
                                return option.value === value?.value;
                            }}
                            required={true}
                            defaultValue={'5'}
                            name={'minutes'}
                            size={'small'}
                            options={[
                                '5',
                                '10',
                                '15',
                                '30',
                                '60',
                            ]}
                            maxWidth={200}
                            minWidth={200}
                        />
                    </Box>

                    <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderTop: '1px solid #E0E0E0',
                            padding: '24px',
                            margin: '0 -24px -36px',
                        }}>

                        <R1Button color='inherit' size='large' onClick={closeModal}>Exit</R1Button>

                        <R1SubmitButton
                            color='secondary'
                            disabled={false}
                            disableIfClean={true}
                            className='save-edit-map-button'
                            size={'large'}>
                            Impersonate
                        </R1SubmitButton>
                    </Box>
                </div>
            </R1Form>
        </Box>
    );
}

export default ImpersonateUserModal;
