import React from 'react';
import {useInsightsQuery} from "../../../dashboards/hooks/useInsightsQuery";
import {filterSetDateRange, roundString} from "../../../../js/utils";
import {ScoringDashboardContext} from "../../ScoringDashboardContext";


export const useScoringContributionDashboard = ({
                                                    isCombined,
                                                    nestedRunKpis = [],
                                                }) => {

    const typePrefix = isCombined ? 'Combined' : '';

    const {
        selectedMcaRun,
        selectedScoringRun,
        startDate,
        endDate,
        region,
        scoringInsightMetadata,
        mcaInsightMetadata
    } = React.useContext(ScoringDashboardContext);

    const {
        id: scoringId
    } = selectedScoringRun;

    const {
        id: mcaRunId
    } = selectedMcaRun;

    // REST calls
    const {
        results: contributionsResults,
        fetch: fetchContributions,
        isLoading: isLoadingContributions
    } = useInsightsQuery({
        chartType: `${typePrefix}ContributionsOverTime`,
    });

    const {
        results: contributionsWithNegativesResults,
        fetch: fetchContributionsWithNegatives,
        isLoading: isLoadingContributionsWithNegatives
    } = useInsightsQuery({
        chartType: `${typePrefix}ContributionsOverTime`,
    });

    // Initiate calls
    React.useEffect(() => {
        if (mcaRunId && scoringId) {
            const ignoreCols = nestedRunKpis
                .map(kpi => kpi.replace('_contributions', ''));

            let regions = [];
            if (scoringInsightMetadata.regions?.length > 1 && region && region !== 'All') {
                ignoreCols.push('Base');
                ignoreCols.push('Base_with_Variance');
                regions = [region];
            }

            const additionalPayload = {
                runId: mcaRunId,
                scoringId,
                filters: {
                    regions
                }
            }

            fetchContributions(null, {
                chartParams: {
                    variableNameFormatType: 'Group',
                    ignoreCols
                },
                ...additionalPayload
            });
            fetchContributionsWithNegatives(null, {
                chartParams: {
                    includeNegatives: true,
                    variableNameFormatType: 'Group',
                    ignoreCols
                },
                ...additionalPayload
            });
        }
    }, [mcaRunId, scoringId, region,  nestedRunKpis]);

    // Parse results
    const contributionsData = React.useMemo(() => {
        if (!scoringId) {
            return []
        }
        const data = filterSetDateRange(contributionsResults?.results, startDate, endDate) || [];
        return data.map(mapContributionDate);
    }, [contributionsResults, startDate, endDate, scoringId]);

    const contributionsWithNegativesData = React.useMemo(() => {
        if (!scoringId) {
            return []
        }
        const data = filterSetDateRange(contributionsWithNegativesResults?.results, startDate, endDate) || [];
        return data.map(mapContributionDate);
    }, [contributionsWithNegativesResults, startDate, endDate, scoringId]);

    const modelData = React.useMemo(() => {
        if (!scoringInsightMetadata || !mcaInsightMetadata) {
            return [];
        }

        return [
            {
                modelSummary: 'Selected Model',
                mape: mcaInsightMetadata.mape === 'Infinity' ? 'Infinity' : roundString(mcaInsightMetadata.mape, 4),
                rsq: roundString(mcaInsightMetadata.rSquared, 4),
                minFold: roundString(mcaInsightMetadata.minFold, 4),
            },
            {
                modelSummary: 'Scored Data',
                mape: scoringInsightMetadata.mape === 'Infinity' ? 'Infinity' : roundString(scoringInsightMetadata.mape, 4),
                rsq: roundString(scoringInsightMetadata.rSquared, 4),
                minFold: roundString(scoringInsightMetadata.minFold, 4),
            }
        ]
    }, [scoringInsightMetadata, mcaInsightMetadata]);

    // Loading state
    const isLoading = React.useMemo(() => {
        return [
            isLoadingContributions,
            isLoadingContributionsWithNegatives
        ].some(isLoad => isLoad);
    }, [isLoadingContributions, isLoadingContributionsWithNegatives]);

    return {
        contributions: contributionsData,
        contributionsWithNegatives: contributionsWithNegativesData,
        modelData,
        isLoading
    };
}

function mapContributionDate(row) {
    const {
        Actual,
        ...rest
    } = row;

    return rest;
}