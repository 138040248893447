import React from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import {useForm} from 'react-hook-form';
import EmailIcon from '@mui/icons-material/Email';
import _ from 'lodash';
import {CheckboxLV} from '../../components/form/CheckboxLV';
import {TitleLV} from '../../components/form/title/TitleLV';
import {TextInputLV} from '../../components/form/text/TextInputLV';
import {SelectLV, mapToOptions} from '../../components/form/select/SelectLV';
import {FormRowLV} from '../../components/form/row/FormRowLV';
import {ButtonLV} from '../../components/form/buttons/ButtonLV';
import {OrangeButtonLV} from '../../components/form/buttons/OrangeButtonLV';
import {isTopLevel, isPartnerLevel, isClientLevel, TYPES} from '../../js/user-types';
import {isAdminRole, isAdvancedRole, isRegularRole, isTrialRole, ROLES} from '../../js/user-roles';
import {useUserManagementRest} from './useUserManagementRest';
import {isEmail} from '../../js/validation';
import {SuccessFailStatusModal} from '../../components/modal/SuccessFailStatusModal';
import {PRODUCT} from '../../constants/products'

import {styles} from './UserManagementForm.styles';

export const UserManagementForm = ({
  closeModal, 
  isEdit=false, 
  values={},
  refetchUsers=()=> {}
}) => {
  const {
    users=[], 
    partners=[], 
    isLoading,
    postCreateUser,
    createUserResponse={},
    putUpdateUser,
    updateUserResponse={},
    resendUserInvite,
    resendUserInviteResponse={}
  } = useUserManagementRest(values.username);
  const userEmails = users.map(user => user.email);
  const userNames = users.map(user => user.username);

  const [status, setStatus] = React.useState(0);   // 0 = form | 1 = success | 2 = error
  const defaultResendMessage = {message: 'Resend User Invite'};
  const [resendMessage, setResendMessage] = React.useState(defaultResendMessage);
 
  //User Groups & permission
  let {groups, partnerIds, clientIds, id, ...defaultValues} = values;
  delete defaultValues['index'];
  delete defaultValues['edit'];
  delete defaultValues['delete'];

  const [firstName, setFirstName] = React.useState(defaultValues.firstName);
  const [lastName, setLastName] = React.useState(defaultValues.lastName);
  const [username, setUsername] = React.useState(defaultValues.username);
  const [email, setEmail] = React.useState(defaultValues.email);

  if (isClientLevel(groups)) {
    defaultValues.selectedType = TYPES.CLIENT;
  } else if (isPartnerLevel(groups)) {
    defaultValues.selectedType = TYPES.PARTNER;
  } else if (isTopLevel(groups)) {
    defaultValues.selectedType = TYPES.LEAVENED;
  }

  if (isTrialRole(groups)) {
    defaultValues.selectedRole = ROLES.TRIAL_USER;
  } else if (isRegularRole(groups)) {
    defaultValues.selectedRole = ROLES.REGULAR_USER;
  } else if (isAdvancedRole(groups)) {
    defaultValues.selectedRole = ROLES.ADVANCED_USER;
  } else if (isAdminRole(groups)) {
    defaultValues.selectedRole = ROLES.ADMIN;
  }

  const [selectedType, setSelectedType] = React.useState(defaultValues.selectedType);
  const [selectedRole, setSelectedRole] = React.useState(defaultValues.selectedRole);

  defaultValues.experimental = defaultValues.experimental || false;
  const [experimental, setExperimental] = React.useState(defaultValues.experimental);

  defaultValues.adImpactPermission = checkForGroup(groups, PRODUCT.ADIMPACT.userGroup);
  const [adImpactPermission, setAdImpactPermission] = React.useState(defaultValues.adImpactPermission);

  defaultValues.mmmPermission = checkForGroup(groups, PRODUCT.MMM.userGroup);
  const [mmmPermission, setMmmPermission] = React.useState(defaultValues.mmmPermission);

  defaultValues.wavecastPermission = checkForGroup(groups, PRODUCT.MASLOW.userGroup);
  const [wavecastPermission, setWavecastPermission] = React.useState(defaultValues.wavecastPermission);

  const isAdminOverride = defaultValues.selectedType === TYPES.LEAVENED && defaultValues.selectedRole === ROLES.ADMIN;
  const [adminPermissionsOverride, setAdminPermissionsOverride] = React.useState(isAdminOverride);
  
  //Partners & clients
  const defaultPartners = React.useMemo(() => 
    partners?.filter(partnerElm => partnerIds?.includes(partnerElm.id))
  , [partners]);
  defaultValues.partner = defaultPartners?.length ? {label: defaultPartners[0]?.display, value: defaultPartners[0]?.id} : null;

  const partnerOptions =  React.useMemo(() => 
    partners.map(partnerElm => ({
      label: partnerElm.display,
      value: partnerElm.id
    }))
  , [partners]);

  const defaultClients =  React.useMemo(() => 
    partners
      .find(partnerElm => partnerElm?.id === defaultValues.partner?.value)?.companies
      .filter(client => (clientIds?.includes(client.id)))
  , [partners]);
  defaultValues.client = defaultClients?.length ? {label: defaultClients[0]?.display, value: defaultClients[0]?.id} : null
  
  const [partner, setPartner] = React.useState(null); 
  const [client, setClient] = React.useState(null);
  const [clientOptions, setClientOptions] = React.useState([]); 
  const [dirty, setDirty] = React.useState(false);

  const {handleSubmit, formState, register, errors, control, setValue, getValues, reset} = useForm({
    defaultValues
  });
  const {isSubmitting} = formState;

  useDeepCompareEffect(() => {
    setPartner(defaultValues.partner);
    setClient(defaultValues.client);
    reset({...defaultValues});
  },[defaultValues]);

  const updateClientOptions = (selectedPartner) => {
    let options = [];
    if (selectedPartner) {
      const clients = partners.find(partnerElm => partnerElm.id === selectedPartner?.value)?.companies || [];
      options = clients.map(client => ({
        label: client.display,
        value: client.id
      }));
    }
    setClientOptions(options);
  }

  React.useEffect(() => {
    updateClientOptions(partner);
  },[partner]);
  
  // useEffect with curry function executes when component is unmounted from Document
  React.useEffect(() => () => {
    refetchUsers();
  },[refetchUsers]);
  
  // Display form/success/fail based on REST return status on POST
  React.useEffect(() => {
    if(createUserResponse.loading || updateUserResponse.loading) {
      return;
    }

    // Show Error Message
    if(createUserResponse.error || updateUserResponse.error) {
      setStatus(2);
      return;
    }

    // Show Success Message
    if(createUserResponse.data || updateUserResponse.data) {
      setStatus(1);
    }
  }, [
    createUserResponse.loading, 
    createUserResponse.error, 
    createUserResponse.data, 
    updateUserResponse.loading, 
    updateUserResponse.error, 
    updateUserResponse.data
  ]);

  // Runs on updates to resendUserInviteResponse
  React.useEffect(() => {
    if (resendUserInviteResponse?.error?.response?.data?.errorCode === 'UnsupportedUserStateException') {
      setResendMessage({message: 'User Already Set Password'});
    }

    if(resendUserInviteResponse?.response?.status === 200) {
      setResendMessage({message: 'Email Sent'});
    }

    // Reset back to default after 30 seconds
    setTimeout(function() { setResendMessage(defaultResendMessage); }, 30000);
  }, [resendUserInviteResponse])

  const types = Object.values(TYPES);
  const userTypeOptions = mapToOptions(types, 'value', 'label');
  
  const roleOptions = () => {
    const roles = Object.values(ROLES);
    const userRoleOptions = mapToOptions(roles, 'value', 'label');

    let filteredRoleOptions = userRoleOptions;

    //remove trial user from options for non-client 
    if (!isClientLevel([selectedType?.value])) {
      filteredRoleOptions = filteredRoleOptions.filter(role => role.value !== ROLES.TRIAL_USER.value);
    }

    //remove regular user from options for admin
    if (isTopLevel([selectedType?.value])) {
      filteredRoleOptions = filteredRoleOptions.filter(role => role.value !== ROLES.REGULAR_USER.value);
    }

    //remove wavecast user from options for non-admin
    if (!isTopLevel([selectedType?.value])) {
      filteredRoleOptions = filteredRoleOptions.filter(role => role.value !== ROLES.WAVECAST_USER.value);
    }
    return filteredRoleOptions;
  }

  // Select functions
  const typeSelect = (selected) => {
    if (!_.isEqual(selected, selectedType)) {
      setValue('selectedType', selected, {shouldDirty: true});
      setSelectedType(selected);
    }
  };

  const roleSelect = (selected) => {
    if (!_.isEqual(selected, selectedRole)) {
      setValue('selectedRole', selected, {shouldDirty: true});
      setSelectedRole(selected);
      delete errors['selectedType'];
    }
  };

  React.useEffect(() => {
    let partnerValue = null;
    let clientValue = null;
    if (selectedType?.label === 'Client') {
        partnerValue = partner || defaultValues.partner;
        clientValue = client || defaultValues.client;
    }
    if (selectedType?.label === 'Partner') {
        partnerValue = partner || defaultValues.partner;
    }
    setValue('partner', partnerValue, {shouldDirty: true});
    setPartner(partnerValue);
    setValue('client', clientValue, {shouldDirty: true});
    setClient(clientValue);
  }, [selectedType]);

  React.useEffect(() => {
    if(selectedType?.value === TYPES.LEAVENED.value && selectedRole?.value === ROLES.ADMIN.value){
      setAdminPermissionsOverride(true);
    } else {
      setAdminPermissionsOverride(false);
    }
  },[selectedType, selectedRole]);

  const partnerSelect = (selected) => {
    if (!_.isEqual(selected, partner)) {
      setValue('partner', selected, {shouldDirty: true});
      setValue('client', null, {shouldDirty: true});

      setPartner(selected);
      setClient(null);
    }
  };

  const clientSelect = (selected) => {
    if (!_.isEqual(selected, client)) {
      setValue('client', selected, {shouldDirty: true});
      setClient(selected);
    }
  };

  const onChange = event => {
    switch(event.target.name) {
      case 'firstName':
        setFirstName(event.target.value);
        break;
      case 'lastName':
        setLastName(event.target.value);
        break;
      case 'username':
        setUsername(event.target.value);
        break;
      case 'email':
        setEmail(event.target.value);
        break;
      default:
    }
    setValue(event.target.name, event.target.value, {shouldDirty: true});
  };

  const onChangeCheckbox = event => {
    switch(event.target.name) {
      case 'experimental':
        setExperimental(event.target.checked);
        break;
      case 'adImpactPermission':
        setAdImpactPermission(event.target.checked);
        break;
      case 'mmmPermission':
        setMmmPermission(event.target.checked);
        break;
      case 'wavecastPermission':
        setWavecastPermission(event.target.checked);
        break;
      default:
    }
  };

  React.useEffect(() => {
    setDirty(
      !_.isEqual(firstName, defaultValues.firstName) ||
      !_.isEqual(lastName, defaultValues.lastName) ||
      !_.isEqual(username, defaultValues.username) ||
      !_.isEqual(email, defaultValues.email) ||
      !_.isEqual(selectedType,defaultValues.selectedType) ||
      !_.isEqual(selectedRole,defaultValues.selectedRole) ||
      !_.isEqual(partner, defaultValues.partner) ||
      !_.isEqual(client, defaultValues.client) ||
      defaultValues.experimental !== experimental || 
      defaultValues.adImpactPermission !== adImpactPermission || 
      defaultValues.mmmPermission !== mmmPermission || 
      defaultValues.wavecastPermission !== wavecastPermission
    );
  }, [defaultValues, firstName, lastName, username, email, 
      selectedType, selectedRole, partner, client,
      experimental, adImpactPermission, mmmPermission, wavecastPermission]);

  const resendInvite = () => {
    resendUserInvite({
      data: {
        username: defaultValues.username
      }
    });
  }

  const getResendInviteButton = () => {
    if (isEdit) {
      if (resendMessage.message === defaultResendMessage.message) {
        return (
            <div className={styles.resendContainer} onClick={resendInvite}>
              <EmailIcon></EmailIcon><span className={styles.resendMessage}>{resendMessage.message}</span> 
            </div>
        )
      } else {
        return (
            <div className={styles.resendContainerEmailSent}>
              <EmailIcon></EmailIcon><span className={styles.resendMessage}>{resendMessage.message}</span>
            </div>
        )
      }
    }
    return null;
  }

  const getUsernameField = () => {
    if (!isEdit) {
      return (
          <FormRowLV>
            <TextInputLV
                name='username'
                disabled={isEdit}
                placeholder='User Name'
                dataTest='userName-add-new-form'
                description='250 Characters Max'
                register={
                  register(
                      {
                        required: 'User Name is required',
                        maxLength: {
                          value: 250,
                          message: 'Cannot exceed 250 characters'
                        },
                        validate: {
                          isUniqueUsername: (username) => validateUnique(isEdit, username, userNames) || 'Username already exists'
                        }
                      }
                  )}
                onChange={onChange}
                errors={errors}
                width={315}
            />
          </FormRowLV>
      )
    }
    return null;
  }

  // Success Confirmation
  if(status === 1) {
    return (
      <SuccessFailStatusModal
        isError={false}
        title={isEdit ? 'User Updated' : 'New User Added'}
        description={`User '${username}' has been successfully ${isEdit ? 'updated' : 'added'}.`}>

        <FormRowLV>
          <OrangeButtonLV
            dataTest='addNewComplete-addAnotherButton'
            onClick={()=>(setStatus(0))}
            hidden={isEdit}>
            ADD ANOTHER
          </OrangeButtonLV>

          <ButtonLV
            onClick={closeModal}
            dataTest='addNewComplete-closeButton'>
            CLOSE
          </ButtonLV>
        </FormRowLV>        
      </SuccessFailStatusModal>
    );
  }

  // Display Error
  if(status === 2) {
    const errMessage = _.get(createUserResponse, 'error.response.data.message', '');
    return (
      <SuccessFailStatusModal
        isError={true}
        title={isEdit ? 'User Not Updated' : 'New User Not Added'}
        description={`User '${username}' Failed to be ${isEdit ? 'updated' : 'added'}.  ${errMessage}`}>

        <FormRowLV>
          <ButtonLV
            onClick={closeModal}
            dataTest='addNewComplete-closeButton'>
            CLOSE
          </ButtonLV>
        </FormRowLV>        
      </SuccessFailStatusModal>
    );
  }

  // Form submit
  const formSubmit = (formValues={}) => {
    const {
      firstName,
      lastName,
      username,
      email,
      selectedRole={},
      selectedType={},
      partner={},
      client={}
    } = formValues;

    // Remove 'Leavened' word form group name
    const type = isTopLevel([selectedType?.value]) ? '' : selectedType?.value;
    const groups = selectedRole?.value ? [type + selectedRole?.value] : [];
    const partnerIds = [partner.id || partner.value].filter(partner => partner);
    const companyIds = [client.id || client.value].filter(client => client);
    const emailAddress = email;
    const payload = {
      firstName, lastName, username, emailAddress, partnerIds, companyIds, groups, experimental, adImpactPermission, mmmPermission, wavecastPermission
    };

    if (isEdit) {
      putUpdateUser({
        data: {...payload, id}
      });
    } else {
      postCreateUser({
        data: payload
      });
    }
  };

  // Form
  return (
    <form autoComplete="off"
      onSubmit={handleSubmit(formSubmit)}>
      <TitleLV className={styles.titleContainer}>{isEdit ? `Editing ${values.username}` : 'Add New User'}</TitleLV>

      <FormRowLV>
        <TextInputLV
          name='firstName'
          placeholder='First Name'
          dataTest='firstName-add-new-form'
          register={
            register(
              {required: 'First Name is required'}
            )}
          onChange={onChange}
          errors={errors}
          width={315}
        />
      </FormRowLV>
      
      <FormRowLV>
        <TextInputLV
          name='lastName'
          placeholder='Last Name'
          dataTest='lastName-add-new-form'
          register={
            register(
              {required: 'Last Name is required'}
            )}
          onChange={onChange}
          errors={errors}
          width={315}
        />
      </FormRowLV>

      {getUsernameField()}

      <FormRowLV>
        <TextInputLV
          name='email'
          disabled={isEdit}
          placeholder='Email'
          description='250 Characters Max'
          dataTest='email-add-new-form'
          register={
            register(
              {
                required: 'Email is required',
                maxLength: {
                  value: 250,
                  message: 'Cannot exceed 250 characters'
                },
                validate: {
                  isValidEmail: (email) => isEmail(email) || 'Enter a valid email address',
                  isUniqueEmail: (email) => validateUnique(isEdit, email, userEmails) || 'Email Address already exists'
                }
              }
            )}
          onChange={onChange}
          errors={errors}
          width={315}
        />
      </FormRowLV>

      <FormRowLV>
        <SelectLV
          id='type-select'
          control={control}
          name='selectedType'
          placeholder='Select Type'
          options={userTypeOptions}
          rules={
            { required: 'Select User Type',
              validate: { 
                isRegularRole: value => validateRegularRole(getValues('selectedRole'), value)
                  || 'System User Type cannot be used with Regular User Role',
                isTrialRole: value => validateTrialRole(getValues('selectedRole'), value)
                  || 'The selected User Type cannot be used with Trial User Role - must be a Client user',
                isWavecastRole: value => validateWavecastRole(getValues('selectedRole'), value) 
                  || 'The selected User Type cannot be used with Wavecast User Role'
              }
            }
          }          
          onChange={typeSelect}
          errors={errors}
        />
      </FormRowLV>

      <FormRowLV>
        <SelectLV
          id='role-select'
          control={control}
          name='selectedRole'
          placeholder='Select Role'
          options={roleOptions()}
          rules={{required: 'Select User Role'}}
          onChange={roleSelect}
          errors={errors}
        />
      </FormRowLV>

      <FormRowLV
        id='partner-select-row'
        hidden={!isPartnerLevel([selectedType?.value]) && !isClientLevel([selectedType?.value])}>
        <SelectLV
          id='partner-select'
          control={control}
          name='partner'
          placeholder='Select Partner'
          options={partnerOptions}
          rules={{required: 'Select a Partner'}}
          onChange={partnerSelect}
          errors={errors}
          isLoading={isLoading}
        />
      </FormRowLV>

      <FormRowLV
        id='client-select-row'
        hidden={!isClientLevel([selectedType?.value])}>
        <SelectLV
          id='client-select'
          control={control}
          name='client'
          placeholder='Select Client'
          options={clientOptions}
          rules={{required: 'Select a Client'}}
          onChange={clientSelect}
          errors={errors}
          isLoading={isLoading}
        />
      </FormRowLV>

      <FormRowLV>
        <CheckboxLV
          name='experimental'
          label='Experimental User'
          darkTheme={true}
          checked={experimental}
          onChange={onChangeCheckbox}
          dataTest='experimental-checkbox'>
        </CheckboxLV>
      
        <CheckboxLV
          name='adImpactPermission'
          label='AdImpact Permission'
          disabled={adminPermissionsOverride}
          darkTheme={true}
          checked={adminPermissionsOverride || adImpactPermission}
          onChange={onChangeCheckbox}
          dataTest='adImpact-checkbox'>
        </CheckboxLV>
      </FormRowLV>

      <FormRowLV>
        <CheckboxLV
          name='mmmPermission'
          label='MMM Permission'
          disabled={adminPermissionsOverride}
          darkTheme={true}
          checked={adminPermissionsOverride || mmmPermission}
          onChange={onChangeCheckbox}
          dataTest='mmm-checkbox'>
        </CheckboxLV>

        <CheckboxLV
          name='wavecastPermission'
          label='Wavecast Permission'
          disabled={adminPermissionsOverride}
          darkTheme={true}
          checked={adminPermissionsOverride || wavecastPermission}
          onChange={onChangeCheckbox}
          dataTest='wavecast-checkbox'>
        </CheckboxLV>
      </FormRowLV>

      <FormRowLV>
        <OrangeButtonLV
          submitting={isSubmitting || createUserResponse.loading || updateUserResponse.loading}
          isDisabled={!_.isEmpty(errors)}
          dirty={dirty}
          dataTest='addNew-submitButton'>
          {isEdit ? 'Update User' : 'Add User'}
        </OrangeButtonLV>

        <ButtonLV
          onClick={closeModal}
          submitting={isSubmitting || createUserResponse.loading || updateUserResponse.loading}
          dataTest='cancel-button'>
          Cancel
        </ButtonLV>
      </FormRowLV>

      {getResendInviteButton()}

    </form>);
};

const checkForGroup = (arrayToCheck = [], groupToFind) => {
  return arrayToCheck.some(element => element.includes(groupToFind)) || false;
}

// Confirm email and username do not already exist, return true if is invalid
export const validateUnique = (isEdit, field, userList=[]) => {
  if(typeof field !== 'string' || isEdit) {
    return true;
  }
  const compareField = field.toLowerCase();
  const compareList = userList.filter(value => typeof value === 'string');
  return !compareList.some(value => value.toLowerCase() === compareField);
};

//Role cannot be regular user for Leavened Type
export const validateRegularRole = (role, type) => {
  return !(isTopLevel([type?.value]) && role?.value === ROLES.REGULAR_USER.value);
};

//Wavecast Role only available for Leavened (system) Type
export const validateWavecastRole = (role, type) => {
  return !(!isTopLevel([type?.value]) && role?.value === ROLES.WAVECAST_USER.value);
};

//Trial Role only available for Client type
export const validateTrialRole = (role, type) => {
  return !(!isClientLevel([type?.value]) && role?.value === ROLES.TRIAL_USER.value);
};
