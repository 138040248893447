import {css} from 'emotion';

export const styles = {
    icon: css`
        padding-right: 10px;
    `,
    message: css`
        display: flex;
        white-space: pre-line;
    `
};
