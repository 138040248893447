import React from 'react';
import _ from 'lodash';
import {TableLV} from '../../../components/data-table/TableLV';
import {numberWithCommas, roundString} from '../../../js/utils.js';
import {CHART_FORMAT} from '../../../components/charts/ChartFormat.constant';
import {GraphContainerLV} from '../../../components/dashboards/GraphContainerLV';
import {Chiclet} from '../../../components/dashboards/Chiclet';
import {ChicletTitle} from '../../../components/dashboards/ChicletTitle';
import {GraphConfig} from '../../../components/dashboards/GraphConfig';
import {GRAPH_OPTIONS} from '../../../components/dashboards/graph-options.constant';
import {getDummyData} from '../../../components/data-table/getDummyData';
import {EditCostPerCell} from './EditCostPerCell';

export const RoiDashboard = ({
                                 isRevenue,
                                 roiChartData = [],
                                 titlePrefix = '',
                                 loading,
                             }) => {
    const [costPer, setCostPer] = React.useState({});

    const setCost = (event = {target: {}}) => {
        const tactic = event.target.name;
        const value = event.target.value;
        if (tactic) {
            setCostPer({...costPer, [tactic]: value});
        }
    };

    const tableTitle = isRevenue ? 'ROI Summary' : 'Cost per Acquisition Summary';
    const tableDefaultRow = {
        tactic: '',
        costPer: '',
        spend: '',
        kpi: '',
        costPerAcquisition: '',
        prctcontributions: ''
    };

    const {
        data: contributionsData,
        graphOptions: graphOptions
    } = React.useMemo(() => {
        const data = roiChartData.map(item => ({
            'name': item['tactic'],
            'percentage': round(Number(item['prctcontributions'])*100),
            'value': round(Number(item[isRevenue ? 'revenue' : 'kpi']))
        }));

        let graphOptions = [
            GRAPH_OPTIONS.PIE,
            GRAPH_OPTIONS.WATERFALL
        ];

        if (data.some(item => item['percentage'] < 0)) {
            graphOptions.shift()
        }
        return {
            data,
            graphOptions
        };
    }, [roiChartData, isRevenue])

    const override = {
        costPer: {
            name: 'Cost Per',
            component: <CostPerInput setCost={setCost} costPer={costPer}/>,
            align: 'center',
            headerStyle: {
                width: '90px',
            }
        },
        spend: {
            align: 'center',
            component: <Spend costPer={costPer}/>
        },
        revenue: {
            align: 'center',
            component: <CurrencyValue/>,
        },
        kpi: {
            name: 'KPI',
            component: <KpiValue/>,
            align: 'center'
        },
        roi: {
            name: 'ROI',
            component: <RoiValue costPer={costPer}/>,
            align: 'center'
        },
        costPerAcquisition: {
            name: <div>Cost Per<br/>Acquisition</div>,
            component: <CpaValue costPer={costPer}/>,
            align: 'center'
        },
        prctcontributions: {
            hidden: true
        },
        impressions: {
            hidden: true
        }
    };

    const config = new GraphConfig({
        title: `${titlePrefix}Contribution`,
        data: contributionsData,
        graphOptions: graphOptions,
        xAxisLabel: 'Contribution',
        yAxisLabel: isRevenue ? 'Revenue' : 'KPI',
        xAxisKey: 'percentage',
        yAxisKey: 'value',
        xTicks: roiChartData?.length * 5,
        yTicks: roiChartData?.length * 5,
        imageElementId: 'contribution-summary-chiclet',
        imageFileName: 'Contributions',
        formatMap: {
            'percentage': CHART_FORMAT.PERCENT,
            'value': isRevenue ? CHART_FORMAT.CURRENCY : CHART_FORMAT.INTEGER
        },
        isLoading: loading
    });

    return (
        <Chiclet
            id='contribution-summary-chiclet'>
            <GraphContainerLV
                graphConfig={config}>
                <div className='contribution-table-container'>
                    <ChicletTitle>{titlePrefix}{tableTitle}</ChicletTitle>
                    <TableLV
                        data={roiChartData?.length ? roiChartData : getDummyData(tableDefaultRow, 3)}
                        override={override}
                        isLoading={loading}
                    />
                </div>
            </GraphContainerLV>
        </Chiclet>
    );
}

export const CostPerInput = (({cell, setCost, costPer}) => {
    const spend = Number(_.get(cell, 'row.values.spend'));
    const impressions = Number(_.get(cell, 'row.values.impressions'));
    const tactic = _.get(cell, 'row.values.tactic');
    if (spend !== 0 || !impressions || !tactic || tactic === 'Base') {
        return null;
    }
    return (<EditCostPerCell cell={cell} setCostPer={setCost} costPer={Number(costPer[tactic]).toFixed(2)}
                             placeholder={"$"}/>);
});

export const Spend = (({cell, costPer}) => {
    const tactic = _.get(cell, 'row.values.tactic');
    const impressions = Number(_.get(cell, 'row.values.impressions'));
    let spend = Number(cell.value);
    if (impressions && !spend) {
        spend = Number(costPer[tactic]) * impressions;
    }
    return (spend ? <div>$ {numberWithCommas(roundString(spend, 0))}</div> : '');
});

export const CurrencyValue = (({cell, decimals = 0}) => Number(cell.value) ?
    <div>$ {numberWithCommas(roundString(cell.value, decimals))}</div> : '');

export const KpiValue = (({cell}) => Number(cell.value) ?
    <div> {numberWithCommas(roundString(cell.value, 0))}</div> : '');

export const RoiValue = (({cell, costPer}) => {
    const tactic = _.get(cell, 'row.values.tactic');
    const impressions = Number(_.get(cell, 'row.values.impressions'));
    let value = Number(cell.value);
    const spend = Number(costPer[tactic]) * impressions;
    if (!value && spend) {
        value = Number(_.get(cell, 'row.values.revenue')) / spend;
    }
    return value ? <div>{numberWithCommas(roundString(value, 2))}</div> : '';
});

export const CpaValue = (({cell, costPer}) => {
    const tactic = _.get(cell, 'row.values.tactic');
    const impressions = Number(_.get(cell, 'row.values.impressions'));
    const kpi = Number(_.get(cell, 'row.values.kpi'));
    let value = Number(cell.value);
    if (!value && kpi) {
        const spend = Number(costPer[tactic]) * impressions;
        value = spend / kpi;
    }
    return value ? <div>$ {numberWithCommas(roundString(value, 2))}</div> : '';
});

const round = (num) => (Math.round((num + Number.EPSILON) * 100) / 100);