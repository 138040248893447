import React, { useState } from 'react';
import { useLocation } from "react-router-dom";
import { R1Stepper } from '@rainagency/rain-one-soggy-loaf';
import { FeatureLV } from '../../../components/pages/FeatureLV';
import { SelectDataForm } from './select-data/select-data-form';
import { CreateAnalysisForm } from './configure/create-analysis-form';
import { ConfigureAnalysis } from './configure/configure-analysis';
import { RunAnalysis } from './run-analysis/run-analysis';

import {styles} from './select-analysis.styles';

export const SelectAnalysis = () => {
    const location = useLocation();
    const { isReRun, runId, adImpactId, analysisStartType } = location?.state || {};
    const [runParameters, setRunParameters] = useState({});
    const [analysisValues, setAnalysisValues] = useState();
    const [configValues, setConfigValues] = useState();
    const [hideConfigStep, setHideConfigStep] = useState();

    const isReadOnly = (!!runId || !!adImpactId) && !isReRun;

    /* Temporary skipping Select-Data step */
    const isLoading = SelectDataForm({setRunParameters});

    let steps = [
        // {
        //     label: 'Select Data',
        //     hideButtons: true,
        //     Component: (
        //         <SelectDataForm
        //             stepForward
        //             setRunParameters={ setRunParameters } />
        //     )
        // }, 
        {
            label: 'Create Analysis',
            hideButtons: true,
            Component: (
                <CreateAnalysisForm 
                    stepForward
                    stepBack
                    analysisStartType={ analysisStartType }
                    runId={ runId }
                    adImpactId = { adImpactId }
                    disabled={ isReadOnly || isLoading }
                    runParameters={ runParameters } 
                    analysisValues={ analysisValues }
                    setAnalysisValues={ setAnalysisValues }
                    setHideConfigStep={ setHideConfigStep } />
            )
        },
        {
            label: 'Configure Analysis',
            hideButtons: true,
            Component: (
                <ConfigureAnalysis 
                    stepForward
                    stepBack
                    runId={ runId }
                    disabled={ isReadOnly }
                    configValues={ configValues }
                    setConfigValues={ setConfigValues } 
                    analysisValues={ analysisValues }
                    setAnalysisValues={ setAnalysisValues } />
            )
        },
        {
            label: 'Run Analysis',
            hideButtons: true,
            Component: (
                <RunAnalysis
                    analysisValues={ analysisValues }
                    configValues={ configValues } />
            )
          }
    ];

    if (hideConfigStep) {
        steps = steps.filter(step => step.label !== 'Configure Analysis');
    }

    let title = 'New Analysis';
    if (isReadOnly) {
        steps = steps.filter(step => step.label !== 'Select Data' && step.label !== 'Run Analysis');
        title = 'View Analysis';
    }

    return (
        <FeatureLV
            title={ title }
            sx={{
                    '.feature-children': {
                        'overflowY': 'hidden'
                } 
            }}
        >
            <div className={styles.stepper}>
                <R1Stepper steps={steps} />
            </div>
        </FeatureLV>
    );
}
