import {useLocation, useHistory} from 'react-router-dom';
import queryString from 'query-string';
import {PRODUCT_URL_PARAM, MCA_RUN_ID_URL_PARAM, AD_IMPACT_ID_URL_PARAM} from './useCampaignManagement';
import {PRODUCT, LIMITED_PRODUCT, TRIAL_PRODUCT} from '../constants/products';
import {useUserRoleFromGroup} from '../hooks/useUserRoleFromGroup';

export const DEFAULT_PRODUCT = PRODUCT.MMM;
export const DEFAULT_LIMITED_PRODUCT = LIMITED_PRODUCT.MMM;
export const DEFAULT_TRIAL_PRODUCT = TRIAL_PRODUCT.MMM;
export const DEFAULT_MASLOW_PRODUCT = PRODUCT.MASLOW;

/**
 * Hook to handle selected product from the URL
 * @returns {selectedProduct} current selected product based on URL Path
 */
export const useProduct = () => {
  const {isRegular, isWavecast, isTrial, hasMMM, hasAdImpact, isMarketingTools} = useUserRoleFromGroup();
  const location = useLocation() || {search:''};
  const query = queryString.parse(location.search);
  return getProductByKey(query[PRODUCT_URL_PARAM], isRegular, isWavecast, isTrial, hasMMM, hasAdImpact, isMarketingTools);
};

/**
 * Hook to set the currently selected product in the url
 * @returns Function that can set a product key to the current URL
 */
export const useSetProduct = () => {
  const location = useLocation();
  const history = useHistory();

  return (key) => {
    const productPath = PRODUCT[key].path;
    const query = queryString.parse(location.search);
    query[PRODUCT_URL_PARAM] = key;
    const urlParams = queryString.stringify(query);
    const path = `${productPath}?${urlParams}`;
    history.push(path);
    return path;
  };
};

/**
 * Hook to redirect to selected a path, include all URL params
 * @returns Function that goes to a given path while keeping url query
 */
export const useSetPath = () => {
  const location = useLocation();
  const history = useHistory();

  return (path) => {
    const fullPath = `${path}${location.search}`;
    history.push(fullPath);
    return fullPath;
  };
};

/**
 * Hook to redirect to home and removes selected Runs from url
 * @returns Function that redirects home
 */
export const useRedirectHome = () => {
  const location = useLocation();
  const history = useHistory();

  return () => {
    const path = '/';
    const query = queryString.parse(location.search);
    delete query[MCA_RUN_ID_URL_PARAM];
    delete query[AD_IMPACT_ID_URL_PARAM];

    const urlParams = queryString.stringify(query);
    const fullPath = `${path}?${urlParams}`;
    history.push(fullPath);
    return fullPath;
  };
};

/**
 * Determine which product tab matches the url path
 * @param key Product key
 * @returns Tab matching url path if applicable
 */
export const useGetTabFromUrl = (key) => {
  const path = useLocation().pathname;
  const {isRegular, isTrial} = useUserRoleFromGroup();
  if (isRegular) {
    return LIMITED_PRODUCT[key].tabs.find(t => t.path === path) || DEFAULT_LIMITED_PRODUCT.tabs[0];
  }

  if (isTrial) {
    return TRIAL_PRODUCT[key].tabs.find(t => t.path === path) || DEFAULT_TRIAL_PRODUCT.tabs[0];
  }

  if(!key || !PRODUCT[key]) {
    return undefined;
  }
  return PRODUCT[key].tabs.find(t => t.path === path);
};

/**
 * Get a product by its key, return default if it does not match
 * @param key Product key
 * @returns Product object that matches or default product
 */
export const getProductByKey = (key, isRegular, isWavecast, isTrial, hasMMM, hasAdImpact, isMarketingTools) => {
  if (isRegular) {
    return LIMITED_PRODUCT[key] || DEFAULT_LIMITED_PRODUCT;
  }

  if (isTrial) {
    return TRIAL_PRODUCT[key] || DEFAULT_TRIAL_PRODUCT;
  }

  if (isWavecast) {
    return DEFAULT_MASLOW_PRODUCT;
  }

  if (isMarketingTools) {
    return PRODUCT.MARKETING_TOOLS;
  }

  if(!key || !PRODUCT[key]) {
    return !hasMMM && hasAdImpact ? PRODUCT.ADIMPACT : PRODUCT.MMM;
  }
  return PRODUCT[key];
};

/**
 * Returns the url path from location hook
 * @returns Url path from location hook
 */
export const useGetPath = () => {
  return useLocation().pathname;
};
