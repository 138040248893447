import {css} from 'emotion';

export const styles = {
  content: css`
    width: 100%;
    display: flex;
    flex-direction: column;

    .row {
      display: flex;
      align-self: center;
    }

    .chart-title {
      text-align: center;
      color: #333;
      font-size: 16px;
      font-weight: 400;
      margin-left: 100px;
      margin-top: 24px;
    }

    .button-row {
      justify-content: flex-end;
    }
    .download-button {
      justify-content: center;
    }
  `
};
