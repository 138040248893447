import {css} from 'emotion';

export const styles = {
  controlPanel: css`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  icons: css`
    display: flex;
    justify-content: center;
    align-items: center;

    .graph-control-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
    }

    .graph-control-icon:not(:last-child) {
      border-right: 1px solid rgba(0, 0, 0, 0.12);
    }
  `
};
