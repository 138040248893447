import {
    getGroupMeasure,
    getGroup,
    getMeasure
} from './core/variable-name-translator-core.js';

// Actions
export const FETCH_MCA_RUN_VARIABLE_NAME_TRANSLATOR = `mca/run/variable-name-translator`;
export const FETCH_MCA_RUN_VARIABLE_NAME_TRANSLATOR_REQUEST = `mca/run/variable-name-translator-request`;
export const FETCH_MCA_RUN_VARIABLE_NAME_TRANSLATOR_SUCCESS = `mca/run/variable-name-translator-success`;

const ID = 'variableNameTranslator';
const local = (state) => state['mca'][ID];
export const getMcaVariable = (state) => local(state).mcaVariable;
export const getMcaVariableGroupAndMeasure = (state, variableName) =>
    getGroupMeasure(local(state).mcaVariable, variableName);
export const getMcaVariables = (state) => local(state).mcaVariable;

export const getMcaVariableGroup = (state, variableName) =>
    getGroup(local(state).mcaVariable, variableName);

export const getMcaVariableMeasure = (state, variableName) =>
    getMeasure(local(state).mcaVariable, variableName);

export const initialState = {
    mcaVariable: {}
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_MCA_RUN_VARIABLE_NAME_TRANSLATOR_SUCCESS:
            return {...state, mcaVariable: action.readableNameMap};
        default:
            return state;
    }
}

// action creators
export function fetchMcaRunVariableNameTranslatorSuccess(readableNameMap) {
    return {
        type: FETCH_MCA_RUN_VARIABLE_NAME_TRANSLATOR_SUCCESS,
        readableNameMap
    };
}

export function fetchMcaRunVariableNameTranslatorRequest(
    token,
    baseUrl,
    runId,
    model
) {
    return {
        type: FETCH_MCA_RUN_VARIABLE_NAME_TRANSLATOR_REQUEST,
        token,
        baseUrl,
        runId,
        model
    };
}
