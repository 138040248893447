import {css} from 'emotion';

export const styles = {
    formContainer: css`
        margin-top: 200px;
    `,
    title: css`
        font-size: 34px;
        line-height: 40px;
        margin-bottom: 31px;
    `,
    cssGridBlock: css`
        width: 24px;
        display: inline-block;
    `,
    formBox: css`
        display: flex;
        position: absolute;
        justify-content: center;
        opacity: 0.54;
        background-color: #2b3c4b;
        width: 400px;
        height: 312px;
        border-radius: 15px;
        pointer-events: none !important;
    `,
    fields: css`
        margin-left: 24px;
    `,
    fileType: css`
        z-index: 10;
    `,
    buttonContainer: css`
        display: block;
        position: absolute;
        top: 570px;
        margin-left: 24px;
    `,
    waiting: css`
        cursor: wait !important;
    `,
    templateDownload: css`
        cursor: pointer !important;
        width: 350px;
    `,
    downloadText: css`
        overflow-wrap: anywhere;
        padding-left: 30px;
        text-indent: -24px;
    `,
    spinner: css`
        color: white !important;
    `
};
