import React from 'react';
import {EditableNumericCell} from '../../../components/data-table/cells/EditableNumericCell';
import {forceNumeric} from '../../../js/utils';
import _ from 'lodash';

/**
 * Cell that adds validation for Observed Range to numeric input
 */
export const EditScenarioCell = ({
  name,
  formData={},
  cell={},
  errors,
  register=()=>{},
  isCurrency=false,
  nameSuffix='_scenario1',
  isValidateObservedRange=true,
  defaultValue=0,
  nestedKpis={},
  disabled=false,
  ...props
}) => {
  let hidden = false;
  const variableName = name || _.get(cell, 'row.values.variableName');
  let variableGroup = _.get(cell, 'row.values.variableTactic');

  if(!variableGroup) {
    variableGroup = variableName;
  }
  if(!variableGroup) {
    return null;
  }

  if(nestedKpis[variableName]){
    defaultValue = 0;
    cell.value = 0;
    disabled=true;
    isCurrency=false;
    hidden=true;
  }

  let value = cell.value || defaultValue;
  let validation = {};
  const fieldName = `${variableGroup}${nameSuffix}`;
  const minObserved = Number(_.get(cell, 'row.values.minObserved'));
  const maxObserved = Number(_.get(cell, 'row.values.maxObserved'));

  if(isValidateObservedRange) {
    validation = {
      validate: value => validateObservedRange(value, minObserved, maxObserved)
    };
  }

  if(!value){
    value = formData[fieldName];
  }

  return (
    <EditableNumericCell
      name={fieldName}
      cell={cell}
      isCurrency={isCurrency}
      errors={errors}
      defaultValue={value}
      disabled={disabled}
      register={
        register(validation)
      }
      hidden={hidden}
      {...props}
    />
  );
};

/**
 * Validate the value is in required range.
 * 0 is also a valid form entry
 */
export const validateObservedRange = (value, minObserved, maxObserved) => {
  const numericValue = forceNumeric(value);
  if(isNaN(minObserved) || isNaN(maxObserved)) {
    return true;
  }

  if(numericValue === 0) {
    return true;
  }

  const minBuffered = Math.floor(minObserved * .85);
  const maxBuffered = Math.ceil(maxObserved * 1.15);

  if(minBuffered <= numericValue && numericValue <= maxBuffered) {
    return true;
  }

  return `Value is out of observed range. (${minBuffered} - ${maxBuffered})`;
};
