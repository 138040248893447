// selectors
const ID = 'loading';
const local = (state) => state['platform'][ID];
export const getLoadingState = (state, action) =>
    getLoadingStateHelper(local(state), action);

export const initialState = {};

export default function reducer(state = initialState, action) {
    const {type} = action;

    // check if the end of the type of the action matches REQUEST, SUCCESS or FAILURE
    const matches = /(.*)-(request|success|failure)/.exec(type);

    if (!matches) return state;
    const [, requestName, requestState] = matches;
    return {
        ...state,
        [requestName]: requestState === 'request'
    };
}

// helper functions
export function getLoadingStateHelper(state, action) {
    const requestName = Object.keys(state).find(
        (element) => element === action
    );
    if (!requestName) return false;
    else return state[requestName];
}
