import React from 'react';
import _ from 'lodash';
import {StickyTableLV} from '../../components/data-table/StickyTableLV';
import {EllipsisCell} from '../../components/data-table/cells/EllipsisCell';
import {getDummyData} from '../../components/data-table/getDummyData';
import {useCampaignManagement} from '../../hooks/useCampaignManagement';
import {useGetMcaModels} from '../../hooks/useGetMcaModels';
import {usePost} from '../../hooks/useRest'
import {useGetUploadedFiles} from '../../hooks/useGetUploadedFiles';
import {useGetFileJson} from '../../hooks/useUploadedFileJson'
import {numberWithCommas} from '../../js/utils';
import {styles} from './McaModelComparison.styles';
import Stack from '@mui/material/Stack';
import {R1Button, R1Modal, R1Dialog} from '@rainagency/rain-one-soggy-loaf';
import { urlBuilders } from '../../js/constants';
import darkImageX from "../../assets/dark_x_24px.svg";
import {FEATURES} from "../../constants/features";
import {useFeatureSelect} from "../../hooks/useFeatureSelect";

export const McaModelComparison = ({magnifaiRun, setMagnifaiRun}) => {
  const showModal = magnifaiRun?.run_status === "Success" ? true : false;
  const {mcaRunId, magnifAIId} = useCampaignManagement();

  return (
    <R1Modal
      open={showModal}
      disableBackdropClick={false}
      customStyles={{
        "box-shadow": "0 2px 15px 0 rgba(0, 0, 0, 0.3)",
        "background": "#F4F4F5",
        "border-radius": "10px",
        "padding": "0px",
        "height": "50%",
        "overflow": "hidden"
      }}
      ActionComponent={<div/>}
      BodyComponent={
        <ModelComparisonModal 
          mcaRunId={mcaRunId} 
          magnifAIId={magnifAIId}
          setMagnifaiRun={setMagnifaiRun}/>}
      >
    </R1Modal>
  );
};

const ModelComparisonModal = ({ 
    mcaRunId, 
    magnifAIId, 
    setMagnifaiRun, 
    closeModal
  }) => {
  const {
    model={},
    modelList=[],
    loading
  } = useGetMcaModels(mcaRunId);
  const { onFeatureSelect } = useFeatureSelect();
  const mcaModelList = React.useMemo(() => mapMcaModelList(modelList), [modelList]);

  const {
    allUploadedFiles: campaignUploadedFiles,
    loading: uploadedFilesLoading
  } = useGetUploadedFiles();

  const {fileErrors: magnifAIErrors, fileJson: magnifAIJSON, isFileJsonLoading: magnifAIJSONLoading} = useGetFileJson({
      bucket: campaignUploadedFiles[0]?.bucket,
      location: 'MCA/run-data-set/' + mcaRunId + '/magnifAI/' + magnifAIId + '/final_model.csv'
  })
  const [{data: finalizeResponse, loading: finalizeLoading, error: finalizeError}, finalizeMagnifAIPost] = usePost({
    url: urlBuilders.postFinalizeMagnifaiRun(),
    manual: true
  });

  React.useEffect(() => {
    if (finalizeResponse?.success) {
      setMagnifaiRun({});
      closeModal();
    } 
    }, [finalizeResponse]);

  const finalizeMagnifAI = async (acceptRun) => {
    const modelInfo ={
      id: mcaRunId,
      magnifAIId: magnifAIId,
      acceptRun: acceptRun,
      includeIntercept: model.base > 0
    }
    await finalizeMagnifAIPost({data: modelInfo});
    onFeatureSelect({
      feature: FEATURES.MMM_ANALYSIS,
    });
  }
  const tunedModel = magnifAIJSON[0];
  const tunedListForTable = React.useMemo(() => mapMcaModelList(magnifAIJSON), [magnifAIJSON]);
  let alreadyTuned = false;
  if (magnifAIJSON[0]?.dependentvariable === "") {
    alreadyTuned = true;
  }
  const tablesLoading = loading || uploadedFilesLoading || magnifAIJSONLoading;
  return (
    <div className={styles.container}>
      <img
        src={darkImageX}
        alt='Close'
        className={styles.x}
        onClick={closeModal}
        data-test='modal-trigger-close' />
      <div className={styles.modalHeader}>Model Tuning</div>
      <Stack className={styles.stack} direction='row'>
        <TableComponent 
          tableTitle={'Selected Model'}
          model={model}
          modelList={mcaModelList}
          isLoading={loading}
          />
        <div className={styles.separator} />
        <TableComponent 
          tableTitle={'Tuned Model'}
          model={tunedModel}
          modelList={tunedListForTable}
          isLoading={tablesLoading}
          tunedModel={true}
          alreadyTuned={alreadyTuned}
          />
      </Stack>
      <div className={styles.buttonContainer}>
        <R1Button
          className={alreadyTuned ? styles.primaryOriginalButton : styles.originalButton}
          onClick={()=>{finalizeMagnifAI(false)}}
          loading={finalizeLoading}
          data-test={'select-original-model-button'}
        >
          Select Original Model
        </R1Button>
        <R1Dialog
          ActionComponent={
            <R1Button
              className={styles.tunedButton}
              disabled={alreadyTuned}
              loading={finalizeLoading}
              data-test={'select-tuned-model-button'}
              >
              Select Tuned Model
            </R1Button>
          }
          title="Would you like to use the Tuned Model?"
          description="Your previous model will be overwritten."
          onCancel={function noRefCheck(){}}
          onConfirm={()=>{finalizeMagnifAI(true)}}
          width="25%"
        />
      </div>
    </div>
  )
}

const TableComponent = ({
  className, 
  tableTitle='', 
  model={}, 
  modelList=[], 
  isLoading=false, 
  tunedModel=false, 
  alreadyTuned=false
}) => {
  const loading = isLoading;
  const rsq = model.rsq;
  const mape = model.mape;
  const rsqDisplay = (rsq * 100).toFixed(2);
  const mapeDisplay = Math.round(mape * 100);

  let containerStyle = styles.modelContainer;

  if (alreadyTuned) {
    containerStyle = styles.alreadyTunedContainer;
  } else if (tunedModel) {
    containerStyle = styles.tunedContainer;
  }
  
  const override = {
    variable: {
      component: <EllipsisCell/>,
      style: {
        width: '220px',
        maxWidth: '250px',
        height: '20px'
      }
    }
  };
  return (
    <div className={styles.gridSide}>
      <div className={containerStyle}>
        <div className={styles.topBar}>
          <div className={styles.modelTitle}>
            {tableTitle}
          </div>
          <div className={styles.modelCalc}>
            <div>
              <div className='calculation' data-test='rsq'>
                {rsqDisplay}%
              </div>
              <div>
                R SQUARED
              </div>
            </div>
          </div>
          <div className={styles.modelCalc}>
            <div>
              <div className='calculation' data-test='mape'>
                {mapeDisplay}%
              </div>
              <div>
                MAPE
              </div>
            </div>
          </div>
        </div>

        <div className={styles.tableContainer}>
          <StickyTableLV
            data={modelList}
            isLoading={loading}
            hasData={modelList.length}
            className={styles.tableStyles}
            override={override}
          />
        </div>
      </div>
      <AlreadyTunedAlert visible={alreadyTuned} />
    </div>
  );
}

const AlreadyTunedAlert = ({
  className, visible=false, ...props
}) => {
  let alertDiv = null;
  if (visible){
    alertDiv = (
      <div className = {styles.alreadyTunedAlert}>
        Your selected model is already the best model!
      </div>
    )
  }
  return alertDiv;
}

const mapMcaModelList = (list=[]) => {
  if(list.length < 1) {
    return getDummyData({
      variable: '',
      decay: '',
      saturation: '',
      contribution: '',
      confidence: ''
    }, 3);
  }
  return list.map(row => {
    const confidence = Math.round((1 - Number(row.pval)) * 100);
    const contribution = numberWithCommas(Math.round(row.contributions));
    return {
      variable: row.variablename || row.independentvariable,
      decay: _.upperFirst(_.toLower(row.variabledecay)) + (isNaN(row.variabledecay) ? '' : '%'),
      saturation: _.upperFirst(_.toLower(row.variablesaturation)),
      contribution,
      confidence: `${confidence}%`
    };
  });
};
