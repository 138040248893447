import {sortByColumnBetter, sortByColumn} from '../../js/utils.js';

// actions
export const FETCH_MCA_MODELS = `app/mca-models/fetch`;
export const LOAD_MCA_MODELS = `app/mca-models/load-mca-models`;
export const SORT_MCA_MODELS = `app/mca-models/sort`;
export const SORT_MODEL_DETAILS = `app/mca-models/sort-details`;
export const RESET_MODELS = `app/mca-models/reset-models`;
export const UPDATE_STATUS = `app/mca-models/update-status`;
export const SPINNER = `app/mca-models/spinner`;
export const SET_SELECTED_MODEL = `mca/mca-models/set-selected-model`;
export const LOAD_QUERY_ID = `app/mca-models/load-query-id`;
export const SET_DOWNLOAD_STATUS = `app/mca-models/set-download-status`;
export const SET_APPOVED_MODEL_ID = `app/mca-models/set-approved-model-id`;
export const APPROVE_MODEL = `app/mca-models/approve-model`;
export const FETCH_SELECTED_MODEL = `app/mca-models/fetch-selected-model`;
export const SET_CAN_FETCH_MODELS = `app/mca-models/set-can-fetch-models`;

// selectors
const ID = 'models';
const local = (state) => state['mca'][ID];
export const getMcaModels = (state) => local(state).allIds;
export const getMcaModelById = (state, id) => local(state).byId[id];
export const getSortColumn = (state) => local(state).tableSortColumn;
export const getSortAscending = (state) => local(state).tableSortAscending;
export const getSpinner = (state) => local(state).spinner;
export const getSelectedModelId = (state) => local(state).selectedModelId;
export const getQueryId = (state) => local(state).queryId;
export const getDownloading = (state) => local(state).downloading;
export const getApprovedModelId = (state) => local(state).approvedModelId;
export const getCanFetchModels = (state) => local(state).canFetchModels;
export const getSelectedModel = (state) =>
    local(state).byId[local(state).selectedModelId];
export const getExpectedModelCount = (state) => local(state).expectedModelCount;

export const initialState = {
    byId: {},
    allIds: [],
    canFetchModels: true,
    tableSortColumn: 'modelid',
    tableSortAscending: true,
    selectedModelId: '',
    spinner: false,
    queryId: 'queryId',
    downloading: false,
    approvedModelId: '',
    expectedModelCount: null
};

export default function reducer(state = initialState, action) {
    let id = 'modelid';
    let newById = {};
    let newAllIds = [];
    switch (action.type) {
        case LOAD_MCA_MODELS:
            newAllIds = action.mcaModels.map((model) => {
                let details = [];
                let isUnique = true;
                if (newById[model[id]]) {
                    isUnique = false;
                    details = newById[model[id]].details.slice();
                }

                details.push({
                    variable: model.independentvariable,
                    confidence: Math.round((1 - Number(model.pval)) * 100),
                    vif: Number(model.vif).toFixed(2),
                    contribution: Math.round(
                        Number(model.prctcontributions) * 100
                    )
                });

                newById[model[id]] = Object.assign({}, model, {
                    durbinwatson: Number(model.durbinwatson).toFixed(2),
                    rsq: (model.rsq * 100).toFixed(2),
                    mape: Math.round(model.mape * 100),
                    status: 'In Review',
                    details: details
                });
                return isUnique ? model[id] : null;
            });

            let index = newAllIds.indexOf(null);
            while (index > -1) {
                newAllIds.splice(index, 1);
                index = newAllIds.indexOf(null);
            }

            if (state.tableSortColumn !== '') {
                newAllIds = sortByColumnBetter(
                    newAllIds,
                    newById,
                    state.tableSortColumn,
                    state.tableSortAscending
                );
            }
            return Object.assign({}, state, {byId: newById, allIds: newAllIds});
        case SORT_MCA_MODELS:
            let ascendingSort = state.tableSortAscending;
            if (state.tableSortColumn === action.columnToSortBy) {
                if (ascendingSort === false) {
                    ascendingSort = true;
                } else {
                    ascendingSort = false;
                }
            } else {
                ascendingSort = true;
            }
            const sortedIds = sortByColumnBetter(
                state.allIds,
                state.byId,
                action.columnToSortBy,
                ascendingSort
            );
            return Object.assign({}, state, {
                tableSortAscending: ascendingSort,
                tableSortColumn: action.columnToSortBy,
                allIds: sortedIds
            });
        case SORT_MODEL_DETAILS:
            let ascending = state.tableSortAscending;
            if (state.tableSortColumn === action.columnToSortBy) {
                if (!ascending) {
                    ascending = true;
                } else {
                    ascending = false;
                }
            } else {
                ascending = true;
            }
            const details = state.byId[state.selectedModelId]['details'];
            const sortedDetails = sortByColumn(
                details,
                action.columnToSortBy,
                ascending
            );

            newById[state.selectedModelId] = Object.assign(
                {},
                state.byId[state.selectedModelId],
                {details: sortedDetails}
            );
            return Object.assign({}, state, {
                tableSortAscending: ascending,
                tableSortColumn: action.columnToSortBy,
                byId: newById
            });
        case SPINNER:
            return Object.assign({}, state, {spinner: action.show});
        case RESET_MODELS:
            return Object.assign({}, initialState);
        case UPDATE_STATUS:
            let model = Object.assign({}, state.byId[action.modelId], {
                status: action.status
            });
            newById = Object.assign({}, state.byId, {[action.modelId]: model});
            return Object.assign({}, state, {byId: newById});
        case SET_SELECTED_MODEL:
            sessionStorage.setItem(
                `mca/model/selectedModel`,
                action.selectedModelId
            );
            return Object.assign({}, state, {
                selectedModelId: action.selectedModelId
            });
        case LOAD_QUERY_ID:
            return Object.assign({}, state, {queryId: action.queryId});
        case SET_DOWNLOAD_STATUS:
            return Object.assign({}, state, {downloading: action.status});
        case SET_APPOVED_MODEL_ID:
            return Object.assign({}, state, {
                approvedModelId: action.approvedModelId
            });
        case SET_CAN_FETCH_MODELS:
            return Object.assign({}, state, {
                canFetchModels: action.canFetchModels
            });
        default:
            return state;
    }
}

export function loadMcaModels(mcaModels) {
    return {
        type: LOAD_MCA_MODELS,
        mcaModels
    };
}

export function sortMcaModels(columnToSortBy) {
    return {
        type: SORT_MCA_MODELS,
        columnToSortBy
    };
}

export function sortModelDetails(columnToSortBy) {
    return {
        type: SORT_MODEL_DETAILS,
        columnToSortBy
    };
}

export function spinner(show) {
    return {
        type: SPINNER,
        show
    };
}
export function resetModels() {
    return {
        type: RESET_MODELS
    };
}

export function updateStatus(modelId, status) {
    return {
        type: UPDATE_STATUS,
        modelId,
        status
    };
}

export function setSelectedModel(selectedModelId) {
    return {
        type: SET_SELECTED_MODEL,
        selectedModelId
    };
}
export function loadQueryId(queryId) {
    return {
        type: LOAD_QUERY_ID,
        queryId
    };
}
export function setDownloadStatus(status) {
    return {
        type: SET_DOWNLOAD_STATUS,
        status
    };
}

export function setApprovedModelId(approvedModelId) {
    return {
        type: SET_APPOVED_MODEL_ID,
        approvedModelId
    };
}

export function setCanFetchModels(canFetchModels) {
    return {
        type: SET_CAN_FETCH_MODELS,
        canFetchModels
    };
}
