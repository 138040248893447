import {css} from 'emotion';

export const styles = {
    container: css`
        overflow-y: scroll;
        height: 100vh;
        width: 100vw;
        background: #374f63;
        color: #ffffff;
    `,
    title: css`
        font-weight: lighter !important;
        font-size: 48pt;
        margin-top: 152px !important;
        margin-bottom: 11px;
        text-align: center !important;
        line-height: 65px;
    `,
    formContainer: css`
        display: flex;
        justify-content: center;
    `,
    typeBoxSmall: css`
        margin-left: auto;
        margin-right: auto;
        padding: 40px 23px 40px 24px;
        background-color: rgba(43, 60, 75, 0.54);
        border-radius: 15px;
        width: 400px;
        height: 68px;
    `,
    typeBoxBig: css`
        margin-left: auto;
        margin-right: auto;
        padding: 40px 23px 40px 24px !important;
        background-color: rgba(43, 60, 75, 0.54);
        border-radius: 15px;
        width: 400px;
        height: 355px;
    `,
    fieldContainer: css`
        margin-bottom: 40px !important;
    `,
    fields: css`
        height: 73px;
        padding-bottom: 16px;
    `,
    datesContainer: css`
        display: flex;
        height: 72px;
        padding-top: 16px;
        padding-bottom: 16px;
    `,
    labels: css`
        opacity: 0.54;
    `,
    dates: css`
        font-size: 12px;
        padding-right: 48px;
        width: 152px;
    `,
    buttonContainer: css`
        display: flex;
        justify-content: center;
        padding-top: 64px;
        padding-bottom: 30px;
        height: 48px !important;
    `,
    cancelButton: css`
        width: 160px;
        height: 48px;
        background: #ffffff;
        border: 1px solid rgba(126, 146, 146, 0.25);
        box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.15);
        font-size: 14px;
        color: #374f63;
        text-align: center;
        line-height: 20px;
    `,
    submitButton: css`
        margin-left: 20px !important;
        width: 160px;
        height: 48px;
        background: rgba(245, 242, 239, 0.2) !important;
        opacity: 0.5;
        font-size: 14px;
        color: #ffffff !important;
        text-align: center;
        line-height: 20px;
    `,
    submitButtonActive: css`
        margin-left: 20px !important;
        width: 160px;
        height: 48px;
        background: #ff7762 !important;
        border: 1px solid rgba(126, 146, 146, 0.25);
        box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.25);
        font-size: 14px;
        color: #ffffff !important;
        text-align: center;
        line-height: 20px;
    `,
    closeButton: css`
        width: 184px;
        height: 48px;
        background: #ffffff;
        border: 1px solid rgba(126, 146, 146, 0.25);
        box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.15);
        font-size: 14px;
        color: #374f63;
        text-align: center;
        line-height: 20px;
        margin-top: 40px !important;
    `,
    completeContainer: css`
        text-align: center;
        margin: 0 auto;
        margin-top: 152px;
    `,
    completeTitle: css`
        font-weight: lighter !important;
        font-size: 48px !important;
        margin-top: 13px !important;
        margin-bottom: 4px;
        text-align: center !important;
    `,
    icon: css`
        padding-left: 24px;
        padding-right: 8px;
    `,
    typeName: css`
        margin-left: auto;
        margin-right: auto;
        text-transform: initial !important;
        font-size: 16px !important;
        font-weight: bold !important;
        color: white !important;
        text-align: center !important;
        width: 400px;
    `,
    completeMsg: css`
        text-transform: initial !important;
        font-size: 16px !important;
        font-weight: lighter !important;
        width: 425px;
    `
};
