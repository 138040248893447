import {css} from 'emotion';
import {commonStyles} from '../../styles/commonStyles';

export const styles = {
  container: css`
    display: flex;
    flex-direction: row;
    position: relative;
    min-height: 200px;
    height: 100%;

    th {
      position: sticky;
      height: 80px;
      top: 0;
      background: #FFF;
      padding-left: 0;
    }

    table {
      min-width: 200px;
      min-height: 100px;

      &.loading::after {
        left: 30%;
      }

      tr:nth-child(odd) td{
        background: transparent;
      }

      tr:nth-child(even) td{
        background: transparent;
      }
    }

    .hide-rows {
      tbody {
        display: none;
      }
    }

    .header-cell-container {
      padding: 0;
    }

    .display-column {
      font-weight: bold;
      font-size: 20px;
      color: #333;
    }

    .campaign-column {
      display: flex;
      flex-grow: 1;
      padding-left: 24px;
      overflow-y: auto;
      align-items: flex-start;
    }

    .single-column {
      min-width: 200px;
      overflow-y: auto;
      overflow-x: clip;
      display: flex;
      padding-left: 24px;
      padding-right: 12px;
      align-items: flex-start;

      border-width: 3px 3px 3px 0;
      border-style: solid;
      border-image: linear-gradient(to left, #E4E4E4, #FFF) 1 100%;
    }

    .no-border {
      th, td {
        border: none;
      }
    }

    &.no-data {
      opacity: 0.5;
    }

    &.no-data::after {
      content: 'No Data';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      ${commonStyles.fontFamily}
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 42px;
      color: #828282;
    }

  `
};
