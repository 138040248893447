import React from 'react';
import {EditableNumericCell} from '../../../components/data-table/cells/EditableNumericCell';
import {forceNumeric} from '../../../js/utils';
import _ from 'lodash';

/**
 * Cell that adds validation for Observed Range to numeric input
 */
export const EditScenarioCostPer = ({
  name,
  formData={},
  cell={},
  errors,
  register=()=>{},
  isCurrency=true,
  nameSuffix='_price',
  costPer=1,
  setCostPer=()=>{},
  ...props
}) => {
  const variableName = name || _.get(cell, 'row.values.variableTactic');
  if(!variableName) {
    return null;
  }

  const fieldName = `${variableName}${nameSuffix}`;
  let value = cell.value || costPer;
  if(!value){
    value = formData[fieldName];
  }

  return (
    <EditableNumericCell
      name={fieldName}
      cell={cell}
      isCurrency={isCurrency}
      errors={errors}
      defaultValue={value}
      onBlur={setCostPer}
      register={register}
      {...props}
    />
  );
};