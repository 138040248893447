// Actions
export const SET_SELECTED_MEDIA_VEHICLE = `mca/run/set-selected-media-vehicle`;
export const LOAD_SATURATED_MEDIA_VEHICLES = `mca/run/load-saturated-media-vehicles`;

const ID = 'mediaVehicleDashboard';
const local = (state) => state['mca'][ID];
export const getSaturatedMediaVehicles = (state) =>
    local(state).saturatedMediaVehicles;
export const getSelectedMediaVehicle = (state) =>
    local(state).selectedMediaVehicle;

export const initialState = {
    saturatedMediaVehicles: [],
    selectedMediaVehicle: ''
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_SATURATED_MEDIA_VEHICLES:
            let mcaVariables = action.readableNameMap;
            let saturatedMediaVehicles = [];
            if (mcaVariables) {
                for (let variableName in mcaVariables) {
                    const variable = mcaVariables[variableName];
                    if (variable.saturation) {
                        saturatedMediaVehicles.push(variable.name);
                    }
                }
            }

            let selectedMediaVehicle = '';

            if (saturatedMediaVehicles) {
                selectedMediaVehicle = saturatedMediaVehicles[0];
            }

            return {
                ...state,
                saturatedMediaVehicles,
                selectedMediaVehicle: selectedMediaVehicle
            };
        case SET_SELECTED_MEDIA_VEHICLE:
            return Object.assign({}, state, {
                selectedMediaVehicle: action.selectedMediaVehicle
            });
        default:
            return state;
    }
}

// action creators
export function loadSaturatedMediaVehicles(readableNameMap) {
    return {
        type: LOAD_SATURATED_MEDIA_VEHICLES,
        readableNameMap
    };
}

export function setSelectedMediaVehicle(selectedMediaVehicle) {
    return {
        type: SET_SELECTED_MEDIA_VEHICLE,
        selectedMediaVehicle
    };
}
