import React from 'react';
import {ButtonLV} from './ButtonLV';
import {styles} from './button.styles';

export const ButtonTransparent = ({...props}) => {
  return (
    <ButtonLV
      className={styles.transparent}
      {...props}
    />
  );
};
