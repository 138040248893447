import React from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckIcon from '@mui/icons-material/Check';
import {StickyTableLV} from '../../components/data-table/StickyTableLV';
import {ModalLV} from '../../components/modal/ModalLV';
import {EditCell} from '../../components/data-table/cells/EditCell';
import {DeleteCell} from '../../components/data-table/cells/DeleteCell';
import {styles} from './UserTable.styles';
import {UserManagementForm} from './UserManagementForm';
import {DeleteUserForm} from './DeleteUserForm';
import {getDummyData} from '../../components/data-table/getDummyData';
import _ from 'lodash';

export const UserTable = ({data, loading, refetchUsers=()=>{}}) => {
  const users = React.useMemo(() => formatUserDataset(data), [data]);

  const override = {
    index: {
      name: ' # ',
      className: styles.indexCells,
      align: 'center'
    },
    email: {
      className: styles.email
    },
    experimental: {
      component: <ExperimentalCell />,
      name: 'Experimental',
      className: styles.checkIcon,
      align: 'center',
      disableSorting: true
    },
    edit: {
      component: <EditUserCell refetchUsers={refetchUsers}/>,
      name: 'Edit',
      className: styles.editIcon,
      align: 'center',
      disableSorting: true
    },
    delete: {
      component: <DeleteUserCell refetchUsers={refetchUsers}/>,
      name: <MoreVertIcon/>,
      className: styles.deleteIcon,
      align: 'center',
      disableSorting: true
    },
    id: {
      hidden: true
    },
    partnerIds: {
      hidden: true
    },
    clientIds: {
      hidden: true
    },
    groups: {
      hidden: true
    },
    mmmPermission: {
      hidden: true
    },
    adImpactPermission: {
      hidden: true
    },
    wavecastPermission: {
      hidden: true
    },
  };
  return (
    <div data-test='user-table-container' className={styles.tableContainer}>
      <StickyTableLV
        data={users}
        override={override}
        isLoading={loading}
        hasData={!!data}
      />
    </div>
  );  
};

const ExperimentalCell = ({cell}) => {
  const experimental = _.get(cell, 'row.values.experimental');
  return (
    experimental ?
      <CheckIcon /> : null
  );
};

const EditUserCell = ({cell, refetchUsers}) => {
  const username = _.get(cell, 'row.values.username');
  const values = _.get(cell, 'row.values');
  return (
    <ModalLV>
      <EditCell dataTest={`edit-icon-${username}`} />
      <UserManagementForm isEdit={true} values={values} refetchUsers={refetchUsers} />
    </ModalLV>
  );
};

const DeleteUserCell = ({cell, refetchUsers}) => {
  const username = _.get(cell, 'row.values.username');
  return (
    <ModalLV
      isDark={false}
      minWidth={448}
      minHeight={264}>
      <DeleteCell
        dataTest={`delete-icon-${username}`}
      />
      <DeleteUserForm cell={cell} refetchUsers={refetchUsers} />
    </ModalLV>
  );
};

const formatUserDataset = (users=[]) => {
  if(!users.length) {
    return getDummyData(tableSchema, 10);
  }

  return users.map((user, index) => {
    const experimental = user.experimental;
    delete user['experimental'];
    const row = {
      index: formatIndex(index, users.length),
      ...user,
      experimental,
      edit: '',
      delete: ''
    };
    return row;
  });
};

export const formatIndex = (index, length) => {
  const leadingZero = '0';
  const maxZeros = String(length).length;
  const indexLength = String(index + 1).length;
  return leadingZero.repeat(maxZeros - indexLength) + (index +1);
};

/**
 * Base table keys, used to create dummy data to fill table before data loads
 */
const tableSchema = {
  index: '',
  username: '',
  firstName: '',
  lastName: '',
  email: '',
  partner: '',
  client: '',
  experimental: '',
  edit: '',
  delete: '',
  id: '',
  partnerIds: [],
  clientIds: [],
  groups: []
};
