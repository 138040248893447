import React from 'react';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Tooltip from '@mui/material/Tooltip';

import downloadAll from '../../../../assets/download-all.svg';
import downloadSelected from '../../../../assets/download-checked.svg';

import {styles} from './download-menu.styles.js';

class DownloadMenu extends React.Component
{
    constructor(props) {
        super(props);
        this.state = { menu: null };

        this.handleClick = this.handleClick.bind(this);
        this.downloadAll = this.downloadAll.bind(this);
        this.downloadSelected = this.downloadSelected.bind(this);
    }

    downloadAll() {
        this.props.downloadAll();
    }

    downloadSelected() {
        this.props.downloadSelected();
    }

    handleClick = event => {
        this.setState({ menu: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ menu: null });
    };

    render() {
        const open = Boolean(this.state.menu);

        let options = [
            <div data-test='downloadAll-downloadMenu-comparisonPage' onClick={this.downloadAll}><img className={styles.menuIcon} src={downloadAll} alt="Download All"/><span className={styles.menuItem}>Download All as 1 File</span></div>,
        ];
        if (this.props.modelsSelected) {
            options.push(<div data-test='downloadSelected-downloadMenu-comparisonPage' onClick={this.downloadSelected}>
                <img className={styles.menuIcon} src={downloadSelected} alt="Download Selected"/><span className={styles.menuItem}>Download Selected as 1 File</span></div>);
        }

        return (
            <div>
                <Tooltip title='Download selection menu'>
                    <IconButton
                        data-test='kabobButton-downloadMenu-comparisonPage'
                        className={styles.kabobMenuIcon}
                        aria-label="More"
                        aria-owns={open ? 'download-menu' : undefined}
                        aria-haspopup="true"
                        onClick={this.handleClick}
                        size="large">
                        <MoreVertIcon/>
                    </IconButton>
                </Tooltip>
                <Menu data-test='downloadMenuBox-downloadMenu-comparisonPage'
                    id="download-menu"
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "left" }}
                    anchorEl={this.state.menu}
                    open={open}
                    onClose={this.handleClose}
                    PaperProps={{
                        style: {
                            maxHeight: 240,
                            width: 264,
                        },
                    }}
                >
                    {options.map((option, index) => (
                        <MenuItem key={index} selected={option === ''} onClick={this.handleClose}>
                            {option}
                        </MenuItem>
                    ))}
                    {this.props.modelsSelected ? '' : <MenuItem data-test='selectForMoreMsg-downloadMenu-comparisonPage' className={styles.constMenuItem}
                        disabled={true}>Select multiple models for more options</MenuItem>
                    }
                </Menu>
            </div>
        );
    }
}

export default DownloadMenu;
