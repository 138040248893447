import React, {useEffect} from 'react';
import useSocket from "./hooks/useSocket";

import MarkdownMessage from "./messages/Markdown";

import {Config} from "../../config/ConfigFactory";

const ActionProvider = ({ createChatBotMessage, setState, children }) => {

    const [prefaceId, setPrefaceId] = React.useState(null);

    const serverUrl = Config.getConfig().YEASTY_URL;

    const {
        socket,
        connectionError
    } = useSocket(serverUrl);

    const emitToServer = (text) => {
        const message = { text };
        socket.emit("MessageRequest", message);
    }

    const updateChatbotState = (message) => {
        setState(prevState => {
            return {
                ...prevState,
                messages: [...prevState.messages, message],
            }
        });
    }

    const resetChatbotStateWithMessages = (newMessages) => {
        setState(prevState => ({
            ...prevState,
            messages: newMessages,
        }));
    }

    const removePreface = (prefaceId) => {
        if (prefaceId) {
            setState(prevState => {

                const newMessages = prevState.messages.filter((message) => message.id !== prefaceId);

                return {
                    ...prevState,
                    messages: newMessages,
                }
            });
        }
    }

    useEffect(() => {
        if (connectionError) {
            const botMessage = createChatBotMessage(
                "Sorry, I'm having trouble connecting to the server. Please try again later.",
            );

            resetChatbotStateWithMessages([botMessage]);
        }
    }, [connectionError]);

    useEffect(() => {
        if (!socket) {
            return;
        }

        // Listen for messages from the server
        socket.on('MessageResponse', (message) => {
            if (message.type === 'Preface') {
                const serverMessage = createChatBotMessage(message?.text);
                updateChatbotState(serverMessage);
                setPrefaceId(serverMessage.id);
            }

            if (message.type === 'Text' && prefaceId) {
                const serverMessage = createChatBotMessage(message?.text);
                updateChatbotState(serverMessage);
                removePreface(prefaceId);
            }

            if (message.type === 'Markdown' && prefaceId) {
                const botMessage = createChatBotMessage(<MarkdownMessage payload={{
                    text: message?.text
                }} />);
                updateChatbotState(botMessage);
                removePreface(prefaceId);
            }
        });

        return () => {
            socket.off('MessageResponse');
        }
    }, [socket, prefaceId]);

    return (
        <div>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, {
                    actions: {
                        emitToServer,
                        removePreface
                    },
                });
            })}
        </div>
    );
};

export default ActionProvider;