import React from 'react';
import _ from 'lodash';
import {EditableNumericCell} from '../../../components/data-table/cells/EditableNumericCell';
import {styles} from './EditCostPerCell.styles';

/**
 * Cell that adds validation for Observed Range to numeric input
 */
export const EditCostPerCell = ({
  cell={},
  register=()=>{},
  costPer='',
  setCostPer=()=>{},
  ...props
}) => {
  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      setCostPer(event);
    }
  };

  return (
    <EditableNumericCell
      name={_.get(cell, 'row.values.tactic')}
      cell={cell}
      className={styles.input}
      defaultValue={costPer}
      onBlur={setCostPer}
      onKeyDown={onKeyDown}
      {...props}
    />
  );
};