import React from 'react';
import {useInsightsQuery} from "../../hooks/useInsightsQuery";
import {DashboardContext} from "../../DashboardContext";


export const useContributionDashboard = ({
                                             mcaRuns,
                                             nestedRunIds,
                                             id,
                                             kpiType
                                                }) => {

    const typePrefix = nestedRunIds.length ? 'Combined' : '';

    const {filterByDateRange, insightMetadata, dashboardRegion} = React.useContext(DashboardContext);

    // REST calls
    const {
        results: roiResults,
        fetch: fetchRoi,
        isLoading: roiLoading
    } = useInsightsQuery({
        chartType: `${typePrefix}CPASummary`,
    });

    const {
        results: contributionsOverTimeResults,
        fetch: fetchContributionsOverTime,
        isLoading: contributionsOverTimeLoading
    } = useInsightsQuery({
        chartType: `${typePrefix}ContributionsOverTime`,
    });

    const isLoading = [roiLoading, contributionsOverTimeLoading].some(isLoad => isLoad);

    React.useEffect(() => {
        if (id && insightMetadata && kpiType) {
            const ignoreCols = mcaRuns
                .filter(run => nestedRunIds.includes(run.id))
                .map(run => {
                    const kpi = run.kpi.replace('_contributions', '');
                    const variable = insightMetadata.variables[kpi]
                    if (variable) {
                        return variable.name
                    }
                }).filter(col => col) || [];

            let regions;
            ignoreCols.push('Base')
            if (insightMetadata.regions?.length > 1 && dashboardRegion && dashboardRegion !== 'All') {
                ignoreCols.push('Base_with_Variance')
                regions = [dashboardRegion];
            }

            const additionalPayload = {
                runId: id,
                filters: {
                    regions
                }
            }

            fetchRoi(null, {
                chartParams: {
                    kpiType,
                },
                nestedRunIds,
                ...additionalPayload
            });
            fetchContributionsOverTime(null, {
                chartParams: {
                    ignoreCols,
                    variableNameFormatType: 'Group'
                },
                ...additionalPayload
            });
        }
    }, [id, insightMetadata, dashboardRegion, kpiType]);

    const {
        roiGraphData
    } = React.useMemo(() => {
        if (!roiResults) {
            return {};
        }
        return {
            roiGraphData: roiResults.results
        };
    }, [roiResults]);

    const {
        contributionsOverTimeGraphData
    } = React.useMemo(() => {
        if (!contributionsOverTimeResults) {
            return {};
        }
        return {
            contributionsOverTimeGraphData: filterByDateRange(contributionsOverTimeResults.results)
        };
    }, [contributionsOverTimeResults]);


    return {
        roiGraphData,
        contributionsOverTimeGraphData,
        isLoading
    };
}