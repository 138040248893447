import { USER_GROUPS } from './userGroups';

export const FEATURES = {
  OSTROW: {
    key: 'OSTROW',
    label: 'Ostrow Model',
    ariaLabel: 'Ostrow Model',
    path: '/marketing-tools/ostrow',
    allowedGroups: [
      USER_GROUPS.Admin_Group,
      USER_GROUPS.Company_Trial_Group
    ]
  },
  MANAGE_DATA: {
    key: 'MANAGE_DATA',
    label: 'Manage Data',
    ariaLabel: 'Manage Data',
    path: '/campaign/data-management',
    allowedGroups: [
      USER_GROUPS.Company_Trial_Group,
      USER_GROUPS.Admin_Group,
      USER_GROUPS.Advanced_Group,
      USER_GROUPS.Company_Admin_Group,
      USER_GROUPS.Company_Advanced_Group,
      USER_GROUPS.Company_Trial_Group,
      USER_GROUPS.Partner_Admin_Group,
      USER_GROUPS.Partner_Advanced_Group,
    ]
  },
  MMM_DASHBOARDS: {
    key: 'MMM_DASHBOARDS',
    label: 'Dashboards',
    ariaLabel: 'MMM Dashboards',
    path: '/campaign/dashboards',
    allowedGroups: [
      USER_GROUPS.Admin_Group,
      USER_GROUPS.Company_Trial_Group,
      USER_GROUPS.MMM_Group
    ]
  },
  MMM_ANALYSIS: {
    key: 'MMM_ANALYSIS',
    label: 'Analysis',
    ariaLabel: 'MMM Analysis',
    path: '/campaign/mca',
    allowedGroups: [
      USER_GROUPS.Admin_Group,
      USER_GROUPS.Company_Trial_Group,
      USER_GROUPS.MMM_Group
    ]
  },
  MMM_SELECTED: {
    key: 'SELECTED',
    label: 'Selected',
    ariaLabel: 'MMM Selected',
    path: '/campaign/mca-selected',
    allowedGroups: [
      USER_GROUPS.Admin_Group,
      USER_GROUPS.Company_Trial_Group,
      USER_GROUPS.MMM_Group
    ]
  },
  SCENARIO_PLANNER: {
    key: 'SCENARIO_PLANNER',
    label: 'Scenario Planner',
    ariaLabel: 'Scenario Planner',
    path: '/campaign/scenario-planner',
    allowedGroups: [
      USER_GROUPS.Admin_Group,
      USER_GROUPS.Company_Trial_Group,
      USER_GROUPS.MMM_Group
    ]
  },
  SCENARIO_OPTIMIZER: {
    key: 'SCENARIO_OPTIMIZER',
    label: 'Scenario Optimizer',
    ariaLabel: 'Scenario Optimizer',
    path: '/campaign/scenario-optimizer',
    allowedGroups: [
      USER_GROUPS.Admin_Group,
      USER_GROUPS.Company_Trial_Group,
      USER_GROUPS.MMM_Group
    ]
  },
  SCORING: {
    key: 'SCORING',
    label: 'Scoring',
    ariaLabel: 'Scoring',
    path: '/campaign/scoring',
    allowedGroups: [
      USER_GROUPS.Admin_Group,
      USER_GROUPS.MMM_Group
    ]
  },
  ADIMPACT_DASHBOARDS: {
    key: 'ADIMPACT_DASHBOARDS',
    label: 'Dashboards',
    ariaLabel: 'AdImpact Dashboards',
    path: '/campaign/ad-impact-dashboards',
    allowedGroups: [
      USER_GROUPS.Admin_Group,
      USER_GROUPS.AdImpact_Group
    ]
  },
  ADIMPACT_ANALYSIS: {
    key: 'ADIMPACT_ANALYSIS',
    label: 'Analysis',
    ariaLabel: 'AdImpact Analysis',
    path: '/campaign/ad-impact',
    allowedGroups: [
      USER_GROUPS.Admin_Group,
      USER_GROUPS.AdImpact_Group
    ]
  },
  RUN_MASLOW: {
    key: 'RUN_MASLOW',
    label: 'Maslow',
    ariaLabel: 'Maslow',
    path: '/maslow',
    allowedGroups: [
      USER_GROUPS.Admin_Group,
      USER_GROUPS.Wavecast_Group
    ]
  },
  MANAGE_CAMPAIGNS: {
    key: 'MANAGE_CAMPAIGNS',
    label: 'Manage Campaigns',
    ariaLabel: 'Manage Campaigns',
    path: '/campaign-management',
    allowedGroups: [
      USER_GROUPS.Admin_Group,
      USER_GROUPS.Advanced_Group,
      USER_GROUPS.Company_Admin_Group,
      USER_GROUPS.Company_Advanced_Group,
      USER_GROUPS.Company_Regular_Group,
      USER_GROUPS.Company_Trial_Group,
      USER_GROUPS.Partner_Admin_Group,
      USER_GROUPS.Partner_Advanced_Group,
      USER_GROUPS.Partner_Regular_Group,
    ]
  },
  USER_MANAGEMENT: {
    key: 'USER_MANAGEMENT',
    label: 'User mangement',
    path: '/user-management',
    allowedGroups: [
      USER_GROUPS.Admin_Group,
      USER_GROUPS.Partner_Admin_Group,
      USER_GROUPS.Company_Admin_Group,
    ]
  },
  SELECT_ANALYSIS: {
    key: 'SELECT_ANALYSIS',
    label: 'New Analysis',
    ariaLabel: 'New Analysis',
    path: '/campaign/select-analysis',
    allowedGroups: [
      USER_GROUPS.Admin_Group,
      USER_GROUPS.Advanced_Group,
      USER_GROUPS.Company_Admin_Group,
      USER_GROUPS.Company_Advanced_Group,
      USER_GROUPS.Company_Trial_Group,
      USER_GROUPS.Partner_Admin_Group,
      USER_GROUPS.Partner_Advanced_Group,
      USER_GROUPS.MMM_Group,
      USER_GROUPS.Admin_Group
    ]
  },
  USER_GUIDE: {
    key: 'USER_Guide',
    label: 'User Guide',
    path: '/user-guide',
    allowedGroups: [
      USER_GROUPS.Company_Trial_Group,
      USER_GROUPS.Admin_Group,
      USER_GROUPS.Advanced_Group,
      USER_GROUPS.Company_Admin_Group,
      USER_GROUPS.Company_Advanced_Group,
      USER_GROUPS.Company_Trial_Group,
      USER_GROUPS.Partner_Admin_Group,
      USER_GROUPS.Partner_Advanced_Group
    ]
  },
};

/**
 * Source of truth for all product features
 */
export const PRODUCT = {
  MMM: {
    key: 'MMM',
    label: 'MMM',
    path: '/campaign/mca',
    features: [
      FEATURES.MMM_ANALYSIS,
      FEATURES.MMM_SELECTED,
      FEATURES.MMM_DASHBOARDS,
      FEATURES.MANAGE_DATA,
      FEATURES.SCENARIO_PLANNER,
      FEATURES.SCENARIO_OPTIMIZER,
      FEATURES.SCORING
    ],
    allowedGroups: [
      USER_GROUPS.Admin_Group,
      USER_GROUPS.Company_Trial_Group,
      USER_GROUPS.MMM_Group
    ]
  },
  ADIMPACT: {
    key: 'ADIMPACT',
    label: 'AdImpact',
    path: '/campaign/ad-impact',
    features: [
      FEATURES.ADIMPACT_ANALYSIS,
      FEATURES.ADIMPACT_DASHBOARDS,
      FEATURES.MANAGE_DATA
    ],
    allowedGroups: [
      USER_GROUPS.Admin_Group,
      USER_GROUPS.AdImpact_Group
    ]
  },
  MASLOW: {
    key: 'MASLOW',
    label: 'Maslow',
    path: '/maslow',
    features: [
      FEATURES.RUN_MASLOW
    ],
    allowedGroups: [
      USER_GROUPS.Admin_Group,
      USER_GROUPS.Wavecast_Group
    ]
  },
  MARKETING_TOOLS: {
    key: 'MARKETING_TOOLS',
    label: 'Marketing Tools',
    path: '/marketing-tools',
    features: [
      FEATURES.OSTROW
    ],
    allowedGroups: [
      USER_GROUPS.Admin_Group,
      USER_GROUPS.Company_Trial_Group
    ]
  },
  USER_MANAGEMENT: {
    key: 'USER_MANAGEMENT',
    label: 'User mangement',
    path: '/user-management',
    features: [
      FEATURES.USER_MANAGEMENT,
    ],
    allowedGroups: [
      USER_GROUPS.Admin_Group,
      USER_GROUPS.Partner_Admin_Group,
      USER_GROUPS.Company_Admin_Group,
    ]
  },
  CAMPAIGN_MANAGEMENT: {
    key: 'CAMPAIGN_MANAGEMENT',
    label: 'Campaign Management',
    path: '/campaign-management',
    features: [
      FEATURES.MANAGE_CAMPAIGNS
    ],
    allowedGroups: [
      USER_GROUPS.Admin_Group,
      USER_GROUPS.Advanced_Group,
      USER_GROUPS.Company_Admin_Group,
      USER_GROUPS.Company_Advanced_Group,
      USER_GROUPS.Company_Regular_Group,
      USER_GROUPS.Company_Trial_Group,
      USER_GROUPS.Partner_Admin_Group,
      USER_GROUPS.Partner_Advanced_Group,
      USER_GROUPS.Partner_Regular_Group,
    ]
  },
  MANAGE_DATA: {
    key: 'MANAGE_DATA',
    label: 'Manage Data',
    ariaLabel: 'Manage Data',
    path: '/campaign/data-management',
    features: [
      FEATURES.MANAGE_DATA
    ],
    allowedGroups: [
      USER_GROUPS.Company_Trial_Group,
      USER_GROUPS.Admin_Group,
      USER_GROUPS.Advanced_Group,
      USER_GROUPS.Company_Admin_Group,
      USER_GROUPS.Company_Advanced_Group,
      USER_GROUPS.Company_Trial_Group,
      USER_GROUPS.Partner_Admin_Group,
      USER_GROUPS.Partner_Advanced_Group,
    ]
  },
};
