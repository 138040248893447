import React from 'react';
import {DATE_SPAN} from '../../constants/dates';
import PropTypes from 'prop-types';
import {ComposedChart, Bar, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer} from 'recharts';
import {getChartColorAtIndex} from '../../components/charts/chartColors';
import {useToggleColumnLegend} from './CustomLegend';
import {CustomTooltip} from './CustomTooltip';
import {CHART_FORMAT} from './ChartFormat.constant';
import {useValidateChartData} from './useValidateChartData';
import {formatAxis} from './chart-format.util';
import {styles, getChartStateCss} from './charts.styles';
import {useBroadcastDates} from '../../hooks/useBroadcastDates';
import {useDataRollup} from '../../hooks/useDataRollup';

import _ from 'lodash';

/**
 * Builds a stacked bar chart of the contribution data,
 * http://recharts.org/en-US/api/BarChart
 */
export const StackedBarChartLV = ({
  data=[], xAxisLabel='', yAxisLabel='', xAxisKey='x', yAxisKey='y', isLoading=false, formatMap={},
  isBroadcastDates=false, TopLeftContent, secondaryValue='', isClustered, interval
}) => {
  let chartData = useValidateChartData(data, 1, xAxisKey, yAxisKey);
  const chartStateCss = getChartStateCss(data, isLoading);
  const [dateSpan, setDateSpan] = React.useState(DATE_SPAN.NONE);
  const {
    legendClickHandler,
    hiddenColumnMap,
    CustomLegend
  } = useToggleColumnLegend(chartData);
  const {
    selectedBroadcastDates, dataWithTimestamps, min, max
  } = useBroadcastDates({
    data: chartData,
    selectedDateSpan: dateSpan,
    isLoading,
    isBroadcastDates
  });
  const rollupData = useDataRollup({
    data: dataWithTimestamps,
    timestamps: selectedBroadcastDates,
    isDataRollup: isBroadcastDates
  });
  if(isBroadcastDates) {
    // Add timestamps and hide them on display to user
    chartData = rollupData;
    formatMap = {...formatMap, timestamp: CHART_FORMAT.HIDDEN};
  }
  
  const getStackedBar = (item, index) => {
    let returnItem;
    if (item != secondaryValue) {
      returnItem = (
        <Bar
          yAxisId={yAxisLabel}
          maxBarSize={200}
          key={index}
          stackId="a"
          dataKey={item}
          hide={hiddenColumnMap[item]}
          fill={getChartColorAtIndex(index)} />
      );
    } else if (isClustered) {
      returnItem = (
        <Bar
          yAxisId={yAxisLabel}
          maxBarSize={200}
          key={index}
          dataKey={item}
          hide={hiddenColumnMap[item]}
          fill={getChartColorAtIndex(2)} />
      );
    } else {
      returnItem = (
        <Line
          yAxisId={yAxisLabel}
          maxBarSize={200}
          key={index}
          hide={hiddenColumnMap[item]}
          dot={false}
          stroke={"black"}
          opacity={"50%"}
          strokeWidth={3}
          dataKey={item}/>
      );
    }
    return returnItem;
  };
  const keysToRemove = ['name','date','timestamp','region', 'variable'];
  const keys = _.uniq(_.flatten(_.map(chartData, _.keys))).filter(k => !keysToRemove.includes(k));
  const StackedBars = keys.map(getStackedBar);

  // Hide name row in tooltip
  let toolTipOverride = {};
  const tooltipMap = {
    name: CHART_FORMAT.HIDDEN,
    ...toolTipOverride,
    ...formatMap
  };

  // Convert "timestamp" key to "name" for reCharts to be able to display each date as its own bar
  if (isBroadcastDates) {
    chartData = chartData.map(({
      timestamp: name,
      region,  // destructuring removes region and it won't be included in `...rest`
      ...rest
    }) => ({
      name,
      ...rest
    }));
  } 

  const classNames = [styles.chart, chartStateCss].join(' ');
  return (
    <ResponsiveContainer
      width='100%'
      height={350}
      debounce={1}
      id='stacked-bar-chart'
      className={classNames}>

      <ComposedChart 
        data={chartData}
        stackOffset='sign' >
        <XAxis 
          dataKey={xAxisLabel}
          axisLine={false} 
          tickLine={false}
          tickFormatter={formatAxis(xAxisKey, formatMap)}
          interval={interval}
          />
        <YAxis
          id='stacked-bar-chart-primary-y-axis'
          yAxisId={yAxisLabel}
          width={100}
          tickMargin={10}
          tickCount={11}
          tickFormatter={formatAxis(yAxisKey, formatMap)}
          label={{
            value: yAxisLabel,
            angle: -90,
            offset: 0,
            position: 'left',
            style:{ textAnchor: 'middle' }
          }}/>
        <Tooltip 
          content={<CustomTooltip map={tooltipMap} 
          hiddenColumnMap={hiddenColumnMap}
          />}
          cursor={false} 
        />

        <Legend
          align='left'
          verticalAlign='top'
          hiddenColumnMap={hiddenColumnMap}
          onClick={legendClickHandler}
          isLoading={isLoading}
          isBroadcastDates={isBroadcastDates}
          dateSpan={dateSpan}
          setDateSpan={setDateSpan}
          TopLeftContent={TopLeftContent}
          content={CustomLegend}
        />

        {StackedBars}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

StackedBarChartLV.propTypes = {
  /**
   * EXAMPLE:
   * [{name: 'Slice1', value: 1234.0345, percentage: 45.456}]
   */
  data: PropTypes.array,
  /**
   * Text to dispay on the X Axis
   */
  xAxisLabel: PropTypes.string,
  /**
   * Text to dispay on the Y Axis
   */
  yAxisLabel: PropTypes.string,
  /**
   * Map of keys and the desired format the values will be in
   */
  formatMap: PropTypes.object
};
