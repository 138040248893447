import React from 'react';
import {usePost} from '../../hooks/useRest';
import {useGetScenarioTable} from './useGetScenarioTable';
import {urlBuilders} from '../../js/constants';

export const usePostScenarioPlanner = (mcaRuns=[], selectedMcaRun={}, dashboardRegion) => {
  const selectedMcaRunId = selectedMcaRun.id;

  const [{
    data: scenario1={tableResults:[]},
    loading: scenario1Loading
  }, postScenario1] = usePost({
    url: urlBuilders.postScenarioPlanner()
  });

  const [{
    data: scenario2={tableResults:[]},
    loading: scenario2Loading
  }, postScenario2] = usePost({
    url: urlBuilders.postScenarioPlanner()
  });

  const {
    modelMap={},
    groupMap={},
    optimizerMap={},
    nestedKpis={},
    nestedRunIds=[],
    regions=[],
    loading: modelListLoading,
  } = useGetScenarioTable(mcaRuns, selectedMcaRun, dashboardRegion);

  const regionModelMap = modelMap[dashboardRegion] || {};

  // Stitch contribution with model data and memoize
  const scenario1Map = React.useMemo(
    () => getScenarioMap(scenario1.tableResults, regionModelMap, selectedMcaRun),
    [scenario1.tableResults, regionModelMap, selectedMcaRun]);

  const scenario2Map = React.useMemo(
    () => getScenarioMap(scenario2.tableResults, regionModelMap, selectedMcaRun),
    [scenario2.tableResults, regionModelMap, selectedMcaRun]);

  const modelAndScenarioList = React.useMemo(
    () => combineScenarioAndModelLists(regionModelMap, [scenario1Map, scenario2Map]),
    [regionModelMap, scenario1Map, scenario2Map]);

  // Handle posting form
  const postScenarios = React.useMemo(
    () => getPostScenarioRestAction(selectedMcaRunId, postScenario1, postScenario2, nestedRunIds, dashboardRegion),
    [selectedMcaRunId, postScenario1, postScenario2, dashboardRegion]
  );

  return {
    scenarios: modelAndScenarioList,
    nestedKpis: nestedKpis,
    regions: regions,
    loading: [scenario1Loading, scenario2Loading, modelListLoading].some(s=>s),
    postScenarios,
    optimizerMap,
    groupMap
  };
};

/**
 * Break out this function so it can be Memoized
 * This was causing triggering Effect changes when used by other components
 */
const getPostScenarioRestAction = (
  selectedMcaRunId,
  postScenario1=()=>{},
  postScenario2=()=>{},
  nestedRunIds=[],
  dashboardRegion
) => {
  // prevent REST calls with error body when resetting scenarios
  return (formBody={}) => {
    const scenario1Body = {
      data: {
        mcaRunId: selectedMcaRunId,
        nestedRunIds: nestedRunIds,
        table: parseFormData(formBody, '_scenario1'),
        region: dashboardRegion
      }
    };

    const scenario2Body = {
      data: {
        mcaRunId: selectedMcaRunId,
        nestedRunIds: nestedRunIds,
        table: parseFormData(formBody, '_scenario2'),
        region: dashboardRegion
      }
    };

    postScenario1(scenario1Body);
    postScenario2(scenario2Body);
  };
};

const parseFormData = (body={}, scenarioName) => {
  const relevantFields = Object.entries(body).filter(([key]) => (key.endsWith(scenarioName) || key.endsWith('_price')));
  return relevantFields.reduce((prev, [key, value]) => (
    {
      ...prev,
      [key.replace(scenarioName, '')]: value
    }
  ), {});
};

/**
 * Scenario/Model list
 * @param scenarios List of scenarios returned from back-end
 * @param model Mca Model info
 * @return List of all model contributions with associated scenarios
 */
const combineScenarioAndModelLists = (model={}, scenarios=[]) => {
  return Object.values(model).map((contribution) => ({
    ...contribution,
    ...scenarioContributionListToMap(scenarios, contribution.variableName)
  }));    
};

/**
 * Add list of scenario contributions to a single map
 */
const scenarioContributionListToMap = (scenarios=[], contributionKey) => {
  return scenarios
    .filter(s=>s[contributionKey])
    .reduce((map, scenario, index) => {
      const idx = index+1;
      return {
        ...map,
        [`scenario${idx}Value`]: scenario[contributionKey].value,
        [`scenario${idx}VariableValue`]: scenario[contributionKey].variableValue,
        [`costPer`]: scenario[contributionKey].costPer,
        [`scenario${idx}Spend`]: scenario[contributionKey].spend,
        [`scenario${idx}Contribution`]: scenario[contributionKey].variableContribution,
        [`scenario${idx}Ratio`]: scenario[contributionKey].ratio
      };
    }, {});
};

/**
 * Calculate scenario contribution
 * @param variableValue from scenarioPlanner endpoint response
 * @param contribution from scenarioPlanner endpoint response
 * @param price suffixed with "_price" from scenarioPlanner back-end result
 * @param measure type of measure from the MCA Model
 * @param kpiType kpi label from MCA Run
 * @return Object with calculation results
 */
export const calculateContribution = ({
  variableValue=0,
  price=1,
  measure,
  contribution=0,
  kpiType
}) => {
  const nonSpenders = ['Binary', 'Base', 'Control'];
  let spend = variableValue;
  let value = variableValue;

  if(measure === 'Impressions') {
    value = value/1000;
    spend = spend/1000;
  }

  if(nonSpenders.includes(measure)){
    spend = 0;
  }

  let typeResult = {
    variableContribution: contribution,
    ratio: contribution/(spend || 1)
  };

  if(kpiType === 'Units') {
    typeResult = {
      variableContribution: contribution,
      ratio: spend/(contribution || 1)
    };
  }

  return {
    costPer: price,
    value: value,
    spend,
    variableValue,
    ...typeResult
  };
};

/**
 * Get map of model and scenario contribution results
 * Use model map to populate scenario data if scenario has not yet been POSTED
 * @param scenarioData Scenario contribution result from the back-end
 * @param modelMap Mca Model info
 * @param mcaRun Selected MCA run
 * @returns Map of Scenario contributions and MCA model data
 */
export const getScenarioMap = (scenarioData=[], modelMap={}, mcaRun={}) => {
  if(!scenarioData.length) {
    scenarioData = Object.keys(modelMap).map(key => ({variableName: key}));
  }

  const regPrice = /_price/g;
  const contributions = scenarioData.filter(row =>!priceFilter(row));
  const priceMap = scenarioData
        .filter(priceFilter)
        .reduce((map, row) => ({
          ...map,
          [row.variableName.replace(regPrice, '')]: row
        }), {});

  return contributions.reduce((map, contributionRow) => {
    const model = modelMap[contributionRow.variableName] || {};
    const priceContribution = priceMap[contributionRow.variableName] || {};
    const calcResult = calculateContribution({
      variableValue: contributionRow.variableValue,
      contribution: contributionRow.contribution,
      price: priceContribution.variableValue,
      measure: model.measure,
      kpiType: mcaRun.kpiType
    });

    return {
      ...map,
      [contributionRow.variableName]: {
        ...calcResult,
      }
    };
  }, {});
};

const priceFilter = (scenario={}) => {
  return typeof scenario.variableName === 'string' && scenario.variableName.endsWith('_price');
};
