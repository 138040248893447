export const getGroupMeasure = (translator, name) => {
    const variableMap = translator[name];
    if (variableMap) {
        return variableMap.name + ' ' + variableMap.measure;
    } else {
        return name;
    }
};

export const getGroup = (translator, name) => {
    const variableMap = translator[name];
    if (variableMap) {
        return variableMap.name;
    } else {
        return name;
    }
};

export const getMeasure = (translator, name) => {
    const variableMap = translator[name];
    if (variableMap) {
        return variableMap.measure;
    } else {
        return name;
    }
};
