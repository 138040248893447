import {queryBuilder} from './utils.js';

// constants
export const maxStatusTries = 60;

export const bigIntLimit = 9007199254740991;

export const MAX_MODEL_DOWNLOAD = 500000;
export const MAX_MODEL_ANALYSIS = 1000;

export const memoryDecays = ['No', '10', '20', '30', '40', '50', '60', '70', '80', '90', '?'];
export const saturations = ['No', 'OPTIMAL', 'BUILD', 'SATURATED', '?'];
export const measures = ['Spend', 'Impressions', 'Control', 'Ratings', 'Binary', 'Clicks'];

export const kpi_filename = 'dependent_variable.csv';

export const NESTED_MODEL_TYPE_OPTIONS = [
    {label: 'Media Interaction Model',value: 'MEDIA_INTERACTION_MODEL'}, {label: 'Sub-Model', value: 'SUB_MODEL'}
];

export const urlBuilders = {
    postRecentActivity: () => ('v1/recent-activity'),
    runWavecast: () => `v1/wavecast/run/s3`,
    getWavecastAnalysisResults: (wavecastId, query) =>
     `v2/wavecast/${wavecastId}` + queryBuilder(query),
    getMcaRunByCampaignId: (id) => `v1/mca-run/?campaignId=${id}`,
    getAllPartners: () => `v1/partner`,
    getPartnerById: (partnerId) => `v1/partner/${partnerId}`,
    getAllClients: () => `v1/company`,
    getMcaRun: (mcaRunId) => `v1/mca-run/${mcaRunId}`,
    getAllMcaRuns: () => `v1/mca-run`,
    runMca: () => `v1/mca-run`,
    exportMcaRun: (id) => `v1/mca-run/${id}/export`,
    getDataStatus: (id) => `v1/input-data/${id}/status`,
    uploadFiles: () => `api/data-manager/v1/input-data/`,
    createCampaign: () => `v1/campaign`,
    createClient: () => `v1/company`,
    getCampaignById: (id) => `v1/campaign/${id}`,
    updateCampaign: (id) => `v1/campaign/${id}`,
    createPartner: () => `v1/partner`,
    getMcaResultCount: (mcaRunId) => `v2/mca-run/${mcaRunId}/result/count`,
    getMcaVariables: (mcaRunId) => `v2/mca-run/${mcaRunId}/groups`,
    getMcaModels: (mcaRunId) => `v2/mca-run/${mcaRunId}/result/models`,
    getMcaRanges: (mcaRunId) => `v2/mca-run/${mcaRunId}/result/range`,
    getMcaModelsByModelId: (mcaRunId) =>
        `v1/mca-run/${mcaRunId}/result/models-by-id`,
    getQueryStatus: (queryId) => `v1/query-status/${queryId}`,
    getResultRows: (queryId) => `v1/query-result/rows/${queryId}`,
    getResultCount: (queryId) => `v1/query-result/count/${queryId}`,
    getResultModel: (queryId) => `v1/query-result/model/${queryId}`,
    getResultRanges: (queryId) => `v1/query-result/range/${queryId}`,
    getClientById: (id) => `v1/company/${id}`,
    updateClient: (id) => `v1/company/${id}`,
    deleteClient: (id) => `v1/company/${id}`,
    completeUpload: () => `v1/complete-upload`,
    scoringCompleteUpload: () => `v1/scoring-complete-upload`,
    deleteScoring: (scoringId) => `v1/scoring/${scoringId}`,
    updateScoring: (scoringId) => `v1/scoring/${scoringId}`,
    configAICompleteUpload: () => `v1/configai-complete-upload`,
    exportMca: (queryId, filetype, fileName) =>
        `v1/export-athena` + queryBuilder({queryId, filetype, fileName}),
    getUploadedCampaignFilesMetaData: (campaignId) =>
        `v1/uploaded-campaign-files-metadata` + queryBuilder({campaignId}),
    getUploadedFileById: (fileId) => `v1/uploaded-file/${fileId}`,
    getGroupingTemplateStatus: (campaignId, dataType) =>
        'v1/data-status' + queryBuilder({campaignId, dataType}),
    getGroupingTemplateUrl: (bucketName, keyPath) =>
        'v1/get-pre-signed-url' + queryBuilder({bucketName, keyPath}),
    getScoringArchiveUrl: (scoringId, mcaRunId) =>
        'v1/get-pre-signed-url' + queryBuilder({bucketName, keyPath}),
    getKpis: ({id, type}) => 'v1/kpi' + queryBuilder({id, type}),
    getKpisForCampaignId: (campaignId) => 'v1/kpi' + queryBuilder({campaignId}),
    runMcaV2: () => `v2/mca-run`,
    runMcaV3: () => `v3/mca-run`,
    trialRunMcaV3: () => `v3/mca-run/trial`,
    updateMcaRun: (mcaRunId) => `v2/mca-run/${mcaRunId}`,
    deleteMcaRun: (mcaRunId) => `v2/mca-run/${mcaRunId}`,
    selectFinalModel: (mcaRunId, partition, modelId, encoding, runName) =>
        `v1/final-model` + queryBuilder({mcaRunId, partition, modelId, encoding, runName}),
    approveModel: (mcaRunId, partition, modelId, encoding, runName) =>
        `v1/final-model` + queryBuilder({mcaRunId, partition, modelId, encoding, runName}),
    postFinalModel: () => `v1/final-model`,
    getFileLocation: (campaignId, dataType) =>
        'v1/data-location' + queryBuilder({campaignId, dataType}),
    getFileUrl: (bucketName, keyPath, fileName) =>
        'v1/get-pre-signed-url' + queryBuilder({bucketName, keyPath, fileName}),
    getUploadedFileAsJson: (bucketName, keyPath) =>
        'v1/get-pre-signed-url' + queryBuilder({bucketName, keyPath, json: true}),
    getUploadedVariables: (campaignId) =>
        `v1/uploaded-variables/${campaignId}`,
    getSelectedModel: (mcaRunId) =>
        `v1/selected-model` + queryBuilder({mcaRunId}),
    getUsers: () => `v1/user`,
    getUserByUsername: (username) => `v1/user/${username}`,
    fetchInsightArchive: (mcaRunId, modelId, fileName) =>
        `v1/insights-archive-url` + queryBuilder(modelId ? {mcaRunId, modelId, fileName} : 
            {mcaRunId, fileName}),
    updateV2McaRunStatus: (mcaRunId) => `v2/mca-run/${mcaRunId}/status`,
    updateV3McaRunStatus: (mcaRunId) => `v3/mca-run/${mcaRunId}/status`,
    exportErrorsCsv: (linkingId) =>
        `v1/export-errors` + queryBuilder({linkingId}),
    getOutputsInsightsDataByType: (id, fileType, mcaRunId, modelId, kpi) =>
        `v2/get-outputs-insights` + queryBuilder({id, fileType, mcaRunId, modelId, kpi}),
    getOutputsInsightsUrl: (id, fileType, header) =>
        `v2/get-outputs-insights` + queryBuilder({id, fileType, header}),
    getMcaTransform: (id, fileType, fileName) =>
        `v1/get-mca-transform` + queryBuilder({id, fileType, fileName}),
    getScoringTransform: (id, fileType, fileName) =>
        `v1/get-scoring-transform` + queryBuilder({id, fileType, fileName}),
    getGroupMap: (id) => `v2/mca-run/${id}/readable-name`,
    createUser: () => `v1/user`,
    deleteUser: (username) => `v1/user/${username}`,
    updateUser: (username) => `v1/user/${username}`,
    resendUserInvite: () => `v1/resend-invite`,
    getMediaGroupingConfigs: (campaignId, mcaRunId, configAIId) =>
        `v1/media-grouping-configs` + queryBuilder(mcaRunId ? {mcaRunId} : {campaignId, configAIId}),
    postScenarioPlanner: () => `v1/scenario-planner`,
    postWavecast: () => `v2/wavecast`,
    getWavecast: (wavecastId) => `v2/wavecast/${wavecastId}`,
    getWavecastRunsByCampaignId: (campaignId) => `v2/wavecast?campaignId=${campaignId}`,
    startScoreCalculations: () => `v1/scoring`,
    getScoreCalculationsByCampaignId: (campaignId) => `v1/scoring/?campaignId=${campaignId}`,
    deleteFileByFileId: (fileId) => `v1/data-location?id=${fileId}`,
    modelOutputsInsights: () => `v1/model-outputs-insights`,
    getCompletedOutputsInsights: (mcaRunId, modelIds) =>
        `v1/outputs-insights` + queryBuilder({mcaRunId, modelIds}),
    postDownloadDataTemplate: () => 'v1/data-template',
    getScoringFileUrl: (scoringId, mcaRunId, fileType) => `v1/scoring-input` + queryBuilder({scoringId, mcaRunId, fileType}),
    deleteAdImpactRun: (wavecastId) => `v2/wavecast/${wavecastId}`,
    updateAdImpactRun: (wavecastId) => `v2/wavecast/${wavecastId}`,
    getOutputsInsightsStatus: (mcaRunId) => `v1/outputs-insights-status/${mcaRunId}`,
    runConfigAI: () => `v1/configai`,
    getConfigAIRunStatus: (campaignId, configAIId) => `v1/configai-status` + queryBuilder({campaignId, configAIId}),
    getScoringOutputsInsightsStatus: (scoringId) => `v1/outputs-insights-status/scoring/${scoringId}`,
    startModelAnalysis: (runId, queryId) => `v1/model-analysis` + queryBuilder({runId, queryId}),
    getModelAnalysisUrl: (runId, dataType, fileName) => 'v1/generated-file-url' + queryBuilder({runId, dataType, fileName}),
    runMagnifAI: () => `v1/magnifai`,
    getMagnifAIRunStatus: () => `v1/magnifai-status`,
    getSupersetGuestToken: (dashboardId) => `v1/superset/guest-token?dashboardId=${dashboardId}`,
    postFinalizeMagnifaiRun: () => `v1/magnifai-finalizer`,
    getVariableDetails: (runId) => `v1/variable-details` + queryBuilder({runId}),
    postVariableDetails: () => `v1/variable-details`,
    postMcaRunFileUrl: (mcaRunId) => 'v1/mca-run-file-url' + queryBuilder({mcaRunId}),
    postCombineScenarios: () => `v1/combine-scenarios`,
    queryChartData: () => `v1/chart-data`,
    queryInsight: () => `v1/insight-query`,
    getInsightUrl: (queryId) => `v1/chart-data/${queryId}/url`,
    getChartDataUrl: (queryId) => `v1/insight-query/${queryId}/url`,
    getInsightMetadata: (runId, scoringId) => `v1/insight-metadata` + queryBuilder({runId, scoringId}),
};
