import {css} from 'emotion';

export const styles = {
  content: css`
    .scoring-model-summary-table {
      .header-cell-container {
        text-align: center !important;
      }

      td {
        padding: 20px;
        color: grey;
      }
    }
  `
};
