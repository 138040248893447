import {css} from 'emotion';

export const styles = {
  panel: css`
    background-color: #FFF;
    width: 100%;
    .top-graph-dropdown {
      width: 146px;
      padding-right: 24px;
    }

    .left-graph-dropdown {
      min-width: 146px;
      display: flex;
      align-items: center;
      padding-right: 24px;
    }

    .bottom-graph-dropdown {
      width: 146px;
      padding: 24px 0 0 224px;
    }
  `,
  graphsContainer: css`
    display: flex;
    width: inherit;
    justify-content: center;
    align-items: center;
    padding-top: 24px;
  `,
  belowGraph: css`
    display: flex;
    justify-content: center;
  `,
  label: css`
    text-align: center;
    margin: 18px 0;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    white-space: nowrap;

  `
};
