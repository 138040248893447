import React from 'react';
import PropTypes from 'prop-types';
import {Config} from "../../config/ConfigFactory";

export const SupersetCreateChart = ({
                                        callToActionComponent,
                                        tableName = ''
                                    }) => {

    if (!callToActionComponent) {
        return;
    }

    const handleClick = () => {

        const url = encodeURI(`${Config.getConfig().SUPERSET_DOMAIN}/tablemodelview/list/?filters=(table_name:${tableName})&pageIndex=0&sortColumn=changed_on_delta_humanized&sortOrder=desc`)
        window.open(url, '_blank').focus();
    }

    // Add Open functionality to passed in Action component
    const ActionComponentEl = React.cloneElement(callToActionComponent, {
        onClick: handleClick
    });

    return (
        <div>
            {ActionComponentEl}
        </div>
    );
};

SupersetCreateChart.propTypes = {
    callToActionComponent: PropTypes.func,
    campaignName: PropTypes.string
};
