import React from 'react';

const ImagePopoverContext = React.createContext();

function ImagePopoverProvider({children}) {

    const [imageUrl, setImageUrl] = React.useState(null);
    const [alt, setAlt] = React.useState(null);
    const [popoverOpen, setPopoverOpen] = React.useState(false);
    const [style, setStyle] = React.useState({});

    const openPopover = ({imageUrl, alt, style}) => {
        setImageUrl(imageUrl);
        setAlt(alt);
        setStyle(style);
        setPopoverOpen(true);
    }

    const closePopover = () => {
        setImageUrl(null);
        setAlt(null);
        setPopoverOpen(false);
    }

    return (
        <ImagePopoverContext.Provider
            value={{
                imageUrl,
                alt,
                openPopover,
                closePopover,
                isOpen: popoverOpen,
                style,
                setStyle
            }}>

            {children}

        </ImagePopoverContext.Provider>
    );

}

export {ImagePopoverProvider, ImagePopoverContext};

