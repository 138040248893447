import React from 'react';
import { urlBuilders } from '../../js/constants';
import { useGetPolling } from '../../hooks/usePolling';
import {GridStatusCell} from "../../components/data-grid/GridStatusCell";
import {RUN_STATUS} from "../../constants/mca-run-statuses";

/**
 * Display normal cell status if File Row is not pending
 * If pending, Poll the file endpoint and refresh table on complete
 */
export const FileStatusCell = ({
                                   cell,
                                   fetchFiles
                                  }) => {

    if(cell?.row?.fileStatus === RUN_STATUS.PENDING) {
        return (
            <FilePollingStatusCell
                cell={cell}
                fetchFiles={fetchFiles}
            />
        );
    }

    return (
        <GridStatusCell
            dataTest={`file-table-status-cell-${cell.row.fileName}`}
            id={cell.row.id}
            status={`FILE_${cell.row.fileStatus}`}
            name={cell.row.fileName}
            onClick={() => {}}
        />
    );
};


const FilePollingStatusCell = ({
                                      cell,
                                      fetchFiles = () => {}
                                  }) => {
    const fileId = cell?.row?.id;
    const pollUrl = urlBuilders.getUploadedFileById(fileId);

    const {data=[], clearIntervalInstance} = useGetPolling({
        interval: 1000,
        url: pollUrl
    });

    const statusFromData = React.useMemo(() => {
        return data.file_status;
    }, [data]);

    React.useEffect(() => {
        if(!statusFromData) {
            return;
        }

        if (statusFromData !== RUN_STATUS.PENDING) {
            clearIntervalInstance();
            fetchFiles();
        }

    }, [statusFromData]);

    return (
        <GridStatusCell
            dataTest={`file-table-status-cell-${cell.row.name}`}
            id={cell.row.id}
            status={`FILE_${cell.row.fileStatus}`}
            name={cell.row.fileName}
            onClick={() => {}}
        />
    );

};
