import React from 'react';
import {MoreVert, Redo, BarChart, Insights, Add} from "@mui/icons-material";
import _ from 'lodash';
import {R1ActionIcon, R1Button, R1Popover} from "@rainagency/rain-one-soggy-loaf";
import McaModelsManagement from './mca-models-comparison/mca-models-components/mcaModelsManagement';
import {SelectedMcaModel} from './SelectedMcaModel';
import {useUserRoleFromGroup} from '../../hooks/useUserRoleFromGroup';
import {useCampaignManagement } from '../../hooks/useCampaignManagement';
import {useMcaRuns} from '../../hooks/useMcaRuns';
import { FeatureLV } from '../../components/pages/FeatureLV';
import { useFeatureSelect } from '../../hooks/useFeatureSelect';
import { FEATURES } from '../../constants/features';

import StartMagnifaiModal from "./magnifai/StartMagnifaiModal";

export const McaSelected = () => {
  const [hasParsedModels, setHasParsedModels] = React.useState();
  const [insightsStatus, setInsightsStatus] = React.useState();
  const [startInsights, setStartInsights] = React.useState();

  const {mcaRunId, magnifAIId} = useCampaignManagement();
  const {
    selectedMcaRun,
    loading: isMcaRunLoading,
    magnifaiRuns=[]
  } = useMcaRuns();

  const status = _.get(selectedMcaRun, 'runStatus');
  const matchingMagnifaiRun = magnifaiRuns?.filter(run => run.magnifai_id == magnifAIId)[0];
  const [magnifaiRun, setMagnifaiRun] = React.useState();
  React.useEffect(()=> {
    if (matchingMagnifaiRun) {
      setMagnifaiRun(matchingMagnifaiRun)
    }
  }, [matchingMagnifaiRun, setMagnifaiRun])

  let generateInsightsButtonText;
  switch(insightsStatus) {
    case 'loading':
      generateInsightsButtonText = 'Generating Outputs and Insights ';
      break;
    case 'done':
      generateInsightsButtonText = 'Outputs and insights generated ';
      break;
    case 'enabled':
      generateInsightsButtonText = 'Generate Outputs and Insights ';
      break;
    default:
      generateInsightsButtonText = null;
  }

  if(!mcaRunId && isMcaRunLoading) {
    return (
      <FeatureLV
        loading={true}
        title='Selected MMM Analysis'
        description={`Loading analysis: ${selectedMcaRun?.name}`}>
      </FeatureLV>
    );
  }

  if(!mcaRunId) {
    return (
      <FeatureLV
        title='No MMM Analysis Selected'
        description={'Select an MMM analysis'} >
      </FeatureLV>
    );
  }

  if (status === 'RUN_COMPLETE' && hasParsedModels) {
    return (
      <FeatureLV
        title='Model Comparison'
        sx={{
          '.feature-children': {
            'overflowY': 'hidden'
          }
        }}
        description={`Filter and select a model for analysis: ${selectedMcaRun?.name}`}
        CallToAction={
          <ModelActionItemMenu 
            selectedMcaRun={selectedMcaRun} 
            generateInsightsButtonText={generateInsightsButtonText} 
            insightsStatus={insightsStatus} 
            setStartInsights={setStartInsights} />
        }>
          <McaModelsManagement
            setHasParsedModels={setHasParsedModels}
            setStartInsights={setStartInsights}
            setInsightsStatus={setInsightsStatus}
            startInsights={startInsights} />
      </FeatureLV>
    );
  } else if (status === 'RUN_COMPLETE') {
    return (
      <FeatureLV
        title='Model Comparison'
        sx={{
          '.feature-children': {
            'overflowY': 'hidden'
          }
        }}
        description={`Filter and select a model for analysis: ${selectedMcaRun?.name}`}
        CallToAction={
          <ReRunCallToAction selectedMcaRun={selectedMcaRun}/>
        }>
          <McaModelsManagement setHasParsedModels={setHasParsedModels} />
      </FeatureLV>
    );
  } else if (status === 'FINAL') {
    return (
      <FeatureLV
        title='Selected Model'
        description={`Selected model for analysis: ${selectedMcaRun?.name}`}
        CallToAction={
          <ActionItemMenu selectedMcaRun={selectedMcaRun} magnifaiRun={magnifaiRun} />
        }>

        <SelectedMcaModel magnifaiRun={magnifaiRun} setMagnifaiRun={setMagnifaiRun} />

      </FeatureLV>
    );
  } 
  return null;
};

const ModelActionItemMenu = ({ selectedMcaRun, generateInsightsButtonText, insightsStatus, setStartInsights }) => {
  const reRunActionIcon = <SelectAnalysis key='reRunActionIcon' runId={selectedMcaRun?.id} />;
  let children = [reRunActionIcon];
  const disabled = insightsStatus !== 'enabled';
    const InsightsIcon = React.forwardRef((props, ref) => 
      <Insights {...props} ref={ref} color={disabled ? 'disabled' : 'primary'} />);
    
  const generateOutputsAndInsights = !!generateInsightsButtonText ?
    <R1ActionIcon
      key='generateOutputsAndInsights'
      label={generateInsightsButtonText}
      disabled={disabled}
      customStyles={disabled ? { color: '#B0B0AF', cursor: 'default' } : {}}
      onClick={() => disabled ? {} : setStartInsights(true)}
      icon={InsightsIcon}
    /> : null;
 
  if (generateOutputsAndInsights) {
    children.push(generateOutputsAndInsights);
  }

  return (
    <R1Popover
      open={true}
      anchorVertical='bottom'
      anchorHorizontal='left'
      transformVertical='top'
      transformHorizontal='left'
      ActionComponent={<ActionIcon />}
      children={children}
    />
      
  );
};

const ReRunCallToAction = ({
  selectedMcaRun
}) => {
  const { isTrial } = useUserRoleFromGroup();
  const { mcaRunId } = useCampaignManagement();

  if (isTrial) {
    return null;
  }

  let value;
  if (selectedMcaRun) {
    value = {
      name: selectedMcaRun.name,
      kpi: selectedMcaRun.kpi,
      kpiType: selectedMcaRun.kpiType || 'Revenue',
      includeIntercept: selectedMcaRun.includeIntercept,
      timeScale: selectedMcaRun.timeScale || 'DAILY',
      startDate: new Date(selectedMcaRun.startDate + 'T00:00:00'),
      endDate: new Date(selectedMcaRun.endDate + 'T00:00:00')
    };
  }

  return (
    <SelectAnalysis runId={mcaRunId} isButton />
  ); 
}

const ActionItemMenu = ({ selectedMcaRun, magnifaiRun }) => {
  const TuneModelActionIcon = () => {
    if (magnifaiRun) {
      return (
        <R1ActionIcon
          label={'Model Already Tuned'}
          ModalProps={{}}
          icon={BarChart}
          customStyles={{ maxWidth: '600px', 'background-color': '#EFEFEF' }}
          hide={false} // ToDo: Reactivate MagnifAI Integration Test
        />)
    } else {
      return (
        <R1ActionIcon
          label={'Tune Model'}
          ModalContent={<MagnifaiAction selectedMcaRun={selectedMcaRun}/>}
          ModalProps={{customStyles: {maxWidth: '600px'}}}
          icon={BarChart}
          hide={selectedMcaRun.childrenId} // ToDo: Reactivate MagnifAI Integration Test
        />)
    }
  }

  const reRunActionIcon = <SelectAnalysis runId={selectedMcaRun?.id} />;

  return (
    <R1Popover
      open={true}
      anchorVertical='bottom'
      anchorHorizontal='left'
      transformVertical='top'
      transformHorizontal='left'
      ActionComponent={<ActionIcon/>}
    >
      {reRunActionIcon}

      <TuneModelActionIcon />

    </R1Popover>
  );
};

const MagnifaiAction = ({selectedMcaRun, closeModal=()=>{}}) => {

  return (
    <StartMagnifaiModal selectedMcaRun={selectedMcaRun} closeModal={closeModal}/>
  );

};

const ActionIcon = ({onClick=()=>{}}) => {
  return (
    <R1Button
      onClick={onClick}
      endIcon={<MoreVert/>}
      color='secondary'
    >
      Model Actions
    </R1Button>
  );
};

const SelectAnalysis = ({runId, isButton}) => {
  const { onFeatureSelect } = useFeatureSelect();

  const clickHandler = () => {
    onFeatureSelect({
      feature: FEATURES.SELECT_ANALYSIS,
      isReRun: true,
      runId 
    });
  };

  if (isButton) {
    return (
      <R1Button
        onClick={clickHandler}
        startIcon={<Add/>}
        color='secondary'>
        Rerun Analysis
      </R1Button>
    )
  }

  return (
    <R1ActionIcon
      label='Rerun Analysis'
      icon={Redo}
      onClick={clickHandler} />
  );
}