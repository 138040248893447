import React from 'react';
import {useGet} from '../../hooks/useRest';
import {TitleLV} from '../../components/form/title/TitleLV';
import {SelectLV} from '../../components/form/select/SelectLV';
import {FormRowLV} from '../../components/form/row/FormRowLV';
import {ButtonLV} from '../../components/form/buttons/ButtonLV';
import {OrangeButtonLV} from '../../components/form/buttons/OrangeButtonLV';
import {SuccessFailStatusModal} from '../../components/modal/SuccessFailStatusModal';
import {urlBuilders} from '../../js/constants';
import {NewPartnerForm} from './NewPartnerForm';
import {NewClientForm} from './NewClientForm';
import {NewCampaignForm} from './NewCampaignForm';
import {useGetUserPrivileges} from '../../hooks/useGetUserPrivileges';

import {styles} from './AddNewCampaign.styles';
import _ from 'lodash';

export const AddNewCampaign = ({
  refetch = () => {},
  closeModal= () => {}
}) => {

  const url = urlBuilders.getAllPartners();
  const [{
    data: partners=[],
    loading
  }] = useGet({url});

  const [formTypeOption, setFormTypeOption] = React.useState();
  const [error, setError] = React.useState();
  const [success, setSuccess] = React.useState();

  React.useEffect(() => {
    return () => refetch();
  },[refetch]);

  let typeOptions = [
    {
      label: 'Partner',
      value: TYPES.PARTNER,
      component: <NewPartnerForm
                   setError={setError}
                   setSuccess={setSuccess}
                   cancelClick={closeModal}
                 />
    },
    {
      label: 'Client',
      value: TYPES.CLIENT,
      component: <NewClientForm
                   setError={setError}
                   setSuccess={setSuccess}
                   partners={partners}
                   isLoading={loading}
                   cancelClick={closeModal}
                 />
    },
    {
      label: 'Campaign',
      value: TYPES.CAMPAIGN,
      component: <NewCampaignForm
                   setError={setError}
                   setSuccess={setSuccess}
                   partners={partners}
                   isLoading={loading}
                   cancelClick={closeModal}
                 />
    }
  ];

  // Limit options based on User Role
  const {canAddPartner, canAddCompany, canAddCampaign} = useGetUserPrivileges();
  if(!canAddPartner) {
    typeOptions = typeOptions.filter(row => row.value !== TYPES.PARTNER);
  }
  
  if(!canAddCompany) {
    typeOptions = typeOptions.filter(row => row.value !== TYPES.CLIENT);
  }

  if(!canAddCampaign) {
    typeOptions = typeOptions.filter(row => row.value !== TYPES.CAMPAIGN);
  }

  const resetState = () => {
    setError();
    setSuccess();
    setFormTypeOption({});
  };

  const FormComponent = _.get(formTypeOption, 'component', null);

  if(error || success) {
    let message = success;
    const isError = !!error;
    if(isError) {
      message = error;
    }

    return (
      <SuccessFailStatusModal
        isError={isError}
        title={<div data-test='addNew-title'>{message.title}</div>}
        description={<div>
                       <p data-test='addNew-text'>{message.text}</p>
                       <p data-test='addNew-message'>{message.description}</p>
                     </div>}>

        <FormRowLV>
          <OrangeButtonLV
            dataTest='addNewComplete-addAnotherButton'
            onClick={resetState}>
            ADD ANOTHER
          </OrangeButtonLV>

          <ButtonLV
            onClick={closeModal}
            dataTest='addNewComplete-closeButton'>
            CLOSE
          </ButtonLV>
        </FormRowLV>        
      </SuccessFailStatusModal>
    );
  }

  return (
    <div
      className={styles.container} 
      data-test='add-new-form'>

      <TitleLV>Add New</TitleLV>

      <FormRowLV>
        <SelectLV
          id='selectType-add-new-form'
          name='selectedType'
          placeholder={'Select Type'}
          options={typeOptions}
          onChange={setFormTypeOption}
          defaultValue={formTypeOption}
          isClearable={false}
          isLoading={loading}
        />
      </FormRowLV>

      {FormComponent}

    </div>
  );
};

export const TYPES = {
  PARTNER: 'PARTNER',
  CLIENT: 'CLIENT',
  CAMPAIGN: 'CAMPAIGN'
};
