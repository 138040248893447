import React from 'react';
import {useForm} from 'react-hook-form';
import {FormRowLV} from '../../components/form/row/FormRowLV';
import {TextInputLV} from '../../components/form/text/TextInputLV';
import {TitleLV} from '../../components/form/title/TitleLV';
import {SelectLV} from '../../components/form/select/SelectLV';
import {OrangeButtonLV} from '../../components/form/buttons/OrangeButtonLV';
import {ButtonLV} from '../../components/form/buttons/ButtonLV';
import {useDeleteCampaign} from './useDeleteCampaign';
import {useCampaignManagement} from '../../hooks/useCampaignManagement';
import {urlBuilders} from '../../js/constants.js';
import {usePost, usePut} from '../../hooks/useRest';
import moment from 'moment';
import _ from 'lodash';
import {useGetUserPrivileges} from '../../hooks/useGetUserPrivileges';
import {useDefaultIds} from '../../hooks/useDefaultIds';

export const NewClientForm = ({
  partners=[],
  cancelClick=()=>{},
  isLoading,
  setSuccess=()=>{},
  setError=()=>{},
  isEdit=false,
  defaultValues={},
  formTitle
}) => {
  const {handleSubmit, formState, register, errors, control, setValue, getValues} = useForm();
  const {dirty, isSubmitting} = formState;
  const {partnerId} = useCampaignManagement();
  const {defaultPartnerId} = useDefaultIds(partners);

  const url = urlBuilders.createClient();
  const updateUrl = urlBuilders.updateClient(defaultValues.id);
  const deleteUrl = urlBuilders.deleteClient(defaultValues.id);

  const [{data: postData, loading, error: postError}, postClient] = usePost({url});
  const [{
    data: putData,
    error: putError
  }, putClient] = usePut({url: updateUrl});

  React.useEffect(() => {
    if(loading) {
      return;
    }

    const {name} = getValues();
    let successTitle = 'New Client Added';
    let successText = `${name} has been successfully added.`;
    let errTitle = 'Client Not Added';
    let errText = `${name} failed to be added.`;

    if(isEdit) {
      successTitle = 'Client Updated';
      successText = `${name} has been successfully updated.`;
      errTitle = 'Client Not Updated';
      errText = `${name} failed to be updated.`;
    }

    if(postError || putError) {
      const error = postError || putError;
      setError({
        title: errTitle,
        text: errText,
        description: _.get(error, 'response.data.errors[0]')
      });
      return;
    }

    if(postData || putData) {
      setSuccess({
        title: successTitle,
        text: successText
      });
    }
  }, [postData, putData, loading, postError, putError, setError, setSuccess, getValues, isEdit]);

  const {DeleteCampaignModal, DeleteSuccessFailMessage} = useDeleteCampaign({
    deleteUrl,
    title: 'Delete Client',
    type: 'Client',
    name: defaultValues.companyName,
    cancelClick
  });

  const partnerOptions = partners.map(partner => ({
    label: partner.display,
    value: partner.id
  }));

  const selectedPartnerId = partnerId || defaultPartnerId;
  const defaultPartner = partnerOptions.find(p => p.value === selectedPartnerId);

  const {canViewPartner} = useGetUserPrivileges();

  const newClientSubmit = (formData={partner: {}}) => {
    if(isEdit) {
      putClient({
        data: {
          id: defaultValues.id,
          companyName: formData.name,
          display: formData.name,
          contractStartDate: defaultValues.contractStartDate,
          partnerId: defaultValues.partnerId,
          apiKey: formData.apiKey,
          apiPassword: formData.apiPassword,
          apiViewId: formData.apiViewId,
          status: 'ACTIVE'
        }
      });
      return;
    }

    const formPartnerId = _.get(formData, 'partner.value', formData.partner) || selectedPartnerId;

    postClient({
      data: {
        companyName: formData.name,
        contractStartDate: moment().format('YYYY-MM-DD'),
        partnerId: formPartnerId
      }
    });
  };

  // Display Success/Fail Delete message to user
  if(_.get(DeleteSuccessFailMessage, 'props.message.title')) {
    return DeleteSuccessFailMessage;
  }

  return (
    <form
      onSubmit={handleSubmit(newClientSubmit)}
      className={'new-client-form'}
      data-test='add-new-form-client'>

      <TitleLV hidden={!formTitle}>
        {formTitle}
      </TitleLV>

      <input
        type='hidden'
        name='partner'
        ref={register}
        value={defaultPartnerId}
      />

      <FormRowLV hidden={isEdit || !canViewPartner}>
        <SelectLV
          id='partner-select'
          control={control}
          name='partner'
          placeholder={'Select Partner'}
          options={partnerOptions}
          rules={{required: 'Select a Partner'}}
          setValue={setValue}
          errors={errors}
          isLoading={isLoading}
          defaultValue={defaultPartner}
          dataTest='partner-select'
        />
      </FormRowLV>

      <FormRowLV>
        <TextInputLV
          name='name'
          placeholder={'Client Name'}
          dataTest={'Name-add-new-form'}
          description={'250 Characters Max'}
          dirty={dirty}
          register={
            register(
              {
                required: 'Client Name is required',
                maxLength: {
                  value: 250,
                  message: 'Cannot exceed 250 characters'
                }
              }
            )}
          errors={errors}
          width={310}
          defaultValue={defaultValues.companyName}
        />
      </FormRowLV>
      <FormRowLV hidden={!isEdit}>
        <TextInputLV
          name='apiKey'
          placeholder={'API Key'}
          dataTest={'key-add-new-form'}
          dirty={dirty}
          register={
            register(
            {
              maxLength: {
                value: 250,
                message: 'Cannot exceed 250 characters'
              }
            }
          )}
          width={310}
      />
      </FormRowLV>
      <FormRowLV hidden={!isEdit}>
          <TextInputLV
            type='password'
            name='apiPassword'
            placeholder={'API Password'}
            dataTest={'password-add-new-form'}
            dirty={dirty}
            register={
              register({})
            }
            width={310}
        />
      </FormRowLV>
      
      <FormRowLV>
        <OrangeButtonLV
          submitting={isSubmitting}
          dirty={dirty}
          dataTest={'addNew-submitButton'}>
          Save
        </OrangeButtonLV>

        <ButtonLV
          onClick={cancelClick}
          submitting={isSubmitting}
          dataTest={'addNew-cancelButton'}>
          Cancel
        </ButtonLV>
      </FormRowLV>

      <FormRowLV className='delete-row' hidden={!isEdit} center={true}>
        {DeleteCampaignModal}
      </FormRowLV>

    </form>
  );
};
