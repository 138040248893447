import request from 'superagent';

/**
 * [getData given a token and a url will initiate a get which it will return in a promise]
 * @param  {string} token the keycloak token
 * @param  {string} url   the full url to hit
 * @return {promise}       promise that will resolve when the requested data is here
 */
export const getData = (token, url, customErrorHandler) => {
    return new Promise((resolve, reject) => {
        request
            .get(url)
            .type(`application/json`)
            .set({
                'Cache-Control': `no-cache`,
                Authorization: `Bearer ` + token
            })
            .then((response) => {
                resolve(response.body);
            })
            .catch((error) => {
                resolve(ajaxErrors(error, [], customErrorHandler));
            });
    });
};

/**
 * [putData given a token and a url will initiate a PUT request which it will return in a promise]
 * @param  {string} token the keycloak token
 * @param  {string} url   the full url to hit
 * @return {promise}       promise that will resolve when the requested data is here
 */
export const putData = (token, url, payload, customErrorHandler) => {
    return new Promise((resolve, reject) => {
        request
            .put(url)
            .type('application/json')
            .set({
                'Cache-Control': 'no-cache',
                Authorization: 'Bearer ' + token
            })
            .send(payload)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                resolve(ajaxErrors(error, [], customErrorHandler));
            });
    });
};

/**
 * [patch given a token and a url will initiate a PUT request which it will return in a promise]
 * @param  {string} token the keycloak token
 * @param  {string} url   the full url to hit
 * @return {promise}       promise that will resolve when the requested data is here
 */
export const patch = (token, url, payload, customErrorHandler) => {
    return new Promise((resolve, reject) => {
        request
            .patch(url)
            .type('application/json')
            .set({
                'Cache-Control': 'no-cache',
                Authorization: 'Bearer ' + token
            })
            .send(payload)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                resolve(ajaxErrors(error, [], customErrorHandler));
            });
    });
};

export const post = (token, url, payload, customErrorHandler) => {
    return new Promise((resolve, reject) => {
        request
            .post(url)
            .type('application/json')
            .set({
                'Cache-Control': 'no-cache',
                Authorization: 'Bearer ' + token
            })
            .send(payload)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                resolve(ajaxErrors(error, [], customErrorHandler));
            });
    });
};

export const delData = (token, url, customErrorHandler) => {
    return new Promise((resolve, reject) => {
        request
            .delete(url)
            .type('application/json')
            .set({
                'Cache-Control': 'no-cache',
                Authorization: 'Bearer ' + token
            })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                resolve(ajaxErrors(error, [], customErrorHandler));
            });
    });
};

export const wavecastPost = (token, url, payload, customErrorHandler) => {
    return new Promise((resolve, reject) => {
        request
            .post(url)
            .type('application/json')
            .set({
                'Cache-Control': 'no-cache',
                Authorization: 'Bearer ' + token
            })
            .send(payload)
            .then((response) => {
                resolve([{error: false, response: response}]);
            })
            .catch((err, message) => {
                let errors = err.response.body
                    ? err.response.body.errors.join(', ')
                    : err.message;
                resolve([{error: true, message: errors}]);
            });
    });
};

export function ajaxErrors(error, returnThing, customErrorHandler) {
    let response;
    if (customErrorHandler) {
        response = customErrorHandler();
    }
    if (!response) {
        switch (error.status) {
            case 418: //teapot
                response = {
                    error: true,
                    response: returnThing,
                    message: 'where did you find a teapot'
                };
                break;
            case 404: // Not Found
                response = {
                    error: true,
                    response: returnThing,
                    message: '404 not found'
                };
                break;
            case 403: // Forbidden
                // TODO retry refresh token, if that fails logout
                response = {
                    error: true,
                    response: returnThing,
                    message: '403 forbidden'
                };
                break;
            case 401: // Unauthorized
                // TODO retry refresh token, if that fails logout
                response = {
                    error: true,
                    response: returnThing,
                    message: '401 unauthorized',
                    rawError: error
                };
                break;
            case 400: // Bad Request
                response = {
                    error: true,
                    response: returnThing,
                    message: '400 bad request',
                    rawError: error
                };
                break;
            case 500: // Internal Service Error
                response = {
                    error: true,
                    response: returnThing,
                    message: '500 internal server error'
                };
                break;
            default:
                response = {
                    error: true,
                    response: returnThing,
                    message: 'Something went wrong'
                };
                break;
        }
    }
    return response;
}
