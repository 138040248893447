import {useLocation, useHistory} from 'react-router-dom';
import {useProduct} from './useProduct';
import queryString from 'query-string';

export const PRODUCT_URL_PARAM = 'product';
export const PARTNER_ID_URL_PARAM = 'partnerId';
export const CLIENT_ID_URL_PARAM = 'clientId';
export const CAMPAIGN_ID_URL_PARAM = 'campaignId';
export const MCA_RUN_ID_URL_PARAM = 'mcaRunId';
export const AD_IMPACT_ID_URL_PARAM = 'adImpactId';
export const SCORING_ID_URL_PARAM = 'scoringId';
export const CONFIGAI_ID_URL_PARAM = 'configAIId';
export const MAGNIFAI_ID_URL_PARAM = 'magnifAIId';
export const DASHBOARD_TAB_URL_PARAM = 'dTab';
export const SCENARIO_DASHBOARD_TAB_URL_PARAM = 'sTab';
export const SCORING_DASHBOARD_TAB_URL_PARAM = 'scoreTab';
export const ADIMPACT_DASHBOARD_TAB_URL_PARAM = 'adTab';
export const DATA_DASHBOARD_TAB_URL_PARAM = 'dataTab';

export const TAB_URL_KEY = {
  MMM_DASHBOARD: DASHBOARD_TAB_URL_PARAM,
  ADIMPACT_DASHBOARD: ADIMPACT_DASHBOARD_TAB_URL_PARAM,
  SCENARIO_DASHBOARD: SCENARIO_DASHBOARD_TAB_URL_PARAM,
  SCORING_DASHBOARD: SCORING_DASHBOARD_TAB_URL_PARAM,
  DATA_DASHBOARD: DATA_DASHBOARD_TAB_URL_PARAM
};

/**
 * All URL query parameter interaction
 * Handle getting/setting url query params for Partner/Client/CampaignIds/...etc
 *
 * @param urlQueryKey - Custom URL Query param to set/get
 *        EXAMPLE: https://leavened.io?scoreTab=2
 */
export const useCampaignManagement = (urlQueryKey) => {
  const location = useLocation();
  const history = useHistory();
  const defaultProduct = useProduct();

  return  {
    partnerId: getQueryParam(location, PARTNER_ID_URL_PARAM),
    clientId: getQueryParam(location, CLIENT_ID_URL_PARAM),
    campaignId: getQueryParam(location, CAMPAIGN_ID_URL_PARAM),
    mcaRunId: getQueryParam(location, MCA_RUN_ID_URL_PARAM),
    adImpactId: getQueryParam(location, AD_IMPACT_ID_URL_PARAM),
    scoringId: getQueryParam(location, SCORING_ID_URL_PARAM),
    configAIId: getQueryParam(location, CONFIGAI_ID_URL_PARAM),
    magnifAIId: getQueryParam(location, MAGNIFAI_ID_URL_PARAM),
    dashboardTabIndex: Number(getQueryParam(location, DASHBOARD_TAB_URL_PARAM)),
    scenarioPlannerTabIndex: Number(getQueryParam(location, SCENARIO_DASHBOARD_TAB_URL_PARAM)),
    scoringTabIndex: Number(getQueryParam(location, SCORING_DASHBOARD_TAB_URL_PARAM)),
    dataTabIndex: Number(getQueryParam(location, DATA_DASHBOARD_TAB_URL_PARAM)),
    adImpactTabIndex: Number(getQueryParam(location, ADIMPACT_DASHBOARD_TAB_URL_PARAM)),
    setPartnerId: setUrlQuery(location, history, PARTNER_ID_URL_PARAM, defaultProduct),
    setClientId: setUrlQuery(location, history, CLIENT_ID_URL_PARAM, defaultProduct),
    setCampaignId: setUrlQuery(location, history, CAMPAIGN_ID_URL_PARAM, defaultProduct),
    setMcaRunId: setUrlQuery(location, history, MCA_RUN_ID_URL_PARAM, defaultProduct),
    setAdImpactId: setUrlQuery(location, history, AD_IMPACT_ID_URL_PARAM, defaultProduct),
    setScoringId: setUrlQuery(location, history, SCORING_ID_URL_PARAM, defaultProduct),
    setConfigAIId: setUrlQuery(location, history, CONFIGAI_ID_URL_PARAM, defaultProduct),
    setMagnifAIId: setUrlQuery(location, history, MAGNIFAI_ID_URL_PARAM, defaultProduct),
    setDashboardTabIndex: setUrlQuery(location, history, DASHBOARD_TAB_URL_PARAM, defaultProduct),
    setScenarioPlannerTabIndex: setUrlQuery(location, history, SCENARIO_DASHBOARD_TAB_URL_PARAM, defaultProduct),
    setScoringTabIndex: setUrlQuery(location, history, SCORING_DASHBOARD_TAB_URL_PARAM, defaultProduct),
    setDataTabIndex: setUrlQuery(location, history, DATA_DASHBOARD_TAB_URL_PARAM, defaultProduct),
    setAdImpactTabIndex: setUrlQuery(location, history, ADIMPACT_DASHBOARD_TAB_URL_PARAM, defaultProduct),
    setCustomQueryParam: setUrlQuery(location, history, urlQueryKey, defaultProduct),
    customQueryParamValue: getQueryParam(location, urlQueryKey)
  };
};

/**
 * Set URL query params in the url
 * If Partner is set, clear others
 */
export const setUrlQuery = (location={}, history, urlQueryKey, defaultProduct={}) => {
  return (id) => {
    const product = getQueryParam(location, PRODUCT_URL_PARAM);
    let partnerId =  getQueryParam(location, PARTNER_ID_URL_PARAM);
    let clientId =  getQueryParam(location, CLIENT_ID_URL_PARAM);
    let campaignId = getQueryParam(location, CAMPAIGN_ID_URL_PARAM);
    let mcaRunId = getQueryParam(location, MCA_RUN_ID_URL_PARAM);
    let adImpactId = getQueryParam(location, AD_IMPACT_ID_URL_PARAM);
    let scoringId = getQueryParam(location, SCORING_ID_URL_PARAM);
    let configAIId = getQueryParam(location, CONFIGAI_ID_URL_PARAM);
    let magnifAIId = getQueryParam(location, MAGNIFAI_ID_URL_PARAM);
    let dashboardTabIndex = getQueryParam(location, DASHBOARD_TAB_URL_PARAM);
    let scenarioPlannerTabIndex = getQueryParam(location, SCENARIO_DASHBOARD_TAB_URL_PARAM);
    let scoringTabIndex = getQueryParam(location, SCORING_DASHBOARD_TAB_URL_PARAM);
    let dataTabIndex = getQueryParam(location, DATA_DASHBOARD_TAB_URL_PARAM);
    let adImpactTabIndex = getQueryParam(location, ADIMPACT_DASHBOARD_TAB_URL_PARAM);
    
    if(urlQueryKey === PARTNER_ID_URL_PARAM) {
      partnerId = id;
      clientId = '';
      campaignId = '';
      configAIId = '';
      magnifAIId = '';
    }

    if(urlQueryKey === CLIENT_ID_URL_PARAM) {
      clientId = id;
      campaignId = '';
      configAIId = '';
      magnifAIId = '';
    }

    if(urlQueryKey === CAMPAIGN_ID_URL_PARAM) {
      campaignId = id;
      configAIId = '';
      magnifAIId = '';
    }

    if(urlQueryKey === MCA_RUN_ID_URL_PARAM) {
      mcaRunId = id;
    }

    if(urlQueryKey === AD_IMPACT_ID_URL_PARAM) {
      adImpactId = id;
    }

    if(urlQueryKey === SCORING_ID_URL_PARAM) {
      scoringId = id;
    }

    if(urlQueryKey === CONFIGAI_ID_URL_PARAM) {
      configAIId = id;
    }

    if(urlQueryKey === MAGNIFAI_ID_URL_PARAM) {
      magnifAIId = id;
    }

    if(urlQueryKey === DASHBOARD_TAB_URL_PARAM) {
      dashboardTabIndex = id;
    }

    if(urlQueryKey === SCENARIO_DASHBOARD_TAB_URL_PARAM) {
      scenarioPlannerTabIndex = id;
    }

    if(urlQueryKey === SCORING_DASHBOARD_TAB_URL_PARAM) {
      scoringTabIndex = id;
    }

    if(urlQueryKey === ADIMPACT_DASHBOARD_TAB_URL_PARAM) {
      adImpactTabIndex = id;
    }

    if(urlQueryKey === DATA_DASHBOARD_TAB_URL_PARAM) {
      dataTabIndex = id;
    }

    // Map to persist other query params in the url
    const queryMap = {
      [PARTNER_ID_URL_PARAM]: partnerId,
      [CLIENT_ID_URL_PARAM]: clientId,
      [CAMPAIGN_ID_URL_PARAM]: campaignId,
      [MCA_RUN_ID_URL_PARAM]: mcaRunId,
      [AD_IMPACT_ID_URL_PARAM]: adImpactId,
      [SCORING_ID_URL_PARAM]: scoringId,
      [CONFIGAI_ID_URL_PARAM]: configAIId,
      [MAGNIFAI_ID_URL_PARAM]: magnifAIId,
      [PRODUCT_URL_PARAM]: product || defaultProduct.key,
      [DASHBOARD_TAB_URL_PARAM]: dashboardTabIndex,
      [SCENARIO_DASHBOARD_TAB_URL_PARAM]: scenarioPlannerTabIndex,
      [SCORING_DASHBOARD_TAB_URL_PARAM]: scoringTabIndex,
      [ADIMPACT_DASHBOARD_TAB_URL_PARAM]: adImpactTabIndex,
      [DATA_DASHBOARD_TAB_URL_PARAM]: dataTabIndex,
    };

    // Clear empty params
    Object.keys(queryMap).forEach(key => {
      const val = queryMap[key]
      if(!val && val !== 0) {
        delete queryMap[key];
      }
    });

    const urlParams = queryString.stringify(queryMap);
    const path = `${location.path || ''}?${urlParams}`;

    history.push(path);
  };
};

/**
 * Return a url Query param value
 */
const getQueryParam = (location={}, urlQueryKey) => {
  const query = queryString.parse(location.search);
  return query[urlQueryKey];
};
