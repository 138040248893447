import { FILE_TYPE_MAP } from '../../../constants/file-types';
import {DEFAULT_VALIDATION_OPTIONS, VALIDATION_OPTIONS_MAP} from './validationTypeOptions';

const Measures = {
  Impressions: {
    label: 'Impressions',
    value: 'Impressions',
    validation: [VALIDATION_OPTIONS_MAP.POSITIVE_NUMBER]
  },
  Spend: {
    label: 'Spend',
    value: 'Spend',
    validation: [VALIDATION_OPTIONS_MAP.POSITIVE_NUMBER]
  },
  Clicks: {
    label: 'Clicks',
    value: 'Clicks',
    validation: [VALIDATION_OPTIONS_MAP.POSITIVE_INTEGER]
  },
  Ratings: {
    label: 'Ratings',
    value: 'Ratings',
    validation: [VALIDATION_OPTIONS_MAP.POSITIVE_NUMBER]
  },
  Binary: {
    label: 'Binary',
    value: 'Binary',
    validation: [VALIDATION_OPTIONS_MAP.ONE_OR_ZERO]
  },
  Control: {
    label: 'Control',
    value: 'Control',
    validation: [VALIDATION_OPTIONS_MAP.POSITIVE_NUMBER]
  }
}

export const UPLOAD_FILE_TYPES = [
  {
    label: "Business Drivers",
    value: FILE_TYPE_MAP.MEDIA_VEHICLE,
    mappingColumns: [
      {
        inputType: "text",
        label: "Revised Name",
        name: "finalName"
      },
      {
        inputType: "text",
        label: "Group Name",
        name: "groupName",
        disabledColumnNames: [
            'Region',
            'Date'
        ]
      },
      {
        inputType: "autocomplete",
        label: "Measure",
        name: "measure",
        disabledColumnNames: [
          'Region',
          'Date'
        ],
        autocompleteProps: {
          rules: {

          },
        },
        options: Object.values(Measures).reduce((all, cur) => {
          const { label, value } = cur;
          return [ ...all, { label, value }];
        }, [])
      },
      {
        inputType: "autocomplete",
        label: "Data Type",
        name: "validation",
        autocompleteProps: {
          rules: {
        //required: 'Data Type is required'
          },
        },
        options: (row) => {
          const measureObj = Object.values(Measures).find(value => value.value === row?.measure?.value);
          return measureObj?.validation || DEFAULT_VALIDATION_OPTIONS;
        }
      },
      {
        inputType: "autocomplete",
        label: "Variable Type",
        name: "variableType",
        autocompleteProps: {
          rules: {
            
          },
        },
        disabledColumnNames: [
          'Region',
          'Date'
        ],
        options: [
          {
            label: "Media",
            value: "Media"
          },
          {
            label: "Promotion",
            value: "Promotion"
          },
          {
            label: "Distribution",
            value: "Distribution"
          },
          {
            label: "Macroeconomic",
            value: "Macroeconomic"
          },
          {
            label: "Competitive",
            value: "Competitive"
          },
          {
            label: "Seasonality",
            value: "Seasonality"
          },
          {
            label: "Other",
            value: "Other"
          },
        ]
      },
    ]
  },
  {
    label: "Dependent Variable",
    value: FILE_TYPE_MAP.KPI,
    mappingColumns: [
      {
        inputType: "text",
        label: "Revised Name",
        name: "finalName"
      },
      {
        inputType: "autocomplete",
        label: "Data Type",
        name: "validation",
        autocompleteProps: {
          rules: {
            //required: 'Data Type is required'
          },
        },
        options: DEFAULT_VALIDATION_OPTIONS
      }
    ]
  },
  {
    label: "Analysis Configuration MMM",
    value: FILE_TYPE_MAP.MEDIA_GROUPING,
    mappingColumns: [
      {
        inputType: "autocomplete",
        label: "Data Type",
        name: "validation",
        autocompleteProps: {
          rules: {
            //required: 'Data Type is required'
          },
        },
        options: (row, cell) => {
          const { field } = row;
          if (field === 'Saturation') {
            return [VALIDATION_OPTIONS_MAP.SATURATION]
          }
          if (field === 'MemoryDecay') {
            return [VALIDATION_OPTIONS_MAP.DECAY]
          }
          if (field === 'Data Grouping Required' || field === 'Analyze') {
            return [VALIDATION_OPTIONS_MAP.YES_NO]
          }
          if (field === 'Measure') {
            return [VALIDATION_OPTIONS_MAP.MEASURE]
          }

          return [VALIDATION_OPTIONS_MAP.TEXT_NO_SPECIAL]
        }
      }
    ]
  },
  {
    label: 'Linear TV Custom AdImpact',
    value: FILE_TYPE_MAP.PLACEMENT_CUSTOM,
    mappingColumns: [
      {
        inputType: "text",
        label: "Revised Name",
        name: "finalName"
      },
      {
        inputType: "autocomplete",
        label: "Data Type",
        name: "validation",
        autocompleteProps: {
          rules: {
            //required: 'Data Type is required'
          },
        },
        options: (row, cell) => {
          const foundValidation = Object.values(VALIDATION_OPTIONS_MAP).filter(val => {
            return val.value === cell?.formattedValue
          })?.[0];

          if (foundValidation) {
            return [foundValidation]
          }

          return [...DEFAULT_VALIDATION_OPTIONS, VALIDATION_OPTIONS_MAP.TIME, VALIDATION_OPTIONS_MAP.DURATION]
        }
      }
    ]
  },
  {
    label: 'Linear TV Ad Instance AdImpact',
    value: FILE_TYPE_MAP.AD_INSTANCE,
    mappingColumns: [
      {
        inputType: "text",
        label: "Revised Name",
        name: "finalName"
      },
      {
        inputType: "autocomplete",
        label: "Data Type",
        name: "validation",
        autocompleteProps: {
          rules: {
            //required: 'Data Type is required'
          },
        },
        options: (row, cell) => {
          const foundValidation = Object.values(VALIDATION_OPTIONS_MAP).filter(val => {
            return val.value === cell?.formattedValue
          })?.[0];

          if (foundValidation) {
            return [foundValidation]
          }

          return [...DEFAULT_VALIDATION_OPTIONS, VALIDATION_OPTIONS_MAP.TIME, VALIDATION_OPTIONS_MAP.DURATION]
        }
      }
    ]
  },
  {
    label: 'Digital Video AdImpact',
    value: FILE_TYPE_MAP.DIGITAL_VIDEO,
    mappingColumns: [
      {
        inputType: "text",
        label: "Revised Name",
        name: "finalName"
      },
      {
        inputType: "autocomplete",
        label: "Data Type",
        name: "validation",
        autocompleteProps: {
          rules: {
            //required: 'Data Type is required'
          },
        },
        options: DEFAULT_VALIDATION_OPTIONS
      }
    ]
  },
  {
    label: 'OTT AdImpact',
    value: FILE_TYPE_MAP.OTT,
    mappingColumns: [
      {
        inputType: "text",
        label: "Revised Name",
        name: "finalName"
      },
      {
        inputType: "autocomplete",
        label: "Data Type",
        name: "validation",
        autocompleteProps: {
          rules: {
            //required: 'Data Type is required'
          },
        },
        options: DEFAULT_VALIDATION_OPTIONS
      }
    ]
  },
  {
    label: 'Social Media AdImpact',
    value: FILE_TYPE_MAP.FACEBOOK,
    mappingColumns: [
      {
        inputType: "text",
        label: "Revised Name",
        name: "finalName"
      },
      {
        inputType: "autocomplete",
        label: "Data Type",
        name: "validation",
        autocompleteProps: {
          rules: {
            //required: 'Data Type is required'
          },
        },
        options: DEFAULT_VALIDATION_OPTIONS
      }
    ]
  },
  {
    label: 'Terrestrial Radio AdImpact',
    value: FILE_TYPE_MAP.TERRESTIAL_RADIO,
    mappingColumns: [
      {
        inputType: "text",
        label: "Revised Name",
        name: "finalName"
      },
      {
        inputType: "autocomplete",
        label: "Data Type",
        name: "validation",
        autocompleteProps: {
          rules: {
            //required: 'Data Type is required'
          },
        },
        options: DEFAULT_VALIDATION_OPTIONS
      }
    ]
  },
  {
    label: 'Digital Audio AdImpact',
    value: FILE_TYPE_MAP.DIGITAL_AUDIO,
    mappingColumns: [
      {
        inputType: "text",
        label: "Revised Name",
        name: "finalName"
      },
      {
        inputType: "autocomplete",
        label: "Data Type",
        name: "validation",
        autocompleteProps: {
          rules: {
            //required: 'Data Type is required'
          },
        },
        options: DEFAULT_VALIDATION_OPTIONS
      }
    ]
  },
];
