// actions
export const SET_COUNT = `mca/filter/set-count`;
export const SET_TOTAL = `mca/filter/set-total`;
export const SET_RANGES = `mca/filter/load-ranges`;
export const SET_FILTERS = `mca/filter/set-filters`;
export const SET_VARIABLES = `mca/filter/set-variables`;
export const MODEL_COUNT = `mca/filter/model-count`;
export const MODEL_TOTAL_COUNT = `mca/filter/model-total-count`;
export const MODEL_RANGES = `mca/filter/model-ranges`;
export const LOAD_MCA_VARIABLES = `mca/filter/load-mca-variables`;
export const LOADING_DONE = `mca/filter/loading-done`;
export const LOADING_START = `mca/filter/loading-start`;
export const RESET = `mca/filter/reset`;

export const initialState = {
    total: -1,
    count: -1,
    loading: false,
    filters: {},
    variables: [],
    ranges: []
};

// selectors
const ID = `filter`;
const local = (state) => state['mca'][ID];
export const getTotal = (state) => local(state).total;
export const getCount = (state) => local(state).count;
export const getLoading = (state) => local(state).loading;
export const getFilters = (state) => Object.assign({}, local(state).filters);
export const getMcaVariables = (state) => local(state).variables;
export const getRanges = (state) => local(state).ranges;

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_COUNT:
            return Object.assign({}, state, {count: action.count});
        case SET_TOTAL:
            return Object.assign({}, state, {total: action.total});
        case SET_RANGES:
            return Object.assign({}, state, {ranges: action.ranges});
        case SET_VARIABLES:
            return Object.assign({}, state, {variables: action.variables});
        case LOADING_DONE:
            return Object.assign({}, state, {loading: false});
        case LOADING_START:
            return Object.assign({}, state, {loading: true});
        case SET_FILTERS:
            return Object.assign({}, state, {filters: action.filters});
        case RESET:
            return Object.assign({}, initialState);
        default:
            return state;
    }
}

// action creators
export function setCount(count) {
    return {
        type: SET_COUNT,
        count
    };
}

export function setTotal(total) {
    return {
        type: SET_TOTAL,
        total
    };
}

export function setRanges(ranges) {
    return {
        type: SET_RANGES,
        ranges
    };
}

export function setFilters(filters) {
    return {
        type: SET_FILTERS,
        filters
    };
}

export function setVariables(variables) {
    return {
        type: SET_VARIABLES,
        variables
    };
}

export function loadingDone() {
    return {
        type: LOADING_DONE
    };
}

export function loadingStart() {
    return {
        type: LOADING_START
    };
}

export function resetFilters() {
    return {
        type: RESET
    };
}
