import React from 'react';
import { R1Button } from '@rainagency/rain-one-soggy-loaf';
import { Add } from '@mui/icons-material';

/**
 * Big Orange Button + Call to Action on every Tab
 * /src/assets/plus_sign.svg
 */
export default function CallToActionLV({children, onClick, isDisabled, dataTest, ...props}) {

  // ButtonLV will turn into form submit without a click handler
  // Set a blank one if one is not defined
  if(!onClick) {
    onClick = () => {};
  }

  return (
    <R1Button
      onClick={onClick}
      data-test={dataTest}
      startIcon={<Add/>}
      color='secondary'
      disabled={isDisabled}
      {...props}>

      {children}

    </R1Button>
  );
}

export {CallToActionLV}
