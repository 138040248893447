import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {styles, lightStyles, container, base, spaceFillerDiv} from './tabs.styles';

/**
 * [DataTabs takes two parameters, for labels of tabs and selected tab 
 * and returns tabs with labels and indicator]
 * @param {array} labels labels of tabs
 * @param {integer} selectedTab of selected tab
 */
export default function DataTabs({isDark=true, isBase=false, isHidden=false, ...props}) {
  const currentStyles = isDark ? styles : lightStyles;
  let parentClasses = { indicator: currentStyles.tabsIndicator };
  let childClasses = { root: currentStyles.tabRoot, selected: currentStyles.tabSelected };

  let containerClasses = [container];
  let isSpacerHidden = false;
  // Add drop shadow if these are the top tabs
  if(isBase) {
    containerClasses.push(base);
    isSpacerHidden = true;
  }

  const containerStyles = containerClasses.join(' ');

  const displayTabs = (
  <div className={containerStyles} data-test='datatabs-container'>
    <Tabs
      data-test='tabs-components'
      value={props.selectedTab} 
      classes={parentClasses} >
      {props.labels.map((label, index) =>
                        <Tab key={index}
                            disableRipple
                            classes={childClasses}
                            label={label}
                            onClick={event => props.onClick(index)}
                            data-test={label} />)
      }
    </Tabs>

    <div hidden={isSpacerHidden}
        data-test='spacer-under-tabs'
        className={currentStyles.spacer} />

  </div>);

  const hiddenTabs = (<div className={spaceFillerDiv}></div>);

  return isHidden ? hiddenTabs : displayTabs;
}

export {DataTabs};
