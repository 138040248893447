import React from 'react';
import {useForm} from 'react-hook-form';
import {ControlVariableTable} from './tables/ControlVariableTable';
import {BinaryVariableTable} from './tables/BinaryVariableTable';
import {FormRowLV} from '../../components/form/row/FormRowLV';
import {OrangeButtonLV} from '../../components/form/buttons/OrangeButtonLV';
import {GreyButtonLV} from '../../components/form/buttons/GreyButtonLV';
import {styles} from './InputScenariosForm.styles';
import ModalLV from '../../components/modal/ModalLV';
import {ScenarioWarningDialog} from './ScenarioWarningDialog';
import _ from "lodash";
import {MediaVariablesTable} from "./tables/MediaVariablesTable";

export const InputScenariosForm = ({
  data=[],
  groupMap={},
  postScenarios=()=>{},
  loading=false,
  isSubmitted=false,
  setIsSubmitted=()=>{},
  warningOpen=false,
  setWarningOpen=()=>{},
  selectedMcaDashboardRun={},
  nestedKpis={},
  dashboardRegion
}) => {

  // Toggle to redraw children on clear
  const [isClearToggle, setIsClearToggle] = React.useState(false);
  const [warningData, setWarningData] = React.useState({});
  const {register, handleSubmit, errors, reset} = useForm();

  const resetAll = () => {
    setIsClearToggle(!isClearToggle);
    postScenarios({});
    reset();
  };

  // If Selected Run changes, reset form
  React.useEffect(() => {
    if(!selectedMcaDashboardRun.id) {
      return;
    }
    
    reset();
    setIsClearToggle(isClearToggle);
  }, [selectedMcaDashboardRun.id, isSubmitted, reset, setIsClearToggle, isClearToggle]);

  if(isSubmitted) {
    return null;
  }

  const grouped = data.reduce((unique, o) => {
    if(!unique.some(obj => obj.tactic === o.tactic)) {
      unique.push(o);
    }
    return unique;
  },[]);

  const validateData = (formData) => {
    let warning = true;
    Object.keys(formData).forEach(key => {
      let vehicleValue = parseFloat(formData[key]);
      const vehicleName = key.split('_')[0];
      if(formData[vehicleName + '_price']){
        vehicleValue = vehicleValue / formData[vehicleName + '_price'];
      }
      const getTableRow = grouped.find(row =>
        (row.Vehicle === vehicleName)) || {};
      const maxValue = parseFloat(getTableRow.maxObserved);
      if(maxValue && vehicleValue > maxValue){
        warning = warning & false;
      }
    });
    return warning;
  };

  const unpackGroups = (formData) => {
    Object.keys(formData).forEach(key => {
      if(key.endsWith('_scenario1')) {
        const standardKey = key.split('_scenario1')[0];
        const volume = formData[key];
        const variables = groupMap[standardKey] || [];
        delete formData[key];
        variables.forEach(variable => {
          const varName = variable.variableName;
          const measure = variable.measure;
          const scenarioVar = varName + '_scenario1';
          if(measure === 'Impressions'){
            formData[scenarioVar] = volume * 1000;
          }
          else{
            formData[scenarioVar] = volume;
          }
        });
      }
      if(key.endsWith('_scenario2')) {
        const standardKey = key.split('_scenario2')[0];
        const volume = formData[key];
        const variables = groupMap[standardKey] || [];
        delete formData[key];
        variables.forEach(variable => {
          const varName = variable.variableName;
          const measure = variable.measure;
          const scenarioVar = varName + '_scenario2';
          if(measure === 'Impressions'){
            formData[scenarioVar] = volume * 1000;
          }
          else{
            formData[scenarioVar] = volume;
          }
        });
      }
      if(key.endsWith('_price')) {
        const standardKey = key.split('_price')[0];
        const price = formData[key];
        const variables = groupMap[standardKey].filter(v => v.measure !== 'Spend').map(c => c.variableName) || [];
        delete formData[key];
        variables.forEach(varName => {
          const scenarioVar = varName + '_price';
          formData[scenarioVar] = price;
        });
      }
    });
    return formData;
  };

  const cleanData = (formData) => {
    // Any Impressions measure fields (_impressions_) need to be multiplied by 1000 BEFORE post then divided by 1000 AFTER post
    // Other Unit Volume and Cost Per are no longer multiplied before the backend calculations
    Object.keys(formData).forEach(key => {
      if(key.includes('_volume_')) {
        const standardKey = key.replace('_volume_', '_');
        const volume = formData[key];
        formData[standardKey] = isNaN(volume) ? 0 : volume;
        delete formData[key];
      }
      // Remove odd situation of blank variables seen only on the first submit
      if(key === '_scenario1' || key === '_scenario2'){
        delete formData[key];
      }
    });
    return formData;
  };

  const formSubmit = (formData={}) => {
    setWarningOpen(false);
    formData = unpackGroups(cleanData(formData));
    if(!validateData(formData)){
      setWarningOpen(true);
      setWarningData(formData);
      return;
    }

    setIsSubmitted(true);
    postScenarios(formData);
  };

  return (
  	<div className={styles.content}>
      <form
        onSubmit={handleSubmit(formSubmit)}>

        <FormRowLV expand={true}>
          <MediaVariablesTable
            data={data}
            nestedKpis={nestedKpis}
            loading={loading}
            errors={errors}
            register={register}
            timeScale={selectedMcaDashboardRun.timeScale}
            selectedRegion={dashboardRegion}
          />
        </FormRowLV>

        <FormRowLV flexChildren={[1, 2]}>
          <BinaryVariableTable
            data={data}
            loading={loading}
            errors={errors}
            register={register}
            isClearToggle={isClearToggle}
            interceptIncluded={selectedMcaDashboardRun.includeIntercept}
          />
          <ControlVariableTable
            data={data}
            loading={loading}
            errors={errors}
            register={register}
            selectedRegion={dashboardRegion}
          />
        </FormRowLV>

        <FormRowLV className='button-row'>
          <GreyButtonLV
            onClick={resetAll}
            dataTest='scenario-button-clear-scenarios'>
            Clear Scenarios
          </GreyButtonLV>

          <OrangeButtonLV
            dataTest='scenario-button-calculate-scenarios'>
            Calculate
          </OrangeButtonLV>
        </FormRowLV>

      </form>
      <ModalLV isOpen={warningOpen} minHeight={400} minWidth={400}>
        <div/>
        <ScenarioWarningDialog
          setIsSubmitted={setIsSubmitted}
          setWarningOpen={setWarningOpen}
          postScenarios={postScenarios}
          data={warningData}
        />
      </ModalLV>
    </div>
  );
};