
import React from 'react';
import {roundString} from '../../js/utils';
import {HeaderSummary} from './HeaderSummary';

export const ContributionHeaderSummary = ({
    variableType,
    totalContribution,
    totalSpend = 0,
    impressions = {},
    costPer = {}
}) => {
    
    const spends = totalSpend + Object.keys(costPer).reduce((total, key) => total + (costPer[key] * impressions[key]), 0);
    let totals = [
        { label: 'Total Spend', value: roundString(spends, 0), format:'currency' }
    ];

    if (variableType === 'Revenue') {
        const totalRoi = totalContribution / (spends || 1);
        totals.push({ label: 'Total Revenue', value: roundString(totalContribution, 0), format:'currency' });
        totals.push({ label: 'ROI', value: roundString(totalRoi, 2), format:'unit' });
    } else {
        const totalCpa = spends / (totalContribution || 1);
        totals.push({ label: 'Total KPI', value: roundString(totalContribution, 0), format:'unit' });
        totals.push({ label: 'CPA', value: roundString(totalCpa, 2), format:'currency' });
    };

    return (<HeaderSummary items={totals}/>);
};
