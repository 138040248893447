import React from 'react';
import { Box } from '@mui/material';
import { R1Button } from '@rainagency/rain-one-soggy-loaf';
import { Config } from '../../../../config/ConfigFactory';
import { useUserRoleFromGroup } from '../../../../hooks/useUserRoleFromGroup';
import { numberWithCommas } from '../../../../js/utils';
import { SubmitContinue } from './submit-continue';

import { styles } from './configure-analysis.styles';

export const ConfigControl = ({
    loading, disabled, readOnly, analysisValues, configValues,
    handleStepBack=()=>{}, setExpectedCount=()=>{}
  }) => {
  const { isTrial } = useUserRoleFromGroup();

  const modelLimit = React.useMemo(() => {
    if (Config && Config.getConfig) {
        if (isTrial) {
            return Config.getConfig().MAX_TRIAL_RUN_MODELS;
        }
        return Config.getConfig().MAX_RUN_MODELS;
    }
  }, [isTrial]);

  const variablesCount = configValues?.VariableName?.length;
  const expectedCountUnFormatted = expectedModelCount(configValues, variablesCount);
  const expectedCount = numberWithCommas(expectedCountUnFormatted);
  const modelsExceeded = expectedCountUnFormatted > modelLimit;
  const runDisabled = disabled || expectedCountUnFormatted < 1 || modelsExceeded;

  let modelsCount = modelsExceeded ? `Models cannot exceed ${numberWithCommas(modelLimit)}. Current model count: ${expectedCount}` : '';
  modelsCount =  runDisabled ? modelsCount : `${expectedCount} models will be executed`;
  setExpectedCount(expectedCount);

  const allDecays = configValues.MemoryDecay?.flat();
  const allSaturations = configValues.Saturation?.flat();
  const isConfigAIRun = allDecays?.includes('?') || allSaturations?.includes('?');

  return (
    <div className={styles.controlBar}>
      <div id='modelCount' className={styles.modelsCount}>{modelsCount}</div>
      <Box
        sx={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            gap: '24px',
            height: '50px',
            marginRight: '100px',
            marginLeft: 'auto',
        }}>
          <R1Button 
            color='secondary' 
            variant='outlined' 
            size='large' 
            sx={{ borderColor: 'white', color: 'white' }} 
            onClick={handleStepBack}>
            Back
          </R1Button>
          <SubmitContinue 
            isLastStep={ true }
            analysisType= { isConfigAIRun && 'ConfigAI' }
            analysisValues= { {...analysisValues, expectedCount} }
            configValues={ configValues }
            disabled={ runDisabled }
            skipSubmit={ readOnly }
            loading={ loading } />
      </Box>
    </div>
  );
}

/* Calculating Expected number of models */
const  expectedModelCount = (values={}, variablesCount=0) => {
  //Verify all fields are filled
  let variableGroup, measure, analyze, memoryDecay, saturation, required;
  variableGroup = values['VariableGroup'];
  measure = values['Measure'];
  analyze = values['Analyze'];
  memoryDecay = values['MemoryDecay'];
  saturation = values['Saturation'];
  required = values['Data Grouping Required'];

  if (variablesCount < 1 ||
    variableGroup.length < variablesCount || 
    measure.length < variablesCount ||
    analyze.length < variablesCount ||
    memoryDecay.length < variablesCount ||
    saturation.length < variablesCount ||
    required.length < variablesCount) {
      return 0;
  }

  let groups = new Map();
  let anyRequired = false;
  for (let index = 0; index < variablesCount; index++) {
    if (values.Analyze[index] === 'Yes') {
      const item = {
        'MemoryDecay': memoryDecay[index],
        'Saturation': saturation[index],
        'VariableGroup': variableGroup[index],
        'Data Grouping Required': required[index]
      };
      const currentValue = groups.get(item['VariableGroup']) || {count: 0, required: 0};
      const isRequired = item['Data Grouping Required'] === 'Yes' ? 0 : 1;
      const memoryDecayCount = item['MemoryDecay'] === '?' ? 3 : (item['MemoryDecay']?.length || 1);
      const saturationCount = item['Saturation'] === '?' ? 3 : (item['Saturation']?.length || 1);
      const groupCount = currentValue.count + memoryDecayCount * saturationCount;
      anyRequired = anyRequired || isRequired === 0;
      groups.set(item['VariableGroup'], {count: groupCount, required: currentValue.required || isRequired});
    }
  };

  let modelsCount = 1;
  for (let value of groups.values()) {
    modelsCount *= value.count + value.required;
  }

  if (!anyRequired) {
    modelsCount--;
  }
  return modelsCount;
}
