import React from 'react';
import {getStyles} from '../text/TextInputLV.styles';
import PropTypes from 'prop-types';
import {FieldErrorLV} from './FieldErrorLV';
import {DescriptionLV} from './DescriptionLV';
import {FormUtilLV} from '../util/FormUtilLV';

/**
 * Form text input field for Leavened
 * Uses react-hook-form for text field validation
 */  
export const FormElementWrapper = ({
  children, name, label, placeholder, dirty, className,
  register, errors, description, hideBorder, dataTest, ...props}) => {

    let width = props.width || 315;
    // Clear width if explicitly set to 0
    if(props.width === 0) {
      width = undefined;
    }

    const styles = getStyles(props);
    const containerClass = [styles.container, className].join(' ');
    return (
      <div
        className={containerClass}
        style={FormUtilLV.addWidth(width)}
        data-test={props.dataTest}>

        <label
          data-test='form-element-label'
          display={label}
          className={styles.label}>
          {label}
        </label>

        {children}

        <DescriptionLV
          data-test='form-description'
          dataTest={dataTest}
          {...props}>

          {description}
        </DescriptionLV>

        <FieldErrorLV
          data-test='form-field-errors'
          name={name}
          dirty={dirty}
          errors={errors} />

      </div>
    );
};

FormElementWrapper.propTypes = {
  /**
   * Field name to be reflected in form object
   */
  name: PropTypes.string,
  /**
   * Label to display for the text input element
   */
  label: PropTypes.string,
  /**
   * Placeholder text
   */
  placeholder: PropTypes.string,
  /**
   * Description that is displayed underneath input
   */
  description: PropTypes.string,
  /**
   * Registration to form, and validation of form element
   * https://react-hook-form.com/api#register
   */
  register: PropTypes.func,
  /**
   * All form errors from parent form
   * https://react-hook-form.com/api#errors
   */
  errors: PropTypes.object,
  /**
   * If parent form has been touched
   */
  dirty: PropTypes.bool,
  /**
   * Override standard width of element
   */
  width: PropTypes.number
}; 
