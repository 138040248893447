import React from 'react';
import {ButtonLV} from './ButtonLV';
import {styles} from './button.styles';

export const GreyButtonLV = ({className='', ...props}) => {
  const classNames = [className, styles.grey].join(' ');
  return (
    <ButtonLV
      className={classNames}
      {...props} />
  );
};
