import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import Select from '@mui/material/Select';
import {CheckboxLV} from '../../../components/form/CheckboxLV';

import {compareArrays} from '../../../js/utils';

import {styles} from './SelectFieldLV.styles';

const selectStyle = theme => ({
  select: {
      '&:before': {
          border: 0,
      },
      '&:after': {
          border: 0,
      }
  },
  icon: {
      fill: '#ffffff',
  },
});

const NoStr = 'No';
const questionStr = '?';

/**
 * Form multiple select menu with 'No' option
 */
class MultipleSelectLV extends React.Component {
  constructor(props) {
    super(props);
    this.state = {selection: this.props.value || []};
    this.handleChecked = this.handleChecked.bind(this);
  }

  componentDidUpdate(prevProps) {
    // update upon configAI completion
    if (!compareArrays(this.props.value, prevProps.value)) {
      this.setState({selection: this.props.value})
    }
  }

  handleChecked(event) {
    let selection = this.state.selection.slice();
    const checkbox = event.target.name;
    if (!event.target.checked) {
      selection = selection.filter((item) => item !== checkbox);
      if (checkbox === NoStr || checkbox === questionStr) {
        selection.push(this.props.items[1]);
      }
    } else {
      if (checkbox === NoStr) {
        selection = [NoStr];
      }
      else if(checkbox === questionStr){
        selection = [questionStr];
      }
      else{
        selection = selection.filter((item) => (item !== NoStr && item !== questionStr));
        selection.push(checkbox);
      }
    }
    if (!selection.length && !this.props.allowNoSelection) {
      selection.push(NoStr);
    }
    selection.sort();
    this.setState({selection});
  }

  render() {
    const {name, items, onChange=()=>{}, classes, className, disabled, isSuggested} = this.props;
    const menuItems = items.map((item, index) =>
      <div className={[styles.checkboxItem, `select-LV-${item}`].join(' ')} key={index}>
         <CheckboxLV 
          name={item}
          label={item}
          darkTheme={false}
          checked={this.state.selection.includes(item)}
          onChange={this.handleChecked}
          />
      </div>);

    const all = items.filter(item => (item !== NoStr && item !== questionStr));
    const strValue =  this.state.selection.toString().replace(/,/g, '/');
    const value = compareArrays(this.state.selection, all) ? 'All' : strValue;
    const parentClassName = [classes.select, className].join(' ');
    return (
      <Select
        name={name}
        multiple={true} 
        displayEmpty={true}
        value={this.state.selection || this.props.placeholder} 
        renderValue={val => val.length ? value : <div className={styles.placeholder}>{this.props.placeholder}</div> || []}
        onClose={() => onChange({target:{name, value}})}
        disabled={disabled}
        className={`${parentClassName} ${styles.selectMain}`}
        variant='standard'
        inputProps={{
            classes: {
                select: (isSuggested ? [styles.selectField, 'suggested'].join(' ') : styles.selectField),
                icon: classes.icon,
            },
        }}
      >
        {menuItems}
      </Select>
    );
  }
}

MultipleSelectLV.propTypes = {
  /**
   * Field name which will be key for form errors
   */
  name: PropTypes.string.isRequired,
  /**
   * The initial selected value
   */
  value: PropTypes.array,
  /** 
   * Array of menu items
   */
  items: PropTypes.array,
  /** 
   * Function for onChange event
   */
  onChange: PropTypes.func
};

export default withStyles(selectStyle)(MultipleSelectLV);
