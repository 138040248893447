import React from 'react';
import {createChatBotMessage} from "react-chatbot-kit";

const YeastyContext = React.createContext();

const FIRST_MESSAGE = createChatBotMessage('Hello! I am Doughy, your friendly chatbot. How can I help you today?');

function YeastyProvider({children}) {

    const [messages, setMessages] = React.useState([FIRST_MESSAGE]);

    const saveMessages = (messages = []) => {
        setMessages(messages);
    }

    return (
        <YeastyContext.Provider
            value={{
                messageHistory: messages,
                saveMessages
            }}>

            {children}

        </YeastyContext.Provider>
    );

}

export {YeastyProvider, YeastyContext};

