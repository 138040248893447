import {css} from 'emotion';

export const styles = {
  container: css `
    padding-bottom: 12px;
  `,
  topRow: css `
    display: flex;
  `,
  icons: css `
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    height: 36px;
    margin: 30px 28px 0 0;

   &>div {
     margin-right: 14px;
   }
  `,
  breadCrumbRow: css `
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
    height: 36px;
    margin: 34px 0 0 14px;

   &>div {
     margin-right: 14px;
   }
  `,
  breadCrumb: css `
   margin-top: 2px;
  `,
  hidden: css `
   display: none;
  `,
  usernameContainer: css `
   display: flex; 
   height: 228px; 
   padding-top: 70px;
   padding-left: 60px;
  `,
  profileChip: css `
   cursor: default;
   min-width: 34px !important;
  `,
  username: css `
   display: inline-block;
   line-height: 42px;
   padding-left: 24px;
   max-width: 172px;
   word-wrap: break-word;
  `,
  arrow: css `
   cursor: pointer; 
   padding-top: 10px; 
   padding-left: 24px;
   padding-right: 20px;
  `,
  listItem: css `
   height: 48px !important;
   font-size: 14px !important;
   color: #333333 !important;
   margin-left: 61px !important;
   border-bottom: 1px solid #E0E0E0 !important;
  `,
  divider: css `
    margin-left: 61px !important;
  `
};
