import {css} from 'emotion';

export const styles = {
  cta: css`
    height: 36px;
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);
    min-width: 100px;
    padding: 0 12px;
  `,
  content: css`
    display: flex;
    align-self: center;
  `,
  plus: css`
    width: 10px;
    margin-right: 8px;
  `
};
