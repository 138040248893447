import {css} from 'emotion';
import {commonStyles} from '../../styles/commonStyles';

export const styles = {
  table: css`
    width: 100%;
    position: relative;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: #333333;

    .separator {
      border: 0 !important;
      padding: 0;
      margin: 0;
      background: none !important;
      width: 24px;

      .header-cell-container {
        display: none;
      }
    }

    .editable {
      padding: 0 20px 0 0;
      margin: 0;

      .editable-cell {
        min-width: 120px;
      }

      :last-child {
        padding: 0;
      }
    }

    .group-top {
      border: 0;
      font-weight: normal;
      font-size: 12px;
      color: #828282;
      letter-spacing: 0.4px;
      white-space: nowrap;
      overflow: hidden;
    }

    .group-base {
      border-bottom: 1px solid #BDBDBD;
    }

    background: transparent;

    &.no-data, &.loading {
      thead {
        opacity: 0.5;
      }
      tbody {
        opacity: 0.5;
      }
    }

    &.loading::after {
      content: '';
      position: absolute;
      top: 42%;
      left: 46%;
      display: flex;
      justify-content: center;
      align-items: center;
      ${commonStyles.loadingSpinner}
    }

    &.no-data::after {
      content: 'No Data';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      ${commonStyles.fontFamily}
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 42px;
      color: #828282;
    }

    .LV__placeholder {
      opacity: 0.6;
    }
  `,
  tableHeaderCell: css`
    vertical-align: bottom;
    padding-bottom: 10px;
    border-bottom: 1px solid #BDBDBD;
    margin: 0;
    padding: 0 8px 10px 0;
    text-align: left;

    font-weight: 400;
    font-size: 14px;
    color: #333;

    &.center {
      padding: 0 0 10px 0;

      .header-cell-container {
        justify-content: center;
        padding: 0;

        .header-cell-text {
          text-align: center;
        }
      }
    }

    &.left {
      .header-cell-container {
        justify-content: flex-start;

        .header-cell-text {
          text-align: left;
        }
      }
    }

    &.right {
      padding: 0 0 10px 8px;

      .header-cell-container {
        justify-content: flex-end;
      }

      .header-cell-text {
        text-align: right;
        padding-right: 8px;
      }
    }

    .header-cell-container {
      min-height: 20px;
      display: flex;
      padding: 0;

      .column-sort {
        align-self: center
      }
    }

    &.hidden {
      display: none;
    }

    .column-sort {
      display: none;
    }

    &.sort {
      &:hover {
        color: #FF6B57;
        path {
          fill: #FF6B57;
          fill-opacity: 1;
        }
      }

      .column-sort {
        display: inline-flex;
        vertical-align: bottom;
        &:hover {
          color: #FF6B57;
          path {
            fill: #FF6B57;
            fill-opacity: 1;
          }
        }
      }

      &.is-sorted {
        color: #FF6B57;
        path {
          fill: #FF6B57;
          fill-opacity: 1;
        }      
      }
    }
  `,
  tableRow: css `
    &.hidden {
      display: none;
    }
  `,
  tableCell: css`
    color: #828282;
    font-weight: 400;
    border-bottom: 1px solid #F2F2F2;
    padding: 0 8px 0 0;
    margin: 0;
    height: 36px;
    letter-spacing: 0.25px;
    white-space: nowrap;

    &.left-cell {
      text-align: left;
    }

    &.center-cell {
      text-align: center;
      padding: 0;
    }

    &.right-cell {
      text-align: right;
    }

  `,
  center: css`
    .header-cell-container {
      justify-content: center;
    }
  `,
  sortImg: css`
    vertical-align: bottom;
  `
};
