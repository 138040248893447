import React from 'react';
import {useGet, usePost} from './useRest';

/**
 * @augments useGetPolling
 */
const usePolling = ({
  url,
  interval = 10000,
  onPoll,
  onPollingStopped = () => {},
  restHook,
  postData = null
}) => {

  const [timeoutInstance, setTimeoutInstance] = React.useState();
  const [pollCount, setPollCount] = React.useState(0);

  const restHookType = postData ? restHook({url, data: postData, manual: true}) : restHook({url, manual: true});

  const [{
    data, loading, error, response
  }, refetch = () => {}] = restHookType;

  React.useEffect(() => {
    if(!url) {
      return () => {};
    }

    // Set a new interval
    const instance = setInterval(
      () => {
        refetch();
        setPollCount(prevCount => prevCount + 1);
      },
      interval
    );

    setTimeoutInstance(instance);

    // On Unmount, clear interval to prevent stray polling calls
    return () => {
      clearInterval(instance);
    };

  }, [url]);

  const clearIntervalInstance = React.useCallback(
    () => {
      clearInterval(timeoutInstance);
      onPollingStopped();
    },
    [url, timeoutInstance]
  );

  // Run onPoll function and stop polling if it returns true
  React.useEffect(() => {
    if(typeof onPoll !== 'function') {
      return;
    }

    if(!data) {
      return;
    }

    if(!onPoll(data)) {
      return;
    }

    clearIntervalInstance();

  }, [data]);

  return {
    data,
    loading,
    error,
    response,
    refetch,
    timeoutInstance,
    clearIntervalInstance,
    pollCount
  };
};

/**
 * Continuosly poll a url
 *
 * @param url Url to continuously poll
 *            Populating url will trigger polling to begin
 * @param interval Time in milliseconds to call endpoint
 * @param onPoll Function runs on each poll response
 *               return false to continue polling
 *               return true to stop polling
 *               EXAMPLE: (pollingResponse) => { return pollingResponse.status === 'Success'; }
 * @param onPollingStopped Function runs when polling stops
 *
 * @returns {Object} status Status of the current polling operation
 * @returns status.data payload returned from endpoint
 * @returns status.loading Loading status
 * @returns status.error Error message of endpoint
 * @returns status.response Full response from endpoint
 * @returns status.refetch Function to recall endpoint
 * @returns status.timeoutInstance Memory instance of running timeout
 * @returns status.clearIntervalInstance Clears polling instance
 * @returns status.pollCount Number of times endpoint has been called
 */
export const useGetPolling = ({
  url,
  interval,
  onPoll,
  onPollingStopped
}) => {
  return usePolling({
    url,
    interval,
    restHook: useGet,
    onPoll,
    onPollingStopped
  });
};

export const usePostPolling = ({
  url,
  interval,
  onPoll,
  onPollingStopped,
  postData
}) => {
  return usePolling({
    url,
    interval,
    restHook: usePost,
    onPoll,
    onPollingStopped,
    postData
  });
};
