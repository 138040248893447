import {css} from 'emotion';

export const styles = {
  buttonContainer: css`
        display: flex;
        align-items: center;
        justify-content: center;
    `,
  approveButton: css`
        margin: 50px 24px 10px 0px;
    `,
  cancelButton: css`
        margin: 50px 24px 10px 0px;
    `,
  title: css`
        text-align: center !important;
        margin-top: 25px !important;
        font-size: 35px;
    `,
  body: css`
        margin-left: 50px;
        margin-right: 50px;
        text-align: center !important;
    `
};