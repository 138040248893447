import React from 'react';
import {styles} from './Chiclet.styles';

/**
 * Title Text for chiclet container
 */
export const ChicletTitle = ({children}) => {
  return (
    <div className={styles.title} data-test='chiclet-display-title'>
      {children}
    </div>
  );
};
