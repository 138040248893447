import { FEATURES } from '../constants/features';
import { useLocation, useHistory } from 'react-router-dom';
import { useCampaign } from '../hooks/useCampaign';
import { useClient } from '../hooks/useClient';

export const useBreadCrumbs = (
  isLoading = false
) => {
  const history = useHistory();
  const location = useLocation() || {};
  const campaign = useCampaign() || {};
  const client = useClient() || {};
  let breadcrumbs = [];

  if(client.display) {
    breadcrumbs.push(
      {
        label: client.display,
      },
    );
  }

  if(campaign.display) {
    breadcrumbs.push(
      {
        label: campaign.display,
      },
    );
    return breadcrumbs;
  }

  if(!breadcrumbs.length && isLoading) {
    return undefined;
  }

  // Don't display "Select a Campaign" on these features
  const excludedFeatures = [ FEATURES.MANAGE_CAMPAIGNS ];
  const isHideCampaign = excludedFeatures.some(f => (
    f.path === location.pathname
  ));

  if(isHideCampaign) {
    return [];
  }

  // Default
  return [
    {
      label: 'Select a Campaign',
      color: '#F44336',
      onClick: () => {
        history.push(
          `${FEATURES.MANAGE_CAMPAIGNS.path}${location.search}`
        );
      }
    },
  ];
  
};
