import React from 'react';
import Button from '@mui/material/Button';
import {FormElementWrapper} from './wrapper/FormElementWrapper';

import csvWhite from '../../assets/csv-white.svg';
import roundWarning from '../../assets/round-warning-24px.svg';

import {fieldStyles} from './form.styles.js';
    
export const FileInputLV = props => {
    const label = props.filename ? ( 
        <div className={fieldStyles.CustomfileInput}>
          <label className={fieldStyles.labels}>
            <img src={csvWhite} alt='csv icon'/>
            <div className={fieldStyles.fileLabel}>
              {props.filename}
            </div>
          </label>
        </div>) : (
        <div className={fieldStyles.CustomfileInput}>
            <label className={fieldStyles.labelsDark} htmlFor='selectFile'> <img src={csvWhite} alt='csv icon'/><div className={fieldStyles.noFileLabel}>No File Selected</div></label>
            <Button className={fieldStyles.fileButton} name='selectFile' variant="contained">SELECT FILE</Button>     
        </div>);
    const warningMsg = props.warningText && props.filename ? (
        <div className={fieldStyles.warningMsgContainer}><img src={roundWarning} alt='round warning'/> 
            <span className={fieldStyles.warningMsg}>{props.warningText}</span></div>) : null;
    return (
        <FormElementWrapper {...props}>
            <div className={fieldStyles.fileInputContainer}>
                <input 
                    className={fieldStyles.fileInput}
                    name={props.name}
                    onChange={props.onChange}
                    type="file"
                    disabled={props.disabled}
                    data-test='uploadFile'
                />
               {label}
            </div>
            <div>{warningMsg}</div>
        </FormElementWrapper>);
}
