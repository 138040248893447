import React from 'react';
import {Chiclet} from '../../../components/dashboards/Chiclet';
import {TableLV, parseOptionalTableData} from '../../../components/data-table/TableLV';
import {EllipsisCell} from '../../../components/data-table/cells/EllipsisCell';
import {IntervalRadioSelect} from '../../../components/date/IntervalRadioSelect';
import {useDateAndTotalsRollup} from '../../../hooks/useDateAndTotalsRollup';
import {DATE_SPAN} from '../../../constants/dates';
import {styles} from './ScoredDataIntervalsChiclet.styles';

export const ScoredDataIntervalsChiclet = ({
  data, isCombined, isLoading, csvData
}) => {
  const combinedPrefix = isCombined ? 'combined-': '';
  const combinedTitle = isCombined ? 'Combined ' : '';

  const [dateSpan, setDateSpan] = React.useState(DATE_SPAN.QUARTERLY);
  const rollup = useDateAndTotalsRollup({
    data,
    dateSpan
  });

  return (
    <Chiclet
      id='data-intervals-chiclet'
      imageFileName={`${combinedPrefix}scoring-data-intervals.png`}
      csvFileName={`${combinedPrefix}scoring-data-intervals.csv`}
      data={rollup.downloadData}
      className={styles.content}>

      <div className='interval-table-container'>

        <IntervalRadioSelect
          dateSpan={dateSpan}
          onChecked={setDateSpan}
          isDisabled={isLoading}
          title={`${combinedTitle}Data Intervals`}
        />

        <IntervalTable
          data={rollup.data}
          csvData={csvData}
          groups={rollup.groups}
          override={rollup.override}
          isLoading={isLoading}
        />

      </div>

    </Chiclet>
  );
};

const IntervalTable = ({
  data,
  override={},
  groups,
  isLoading
}) => {
  const hasData = Array.isArray(data) && data.length > 1;
  let tableData = parseOptionalTableData(data, dummyIntervalData);
  tableData = tableData.filter(row => row.label !== 'region');
  let displayGroups = groups;
  let displayOverride = override;

  if(!hasData) {
    displayOverride = dummyOverride;
    displayGroups = dummyGroups;
  }

  const tableOverride = {
    label: {
      name: 'Variables',
      percentWidth: 25,
      className: 'label-row',
      headerClassName: 'label-row',
      component: <EllipsisCell/>
    },
    ...displayOverride
  };

  return (
    <div className='interval-rollup-table-container'>
      <TableLV
        data={tableData}
        override={tableOverride}
        className='interval-table'
        hasData={hasData}
        isLoading={isLoading}
        disableTableSort={true}
        groups={displayGroups}
        dataTest='interval-rollup-table'
      />
    </div>
  );
};

const dummyGroups = [
  {
    name: '2019',
    columns: ['q42019'],
    align: 'right'
  }, {
    name: '2020',
    columns: ['q12020'],
    align: 'right'
  }, {
    name: '2020',
    columns: ['q22020'],
    align: 'right'
  }, {
    name: '2020',
    columns: ['q32020'],
    align: 'right'
  }
];

const dummyOverride = {
  q42019: {
    name: 'Quarter 4',
    align: 'right'
  },
  q12020: {
    name: 'Quarter 1',
    align: 'right'
  },
  q22020: {
    name: 'Quarter 2',
    align: 'right'
  },
  q32020: {
    name: 'Quarter 3',
    align: 'right'
  }
};

const dummyIntervalData = [
  {
    label: 'Base', q42019: '', q12020: '', q22020: '', q32020: ''
  },
  {
    label: 'TV Lower Teir', q42019: '', q12020: '', q22020: '', q32020: ''
  },
  {
    label: 'TV Mid Teir', q42019: '', q12020: '', q22020: '', q32020: ''
  },
  {
    label: 'TV Upper Teir', q42019: '', q12020: '', q22020: '', q32020: ''
  },
  {
    label: 'Grand Total', q42019: '', q12020: '', q22020: '', q32020: ''
  }
];
