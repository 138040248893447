import React from 'react';
import {getStyles} from '../text/TextInputLV.styles';
import PropTypes from 'prop-types';

export const DescriptionLV = ({children, ...props}) => {
  const styles = getStyles(props);
  return (
    <div
      id='field-description'
      data-test='field-description'
      data-test-e2e={props.dataTest + "-description"}
      display={children}
      className={styles.description}>
      {children}
    </div>);
};

DescriptionLV.propTypes = {
  /**
   * Description that is displayed underneath input field
   */
  description: PropTypes.string,
};
