import React from 'react';
import {useGet} from '../../hooks/useRest';
import {urlBuilders} from '../../js/constants';
import _ from 'lodash';

export const useUsersList = () => {
  const [{
    data: users=[],
    loading: isUsersLoading}, refetchUsers] = useGet({
      url: urlBuilders.getUsers()
    });

  const [{
    data: partners=[],
    loading: isPartnersLoading}, refetchPartners] = useGet({
    url: urlBuilders.getAllPartners()
  });

  const [{
    data: clients=[],
    loading: isClientsLoading}, refetchClients] = useGet({
      url: urlBuilders.getAllClients()
  });

  const partnerMap = React.useMemo(()=>mapPartnerList(partners), [partners]);
  const clientMap = React.useMemo(()=>mapClientList(clients), [clients]);
  const userList = React.useMemo(()=>addPartnersToUsers(users, partnerMap, clientMap), [users, partnerMap, clientMap]);

  const refetchAll = React.useMemo(() => {
    return () => {
      refetchUsers();
      refetchPartners();
      refetchClients();
    };
  }, [refetchUsers, refetchPartners, refetchClients]);

  return {
    data: userList,
    refetchUsers: refetchAll,
    loading: [isUsersLoading, isPartnersLoading, isClientsLoading].some(l => l)
  };
};

const addPartnersToUsers = (users=[], partnerMap={}, clientMap={}) => {
  return users.map((user) => {
    const partnerId = _.get(user, 'partnerIds[0]');
    const clientId = _.get(user, 'clientIds[0]');
    const partner = partnerMap[partnerId] || {};
    const client = clientMap[clientId] || {};
    return {
      ...user,
      partner: partner.name,
      client: client.name
    };
  });
};

const mapPartnerList = (partners=[]) => {
  const partnerMap = {};
  partners.forEach(({id, name}) => {
    partnerMap[id] = {id, name};
  });
  return partnerMap;
};

const mapClientList = (clients=[]) => {
  const clientMap = {};
  clients.forEach(({id, companyName}) => {
    clientMap[id] = {id, name: companyName};
  });
  return clientMap;
};
