import React from 'react';
import {OptionBar} from './options/OptionBar';
import {OptionWaterfall} from './options/OptionWaterfall';
import {OptionStackedBar} from './options/OptionStackedBar';
import {OptionPie} from './options/OptionPie';
import {OptionLine} from './options/OptionLine';
import {OptionScatter} from './options/OptionScatter';
import {LineChartLV} from '../charts/LineChartLV';
import {PieLV} from '../charts/PieLV';
import {ScatterChartLV} from '../charts/ScatterChartLV';
import {StackedBarChartLV} from '../charts/StackedBarChartLV';
import {WaterfallChartLV} from '../charts/WaterfallChartLV';
import {BarChartLV} from '../charts/BarChartLV';
import {CombChartLV} from '../charts/CombChartLV';

/**
 * Graph drop-down options and the component it selects
 */
export const GRAPH_OPTIONS = {
  BAR: {
    value: 'BAR',
    label: <OptionBar>
             Bar
           </OptionBar>,
    graph: BarChartLV
  },
  COMB: {
    value: 'COMB',
    label: <OptionBar>
      COMB
    </OptionBar>,
    graph: CombChartLV
  },
  WATERFALL: {
    value: 'WATERFALL',
    label: <OptionWaterfall>
             Waterfall
           </OptionWaterfall>,
    graph: WaterfallChartLV
  },
  STACKED_BAR: {
    value: 'STACKED_BAR',
    label: <OptionStackedBar>
             Stacked
           </OptionStackedBar>,
    graph: StackedBarChartLV
  },
  PIE: {
    value: 'PIE',
    label: <OptionPie>
             Pie
           </OptionPie>,
    graph: PieLV
  },
  LINE: {
    value: 'LINE',
    label: <OptionLine>
             Line
           </OptionLine>,
    graph: LineChartLV
  },
  SCATTER: {
    value: 'SCATTER',
    label: <OptionScatter>
             Scatter
           </OptionScatter>,
    graph: ScatterChartLV
  }
};
