import {css} from 'emotion';

export const styles = {
  disabledRow: css`
    {
      background-color: #DDD !important;
      &:hover {
        background-color: #DDD !important;
      }
    }
  `,
  multiSelectFields: css`
    && div[class *="MuiInputBase-root"] {
      height: 40px !important;
      padding-top: 5px !important;
      flex-flow: nowrap;
      width: 240px;
    }
  `,
  markedField: css`
    && div[class *="MuiInputBase-root"] {
      height: 40px !important;
      padding-top: 5px !important;
      flex-flow: nowrap;
      width: 240px;
      color: rgb(255, 120, 99);
    }
    && div[class *="MuiChip-root"] {
      color: rgb(255, 120, 99);
    }
  `,
  errorField: css`
    && div[class *="MuiInputBase-root"] {
      width: 240px;
      fieldset {
        border-color: red
      }
    }
  `,
  tags: css`
    padding-top: 8px;
    padding-left: 4px;
  `,
  configGrid: css`
    height: 100%;
  `,
  controlBar: css`
    display: flex;
    position: absolute;
    bottom: 0;
    margin-left: -24px;
    height: 60px;
    width: 94vw;
    background: #FF6B57;
    color: white;
  `,
  modelsCount: css`
    line-height: 60px;
    text-align: left;
    margin-left: 24px;
  `
}
