import React from 'react';
import {styles} from './TitleLV.styles';

/**
 * Standard form title
 */
export const TitleLV = ({center, children, className = ''}) => {
  const customStyle = {};
  if(center) {
    customStyle.textAlign = 'center';
  }

  return (
    <div className={`${styles.title} ${className}`} style={customStyle}>
      {children}
    </div>
  );
};
