import {call, put, takeEvery} from 'redux-saga/effects';

import {urlBuilders} from '../../../js/constants';
import {post, putData, delData} from '../../../js/server.js';

import {
    changeEditStep,
    DELETE_COMPLETE
} from '../../../modules/platform/edit.js';

import {fetchPartners} from './partner-sagas.js';

import {
    loadCampaignErrors,
    CREATE_CAMPAIGN,
    UPDATE_CAMPAIGN,
    DELETE_CAMPAIGN
} from '../campaign.js';
import {fetchClients} from './client-sagas.js';
import {
    changeAddNewStep,
    ADD_NEW_COMPLETE
} from '../../../modules/platform/addNew.js';
import {closeModal} from '../modal.js';
import {snackbar} from '../snackbar.js';

export function* createCampaign(action) {
    let campaignResponse = yield call(
        post,
        action.token,
        action.baseUrl + urlBuilders.createCampaign(),
        action.payload
    );

    if (!campaignResponse.error) {
        yield call(fetchPartners, action);
    } else {
        yield put(
            loadCampaignErrors(campaignResponse.rawError.response.body.errors)
        );
    }
    yield put(changeAddNewStep(ADD_NEW_COMPLETE));
}

export function* watchCreateCampaign() {
    yield takeEvery(CREATE_CAMPAIGN, createCampaign);
}

export function* updateCampaign(action) {
    let campaignResponse = yield call(
        putData,
        action.token,
        action.baseUrl + urlBuilders.updateCampaign(action.params.campaignId),
        action.payload
    );
    if (!campaignResponse.error) {
        yield call(fetchClients, action);
        yield put(closeModal());
    } else {
        let errorMessage = campaignResponse.rawError.response.body.errors[0];
        yield put(
            snackbar(
                'error',
                'ERROR: \n Error while updating campaign: ' + errorMessage
            )
        );
    }
}

export function* deleteCampaign(action) {
    const campaignResponse = yield call(
        delData,
        action.token,
        action.baseUrl + urlBuilders.updateCampaign(action.params.campaignId)
    );
    if (campaignResponse.rawError !== undefined || campaignResponse.error) {
        const errors =
            campaignResponse.rawError === undefined
                ? [campaignResponse.message]
                : campaignResponse.rawError.response.body.errors;
        yield put(loadCampaignErrors(errors));
    } else {
        yield call(fetchClients, action);
    }
    yield put(changeEditStep(DELETE_COMPLETE));
}

export function* watchUpdateCampaign() {
    yield takeEvery(UPDATE_CAMPAIGN, updateCampaign);
}

export function* watchDeleteCampaign() {
    yield takeEvery(DELETE_CAMPAIGN, deleteCampaign);
}
