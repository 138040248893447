import React from 'react';
import {ButtonLV} from './ButtonLV';
import {styles} from './button.styles';

class IconButtonLV extends React.Component {
  render() {
    return (
      <ButtonLV
        className={styles.icon}
        {...this.props} />
    );
  }
}

export {IconButtonLV};