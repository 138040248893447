import {css} from 'emotion';

export const styles = {
  label: css`
    display: flex;
    .graph-label-text {
      margin-left: 12px;
    }
  `,
};
