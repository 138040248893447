import {
    R1ActionIcon,
} from '@rainagency/rain-one-soggy-loaf';
import {
    usePostTriggerDownloadUrl,
} from '../../../hooks/useTriggerDownloadUrl';
import { urlBuilders } from '../../../js/constants';
import {useGet} from "../../../hooks/useRest";

/**
 * Download results file on Grid Action Popover click
 */
export const AdImpactDownloadResultsActionIcon = ({
                                                    cell,
                                                    hide,
                                                    closePopover
                                                }) => {

    const {
        triggerDownload,
        loading
    } = usePostTriggerDownloadUrl({
        restHook: useGet,
        requestUrl: urlBuilders.getWavecast(cell.row.id),
        onFinal: closePopover
    });

    const handleClick = () => {
        triggerDownload();
    };

    return (
        <R1ActionIcon
            iconType='File'
            label='Export AdImpact Results File'
            hide={hide}
            onClick={handleClick}
            customStyles={{
                cursor: loading ? 'wait' : 'pointer'
            }}
        />
    );

};
