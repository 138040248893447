import React from 'react';
import {useForm} from 'react-hook-form';
import {FormRowLV} from '../../components/form/row/FormRowLV';
import {TextInputLV} from '../../components/form/text/TextInputLV';
import {OrangeButtonLV} from '../../components/form/buttons/OrangeButtonLV';
import {ButtonLV} from '../../components/form/buttons/ButtonLV';
import {urlBuilders} from '../../js/constants.js';
import {usePost} from '../../hooks/useRest';

export const NewPartnerForm = ({
  cancelClick=()=>{},
  setSuccess=()=>{},
  setError=()=>{}
}) => {
  const {handleSubmit, formState, register, errors, getValues} = useForm();
  const {dirty, isSubmitting} = formState;

  const url = urlBuilders.createPartner();
  const [{data, loading, error}, postPartner] = usePost({url});

  React.useEffect(() => {
    if(loading) {
      return;
    }

    const {name} = getValues();
    if(error) {
      setError({
        title: 'New Partner Not Added',
        text: `${name} Failed to be added.`,
        description: error.message
      });
    }

    if(data) {
      setSuccess({
        title: 'New Partner Added',
        text: `${name} has been successfully added.`
      });
    }
  }, [data, loading, error, setError, setSuccess, getValues]);

  const newPartnerSubmit = (formData={}) => {
    postPartner({
      data: formData
    });
  };

  return (
    <form
      onSubmit={handleSubmit(newPartnerSubmit)}
      className={'new-partner-form'}>

      <FormRowLV>
        <TextInputLV
          name='name'
          placeholder={'Partner Name'}
          dataTest={'Name-add-new-form'}
          description={'250 Characters Max'}
          dirty={dirty}
          register={
            register(
              {
                required: 'Partner Name is required',
                maxLength: {
                  value: 250,
                  message: 'Cannot exceed 250 characters'
                }
              }
            )}
          errors={errors}
          width={315}
        />
      </FormRowLV>

      <FormRowLV>
        <OrangeButtonLV
          submitting={isSubmitting}
          dirty={dirty}
          dataTest={'addNew-submitButton'}>
          Save
        </OrangeButtonLV>

        <ButtonLV
          onClick={cancelClick}
          submitting={isSubmitting}
          dataTest={'addNew-cancelButton'}>
          Cancel
        </ButtonLV>
      </FormRowLV>

    </form>
  );
};
