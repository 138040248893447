import React, {useState} from 'react';
import {useLocation} from 'react-router-dom';
import {HeaderAndTabs} from '../pages/header/HeaderAndTabs';
import {AuthToken} from '../hooks/AuthFactory';
import {HeaderContext} from './HeaderContext';

export const PRODUCT_STORE_KEY = 'PRODUCT_STORE_KEY';

/**
 * Context for header events/actions
 */
function HeaderProvider({children}) {
  const location = useLocation() || {};
  const headerRef = React.useRef();
  const refreshHandlerRef = React.useRef({});
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [counter, setCounter] = React.useState(0);

  /**
   * Map current location to use for unique mapping
   */
  const locationKey = React.useMemo(() => {
    return `${location.pathname}${location.search}`;
  }, [location]);

  /**
   * Set the function to call when the "Refresh" button is clicked
   */
  const setRefreshClickHandler = (handlerFunc) => {
    // Do not set if handler is the same
    if(refreshHandlerRef.current[locationKey] === handlerFunc) {
      return;
    }

    refreshHandlerRef.current[locationKey] = handlerFunc;
    setCounter(counter+1);
  };

  /**
   * Returns the Refresh function for modals to leverage when closing
   */
  const getRefreshClickHandler = () => {
    return refreshHandlerRef.current[locationKey];
  };

  /**
   * Get the height of the current header
   * This can be used to calculate the size of body elements
   */
  const getHeaderHeight = () => {
    if(!headerRef.current) {
      return 0;
    }
    return headerRef.current.clientHeight;
  };

  /**
   * Set the height of the current window, adjust on resize
   * This can be used to calculate the size of child elements
   */
  // Add window height and update on resize
  const resizeHandler = () => {
    const {innerHeight} = window;
    setWindowHeight(innerHeight);
  };
  window.addEventListener('resize', resizeHandler);

  /**
   * Return known heights
   * This will allow children to size themselves relatively
   */
  const getAllHeights = () => ({
    windowHeight: windowHeight,
    headerHeight: getHeaderHeight()
  });

  return (
    <HeaderContext.Provider
      value={{
        setRefreshClickHandler,
        getRefreshClickHandler,
        getHeaderHeight,
        getAllHeights
      }}>

      {children}

    </HeaderContext.Provider>
  );
  
}
export {HeaderProvider};
