import {UPLOAD_FILE_TYPES} from './constants/fileTypeOptions.constant';
import React from "react";
import {MultiFileUploadLV} from "../../../components/file-upload/MultiFileUploadLV";
import CallToActionLV from "../../../components/tabs/CallToActionLV";

export const ScoringFileUpload = ({
                                      mcaRunId,
                                      onComplete = () => {}
                                  }) => {

    const uploadArgs = React.useMemo(() => {
        return {
            mcaRunId: mcaRunId
        }
    }, [mcaRunId])

    const ctaButton = React.useMemo(() => {
        return (
            <CallToActionLV
                isDisabled={!mcaRunId}
                dataTest={'scoring-new-analysis'}>
                Upload Scoring Data
            </CallToActionLV>
        );
    }, [mcaRunId])

    return (
        <MultiFileUploadLV
            uploadFileTypes={UPLOAD_FILE_TYPES}
            uploadArgs={uploadArgs}
            onComplete={onComplete}
            callToActionComponent={ctaButton}
            type={'Scoring'}
        />
    )
};
