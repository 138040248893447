import React from 'react';
import {TableLV} from '../../../components/data-table/TableLV';
import {NumericCell} from '../../../components/data-table/cells/NumericCell';
import _ from "lodash";

export const ComparisonTable = ({
  data=[],
  kpiType,
  loading=false,
  hasData=false,
  timeScale,
  nestedKpis=[]
}) => {

  const timeScaleLabel = timeScale ? timeScale.charAt(0).toUpperCase() + timeScale.slice(1).toLowerCase() : 'Daily';

  let projectedLabel = 'Projected Revenue';
  let incrementalLabel = 'Incremental Revenue';
  let marginalLabel = 'Marginal ROI';
  let ratioLabel = 'ROI';
  let isCurrency = true;
  let decimals = 0;
  const alignColumn = 'center';

  if(kpiType === 'Units') {
    projectedLabel = 'Projected Units';
    incrementalLabel = 'Incremental Units';
    marginalLabel = 'Marginal CPA';
    ratioLabel = 'CPA';
    isCurrency = false;
  }

  const override = {
    variableName: {
      hidden: true
    },
    variableTactic: {
      name: 'Marketing Tactic',
      align: 'left',
      component: <NestedBaseCell nestedKpis={nestedKpis}/>
    },
    scenario1Spend: {
      name: `${timeScaleLabel} Spend`,
      align: alignColumn,
      component: <NumericCell isCurrency={true} decimals={decimals} isInline={true} align={alignColumn}/>
    },
    scenario1Contribution: {
      name: projectedLabel,
      align: alignColumn,
      component: <NumericCell isCurrency={isCurrency} decimals={decimals} isInline={true} align={alignColumn}/>
    },
    scenario1Ratio: {
      name: ratioLabel,
      align: alignColumn,
      component: <NumericCell isCurrency={!isCurrency} isInline={true} align={alignColumn}/>
    },
    separator1: {
      separator: true
    },
    scenario2Spend: {
      name: `${timeScaleLabel} Spend`,
      align: alignColumn,
      component: <NumericCell isCurrency={true} decimals={decimals} isInline={true} align={alignColumn}/>
    },
    scenario2Contribution: {
      name: projectedLabel,
      align: alignColumn,
      component: <NumericCell isCurrency={isCurrency} decimals={decimals} isInline={true} align={alignColumn}/>
    },
    scenario2Ratio: {
      name: ratioLabel,
      align: alignColumn,
      component: <NumericCell isCurrency={!isCurrency} isInline={true} align={alignColumn}/>
    },
    separator2: {
      separator: true,
      style: {
        width: '60px'
      },
    },
    incrementalSpend: {
      align: alignColumn,
      component: <NumericCell isCurrency={true} decimals={decimals} isInline={true} align={alignColumn}/>
    },
    incremental: {
      name: incrementalLabel,
      align: alignColumn,
      component: <NumericCell isCurrency={isCurrency} decimals={decimals} isInline={true} align={alignColumn}/>
    },
    marginal: {
      name: marginalLabel,
      align: alignColumn,
      component: <NumericCell isCurrency={!isCurrency} isInline={true} align={alignColumn}/>
    },
  };

  const groups = [{
    name: 'Scenario 1',
    align: alignColumn,
    columns: ['scenario1Spend', 'scenario1Contribution', 'scenario1Ratio']
  }, {
    name: 'Separator1',
    align: alignColumn,
    columns: ['separator1']
  }, {
    name: 'Scenario 2',
    align: alignColumn,
    columns: ['scenario2Spend', 'scenario2Contribution', 'scenario2Ratio']
  }, {
    name: 'Separator2',
    align: alignColumn,
    columns: ['separator2']
  }, {
    name: 'Comparison',
    align: alignColumn,
    columns: ['incrementalSpend', 'incremental', 'marginal']
  }];

  return (
    <TableLV
      data={data}
      hasData={hasData}
      isLoading={loading}
      override={override}
      groups={groups}
      disableTableSort={false}
    />
  );
};

export const NestedBaseCell = ({cell, nestedKpis = []}) => {
  let varName = _.get(cell, 'row.values.variableTactic');
  if(nestedKpis.includes(varName)){
    varName = varName + ' Base';
  }
  return (<div>{varName}</div>);
}