import {Auth} from 'aws-amplify';
export const AUTH_KEY = 'AUTH_KEY';
export const TOKEN_PAYLOAD = 'TOKEN_PAYLOAD';

/**
 * Populate amplify token and repopulate after # Seconds
 * Amplify already handles caching and renewal of Auth
 *
 * When using axios-hooks for REST calls, URL/Auth is populated on component redraw,
 *   instead of on request.  This can cause Amplify to get hammered with token requests
 *   on every draw.
 *
 * This factory lazy-loads a token, and updates after # Seconds
 */
export default class AuthFactory {

  async attachBearerToken(config) {
    try {
      const session = await Auth.currentSession();
      const refreshToken = session.getRefreshToken();
      const currentTimeSeconds = Math.round(+new Date() / 1000);
      let idToken = session.getIdToken();

      // Refresh 20 minutes before expiring;
      const timeBeforeExpire = 1200;
      if (idToken.getExpiration() < currentTimeSeconds + timeBeforeExpire) {
        const currentUser = await Auth.currentAuthenticatedUser();
        idToken = await refreshSessionWrapper(currentUser, refreshToken);
      }

      window.localStorage.setItem(AUTH_KEY, idToken.jwtToken);
      window.localStorage.setItem(TOKEN_PAYLOAD, JSON.stringify(idToken.payload));

      config.headers.Authorization = "Bearer " + idToken.getJwtToken();
    } catch (err) {
      console.log(err);
      if ('No current user' === err) {
        const token = window.localStorage.getItem(AUTH_KEY);
        config.headers.Authorization = "Bearer " + token;
      }
    }

    return config;
  }

  /**
   * Return the current auth token
   * Check if it needs to be renewed and trigger renewal
   * Return AUTH from Local Storage if it is not yet set
   */
  getAuthToken() {
    return {
      token: window.localStorage.getItem(AUTH_KEY),
      payload: window.localStorage.getItem(TOKEN_PAYLOAD)
    };
  }

  // Use Amplify to renew token
  async forceRenewToken() {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();
      const refreshToken = currentSession.getRefreshToken();

      const idToken = await refreshSessionWrapper(cognitoUser, refreshToken);

      window.localStorage.setItem(AUTH_KEY, idToken.jwtToken);
      window.localStorage.setItem(TOKEN_PAYLOAD, JSON.stringify(idToken.payload));
    } catch (e) {
      console.log('Unable to refresh auth token', e);
    }
  }

  // Handle logout and clear storage
  logOut() {
    Auth.signOut()
      .then(() => {
        window.sessionStorage.clear();
        window.localStorage.clear();

        // Wipe any component state
        location.reload();
      })
      .catch(err => console.log(err));
  }
}

const refreshSessionWrapper = (user, refreshToken) => {
  return new Promise((res, rej) => {
    user.refreshSession(refreshToken, (err, data) => {
      if (err) {
        Auth.signOut().then(() => {
          window.sessionStorage.clear();
          window.localStorage.clear();

          // Wipe any component state
          location.reload();
        }).catch(err => console.log(err));
      } else {
        res(data.getIdToken());
      }
    });
  });
}

// Singleton
export const AuthToken = new AuthFactory();
