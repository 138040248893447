import {call, put, takeEvery} from 'redux-saga/effects';

import {urlBuilders} from '../../../js/constants.js';

import {fetchPartners} from './partner-sagas.js';
import {
    FETCH_ALL_CLIENTS,
    LOAD_ALL_CLIENTS,
    CREATE_CLIENT,
    UPDATE_CLIENT,
    DELETE_CLIENT,
    loadClientErrors
} from '../client.js';
import {LOAD_ALL_CAMPAIGNS} from '../campaign.js';
import {getData, post, putData, delData} from '../../../js/server';
import {
    changeAddNewStep,
    ADD_NEW_COMPLETE
} from '../../../modules/platform/addNew.js';
import {
    changeEditStep,
    DELETE_COMPLETE
} from '../../../modules/platform/edit.js';

import {closeModal} from '../modal.js';
import {snackbar} from '../snackbar.js';

/**
 * [fetchClients gets all the clients. Departments and campaigns are also in the response,
 * so those need to be broken out and sent to the right modules.]
 * @param  {object}    action the action that started the saga
 * @return {Generator}        the generator function that controls the flow of the saga
 */
export function* fetchClients(action) {
    let clients = yield call(
        getData,
        action.token,
        action.baseUrl + urlBuilders.getAllClients()
    );
    const campaigns = yield call(pullOffChild, 'campaigns', clients);
    clients = yield call(killChild, 'campaigns', clients);
    yield put({type: LOAD_ALL_CLIENTS, clients});
    yield put({type: LOAD_ALL_CAMPAIGNS, campaigns});
}

export function* watchFetchClients() {
    yield takeEvery(FETCH_ALL_CLIENTS, fetchClients);
}

/**
 * Takes a name of a child and then an array of objects, pulls the named child off of all the objects,
 * returing a new array made of all these children
 * @param  {String} child the key to pull off
 * @param  {array} array the array of objects
 * @return {array}       made of the children of the input array
 */
export function pullOffChild(child, array) {
    const newArray = [];
    // eslint-disable-next-line
    array.map((parentObject) => {
        // eslint-disable-next-line
        parentObject[child].map((childObject) => {
            newArray.push(Object.assign({}, childObject));
        });
    });
    return newArray;
}

/**
 * Takes a name of a child and then an array of objects and removes the key that matches the child from
 * each of the objects
 * @param  {String} child the key to remove
 * @param  {array} array array of objects to have child removed
 * @return {array}       the array of objects with the child removed
 */
export function killChild(child, array) {
    return array.map((object) => {
        delete object[child];
        return Object.assign({}, object);
    });
}

export function* createClient(action) {
    const clientResponse = yield call(
        post,
        action.token,
        action.baseUrl + urlBuilders.createClient(),
        action.payload
    );
    if (!clientResponse.error) {
        yield call(fetchPartners, action);
    } else {
        yield put(
            loadClientErrors(clientResponse.rawError.response.body.errors)
        );
    }
    yield put(changeAddNewStep(ADD_NEW_COMPLETE));
}

export function* watchCreateClient() {
    yield takeEvery(CREATE_CLIENT, createClient);
}

/**
 * [UpdateClient updates the selected client using client id.]
 * @param  {object}    action the action that started the saga
 * @return {Generator}        the generator function that controls the flow of the saga
 */
export function* updateClient(action) {
    let clientResponse = yield call(
        putData,
        action.token,
        action.baseUrl + urlBuilders.updateClient(action.params.clientId),
        action.payload
    );

    if (!clientResponse.error) {
        yield call(fetchClients, action);
        yield put(closeModal());
    } else {
        let errorMessage = clientResponse.rawError.response.body.errors[0];
        yield put(
            snackbar(
                'error',
                'ERROR: \n Error while updating client: ' + errorMessage
            )
        );
    }
}

export function* watchUpdateClient() {
    yield takeEvery(UPDATE_CLIENT, updateClient);
}

/**
 * [DeleteClient deletes the selected client using client id.]
 * @param  {object}    action the action that started the saga
 * @return {Generator}        the generator function that controls the flow of the saga
 */
export function* deleteClient(action) {
    const clientResponse = yield call(
        delData,
        action.token,
        action.baseUrl + urlBuilders.deleteClient(action.params.clientId)
    );
    if (!clientResponse.error) {
        yield call(fetchPartners, action);
    } else {
        yield put(
            loadClientErrors(clientResponse.rawError.response.body.errors)
        );
    }
    yield put(changeEditStep(DELETE_COMPLETE));
}

export function* watchDeleteClient() {
    yield takeEvery(DELETE_CLIENT, deleteClient);
}
