import React from 'react';
import {ButtonLV} from './ButtonLV';
import {styles} from './button.styles';

class OrangeButtonLV extends React.Component {
  render() {
    return (
      <ButtonLV
        className={styles.orange}
        {...this.props} />
    );
  }
}

export {OrangeButtonLV};
