import {css} from 'emotion';

export const styles = {
  title: css`
    font-family: 'Roboto', sans-serif;
    font-size: 34px;
    line-height: 40px;
    text-align: left;
    letter-spacing: 0.25px;
    margin-bottom: 18px;
  `
};
