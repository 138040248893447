import {combineReducers} from 'redux';

import partner from './partner.js';
import campaign from './campaign.js';
import client from './client.js';
import config from './config.js';
import department from './department.js';
import cognito from './cognito.js';
import modal from './modal.js';
import snackbar from './snackbar.js';
import uploadedFiles from './uploadedFiles.js';
import downloadTemplate from './downloadTemplate.js';
import addNew from './addNew.js';
import edit from './edit.js';
import dialog from './dialog';
import loading from './loading.js';

const platform = combineReducers({
    partner,
    campaign,
    client,
    config,
    department,
    cognito,
    modal,
    snackbar,
    uploadedFiles,
    downloadTemplate,
    addNew,
    edit,
    dialog,
    loading
});

export default platform;
