import React from 'react';
import {ImagePopoverContext} from "./context/ImagePopoverContext";

const Image = ({imageUrl, alt, popoverStyle}) => {

    const {
        openPopover,
    } = React.useContext(ImagePopoverContext);

    const image = React.useMemo(() => {
        if (imageUrl) {
            return (
                <img alt={alt} src={imageUrl} style={{  maxWidth: '400px', width: '100%' }} />
            );
        }
    }, [imageUrl]);

    const openPopoverWrapper = () => {
        openPopover({imageUrl, alt, style: popoverStyle});
    }

    return (
        <div>
            <div style={{
                maxWidth: '600px',
                width: '100%'
            }} onClick={openPopoverWrapper}>
                {image}
            </div>
        </div>

    );
};

export default Image;