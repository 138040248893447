import {call, put, takeEvery, select} from 'redux-saga/effects';

import {urlBuilders} from '../../../js/constants';
import {RUN_STATUS} from '../../../constants/mca-run-statuses';
import {formatFilters} from '../../../js/validation';
import {getMcaVariables} from './filter-sagas';
import {fetchMcaRunsByCampaign} from './run-sagas'
import {
  FETCH_MCA_MODELS,
    loadMcaModels,
    spinner,
    loadQueryId,
    APPROVE_MODEL,
    FETCH_SELECTED_MODEL,
    setSelectedModel,
    setCanFetchModels
} from '../models';

import {fetchMcaRunVariableNameTranslatorRequest} from '../variable-name-translator';
import {snackbar} from '../../platform/snackbar';

import {FETCH_MCA_RUNS_BY_CAMPAIGN_REQUEST, setSelectedMcaRun, getMcaRunStatus} from '../run';

import {getData, post, putData} from '../../../js/server';
import {closeDialog} from '../../platform/dialog';
import {closeModal} from '../../../modules/platform/modal';

export function* fetchMcaModels(action) {
    yield put(spinner(true));
    yield put(setCanFetchModels(false));
    const mcaVariables = yield call(getMcaVariables, action);
    const filters = yield formatFilters(action.payload, mcaVariables);
    const getQueryId = yield call(
        post,
        action.token,
        action.baseUrl + urlBuilders.getMcaModels(action.payload.mcaRunId),
        filters
    );

    if (!getQueryId.error) {
        const queryId = getQueryId.body.queryId;
        const response = yield call(getMcaRowsResult, action, queryId);
        if (!response.error) {
            yield put(loadMcaModels(response.data));
            yield put(loadQueryId(queryId));
        }
    }

    yield put(spinner(false));
}

export function* watchFetchMcaModels() {
    yield takeEvery(FETCH_MCA_MODELS, fetchMcaModels);
}

export function getMcaRowsResult(action, queryId) {
    return new Promise(function(resolve, reject) {
        let interval = setInterval(
            /* istanbul ignore next */ () => {
                getData(
                    action.token,
                    action.baseUrl + urlBuilders.getQueryStatus(queryId)
                ).then((res) => {
                    if (res.status === 'Success') {
                        clearInterval(interval);
                        getData(
                            action.token,
                            action.baseUrl + urlBuilders.getResultRows(queryId)
                        ).then((res) => {
                            resolve(res);
                        });
                    } else if (res.status === 'Error') {
                        clearInterval(interval);
                        reject(res);
                    }
                });
            },
            1000
        ); //1sec
    });
}

export function getMcaModelResult(action, queryId) {
    return new Promise(function(resolve, reject) {
        let interval = setInterval(
            /* istanbul ignore next */ () => {
                getData(
                    action.token,
                    action.baseUrl + urlBuilders.getQueryStatus(queryId)
                ).then((res) => {
                    if (res.status === 'Success') {
                        clearInterval(interval);
                        getData(
                            action.token,
                            action.baseUrl + urlBuilders.getResultRows(queryId)
                        ).then((res) => {
                            resolve(queryId);
                        });
                    } else if (res.status === 'Error') {
                        clearInterval(interval);
                        reject(queryId);
                    }
                });
            },
            1000
        ); //1sec
    });
}

export function* approveModel(action) {
    yield put(spinner(true));
    const statusResponse = yield call(updateStatus, action, 'FINALIZING');
    yield put(spinner(false));
    yield put(closeDialog());
    yield put(closeModal(`mcaDetails`));

    if (statusResponse.error) {
        yield put(snackbar('error', 'Error Approving Model'));
    } else {
        //Re-fetch to show new finalizing status
        yield put(
            Object.assign({}, action, {
                type: FETCH_MCA_RUNS_BY_CAMPAIGN_REQUEST
            })
        );
        //show mca-runs table
        yield put(setSelectedMcaRun(''));

        //start select/move final model process
        const selectResponse = yield call(
            getData,
            action.token,
            action.baseUrl +
                urlBuilders.selectFinalModel(
                    action.params.mcaRunId,
                    action.params.partition,
                    action.params.modelId
                )
        );

        if (selectResponse.error) {
            yield put(snackbar('error', 'Error Approving Model'));
        } else {
            yield call(waitForQueryCompletion, action, selectResponse.queryId);
            const postResponse = yield call(
                post,
                action.token,
                action.baseUrl + urlBuilders.postFinalModel(),
                {
                    queryId: selectResponse.queryId,
                    mcaRunId: action.params.mcaRunId
                }
            );

            if (postResponse.error) {
                yield put(snackbar('error', 'Error Approving Model'));
            }
        }

        //Re-fetch to show final status
        yield call(fetchMcaRunsByCampaign, action);

        //If it hasn't finalized set back to run complete
        const selectedMcaRunStatus = yield select(getMcaRunStatus, action.params.mcaRunId);

        if (selectedMcaRunStatus === RUN_STATUS.FINALIZING) {
            yield call(updateStatus, action, 'RUN_COMPLETE');
            yield call(fetchMcaRunsByCampaign, action);
        } 
    }
}

export function* watchApproveModel() {
    yield takeEvery(APPROVE_MODEL, approveModel);
}

export function* fetchSelectedModel(action) {
    yield put(spinner(true));
    const response = yield call(
        getData,
        action.token,
        action.baseUrl + urlBuilders.getSelectedModel(action.params.mcaRunId)
    );
    if (!response.error) {
        yield put(loadMcaModels(response));
        yield put(setSelectedModel(response[0].modelid));
        yield put(
            fetchMcaRunVariableNameTranslatorRequest(
                action.token,
                action.baseUrl,
                action.params.mcaRunId
            )
        );
    } else {
        // this error comes up on refresh when the selected run does not have a final model
        // ignoring the error for now so the user doesnt get false error messages
        // yield put(snackbar('error', 'Error retrieving model details.'));
    }
    yield put(spinner(false));
}

export function* watchFetchSelectedModel() {
    yield takeEvery(FETCH_SELECTED_MODEL, fetchSelectedModel);
}

export function waitForQueryCompletion(action, queryId) {
    return new Promise((resolve, reject) => {
        let interval = setInterval(
            /* istanbul ignore next */ () => {
                getData(
                    action.token,
                    action.baseUrl + urlBuilders.getQueryStatus(queryId)
                ).then((res) => {
                    if (res.status === 'Success') {
                        clearInterval(interval);
                        resolve(queryId);
                    } else if (res.status === 'Error') {
                        clearInterval(interval);
                        reject(queryId);
                    }
                });
            },
            1000
        ); //1sec
    });
}

export function* updateStatus(action, runStatus) {
    return yield call(
        putData,
        action.token,
        action.baseUrl +
            urlBuilders.updateV2McaRunStatus(action.params.mcaRunId),
        {runStatus}
    );
}
