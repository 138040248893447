import React from 'react';
import { styled } from '@mui/system';
import { ArrowForwardIos } from '@mui/icons-material';
import {
  IconButton,
  Typography,
} from '@mui/material';
import { Logo } from './Logo';
import { NavigationMenu } from './NavigationMenu';
import Dots from '../../../assets/Dots.svg';
import { LeavenedTextSVG } from '../../../assets/LeavenedTextSVG';

export const NAVIGATION_OPEN_STATE = 'navigation-open-state-lv';

/**
 * Left navigation panel for the entire app
 * @param products - map of products available to logged in user
 */
export const LeftNavigation = ({
  products,
  selectedFeature,
  setSelectedFeature = () => {},
  isOpen = false,
  isHidden
}) => {
  const [open, setIsOpen] = React.useState(isOpen);

  // Open state change from parent
  React.useEffect(() => {
    setIsOpen(isOpen);
  }, [isOpen]);

  // Update isHidden on change from parent
  // Collapse navigation if hidden
  React.useEffect(() => {

    if(isHidden) {
      setIsOpen(false);
      return;
    }

    const storageCollapseState = window.localStorage.getItem(
      NAVIGATION_OPEN_STATE
    );

    if(storageCollapseState === undefined) {
      setIsOpen(true);
      return;
    }

    // Force storage value to boolean
    setIsOpen(storageCollapseState === 'true');

  }, [isHidden]);

  const toggle = () => {
    const openState = !open;
    setIsOpen(openState);
    window.localStorage.setItem(
      NAVIGATION_OPEN_STATE, openState
    );
  };

  return (
    <>

      <NavContainer
        aria-label={LABELS.LEFT_NAVIGATION}
        open={open}>

        <NavigationToggleIcon
          open={open}
          toggle={toggle}
          isHidden={isHidden}
        />

        <LogoContainer
          open={open}
          hide={true}
        />

        <LeavenedTextSVG
          setSelectedFeature={setSelectedFeature}
          open={open}
          hide={false}
        />

        <NavigationMenu
          open={open}
          isHidden={isHidden}
          products={products}
          selectedFeature={selectedFeature}
          setSelectedFeature={setSelectedFeature}
        />

        <DotsImage
          open={open}
        />

      </NavContainer>

    </>
  );
};

const NavigationToggleIcon = ({
  toggle = () => {},
  open,
  isHidden,
  ...props
}) => {

  let extendedStyles = {};

  if(isHidden) {
    extendedStyles = {
      opacity: 0
    };
  }

  return (
    <NavigationToggleStyled
      className='navigation-toggle'
      aria-label={open ? LABELS.CONTRACT : LABELS.EXPAND}
      open={open}
      onClick={toggle}
      style={extendedStyles}
      {...props}>

      <ArrowIcon />

    </NavigationToggleStyled>
  );

};

const LogoContainer = ({
  open,
  hide
}) => {

  if(hide) {
    return null;
  }

  const displayText = open ? 'Leavened' : null;

  return (
    <LogoContainerStyled
      open={open}
      className='logo'>

      <div className='logo-wrapper'
           style={{
             display: 'flex'
           }}>
        <Logo
          aria-label='logo'
        />
      </div>

      <Typography
        className='leavened-text'
        variant={'subtitle2'}>

        {displayText}

      </Typography>


    </LogoContainerStyled>
  );
};

const DotsImage = ({
  open
}) => {
  return (
    <div
      className='dots-container'
      style={{
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        width: open ? 196 : 84,
        height: 132
      }}>

      <img
        src={Dots}
      />

    </div>
  );
};

const LogoContainerStyled = styled('div')(({
  theme,
  open
}) => ({
  display: 'flex',
  height: 32,
  alignItems: 'flex-end',
  gap: open ? 12 : 0,
  width: open ? 156 : 40,
  paddingBottom: 16,
  paddingTop: 22,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  '& .leavened-text': {
    opacity: 0,
    color: '#FFF',
    left: 89,
    userSelect: 'none',
    transition: theme.transitions.create(['opacity'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.complex,
    }),
  },
  '&[open] .leavened-text': {
    opacity: 1
  }
}));

const ArrowIcon = styled(ArrowForwardIos)(() => ({
  fontSize: 16,
  color: '#1B1B1B'
}));

const NavContainer = styled('div')(({
  theme, open
}) => {
  return {
    width: open ? 196 : 84,
    minWidth: open ? 196 : 84,
    backgroundColor: theme.palette.primary.main,
    marginRight: '16px',
    paddingTop: 32,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start !important',
    alignItems: 'center',
    gap: 16,
    transition: theme.transitions.create(['width', 'min-width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  };
});

const NavigationToggleStyled = styled(IconButton)(({
  theme, open
}) => {
  return {
    zIndex: 100,
    width: 16,
    height: 48,
    padding: '16px 0',
    background: open ? theme.palette.grey[600] : theme.palette.grey[300],
    borderRadius: '0 16px 16px 0',
    position: 'absolute',
    left: open ? 196 : 84,
    top: 72,
    transform: open ? 'rotate(-180deg) translate(16px,0px)' : 'none',
    transition: theme.transitions.create(['left'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&:hover': {
      background: open ? theme.palette.grey[700] : theme.palette.grey[300],
      opacity: open ? '.6' : '.4'
    }
  };
});

export const LABELS = {
  EXPAND: 'Expand navigation',
  CONTRACT: 'Collapse navigation',
  LEFT_NAVIGATION: 'Left Navigation'
};
