import React from 'react';

import { useCampaignManagement } from '../../../../hooks/useCampaignManagement';
import { usePost } from '../../../../hooks/useRest';
import { useGetPolling } from "../../../../hooks/usePolling";
import DownloadCSVFactory from '../../../../js/download-csv-factory';
import { uploadFileToS3 } from '../../../../js/storage';
import { urlBuilders } from '../../../../js/constants';

const MEDIA_GROUPING_FILE_NAME = 'media_grouping.csv';
const CONFIGAI_MEDIA_GROUPING_FILE_TYPE = 'configAIMediaGrouping';

export const useConfigAI = ({
  analysisValues,
  configValues = {}
}) => {
  const { campaignId, setConfigAIId } = useCampaignManagement();
  const [configAI, setConfigAI] = React.useState();
  const [configAILoading, setConfigAILoading] = React.useState();
  const [configAIError, setConfigAIError] = React.useState();

  /* Call to start ConfigAI Run */
  const [{data: configAIRunResult, error: configAIRunErrors}, runConfigAI] = usePost({
    url: urlBuilders.runConfigAI(),
    data: {},
    manual: true
  });

  const {data={}, clearIntervalInstance, error: responseError, refetch: getConfigAIStatus} = useGetPolling({
    url: configAI ? urlBuilders.getConfigAIRunStatus(campaignId, configAI) : undefined,
    interval: 1000
  });  

  React.useEffect(() => {
    if (configAIRunResult?.configai_id) {
      setConfigAI(configAIRunResult.configai_id);
    }
  }, [configAIRunResult]);

  React.useEffect(() => {
    if (!configAI) {
      clearIntervalInstance();
    } else if (data?.run_status !== 'Running') {
      clearIntervalInstance();
      getConfigAIStatus();
    }
  }, [configAI]);

  const updateConfigAIId = (id) => {
    setConfigAIId(id);
    window.localStorage.setItem('configAIId', id);
  };

  React.useEffect(() => {
    if(data?.run_status === 'Success'){
      clearIntervalInstance();
      updateConfigAIId(configAI);
      setConfigAILoading(false);
    }
  }, [data]);

  const resetConfigAI = () => {
    setConfigAI();
    clearIntervalInstance();
    setConfigAILoading(false);
  };

  //Error handling
  React.useEffect(() => {
    if (configAIRunErrors || responseError) {
      setConfigAIError(true);
      resetConfigAI();
    }
  }, [configAIRunErrors, responseError]);

  const startConfigAI = async() => {
    resetConfigAI();
    setConfigAIError(false);
    setConfigAILoading(true);
    const configs = formatValues(configValues);
    DownloadCSVFactory.setReportProperties();
    DownloadCSVFactory.createDownload(CONFIGAI_MEDIA_GROUPING_FILE_TYPE, configs, MEDIA_GROUPING_FILE_NAME);
    const report = DownloadCSVFactory.getDownload(CONFIGAI_MEDIA_GROUPING_FILE_TYPE);
    const blob = report.download(false);
    const fileUploadResponse = (await uploadFileToS3(blob, MEDIA_GROUPING_FILE_NAME)) || {};
    if (fileUploadResponse.error) {
      setConfigAIError(true);
      resetConfigAI();
    } else {
      const payload = {...analysisValues};
      payload["uploadData"] = {
        data:  {
          key: fileUploadResponse,
          dataType: CONFIGAI_MEDIA_GROUPING_FILE_TYPE,
          campaignId
        }
      };
      await runConfigAI({data: payload});
    }
  }
  return {
    startConfigAI,
    configAILoading,
    configAIError
  };
}

const formatValues = (values={}) => {
  if (Object.keys(values).length === 0) {
    return;
  }

  return values['VariableName'].map((name, index) => ({
      'VariableName': name,
      'VariableGroup': values['VariableGroup'][index],
      'Measure': values['Measure'][index],
      'Analyze': values['Analyze'][index]?.toLowerCase(),
      'MemoryDecay': values['MemoryDecay'][index]?.join('/').replace('No', 'no'),
      'Saturation' : values['Saturation'][index]?.join('/').replace('No', 'no'),
      'Data Grouping Required': values['Data Grouping Required'][index]?.toLowerCase()
    })
  );
};
