import {css} from 'emotion';

export const styles = {
    titleContainer: css`
        padding-top: 30px;
    `,
    resendContainer: css`
        display: flex;
        text-align: center;
        color: rgba(255, 255, 255, 0.5) !important;
        font-size: 16px;
        cursor: pointer;
        padding-bottom: 30px;
    `,
    resendContainerEmailSent: css`
        display: flex;
        text-align: center;
        color: rgba(255, 255, 255, 0.5) !important;
        font-size: 16px;
        padding-bottom: 30px;
    `,
    resendMessage: css`
      padding-top: 2px;
      padding-left: 5px;
    `
};
