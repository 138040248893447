import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {SelectLV, mapToOptions} from '../form/select/SelectLV';
import {styles} from './DashboardDatePickerBar.styles';

/**
 * Form within dashboard that contains Date Selector for all Dashboard Tabs
 * {Children} will display when a user clicks the "3 Dots" on the right
 */
const DashboardRegionPickerBar = ({
  isDisabled=false,
  regions,
  defaultRegion,
  setDashboardRegion
}) => {
  const classNames = styles.container;
  const regionOptions = mapToOptions(regions);
  const dashboardRegion = regionOptions.find(option => option.value === defaultRegion);

  useEffect(() => {
    if (setDashboardRegion) {
      setDashboardRegion(defaultRegion);
    }
  }, [defaultRegion]);

  const SelectDashboardRegion = () => {
    if (!setDashboardRegion) {
      return null;
    }
    return (
      <div className={styles.regionContainer}>
        <SelectLV
          label='Region'
          isDark={false}
          width={0}
          isClearable={false}
          options={regionOptions}
          value={dashboardRegion}
          onChange={(region={}) => setDashboardRegion(region.value)}
          isDisabled={isDisabled}
        />
      </div>
    );
  }

  return(
    <div
      data-test='region-picker-dashboard-bar-container'
      className={classNames}>
      <SelectDashboardRegion/>
    </div>
  );
};



DashboardRegionPickerBar.propTypes = {
  /**
   * Will display when the 3 dots are clicked
   */
  children: PropTypes.node
};

export {DashboardRegionPickerBar};
