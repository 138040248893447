import {css} from 'emotion';

export const styles = {
    page: css`
        flex-grow: 1;
    `,
    topBar: css`
        display: flex;
        position: sticky;
        top: 0;
        height: 113px;
        width: 100%;
        margin-left: 0;
        background: #FFFFFF;
        border-radius: 20px 20px 0 0;
    `,
    modelTitle: css`
        font-size: 48px;
        position: relative;
        margin-top: 40px;
        margin-left: 70px;
        line-height: 56.25px;
    `,
    modelTable: css`
        position: relative;
        top: 51px;
        margin-left: auto;
        border-collapse: collapse;
    `,
    separator: css`
        border-left: 1px solid rgba(126, 146, 146, 0.25);
    `,
    modelValues: css`
        font-size: 14px;
        line-height: 16px;
        text-align: left;
        padding-right: 14px;
        padding-left: 14px;
    `,
    buttonContainer: css`
        margin-top: 51px;
        margin-buttom: 12px;
        margin-left: 32px;
        margin-right: 71px;
    `,
    downloadIcon: css`
        height: 36px;
        width: 36px;
        background: #F2F2F2 !important;
        border: 1px solid rgba(126, 146, 146, 0.25);
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24);
        padding: 0 0 2px 0 !important;
        margin-left: 12px !important;
    `,
    navButtons: css`
        display: flex;
        padding-top: 15px;
        margin-bottom: 15px;
        justify-content: center;
    `,
    buttonSeparator: css`
        width: 20px;
    `
};
