import React from 'react';
import PropTypes from 'prop-types';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import {memoryDecays, saturations, measures} from '../../../../js/constants';

import {TextInputLV} from '../../../../components/form/text/TextInputLV';
import {SelectYesNoLV} from '../../../../components/form/select/SelectYesNoLV';
import SelectFieldLV from '../../../../components/form/select/SelectFieldLV';
import MultipleSelectLV from '../../../../components/form/select/MultipleSelectLV';

import {styles} from '../start-new-modal.styles.js';

const maxFieldLength = 250;
class MediaGroupingRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            analyze: this.props.data ? this.props.data.Analyze : 'No'
        };
        this.values = Object.assign({}, this.props.data);
        this.onChange = this.onChange.bind(this);
    }

    onChange(event) {
        const {name, value} = event.target;
        let errorMsg = '';
        let expandedValue = value;
        expandedValue = (name === 'MemoryDecay') && (value === 'All') ? memoryDecays.slice(1, -1) : value;
        expandedValue = (name === 'Saturation') && (value === 'All') ? saturations.slice(1, -1) : expandedValue;

        if (value.length > maxFieldLength) {
            errorMsg = `name cannot exceed ${maxFieldLength} characters`;
        }
        this.props.onMediaGroupingUpdate({variableName: this.props.data['VariableName'], name: name, value: expandedValue.toString().replace(/,/g, '/')});
        if(name === 'Data Grouping Required'){
            this.props.onRequiredGroupingUpdate({variableGroup: this.props.data['VariableGroup'], name: name, value: value});
        }
        const analyze = name === 'Analyze' ? value : this.state.analyze;
        this.setState({
            errors: {
                [name]: {
                'message': errorMsg
                }
            },
            analyze
        });
    }

    render () {
        const {data, row, readOnly, suggestedConfigMap, mediaGroupingErrors} = this.props;
        const measure = data['Measure'];
        const analyze = data['Analyze'];
        const required = data['Data Grouping Required'];

        let decayIsSuggested = false;
        let satIsSuggested = false;
        const variableName = data['VariableName'];
        if(suggestedConfigMap && suggestedConfigMap[variableName]){
            decayIsSuggested = suggestedConfigMap[variableName]['MemoryDecay'] || false;
            satIsSuggested = suggestedConfigMap[variableName]['Saturation'] || false;
        }
        
        let decayValues = data['MemoryDecay'].split('/');
        decayValues = !decayValues.length ? ['No'] : decayValues;

        let saturationValues = data['Saturation'].split('/');
        saturationValues = !saturationValues.length ? ['No'] : saturationValues;

        const disabled = readOnly || this.state.analyze === 'No';
        const selectStyle = disabled ? `${styles.disabledSelect}` : '';
        return (
            <TableRow height={10} className={ row % 2? styles.stripe:''} data-test={'media-grouping-table-row-' + row}>
                <TableCell className={styles.textColumn} title={data['VariableName']} alt={data['VariableName']}> 
                    <TextInputLV name='VariableName' value={data['VariableName']} errors={mediaGroupingErrors[row]}
                        data-test='Variable-Name-Field' disabled={true}
                        width={200}/>
                </TableCell>
                <TableCell className={styles.textColumn}>
                    <TextInputLV name='VariableGroup' defaultValue={data['VariableGroup']} onChange={this.onChange} 
                        errors={mediaGroupingErrors[row] || this.state.errors} 
                        width={100} disabled={readOnly} data-test='Variable-Group-Field'/>
                </TableCell>
                <TableCell className={styles.measureColumn}>
                    <SelectFieldLV name='Measure' value={measure} items={measures}
                        onChange={this.onChange} disabled={readOnly} errors={mediaGroupingErrors[row]} 
                        data-test='Measure-Field'/>
                </TableCell>
                <TableCell className={styles.selectColumn}>
                    <SelectYesNoLV name='Analyze' value={analyze}
                        onChange={this.onChange} errors={mediaGroupingErrors[row]} disabled={readOnly} 
                        data-test='Analyze-Field'/>
                </TableCell>
                <TableCell className={styles.decaySelectColumn}>
                    <MultipleSelectLV name='MemoryDecay' value={decayValues} className={selectStyle}
                        items={memoryDecays} onChange={this.onChange} errors={mediaGroupingErrors[row]} disabled={disabled} isSuggested={decayIsSuggested}
                        data-test='MemoryDecay-Field'/>
                </TableCell>
                <TableCell className={styles.satSelectColumn}>
                    <MultipleSelectLV name='Saturation' value={saturationValues} className={selectStyle}
                        items={saturations} onChange={this.onChange} errors={mediaGroupingErrors[row]} disabled={disabled} isSuggested={satIsSuggested}
                        data-test='Saturation-Field'/>
                </TableCell>
                <TableCell className={styles.selectColumn}>
                    <SelectYesNoLV name='Data Grouping Required' value={required} className={selectStyle} 
                        onChange={this.onChange} errors={mediaGroupingErrors[row]} disabled={disabled} 
                        data-test='Data-Grouping-Required-Field'/>     
                </TableCell>
            </TableRow>
        );
    }
}

MediaGroupingRow.propTypes = {
    data: PropTypes.object,
    row: PropTypes.number,
    readOnly: PropTypes.bool,
    onMediaGroupingUpdate: PropTypes.func,
    mediaGroupingErrors: PropTypes.array
};

export default (MediaGroupingRow);
