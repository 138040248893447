import moment from 'moment';
import {RUN_STATUS} from '../../../constants/mca-run-statuses.js';

/**
 * takes in the run state and a new run state with the selected run set to the most recent run
 * the run must have a status of completed
 * @param {object} state the current run state
 * @return {object} copied paramter state with new selected run
 */
export function setSelectedMcaRunToMostRecentCore(state) {
    if (state.allIds.length === 0) {
        return Object.assign({}, state, {selectedMcaRun: ''});
    }
    let mostRecentRun = null;
    state.allIds.forEach((id, index) => {
        if (!mostRecentRun && state.byId[id].runStatus === RUN_STATUS.COMPLETED)
            mostRecentRun = id;
        else if (
            state.byId[id].runStatus === RUN_STATUS.COMPLETED &&
            moment(state.byId[id].runStartDate).diff(
                moment(state.byId[mostRecentRun].runStartDate)
            ) > 0
        ) {
            mostRecentRun = id;
        }
    });
    return Object.assign({}, state, {selectedMcaRun: mostRecentRun});
}

/**
 * get the run status if the run exists, otherwise return null
 * @param  {object} state the current state
 * @param  {string} id    a run id
 * @return {string}       the given run's status
 */
export function getMcaRunStatusCore(state, id) {
    if (state.byId[id]) return state.byId[id].runStatus;
    else return null;
}
