import React from 'react';
import {
  R1ActionIcon, R1Button
} from '@rainagency/rain-one-soggy-loaf';
import {useUserRoleFromGroup} from "../../../../hooks/useUserRoleFromGroup";
import StartNewModal from "../../../new-analysis/start-new-modal/start-new-modal";
import {Add, Redo} from "@mui/icons-material";
import {useFeatureSelect} from "../../../../hooks/useFeatureSelect";
import {FEATURES} from "../../../../constants/features";

/**
 * Rerun MMM Run action on grid popover
 */
export const RerunAnalysisActionIcon = ({cell}) => 
  <SelectAnalysis runId={cell?.row?.id}/>;

/**
 * Rerun analysis form
 * Display snackbar alert on success or error
 */

const ReRunAction = ({selectedMcaRun, closeModal=()=>{}}) => {
  const { isTrial } = useUserRoleFromGroup();

  if (isTrial) {
    return null;
  }

  let value;
  if (selectedMcaRun) {
    value = {
      name: selectedMcaRun.name,
      kpi: selectedMcaRun.kpi,
      kpiType: selectedMcaRun.kpiType || 'Revenue',
      includeIntercept: selectedMcaRun.includeIntercept,
      timeScale: selectedMcaRun.timeScale || 'DAILY',
      startDate: new Date(selectedMcaRun.startDate + 'T00:00:00'),
      endDate: new Date(selectedMcaRun.endDate + 'T00:00:00')
    };
  }

  return (
    <StartNewModal value={value} selectedMcaRunId={selectedMcaRun.id} closeModal={closeModal}/>
  );
};

const SelectAnalysis = ({runId, isButton}) => {
  const { onFeatureSelect } = useFeatureSelect();

  const clickHandler = () => {
    onFeatureSelect({
      feature: FEATURES.SELECT_ANALYSIS,
      isReRun: true,
      runId
    });
  };

  if (isButton) {
    return (
      <R1Button
        onClick={clickHandler}
        startIcon={<Add/>}
        color='secondary'>
        Rerun Analysis
      </R1Button>
    )
  }

  return (
    <R1ActionIcon
      label='Rerun Analysis'
      icon={Redo}
      onClick={clickHandler} />
  );
}