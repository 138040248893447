import React from 'react';
import {sentenceCase, capitalCase} from 'change-case';
import {CHART_FORMAT} from './ChartFormat.constant';
import {formatChartValue} from './chart-format.util';
import {styles} from './CustomTooltip.styles';

/**
 * Custom tooltip charts
 * http://recharts.org/en-US/api/Tooltip
 * @param active Bool Usually from Recharts, show/hide tooltip
 * @param payload Object array from Recharts containing data to display
 * @param map Our Format map describing what type our columns are
 * @param hiddenColumnMap Hidden columns from legend should be hidden in tooltip
 * @returns Tooltip to display on hover in chart
 */
export const CustomTooltip = ({
  active, payload, map={}, hiddenColumnMap={}
}) => {
  if(!active || !payload) {
    return <div data-test='non-active-tooltip'/>;
  }

  let dataRow = payload[0] ? payload[0].payload : [];
  if(!dataRow) {
    dataRow = [];
  }

  // Pie charts have a double nested payload object
  if(dataRow.payload) {
    dataRow = dataRow.payload;
  }

  // Filter hidden rows
  const dataRowArr = Object.entries(dataRow)
        .filter(([key, value]) => {
          return map[key] !== CHART_FORMAT.HIDDEN;
        })
        .filter(([key, value]) => {
          return !hiddenColumnMap[key];
        });

  const Keys = dataRowArr.map(
    ([key], index) => getKeyCell(key, index, map));
  const Values = dataRowArr.map(
    ([key, value], index) => getValueCell(key, value, index, map)
  );
  return(<div className={styles.box}>
           <div className='tool-key'>{Keys}</div>
           <div className='tool-value'>{Values}</div>
         </div>);
};

/**
 * Key cells should have proper nouns
 */
const getKeyCell = (key, index, map={}) => {
  //rename any custom tooltip keys
  const customKey = map.keys && key in map.keys ? map.keys[key] : key;
  const friendlyName = capitalCase(sentenceCase(customKey));

  return (<div key={index} datakey={key}>{friendlyName}</div>);
};

/**
 * Render values and add $, %, or .00 as needed
 */
const getValueCell = (key, value, index, map={}) => {
  const parsed = formatChartValue(key, value, map) || '';
  return (<div key={index}>{parsed.toString()}</div>);
};
