// actions
export const LOAD_CONFIG = `platform/config/load`;

// selectors
export const getModelLimit = (state) => state.platform.config.MODEL_LIMIT;

export default function configReducer(config = {}, action) {
    switch (action.type) {
        case LOAD_CONFIG:
            return action.config;
        default:
            return config;
    }
}

// action creators
export function loadConfig(config) {
    return {
        type: LOAD_CONFIG,
        config
    };
}
