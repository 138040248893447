import React from 'react';
import {numberWithCommas} from '../../js/utils';
import moment from 'moment';
import {CHART_FORMAT} from './ChartFormat.constant';
import {ReferenceArea} from 'recharts';
import _ from 'lodash';

// Turn off chart animation if row length is greater than
export const MAX_ANIMATION_COUNT = 500;
export const MAX_ANIMATION_KEY_COUNT = 10;

/**
 * Format a chart value based on map
 * Examples: $currency.00, percent%, integer
 * Currency is always default
 * Default to PERCENT% if key contains percent
 */
export const formatChartValue = (key='', value, map={}, isAxis=false) => {
  // Default percent keys
  let defaultMap = {};

  if(key.includes('percent') || key.includes('prct')) {
    defaultMap[key] = CHART_FORMAT.PERCENT;
  }

  if(key.includes('date')) {
    defaultMap[key] = CHART_FORMAT.DATE;
  }

  const combinedMap = {...map, ...defaultMap};
  const parsedNumber = getParsedNumber(key, value, combinedMap, isAxis);
  return getFormattedString(parsedNumber, key, combinedMap);
};

/**
 * Format graph axis using a curry function
 */
export const formatAxis = (key, map) => {
  return tick => {
    return formatChartValue(key, tick, map, true);
  };
};

/**
 * Return a string formatted number with $,% accordingly
 */
const getFormattedString = (number, key, map={}) => {
  if(moment(number, 'YYYY-MM-DD', true).isValid() && map[key] === CHART_FORMAT.DATE) {
    return moment(number).format('MM/DD/YYYY');
  }

  if(Array.isArray(number)){
    const lower = (number[0] < 1 && number[0] > 0) ? number[0] : numberWithCommas(number[0]);
    const upper = (number[1] < 1 && number[1] > 0) ? number[1] : numberWithCommas(number[1]);
    if(map[key] === CHART_FORMAT.CURRENCY) {
      return '$' + lower + ' - $' + upper;
    }

    if(map[key] === CHART_FORMAT.INTEGER) {
      return lower + ' - ' + upper;
    }

    if(map[key] === CHART_FORMAT.INTEGER_CURRENCY) {
      return '$' + numberWithCommas(Math.round(number[0])) + ' - $' + numberWithCommas(Math.round(number[1]));
    }

    // default to dollars
    return '$' + lower + ' - $' + upper;
  }

  if(isNaN(number) || !number) {
    return number;
  }

  // Add commas only if number is not only a decimal
  const parsedNumber = (number < 1 && number > 0) ? number : numberWithCommas(number);

  if(map[key] === CHART_FORMAT.PERCENT) {
    return parsedNumber + '%';
  }

  if(map[key] === CHART_FORMAT.CURRENCY) {
    return '$' + parsedNumber;
  }

  if(map[key] === CHART_FORMAT.INTEGER) {
    return parsedNumber;
  }

  if(map[key] === CHART_FORMAT.INTEGER_CURRENCY) {
    return '$' + numberWithCommas(Math.round(number));
  }

  if(map[key] === CHART_FORMAT.STRING) {
    return number
  }
  
  // default to dollars
  return '$' + parsedNumber;
};

/**
 * Parse a number to percent, commas, or deep decimal
 */
export const getParsedNumber = (key, value, map={}, isAxis=false) => {
  let parsedValue = parseFloat(value);
  const fixedCount = isAxis ? 0 : 2;

  if(Array.isArray(value) && value.length === 2){
    return [parseFloat(value[0]).toFixed(0), parseFloat(value[1]).toFixed(0)];
  }

  if(isNaN(value)) {
    return value;
  }

  // Deep decimals should not round to 0 unless greater depth than 100
  if(Math.abs(parsedValue) < 1 && Math.abs(parsedValue) > 0) {
    let depth = getDecimalDepth(parsedValue);

    if(depth > 99) {
      return 0;
    }
    return parsedValue.toFixed(depth+1);
  }

  if(map[key] === CHART_FORMAT.PERCENT) {
    return parsedValue.toFixed(fixedCount);
  }

  if(map[key] === CHART_FORMAT.INTEGER) {
    return parsedValue.toFixed(0);
  }

  return parsedValue.toFixed(fixedCount);
};

/**
 * Gets the depth of a deep decimal number EX: 0.000003456
 * would return 6
 */
export const getDecimalDepth = (decimal) => {
  let depth = 0;

  if(isNaN(decimal)) {
    return 0;
  }

  // Recursively find the depth of first digit greater than 0
  const findFirstDigits = (deepDecimal) => {
    if(Math.abs(deepDecimal) < 1 && Math.abs(deepDecimal) > 0) {
      ++depth;
      return(findFirstDigits(deepDecimal*10));
    }
    return deepDecimal;
  };
  
  // Run recursive closure to populate depth
  findFirstDigits(decimal);

  return depth;
};

/**
 * Guess the character length of x-axis values
 * Can be used to get character count of y axis entries to align label
 * @param data dataset to inspect and get max length of numeric fields from
 * @param xKey Field to disregard, often the x axis key, which should noy be in count
 * @return max count of numeric fields
 */
export const getMaxAxisLength = (data=[], xKey) => {
  if(data.length < 1) {
    return 0;
  }
  const firstEntry = data[0];
  const numericKeys = Object
        .keys(firstEntry)
        .filter(key => {
          if(key === xKey) {
            return false;
          }
          return !isNaN(firstEntry[key]);
        });


  const charLengths = data.map(row => {
    return _.max(numericKeys.map(key => {
      const value = getParsedNumber('', row[key], {}, true);

      if (!value) {
        return 0;
      }

      // Long decimals below 1 can obscure character count
      if(!isNaN(value) && (value < 1 || value < -1)) {
        return 0;
      }

      return value.toString().length;

    }));
  });

  const max = _.max(charLengths);

  if(!max) {
    return 0;
  }

  return max;
};

/**
 * Return the offset for the label of the Y Axis
 * Get the estimates Y character count and return a
 * margin offset for the label
 * @param data dataset to inspect and get max length of numeric fields from
 * @param offset base offset to add
 * @param xKey Field to disregard, often the x axis key, which should noy be in count
 * @returns expected margin for y axis label
 */
export const getYAxisLabelOffset = (data=[], offset=0, xKey) => {
  const maxYAxisLength = getMaxAxisLength(data, xKey);
  return (maxYAxisLength * 2 + offset) * -1;
};

/**
 * Return the offset for the label of the X Axis
 * Get the estimates X character count and return a
 * margin offset for the label
 * @param data dataset to inspect and get max length of numeric fields from
 * @param offset base offset to add
 * @param yKey Field to disregard, often the y axis key, which should noy be in count
 * @returns expected margin for x axis label
 */
export const getXAxisLabelOffset = (data=[], offset=0, yKey) => {
  const maxXAxisLength = getMaxAxisLength(data, yKey);
  return (maxXAxisLength * 2 + offset);
};

/**
 * Return a list of chart ReferenceAreas
 * http://recharts.org/en-US/api/ReferenceArea
 * @params referenceAreaConfigs Object array defining area information
 *         {
 *           key: 'Saturation', // Optional reference to the row
 *           value: 123, // Numeric value for x-axis
 *           y1: 0, // y coord to start box | undefined to start from beginning
 *           y2: 0, // y coord to end box | undefined to go to end
 *           x1: 0, // x coord to start box | undefined to start from beginning
 *           x2: 0, // x coord to end box | undefined to go to end
 *           color: '#2A5588', // Hex color to display
 *           label: 'Saturation', // Text to display to User in chart
 *         }
 * @params hiddenColumnMap Map of hidden columns EX: {col1: true, col2: false}
 * @returns List of [<ReferenceArea/>]
 */
export const getReferenceAreas = (referenceAreaConfigs=[], hiddenColumnMap={}) => {
  return referenceAreaConfigs.map(({y1, y2, x1, x2, color, key, label}, index) => {
    let startY = y1 ? Number(y1) : undefined;
    const endY = y2 ? Number(y2) : undefined;
    const columnKey = key || label;
    const isHidden = hiddenColumnMap[columnKey];

    // Minimum start is 0
    if(index === 0 && startY > 0) {
      startY = 0;
    }
    
    return (
      <ReferenceArea
        y1={startY}
        y2={endY}
        x1={x1}
        x2={x2}
        fill={color}
        key={index}
        fillOpacity={isHidden ? 0 : 1}
        hide={true}
      />
    );
  });
  
};

/**
 * Determine the slope on a graph between 2 points
 * FORMULA: (y2 - y1)/(x2 - x1) = slope
 * https://www.mathplanet.com/education/pre-algebra/graphing-and-functions/the-slope-of-a-linear-function
 * @param y1 The "previous" coordinate on the y axis
 * @param y2 The "next" coordinate on the y axis
 * @param x1 The "previous" coordinate on the x axis
 * @param x2 The "next" coordinate on the x axis
 * @returns The calculated slope of the line between x and y
 */
export const calculateSlope = (y1, y2, x1, x2) => {
  if([y1, y2, x1, x2].some(coor => isNaN(coor))) {
    return 0;
  }

  const rise = y2 - y1;
  const run = x2 - x1;
  if(run === 0) {
    return 0;
  }

  return rise/run;
};

/**
 * Determine the y-axis on a chart using the point-slope equation
 * y = (slope * (x - xNext)) + yNext;
 * https://www.mathsisfun.com/algebra/line-equation-point-slope.html
 * @param xPrev The "previous" coordinate on the x axis
 * @param x The current known coordinate on the x axis
 * @param xNext The "next" coordinate on the x axis
 * @param yPrev The "previous" coordinate on the y axis
 * @param yNext The "next" coordinate on the y axis
 * @returns The y-axis value
 */
export const calculateYAxis = (xPrev, x, xNext, yPrev, yNext) => {
  if([xPrev, x, xNext, yPrev, yNext].some(coor => isNaN(coor))) {
    return undefined;
  }
  const slope = calculateSlope(yPrev, yNext, xPrev, xNext);
  return (slope * (x - xNext)) + yNext;
};

/**
 * Get previos and next coordinates from a dataset for calculating slope and y-intercept
 * Also get mix/max y values in a dataset
 * @param data Object array dataset to sort/search
 * @param xKnown Known number to retrieve Previous and Next coordinates for
 * @param xKey property that represents the x-axis key
 * @param yKey property that represents the y-axis key
 * @returns Object with x,y,min,max points
 */
export const getYAxisFromData = (data=[], xKnown=0, xKey, yKey) => {
  if(!Array.isArray(data) || !data.length) {
    return undefined;
  }

  const schema = data[0];
  if([schema[xKey], schema[yKey]].some(s => s === undefined)) {
    return undefined;
  }

  const sortedSetByY = _.sortBy(data, [yKey]);
  let nextEntry = sortedSetByY.reverse().find(row => isInRange(row[xKey], xKnown)) || {};
  const prevEntry = sortedSetByY.find(row => isInRange(xKnown, row[xKey])) || {};

  const coords = {
    xPrev: prevEntry[xKey],
    x: xKnown,
    xNext: nextEntry[xKey],
    yPrev: prevEntry[yKey],
    yNext: nextEntry[yKey]
  };

  return calculateYAxis(coords.xPrev, coords.x, coords.xNext, coords.yPrev, coords.yNext);
};

const isInRange = (value=0, compareVal=0) => {
  return (value > compareVal);
};

/**
 * Determine if a chart should be animated by checking data against thresholds
 * @param data Chart Data
 * @param hide turn off animation
 * @returns Boolean if animation should be displayed 
 */
export const getIsAnimationActive = (data=[], hide) => {
  if(hide || !data.length || data.length > MAX_ANIMATION_COUNT){
    return false;
  }
  
  const columnsObj = data[0] || {};
  const keys = Object.entries(columnsObj);
  return keys.length <= MAX_ANIMATION_KEY_COUNT;
};
