import React from 'react';
import {useDelete} from '../../hooks/useRest';
import {ButtonLV} from '../../components/form/buttons/ButtonLV';
import {GreyButtonLV} from '../../components/form/buttons/GreyButtonLV';
import {OrangeButtonLV} from '../../components/form/buttons/OrangeButtonLV';
import {FormRowLV} from '../../components/form/row/FormRowLV';
import {TitleLV} from '../../components/form/title/TitleLV';
import {ModalLV} from '../../components/modal/ModalLV';
import {SuccessFailStatusModal} from '../../components/modal/SuccessFailStatusModal';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {styles} from './useDeleteCampaign.styles';

export const useDeleteCampaign = ({
  deleteUrl,
  title,
  type,
  name,
  cancelClick=()=>{}
}) => {

  const [responseMessage, setResponseMessage] = React.useState({});
  const [{
    loading, error, response={}
  }, callDelete] = useDelete({url: deleteUrl});

  React.useEffect(() => {
    if(loading) {
      return;
    }

    if(error) {
      setResponseMessage({
        isError: true,
        title: `${type} Not Deleted`,
        text: `${name} failed to be deleted.`
      });
    }

    if(response.status === 204) {
      setResponseMessage({
        title: `${type} Deleted`,
        text: `${name} has been successfully deleted.`
      });
    }


  }, [response.status, loading, error, setResponseMessage, title, type, name]);

  return {
    DeleteCampaignModal: <DeleteCampaignModal
                           deleteUrl={deleteUrl}
                           title={title}
                           name={name}
                           callDelete={callDelete}
                         />,
    DeleteSuccessFailMessage: <DeleteSuccessFailMessage
                                message={responseMessage}
                                cancelClick={cancelClick}
                              />
  };
};

export const DeleteCampaignModal = ({
  title,
  name,
  callDelete=()=>{}
}) => {
  return (
    <ModalLV
      isDark={false}
      minWidth={350}
      minHeight={300}>

      <DeleteCampaignLabel
        title={title}
      />
      
      <DeleteCampaign
        callDelete={callDelete}
        title={title}
        name={name}
      />
    </ModalLV>
  );
};

export const DeleteCampaign = ({
  closeModal=()=>{},
  callDelete=()=>{},
  title,
  name
}) => {

  return (
    <div className={styles.form}>

      <TitleLV center={true}>{title}</TitleLV>

      <FormRowLV center={true}>
        <div className='delete-text'>
          Are you sure you want to delete {name}?
          All data, and campaigns will be deleted forever.
        </div>
      </FormRowLV>

      <FormRowLV center={true}>
        <OrangeButtonLV
          dataTest='delete-forever'
          onClick={callDelete}>
          Delete Forever
        </OrangeButtonLV>

        <GreyButtonLV
          className={styles.greyButton}
          onClick={closeModal}
          dataTest='addNewComplete-closeButton'
          width={100}>
          Cancel
        </GreyButtonLV>
      </FormRowLV>        
    </div>
  );
};

export const DeleteCampaignLabel = ({title, onClick=()=>{}}) => {
  return (
    <div onClick={onClick} className={styles.label}>
      <DeleteForeverIcon />
      <span data-test='editModal-deleteButton'>
        {title}
      </span>
    </div>
  );
};

export const DeleteSuccessFailMessage = ({
  message={},
  cancelClick=()=>{}
}) => {
  if(!message.title) {
    return null;
  }

  return (
    <SuccessFailStatusModal
      isError={message.isError}
      title={<div data-test='addNew-title'>{message.title}</div>}
      description={
        <div
          data-test='editModal-successfulDeleteMessage'>
          {message.text}
        </div>
      }>

      <FormRowLV>
        <ButtonLV
          dataTest='delete-close-modal'
          onClick={cancelClick}>
          Close
        </ButtonLV>
      </FormRowLV>

    </SuccessFailStatusModal>
  );
};
