import {css} from 'emotion';

export const styles = {
    navButton: css`
        min-width: 72px;
        width: 72px;
        height: 44px;
        background: #304658;
    `
};
