import {css} from 'emotion';

export const styles = {
    title: css`
        font-size: 34px;
        line-height: 40px;
        margin-bottom: 31px;
        margin-top: 43px;
    `,
    cssGridBlock: css`
        width: 24px;
        display: inline-block;
    `,
    formGroup: css`
        display: flex;
    `,
    formField: css`
        input {
            display: inline-block;
            position: relative;
            height: 27px;
            z-index: 2;
            background: transparent;
            &::placeholder {
              color: #8E9EA5;
              opacity: 1;
            }
        };

        label {
            display: inline-block;
            position: relative;
            z-index: 1;
            transition: all 0.5s ease-in-out; 
            font-size: 12px;
            top: 14px;
        };

        input:focus + label {
            font-size: 9px;
            top: 0;
        };

        input:not(:placeholder-shown) + label {
            font-size: 9px;
            top: 0;
        };

        div[id=field-description] {
            font-size: 12px;
        };
    `,
    paddedError: css`
        div[id=error-message] {
            padding-top: 16px !important;
            text-align: right !important;
        }
    `,
    interceptField: css`
        margin-top: 24px;
    `,
    buttonContainer: css`
        margin-top: 2.5em;
        padding-bottom: 30px;
    `,
    narrowResultsButton: css`
        color: #FFFFFF !important;
        background-color: #FF7762 !important;
        border-radius: 200px !important;
        width: 192px !important;
        height: 36px !important;
    `,
    narrowResultsButtonDisabled: css`
        width: 192px !important;
        height: 36px !important;
`,
    viewModelsButton: css`
        color: black !important;
        background-color: #F5F2EF !important;
        border-radius: 200px !important;
        width: 144px !important;
        height: 36px !important;
    `,
    viewModelsButtonDisabled: css`
        width: 144px !important;
        height: 36px !important;
    `,
    innerField: css`
        font-family: 'Roboto', sans-serif;
        padding: 0 0 16px 16px;
        div[id=error-message] {
            padding-top: 16px !important;
        }
    `,
    modelChipContainer: css`
        display: block;
    `,
    modelsChip: css`
        margin-top: 16px;
        margin-left: 16px;
        background-color: rgba(255, 255, 255, 0.5) !important;
    `,
    fieldBox: css`
        display: block;
    `,
    addButton: css`
        background: #a9d980;
        min-width: 68px;
        color: #FFF;
        outline: none;
        z-index: 10;
    `,
    downloadContainer: css`
        display: flex;
        text-align: center;
        color: rgba(255, 255, 255, 0.5) !important;
        font-size: 12px;
        cursor: pointer;
        padding-bottom: 20px;
    `,
    groupStyle: css`
        margin-top: 20px !important;
        padding-bottom: 36px;
    `,
    selectStyle: css`
        top: -10px;
        width: 170px !important;
        text-align: center !important;
    `,
    groupTitle: css`
        font-size: 12px;
        width: 80px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    `,
    inputField: css`
        margin-top: -26px;
    `
};
