import React from 'react';
import moment from 'moment';
import {filterSetDateRange} from "../../js/utils";

const DashboardContext = React.createContext();

/**
 * Context for Dashboard events/actions
 * Used for variables that must be persisted for all dashboards
 */
function DashboardProvider({children}) {
  const [dashboardStartDate, setDashboardStartDate] = React.useState();
  const [dashboardEndDate, setDashboardEndDate] = React.useState();
  const [dashboardRegion, setDashboardRegion] = React.useState();
  const [insightMetadata, setInsightMetadata] = React.useState();

  /**
   * Filter a data set by the currently selected dashboard date range
   * @param dataSet Array of objects
   * @param startKey Optional key in data row representing start date
   * @param endKey Optional key in data row representing end date
   * @param dataSet Array of objects
   * @param dateKey Optional key to represent date
   * @return Filtered object array that lies within date range
   */
  const filterByDateRange = (dataSet, dateKey) => {
    return filterSetDateRange(
      dataSet,
      dashboardStartDate,
      dashboardEndDate,
      dateKey
    );
  };

  const filterByRegion = (dataSet=[]) => {
    if (!dataSet.length || !dashboardRegion) {
      return dataSet;
    }
    //find all region keys, some rows in dataset may not have region (roi)
    const regionRows =  dataSet.filter(data => Object.keys(data).find(key => key.toLowerCase() === 'region'));
    if (!regionRows.length) {
      return dataSet;
    }

    const regionKey = Object.keys(regionRows[0]).find(key => key.toLowerCase() === 'region');
    return dataSet.filter(data => (data[regionKey] === dashboardRegion) || !data[regionKey]);
  }

  return (
    <DashboardContext.Provider
      value={{
        setDashboardStartDate,
        dashboardStartDate,
        setDashboardEndDate,
        dashboardEndDate,
        filterByDateRange,
        setDashboardRegion,
        dashboardRegion,
        filterByRegion,
        setInsightMetadata,
        insightMetadata
      }}>

      {children}

    </DashboardContext.Provider>
  );
  
}

export {DashboardProvider, DashboardContext};

