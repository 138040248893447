import {css} from 'emotion';
import {commonStyles} from '../../styles/commonStyles';

export const styles = {
  container: css`
     height: 70px;
     border-top: 8px solid #F2F2F2;
     border-bottom: 4px solid #F2F2F2;
     background: #FFF;
     display: flex;
     ${commonStyles.fontFamily}
     
     &.read-only {
       .dependentVariable, .rsquare, .mape, .confidence {
         display: none;
       }
     }
  `,
  regionContainer: css`
     margin-left: 100px;
     min-width: 146px;
     display: flex;
     align-items: center;
     padding-right: 24px;
  `,
  formContainer: css`
     padding: 12px 0;
     display: flex;
     margin-right: 0;
     margin-left: auto;
  `,
  rSquareBox: css`
     padding: 0 24px;
     border-right: 1px solid #F2F2F2;
     align-self: center;
 `,
  mapeBox: css`
     padding: 0 24px;
     border-right: 1px solid #F2F2F2;
     align-self: center;
  `,
  confidenceBox: css`
     padding: 0 24px;
     align-self: center;
  `,
  label: css`
     font-size: 11px;
     margin-top: 5px;
     white-space: nowrap;
  `,
  dependentVariable: css`
     font-size: 14px;
     padding: 0 18px;
     border-right: 1px solid #F2F2F2;
     align-self: center;
 `,
  percent: css`
     font-size: 14px;
     text-transform: uppercase;
  `,
  dateContainer: css`
     display: flex;
     width: 155px;
     justify-content: space-between;
     margin: 0 60px 0 18px;
  `,
  dots: css`
     display: flex;
     margin: 0;
     margin-left: 60px;
     margin: 0 60px 0 60px;
     justify-content: space-between;
     #three-dot-image {
       cursor: pointer;
       width: 8px;
       height: 46px;
     }
     #dots-fade-container {
       display: flex;
       align-items: center;
       justify-content: center;
     }
     #dots-content {
       margin-right: 16px;
     }
  `,
};
