import React from 'react';
import {SignIn} from 'aws-amplify-react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {LogoLV} from './components/LogoLV';
import topDots from './assets/top_left_dots.svg';
import bottomDots from './assets/bottom_right_dots.svg';
import {styles} from './signin.styles.js';
import { NAVIGATION_OPEN_STATE } from './components/layout/ApplicationLayout';

export class CustomSignIn extends SignIn {
    constructor(props) {
        super(props);
        this._validAuthStates = ["signIn", "signedOut", "signedUp"];
        this.handleFormSubmission = this.handleFormSubmission.bind(this);
    }
    handleFormSubmission(evt) {
        evt.preventDefault();
        window.sessionStorage.setItem("trialMessage", "show");
        window.localStorage.setItem(NAVIGATION_OPEN_STATE, 'true');
        this.signIn();
    }
    showComponent(theme) {
        return (
            <div className={styles.loginContainer}>
                <img className={styles.topDots} src={topDots} alt="top dots" />
                <form className={styles.formContainer}>
                  <LogoLV
                    className={styles.logo}
                    isLarge={true}
                  />
                    <TextField
                        id="username"
                        key="username"
                        name="username"
                        label="Username"
                        placeholder="Username"
                        type="text"
                        InputProps={{
                            className: styles.lightField
                        }}
                        InputLabelProps={{
                            className: styles.lightField,
                            shrink: true
                        }}
                        autoFocus
                        onChange={this.handleInputChange}
                    />
                    <TextField
                        id="password"
                        key="password"
                        name="password"
                        label="Password"
                        placeholder="******************"
                        type="password"
                        InputProps={{
                            className: styles.lightField
                        }}
                        InputLabelProps={{
                            className: styles.lightField,
                            shrink: true
                        }}
                        onChange={this.handleInputChange}
                    />

                    <Button
                        className={styles.submitButton}
                        onClick={this.handleFormSubmission}
                        type='submit'>
                        {' '}
                        Login{' '}
                    </Button>
                    <p className={styles.passwordForgotLink}>
                        <span onClick={() => super.changeState('forgotPassword')}>
                            Forgot Password?
                        </span>
                    </p>
                </form>
                <img
                    className={styles.bottomDots}
                    src={bottomDots}
                    alt="bottom dots"
                />
            </div>
        );
    }
}
