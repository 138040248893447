import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import withHTTP from '../hoc/with-http';

import {FETCH_ALL_PARTNERS} from '../modules/platform/partner.js';

import {getSelectedPartner, setSelectedPartner} from '../modules/platform/partner.js';
import {getSelectedClient, setSelectedClient} from '../modules/platform/client.js';
import {getSelectedCampaign, setSelectedCampaign} from '../modules/platform/campaign.js';
import {getSelectedMcaRun, setSelectedMcaRun} from '../modules/mca/run.js';
import {getSelectedModelId, setSelectedModel, FETCH_SELECTED_MODEL} from '../modules/mca/models.js';

/**
 * checks that certain keys in the redux store of truthy values, if they are falsy, then fetch whatever is in session storage
 * for that key
 * @extends React
 */
class Persistance extends React.Component {

    constructor(props) {
        super(props);

        this.getFromSessionStorage = this.getFromSessionStorage.bind(this);

        const {selectedPartner, selectedClient, selectedCampaign, selectedRun, selectedModel, httpCall} = props;
        selectedPartner || this.getFromSessionStorage('platform/partner/selectedPartner', setSelectedPartner);
        selectedClient || this.getFromSessionStorage('platform/client/selectedClient', setSelectedClient);
        selectedCampaign || this.getFromSessionStorage('platform/campaign/selectedCampaign', setSelectedCampaign);
        
        // We only want to keep selected run if we are on the dashboard page.
        let newSelectedRun;
        const refreshedPath = props.location.pathname;
        if (refreshedPath === '/campaign/dashboards') {
            newSelectedRun = selectedRun || this.getFromSessionStorage('mca/run/selectedMcaRun', setSelectedMcaRun);
        }
        
        const newSelectedModel = selectedModel || this.getFromSessionStorage('mca/model/selectedModel', setSelectedModel);

        // parnter, clients, and campaigns are used on multiple pages, so they are being loaded in
        // at the start so each page has what it needs
        httpCall(
            FETCH_ALL_PARTNERS
        );

        // if selectedModel was retrieved, dispatch an action to retreive that model, also requires there to be a selected run
        if (selectedModel !== newSelectedModel && newSelectedRun && newSelectedRun !== 'null') {
            this.props.httpCall(
                FETCH_SELECTED_MODEL,
                {},
                {mcaRunId: newSelectedRun}
            )
        }
    }

    /**
     * given a key and an action creator, fetch from session storage what is at the key,
     * give it to the action creator, and then return the value
     * @param  {string} key           location to retrieve from in session storage
     * @param  {function} actionCreator action creator to put retrieved value in store
     * @return {string}               the retrieved value
     */
    getFromSessionStorage (key, actionCreator) {
        const retrievedValue = sessionStorage.getItem(key);
        this.props.dispatch(actionCreator(retrievedValue));
        return retrievedValue;
    }

    render() {
        return (
            <React.Fragment>
                {this.props.children}
            </React.Fragment>
        );
    }
};

Persistance.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
        PropTypes.element,
        PropTypes.node
    ]),
    selectedPartner: PropTypes.string,
    selectedClient: PropTypes.string,
    selectedCampaign: PropTypes.string,
    selectedRun: PropTypes.string,
    selectedModel: PropTypes.string,
    dispatch: PropTypes.func,
    httpCall: PropTypes.func
};

const mapStateToProps = state => ({
    selectedPartner: getSelectedPartner(state),
    selectedClient: getSelectedClient(state),
    selectedCampaign: getSelectedCampaign(state),
    selectedRun: getSelectedMcaRun(state),
    selectedModel: getSelectedModelId(state)
});

export default withRouter(withHTTP(connect(mapStateToProps)(Persistance)));
