import React from 'react';
import {getStyles} from '../text/TextInputLV.styles';
import PropTypes from 'prop-types';

export const DEFAULT_REQUIRED_MESSAGE = 'This field is required.';

/**
 * Form text input field for Leavened
 */
export const FieldErrorLV = (props) => {
  
  const {dirty, name, errors} = props;
  const message = getErrorMessage(name, errors);
  const isError = message && (dirty === true || dirty === undefined);
  const styles = getStyles(props);
  const className = isError ? styles.error : styles.hidden;

  return (
    <div id='error-message' className={className}>
      {message}
    </div>
  );
};

/**
 * Determine message from react hook form errors
 * Return a generic error if none is provided
 * https://react-hook-form.com/api#errors
 * @param name name of parent input field
 * @param errors react-hook-form error object
 * @returns error message
 */
export const getErrorMessage = (name, errors) => {
  if(!errors || !errors[name]){
    return undefined;
  }

  let message = errors[name].message;
  if(!message && errors[name].type === 'required') {
    message = DEFAULT_REQUIRED_MESSAGE;
  }
  return message;
};

FieldErrorLV.propTypes = {
  /**
   * Field name which will be key for form errors
   */
  name: PropTypes.string,
  /**
   * All form errors from parent form
   * https://react-hook-form.com/api#errors
   */
  errors: PropTypes.object,
  /**
   * If parent form has been touched
   */
  dirty: PropTypes.bool
};
