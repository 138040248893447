import React from 'react';
import {CombinedSummaryHeader} from './tables/CombinedSummaryHeader';
import {BarChartRow} from './BarChartRow';
import {ComparisonTable} from './tables/ComparisonTable';
import {FormRowLV} from '../../components/form/row/FormRowLV';
import {styles} from './InputScenariosReport.styles';
import {sortedMappedScenarioData} from "./calcAndMapScenarioData";

export const InputScenariosReport = ({
  data=[],
  groupMap={},
  loading=false,
  isSubmitted=true,
  kpiType,
  timeScale,
  nestedKpis=[]
}) => {

  if(!isSubmitted) {
    return null;
  }

  const hasData = (Array.isArray(data) && data.length > 0);
  const mappedData = React.useMemo(
    () => sortedMappedScenarioData(data, nestedKpis, groupMap, kpiType), [data, nestedKpis, groupMap, kpiType]
  );

  return (
  	<div
      style={{
        marginTop: '-10px'
      }}
      className={styles.content}>

      <FormRowLV expand={true}>
        <CombinedSummaryHeader
          data={mappedData}
          kpiType={kpiType}
          hasData={hasData}
          loading={loading}
          timeScale={timeScale}
        />
      </FormRowLV>

      <BarChartRow
        data={mappedData}
        kpiType={kpiType}
        loading={loading}
      />

      <FormRowLV expand={true}>
        <ComparisonTable
          data={mappedData}
          kpiType={kpiType}
          hasData={hasData}
          loading={loading}
          timeScale={timeScale}
          nestedKpis={nestedKpis}
        />
      </FormRowLV>

    </div>
  );
};