import React from 'react';
import {TableWithBorder} from '../../../components/data-table/TableWithBorder';
import {EditScenarioCell} from '../cells/EditScenarioCell';
import {getDummyData} from '../../../components/data-table/getDummyData';
import {ObservedRange} from "../cells/ObservedRange";

export const ControlVariableTable = ({
  data=[],
  loading=false,
  errors,
  register=()=>{},
  selectedRegion
}) => {

  let control = React.useMemo(()=> (
    data
      .filter(d=>d.measure === 'Control')
      .map(d=>({
        variableName: d.variableName,
        variableTactic: d.tactic,
        observedRange: '',
        minObserved: d.minObserved,
        meanObserved: Math.floor(d.meanValue),
        median: Math.floor(d.median),
        maxObserved: d.maxObserved,
        scenario1: d.scenario1Value,
        scenario2: d.scenario2Value
      }))
  ), [data]);

  const hasData = !!control.length;

  if(!control.length) {
    control = getDummyData(dummyControlSchema, 4);
  }

  const override = {
    variableName: {
      hidden: true
    },
    variableTactic: {
      name: 'Variable',
    },
    observedRange: {
      name: 'Observed Range',
      hidden: selectedRegion === "ALL REGIONS",
      component: <ObservedRange/>
    },
    minObserved: {
      hidden: true
    },
    maxObserved: {
      hidden: true
    },
    meanObserved: {
      name: 'Observed Mean',
      hidden: selectedRegion === "ALL REGIONS"
    },
    median: {
      name: 'Observed Median',
      hidden: selectedRegion === "ALL REGIONS"
    },
    scenario1: {
      name: 'Scenario 1',
      component:(
        <EditScenarioCell
          nameSuffix='_scenario1'
          isValidateObservedRange={false}
          isCurrency={false}
          errors={errors}
          register={register}
          style={{
            width: '120px'
          }}
        />)
    },
    scenario2: {
      name: 'Scenario 2',
      component:(
        <EditScenarioCell
          nameSuffix='_scenario2'
          isValidateObservedRange={false}
          isCurrency={false}
          errors={errors}
          register={register}
          style={{
            width: '120px'
          }}
        />)
    }
  };

  return (
    <TableWithBorder
      data={control}
      hasData={hasData}
      isLoading={loading}
      override={override}
      disableTableSort={true}
      dataTest='control-based'
      title='Control Variables'
    />
  );
};

const dummyControlSchema = {
  variableName: '',
  variableTactic: '',
  minObserved: '',
  maxObserved: '',
  meanObserved: '',
  median: '',
  scenario1: '',
  scenario2: ''
};
