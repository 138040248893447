export const RUN_STATUS = {
    COMPLETED: 'Completed',
    PROCESSING: 'Processing',
    SELECT_MODEL: 'Select Model',
    ERROR: 'ERROR',
    ERRORS: 'Errors',
    CANCELLED: 'Canceled',
    FINALIZING: 'Finalizing',
    UNKNOWN: 'Unknown',
    RUNNING: 'RUNNING',
    SUCCESS: 'SUCCESS',
    WARNING: 'WARNING',
    PENDING: 'PENDING',
    FAILED: 'FAILED',
    COMPLETE: 'COMPLETE',
    SCORING_ERROR: 'Scoring Errors',
    INITIATED: 'Initiated'
};
