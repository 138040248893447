import React, {useState} from 'react';

import Chip from '@mui/material/Chip';

import {SelectLV, mapToOptions} from './SelectLV';
import {GreenButtonLV} from '../buttons/GreenButtonLV';
import {CheckboxLV} from '../CheckboxLV';

import {styles, customStyle} from './SelectChipsLV.styles';

export default function SelectChipsLV(props) {
    const {
        name, 
        label, 
        checked,
        disabled,
        placeholder,
        options,
        values,
        rules,
        errors,
        control,
        reset,
        updateValues,
        dataTest
    } = props;

    const [checkbox, setCheckbox] = useState(checked); 
    const [selections, setSelections] = useState(values || []);
    const [selectedValue, setSelectedValue] = useState('');

    const addSelection = () => {
        let newSelections = selections.slice();
        if (selectedValue === 'all') {
            newSelections = selections.concat(options);
        } else {
            newSelections.push(selectedValue);
        }
        
        const uniqueSelections = newSelections.filter(
            (variable, index, self) => self.indexOf(variable) === index);
            
        updateValues(uniqueSelections);
        setSelections(uniqueSelections);
        setSelectedValue('');
        reset();
    }

    const deleteSelection = (value) => {
        const newSelections = selections.filter(item => item !== value);
        updateValues(newSelections);
        setSelections(newSelections);
        setSelectedValue('');
        reset();
    }

    let optionsList = options.map(item => {
        return {
            value: item, 
            label: <div>{item}</div>
        };
    });
    optionsList.unshift(
        {value: 'all', label: <div className={styles.italicMenuItem}>Select All</div>});
    
    const fullOptions = mapToOptions(optionsList, 'value', 'label');
    const selectField = checkbox ?
        <div className={styles.fieldBox}>
            <div className={styles.selectFieldContainer}>
                <SelectLV
                    data-test='select-field'
                    className={styles.selectField} 
                    name={name}
                    styles={customStyle}
                    placeholder={placeholder} 
                    disabled={!optionsList.length || disabled}
                    onChange={({value}) => setSelectedValue(value)}
                    value={selectedValue}
                    options={fullOptions}
                    rules={rules}
                    errors={errors}
                    control={control}
                />
            </div>
            <GreenButtonLV 
                data-test='add-button'
                dataTest={name + '-add-button'}
                className={styles.addButton} 
                isDisabled={!selectedValue || !optionsList.length || disabled} 
                onClick={()=>addSelection()}>
                    Add
            </GreenButtonLV>
        </div> : null;

    const chips = checkbox && selections.length ? 
        <div className={styles.modelChipContainer}>
            {selections.map((item, index) => 
                <Chip  data-test='value-chip' 
                    className={styles.modelsChip} 
                    key={index} 
                    disabled={disabled}
                    label={item} 
                    onDelete={() => deleteSelection(item)} 
                />
            )}
        </div> : null;
        
    const onChange = event => {
        const value = event.target.checked;
        if (value) {
            updateValues(selections);
        }
        setCheckbox(value);
    }

    return (
        <div>
            <CheckboxLV 
                data-test='checkbox'
                dataTest={dataTest}
                name={name} 
                disabled={disabled}
                label={label} 
                checked={checkbox}
                onChange={event => onChange(event)}
                darkTheme={true}
            />
            <div>
                {selectField}
                {chips}
            </div>
        </div>
    );
}

export {SelectChipsLV}
