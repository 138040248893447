import {css} from 'emotion';

export const styles = {
    page: css`
        background-color: #F4F4F4;
        overflow-y: scroll !important;
        height: 100%;
    `,
    stickyHeader: css`
        position: sticky;
        top: 0;
        z-index: 4;
        background: white;
        border-bottom: 1px solid #d8d8d8;
    `,
    spinnerContainer: css`
        display: flex;
        width: 100vw;
        padding-top: 100px;
        justify-content: center;
    `,
    headerContainer: css`
        display: flex;
        font-size: 16px;
        padding-top: 1em;
        color: #7e9292;
    `,
    tabsHeader: css`
        justify-content: space-between;
    `,
    tabContainer: css`
        position: relative;
        min-height: 100vh;
        background: #f6f6f6;
        padding-top: 2em;
    `,
    menuContainer: css`
        font-size: 14px;
        min-width: 200px;
        max-height: 40px;
    `,
    modelTable: css`
        position: absolute;
        right: 0;
        margin-top: 0.25em;
        margin-bottom: 1em;
        border-collapse: collapse;
        min-width 200px;
        height: 36px;
    `,
    modelValues: css`
        font-size: 16px;
        color: #374f63;
        text-align: left;
        vertical-align: top;
        padding-left: 0.98em;
        border-left: 1px solid rgba(126, 146, 146, 0.25);
    `,
    modelHeader: css`
        font-size: 12px;
        color: #7e9292;
        text-align: left;
        padding-left: 0.9em;
        border-left: 1px solid rgba(126, 146, 146, 0.25);
    `,
    imageHeader: `
        text-align: center;
        font-weight: bold;
        font-size: 16px;
        padding: 24px 0 42px 0;
    `,
    rangeHeader: `
        text-align: center;
        font-size: 12px;
        line-height: 24px;
    `,
    supersetDashboard: css`
        height: 100%
    `
};

export const notCompletedStyles = {
    wrapper: css`
        padding: 2em;
        width: 80vw;
        border-radius: 15px !important;
        margin: auto;
    `
};
