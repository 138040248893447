import React from 'react';
import {SelectLV} from '../../components/form/select/SelectLV';
import {useCampaignManagement} from '../../hooks/useCampaignManagement';
import {useCampaign} from '../../hooks/useCampaign';
import {useClient} from '../../hooks/useClient';
import {useMcaRuns} from '../../hooks/useMcaRuns';
import useOutputsInsights from './useOutputsInsights';
import {usePost, urlWithDomain} from '../../hooks/useRest';
import {urlBuilders} from '../../js/constants';
import {styles} from './SelectedModelDownloadDropdown.styles';
import downloadCsv from '../../assets/download-csv-gray.svg';
import {downloadFromURL} from '../../js/download';

const FIT = 'fit';
const FIT_MISS = 'fit-miss';
const FOLD = 'fold';
const ROI = 'roi';
const OPTIMAL_ALLOCATION = 'optimal-allocation';
const THRESHOLDS = 'thresholds';
const EFFICIENCY = 'efficiency';
const SATURATION = 'saturation';
const CONTRIBUTIONS = 'contributions';
const DAILY_CONTRIBUTIONS = 'daily-contributions';
const COMBINED_CONTRIBUTIONS = 'combined-contributions';
const ARCHIVE = 'archive';
const MODEL_SUMMARY = 'model-summary';
const SCENARIO_PLANNER = 'scenario-planner';
const DIMINISHING_RETURNS = 'diminishing-returns-modeled';

export const SelectedModelDownloadDropdown = () => {
  const {mcaRunId} = useCampaignManagement();
  const {selectedMcaRun={}} = useMcaRuns();
  const {campaignName} = useCampaign() || {};
  const {companyName} = useClient() || {};
  const {downloadArchive, completedInsightTypes} = useOutputsInsights(mcaRunId);

  const [{
    data: downloadResponse={url: ''},
    loading,
    error
  }, postDownload] = usePost({});

  // Download URL if available in response
  React.useEffect(() => {
    if(loading || !downloadResponse.url || error) {
      return;
    }
    downloadFromURL(downloadResponse.url);
  }, [loading, downloadResponse.url, error]);

  const onSelectDownload = (selected={}) => {
    const url = urlWithDomain(
      urlBuilders.getOutputsInsightsDataByType(mcaRunId, selected.type)
    );

    let payload = {
      mcaRunId,
      type: selected.type,
      fileName: selected.value,
      runName: selectedMcaRun.name,
      campaignName,
      clientName: companyName
    };

    if(selected.type === ARCHIVE) {
      downloadArchive(false, `${selectedMcaRun.name}.zip`)
    } else {
      postDownload({
        url,
        data: payload
      });
    }
  };

    const insightTypeToOption = {
        [ARCHIVE]: {
            label: <FileDownloadOption title={ARCHIVE}>
                Download All
            </FileDownloadOption>,
            value: `${ARCHIVE}_${mcaRunId}.zip`,
            type: ARCHIVE,
            order: 1
        },
        [MODEL_SUMMARY]: {
            label: <FileDownloadOption title={MODEL_SUMMARY}>
                Model Summary
            </FileDownloadOption>,
            value: `${selectedMcaRun.name}_finalModel.csv`,
            type: MODEL_SUMMARY,
            order: 2
        },
        [FIT]: {
            label: <FileDownloadOption title={FIT}>
                Fit
            </FileDownloadOption>,
            value: `${selectedMcaRun.name}_fit.csv`,
            type: FIT,
            order: 3
        },
        [FIT_MISS]: {
          label: <FileDownloadOption title={FIT_MISS}>
              Fit Misses
          </FileDownloadOption>,
          value: `${selectedMcaRun.name}_fitMisses.csv`,
          type: FIT_MISS,
          order: 4
        },
        [FOLD]: {
            label: <FileDownloadOption title={FOLD}>
                Fold
            </FileDownloadOption>,
            value: `${selectedMcaRun.name}_fold.csv`,
            type: FOLD,
            order: 5
        },
        [DAILY_CONTRIBUTIONS]: {
            label: <FileDownloadOption title={DAILY_CONTRIBUTIONS}>
                Daily Contributions
            </FileDownloadOption>,
            value: `${selectedMcaRun.name}_primary-daily_contributions.csv`,
            type: DAILY_CONTRIBUTIONS,
            order: 6
        },
        [COMBINED_CONTRIBUTIONS]: {
            label: <FileDownloadOption title={COMBINED_CONTRIBUTIONS}>
                Combined Daily Contributions
            </FileDownloadOption>,
            value: `${selectedMcaRun.name}_combined-daily_contributions.csv`,
            type: COMBINED_CONTRIBUTIONS,
            order: 7
        },
        [SATURATION]: {
            label: <FileDownloadOption title={SATURATION}>
                Diminishing Returns Observed
            </FileDownloadOption>,
            value: `${selectedMcaRun.name}_diminishingReturns_observed.csv`,
            type: SATURATION,
            order: 8
        },
        [DIMINISHING_RETURNS]: {
            label: <FileDownloadOption title={DIMINISHING_RETURNS}>
                Diminishing Returns Modeled
            </FileDownloadOption>,
            value: `${selectedMcaRun.name}_diminishingReturns_modeled.csv`,
            type: DIMINISHING_RETURNS,
            order: 9
        },
        [EFFICIENCY]: {
            label: <FileDownloadOption title={EFFICIENCY}>
                Flighting Optimization
            </FileDownloadOption>,
            value: `${selectedMcaRun.name}_FlightingOptimization.csv`,
            type: EFFICIENCY,
            order: 10
        },
        [ROI]: {
            label: <FileDownloadOption title={ROI}>
                ROI
            </FileDownloadOption>,
            value: `${selectedMcaRun.name}_Roi.csv`,
            type: ROI,
            order: 11
        },
        [OPTIMAL_ALLOCATION]: {
            label: <FileDownloadOption title={OPTIMAL_ALLOCATION}>
                Optimal Allocation
            </FileDownloadOption>,
                value: `${selectedMcaRun.name}_OptimalAllocation.csv`,
            type: OPTIMAL_ALLOCATION,
            order: 12
        },
        [SCENARIO_PLANNER]: {
            label: <FileDownloadOption title={SCENARIO_PLANNER}>
                Scenario Planner
            </FileDownloadOption>,
                value: `${selectedMcaRun.name}_scenarioPlanner.csv`,
            type: SCENARIO_PLANNER,
            order: 13
        }
    }

    const fileDownloadOptions = [
        insightTypeToOption[ARCHIVE],
        insightTypeToOption[MODEL_SUMMARY],
        ...completedInsightTypes?.filter(oi => oi !=="archive").map((type) => {
            return insightTypeToOption[type];
        })?.filter((type) => {
            return !!type;
        })?.sort((a, b ) => {
            return a.order - b.order;
        })
    ]
  
  return (
    <SelectLV
      className={styles.dropdown}
      options={fileDownloadOptions}
      onChange={onSelectDownload}
      isDark={false}
      width={180}
      isSearchable={false}
      components={{
        DropdownIndicator: CustomDropdownIndicator,
        Placeholder: () => null
      }}
    />
  );
};

const CustomDropdownIndicator = () => {
  return (
    <div className={styles.indicator}>
      <div className='download-icon' />
      <div className='label' data-test='outputs-and-insights'>
        Outputs and Insights
      </div>
    </div>);
};

export const FileDownloadOption = ({title, children}) => {
  return (
    <div className={styles.option}>
      <img src={downloadCsv} alt={title}/>
      <div className='label'>{children}</div>
    </div>
  );
};
