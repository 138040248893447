import React from 'react';
import { Tooltip } from '@mui/material';
import {
  R1DataGrid,
  R1ActionIcon,
} from '@rainagency/rain-one-soggy-loaf';
import {
  formatDateWithNoTimezone,
  formatDateWithTimezoneOffset,
  formatUserName
} from '../../../components/data-grid/dataGridUtil';
import { useFeatureSelect } from '../../../hooks/useFeatureSelect';
import { FEATURES } from '../../../constants/features';
import {MAGNIFAI_ID_URL_PARAM, MCA_RUN_ID_URL_PARAM} from '../../../hooks/useCampaignManagement';
import { TransformationDowloadActionIcon } from './actions/TransformationDowloadActionIcon';
import { ErrorActionIcon } from './actions/ErrorActionIcon';
import { McaResultsActionIcon } from './actions/McaResultsActionIcon';
import { EditRunActionIcon } from './actions/EditRunActionIcon';
import { DownloadRunConfigurationIcon } from './actions/DownloadRunConfigurationIcon';
import { DownloadModelConfigurationIcon } from './actions/DownloadModelConfigurationIcon';
import { CancelActionIcon } from './actions/CancelActionIcon';
import { DeleteRunActionIcon } from './actions/DeleteRunActionIcon';
import { MMMGridStatusCell } from './MMMGridStatusCell';
import { RerunAnalysisActionIcon} from './actions/RerunAnalysisActionIcon';
import { RegenerateInsightsActionIcon } from "./actions/RegenerateInsightsActionIcon";

const DISABLED_ROW_STATUS = ['ERROR', 'RUNNING', 'Running'];

/**
 * List of All MMM Runs for a campaign
 */
export const MMMRunTable = ({
  mmmRuns,
  getRuns,
  magnifaiRuns,
  loading,
}) => {

  const { onFeatureSelect } = useFeatureSelect();

  const columns = getMMMColumns(getRuns, magnifaiRuns);

  return (
    <R1DataGrid
      treeData
      data={mmmRuns}
      getTreeDataPath={(row) => row.path}
      groupingColDef={{
        headerName: "",
        valueFormatter: () => "",
        width: 50
      }}
      columns={columns}
      loading={loading}
      height={'100%'}
      customStyles={{
        padding: '24px',
        cursor: 'default',
        '& .clickable-row:hover': {
          background: 'rgba(0, 0, 0, 0.04)',
          cursor: 'pointer'
        },
        '& .child-row': {
          background: 'rgba(0, 0, 0, 0.08)'
        }
      }}
      // Determine if a row is clickable
      getRowClassName={getRowClassName}
      // Handle click and redirect on valid rows
      onRowClick={(cell) => onRowClick(cell, onFeatureSelect, magnifaiRuns)}
      // Action Popover with 3 dots on right of grid
      renderActionColumn={(cell) => GetActions(cell, getRuns)}
      defaultGroupingExpansionDepth={1}
    />
  );
};

/**
 * Grid column properties
 * https://mui.com/x/api/data-grid/grid-col-def/
 */
const getMMMColumns = (getRuns, magnifaiRuns) => {
  return [
    {
      field: 'runStatus',
      headerName: 'Status',
      minWidth: 224,
      maxWidth: 224,
      renderCell: (cell) => {
        return (
          <MMMGridStatusCell
            cell={cell}
            getRuns={getRuns}
            magnifaiRuns={magnifaiRuns}
          />
        );
      }
    },
    {
      field: 'name',
      headerName: 'Run Name',
      flex: 1,
      minWidth: 176,
      hasTooltip: true,
    },
    {
      field: 'nestedModelType',
      headerName: 'Analysis Type',
      flex: 1,
      maxWidth: 176,
      valueFormatter: (cell) => {
        if(cell.value === 'SUB_MODEL'){
          return 'Sub-Model';
        }
        if(cell.value === 'MEDIA_INTERACTION_MODEL'){
          return 'Media Interaction Model';
        }
        return 'Primary Model';
      }
    },
    {
      field: 'kpi',
      headerName: 'Dependent Variable',
      hasTooltip: true,
      flex: 1,
      minWidth: 140,
      maxWidth: 176,
    },
    {
      field: 'startDate',
      headerName: 'Start',
      maxWidth: 100,
      valueFormatter: (cell) => {
        return formatDateWithNoTimezone(cell.value);
      }
    },
    {
      field: 'endDate',
      headerName: 'End',
      maxWidth: 100,
      valueFormatter: (cell) => {
        return formatDateWithNoTimezone(cell.value);
      }
    },
    {
      field: 'user',
      headerName: 'User',
      width: 60,
      renderCell: (cell) => {
        const {first, last, initials} = formatUserName(cell);
        if (!first.length && !last.length) {
          return initials;
        }

        return (
          <Tooltip title={`${first} ${last}`} disableInteractive>
            <span>{initials}</span>
          </Tooltip>
        );
      }
    },
    {
      field: 'runStartDate',
      headerName: 'Run Date',
      maxWidth: 160,
      minWidth: 160,
      valueFormatter: (cell) => {
        return formatDateWithTimezoneOffset(cell.value+"+00:00", new Date().getTimezoneOffset());
      }
    },
  ];
};

/**
 * Clicking on certain rows should redirect to a feature
 */
const onRowClick = (cell, onFeatureSelect, magnifaiRuns) => {
  if(isDisabledRow(cell.row.runStatus)) {
    return;
  }

  let matchingMagnifaiRun = magnifaiRuns?.filter(run => {return run.mca_run_id === cell.row.id})
  let magnifaiId = '';
  if(matchingMagnifaiRun){
    matchingMagnifaiRun = matchingMagnifaiRun[0];
    if(DISABLED_ROW_STATUS.includes(matchingMagnifaiRun?.run_status)) {
      return;
    }
    magnifaiId = matchingMagnifaiRun?.magnifai_id;
  }

  const id = cell.row.id;

  onFeatureSelect({
    feature: FEATURES.MMM_SELECTED,
    query: {
      [MCA_RUN_ID_URL_PARAM]: id,
      [MAGNIFAI_ID_URL_PARAM]: magnifaiId
    }
  });
};

/**
 * Standard Grid Row Popover Actions
 * EXAMPLE: https://61aea751cb86bd004af4d862-jqlgioqvcv.chromatic.com/?path=/story/grid-action-icons--action-icon
 */
const GetActions = (cell, getRuns) => {

  const isErrored = cell.row.runStatus === 'ERROR';
  const processingStages = ['SUBMITTED', 'STARTING', 'TRANSFORMING', 'BATCHING', 'PROCESSING'];
  const isProcessing = processingStages.includes(cell.row.runStatus);
  const isFinal = cell.row.runStatus === 'FINAL';

  return (
    <R1ActionIcon
      iconType='VerticalDots'
      PopoverContent={[
        <ErrorActionIcon
          key="2"
          cell={cell}
          hide={!isErrored}
        />,
        <McaResultsActionIcon
          key="3"
          cell={cell}
          hide={[isErrored, isProcessing].some(s => s)}
        />,
        <TransformationDowloadActionIcon
          key="4"
          cell={cell}
          hide={[isErrored, isProcessing].some(s => s)}
        />,
        <DownloadRunConfigurationIcon
          key="5"
          cell={cell}
        />,
        <DownloadModelConfigurationIcon
          key="6"
          cell={cell}
          hide={!isFinal}
        />,
        <EditRunActionIcon
          key="7"
          getRuns={getRuns}
          cell={cell}
          hide={isProcessing}
        />,
        <CancelActionIcon
          key="8"
          getRuns={getRuns}
          cell={cell}
          hide={!isProcessing}
        />,
        <RerunAnalysisActionIcon
          key="9"
          cell={cell}
        />,
        <DeleteRunActionIcon
          key="10"
          getRuns={getRuns}
          cell={cell}
          hide={isProcessing}
        />,
        <RegenerateInsightsActionIcon
          hide={!isFinal}
          key="11"
          getRuns={getRuns}
          cell={cell}
        />
      ]}
    />
  );
};

const isDisabledRow = (status) => {
  if(!status) {
    return true;
  }

  return DISABLED_ROW_STATUS.includes(status);
};

const getRowClassName = (params) => {
  const classes = [];
  const isClickable = !isDisabledRow(params.row.runStatus);

  if(isClickable) {
    classes.push('clickable-row');
  }

  if (params.row.isChild) {
    classes.push('child-row');
  }

  // Will set css classes to a string EX: 'clickable-row child-row'
  return classes.join(' ');
};
