import PropTypes from 'prop-types';
import {
  Card,
  Typography,
  Divider,
  Box
} from '@mui/material';

import { styled } from '@mui/system';
import { PanelDownloadButton } from './components/PanelDownloadButton';
import { PanelImageButton } from './components/PanelImageButton';

const PADDING_LR = '32px';

export const ChicletLV = ({
  title = '',
  chicletImageId,
  imageTooltip,
  imageFileName,
  downloadFileData,
  csvFileName,
  excelFileName,
  excelFileTooltip,
  fileTooltip,
  CallToAction,
  sx,
  children
}) => {
  return (
    <ChicletStyled
      data-testid='chiclet-lv-test'
      raised={true}>

      <ChicletContainerStyled
        id={chicletImageId}
        data-testid={chicletImageId}
        className='chiclet-lv'>

        <HeaderStyled>
          <HeaderRowStyled className='chiclet-header-row-lv'>

            <Typography variant='h5'>
              {title}
            </Typography>
            <ControlPanelStyled>
              {CallToAction}

              <PanelDownloadButton
                downloadFileData={downloadFileData}
                csvFileName={csvFileName}
                fileTooltip={fileTooltip}
              />

              <PanelDownloadButton
                downloadFileData={downloadFileData}
                excelFileName={excelFileName}
                excelFileTooltip={excelFileTooltip}
              />

              <PanelImageButton
                chicletImageId={chicletImageId}
                imageTooltip={imageTooltip}
                imageFileName={imageFileName}
              />

            </ControlPanelStyled>

          </HeaderRowStyled>
          <Divider />
        </HeaderStyled>

        <ContentStyled sx={sx}>
          {children}
        </ContentStyled>

      </ChicletContainerStyled>

    </ChicletStyled>
  );
};

const ChicletContainerStyled = styled('div')(({
  theme,
}) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

const ContentStyled = styled(Box)(({
  theme,
}) => ({
  padding: `24px ${PADDING_LR}`,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

const ControlPanelStyled = styled('div')(({
  theme,
}) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  height: '100%',

  // Remove first divider
  '& > .control-panel-divider:nth-of-type(1)': {
    display: 'none'
  }

}));

const ChicletStyled = styled(Card)(({
  theme,
}) => ({
  display: 'flex',
  minHeight: 134,
  background: theme.palette.background.default,
  flexDirection: 'column',
  margin: '24px 32px',
  borderRadius: 10,
  width: 'fill-available'
}));

const HeaderRowStyled = styled('div')(({
  theme,
}) => ({
  display: 'grid',
  gridTemplateColumns: '8fr 4fr',
  alignItems: 'center',
  height: 94,
}));

const HeaderStyled = styled('div')(({
  theme,
}) => ({
  padding: `0 ${PADDING_LR}`
}));

ChicletLV.propTypes = {
  /**
   * Top title for the chiclet
   */
  title: PropTypes.string,
  /**
   * CSS element id to download as image
   * will not show image/file download icon if not set
   */
  chicletImageId: PropTypes.string,
  /**
   * Tooltip title to display on image icon hover
   */
  imageTooltip: PropTypes.string,
  /**
   * Name of image file to download
   * will not show image icon if not set
   */
  imageFileName: PropTypes.string,
  /**
   * Data to turn into CSV on download icon click
   */
  downloadFileData: PropTypes.oneOfType(
    [PropTypes.object, PropTypes.array]
  ),
  /**
   * Name of csv file to download
   * will not show download icon if not set
   */
  csvFileName: PropTypes.string,
  /**
   * Tooltip title to display on image icon hover
   */
  fileTooltip: PropTypes.string,
  /**
   * Custom styling for MUI Card top-most parent
   */
  sx: PropTypes.object,
};
