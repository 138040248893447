import {
  Typography,
  Box
} from '@mui/material';
import { styled } from '@mui/system';
import { RecentActivity } from './components/RecentActivity';

export const HomePage = ({
  firstName,
  lastName,
  setSelectedFeature = () => {},
  selectedFeature = {},
  recentActivity,
  isRecentActivityLoading,
  isColumn
}) => {

  // Do not display if feature is selected
  if(selectedFeature.key) {
    return null;
  }

  let message = 'Welcome Back';
  if(firstName && lastName) {
    message = `Welcome Back, ${firstName} ${lastName}`;
  }

  return (
    <HomePageContainerStyled
      data-testid='home-page'
      className={'home-page-container'}>

      <HomePageMessageStyled
        component='div'
        className='homepage-message'
        data-testid='homepage-message'
        aria-label='Homepage Message'
        variant={'h3'}>

        {message}

      </HomePageMessageStyled>

      <TilesAndRecentActivityContainer
        sx={{
          flexDirection: isColumn ? 'row' : 'column'
        }}
        className='tile-recent-activity-container'>

        <RecentActivity
          setSelectedFeature={setSelectedFeature}
          recentActivity={recentActivity}
          isRecentActivityLoading={isRecentActivityLoading}
          isColumn={isColumn}
        />

      </TilesAndRecentActivityContainer>

    </HomePageContainerStyled>
  );
};

const HomePageMessageStyled = styled(Typography)(({
  theme,
}) => ({

}));

const TilesAndRecentActivityContainer = styled(Box)(({
  theme,
}) => ({
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  gap: 32,
}));

const HomePageContainerStyled = styled('div')(({
  theme,
}) => ({
  padding: '32px 24px 0 24px',
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  gap: 32,
  overflowY: 'auto'
}));
