import React from 'react';
import {CHART_FORMAT} from '../../../components/charts/ChartFormat.constant';
import {Chiclet} from '../../../components/dashboards/Chiclet';
import {ChicletTitle} from '../../../components/dashboards/ChicletTitle';
import {GraphContainerLV} from '../../../components/dashboards/GraphContainerLV';
import {StickyTableLV} from '../../../components/data-table/StickyTableLV';
import {GraphConfig} from '../../../components/dashboards/GraphConfig';
import {GRAPH_OPTIONS} from '../../../components/dashboards/graph-options.constant';

import {styles} from './ScoredContributionsChiclet.styles';

export const ScoredContributionsChiclet = ({
                                               selectedMcaRun = {},
                                               selectedScoringRun = {},
                                               data,
                                               modelData,
                                               isCombined,
                                               isLoading
                                           }) => {
    const combinedPrefix = isCombined ? 'combined-' : '';
    const combinedTitle = isCombined ? 'Combined ' : '';

    const {
        kpiType,
    } = selectedMcaRun
    const {
        hasKpi,
    } = selectedScoringRun

    const referenceDate = selectedScoringRun.startDate || selectedMcaRun.endDate;

    let referenceLine;
    if (referenceDate) {
        referenceLine = {
            x: referenceDate,
            label: referenceDate
        };
    }

    const formatMap = (kpiType === 'Units' && !!data?.[0]) ?
        Object.keys(data?.[0])?.filter((key) => key != 'date' && key != 'region')?.reduce((keys, key) => (
            {
                ...keys,
                [key]: CHART_FORMAT.INTEGER
            }), {Base: CHART_FORMAT.INTEGER}) : {};

    const graphConfiguration = new GraphConfig({
        data,
        formatMap,
        graphOptions: [
            GRAPH_OPTIONS.LINE,
        ],
        isArea: true,
        xAxisKey: 'date',
        yAxisKey: 'Base',
        imageFileName: `${combinedTitle}ScoredDailyContributions`,
        imageElementId: `${combinedPrefix}scored-daily-chiclet-id`,
        referenceLine,
        isLoading,
        scrollBar: {
            minLength: 30
        },
    });

    const modelSummaryTable = !isCombined && hasKpi &&
        (<div className='scoring-model-summary-table'>
            <StickyTableLV
                data={modelData}
                isLoading={isLoading}
                dataTest={`${combinedPrefix}scoring-model-summary`}
            />
        </div>);

    return (
        <Chiclet id={`${combinedPrefix}contributions-chiclet`} className={styles.content}>

            <ChicletTitle>
                {`${combinedTitle}Contributions`}
            </ChicletTitle>

            <GraphContainerLV
                graphConfig={graphConfiguration}
            />

            {modelSummaryTable}

        </Chiclet>
    );
};
