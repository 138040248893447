import React from 'react';
import {useGet} from "../../../../hooks/useRest";
import {urlBuilders} from "../../../../js/constants";
import {useInsightsQuery} from "../../../dashboards/hooks/useInsightsQuery";
import {ScoringDashboardContext} from "../../ScoringDashboardContext";
import {variableNameToRaw} from "../../../../js/utils";

export const useScoringFlightingComparisonDashboard = ({}) => {

    const {
        selectedMcaRun,
        region,
        scoring2,
        scoring1,
        mcaInsightMetadata
    } = React.useContext(ScoringDashboardContext);

    const {
        id: mcaRunId
    } = selectedMcaRun;

    const [{
        data: scoringMeanCenter1 = {data: []},
        loading: scoringMeanCenterLoading1
    }, fetchScoringMeanCenter1] = useGet({
        url: urlBuilders.getScoringTransform(scoring1?.value, 'mean-centering-mv'),
        manual: true
    });

    const [{
        data: scoringMeanCenter2 = {data: []},
        loading: scoringMeanCenterLoading2
    }, fetchScoringMeanCenter2] = useGet({
        url: urlBuilders.getScoringTransform(scoring2?.value, 'mean-centering-mv'),
        manual: true
    });

    const {
        results: contributionsData1,
        fetch: fetchContributionsData1,
        isLoading: isLoadingContributionsData1
    } = useInsightsQuery({
        chartType: `ContributionsOverTime`,
    });

    const {
        results: contributionsData2,
        fetch: fetchContributionsData2,
        isLoading: isLoadingContributionsData2
    } = useInsightsQuery({
        chartType: `ContributionsOverTime`,
    });

    React.useEffect(() => {
        if (mcaRunId && scoring1?.value) {
            fetchScoringMeanCenter1();
            fetchContributionsData1({
                regions: region ? [region] : undefined
            }, {
                runId: mcaRunId,
                scoringId: scoring1.value,
                chartParams: {
                    variableNameFormatType: 'NoDecayOrSaturation',
                    includeNegatives: true,
                    withActuals: true
                }
            });
        }
    }, [mcaRunId, scoring1?.value, region]);

    React.useEffect(() => {
        if (mcaRunId && scoring2?.value) {
            fetchScoringMeanCenter2();
            fetchContributionsData2({
                regions: region ? [region] : undefined
            }, {
                runId: mcaRunId,
                scoringId: scoring2.value,
                chartParams: {
                    variableNameFormatType: 'NoDecayOrSaturation',
                    includeNegatives: true,
                    withActuals: true
                }
            });
        }
    }, [mcaRunId, scoring2?.value, region]);

    const mediaGrouping = React.useMemo(() => {
        if (Object.keys(mcaInsightMetadata || {}).length) {
            const excludeMeasures = ['Binary', 'Control'];
            return Object.entries(mcaInsightMetadata.variables).map(([key, value]) => {
                return {
                    VariableName: variableNameToRaw(key),
                    Measure: value.measure
                }
            }).filter(variable => !excludeMeasures.includes(variable.Measure));
        }
    }, [mcaInsightMetadata]);

    const isScoring1Loading = React.useMemo(() => {
        return [
            isLoadingContributionsData1,
            scoringMeanCenterLoading1,
        ].some(isLoad => isLoad);
    }, [scoringMeanCenterLoading1, isLoadingContributionsData1]);

    const isScoring2Loading = React.useMemo(() => {
        return [
            isLoadingContributionsData2,
            scoringMeanCenterLoading2,
        ].some(isLoad => isLoad);
    }, [scoringMeanCenterLoading2, isLoadingContributionsData2]);

    const contributions1 = React.useMemo(() => {
        return contributionsData1?.results || [];
    }, [contributionsData1]);

    const contributions2 = React.useMemo(() => {
        return contributionsData2?.results || [];
    }, [contributionsData2]);

    const meanCenter1 = React.useMemo(() => {
        return scoringMeanCenter1?.data || [];
    }, [scoringMeanCenter1]);

    const meanCenter2 = React.useMemo(() => {
        return scoringMeanCenter2?.data || [];
    }, [scoringMeanCenter2]);

    return {
        contributions1,
        contributions2,
        meanCenter1,
        meanCenter2,
        mediaGrouping,
        isLoading: isScoring1Loading || isScoring2Loading
    };
}

