import {css} from 'emotion';

export const styles = {
  container: css`
    width: 100%;
    height: 30px;
    display: flex;
    background: #F2F2F2;
    border-radius: 8px;

    &.error {
      background: #FFD0D0;
    }

    input {
      background: green;
      padding-bottom: 0;
      width: 100%;
      background: transparent;
      border: 0;
      outline: none;
    }

    input::placeholder {
      font-style: italic;
      opacity: 0.5;
    }

    &.numeric {
      input {
        text-align: end;
      }
    }

    &.currency::before {
      content: '$';
      margin-top: 6px;
      margin-left: 12px;
    }
    
    &.disabled {
      background: white;
    }
  `
};
