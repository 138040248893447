import React from 'react';
import PropTypes from 'prop-types';
import {getChartColor} from './chartColors';
import {CustomTooltip} from './CustomTooltip';
import {useToggleColumnLegend} from './CustomLegend';
import {formatAxis, getYAxisLabelOffset, getXAxisLabelOffset, getReferenceAreas} from './chart-format.util';
import {sentenceCase, capitalCase} from 'change-case';
import {styles, legendContent, getChartStateCss} from './charts.styles';
import {useValidateChartData} from './useValidateChartData';
import {getIsAnimationActive} from './chart-format.util';

import {
  Scatter, XAxis, YAxis, ResponsiveContainer, Tooltip, Legend, ComposedChart, Line
} from 'recharts';

/**
 * Builds a scatter plot chart of the fit data, actual vs predicted
 * http://recharts.org/en-US/api/ScatterChart
 */
export const ScatterChartLV = ({
  data, xAxisLabel='', yAxisLabel='', xTicks=20, yTicks=11, ticks=[],
  xAxisKey='x', yAxisKey='y', filterKey='', formatMap={}, colorMap={},
  isLoading=false, referenceAreaConfigs, disableLegendColumns=[], columnChartTypeMap={}, disableReferenceAreaLegend=false,
  toggleYValues=false
}) => {

  // Populate all Scatter Lines
  // http://recharts.org/en-US/api/Scatter
  let extendedPayload;

  const chartData = useValidateChartData(data, 15, xAxisKey, yAxisKey);
  const {
    legendData,
    legendClickHandler,
    hiddenColumnMap,
    CustomLegend
  } = useToggleColumnLegend(chartData, filterKey, xAxisKey, yAxisKey);
  const chartStateCss = getChartStateCss(data, isLoading);
  const isAnimationActive = getIsAnimationActive(chartData);
  const ScatterRows = getScatterRows(
    legendData, filterKey, isAnimationActive, hiddenColumnMap, colorMap, yAxisKey
  );
  const Lines = getLines(columnChartTypeMap, isAnimationActive, hiddenColumnMap, xAxisKey, colorMap);
  const legendKey = capitalCase(sentenceCase(filterKey));
  const classNames = [styles.chart,
                      chartStateCss,
                      legendContent(legendKey)
                     ].join(' ');

  // Add legend if ReferenceArray boxes are available
  if(referenceAreaConfigs && Array.isArray(referenceAreaConfigs)) {
    extendedPayload = referenceAreaConfigs.map(r => ({
      value: r.label,
      type: 'rect',
      color: r.color
    }));
  }

  const ReferenceAreas = getReferenceAreas(referenceAreaConfigs, hiddenColumnMap);

  return (
    <ResponsiveContainer
      width='100%'
      height={400}
      debounce={1}
      id='scatter-chart'
      className={classNames}>

      <ComposedChart
        margin={{top: 10, right: 0, left: 0, bottom: 0}}
        style={{
          marginTop: !!data ? '-24px' : '0'
        }}
        data={legendData} >
        <XAxis
          dataKey={xAxisKey}
          type='number'
          scale={ticks.length ? 'linear' : undefined }
          tickMargin={10}
          tickSize={0}
          ticks={ticks}
          tickCount={ticks.length || xTicks}
          tickFormatter={formatAxis(xAxisKey, formatMap)} 
          height={100}
          angle={-90} 
          textAnchor='end'
          label={{
            value: xAxisLabel,
            position: 'center',
            dy: getXAxisLabelOffset(data, 30, yAxisKey)
          }}/>

        <YAxis 
          dataKey={!toggleYValues ? yAxisKey : null}
          type='number'
          width={100}
          tickMargin={20}
          axisLine={false} 
          tickSize={0}
          tickCount={yTicks}
          tickFormatter={formatAxis(yAxisKey, formatMap)}
          label={{
            value: yAxisLabel,
            angle: -90,
            position: 'center',
            dx: getYAxisLabelOffset(data, 30, xAxisKey)
          }}/>

        <Tooltip
          content={
            <CustomTooltip
              map={formatMap}
              hiddenColumnMap={hiddenColumnMap}
            />
          }/>

        <Legend
          align='left'
          verticalAlign='top'
          extendedPayload={disableReferenceAreaLegend ? [] : extendedPayload}
          hiddenColumnMap={hiddenColumnMap}
          disableLegendColumns={disableLegendColumns}
          onClick={legendClickHandler}
          isLoading={isLoading}
          content={CustomLegend}
        />

        {ReferenceAreas}

        {ScatterRows}

        {Lines}

      </ComposedChart>
    </ResponsiveContainer>
  );
};

/**
 * Take a data set and create <Line/> Elements
 */
export const getLines = (
  columnChartTypeMap={}, isAnimationActive, hiddenColumnMap={}, xAxisKey, colorMap
) => {
  if(!columnChartTypeMap)
    return [];

  const lineDataKeys = Object.keys(columnChartTypeMap);
  const lineDataValues = Object.values(columnChartTypeMap);
  return lineDataKeys.map((key, index) => {
    const lineData = lineDataValues[index] || [];
    return (
      <Line
        dataKey={key}
        hide={hiddenColumnMap[key]}
        name={key}
        data={lineData}
        dot={false}
        stroke={getChartColor(index, colorMap, key)}
        strokeWidth={3}
        key={xAxisKey}
        isAnimationActive={isAnimationActive}
      />);
  });
};

/**
 * Take a data set and create <Scatter/> Elements
 */
export const getScatterRows = (
  data, filterKey, isAnimationActive, hiddenColumnMap={}, colorMap={}, yAxisKey
) => {
  const scatterData = filterByKey(data, filterKey);
  return scatterData.map((row, index) => {
    const rowName = row[0][filterKey] || yAxisKey;
    return (
      <Scatter
        dataKey={yAxisKey}
        hide={hiddenColumnMap[rowName]}
        key={`scatter-key-${index}`}
        name={rowName}
        data={row}
        fill={getChartColor(index, colorMap, rowName)}
        isAnimationActive={isAnimationActive}
      />);
  });
};

/**
 * Returns an array of datasets filtered by a given key
 */
export const filterByKey = (data, key) => {
  if(!data || !data.length) {
    return [];
  }

  const DEFAULT_KEY = 'default';
  const filterMap = {};
  data.forEach(row => {
    const filterKey = row[key] || DEFAULT_KEY;
    if(!Array.isArray(filterMap[filterKey])) {
      filterMap[filterKey] = [];
    }
    filterMap[filterKey].push(row);
  });

  return Object.values(filterMap);
};

ScatterChartLV.propTypes = {
  /**
   * Data set to create chart with
   */
  data: PropTypes.array,
  /**
   * Column key to filter each scatter element by
   * Will create a new color for each instance of this key
   */
  filterKey: PropTypes.string,
  /**
   * Map of keys and the desired format the values will be in
   */
  formatMap: PropTypes.object,
  /**
   * Text to dispay on the X Axis
   */
  xAxisLabel: PropTypes.string,
  /**
   * Data key to use in X axis of graph
   */
  xAxisKey: PropTypes.string,
  /**
   * Text to dispay on the Y Axis
   */
  yAxisLabel: PropTypes.string,
  /**
   * Data key to use in Y axis of graph
   */
  yAxisKey: PropTypes.string
};
