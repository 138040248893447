import {call, put, takeEvery} from 'redux-saga/effects';

import {urlBuilders} from '../../../js/constants.js';

import {getData} from '../../../js/server.js';

import {
    FETCH_OUTPUTS_INSIGHTS_FIT_REQUEST,
    FETCH_OUTPUTS_INSIGHTS_THRESHOLDS_REQUEST,
    FETCH_OUTPUTS_INSIGHTS_OPTIMAL_ALLOCATION_REQUEST,
    FETCH_OUTPUTS_INSIGHTS_EFFICIENCY_REQUEST,
    FETCH_OUTPUTS_INSIGHTS_CONTRIBUTIONS_REQUEST,
    FETCH_OUTPUTS_INSIGHTS_DAILY_CONTRIBUTIONS_REQUEST,
    FETCH_OUTPUTS_INSIGHTS_SATURATION_REQUEST,
    fetchOutputsInsightsFitSuccess,
    fetchOutputsInsightsThresholdsSuccess,
    fetchOutputsInsightsOptimalAllocationSuccess,
    fetchOutputsInsightsEfficiencySuccess,
    fetchOutputsInsightsContributionsSuccess,
    fetchOutputsInsightsDailyContributionsSuccess,
    fetchOutputsInsightsSaturationSuccess,
    setFitDateRange,
    CONTRIBUTIONS,
    DAILY_CONTRIBUTIONS,
    FIT,
    THRESHOLDS,
    OPTIMAL_ALLOCATION,
    EFFICIENCY,
    SATURATION,
} from '../outputs-insights.js';

import {snackbar} from '../../platform/snackbar.js';

// each of these function pairs fetchs an outputs and insight from the back end and then sends it to redux

// FIT OUTPUT AND INSIGHT
export function* fetchOutputsInsightsFitData(action) {
    const url = urlBuilders.getOutputsInsightsDataByType(action.runId, FIT);
    const response = yield call(getData, action.token, action.baseUrl + url);
    if (response.error) {
        // TODO
    } else {
        yield put(fetchOutputsInsightsFitSuccess(response.data));
        yield put(setFitDateRange());
    }
}

export function* watchFetchOutputsInsightsFitRequest() {
    yield takeEvery(
        FETCH_OUTPUTS_INSIGHTS_FIT_REQUEST,
        fetchOutputsInsightsFitData
    );
}

// THRESHOLDS OUTPUT AND INSIGHT
export function* fetchOutputsInsightsThresholdsData(action) {
    const url = urlBuilders.getOutputsInsightsDataByType(
        action.runId,
        THRESHOLDS
    );
    const response = yield call(getData, action.token, action.baseUrl + url);
    if (response.error) {
        // TODO
    } else {
        yield put(fetchOutputsInsightsThresholdsSuccess(response.data));
    }
}

export function* watchFetchOutputsInsightsThresholdsRequest() {
    yield takeEvery(
        FETCH_OUTPUTS_INSIGHTS_THRESHOLDS_REQUEST,
        fetchOutputsInsightsThresholdsData
    );
}

// OPTIMAL_ALLOCATION OUTPUT AND INSIGHT
export function* fetchOutputsInsightsOptimalAllocationData(action) {
    const url = urlBuilders.getOutputsInsightsDataByType(
        action.runId,
        OPTIMAL_ALLOCATION
    );
    const response = yield call(getData, action.token, action.baseUrl + url);
    if (response.error) {
        yield put(
            snackbar(
                'error',
                'ERROR: \n Error while getting optimal allocation data for run with id: ' +
                    action.runId +
                    '. \n' +
                    response.message
            )
        );
    } else {
        yield put(fetchOutputsInsightsOptimalAllocationSuccess(response.data));
    }
}

export function* watchFetchOutputsInsightsOptimalAllocationRequest() {
    yield takeEvery(
        FETCH_OUTPUTS_INSIGHTS_OPTIMAL_ALLOCATION_REQUEST,
        fetchOutputsInsightsOptimalAllocationData
    );
}

// EFFICIENCY OUTPUT AND INSIGHT
export function* fetchOutputsInsightsEfficiencyData(action) {
    const url = urlBuilders.getOutputsInsightsDataByType(
        action.runId,
        EFFICIENCY
    );
    const response = yield call(getData, action.token, action.baseUrl + url);
    if (response.error) {
        // TODO
    } else {
        yield put(fetchOutputsInsightsEfficiencySuccess(response.data));
    }
}

export function* watchFetchOutputsInsightsEfficiencyRequest() {
    yield takeEvery(
        FETCH_OUTPUTS_INSIGHTS_EFFICIENCY_REQUEST,
        fetchOutputsInsightsEfficiencyData
    );
}

// CONTRIBUTIONS OUTPUTS AND INSIGHT
export function* fetchOutputsInsightsContributionsData(action) {
    const url = urlBuilders.getOutputsInsightsDataByType(
        action.runId,
        CONTRIBUTIONS
    );
    const response = yield call(getData, action.token, action.baseUrl + url);
    if (response.error) {
        // TODO
    } else {
        yield put(fetchOutputsInsightsContributionsSuccess(response.data));
    }
}

export function* watchFetchOutputsInsightsContributionsRequest() {
    yield takeEvery(
        FETCH_OUTPUTS_INSIGHTS_CONTRIBUTIONS_REQUEST,
        fetchOutputsInsightsContributionsData
    );
}

// DAILY CONTRIUBTIONS OUTPUT AND INSIGHT
export function* fetchOutputsInsightsDailyContributionsData(action) {
    const url = urlBuilders.getOutputsInsightsDataByType(
        action.runId,
        DAILY_CONTRIBUTIONS
    );
    const response = yield call(getData, action.token, action.baseUrl + url);
    if (response.error) {
        // TODO
    } else {
        yield put(fetchOutputsInsightsDailyContributionsSuccess(response.data));
    }
}

export function* watchFetchOutputsInsightsDailyContributionRequest() {
    yield takeEvery(
        FETCH_OUTPUTS_INSIGHTS_DAILY_CONTRIBUTIONS_REQUEST,
        fetchOutputsInsightsDailyContributionsData
    );
}

// SATURATION OUTPUT AND INSIGHT
export function* fetchOutputsInsightsSaturationData(action) {
    const url = urlBuilders.getOutputsInsightsDataByType(
        action.runId,
        SATURATION
    );
    const response = yield call(getData, action.token, action.baseUrl + url);
    if (response.error) {
        // TODO
    } else {
        yield put(fetchOutputsInsightsSaturationSuccess(response.data));
    }
}

export function* watchFetchOutputsInsightsSaturationRequest() {
    yield takeEvery(
        FETCH_OUTPUTS_INSIGHTS_SATURATION_REQUEST,
        fetchOutputsInsightsSaturationData
    );
}
