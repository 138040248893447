import React from "react";
import {ScoringDashboardContext} from "../ScoringDashboardContext";
import moment from "moment";
import {DashboardDatePickerBar} from "../../../components/dashboards/DashboardDatePickerBar";

export const ScoringDatePickWrapper = () => {
    const {setStartDate, setEndDate, scoringInsightMetadata, region, setRegion} = React.useContext(ScoringDashboardContext);

    const {
        maxDate,
        minDate,
        regions = []
    } = React.useMemo(() => {
        if (!scoringInsightMetadata?.minDate || !scoringInsightMetadata?.maxDate) {
            return {};
        }

        return {
            ...scoringInsightMetadata,
            minDate: moment(scoringInsightMetadata.minDate),
            maxDate: moment(scoringInsightMetadata.maxDate),
        }
    }, [scoringInsightMetadata]);

    const regionOptions = React.useMemo(() => {
        let options = [...regions];
        if (options.length > 1) {
            options.unshift('All');
        }
        return options;
    }, [regions]);

    const defaultRegionProp = React.useMemo(() => {
        if (region) {
            return region;
        }

        if (regions && regions.length) {
            return regions[0];
        }
    }, [regionOptions, region]);


return (
    <DashboardDatePickerBar
        isDateOnly={true}
        minDate={minDate}
        maxDate={maxDate}
        defaultStartDate={minDate?.toString()}
        defaultEndDate={maxDate?.toString()}
        setDashboardStartDate={setStartDate}
        setDashboardEndDate={setEndDate}
        isReadOnly={!minDate || !maxDate}
        defaultRegion={defaultRegionProp}
        setDashboardRegion={setRegion}
        regions={regionOptions}
    />
)
}