import React from 'react';
import {TableWithBorder} from '../../../components/data-table/TableWithBorder';
import {getDummyData} from '../../../components/data-table/getDummyData';
import {CheckboxLV} from '../../../components/form/CheckboxLV';
import _ from 'lodash';

export const BinaryVariableTable = ({
  data=[],
  loading=false,
  register=()=>{},
  isClearToggle=false,
  interceptIncluded=false
}) => {
  const BINARY_MEASURES = ['Binary', 'Base'];

  let binary = React.useMemo(()=>(
    data
      .filter(d=>BINARY_MEASURES.includes(d.measure))
      .map(d=>({
        variableName: d.variableName,
        variableTactic: d.tactic,
        scenario1: !!d.scenario1Value,
        scenario2: !!d.scenario2Value
      }))
  ), [data, BINARY_MEASURES]);

  const hasData = !!binary.length;

  if(!binary.length) {
    binary = getDummyData(dummyBinarySchema, 4);
  }

  const override = {
    variableName: {
      hidden: true
    },
    variableTactic: {
      name: 'Variable'
    },
    scenario1: {
      name: `Scenario 1`,
      align: 'center',
      component: <ScenarioCheckbox
                   register={register}
                   nameSuffix={'_scenario1'}
                   isClearToggle={isClearToggle}
                   interceptIncluded={interceptIncluded}
                 />
    },
    scenario2: {
      name: 'Scenario 2',
      align: 'center',
      component: <ScenarioCheckbox
                   register={register}
                   nameSuffix={'_scenario2'}
                   isClearToggle={isClearToggle}
                   interceptIncluded={interceptIncluded}
                 />
    }
  };

  return (
    <TableWithBorder
      data={binary}
      hasData={hasData}
      isLoading={loading}
      override={override}
      disableTableSort={true}
      dataTest='binary'
      title='Binary Variables'
    />
  );
};

export const ScenarioCheckbox = ({
  cell={},
  nameSuffix='',
  register=()=>{},
  isClearToggle=false,
  interceptIncluded=false
}) => {
  const [checked, setChecked] = React.useState(!!cell.value);
  const variableName = _.get(cell, 'row.values.variableName');
  let disabled = false;
  if(!interceptIncluded && variableName === 'Base'){
    disabled = true;
  }
  const onChange = () => {
    setChecked(!checked);
  };

  // Clear checkbox on parent request
  React.useEffect(() => {
    setChecked(false);
  }, [isClearToggle, setChecked]);

  // Set checkbox status from parent if set
  React.useEffect(() => {
    setChecked(!!cell.value);
  }, [cell.value, setChecked]);

  const value = checked ? 1 : 0;
  const name = `${variableName}${nameSuffix}`;

  return (
    <div>
      <CheckboxLV
        onChange={onChange}
        checked={checked}
        disabled={disabled}
        style={{
          marginRight: '0',
          marginLeft: '-6px',
        }}
      />

      <input
        name={name}
        ref={register()}
        type='hidden'
        value={value}
      />
    </div>
  );
};

const dummyBinarySchema = {
  variableName: '',
  variableTactic: '',
  scenario1: '',
  scenario2: ''
};
