import useDeepCompareEffect from 'use-deep-compare-effect';
import { usePost } from '../../../../hooks/useRest';
import {useGetMcaModels} from '../../../../hooks/useGetMcaModels';
import { urlBuilders } from '../../../../js/constants';
import { variableNameToRaw } from '../../../../js/utils';

export const useModelPlacementNames = (selectedRunId) => {
  const {modelList=[], loading} = useGetMcaModels(selectedRunId);
  const filteredVariables = modelList.filter((entry) => { 
      return parseFloat(entry.prctcontributions) >= 0
  });
  const independentVariables = filteredVariables.map(
      model => ({
          value: model.independentvariable,
          label: variableNameToRaw(model.independentvariable)
      })
  );

  const [{data: placementNames={},loading: namesLoading}, getPlacementNames] = usePost({
      url: urlBuilders.postVariableDetails()
  });

  //Get measure for independent variables
  useDeepCompareEffect(() => {
      if (independentVariables.length && selectedRunId) {
          getPlacementNames({
              data: {
                runId: selectedRunId,
                filters: independentVariables.map(independentVariable => independentVariable.value)
              }
          });
      }
  }, [independentVariables, selectedRunId]);

  return {
    independentVariables,
    placementNames: placementNames || {},
    loading: loading || namesLoading
  };
}