import {css} from 'emotion';

export const styles = {
  input: css`
    /* Chrome, Safari, Edge, Opera */
    input[type=number]::-webkit-outer-spin-button,
    input[type=number]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      text-align: center;
    }
    
    
    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
      text-align: center;
    }
  `
}