// actions
export const SPINNER = `app/download-template/spinner`;
export const LOAD_DOWNLOAD_ERRORS = `app/download-template/load-download-errors`;
export const CLEAR_DOWNLOAD_ERRORS = `app/download-template/clear-download-errors`;
export const GET_GROUPING_TEMPLATE_STATUS = `app/download-template/getGroupingTemplateStatus`;
export const UPDATE_GROUPING_TEMPLATE_STATUS = `app/download-template/updateGroupingTemplateStatus`;
export const GET_GROUPING_TEMPLATE_URL = `app/download-template/getGroupingTemplateUrl`;
export const UPDATE_GROUPING_TEMPLATE_URL = `app/download-template/updateGroupingTemplateUrl`;

// selectors
const ID = 'downloadTemplate';
const local = (state) => state['platform'][ID];
export const getDownloadErrors = (state) => local(state).errors;
export const getGroupingTemplateStatus = (state) =>
    local(state).groupingTemplateStatus;
export const getGroupingTemplateUrl = (state) =>
    local(state).groupingTemplateUrl;
export const getSpinner = (state) => local(state).spinner;

export const initialState = {
    errors: [],
    spinner: false,
    groupingTemplateStatus: '',
    groupingTemplateUrl: ''
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SPINNER:
            return Object.assign({}, state, {spinner: action.show});
        case LOAD_DOWNLOAD_ERRORS:
            return Object.assign({}, state, {errors: action.errors});
        case CLEAR_DOWNLOAD_ERRORS:
            return Object.assign({}, state, {errors: []});
        case UPDATE_GROUPING_TEMPLATE_STATUS:
            return Object.assign({}, state, {
                groupingTemplateStatus: action.status
            });
        case UPDATE_GROUPING_TEMPLATE_URL:
            return Object.assign({}, state, {groupingTemplateUrl: action.url});
        default:
            return state;
    }
}

export function spinner(show) {
    return {
        type: SPINNER,
        show
    };
}

export function loadDownloadErrors(errors) {
    return {
        type: LOAD_DOWNLOAD_ERRORS,
        errors
    };
}

export function clearDownloadErrors() {
    return {
        type: CLEAR_DOWNLOAD_ERRORS
    };
}

export function updateGroupingTemplateStatus(status) {
    return {
        type: UPDATE_GROUPING_TEMPLATE_STATUS,
        status
    };
}

export function updateGroupingTemplateUrl(url) {
    return {
        type: UPDATE_GROUPING_TEMPLATE_URL,
        url
    };
}
