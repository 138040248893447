import {
  R1ActionIcon,
} from '@rainagency/rain-one-soggy-loaf';
import {
  usePostTriggerDownloadUrl,
} from '../../../../hooks/useTriggerDownloadUrl';
import { urlBuilders } from '../../../../js/constants';

/**
 * Download transformation file on Grid Action Popover click
 */
export const TransformationDowloadActionIcon = ({
  cell,
  hide,
  closePopover
}) => {

  const {
    triggerDownload,
    loading
  } = usePostTriggerDownloadUrl({
    method: 'POST',
    requestUrl: urlBuilders.getMcaTransform(cell.row.id, 'join-transform'),
    urlObjectPath: 'url',
    onFinal: closePopover
  });

  const handleClick = () => {
    triggerDownload({
      fileName: `${cell.row.runName}_transformation.csv`
    });
  };

  return (
    <R1ActionIcon
      iconType='File'
      label='Export Transformation File'
      hide={hide}
      onClick={handleClick}
      customStyles={{
        cursor: loading ? 'wait' : 'pointer'
      }}
    />
  );
  
};
