import React from 'react';
import {AdImpactDashboardDatePickerBar} from './AdImpactDashboardDatePickerBar';
import {useCampaignManagement, TAB_URL_KEY} from '../../../hooks/useCampaignManagement';
import {useAdImpactRuns} from '../../../hooks/useAdImpactRuns';
import {useCampaign} from '../../../hooks/useCampaign';
import {useClient} from '../../../hooks/useClient';
import {AdImpactChart} from './AdImpactChart';
import {PlacementTable} from './PlacementTable';
import {RUN_TYPES} from '../../../constants/ad-impact-run-types';
import { FeatureLV } from '../../../components/pages/FeatureLV';
import DownloadCSVFactory from '../../../js/download-csv-factory';

export const AdImpactDashboards = () => {
  const {
    adImpactTabIndex=0,
    adImpactId
  } = useCampaignManagement();

  const {campaignName} = useCampaign() || {};
  const {companyName: clientName} = useClient() || {};

  const runTypeFilters = [
    [RUN_TYPES.TV_NO_CUSTOM, RUN_TYPES.TV_WITH_CUSTOM],
    [RUN_TYPES.OTT],
    [RUN_TYPES.FACEBOOK],
    [RUN_TYPES.DIGITAL_VIDEO],
    [RUN_TYPES.DIGITAL_AUDIO],
    [RUN_TYPES.TERRESTRIAL_RADIO]
  ];

  const selectedIdx = isNaN(adImpactTabIndex) ? 0 : adImpactTabIndex;
  const runTypes = runTypeFilters[selectedIdx];

  const {
    adImpactRun,
    loading,
    fetchRuns = () => {}
  } = useAdImpactRuns(runTypes);
  const {adImpactRun: selectedRun} = useAdImpactRuns();

  const AdImpactDatePicker = (
    <AdImpactDashboardDatePickerBar
      selectedAdImpactRun={adImpactRun}
      isReadOnly={true}
    />);


  // Refresh data on change of tab or Selected AdImpact Run
  React.useEffect(() => {
    fetchRuns();
  }, [selectedIdx, adImpactId]);

  const tabs = [
    {
      label: 'Linear Television',
      nestedContent: AdImpactDatePicker,
      content: (
        <div>
        <AdImpactChart
          adImpactRun={adImpactRun}
          isLoading={loading}
          selectedTab={selectedIdx}
          imageFileName='Linear_Television_Chart'
          csvFileName='Linear_Television'
        />
        <PlacementTable
        adImpactRun={adImpactRun}
        isLoading={loading}
       />
        </div>
      ),
    },
    {
      label: 'OTT',
      nestedContent: AdImpactDatePicker,
      content: (
        <AdImpactChart
          adImpactRun={adImpactRun}
          isLoading={loading}
          selectedTab={selectedIdx}
          imageFileName='OTT_Chart'
          csvFileName='OTT'
        />
      ),
    },
    {
      label: 'Social Media',
      nestedContent: AdImpactDatePicker,
      content: (
        <AdImpactChart
          adImpactRun={adImpactRun}
          isLoading={loading}
          selectedTab={selectedIdx}
          imageFileName='SocialMedia_Chart'
          csvFileName='SocialMedia'
        />
      ),
    },
    {
      label: 'Digital Video',
      nestedContent: AdImpactDatePicker,
      content: (
        <AdImpactChart
          adImpactRun={adImpactRun}
          isLoading={loading}
          selectedTab={selectedIdx}
          imageFileName='Digital_Video_Chart'
          csvFileName='Digital_Video'
        />
      ),
    },
    {
      label: 'Audio',
      nestedContent: AdImpactDatePicker,
      content: (
        <AdImpactChart
          adImpactRun={adImpactRun}
          isLoading={loading}
          selectedTab={selectedIdx}
          imageFileName='Audio_Chart'
          csvFileName='Audio'
        />
      ),
    },
    {
      label: 'Radio',
      nestedContent: AdImpactDatePicker,
      content: (
        <AdImpactChart
          adImpactRun={adImpactRun}
          isLoading={loading}
          selectedTab={selectedIdx}
          imageFileName='Radio_Chart'
          csvFileName='Radio'
        />
      ),
    },
  ];

  // Download CSV properties
  const runName = adImpactRun ? adImpactRun.wavecast_name : '';
  DownloadCSVFactory.setReportProperties(clientName, campaignName, runName);

  let description = 'Select an AdImpact analysis';
  if(selectedRun?.wavecast_name) {
    description = `Dashboard results for analysis: ${selectedRun.wavecast_name}`;
  }

  return (
    <FeatureLV
      data-test='ad-impact-dashboards'
      title='AdImpact Dashboards'
      description={description}
      tabs={tabs}
      tabsUrlQueryKey={TAB_URL_KEY.ADIMPACT_DASHBOARD}
      tabHeightOffset={180}>

    </FeatureLV>
  );
};
