import { useEffect, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import _ from 'lodash';

import {useQueryRequest} from '../../hooks/useQueryRequest';

import {urlBuilders} from '../../js/constants';
import {compareObjects} from '../../js/utils';
import {formatFilters} from '../../js/validation.js';
import {downloadFromURL} from '../../js/download';

//Getting the count of models and filters
export default function useMcaFilters(mcaRun={}, mcaVariables, formFilters={}, downloadAllModels=false, modelAnalysis=false, updateCount=true) {
    const mcaRunId = mcaRun.id;
    const mcaRunName = mcaRun.name;
    const partition = '' + mcaRun.partition;
    const encoding = mcaRun.encoding;

    const defaultFilters = mcaRun.includeIntercept !== false ? {intercept: true} : {}; //null or true
    const filters = formatFilters(formFilters || defaultFilters, mcaVariables);
    const payload = Object.assign({}, filters, {mcaRunId, partition, encoding});

    /** (1) GET MCA RUN COUNT */
    const [count, setCount] = useState();
    const countQueryUrl = urlBuilders.getMcaResultCount(mcaRunId);
    const countResultUrl = urlBuilders.getResultCount('query_uuid');
    const [{result: countResult = {count: -1}, loading: countLoading, error: countError}, fetchCount] = 
        useQueryRequest(countQueryUrl, countResultUrl, payload, 'Models Count');

    //fetch only for new filters and runs without saved metrics
    useDeepCompareEffect(() => {
        if (_.isEmpty(mcaRun) || _.isEmpty(formFilters)) {
            return;
        }
        const allIntercept = {intercept: false};            
        const positiveIntercept = {intercept: true};
        if (mcaRun.positiveInterceptCount && compareObjects(formFilters, positiveIntercept)) {
            setCount(mcaRun.positiveInterceptCount);
        } else if (mcaRun.totalCount && compareObjects(formFilters, allIntercept)) {
            setCount(mcaRun.totalCount);
        } else if (mcaRun.id && updateCount) {
            fetchCount();
        }
    }, [mcaRun, formFilters, updateCount, fetchCount]);

    useEffect(() => {
        if(countResult.count > -1) setCount(countResult.count);
    }, [countResult.count])

    /** Downloads */
    const modelsQueryUrl = urlBuilders.getMcaModels(mcaRunId);
    const downloadPayload = {
        mcaRunId,
        partition,
        encoding,
        ...filters
    };
    
    /** (2) Download all models with a specified file name */
    const downloadAllModelsResultUrl = urlBuilders.exportMca('query_uuid', 'csv', mcaRunName + "_results.csv");
    const [{result: downloadAllResult = {}, loading: downloadAllLoading, error: downloadAllError}, fetchDownloadAllModels] = 
        useQueryRequest(modelsQueryUrl, downloadAllModelsResultUrl, downloadPayload, 'Download All Models');

    useEffect(() => {
        if (downloadAllModels) {
            fetchDownloadAllModels();
        }
    }, [downloadAllModels, fetchDownloadAllModels]);

    useEffect(() => {
        if (downloadAllResult.url) {
            downloadFromURL(downloadAllResult.url);
        }
    }, [downloadAllResult.url]);

    /** (3) Download model analysis xlsx file */
    const [{modelAnalysisResult, loading: modelAnalysisLoading, error: modelAnalysisError}, fetchDownloadModelAnalysis] = 
        useQueryRequest(modelsQueryUrl, '', downloadPayload, 'Download Model Analysis', mcaRunName + "_modelAnalysis.xlsx");

    useEffect(() => {
        if (modelAnalysis) {
            fetchDownloadModelAnalysis();
        }
    }, [modelAnalysis, fetchDownloadModelAnalysis]);

    useEffect(() => {
        if (modelAnalysisResult) {
            downloadFromURL(modelAnalysisResult);
        }
    }, [modelAnalysisResult]);

    return {
        count, countLoading, countError,
        downloadAllLoading, downloadAllError,
        modelAnalysisLoading, modelAnalysisError
    };
}