/**
 * Return a blank data set from provided json
 */
export const getDummyData = (sample, rowCount=8) => {
  if(!sample) {
    return [];
  }

  let columnObj = sample;
  let rows = [];
  let template = {};

  if(Array.isArray(sample)) {
    columnObj = sample[0];
  }

  Object.keys(columnObj)
    .forEach(key => {
      template[key] = '';
    });

  for(let i=0; i<rowCount; i++) {
    const newRow = {
      ...template,
      id: Math.random()
    }
    rows.push(newRow);
  }
  return rows;
};
