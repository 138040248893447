import React from 'react';
import EditIcon from '@mui/icons-material/Create';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import {useUserRoleFromGroup} from '../../hooks/useUserRoleFromGroup';
import _ from 'lodash';
import {styles} from './RoleCell.styles';

/**
 * Used in Table to select a role (Partner, Client)
 */
export const RoleCell = ({
  cell={},
  onClick=()=>{},
  onEditClick=()=>{},
  selectedId,
  columnName,
  width=200,
  hasEdit=true
}) => {
  const row = _.get(cell, 'row.values');
  const {isAdmin, isAdvanced} = useUserRoleFromGroup();

  const handleClick = () => {
    onClick(row);
  };

  const handleEditClick = (event) => {
    event.stopPropagation();
    onEditClick(row, columnName);
  };

  // Determine if this cell is selected
  let selectedClass = '';
  let editClass = 'show-icon';
  if(row && row.id === selectedId) {
    selectedClass = 'selected';
  }

  if(!hasEdit) {
    editClass = '';
  }

  const editClient = isAdmin && columnName === 'Client';
  const editCampaign = isAdmin || (isAdvanced && columnName === 'Campaign');
  const editItem =
    <div
      className={`edit-icon ${editClass}`}
      data-test={`editClientIcon-${cell.value}`}
      onClick={handleEditClick}>

      <EditIcon
        fontSize='small'
      />
    </div>;

  return (
    <div
      data-test={`campaignManagementTable-${columnName}-${cell.value}`}
      className={styles.container}
      onClick={handleClick}
      style={{
        width: `${width}px`
      }}>

      <div
        className={`display ${editClass}`}>
          
        <div
          title={`${cell.value}`}
          className={`display-text ${selectedClass}`}>
          {cell.value}{isAdmin && row.hasCredentials ? <CloudDoneIcon className={styles.cloudIcon}/> : ''}
        </div>
      </div>
      
      {editClient || editCampaign ? editItem : null}

    </div>
  );
};
