import React from 'react';
import { useGet } from './useRest';
import { urlBuilders } from '../js/constants';

export const useKpiDropdownOptions = ({
    runId,
    campaignId,
    analysisType
}) => {

    const id = runId || campaignId;

    if (analysisType === 'Primary MMM' && runId) {
        analysisType = 'Rerun Primary MMM'
    }

    const [{
        data: kpiOptions = [],
        loading
    }, getKpiOptions] = useGet({
        url: urlBuilders.getKpis({
            id,
            type: analysisType
        }),
        manual: false
    });

    return {
        loading,
        kpiOptions,
        getKpiOptions
    };
};

