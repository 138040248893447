import {css} from 'emotion';

export const styles = {
    container: css`
        border-radius: 15px !important;
        fill: #ffffff;
        width: 548px;
        overflow: hidden !important;
    `
};
