import React from 'react';
import {
    R1ActionIcon, R1Button
} from '@rainagency/rain-one-soggy-loaf';
import {useUserRoleFromGroup} from "../../../hooks/useUserRoleFromGroup";
import StartNewModal from "../../new-analysis/start-new-modal/start-new-modal";
import {Add, Redo} from "@mui/icons-material";
import {Config} from "../../../config/ConfigFactory";
import {useFeatureSelect} from "../../../hooks/useFeatureSelect";
import {FEATURES} from "../../../constants/features";

/**
 * Open action on grid popover
 */
export const AdImpactConfigActionIcon = ({
                                            cell
                                        }) => {
    const { onFeatureSelect } = useFeatureSelect();

    const clickHandler = () => {
        onFeatureSelect({
            feature: FEATURES.SELECT_ANALYSIS,
            adImpactId: cell.row.id
        });
    };

    return (
        <R1ActionIcon
            label='Analysis Configuration'
            iconType='View'
            onClick={clickHandler} />
    );

};