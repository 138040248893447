import {css} from 'emotion';

export const styles = {
  selectMain: css`
    border-bottom: 0.5px solid #FFFFFF !important;
  `,
  selectRoot: css`
    height: 24px;
    padding-left: 2px;
    :before {
      border-bottom: 0.5px solid #FFFFFF !important;
    }
  `,
  selectField: css`
    display: block;
    width: 100%;
    font-family: 'Roboto', sans-serif !important;
    font-size: 12px !important;
    background: none;
    border: 0;
    color: #FFF !important;
    ::placeholder {
      opacity: 1;
    }
    &.suggested{
      color: #FF7863 !important;
    }
  `,
  menuItem: css`
    display: block;
    font-family: 'Roboto', sans-serif !important;
    font-size: 12px !important;
    width: 180px !important;
  `,
  checkboxItem: css`
    margin-left: 12px;
    font-family: 'Roboto', sans-serif !important;
    font-size: 12px !important;
    &.select-LV-\\? {
      background: #ff6b57;
      margin-left: 0;
      padding-left: 12px;
      color: white;
      svg {
        fill: white;
      }
      .Mui-checked {
        svg {
          fill: #a7da7b;
        }
      }
    }
  `,
  placeholder: css`
    color: #8C8C8C;
  `,

};
