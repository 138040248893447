import {css} from 'emotion';

export const styles = {
  root: css`
    background: #FFF;
    color: #000;
    width: 100%;
    height: 100%;
    display: block;
    overflow-y: hidden;
  `,
  ctaWrapper: css`
    position: absolute;
    margin-top: -20px;
    right: 42px;
    z-index: 10;
  `,
  tabContent: css`
    width: 100%;
    height: 100%;
  `
};
