import React from 'react';
import {ApplicationLayout, NAVIGATION_OPEN_STATE} from './components/layout/ApplicationLayout';
import {Route, useHistory, useLocation} from 'react-router-dom';
import LogRocket from 'logrocket';
import Wavecast from './pages/wavecast/wavecast';
import {UserContainer} from './pages/user-management/UserContainer';
import {UserGuide} from './pages/user-management/UserGuide';
import {CampaignSelection} from './pages/campaign-management/CampaignSelection';
import {useCampaignManagement} from './hooks/useCampaignManagement';
import {MarketingTools} from './pages/marketing-tools/marketing-tools';
import {McaContainer} from './pages/mca/McaContainer';
import {ManageDataTab} from './pages/manage-data/ManageDataTab';
import {AdImpactTab} from './pages/ad-impact/AdImpactTab';
import {DashboardTab} from './pages/dashboards/DashboardTab';
import {DashboardProvider} from './pages/dashboards/DashboardContext';
import {ScenarioPlannerDashboardsTab} from './pages/scenario-planner-dashboards/ScenarioPlannerDashboardsTab';
import {ScoringDashboardsTab} from './pages/scoring-dashboards/ScoringDashboardsTab';
import {McaSelected} from './pages/mca/McaSelected';
import {SelectAnalysis} from './pages/mca/select-analysis/select-analysis';
import {AdImpactDashboards} from './pages/dashboards/ad-impact/AdImpactDashboards';
import {USER_MENU_ACTIONS} from './components/layout/navigation/UserNavigation';
import {AuthToken} from './hooks/AuthFactory';
import {useZendesk} from './hooks/useZendesk';
import {getFeatureByPath} from './hooks/useProductFeatures';
import {useFeatureSelect} from './hooks/useFeatureSelect';
import {FEATURES, PRODUCT} from './constants/features';
import {HeaderProvider} from './hooks/HeaderProvider';
import {useLoggedInUser} from './hooks/useLoggedInUser';
import {useRuns} from './navigation-components/useRuns';
import {useBreadCrumbs} from './navigation-components/useBreadCrumbs';
import {useRecentActivity} from './hooks/useRecentActivity';
import {ScoringDashboardProvider} from "./pages/scoring-dashboards/ScoringDashboardContext";
import {Yeasty} from "./components/yeasty/Yeasty";
import ImagePopover from "./components/images/ImagePopover";

/**
 * Primary Leavened Application with navigation
 */
export const Leavened = () => {

    const location = useLocation() || {};
    const currentFeature = getFeatureByPath(location.pathname) || {};
    const {campaignId} = useCampaignManagement();
    const [yeastyOpen, setYeastyOpen] = React.useState(false);

    const toggleYeasty = (newOpen) => () => {
        setYeastyOpen(newOpen);
    };

    const {
        mcaRuns,
        selectedMcaRun,
        mcaLoading,
        adImpactRuns,
        adImpactRun,
        adImpactLoading,
    } = useRuns();

    const {
        currentUser = {},
        group
    } = useLoggedInUser() || {};

    // Left Navigation Feature Selection
    const {onFeatureSelect} = useFeatureSelect();

    // Top Navigation Handler
    const {topNavigationSelect} = useTopNavigationSelect(currentUser, toggleYeasty(true));

    // MMM/AdImpact Dropdown Select in Top Navigation
    const {onSelectAnalysis} = useSelectAnalysis();

    const {
        isRecentActivityLoading,
        recentActivity
    } = useRecentActivity();

    const isLoading = [
        mcaLoading, adImpactLoading
    ].some(s => s);

    const breadcrumbs = useBreadCrumbs(isLoading);

    const [isLogRocketSet, setIsLogRocketSet] = React.useState(false)

    // Add username to logrocket session
    if (currentUser?.id && !isLogRocketSet) {
        LogRocket.identify(currentUser?.id, {
            name: currentUser?.firstName + currentUser?.lastName,
            email: currentUser?.email,
        });
        setIsLogRocketSet(true);
    }

    // Persist open state of left navigation
    const openStateString = window.localStorage.getItem(NAVIGATION_OPEN_STATE) || 'true';
    const isOpen = openStateString === 'true';  // Force localStorage value to boolean

    return (
        <HeaderProvider>
            <DashboardProvider>
                <ApplicationLayout
                    group={group}
                    breadcrumbs={breadcrumbs}
                    onFeatureSelect={onFeatureSelect}
                    onUserNavigationSelect={topNavigationSelect}
                    firstName={currentUser.firstName}
                    lastName={currentUser.lastName}
                    email={currentUser.email}
                    isOpen={isOpen}
                    isColumn={true}
                    featureKey={currentFeature.key}
                    currentSelectedFeature={currentFeature}
                    onSelectAnalysis={onSelectAnalysis}
                    mmmRuns={mcaRuns}
                    selectedMMMRun={selectedMcaRun}
                    adImpactRuns={adImpactRuns}
                    selectedAdImpactRun={adImpactRun}
                    isRecentActivityLoading={isRecentActivityLoading}
                    recentActivity={recentActivity}
                    isHidden={!campaignId}
                    loading={isLoading}>

                    <Route exact path='/campaign-management' component={CampaignSelection}/>

                    <Route exact path='/maslow' component={Wavecast}/>

                    <Route exact path='/campaign/data-management' component={ManageDataTab}/>

                    <Route exact path='/campaign/dashboards' component={DashboardTab}/>

                    <Route exact path='/campaign/scenario-planner' component={ScenarioPlannerDashboardsTab}/>

                    {/* TODO: Adjust route component */}
                    <Route exact path='/campaign/scenario-optimizer'
                           render={() => <ScenarioPlannerDashboardsTab tabIndex={1}/>}/>

                    <ScoringDashboardProvider>
                        <Route path={'/campaign/scoring'} component={ScoringDashboardsTab}/>
                    </ScoringDashboardProvider>

                    <Route path={'/campaign/ad-impact'} component={AdImpactTab}/>

                    <Route exact path='/campaign/ad-impact-dashboards' component={AdImpactDashboards}/>

                    <Route exact path='/campaign/mca' component={McaContainer}/>

                    <Route exact path='/campaign/mca-selected' component={McaSelected}/>

                    <Route path='/marketing-tools' component={MarketingTools}/>

                    <Route path='/user-management' component={UserContainer}/>

                    <Route path='/user-guide' component={UserGuide}/>

                    <Route exact path='/campaign/select-analysis' component={SelectAnalysis}/>

                </ApplicationLayout>
            </DashboardProvider>

            <ImagePopover/>

            <Yeasty
                isOpen={yeastyOpen}
                close={toggleYeasty(false)}
                open={toggleYeasty(true)}
            />
        </HeaderProvider>
    );
};

const useTopNavigationSelect = (currentUser, openYeasty) => {
    const {openWidget} = useZendesk(currentUser);
    const history = useHistory();
    const location = useLocation() || {};

    const topNavigationSelect = (action) => {
        switch (action) {
            case USER_MENU_ACTIONS.USER_MANAGEMENT:
                history.push(
                    `${FEATURES.USER_MANAGEMENT.path}${location.search}`
                );
                break;
            case USER_MENU_ACTIONS.USER_GUIDE:
                history.push(
                    `${FEATURES.USER_GUIDE.path}${location.search}`
                );
                break;
            case USER_MENU_ACTIONS.REPORT_AN_ISSUE:
                openWidget();
                break;
            case USER_MENU_ACTIONS.LOGOUT:
            default:
                history.push('');
                AuthToken.logOut();
        }
    };

    return {
        topNavigationSelect
    };
};

const useSelectAnalysis = () => {
    const {setMcaRunId, setAdImpactId} = useCampaignManagement();

    const onSelectAnalysis = (analysis, product) => {
        if (product.key === PRODUCT.MMM.key) {
            setMcaRunId(analysis?.id);
        }

        if (product.key === PRODUCT.ADIMPACT.key) {
            setAdImpactId(analysis?.wavecast_id);
        }
    };

    return {
        onSelectAnalysis
    };

};
