import {css} from 'emotion';

export const styles = {
  container: css`
    cursor: pointer;
    padding: 12px 0;
    display: flex;

    &:hover {
      .edit-icon.show-icon {
        display: flex;
      }

      .display.show-icon {
        max-width: calc(100% - 36px);
      }
    }

    .display {
      display: flex;
      flex-grow: 1;
      height: 24px;
      width: 100%;

      .display-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;      
        color: #333333;
        font-size: 14px;
        line-height: 24px;
        display: inline-block;

        &.selected {
          background-color: #F2F2F2;
          border-radius: 160px;   

          padding: 0 8px;
          margin-left: -8px;
        }
      }
    }

    .edit-icon {
      display: none;
      align-items: center;
      justify-content: center;

      margin-left: 12px;
      min-width: 24px;
      min-height: 24px;

      .MuiSvgIcon-fontSizeSmall {
        font-size: 16px;
      }

      &:hover {
        background-color: #F2F2F2;
        border-radius: 24px;
      }
    }
  `,
  cloudIcon: css`
    padding-left: 12px;
    font-size: 18px !important;
  `
};
