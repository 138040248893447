import { useEffect, useState } from 'react';
import io from 'socket.io-client';
import {useLoggedInUser} from "../../../hooks/useLoggedInUser";
import uuid from "react-uuid";

const useSocket = (serverUrl) => {
    const [socket, setSocket] = useState(null);
    const [connectionError, setConnectionError] = useState();
    const {
        currentUser = {}
    } = useLoggedInUser() || {};

    useEffect(() => {

        const socketIo = io(serverUrl, {
            extraHeaders: {
                // TODO - replace with actual API key
                api_key: currentUser?.yeastyApiKey || 'api-key',
                room_id: currentUser?.id || uuid()
            }
        });

        setSocket(socketIo);

        return () => {
            socketIo.disconnect();
        };
    }, [serverUrl]);

    useEffect(() => {
        if (!socket) {
            return;
        }

        socket.on("connect_error", (error) => {
            if (!socket.active) {
                console.error("Socket connection error: ", error);
                setConnectionError(error);
            }
        });
    }, [socket]);

    return {
        socket,
        connectionError
    };
};

export default useSocket;
