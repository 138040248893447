import {useUserRoleFromGroup} from './useUserRoleFromGroup';

export const useGetUserPrivileges = () => {
  const {isAdmin, isAdvanced, isTrial, userType} = useUserRoleFromGroup();
  let userPrivilege = {
    canAddPartner: false,
    canAddCompany: false,
    canAddCampaign: false,
    canViewPartner: false,
    canViewCompany: false,
    canAddUser: false,
    canUpload: false,
    canRunAnalysis: false,
    canSeeDashboard: true,
  };

  if(!isTrial) {
    //View permissions
    if(userType === 'System') {
      userPrivilege.canViewPartner = true;
    }

    if(userType === 'System' || userType === 'Partner') {
      userPrivilege.canViewCompany = true;
    }
  }

  //add/upload permissions
  if(isAdmin) {
    userPrivilege.canAddUser = true;
    if(userType === 'System' || userType === 'Partner') {
      userPrivilege.canAddCompany = true;
    }

    if(userType === 'System') {
      userPrivilege.canAddPartner = true;
    }
  }

  if(isAdmin || isAdvanced) {
    userPrivilege.canAddCampaign = true;
  }

  if(isAdmin || isAdvanced || isTrial) {
    userPrivilege.canUpload = true;
    userPrivilege.canRunAnalysis = true;
  }

  return userPrivilege;
}