import React, { useState, useRef } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import _ from 'lodash';
import useOutputsInsights from '../../useOutputsInsights';
import {openDialog} from '../../../../modules/platform/dialog';
import downloadIcon from '../../../../assets/output-down-arrow.png';
import downloadArchiveIcon from '../../../../assets/download-checked.svg';
import Spinner from '../../../../components/spinner';
import {TableLV} from '../../../../components/data-table/TableLV';
import {CheckboxLV} from '../../../../components/form/CheckboxLV';
import ModalLV from '../../../../components/modal/ModalLV';
import {formatRoiResults} from '../../../../js/validation';

import DownloadMenu from './download-menu';
import {McaDetails} from './mca-details';

import {styles} from './mca-models-table.styles';

export default function McaModelsTable(props) {
    const {
        models, setDownloadModelIds, downloadAll, isDownloading, history, mcaRunId, runName, includeIntercept, 
        mcaVariables, kpi, kpiType, queryId, showModels, setInsightsStatus, startInsights, setStartInsights} = props;
    
    const [selectedModels, setSelectedModels] = useState({});
    const inReviewModels = useRef([]);
    const [inReviewTable, setInReviewTable] = useState();
    const [fetchInsights, setFetchInsights] = useState(showModels);

    const sortedInReviewModels = useRef([]);

    const dispatch = useDispatch();

    const modelIds = models.map(model => Number(model.modelid));
    const {
        insightsLoading,
        completedInsights,
        insightsStatus,
        downloadArchive,
        insightsErrors,
        roiResults={data: []},
        fitResults={data: []}
    } = useOutputsInsights(mcaRunId, includeIntercept, queryId, modelIds, startInsights, setStartInsights, fetchInsights);

    React.useEffect(() => {
        if(['done', 'enabled'].includes(insightsStatus) || insightsErrors) {
            setFetchInsights(false);
        }
        setInsightsStatus(insightsStatus);
    }, [insightsStatus, insightsErrors]);

    // download of model outputs and insights
    const downloadInsights = (modelId) => {
        downloadArchive(modelId, `${runName}_${modelId}.zip`);
    };

    let allCompletedInsights = completedInsights.slice();
    const setInsights = (newInsight) => {
        allCompletedInsights = [new Set([...allCompletedInsights, newInsight])];
    }

    const onMcaDetailsClose = () => {
        if(!_.isEqual(allCompletedInsights, completedInsights)) {
            setInsightsStatus();
            setFetchInsights(true);
        }
    }

    const roiMap = React.useMemo(
        () => formatRoiResults(roiResults.data), 
        [roiResults.data]
    );

    const fitData = React.useMemo(
        () => fitResults.data, 
        [fitResults.data]
    );

    useDeepCompareEffect(() => {
        //initialize all models to not selected
        if (models.length) {
            let selectedModelIds = {};
            for (let model of models) {
                selectedModelIds = Object.assign(selectedModelIds, {[model.modelid]: false});
            }
            //Remove rejected models
            inReviewModels.current = models.filter((model) => model.status === 'In Review');
            setSelectedModels(selectedModelIds);
        }
    }, [models]);

    const allVariables = mcaVariables.reduce((variables, group) => variables.concat(group.variables), []);
    const mediaVariables = allVariables.filter(variable => !['Binary', 'Control'].includes(variable.measure));
    const mediaNames = mediaVariables.map(media => media.variableName);
    
    //table updates
    useDeepCompareEffect(() => {
        if (models.length) {
            const parsedInReviewModels = inReviewModels.current.map((model, index) => {
                const modelVariables = model.details.filter(data => mediaNames.includes(data.variable) )
                const prctContribution = modelVariables.reduce((contributionSum, variable) => contributionSum + Number(variable.prctContribution), 0);
                return {
                    index: index +1, 
                    modelid: model.modelid,
                    variables: modelVariables.length,
                    rsq: `${model.rsq}%`, 
                    mape: `${model.mape}%`, 
                    durbinwatson: model.durbinwatson,
                    prctContribution: `${prctContribution.toFixed(1)}%`,
                    selectAll: '',
                    downloadMenu: '',
                    downloadInsights: model.downloadInsights
                }
            });
            setInReviewTable(buildTable(parsedInReviewModels, 'In Review'));
        }
    }, [allCompletedInsights, selectedModels, roiResults, fitResults]);

    const handleChecked = (modelId, value) => {
        let updatedModelIds = Object.assign({}, selectedModels);
        if (modelId === 'selectAll') {
            for (let key in selectedModels) {
                updatedModelIds[key] = value;
            }
        } else {
            updatedModelIds[modelId] = value;
        }
        setSelectedModels(updatedModelIds);
    }

    const approveModel = (modelId) => {
        dispatch(openDialog('Approve', {modelId, history}));
    }

    const downloadSelected = (modelId) => {
        let  selectedModelIds = [];
        //set to download single model id by click on icon
        if (modelId) {
            selectedModelIds.push(modelId);
        } else {
            //set to download selected models
            for (let key in selectedModels) {
                if (selectedModels[key]) {
                    selectedModelIds.push(key);
                }
            }
        }
        setDownloadModelIds(selectedModelIds);
    }

    const buildTable = (data, type) => {
        //check if anything is in review or just rejected
        let headersOnly = false;
        if ((!Array.isArray(data) || !data.length) && type === 'In Review') {
            data.push({
                index: '', 
                modelid: '',
                variables: '',
                rsq: '', 
                mape: '', 
                durbinwatson: '',
                prctContribution: '',
                selectAll: '',
                downloadMenu: ''
            });
            headersOnly = true;
        } else if (!Array.isArray(data) || !data.length) {
            return null;
        }
        
        //custom headers
        const selectAllBox = <CheckboxLV darkTheme={false} disabled={headersOnly || isDownloading} 
            checked={!headersOnly && Object.keys(selectedModels).every(checkbox => selectedModels[checkbox])}
            onChange={(_event, value) => handleChecked('selectAll', value)}/>
        
        const anySelected = Object.keys(selectedModels).some(checkbox => selectedModels[checkbox]);
        const downloadMenuIcon = <DownloadMenu disabled={headersOnly || isDownloading}
            modelsSelected={anySelected} downloadSelected={() => downloadSelected()} downloadAll={downloadAll}/>

        //rows
        const CellAction= ({cell, className}) => {
            const value = cell.value;
            const modelId = cell.row.values.modelid;
            const models = sortedInReviewModels.current.map(sortedModelIndex => inReviewModels.current[sortedModelIndex]) || [];
            return (
                <ModalLV
                    isDark={false}
                    className={styles.modal}
                    onClose={onMcaDetailsClose}
                    >
                    <div className={className}>{value}</div>
                    <McaDetails selectedModelId={modelId} models={models} downloadSelected={(modelId) => downloadSelected(modelId)} 
                        isDownloading={isDownloading || insightsLoading} approveModel={(modelId) => approveModel(modelId)} 
                        kpi={kpi} kpiType={kpiType} roiMap={roiMap} fitData={fitData} setInsights={setInsights} 
                        queryId={queryId} includeIntercept={includeIntercept}/>
                </ModalLV>);
        };

        const WrappedCheckbox = (({cell}) => 
            <CheckboxLV darkTheme={false} checked={selectedModels[cell.row.values.modelid]}
                onChange={(event, value) => handleChecked(cell.row.values.modelid, value)}/>
        );
        const WrappedDowloadIcon = (({cell, className}) =>
            <img className={className} src={downloadIcon} alt='Download CSV' onClick={() => downloadSelected(cell.row.values.modelid)}/>
        );
        const WrappedInsightsDowloadIcon = (({cell, className}) => {
            const modelid = _.get(cell, 'row.values.modelid');
            if (allCompletedInsights.find(modelId => modelId === Number(modelid))) {
                inReviewModels.current.find(model => model.modelid === modelid)['downloadInsights'] = modelid;
                return (<img className={className} src={downloadArchiveIcon} alt='Download Insights Zip' onClick={() => downloadInsights(modelid)}/>); 
            } else {
                return null;
            }
        });

        const columnOverride = {
            index: {
                component: <CellAction className='index-cell'/>,
                name: '#',
                align: 'center',
                disableSorting: true,
                hidden: true
            },
            modelid: {
                component: <CellAction className='modelId-cell'/>,
                name: <div style={{paddingLeft: '16px'}}>MODEL #</div>
               
            },
            variables: {
                component: <CellAction className='variables-cell'/>,
                name: 'MEDIA VARIABLES'
            },
            rsq: {
                component: <CellAction className='value-cell'/>,
                name: 'R SQUARED'
            },
            mape: {
                component: <CellAction className='value-cell'/>,
                name: 'MAPE',
            },
            prctContribution: {
                component: <CellAction className='value-cell'/>,
                name: 'MEDIA CONTRIBUTION',
            },
            durbinwatson: {
                component: <CellAction className='value-cell'/>,
                name: 'DURBIN WATSON',
            },
            selectAll: {
                component: <WrappedCheckbox/>,
                name: selectAllBox,
                disableSorting: true,
                style: {
                    width: '50px'
                }
            },
            downloadMenu: {
                component: <WrappedDowloadIcon className='download-icon'/>,
                name: downloadMenuIcon,
                headerStyle: {
                    paddingBottom: '0'
                },
                align: 'center',
                disableSorting: true
            },
            downloadInsights: {
                component: <WrappedInsightsDowloadIcon className='download-icon'/>,
                name: 'INSIGHTS',
                headerStyle: {
                    paddingBottom: '0'
                },
                align: 'center',
                disableSorting: true,
                hidden: !allCompletedInsights.length
            }
        };

        const setSortedModels = (sortedRows) => {
            if (sortedRows.length) {
                sortedInReviewModels.current = sortedRows.map(row => row.values.index -1);
            }
        };

        let tableClassName = 'review';
        if (headersOnly) {
            tableClassName = tableClassName + ' disabled';
        }

        return ( 
            <div data-test='mca-models-table-container' className={styles.tableContainer}>
                <TableLV
                    dataTest='mca-model-comparison-table'
                    data={data}
                    override={columnOverride}
                    className={tableClassName}
                    headersOnly={headersOnly}
                    setSortedData={setSortedModels}
                /> 
            </div>
        );
    }

    return ((fetchInsights || insightsLoading) ? <div className={styles.spinnerContainer}><Spinner/></div> : 
        <div>
            {inReviewTable}
        </div>
    );       
    
}

McaModelsTable.propTypes = {    
    downloadAll: PropTypes.func
};

export {McaModelsTable};
