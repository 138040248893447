import {css} from 'emotion';

export const styles = {
  content: css`

    .interval-table-container {
      display: grid;
      
      grid-template-columns: 1fr 11fr;
      column-gap: 80px;

      .interval-rollup-table-container {
        overflow-x: scroll;
        white-space: nowrap;

        .interval-table {
          .label-row {
            position: sticky;
            left: 0;
            background: #FFF;
            z-index: 1;
          }

          tr:last-child {
            td {
              font-weight: bold;
            }
          }

        }
      }
    }
  `
};
