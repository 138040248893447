import {css} from 'emotion';

export const styles = {
  statsTable: css`
    width: 100%;
    display: grid;
    grid-template-columns: 6fr 6fr;
    gap: 24px;

    .model-table {
      th {
        display: none;
      }
    }

  `
};
