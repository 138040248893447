import { styled } from '@mui/system';
import {
  DonutLarge,
  TrackChanges,
  ArrowForward,
  SettingsSuggest,
  FactCheck
} from '@mui/icons-material';
import {
  Tooltip,
  Typography,
  CardActionArea,
  CircularProgress
} from '@mui/material';
import { RECENT_ACTIVITY_TYPES } from '../../../../hooks/useRecentActivity';
import { FEATURES } from '../../../../constants/features';

export const RecentActivity = ({
  recentActivity = [],
  setSelectedFeature,
  isRecentActivityLoading = false,
  isColumn
}) => {

  return (
    <RecentActivityStyled
      data-testid='recent-activity-container'
      className='recent-activity-container'>

      <Typography
        sx={{
          display: recentActivity.length ? 'block': 'none'
        }}
        variant={'h5'}
        component='div'>

        Recent Activity

      </Typography>

      <LoadingSpinner loading={isRecentActivityLoading}/>

      <ActivityButtonContainerStyled
        breakpoint={isColumn ? 'xl' : 'lg'}
        className='recent-activity-buttons-container'>
        
        <RecentActivityContent
          loading={isRecentActivityLoading}
          recentActivity={recentActivity}
          setSelectedFeature={setSelectedFeature}
        />

      </ActivityButtonContainerStyled>

    </RecentActivityStyled>
  );
};

const LoadingSpinner = ({
  loading
}) => {
  if (!loading) {
    return null;
  }

  return (
    <CircularProgressStyled
      className='feature-spinner'
      color='inherit'/>
  );
};

const CircularProgressStyled = styled(CircularProgress)(({
  theme
}) => ({
  position: 'relative',
  left: '50%',
  right: '50%',
  top: '24px',
  color: '#979797',
  opacity: '0.6',
}));

const RecentActivityContent = ({
  recentActivity = [],
  setSelectedFeature={setSelectedFeature},
  loading
}) => {

  if(loading) {
    return null;
  }

  return recentActivity.map((activity, index) => (
    <RecentActivityButton
      setSelectedFeature={setSelectedFeature}
      key={`recent-activity-${index}`}
      {...activity}
    />
  ));
};

const RecentActivityButton = ({
  title = '',
  client,
  campaign,
  partner,
  date,
  setSelectedFeature = () => {},
  feature,
  query,
  type,
  onClick = () => {},
}) => {

  const clientName = client?.display;
  const campaignName = campaign?.display;

  const breadcrumbs = [clientName, campaignName].filter(c => c).join(' / ');
  const tooltipTitle = title.length > 30 ? title : '';  // Display tooltip on long titles

  const handleClick = () => {
    setSelectedFeature({
      feature,
      partner,
      client,
      campaign,
      query
    });

    onClick();
  };

  return (
    <RecentActivityButtonStyled
      className='recent-activity-button'
      data-testid={type}
      aria-label={title}
      onClick={handleClick}>

      <IconWrapperStyled>
        <ActivityIcon type={type} />
      </IconWrapperStyled>

      <LabelStyled>

        <Tooltip title={tooltipTitle}>
          <Typography
            noWrap={true}
            className='action-title'
            variant={'subtitle2'}>
            {title}
          </Typography>
        </Tooltip>

        <Typography
          noWrap={true}
          className='action-client-campaign'
          variant={'caption'}>
          {breadcrumbs}
        </Typography>

      </LabelStyled>

      <DateColumn
        date={date}
      />

      <GoButtonStyled>
        GO <ArrowForward style={{fontSize:'20px'}}/>
      </GoButtonStyled>

    </RecentActivityButtonStyled>
  );
};

const DateColumn = ({
  date
}) => {
  const dateString = new Date(date).toDateString();
  return (
    <DateColumnStyled>
      {dateString}
    </DateColumnStyled>
  );
};

const ActivityIcon = ({
  type
}) => {
  if(type === RECENT_ACTIVITY_TYPES.ADIMPACT) {
    return (
      <Tooltip title={FEATURES.ADIMPACT_ANALYSIS.ariaLabel}>
        <TrackChanges/>
      </Tooltip>
    );
  }

  if(type === RECENT_ACTIVITY_TYPES.SCORING) {
    return (
      <Tooltip title={FEATURES.SCORING.ariaLabel}>
        <FactCheck/>
      </Tooltip>
    );
  }

  if(type === RECENT_ACTIVITY_TYPES.CONFIGAI) {
    return (
      <Tooltip title={'ConfigAI'}>
        <SettingsSuggest/>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={FEATURES.MMM_ANALYSIS.ariaLabel}>
      <DonutLarge />
    </Tooltip>
  );
};

const GoButtonStyled = styled('div')(({
  theme,
}) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.primary.main,
  border: '1px solid #9DAECF',
  borderRadius: 4,
  height: 32,
  fontSize: 14,
  fontWeight: 500
}));

const DateColumnStyled = styled('div')(({
  theme,
}) => ({
  textAlign: 'center',
  padding: '0 16px',
  color: theme.palette.text.secondary,
}));

const IconWrapperStyled = styled('div')(({
  theme,
}) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#FFF',
  height: 40,
  width: 40,
  background: theme.palette.secondary.main,
  borderRadius: 4,
}));

const LabelStyled = styled('div')(({
  theme,
}) => ({
  display: 'inline-flex',
  flexDirection: 'column',
  padding: '2px 16px 0 32px',
  width: 'auto',

  '& .action-title': {
    maxWidth: '250px',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '400px',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '150px',
    }
  },

  '& .action-client-campaign': {
    color: '#979797',
    marginTop: '-4px',
    maxWidth: '250px',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '400px',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '150px',
    }
  }
}));

const RecentActivityStyled = styled('div')(({
  theme,
}) => ({
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
}));

const ActivityButtonContainerStyled = styled('div')(({
  theme,
  breakpoint = 'lg'
}) => ({
  paddingTop: 12,
  display: 'grid',
  gridTemplateColumns: '6fr 6fr',
  overflowY: 'hidden',
  maxHeight: (breakpoint === 'lg') ? 256 : 320,
  [theme.breakpoints.down(breakpoint)]: {
    gridTemplateColumns: '12fr',
  }
}));

const RecentActivityButtonStyled = styled(CardActionArea)(({
  theme,
}) => ({
  display: 'grid',
  gridTemplateColumns: '40px 1fr 100px 64px',
  height: 64,
  borderBottom: `1px solid ${theme.palette.action.disabledBackground}`,
  padding: '0 16px 0 16px',
  '&:hover': {
    background: '#E1EFFC'
  }
}));
