import React from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import {useUserRoleFromGroup} from '../../hooks/useUserRoleFromGroup';
import {TableLV} from '../data-table/TableLV';
import {RoleCell} from './RoleCell';
import {styles} from './CampaignManagement.styles';

/**
 * Select Partner/Client/Campaign
 */
export const CampaignManagement = ({
  data = [],
  onEditClick=()=>{},
  partnerSelect=()=>{},
  clientSelect=()=>{},
  campaignSelect=()=>{},
  currentSelectedPartner={},
  currentSelectedClient={},
  currentSelectedCampaign={},
  displayPartner=true,
  displayClient=true,
  isLoading=false
}) => {
  const {isTrial} = useUserRoleFromGroup();

  const [selectedPartner, setSelectedPartner] = React.useState(currentSelectedPartner);
  const [selectedClient, setSelectedClient] = React.useState(currentSelectedClient);
  const [selectedCampaign, setSelectedCampaign] = React.useState(currentSelectedCampaign);

  /**
   * Effects to ensure parent selections are reflected
   * useDeepCompareEffect() is used as React.useEffect() does not compare objects very well
   */
  useDeepCompareEffect(() => {
    setSelectedPartner(currentSelectedPartner);
  }, [currentSelectedPartner, setSelectedPartner]);

  useDeepCompareEffect(() => {
    setSelectedClient(currentSelectedClient);
  }, [currentSelectedClient, setSelectedClient]);

  useDeepCompareEffect(() => {
    setSelectedCampaign(currentSelectedCampaign);
  }, [currentSelectedCampaign, setSelectedCampaign]);

  /**
   * Selected Data Maps
   */
  let partners = React.useMemo(() => data.map(
    ({display, id, companies}) => ({
      display,
      id,
      companies
    })
  ), [data]);

  const selectedPartnerClients = selectedPartner.companies || [];
  let clients = React.useMemo(() => selectedPartnerClients.map(
    client => ({
      id: client.id,
      display: client.display,
      companyName: client.companyName,
      partnerId: client.partnerId,
      contractStartDate: client.contractStartDate,
      campaigns: client.campaigns,
      hasCredentials: client.hasCredentials
    })
  ), [selectedPartnerClients]);

  const selectedClientCampaigns = selectedClient.campaigns || [];
  let campaigns = React.useMemo(() => selectedClientCampaigns.map(
    campaign => ({
      display: campaign.display,
      id: campaign.id,
      startDate: campaign.startDate,
      endDate: campaign.endDate,
      campaignName: campaign.campaignName,
      companyId: campaign.companyId
    })
  ), [selectedClientCampaigns]);

  if (isTrial && campaigns.length) {
    campaigns = [campaigns[0]];
  }

  /**
   * Column selection handlers
   * Be sure selected row is sent to parent and other columns are cleared accordingly
   */
  const partnerHandler = row => {
    setSelectedPartner(row);
    partnerSelect(row);
  };

  const clientHandler = row => {
    setSelectedClient(row);
    clientSelect(row);
  };

  const campaignHandler = row => {
    setSelectedCampaign(row);
    campaignSelect(row);
  };

  /**
   * Table Overrides
   */
  const partnerOverride = {
    display: {
      name: 'Partners',
      headerClassName: 'display-column',
      component: <RoleCell
                   onClick={partnerHandler}
                   onEditClick={onEditClick}
                   columnName={'Partners'}
                   selectedId={selectedPartner.id}
                   hasEdit={false}
                 />
    },
    id: {
      hidden: true
    },
    companies: {
      hidden: true
    }
  };

  const clientOverride = {
    display: {
      name: 'Clients',
      headerClassName: 'display-column',
      component: <RoleCell
                   onClick={clientHandler}
                   onEditClick={onEditClick}
                   columnName={'Client'}
                   selectedId={selectedClient.id}
                 />
    },
    id: {
      hidden: true
    },
    campaigns: {
      hidden: true
    },
    companyName: {
      hidden: true
    },
    contractStartDate: {
      hidden: true
    },
    partnerId: {
      hidden: true
    },
    hasCredentials: {
      hidden: true
    }
  };

  const campaignOverride = {
    display: {
      name: 'Campaigns',
      headerClassName: 'display-column',
      headerStyle: {
        width: '400px'
      },
      component: <RoleCell
                   onClick={campaignHandler}
                   onEditClick={onEditClick}
                   columnName={'Campaign'}
                   selectedId={selectedCampaign.id}
                   width={400}
                 />
    },
    startDate: {
      style: {
        width: '100px'
      }
    },
    id: {
      hidden: true
    },
    campaignName: {
      hidden: true
    },
    companyId: {
      hidden: true
    }
  };

  /**
   * Table Defaults
   * Maintain table column visibility on empty data
   */
  let partnerDisplay = '';
  let clientDisplay = '';
  let campaignsDisplay = '';
  let partnerStyle = {};
  let clientStyle = {};

  if(!partners.length) {
    partnerDisplay = 'hide-rows';
    partners = dummyTableData.partners;
  }

  if(!clients.length) {
    clientDisplay = 'hide-rows';
    clients = dummyTableData.clients;
  }

  if(!campaigns.length) {
    campaignsDisplay = 'hide-rows';
    campaigns = dummyTableData.campaigns;
  }

  if(!displayPartner) {
    partnerStyle = {
      display: 'none'
    };
  }

  if(!displayClient) {
    clientStyle = {
      display: 'none'
    };
  }

  const mainClasses = [styles.container];
  if(!data.length && !isLoading) {
    mainClasses.push('no-data');
  }

  /**
   * Markup
   */
  return (
    <div className={mainClasses.join(' ')}>

      <div
        className={`single-column no-border ${partnerDisplay}`}
        data-test={'partners-column'}
        style={partnerStyle}>

        <TableLV
          data={partners}
          override={partnerOverride}
          isLoading={isLoading}
          dataTest='partners-table'
        />
      </div>

      <div
        className={`single-column no-border ${clientDisplay}`}
        data-test={'clients-column'}
        style={clientStyle}>

        <TableLV
          data={clients}
          override={clientOverride}
          isLoading={isLoading}
          dataTest='clients-table'
        />
      </div>

      <div className={`campaign-column ${campaignsDisplay}`}
           data-test={'campaign-column'}>
        <TableLV
          data={campaigns}
          override={campaignOverride}
          isLoading={isLoading}
          dataTest='campaign-table'
        />
      </div>

    </div>
  );
};

export const dummyTableData = {
  partners: [{display: '', id: ''}],
  clients: [{display: '', id: ''}],
  campaigns: [{display: '', id: '', startDate: '', endDate: ''}]
};
