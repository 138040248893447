import {css} from 'emotion';
import {commonStyles} from '../../styles/commonStyles';

export const styles = {
  backText: css`
    ${commonStyles.fontFamily}
    display: flex;
    height: 14px;
  `,
  crumb: css`
    margin-right: 8px;
    ${commonStyles.fontFamily}
    color: #FFF;
  `,
  crumbArrow: css`
    ${commonStyles.fontFamily}
    font-weight: 300;
    color: rgba(242, 242, 242, 0.3);
    line-height: 14px;
  `
};
