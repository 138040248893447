import React from 'react';
import {urlBuilders} from '../js/constants';
import {usePost} from './useRest';
import {downloadFromURL} from '../js/download';

/**
 * POST to get template url to download
 * @param id id of mca run
 * @param category template category
 * @param fileName download file name
 * @returns triggerDownload fires POST request and starts download on url return
 */
export const useDownloadTemplateFile = ({
                                          id,
                                          category,
                                          fileName
                                        } = {}) => {

  const [{data: body={}}, downloadTemplate] = usePost({
    url: urlBuilders.postDownloadDataTemplate(),
    manual: true,
    data: {
      category,
      id,
      fileName
    }
  });

  React.useEffect(() => {
    if (body.url) {
      downloadFromURL(body.url);
    }
  }, [body.url]);

  const triggerDownload = () => {
    return downloadTemplate();
  };

  return {
    triggerDownload
  };
};
