import React from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import {RadioButtonLV} from '../form/RadioButtonLV';
import {DATE_SPAN} from '../../constants/dates';
import {styles} from './IntervalRadioSelect.styles';

/**
 * Radio buttons that select WEEKLY, MONTHLY, QUARTERLY
 * @param dateSpan Value from contant, EX: WEEKLY, MONTHLY
 * @param onChecked Function when user selects a radio button
 * @param title Optional title to display
 * @param isHorizontal Radio buttons will be vertical by default
 * @param hasNoneButton Display a "None" Radio button when true
 * @param isDisabled disable radio buttons
 */
export const IntervalRadioSelect = ({
  dateSpan=DATE_SPAN.QUARTERLY,
  onChecked=()=>{},
  title,
  isHorizontal,
  hasNoneButton,
  isDisabled=false
}) => {
  const horizontalCss = isHorizontal ? 'horizontal' : '';
  return (
    <div className={`interval-radio-button-container ${styles.content} ${horizontalCss}`}>

      <IntervalTitle title={title}/>

      <RadioGroup
        row
        name='timeInterval'
        value={dateSpan}
        onChange={(event) => onChecked(event.target.value)}>
        <RadioButtonLV 
          value={DATE_SPAN.NONE}
          label={'None'}
          checked={dateSpan === DATE_SPAN.NONE}
          disabled={isDisabled}
          darkTheme={false}
          dataTest={DATE_SPAN.NONE}
          isHidden={!hasNoneButton}
        />
        <RadioButtonLV 
          value={DATE_SPAN.WEEKLY}
          label={'Weekly'}
          checked={dateSpan === DATE_SPAN.WEEKLY}
          disabled={isDisabled}
          darkTheme={false}
          dataTest={DATE_SPAN.WEEKLY}
        />
        <RadioButtonLV 
          value={DATE_SPAN.MONTHLY}
          label={'Monthly'}
          checked={dateSpan === DATE_SPAN.MONTHLY}
          disabled={isDisabled}
          darkTheme={false}
          dataTest={DATE_SPAN.MONTHLY}
        />
        <RadioButtonLV 
          value={DATE_SPAN.QUARTERLY}
          label={'Quarterly'}
          checked={dateSpan === DATE_SPAN.QUARTERLY}
          disabled={isDisabled}
          darkTheme={false}
          dataTest={DATE_SPAN.QUARTERLY}
        />
      </RadioGroup>

    </div>
  );
};

const IntervalTitle = ({title}) => {
  if(!title) {
    return null;
  }

  return (
    <div className='container-label'>
      {title}
    </div>
  );
};
