import {css} from 'emotion';
import {RUN_STATUS} from '../../../constants/mca-run-statuses';

const styles = {
  cell: css`
    color: #FFF;
    width: 200px;
    height: 40px;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    @keyframes pulsate {
      0% { 
        opacity: 0.5;
      }
      50% { 
        opacity: 1.0;
      }
      100% { 
        opacity: 0.5;
      }
    }

  `,
};

styles[RUN_STATUS.COMPLETED] = css`
    background-color: #A9D980;
  `;
styles[RUN_STATUS.COMPLETE] = css`
    background-color: #A9D980;
  `;
styles[RUN_STATUS.PROCESSING] = css`
    background-color: #56CCF2;
    animation-iteration-count: infinite;
    animation-duration: 3s;
    animation-name: pulsate;
  `;
styles[RUN_STATUS.RUNNING] = css`
    background-color: #56CCF2;
    animation-iteration-count: infinite;
    animation-duration: 3s;
    animation-name: pulsate;
  `;
styles[RUN_STATUS.FINALIZING] = css`
    background-color: #56CCF2;
  `;
styles[RUN_STATUS.SELECT_MODEL] = css`
    background-color: #304658;
  `;
styles[RUN_STATUS.ERROR] = css`
    background-color: #FF6B57;
  `;
styles[RUN_STATUS.ERRORS] = css`
    background-color: #FF6B57;
  `;
styles[RUN_STATUS.CANCELLED] = css`
    background-color: #ACB8B8;
  `;
styles[RUN_STATUS.UNKNOWN] = css`
    background-color: #ACB8B8;
  `;
styles[RUN_STATUS.SUCCESS] = css`
    background-color: #A9D980;
  `;
styles[RUN_STATUS.WARNING] = css`
    background-color: #56CCF2;
  `;
styles[RUN_STATUS.PENDING] = css`
    background-color: #56CCF2;
  `;
styles[RUN_STATUS.FAILED] = css`
    background-color: #FF6B57;
  `;
styles[RUN_STATUS.SCORING_ERROR] = css`
    background-color: #FF6B57;
  `;
styles[RUN_STATUS.INITIATED] = css`
    background-color: #304658;
    animation-iteration-count: infinite;
    animation-duration: 3s;
    animation-name: pulsate;
  `;
export {styles};
