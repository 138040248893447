import React from 'react';
import {TabContentLV} from '../../../../components/tabs/TabContentLV';
import {DataTabs} from '../../../../components/tabs';
import Spinner from '../../../../components/spinner';
import {OrangeButtonLV} from '../../../../components/form/buttons/OrangeButtonLV';
import {formatRoiResults} from '../../../../js/validation';
import {useMcaRuns} from '../../../../hooks/useMcaRuns';
import useOutputsInsights from '../../useOutputsInsights';

import McaDetailsTable from './McaDetailsTable';
import {McaDetailsChart} from './McaDetailsChart';

import {styles} from './McaDetailsTab.styles';

export default function McaDetailsTab({selectedModel={}, kpiType, hasInsights, roiMap=[], fitData=[],
  queryId, includeIntercept, setInsights, testTabIndex}) {
  const modelId = Number(selectedModel.modelid);

  const [tabIndex, setTabIndex] = React.useState(0);
  const {selectedMcaRun={}} = useMcaRuns();

  const [startInsights, setStartInsights] = React.useState(false);
  const [modelInsights, setModelInsights] = React.useState(hasInsights);

  const {
    completedInsights,
    insightsLoading,
    roiResults={data: []},
    fitResults={data: []}
  } = useOutputsInsights(selectedMcaRun.id, includeIntercept, queryId, [modelId], startInsights, setStartInsights, false);

  React.useEffect(() => {
    if(completedInsights && completedInsights.length) {
      setModelInsights(completedInsights.includes(modelId));
      setInsights(modelId);
    }
  }, [modelId, completedInsights]);

  const modelRoiMap = React.useMemo(() => {
    const roiData = roiResults.data.length ? formatRoiResults(roiResults.data) : {};
    return roiData[modelId] || roiMap;
  }, [selectedMcaRun.id, modelId, roiResults.data]);

  const modelFitData = React.useMemo(() => {
    const fitArray = fitResults.data.length ? fitResults.data : fitData;
    const modelFit = fitArray.filter(model => model.modelId === modelId);
    return modelFit.length ? modelFit[0].data : [];
  }, [selectedMcaRun.id, modelId, fitResults.data]);

  const FitContent = () => {
    if (insightsLoading) {
      return (<div className={styles.spinnerContainer}><Spinner/></div>);
    }

    if(hasInsights || modelInsights) {
      return (
        <McaDetailsChart
          kpiType={kpiType}
          modelId={modelId}
          fitResults={modelFitData}
        />
      );
    }

    return (
      <div className={styles.buttonContainer}>
        <div className={styles.infoMessage}>Outputs and insights not generated</div>
        <OrangeButtonLV
          onClick={()=>setStartInsights(true)}>
            Generate Outputs and Insights
        </OrangeButtonLV>
      </div>
    );
  };

  // Tabs per product
  let tabContents = [
    {
      label: 'Contribution',
      content: (<McaDetailsTable
                  selectedModel={selectedModel}
                  roiMap={modelRoiMap}
                  kpiType={kpiType}
                  hasInsights={modelInsights || hasInsights}
                />)
    }, 
    {
      label: 'Fit',
      content: <FitContent/>
    }
  ];

  const tabLabels = tabContents.map(tab => (tab.label));

  const setSelectedTab = (value) => {
    const tab =  (value < tabContents.length) ? value : 0;
    setTabIndex(tab);
  };

  const selectedTab = tabContents[tabIndex] || tabContents[0];

  return (
    <div>
      <TabContentLV
        data-test='mca-details-tabs'
        className={styles.tabRoot}>
        <div
          className='mca-details-tabs-header'>

          <DataTabs
            labels={tabLabels}
            selectedTab={testTabIndex || tabIndex}
            onClick={setSelectedTab}
            isDark={false}
          />
        </div>

        <div
          data-test='mca-details-tab-container'
          className={styles.tabContent}>
          {selectedTab.content}
        </div>

      </TabContentLV>
    </div>
  );
}

export {McaDetailsTab};
