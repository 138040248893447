import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { Tabs, Tab, Box } from '@mui/material';
import { useCampaignManagement } from '../../hooks/useCampaignManagement';
import {Config} from "../../config/ConfigFactory";

export const TabsLV = ({
  tabs=[],
  tabsUrlQueryKey,
  tabIndexOverride,
  tabHeightOffset,
  isTabResetScroll = true,
  sx,
  children
}) => {

  const tabBodyRef = React.useRef();

  const {
    customQueryParamValue = 0,
    setCustomQueryParam = () => {},
  } = useCampaignManagement(tabsUrlQueryKey);

  const [tabIndex, setTabIndex] = React.useState(tabIndexOverride ?? Number(customQueryParamValue));

  React.useEffect(() => {
    // Set scrollbar position to top when changing tabs
    if(tabBodyRef.current && isTabResetScroll) {
      tabBodyRef.current.scrollTop = 0;
    }
    
  }, [tabIndex, isTabResetScroll]);

  const setTabHandler = index => {
    setTabIndex(index);
    setCustomQueryParam(index);
  };

  tabs = tabs.filter(tab => !tab.featureFlagKey || Config.getConfig().FEATURE_FLAGS?.[tab.featureFlagKey])

  const selectedTabIndex = (tabIndex < tabs.length) ? tabIndex : 0;
  let Content = tabs[selectedTabIndex]?.content || null;
  let NestedContent = tabs[selectedTabIndex]?.nestedContent || null;

  // Parse component if sent as function
  if (typeof Content === 'function') {
    Content = <Content/>;
  }

  if (typeof NestedContent === 'function') {
    NestedContent = <NestedContent/>;
  }

  if (!NestedContent) {
    tabHeightOffset = 100;
  }

  const childTabs = React.useMemo(() => {
    return tabs
        .map((t, index) => (
            <TabLV
                key={`tab-${index}`}
                index={index}
                setTabIndex={setTabHandler}
                {...t}
            />
        ));
  }, [tabs]);

  return (
    <>
      <Tabs
        data-test='tabs-components'
        sx={{
          background: '#FFF',
          paddingLeft: '10px'
        }}
        value={selectedTabIndex}>

        {childTabs}

      </Tabs>

      {NestedContent}

      <TabBodyStyled
        sx={sx}
        ref={tabBodyRef}
        offset={tabHeightOffset}
        className='tab-body'>

        {children}

        {Content}

      </TabBodyStyled>

    </>
  );
};

const TabBodyStyled = styled(Box)(({
  theme,
  offset = 0
}) => {

  // Offset from Tabs Header plus any children
  const fullOffset = 48 + offset;

  return {
    alignItems: 'center',
    overflowY: 'auto',
    width: '100%',
    height: `calc(100% - ${fullOffset}px)`,
    background: '#F2F2F2'
  };
});

const TabLV = ({
  index,
  label,
  setTabIndex
}) => {

  const clickHandler = () => {
    setTabIndex(index);
  };

  return (
    <Tab
      disableRipple
      label={label}
      onClick={clickHandler}
      data-test={label}
    />
  );

};

/**
 * Standard MUI 5 Tabs
 * Selected tab is managed in the URL
 */
TabsLV.propTypes = {
  /**
   * URL query param key to maintain state of selected tab in URL
   * From TAB_URL_KEY in useCampaignManagement.jsx
   * EXAMPLE: https://leavened.io?scoreTab=2
   * 
   */
  tabsUrlQueryKey: PropTypes.string,
  /**
   * @param tabs Array of tabs
   *        EXAMPLE: [
   *                   {
   *                     label: 'Sub Tab 1',
   *                     nestedContent: <MyComponentAboveTabBody/>
   *                     content: <MySubTabComponent/>,
   *                     featureFlagKey: 'CUSTOM_DASHBOARDS'
   *                   }
   *                 ]
   */
  tabs: PropTypes.array,
  /**
   * For scrollbars, the tab body needs to know the height of any child objects
   * in order just when to add the scrollbars
   */
  tabHeightOffset: PropTypes.number,
  /**
   * If true, updates scrollbar position to 0 upon tab change
   * Default: true
   */
  isTabResetScroll: PropTypes.bool,
  /**
   * Extended CSS to tab body
   */
  sx: PropTypes.object,
};
