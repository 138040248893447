import React from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import _ from 'lodash';
import { Box, Tooltip, Checkbox, Chip, Stack } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import { 
    useR1Form,
    R1DataGrid,
    R1TextBox, 
    R1AutoComplete
} from '@rainagency/rain-one-soggy-loaf';
import { useGet } from '../../../../hooks/useRest';
import { useCampaignManagement } from '../../../../hooks/useCampaignManagement';
import {useGetFileJson} from '../../../../hooks/useUploadedFileJson'
import { memoryDecays, saturations, measures, urlBuilders } from '../../../../js/constants';
import { ConfigControl } from './config-control';

import { styles } from './configure-analysis.styles';

export const ConfigureAnalysis = ({
    stepForward=()=>{},
    stepBack=()=>{},
    runId,
    disabled, 
    configValues,
    setConfigValues=()=>{},
    analysisValues,
    setAnalysisValues=()=>{},
    isTest
}) => {
  const {campaignId, configAIId} = useCampaignManagement();

  const [mediaGroupingData, setMediaGroupingData] = React.useState([]);
  const { height } = useWindowDimensions();

  //Call to get mediaGrouping by runId or campaignId
  const [{data: mediaGrouping=[], loading: mediaGroupingLoading}, fetchMediaGrouping] = useGet({
    url: urlBuilders.getMediaGroupingConfigs(campaignId, runId),
      manual: true
  });

  React.useEffect(() => {
    if (!configValues && !mediaGroupingLoading) {
      fetchMediaGrouping();
    }
  }, [campaignId, runId]);

  //Call to get configAI mediaGrouping
  const [{data: configAIMediaGrouping=[], loading: configAIMediaGroupingLoading}, fetchConfigAIMediaGrouping] = useGet({
    url: urlBuilders.getMediaGroupingConfigs(campaignId, runId, configAIId),
      manual: true
  });

  const [{data: campaignUploadedFiles={data:[]}, loading: campaignUploadedFilesLoading}, fetchCampaignUploadedFiles] = useGet({
    url: urlBuilders.getUploadedCampaignFilesMetaData(campaignId),
    manual: true
  });

  const {fileJson: originalGroupingConfig} = useGetFileJson({
    bucket:  configAIId && campaignUploadedFiles?.data.length ? campaignUploadedFiles.data[0]?.bucket : '',
    location: configAIId ? `MCA/campaign-data-set/${campaignId}/configAI/${configAIId}/media_grouping.csv` : ''
  });

  React.useEffect(() => {
    if (configAIId && !configAIMediaGroupingLoading && !campaignUploadedFilesLoading) {
      fetchConfigAIMediaGrouping();
      fetchCampaignUploadedFiles();
    }
  }, [configAIId]);

  //Trigger for memorized mediaGroupingData
  const groupingData = configAIMediaGrouping?.length ? configAIMediaGrouping : mediaGrouping || [];
  const gridValues = configValues?.length ? configValues : groupingData;
  useDeepCompareEffect(() => {
    if (gridValues.length) {
      setMediaGroupingData(getGridRows(gridValues));
    }
  }, [gridValues]);

  //Form Data
  const defaultValues = React.useMemo(() => {
    if (mediaGroupingData.length) {
      return mediaGroupingData.reduce((values, value) => ({
        'VariableName': [...values['VariableName'], value['VariableName']],
        'VariableGroup': [...values['VariableGroup'], value['VariableGroup']],
        'Measure': [...values['Measure'], value['Measure']],
        'Analyze': [...values['Analyze'], value['Analyze']],
        'MemoryDecay': [...values['MemoryDecay'], value['MemoryDecay']],
        'Saturation': [...values['Saturation'], value['Saturation']],
        'Data Grouping Required': [...values['Data Grouping Required'], value['Data Grouping Required']]
      }), {
        'VariableName': [],
        'VariableGroup': [],
        'Measure': [],
        'Analyze': [],
        'MemoryDecay': [],
        'Saturation': [],
        'Data Grouping Required': []
      });
    }
  }, [mediaGroupingData]);

  const { R1Form, methods } = useR1Form();
  const formValues = methods.getValues();

  React.useEffect(() => {
    if (!_.isEmpty(defaultValues)) {
      methods.reset(defaultValues);
    }
  },[defaultValues]);

  const handleOnChange = (value=[], name, id) => {
    let updatedValues = _.cloneDeep(formValues);
    updatedValues[name][id] = value;

    if (name === 'MemoryDecay' || name === 'Saturation') {
      if (value[value.length -1] === 'No') {
        updatedValues[name][id] = ['No'];
      } else if (value[value.length -1] === '?') {
        updatedValues[name][id] = ['?'];
      } else {
        updatedValues[name][id] = value.filter(option => option !== 'No' && option !== '?');
      }
    } 

    //Validate matching groups
    if (name === 'Data Grouping Required') {
      updatedValues['VariableGroup'].forEach((variable, index) => {
        if (variable === updatedValues['VariableGroup'][id] && updatedValues['Analyze'][index] === 'Yes') {
          updatedValues['Data Grouping Required'][index] = value;
        }
      });
    }
    
    if (name === 'Analyze' || name === 'VariableGroup') {
      updatedValues['Data Grouping Required'].forEach((variable, index) => {
        if (variable === 'Yes' &&  updatedValues['Analyze'][index] === 'Yes') {
          updatedValues['VariableGroup'].forEach((item, row) => {
            if (item === updatedValues['VariableGroup'][index] && updatedValues['Analyze'][row] === 'Yes') {
              updatedValues['Data Grouping Required'][row] = 'Yes';
            }
          });
        }
      });
    }

    const valueMap = {};

    Object.entries(updatedValues).forEach(([key, val]) => {
      val.forEach((value, index) => {
        valueMap[index] = {
          ...valueMap[index],
          [key]: value
        }
      })
    });

    const updatedMediaGroupingData = Object.values(valueMap).reduce((all, cur, index) => {
      return [
          ...all,
        {
          ...cur,
          id: index
        }
      ]
    }, []);

    methods.reset(updatedValues);
    setMediaGroupingData(updatedMediaGroupingData)
  };

  const handleKeyDown = (event) => {
    event.stopPropagation();
    event.key === 'Enter' && event.preventDefault();
  }

  const formatValues = () => {
    const values = _.cloneDeep(formValues);
    if (Object.keys(values).length === 0) {
      return;
    }
    const configs = values['VariableName'].map((name, index) => ({
        'VariableName': name,
        'VariableGroup': values['VariableGroup'][index],
        'Measure': values['Measure'][index],
        'Analyze': values['Analyze'][index]?.toLowerCase(),
        'MemoryDecay': values['MemoryDecay'][index]?.join('/').replace('No', 'no'),
        'Saturation' : values['Saturation'][index]?.join('/').replace('No', 'no'),
        'Data Grouping Required': values['Data Grouping Required'][index]?.toLowerCase()
      })
    );
    setConfigValues(configs);
  };

  const handleStepBack = () => {
    formatValues();
    stepBack();
  };

  let expectedCount = 0;
  const setExpectedCount = (count) => {
    expectedCount = Number(count.replace(/,/g, ''));
  };

  const handleSubmit = () => {
    formatValues();
    setAnalysisValues({...analysisValues, expectedCount});
    stepForward();
  };

  const columns = getGridColumns(formValues, configAIId, originalGroupingConfig, 
                                  configAIMediaGrouping, disabled, handleKeyDown, handleOnChange);
  const loading = mediaGroupingLoading || configAIMediaGroupingLoading || campaignUploadedFilesLoading ||
     (!!gridValues?.length && !mediaGroupingData?.length);
  
  return (
    <Box sx={{ height: `${height-300}px`, padding: '24px'}} >
      <R1Form
        style={{height: '100%'}}
        methods={methods}
        onSubmit={ handleSubmit }
        customStyles={{
            cursor: methods.formState.isSubmitted ? 'wait' : 'auto'
        }}>
          <div>
            <R1DataGrid
              columns={ columns }
              rows={ mediaGroupingData }
              enablePagination={ false }          
              loading={ loading }
              height={'100%'}
              customStyles={{
                '& [aria-label="Data Grouping Required"]': {
                  whiteSpace: 'pre-wrap',
                  textAlign: 'center',
                  lineHeight: '16px',
                  maxWidth: '130px'
                }
              }}
              getRowClassName={(data) => data.row.Analyze === 'No' ? styles.disabledRow : ''}
            />
            <ConfigControl
              analysisValues= { analysisValues }
              loading = { loading } 
              configValues={ formValues }
              disabled={ isTest ? false : !methods.formState.isValid }
              readOnly={ disabled }
              handleStepBack = { handleStepBack }
              setExpectedCount = { setExpectedCount }
            />
          </div>
      </R1Form>
    </Box>);
};

//Grid Rows
const getGridRows = (mediaGroupingData) => {
  return mediaGroupingData.map((data, index) => {
    const Analyze =  data['Analyze'] ? _.capitalize(data['Analyze']) : 'No';
    const required = data['Data Grouping Required'] ? _.capitalize(data['Data Grouping Required']) : 'No';

    let MemoryDecay = data.MemoryDecay ? data.MemoryDecay.replace('no', 'No') : 'No';
    MemoryDecay = MemoryDecay.split('/');
    
    let Saturation = data.Saturation ? data.Saturation.replace('no', 'No') : 'No';
    Saturation = Saturation.split('/');
    return {...data, id: index, 
      Analyze, MemoryDecay, Saturation, 
      'Data Grouping Required': required
    };
  });
};

//Grid Columns
const getGridColumns = (
    formValues, 
    configAIId,
    originalGroupingConfig=[],
    configAIMediaGrouping=[],
    disabled, 
    handleKeyDown=()=>{}, 
    handleOnChange=()=>{}
  ) => {
    const headerSettings = {
      editable: false,
      sortable: true,
      filterable: false,
      disableColumnMenu: true,
      hasTooltip: false,
      sortComparator: (a, b) => {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      }
    };
    
    if (_.isEmpty(formValues)) {
      return;
    }
    
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    //Identify values that are suggested in configAI
    const getConfigStyle = (fieldName, id) => {
      const currentValue = _.isEqual(formValues[fieldName][id], ['No']) ? ['no'] : formValues[fieldName][id];
      const variableName = formValues['VariableName'][id];
      const originalValue = (originalGroupingConfig.find(original => original.VariableName === variableName) || {})[fieldName];
      const suggestedValue = (configAIMediaGrouping.find(ai => ai.VariableName === variableName) || {})[fieldName]?.split('/').sort();
      return originalValue === '?' && _.isEqual(suggestedValue, currentValue?.sort());  
    }

    const getFieldStyle = (fieldName, id) => {
      if (!!formValues[fieldName]?.length && !formValues[fieldName][id]?.length) {
        return styles.errorField;
      }

      if (configAIId && getConfigStyle(fieldName, id)) {
        return styles.markedField;
      }
      return styles.multiSelectFields
    }

    const limitTags = (values, tagProps, limit) => (
      <Stack direction="row" spacing={ 1 }>
        { values.sort().map((value, index) => {
          return (<Chip label={ value } { ...tagProps({index}) } />)
        }).slice(0, limit) }
        <div className={ styles.tags } > { values.length > limit ? `+${values.length - limit}` : ''} </div>
      </Stack>       
    );

    return ([
      {
        field: 'VariableName',
        headerName: 'Variable Name',
        flex: 1,
        ...headerSettings,
        renderCell: (cell) =>
          <R1TextBox
            name={ `VariableName[${cell.row.id}]` }
            defaultValue={ (!!formValues['VariableName']?.length && formValues['VariableName'][cell.row.id]) }
            fullWidth={ true }
            disabled={ true }
          />
      },
      {
        field: 'VariableGroup',
        headerName: 'Variable Group',
        flex: 1,
        ...headerSettings,
        renderCell: (cell) =>
          <R1TextBox
            name={ `VariableGroup[${cell.row.id}]` }
            defaultValue={ (!!formValues['VariableGroup']?.length && formValues['VariableGroup'][cell.row.id]) }
            onBlur= { (event) => handleOnChange(event.target.value, 'VariableGroup', cell.row.id) }
            onKeyDown={ handleKeyDown }
            rules={{
                required: 'Variable Group is required.',
                maxLength: {
                    value: 250,
                    message: 'Cannot exceed 250 characters'
                }
            }} 
            fullWidth={ true }
            disabled={ disabled }
          />
      },
      {
        field: 'Measure',
        width: 172,
        ...headerSettings,
        renderCell: (cell) => 
          <R1AutoComplete
            name={ `Measure[${cell.row.id}]` }
            options={ measures }
            disableClearable
            defaultValue={ (!!formValues['Measure']?.length && formValues['Measure'][cell.row.id]) }
            onChange={ (value) => handleOnChange(value, 'Measure', cell.row.id) }
            rules={{ required: true }}
            fullWidth={ true }
            disabled={ disabled }
          />
      },
      {
        field: 'Analyze',
        width: 120,
        ...headerSettings,
        renderCell: (cell) => 
          <R1AutoComplete
            name={ `Analyze[${cell.row.id}]` }
            options={ ['Yes', 'No'] }
            disableClearable
            defaultValue={ (!!formValues['Analyze']?.length && formValues['Analyze'][cell.row.id]) }
            onChange={ (value) => handleOnChange(value, 'Analyze', cell.row.id) }
            rules={{ required: true }}
            fullWidth={ true }
            disabled={ disabled }
          />
      },
      {
        field: 'MemoryDecay',
        headerName: 'Memory Decay',
        width: 250,
        required: true,
        ...headerSettings,
        renderCell: (cell) =>
          <Tooltip title={ (!!formValues.MemoryDecay?.length && formValues.MemoryDecay[cell.row.id]?.join('/')) }>
            <div style={{width: '250px'}}>
              <R1AutoComplete
                className={ getFieldStyle('MemoryDecay', cell.row.id) }
                name={ `MemoryDecay[${cell.row.id}]` }
                options={ memoryDecays }
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option}
                  </li>
                )}
                renderTags={ (values, tagProps) => limitTags(values, tagProps, 2) }
                multiple={ true }
                disableCloseOnSelect
                defaultValue={ (!!formValues.MemoryDecay?.length && formValues.MemoryDecay[cell.row.id]) }
                onChange={ (value) => handleOnChange(value, 'MemoryDecay', cell.row.id) }
                rules={{ required: true }}
                fullWidth={ true }
                disabled={ disabled || !!formValues.Analyze?.length && formValues.Analyze[cell.row.id] === 'No' }
              /> 
            </div>
          </Tooltip>
      },
      {
        field: 'Saturation',
        width: 250,
        ...headerSettings,
        renderCell: (cell) =>
          <Tooltip title={ (!!formValues.Saturation?.length && formValues.Saturation[cell.row.id]?.join('/')) }>
            <div style={{width: '250px'}}>
              <R1AutoComplete
                  className={ getFieldStyle('Saturation', cell.row.id) }
                  name={ `Saturation[${cell.row.id}]` }
                  options={ saturations }
                  renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option}
                      </li>
                  )}
                  renderTags={ (values, tagProps) => limitTags(values, tagProps, 1) }
                  multiple={ true }
                  disableCloseOnSelect
                  defaultValue={ (!!formValues['Saturation']?.length && formValues['Saturation'][cell.row.id]) }
                  onChange={ (value) => handleOnChange(value, 'Saturation', cell.row.id) }
                  rules={{ required: true }}
                  fullWidth={ true }
                  disabled={ disabled || !!formValues.Analyze?.length && formValues.Analyze[cell.row.id] === 'No' }
              />
            </div>
          </Tooltip>
      },
      {
        field: 'Data Grouping Required',
        width: 120,
        ...headerSettings,
        renderCell: (cell) =>
          <R1AutoComplete
            name={ `Data Grouping Required[${cell.row.id}]` }
            options={ ['Yes', 'No'] }
            disableClearable
            defaultValue={ (!!formValues['Data Grouping Required']?.length && formValues['Data Grouping Required'][cell.row.id]) }
            onChange={ (value) => handleOnChange(value, 'Data Grouping Required', cell.row.id) }
            rules={{ required: true }}
            fullWidth={ true }
            disabled={ disabled || !!formValues.Analyze?.length && formValues.Analyze[cell.row.id] === 'No' }
          />
      },
    ]);
};

export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  );

  React.useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}