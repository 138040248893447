import {call, put, takeEvery} from 'redux-saga/effects';

import {urlBuilders} from '../../../js/constants.js';
import {post, getData} from '../../../js/server.js';
import {formatFilters} from '../../../js/validation.js';

import {snackbar} from '../../../modules/platform/snackbar.js';

import {
    MODEL_COUNT,
    setCount,
    MODEL_TOTAL_COUNT,
    setTotal,
    loadingDone,
    loadingStart,
    LOAD_MCA_VARIABLES,
    setVariables,
    MODEL_RANGES,
    setRanges
} from '../filter.js';

export function* mcaResultCount(action) {
    yield put(loadingStart());
    yield call(getResultCount, action);
    yield put(loadingDone());
}

export function* watchMcaResultCount() {
    yield takeEvery(MODEL_COUNT, mcaResultCount);
}

export function* mcaTotalCount(action) {
    yield put(loadingStart());
    yield call(getTotalCount, action);
    yield put(loadingDone());
}
export function* watchMcaTotalCountCount() {
    yield takeEvery(MODEL_TOTAL_COUNT, mcaTotalCount);
}

export function* getResultCount(action) {
    const mcaVariables = yield call(getMcaVariables, action);
    const filters = yield formatFilters(action.payload, mcaVariables);
    const queryIdResponse = yield call(
        post,
        action.token,
        action.baseUrl + urlBuilders.getMcaResultCount(action.payload.mcaRunId),
        filters
    );
    if (queryIdResponse.error) {
        yield put(
            snackbar(
                'error',
                'There has been an error getting the result count! '
            )
        );
        yield put(setCount(0));
    } else {
        const queryId = queryIdResponse.body.queryId;
        const count = yield call(getCountResult, action, queryId);
        yield put(setCount(count));
    }
}

export function* getTotalCount(action) {
    const queryIdResponse = yield call(
        post,
        action.token,
        action.baseUrl +
            urlBuilders.getMcaResultCount(action.payload.commonPart.mcaRunId),
        action.payload.commonPart
    );
    if (queryIdResponse.error) {
        yield put(
            snackbar(
                'error',
                'There has been an error getting the total count! '
            )
        );
        yield put(setTotal(0));
    } else {
        const queryId = queryIdResponse.body.queryId;
        const count = yield call(getCountResult, action, queryId);
        yield put(setTotal(count));
    }
}

export function getCountResult(action, queryId) {
    return new Promise(function(resolve, reject) {
        let interval = setInterval(
            /* istanbul ignore next */ () => {
                getData(
                    action.token,
                    action.baseUrl + urlBuilders.getQueryStatus(queryId)
                ).then((res) => {
                    if (res.status === 'Success') {
                        clearInterval(interval);
                        getData(
                            action.token,
                            action.baseUrl + urlBuilders.getResultCount(queryId)
                        ).then((res) => {
                            resolve(res.count);
                        });
                    } else if (res.status === 'Error') {
                        clearInterval(interval);
                        snackbar(
                            'error',
                            'There has been an error getting the count! ' +
                                res.error
                        );
                        resolve(0);
                    }
                });
            },
            1000
        ); //1sec
    });
}

export function* mcaResultRanges(action) {
    yield put(loadingStart());
    const queryIdResponse = yield call(
        post,
        action.token,
        action.baseUrl + urlBuilders.getMcaRanges(action.payload.mcaRunId),
        action.payload
    );
    if (queryIdResponse.error) {
        yield put(setRanges([]));
        yield put(
            snackbar(
                'error',
                'There has been an error getting the data ranges! '
            )
        );
    } else {
        const queryId = queryIdResponse.body.queryId;
        const ranges = yield call(getMcaResultRanges, action, queryId);
        yield put(setRanges(ranges));
    }
    yield put(loadingDone());
}

export function* watchMcaResultRanges() {
    yield takeEvery(MODEL_RANGES, mcaResultRanges);
}

export function getMcaResultRanges(action, queryId) {
    return new Promise(function(resolve, reject) {
        let interval = setInterval(
            /* istanbul ignore next */ () => {
                getData(
                    action.token,
                    action.baseUrl + urlBuilders.getQueryStatus(queryId)
                ).then((res) => {
                    if (res.status === 'Success') {
                        clearInterval(interval);
                        getData(
                            action.token,
                            action.baseUrl +
                                urlBuilders.getResultRanges(queryId)
                        ).then((res) => {
                            resolve(res.data);
                        });
                    } else if (res.status === 'Error') {
                        clearInterval(interval);
                        snackbar(
                            'error',
                            'There has been an error getting the data ranges! ' +
                                res.error
                        );
                        resolve([
                            {name: 'rsq', min: 0, max: 1},
                            {name: 'pval', min: 0, max: 1}
                        ]);
                    }
                });
            },
            1000
        ); //1sec
    });
}

export function* getMcaVariables(action) {
    const response = yield call(
        getData,
        action.token,
        action.baseUrl +
            urlBuilders.getMcaVariables(
                action.payload.mcaRunId || action.params.mcaRunId
            )
    );
    if (response.error) {
        yield put(
            snackbar(
                'error',
                'There has been an error getting the list of variables! '
            )
        );
        yield put(setVariables([]));
    } else {
        yield put(setVariables(response));
        return response;
    }
}

export function* watchGetMcaVariables() {
    yield takeEvery(LOAD_MCA_VARIABLES, getMcaVariables);
}
