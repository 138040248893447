import React from 'react';
import {ButtonTransparent} from '../../components/form/buttons/ButtonTransparent';
import {InsertDrive24} from '../../components/icons/InsertDrive24';
import {ModalLV} from '../../components/modal/ModalLV';
import {HeaderContext} from '../../hooks/HeaderContext';
import {FILE_TYPE_MAP} from '../../constants/file-types';
import {useCampaignManagement} from '../../hooks/useCampaignManagement';
import {useGetUploadedFiles} from '../../hooks/useGetUploadedFiles';
import {useScoringByCampaignId} from './hooks/useScoringByCampaignId';
import {ScoringFileTable} from './ScoringFileTable';
import {useDownloadTemplateFile} from '../../hooks/useDownloadTemplateFile';
import {styles} from './ScoringDataInput.styles';
import {ScoringFileUpload} from "./file-upload/ScoringFileUpload";

export const ScoringDataInput = ({
    scores,
    fetchScores = () => {},
    loading
                                 }) => {

    const {mcaRunId} = useCampaignManagement();
    const {selectedFile} = useGetUploadedFiles(FILE_TYPE_MAP.MEDIA_VEHICLE);

    const {triggerDownload} = useDownloadTemplateFile({
        category: 'SCORING_BUSINESS_DRIVERS_TEMPLATE',
        id: mcaRunId,
        fileName: 'scoring_template.csv'
    });

    return (
        <div className={`scoring-data-input ${styles.content}`}>
            <div className='scoring-input-button-row'>
                <ButtonTransparent
                    onClick={triggerDownload}
                    isDisabled={!mcaRunId || !selectedFile.id}
                    dataTest='business-driver'>

                    <InsertDrive24/>
                    Download Business Drivers Template

                </ButtonTransparent>
            </div>

            <ScoringFileTable
                fetchScoringRuns={fetchScores}
                data={scores}
                loading={loading}
            />

        </div>
    );
};
