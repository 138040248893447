import {
  Divider,
  IconButton,
  Tooltip
} from '@mui/material';
import {
  FileDownloadRounded,
} from '@mui/icons-material';
import DownloadCSVFactory from '../../../js/download-csv-factory';

/**
 * Button that creates a .csv file from provided data
 * Triggers a file download
 */
export const PanelDownloadButton = ({
  downloadFileData,
  csvFileName,
  excelFileName,
  excelFileTooltip='',
  fileTooltip='',
}) => {

  if(!downloadFileData || (!csvFileName && !excelFileName)) {
    return null;
  }

  // Use JSON data array for grids to create a Downloadable CSV file
  if(csvFileName) {
    DownloadCSVFactory.createDownload(
      csvFileName, downloadFileData, csvFileName
    );
  }

  const downloadFileHandler = () => {
    if(csvFileName){
      const report = DownloadCSVFactory.getDownload(
        csvFileName
      );
      report.download();
    }
    else if(excelFileName){
      DownloadCSVFactory.downloadExcel(downloadFileData, excelFileName);
    }
  };

  return (
    <>

      <Divider
        className='control-panel-divider'
        orientation='vertical'
        sx={{
          height: 32
        }}
      />

      <Tooltip title={fileTooltip || excelFileTooltip}>
        <IconButton
          onClick={downloadFileHandler}
          aria-label='Download Data'
          sx={{
            margin: '0 2px'
          }}
          color='primary'>
          <FileDownloadRounded />
        </IconButton>
      </Tooltip>

    </>
  );
};
