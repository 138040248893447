import {
  R1ActionIcon,
} from '@rainagency/rain-one-soggy-loaf';
import {
  usePostTriggerDownloadUrl,
} from '../../../../hooks/useTriggerDownloadUrl';
import { urlBuilders } from '../../../../js/constants';

/**
 * Download Errors on Grid Action Popover click
 */
export const ErrorActionIcon = ({
  cell,
  hide,
  closePopover
}) => {
  const {
    triggerDownload,
    loading
  } = usePostTriggerDownloadUrl({
    requestUrl: urlBuilders.exportErrorsCsv(cell.row.id),
    urlObjectPath: 'data.url',
    onFinal: closePopover
  });

  return (
    <R1ActionIcon
      iconType='Error'
      label='Export MCA Errors'
      hide={hide}
      onClick={triggerDownload}
      customStyles={{
        cursor: loading ? 'wait' : 'pointer'
      }}
    />
  );
};
