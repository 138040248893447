// actions
export const OPEN_MODAL = `platform/modal/open`;
export const CLOSE_MODAL = `platform/modal/close`;

export const initialState = {
    open: false,
    context: '',
    dark: true,
    sized: false,
    completeModalTitle: '',
    completeModalBody: '',
    completeModalSuccess: false
};

// selectors
const ID = `modal`;
const local = (state) => state['platform'][ID];
export const getOpen = (state) => local(state).open;
export const getContext = (state) => local(state).context;
export const getDark = (state) => local(state).dark;
export const getSized = (state) => local(state).sized;
export const getCompleteModalTitle = (state) => local(state).completeModalTitle;
export const getCompleteModalBody = (state) => local(state).completeModalBody;
export const getCompleteModalSuccess = (state) =>
    local(state).completeModalSuccess;

export default function modalReducer(state = initialState, action) {
    switch (action.type) {
        case OPEN_MODAL:
            return Object.assign({}, state, {
                open: true,
                context: action.context,
                sized: action.sized,
                completeModalTitle: action.completeModalTitle,
                completeModalBody: action.completeModalBody,
                completeModalSuccess: action.completeModalSuccess
            });
        case CLOSE_MODAL:
            return Object.assign({}, state, {
                open: false,
                context: '',
                completeModalTitle: '',
                completeModalBody: '',
                completeModalSuccess: false
            });
        default:
            return state;
    }
}

// action creators
export function openModal(
    context,
    sized,
    completeModalTitle,
    completeModalBody,
    completeModalSuccess
) {
    return {
        type: OPEN_MODAL,
        context,
        sized,
        completeModalTitle,
        completeModalBody,
        completeModalSuccess
    };
}

export function closeModal() {
    return {
        type: CLOSE_MODAL
    };
}
