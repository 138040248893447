import {
  Divider,
  IconButton,
  Tooltip
} from '@mui/material';
import {
  Image
} from '@mui/icons-material';
import domToImage from 'dom-to-image-more';
import {saveAs} from 'file-saver';

/**
 * Button that takes a snapshot of the parent Chiclet
 * Downloads snapshot as an image file
 */
export const PanelImageButton = ({
  chicletImageId,
  imageTooltip='',
  imageFileName,
}) => {

  if(!chicletImageId || !imageFileName) {
    return null;
  }

  const imageDownloadClickHandler = () => {
    downloadImage(chicletImageId, imageFileName);
  };

  return (
    <>

      <Divider
        className='control-panel-divider'
        orientation='vertical'
        sx={{
          height: 32
        }}
      />

      <Tooltip title={imageTooltip}>
        <IconButton
          onClick={imageDownloadClickHandler}
          aria-label='Download Image'
          sx={{
            margin: '0 2px'
          }}
          color='primary'>
          <Image />
        </IconButton>
      </Tooltip>
    </>
  );
};

const downloadImage = async(id, fileName) => {
  if(!id || !fileName) {
    return;
  }

  const element = document.getElementById(id);
  let elementBeforeStyle;

  // Before Snapshot
  if(element.style) {
    elementBeforeStyle = {...element.style};
    element.style.background = 'transparent';
    element.style.margin = 0;
    element.style.padding = 0;
  }
  
  const chicletElements = document.querySelectorAll('.chiclet-box');
  chicletElements.forEach(element => element.style.background = 'transparent');
  const blob = await domToImage.toBlob(element, {
    filter: filterElements,
  });

  // After snapshot, set styles back
  if(element.style) {
    element.style = elementBeforeStyle;
  }
  chicletElements.forEach(element => element.style.background = '#FFF');

  saveAs(blob, fileName);
};

/**
 * Filter out certain html node elements when taking snapshot for download
 */
const filterElements = (node={}) => {

  const className = (typeof node.className === 'string') ? node.className : '';
  const filterArray = [
    (className && node.className.includes('control-panel-divider')),
    node.type === 'button'
  ];

  return !filterArray.some(s => s);
};
