import React from 'react';
import {styles} from './GraphIconOption.styles';

export const GraphIconOption = ({children=['','']}) => {
  return(
    <div className={styles.label}>
      {children[0]}
      <div className='graph-label-text'>
        {children[1]}
      </div>
    </div>
  );
};
