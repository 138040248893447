import {call, put, takeEvery} from 'redux-saga/effects';

import {urlBuilders} from '../../../js/constants';

import {snackbar} from '../../platform/snackbar.js';

import {
    GET_GROUPING_TEMPLATE_STATUS,
    GET_GROUPING_TEMPLATE_URL,
    updateGroupingTemplateStatus,
    updateGroupingTemplateUrl,
    loadDownloadErrors,
    spinner
} from '../downloadTemplate.js';

import {getData} from '../../../js/server.js';

export function* getGroupingTemplateStatus(action) {
    yield put(spinner(true));
    const response = yield call(
        getData,
        action.token,
        action.baseUrl +
            urlBuilders.getGroupingTemplateStatus(
                action.params.campaignId,
                action.params.type
            )
    );
    yield put(spinner(false));
    if (response.error) {
        yield put(loadDownloadErrors(response.message));
        yield put(
            snackbar(
                'error',
                'ERROR: \n Error while retrieving status of grouping template file for campaign Id: ' +
                    action.params.campaignId +
                    'and data type: ' +
                    action.params.type +
                    '. \n' +
                    response.message
            )
        );
    } else {
        yield put(updateGroupingTemplateStatus(response.data));
    }
}

export function* watchGetGroupingTemplateStatus() {
    yield takeEvery(GET_GROUPING_TEMPLATE_STATUS, getGroupingTemplateStatus);
}

// gets the location of the grouping template than requests a singed url for that location
export function* getGroupingTemplateUrl(action) {
    yield put(spinner(true));
    const templateLocation = yield call(
        getData,
        action.token,
        action.baseUrl +
            urlBuilders.getFileLocation(
                action.params.campaignId,
                'mediaGroupingTemplate'
            )
    );
    if (templateLocation.error) {
        yield put(loadDownloadErrors(templateLocation.message));
        yield put(
            snackbar(
                'error',
                'ERROR: error while retrieving location of grouping template'
            )
        );
    } else {
        const response = yield call(
            getData,
            action.token,
            action.baseUrl +
                urlBuilders.getFileUrl(
                    templateLocation.fileLocation.bucket,
                    templateLocation.fileLocation.location
                )
        );
        if (response.error) {
            yield put(loadDownloadErrors(response.message));
            yield put(
                snackbar(
                    'error',
                    'ERROR: \n Error while retrieving url of grouping template file in: ' +
                        templateLocation.bucket +
                        '\\' +
                        templateLocation.location +
                        '. \n' +
                        response.message
                )
            );
        } else {
            yield put(updateGroupingTemplateUrl(response.url));
        }
    }
    yield put(spinner(false));
}

export function* watchGetGroupingTemplateUrl() {
    yield takeEvery(GET_GROUPING_TEMPLATE_URL, getGroupingTemplateUrl);
}
