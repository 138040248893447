import {css} from 'emotion';

export const styles = {
    modal: css`
        margin: auto;
        position: relative;
        height: 105%;
        width: 100%;
        background: radial-gradient(
            ellipse at 118% 92%,
            #a4d47d 0%,
            #7e9292 40%,
            #374f63 95%
        );
        color: white;
        display: flex;
    `,
    modalXDark: css`
        color: white;
        position: absolute;
        top: 16px;
        right: 17px;
        cursor: pointer;
        font-size: 32px;
    `,
    modalXLight: css`
        color: black;
        position: absolute;
        top: 16px;
        right: 17px;
        cursor: pointer;
        font-size: 32px;
    `,
    logo: css`
        position: absolute;
        top: 16px;
        left: 17px;
    `
};
