import React from 'react';
import {DashboardContext} from './DashboardContext';
import {McaDashboardDatePickerBar} from './McaDashboardDatePickerBar';
import DownloadCSVFactory from '../../js/download-csv-factory';
import {PRODUCT} from '../../constants/products';
import {ModelStatistics} from './model-statistics-dashboard/ModelStatistics';
import {useProduct} from '../../hooks/useProduct';
import {useCampaign} from '../../hooks/useCampaign';
import {useClient} from '../../hooks/useClient';
import {useMcaRuns} from '../../hooks/useMcaRuns';
import {useAdImpactRuns} from '../../hooks/useAdImpactRuns';
import {RUN_STATUS} from '../../constants/mca-run-statuses';
import {FeatureLV} from '../../components/pages/FeatureLV';
import {TAB_URL_KEY, useCampaignManagement} from '../../hooks/useCampaignManagement';
import _ from 'lodash';
import {SupersetDashboard} from "../../components/dashboards/SupersetDashboard";
import {styles} from './dashboards.styles';
import {SupersetCreateChart} from "../../components/dashboards/SupersetCreateChart";
import CallToActionLV from "../../components/tabs/CallToActionLV";
import {MediaVehicleDashboard} from "./media-vehicle-insight-dashboard/MediaVehicleDashboard";
import {ContributionDashboardWrapper} from "./contribution-dashboard/ContributionDashboardWrapper";

export default function DashboardTab() {
    const currentProduct = useProduct();

    // Dashboard Context hooks
    const {
        setDashboardStartDate,
        dashboardStartDate,
        setDashboardEndDate,
        dashboardEndDate,
        setDashboardRegion,
        dashboardRegion,
        setInsightMetadata
    } = React.useContext(DashboardContext);

    const {selectedMcaRun = {}} = useMcaRuns();
    const {adImpactRun = {}} = useAdImpactRuns();

    const {campaignName} = useCampaign() || {};
    const { dashboardTabIndex } = useCampaignManagement() || {};
    const {companyName: clientName} = useClient() || {};

    // Dashboards only support Final runs
    const selectedMcaDashboardRun = (selectedMcaRun.runStatus === 'FINAL') ? selectedMcaRun : {};
    const selectedAdImpactDashboardRun = (adImpactRun.run_status === RUN_STATUS.COMPLETED) ? adImpactRun : {};

    const datePickerWithRegion = (
        <McaDashboardDatePickerBar
            selectedMcaRunId={selectedMcaDashboardRun.id}
            setDashboardStartDate={setDashboardStartDate}
            setDashboardEndDate={setDashboardEndDate}
            setDashboardRegion={setDashboardRegion}
            defaultStartDate={dashboardStartDate}
            defaultEndDate={dashboardEndDate}
            defaultRegion={dashboardRegion}
            setInsightMetadata={setInsightMetadata}
        />
    );

    const datePicker = (
        <McaDashboardDatePickerBar
            selectedMcaRunId={selectedMcaDashboardRun.id}
            setDashboardStartDate={setDashboardStartDate}
            setDashboardEndDate={setDashboardEndDate}
            defaultStartDate={dashboardStartDate}
            defaultEndDate={dashboardEndDate}
            setInsightMetadata={setInsightMetadata}
        />
    );

    // Tabs per product
    const tabContents = [
        {
            label: 'Model Statistics',
            nestedContent: datePicker,
            content: (
                <ModelStatistics
                    reportMetadata={{
                        clientName,
                        campaignName,
                        runName: selectedMcaDashboardRun.name
                    }}
                    selectedMcaRunId={selectedMcaDashboardRun.id}
                    kpiType={selectedMcaDashboardRun.kpiType || 'Units'}
                />
            )
        },
        {
            label: 'Contribution Analysis',
            nestedContent: datePicker,
            content: (
                <ContributionDashboardWrapper/>
            )
        },
        {
            label: 'Media Vehicle Insights',
            nestedContent: datePickerWithRegion,
            content: (
                <MediaVehicleDashboard
                    selectedMcaRunId={selectedMcaDashboardRun.id}
                />
            )
        },
        {
            label: 'Custom Insights',
            featureFlagKey: 'CUSTOM_DASHBOARDS',
            content: (
                <SupersetDashboard
                    dashboardId={selectedMcaDashboardRun.supersetDashboardId}
                    className={styles.supersetDashboard}
                />
            ),
            cta: {
                title: 'Create Chart',
                component: SupersetCreateChart
            }
        }
    ];

    const cta = React.useMemo(() => {
        const tab = tabContents[dashboardTabIndex || 0];

        const ctaConfig = tab.cta;

        if (ctaConfig) {
            const Component = ctaConfig.component;
            const Title = ctaConfig.title;

            const ctaButton = (
                <CallToActionLV
                    dataTest={'fileUpload-headerButton'}>
                    {Title}
                </CallToActionLV>
            );

            return (
                <Component
                    tableName={`'${selectedMcaDashboardRun.id}'`}
                    callToActionComponent={ctaButton}
                />
            )
        }

        return undefined;
    }, [dashboardTabIndex, selectedMcaRun]);

    // Populate labels for all dashboard CSV downloads
    let runName = _.get(selectedMcaRun, 'display');
    if (currentProduct.key === PRODUCT.ADIMPACT.key) {
        runName = _.get(selectedAdImpactDashboardRun, 'wavecast_name');
    }
    DownloadCSVFactory.setReportProperties(clientName, campaignName, runName);

    let description = 'Select an MMM analysis';
    if (selectedMcaDashboardRun?.name) {
        description = `Dashboard results for analysis: ${selectedMcaDashboardRun.name}`;
    }

    return (
        <FeatureLV
            data-test='dashboard-tabs'
            title='MMM Dashboards'
            description={description}
            tabs={tabContents}
            tabsUrlQueryKey={TAB_URL_KEY.MMM_DASHBOARD}
            tabHeightOffset={178}
            CallToAction={cta}
        >

        </FeatureLV>

    );
}

export {DashboardTab}
