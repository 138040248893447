import PropTypes from 'prop-types';
import {
  Typography,
  Divider,
  IconButton,
  Box,
  CircularProgress
} from '@mui/material';
import { Refresh } from '@mui/icons-material';
import { styled } from '@mui/system';
import { TabsLV } from '../../components/tabs/TabsLV';

/**
 * Standard Leavened Feature Page
 */
export const FeatureLV = ({
  title,
  description,
  CallToAction = null,
  Overlay,
  refreshHandler,
  sx = {},
  tabs,
  tabsUrlQueryKey,
  tabIndexOverride,
  tabHeightOffset,
  isTabResetScroll,
  ariaLabel,
  loading = false,
  dataTest,
  children,
}) => {

  return (
    <FeatureBodyStyled
      sx={sx}
      aria-label={ariaLabel}
      loading={loading.toString()}
      data-test={dataTest}
      className='feature-container-lv'>

      <LoadingSpinner loading={loading} />

      <FeatureHeaderStyled className='feature-header-container'>
        <TitleDescriptionContainerStyled>
          <Title title={title} />
          <Description description={description} />
        </TitleDescriptionContainerStyled>

        <CTA CallToAction={CallToAction} refreshHandler={refreshHandler} />

      </FeatureHeaderStyled>

      <Divider hidden={!!tabs} />

      <OverlayContainer Overlay={Overlay}>

        <FeatureBody
          hasOverlay={!!Overlay}
          tabs={tabs}
          tabsUrlQueryKey={tabsUrlQueryKey}
          tabIndexOverride={tabIndexOverride}
          tabHeightOffset={tabHeightOffset}
          isTabResetScroll={isTabResetScroll}
          hasDescription={!!description}>
          {children}
        </FeatureBody>

      </OverlayContainer>

    </FeatureBodyStyled>
  );
};

const FeatureBody = ({
  tabs,
  tabsUrlQueryKey,
  tabIndexOverride,
  tabHeightOffset,
  isTabResetScroll,
  hasDescription,
  hasOverlay,
  children
}) => {

  let extendedCss = {};
  if(hasOverlay) {
    extendedCss = {
      opacity: 0.3,
      pointerEvents: 'none'
    };
  }

  if(!tabs) {
    return (
      <ChildrenStyled
        sx={extendedCss}
        className='feature-children'
        description={hasDescription ? 1 : 0}>
        {children}
      </ChildrenStyled>
    );
  }

  return (
    <TabsLV
      tabs={tabs}
      sx={extendedCss}
      tabsUrlQueryKey={tabsUrlQueryKey}
      tabIndexOverride={tabIndexOverride}
      tabHeightOffset={tabHeightOffset}
      isTabResetScroll={isTabResetScroll}>

      {children}

    </TabsLV>
  );
};

/**
 * Display content over Feature body section
 */
const OverlayContainer = ({
  Overlay,
  children
}) => {
  if (!Overlay)  {
    return (
      <>
        {children}
      </>
    );
  }

  return (
    <>

      <OverlayStyled
        className='feature-overlay-lv'
        boxShadow={12}>
        {Overlay}
      </OverlayStyled>

      {children}

    </>
  );
};

const OverlayStyled = styled(Box)(({
  theme,
  loading
}) => {
  return {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    justifyContent: 'center',
    alignItems: 'center',
    top: '50%',
    left: '50%',
    fontSize: '50px',
    transform: 'translate(-50%,-50%)',
    zIndex: 100,
    background: 'white',
    padding: 24,
    borderRadius: 20
  };
  
});

const RefreshIcon = ({
  refreshHandler,
  hasCTA
}) => {

  if (!refreshHandler) {
    return null;
  }

  return (
    <CallToActionContainerStyled>
      <IconButton
        aria-label='Refresh'
        sx={{
          marginRight: hasCTA ? '16px' : 0
        }}
        color='primary'
        onClick={refreshHandler}>

        <Refresh/>

      </IconButton>
    </CallToActionContainerStyled>
  );

};

const CTA = ({
  CallToAction,
  refreshHandler
}) => {
  
  if (!CallToAction && !refreshHandler) {
    return null;
  }

  // Parse component if sent as function
  if (typeof CallToAction === 'function') {
    CallToAction = <CallToAction/>;
  }

  return (
    <CallToActionContainerStyled>
      <RefreshIcon
        hasCTA={!!CallToAction}
        refreshHandler={refreshHandler}/>

      {CallToAction}
    </CallToActionContainerStyled>
  );

};

const Title = ({
  title
}) => {
  
  if (!title) {
    return null;
  }

  return (
    <Typography
      variant={'h4'}
      color='primary'
      component='div'>
      {title}
    </Typography>
  );

};

const Description = ({
  description
}) => {
  
  if (!description) {
    return null;
  }

  return (
    <DescriptionTypography
      variant={'subtitle1'}
      component='div'>
      {description}
    </DescriptionTypography>
  );

};

const LoadingSpinner = ({
  loading
}) => {
  if (!loading) {
    return null;
  }

  return (
    <CircularProgressStyled
      className='feature-spinner'
      color='inherit'/>
  );
};

const ChildrenStyled = styled(Box)(({
  theme,
  description
}) => ({
  alignItems: 'center',
  overflowY: 'auto',
  width: '100%',
  height: description ? 'calc(100% - 96px)' : 'calc(100% - 66px)', // Offset from header,
}));

const DescriptionTypography = styled(Typography)(({
  theme
}) => ({
  color: theme.palette.text.secondary
}));

const FeatureHeaderStyled = styled('div')(({
  theme
}) => ({
  display: 'grid',
  gridTemplateColumns: '9fr 3fr',
  padding: '12px 24px',
  background: '#F6F6F6'
}));

const FeatureBodyStyled = styled(Box)(({
  theme,
  loading,
}) => {

  let loadingCss = {};
  const isLoading = (loading === 'true');

  if(isLoading) {
    loadingCss = {
      opacity: '0.6',
      pointerEvents: 'none',
    };
  }

  return {
    height: 'inherit',
    width: '100%',
    ...loadingCss
  };
  
});

const TitleDescriptionContainerStyled = styled('div')(({
  theme
}) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 2
}));

const CallToActionContainerStyled = styled('div')(({
  theme
}) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center'
}));

const CircularProgressStyled = styled(CircularProgress)(({
  theme
}) => ({
  position: 'absolute',
  left: '50%',
  right: '50%',
  top: '50%',
  color: '#979797',
  opacity: '0.6',
}));

FeatureLV.propTypes = {
  /**
   * Title to display for feature
   */
  title: PropTypes.string,
  /**
   * Description for feature
   */
  description: PropTypes.string,
  /**
   * Component to put where Call To Action might be
   */
  CallToAction: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object
  ]),
  /**
   * Overlay component that prevents all action in Feature
   */
  Overlay: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object
  ]),
  /**
   * Navigation loading status
   */
  loading: PropTypes.bool,
  /**
   * Accessibility text used be screen-readers and tests
   */
  ariaLabel: PropTypes.string,
  /**
   * Will display a refresh icon and handle click
   */
  refreshHandler: PropTypes.func,
  /**
   * Data test tag for test suite
   */
  dataTest: PropTypes.string,
  /**
   * Extended styles
   */
  sx: PropTypes.object,
  /**
   * @param tabs Array of tabs
   *        EXAMPLE: [
   *                   {
   *                     label: 'Sub Tab 1',
   *                     nestedContent: <MyComponentAboveTabBody/>
   *                     content: <MySubTabComponent/>
   *                   }
   *                 ]
   */
  tabs: PropTypes.array,
  /**
   * URL query param key to maintain state of selected tab in URL
   * From TAB_URL_KEY in useCampaignManagement.jsx
   * EXAMPLE: https://leavened.io?scoreTab=2
   * 
   */
  tabsUrlQueryKey: PropTypes.string,
  /**
   * For scrollbars, the tab body needs to know the height of any child objects
   * in order just when to add the scrollbars
   */
  tabHeightOffset: PropTypes.number,
  /**
   * If true, updates scrollbar position to 0 upon tab change
   * Default: true
   */
  isTabResetScroll: PropTypes.bool,
};
