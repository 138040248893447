import React from 'react';
import _ from 'lodash';

export const PricingStructure = ({cell={}}) => {
  const COST_PER_LABEL = {
    Impressions: 'CPM',
    Ratings: 'CPP',
    Clicks: 'CPC'
  };

  const measure =_.get(cell, 'row.values.measure');
  const costPerType = COST_PER_LABEL[measure] || '';

  return (
    <div>{`${costPerType}`}</div>
  );
};
