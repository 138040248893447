export const chartColors = [
    '#2A5588', //blue
    '#435063', //gray
    '#FF7863', //orange
    '#FFDD5B', //bright yellow
    '#DCD6D0', //beige
    '#E0E02A', // yellow green
    '#A8DA7C', // green
    '#74795F', //finch green
    '#809190', //dark green
    '#595653' //brown
];

export const getChartColorAtIndex = (idx) => {
  let index = idx;
  if(index > (chartColors.length - 1)) {
    index = index % chartColors.length;
  }
  return chartColors[index];
};

/**
 * Returns a color by index for a custom color by map and key
 * Will not return and index color if it is used by the custom color map
 * @param idx Index of color to get
 * @param colorMap Map of colors my key name EX: {col1: 'red', col2: 'blue'}
 * @param key column key to retrieve color
 * @returns Hex color
 */
export const getChartColor = (
  idx=0, colorMap={}, key
) => {
  if(colorMap[key]) {
    return colorMap[key];
  }

  // Get assigned colors
  const mappedColors = Object.values(colorMap);
  const availableColors = chartColors.filter(c => !mappedColors.includes(c));

  let index = idx;
  if(index > (availableColors.length - 1)) {
    index = index % availableColors.length;
  }

  return availableColors[index];
};
