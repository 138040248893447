import React from 'react';
import {TableLV} from '../../../components/data-table/TableLV';
import {NumericCell} from '../../../components/data-table/cells/NumericCell';
import {divideNumbers, addNumbers, subtractNumbers} from '../../../js/utils';

export const CombinedSummaryHeader = ({
  data=[],
  kpiType,
  loading=false,
  hasData=true,
  timeScale
}) => {
  const headerData = React.useMemo(
    () => fieldCalculations(data, kpiType), [data, kpiType]
  );
  
  const timeScaleLabel = timeScale ? timeScale.charAt(0).toUpperCase() + timeScale.slice(1).toLowerCase() : 'Daily';

  let projectedLabel = 'Projected Revenue';
  let incrementalLabel = 'Incremental Revenue';
  let marginalLabel = 'Marginal ROI';
  let ratioLabel = 'ROI';
  let isCurrency = true;
  let decimals = 0;
  const alignColumn = 'center';

  if(kpiType === 'Units') {
    projectedLabel = 'Projected Units';
    incrementalLabel = 'Incremental Units';
    marginalLabel = 'Marginal CPA';
    ratioLabel = 'CPA';
    isCurrency = false;
  }

  const override = {
    total1Spend: {
      name: `${timeScaleLabel} Spend`,
      align: alignColumn,
      component: <NumericCell isCurrency={true} decimals={decimals} isInline={true} align={alignColumn}/>
    },
    total1Contribution: {
      name: projectedLabel,
      align: alignColumn,
      component: <NumericCell isCurrency={isCurrency} decimals={decimals} isInline={true} align={alignColumn}/>
    },
    total1Ratio: {
      name: ratioLabel,
      align: alignColumn,
      component: <NumericCell isCurrency={!isCurrency} isInline={true} align={alignColumn}/>
    },
    separator1: {
      separator: true
    },
    total2Spend: {
      name: `${timeScaleLabel} Spend`,
      align: alignColumn,
      component: <NumericCell isCurrency={true} decimals={decimals} isInline={true} align={alignColumn}/>
    },
    total2Contribution: {
      name: projectedLabel,
      align: alignColumn,
      component: <NumericCell isCurrency={isCurrency} decimals={decimals} isInline={true} align={alignColumn}/>
    },
    total2Ratio: {
      name: ratioLabel,
      align: alignColumn,
      component: <NumericCell isCurrency={!isCurrency} isInline={true} align={alignColumn}/>
    },
    separator2: {
      separator: true,
      style: {
        width: '60px'
      },
    },
    incrementalSpend: {
      align: alignColumn,
      component: <NumericCell isCurrency={true} decimals={decimals} isInline={true} align={alignColumn}/>
    },
    incremental: {
      name: incrementalLabel,
      align: alignColumn,
      component: <NumericCell isCurrency={isCurrency} decimals={decimals} isInline={true} align={alignColumn}/>
    },
    marginal: {
      name: marginalLabel,
      align: alignColumn,
      component: <NumericCell isCurrency={!isCurrency} isInline={true} align={alignColumn}/>
    },
  };

  const groups = [{
    name: 'Scenario 1',
    align: alignColumn,
    columns: ['total1Spend', 'total1Contribution', 'total1Ratio']
  }, {
    name: 'Separator1',
    columns: ['separator1']
  }, {
    name: 'Scenario 2',
    align: alignColumn,
    columns: ['total2Spend', 'total2Contribution', 'total2Ratio']
  }, {
    name: 'Separator2',
    columns: ['separator2']
  }, {
    name: 'Comparison',
    align: alignColumn,
    columns: ['incrementalSpend', 'incremental', 'marginal']
  }];

  return (
    <TableLV
      data={headerData}
      hasData={hasData}
      isLoading={loading}
      groups={groups}
      override={override}
      disableTableSort={true}
    />
  );
};

export const fieldCalculations = (data=[], kpiType) => {
  const totalsFromData = data.map(row => ({
    scenario1Spend: row.scenario1Spend,
    scenario2Spend: row.scenario2Spend,
    scenario1Contribution: row.scenario1Contribution,
    scenario2Contribution: row.scenario2Contribution,
  })).reduce((map, row) => ({
    total1Spend: addNumbers(map.total1Spend,
                            row.scenario1Spend),
    total2Spend: addNumbers(map.total2Spend,
                            row.scenario2Spend),
    total1Contribution: addNumbers(map.total1Contribution,
                                   row.scenario1Contribution),
    total2Contribution: addNumbers(map.total2Contribution,
                                   row.scenario2Contribution)
  }), {});

  const {
    total1Spend=0,
    total2Spend=0,
    total1Contribution=0,
    total2Contribution=0,
  } = totalsFromData;

  let total1Ratio = divideNumbers(total1Contribution, total1Spend);
  let total2Ratio = divideNumbers(total2Contribution, total2Spend);

  const incrementalSpend = subtractNumbers(total2Spend,
                                           total1Spend);
  const incremental = subtractNumbers(total2Contribution,
                                      total1Contribution);
  let marginal = divideNumbers(incremental,
                               incrementalSpend);

  if(kpiType === 'Units') {

    marginal = divideNumbers(incrementalSpend,
                             incremental);
    total1Ratio = divideNumbers(total1Spend, total1Contribution);
    total2Ratio = divideNumbers(total2Spend, total2Contribution);
  }

  return [{
    total1Spend,
    total1Contribution,
    total1Ratio,
    separator1: null,
    total2Spend,
    total2Contribution,
    total2Ratio,
    separator2: null,
    incrementalSpend,
    incremental,
    marginal,
  }];
};
