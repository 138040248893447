import React from 'react';
import {CallToActionLV} from '../../components/tabs/CallToActionLV';
import {ModalLV} from '../../../src/components/modal/ModalLV';
import {UserManagementForm} from './UserManagementForm';
import {UserTable} from './UserTable';
import {HeaderContext} from '../../hooks/HeaderContext';
import {useUsersList} from './useUsersList';
import {useUserRoleFromGroup} from '../../hooks/useUserRoleFromGroup';
import { FeatureLV } from '../../components/pages/FeatureLV';

export const UserContainer = () => {
  const {data, loading, refetchUsers} = useUsersList();
  const {isAdmin} = useUserRoleFromGroup();

  const {setRefreshClickHandler} = React.useContext(HeaderContext);
  React.useEffect(() => {
    setRefreshClickHandler(refetchUsers);
  },[refetchUsers]);

  // Allow admin only
  if (!isAdmin) {
    return null;
  }

  return (
    <FeatureLV
      title='User Management'
      description='Add or edit user access'
      CallToAction={
        <ModalLV>
          <CallToActionLV
            dataTest={'userManagement-headerButton'} >
            New User
          </CallToActionLV>

          <UserManagementForm refetchUsers={refetchUsers}/>

        </ModalLV>
      }>

      <UserTable
        data={data}
        loading={loading}
        refetchUsers={refetchUsers}
      />

    </FeatureLV>);
  
};
