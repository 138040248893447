import React from 'react';
import {useForm} from 'react-hook-form';
import {FormRowLV} from '../../components/form/row/FormRowLV';
import {TextInputLV} from '../../components/form/text/TextInputLV';
import {TitleLV} from '../../components/form/title/TitleLV';
import {SelectLV} from '../../components/form/select/SelectLV';
import {DateRangePickerLV} from '../../components/form/date/DateRangePickerLV';
import {OrangeButtonLV} from '../../components/form/buttons/OrangeButtonLV';
import {ButtonLV} from '../../components/form/buttons/ButtonLV';
import {useDeleteCampaign} from './useDeleteCampaign';
import {usePost, usePut} from '../../hooks/useRest';
import {useCampaignManagement} from '../../hooks/useCampaignManagement';
import {urlBuilders} from '../../js/constants.js';
import {useDefaultIds} from '../../hooks/useDefaultIds';
import useDeepCompareEffect from 'use-deep-compare-effect';
import moment from 'moment';
import _ from 'lodash';
import {useGetUserPrivileges} from '../../hooks/useGetUserPrivileges';

export const NewCampaignForm = ({
  cancelClick=()=>{},
  isLoading,
  partners=[],
  setError=()=>{},
  setSuccess=()=>{},
  isEdit=false,
  defaultValues={},
  formTitle
}) => {
  const {handleSubmit, formState, register, errors, control, setValue, getValues, reset} = useForm();
  const {dirty, isSubmitting} = formState;
  const {partnerId, clientId} = useCampaignManagement();
  const {defaultPartnerId, defaultClientId} = useDefaultIds(partners);

  const [selectedPartner, setSelectedPartner] = React.useState();
  const [selectedClient, setSelectedClient] = React.useState();

  // Create New Endpoint
  const createUrl = urlBuilders.createCampaign();
  const updateUrl = urlBuilders.updateCampaign(defaultValues.id);
  const deleteUrl = updateUrl;

  const [{data: postData, loading, error: postError}, postCampaign] = usePost({url: createUrl});
  const [{
    data: putData,
    error: putError
  }, putCampaign] = usePut({url: updateUrl});

  const partnerOptions = partners.map(partner => ({
    label: partner.display,
    value: partner.id,
    companies: partner.companies
  }));

  const selectedClients = _.get(selectedPartner, 'companies', []);
  const clientOptions = selectedClients.map(client => ({
    label: client.display,
    value: client.id
  }));

  // Effect to select default partner
  const selectedPartnerId = partnerId || defaultPartnerId;
  useDeepCompareEffect(() => {
    if(!selectedPartnerId) {
      return;
    }

    const defaultPartner = partnerOptions.find(p => p.value === selectedPartnerId);
    setSelectedPartner(defaultPartner);
  }, [selectedPartnerId, partnerOptions, setSelectedPartner]);

  // Select default client
  const selectedClientId = clientId || defaultClientId;
  useDeepCompareEffect(() => {
    if(!selectedClientId || !clientOptions.length) {
      return;
    }

    const defaultClient = clientOptions.find(c => c.value === selectedClientId);
    setSelectedClient(defaultClient);
    setValue('client', defaultClient);
  }, [clientOptions, selectedClientId, setValue, setSelectedClient]);

  // Effect after form submission
  // Determine Success/Fail status to show to user
  React.useEffect(() => {
    if(loading) {
      return;
    }

    const {name} = getValues();
    let successTitle = 'New Campaign Added';
    let successText = `${name} has been successfully added.`;
    let errTitle = 'Campaign Not Added';
    let errText = `${name} failed to be added.`;

    if(isEdit) {
      successTitle = 'Campaign Updated';
      successText = `${name} has been successfully updated.`;
      errTitle = 'Campaign Not Updated';
      errText = `${name} failed to be updated.`;
    }

    if(postError || putError) {
      const error = postError || putError;
      setError({
        title: errTitle,
        text: errText,
        description: _.get(error, 'response.data.message')
      });
      return;
    }

    if(postData || putData) {
      setSuccess({
        title: successTitle,
        text: successText
      });
    }
  }, [postData, putData, loading, postError, putError, setSuccess, setError, getValues, isEdit]);

  const {DeleteCampaignModal, DeleteSuccessFailMessage} = useDeleteCampaign({
    deleteUrl,
    title: 'Delete Campaign',
    type: 'Campaign',
    name: defaultValues.display,
    cancelClick
  });

  const {canViewPartner, canViewCompany} = useGetUserPrivileges();
  
  // Display Success/Fail Delete message to user
  if(_.get(DeleteSuccessFailMessage, 'props.message.title')) {
    return DeleteSuccessFailMessage;
  }

  const campaignSubmit = (formData={client: {}}) => {
    if(isEdit) {
      putCampaign({
        data: {
          id: defaultValues.id,
          campaignName: formData.name,
          companyId: defaultValues.companyId,
          campaignSpend: 0,
          campaignSpendRate: 'TOTAL',
          status: 'ACTIVE',
          startDate: moment(formData.startDate).format('YYYY-MM-DD'),
          endDate: moment(formData.endDate).format('YYYY-MM-DD'),
          notes: undefined,
          product: undefined,
          viewId: formData.apiViewId
        }
      });
      return;
    }

    const formClientId = _.get(formData, 'client.value', formData.client) || selectedClient.value;

    postCampaign({
      data: {
        campaignName: formData.name,
        companyId: formClientId,
        campaignSpend: 0,
        campaignSpendRate: 'TOTAL',
        startDate: moment(formData.startDate).format('YYYY-MM-DD'),
        endDate: moment(formData.endDate).format('YYYY-MM-DD')
      }
    });
  };

  // Set partner and clear client on partner select
  const handlePartnerSelect = (selected) => {
    reset({
      client: null
    });
    setSelectedPartner(selected);
  };

  // Set partner and clear client on partner select
  const handleClientSelect = (selected) => {
    setSelectedClient(selected);
  };

  return (
    <form
      onSubmit={handleSubmit(campaignSubmit)}
      className={'new-campaign-form'}
      data-test='add-new-form-campaign'>

      <TitleLV hidden={!formTitle}>
        {formTitle}
      </TitleLV>

      <FormRowLV
        id='partner-select-row'
        hidden={isEdit || !canViewPartner}>
        <SelectLV
          id='partner-select'
          name='partner'
          placeholder={'Select Partner'}
          options={partnerOptions}
          isLoading={isLoading}
          onChange={handlePartnerSelect}
          defaultValue={selectedPartner}
          dataTest='partner-select'
        />
      </FormRowLV>

      <input
        type='hidden'
        name='client'
        ref={register}
        value={defaultClientId}
      />

      <FormRowLV
        id='client-select-row'
        hidden={isEdit || !canViewCompany}>
        <SelectLV
          id='client-select'
          control={control}
          name='client'
          placeholder={'Select Client'}
          options={clientOptions}
          rules={{required: 'Select a Client'}}
          errors={errors}
          isLoading={isLoading}
          onChange={handleClientSelect}
          defaultValue={selectedClient}
          dataTest='client-select'
        />
      </FormRowLV>

      <FormRowLV>
        <TextInputLV
          name='name'
          placeholder={'Campaign Name'}
          dataTest={'Name-add-new-form'}
          description={'250 Characters Max'}
          dirty={dirty}
          register={
            register(
              {
                required: 'Campaign Name is required',
                maxLength: {
                  value: 250,
                  message: 'Cannot exceed 250 characters'
                }
              }
            )}
          errors={errors}
          width={315}
          defaultValue={defaultValues.campaignName}
        />
      </FormRowLV>

      <FormRowLV>
        <DateRangePickerLV
          startLabel='Date Selector'
          startDescription='From'
          endLabel='&nbsp;'
          endDescription='To'
          width={315}
          register={register(
            { required: 'Date Range Required'}
          )}
          errors={errors}
          setValue={setValue}
          defaultStartDate={defaultValues.startDate}
          defaultEndDate={defaultValues.endDate}
        />
      </FormRowLV>

      <FormRowLV hidden={!isEdit}>
          <TextInputLV
            name='apiViewId'
            placeholder={'View Id'}
            dataTest={'viewId-add-new-form'}
            dirty={dirty}
            register={
              register(
              {
                maxLength: {
                  value: 250,
                  message: 'Cannot exceed 250 characters'
                }
              })
            }
            width={310}
        />
      </FormRowLV>

      <FormRowLV>
        <OrangeButtonLV
          submitting={isSubmitting}
          dirty={dirty}
          dataTest={'addNew-submitButton'}>
          Save
        </OrangeButtonLV>

        <ButtonLV
          onClick={cancelClick}
          submitting={isSubmitting}
          dataTest={'addNew-cancelButton'}>
          Cancel
        </ButtonLV>
      </FormRowLV>

      <FormRowLV className='delete-row' hidden={!isEdit} center={true}>
        {DeleteCampaignModal}
      </FormRowLV>

    </form>
  );
};
