import React from 'react';
import { styled } from '@mui/system';
import { R1AutoComplete } from '@rainagency/rain-one-soggy-loaf';
import {
  getProductByFeatureKey
} from '../../../../hooks/useProductFeatures';
import { PRODUCT, FEATURES } from '../../../../constants/features';
import { RUN_STATUS } from '../../../../constants/mca-run-statuses';
import { useFeatureSelect } from '../../../../hooks/useFeatureSelect';

export const AnalysisRunSelect = ({
  adImpactRuns = [],
  mmmRuns = [],
  selectedAdImpactRun = {},
  selectedMMMRun = {},
  onSelectAnalysis = () => {},
  selectedFeature,
  loading
}) => {

  const { onFeatureSelect } = useFeatureSelect();
  
  const {
    isDisplay, // Should we show the Analysis Run Drop-down
    parentProduct,
    selectedMMMOption,
    selectedAdImpactOption,
    allRunOptions
  } = React.useMemo(
    () => getSelectAnalysisProps(
      selectedFeature,
      mmmRuns,
      adImpactRuns,
      selectedMMMRun.id,
      selectedAdImpactRun.wavecast_id,
    )
    , [mmmRuns, selectedFeature, selectedMMMRun, selectedAdImpactRun]
  );

  if(!isDisplay) {
    return null;
  }

  // Selected option based on current feature
  const selectedOptionMap = {
    [PRODUCT.MMM.key]: selectedMMMOption,
    [PRODUCT.ADIMPACT.key]: selectedAdImpactOption
  };
  const selectedOption = selectedOptionMap[parentProduct?.key];

  // When run is selected
  const onChangeHandler = (selectedRun) => {
    if(!selectedRun) {
      return;
    }

    // Redirect to specific feature if we are changing
    if(parentProduct !== selectedRun?.product) {
      onFeatureSelect({
        feature: selectedRun?.feature
      });
    }
    onSelectAnalysis(selectedRun, selectedRun?.product);
  };

  return (
    <AnalysisSelectContainerStyled
      className='analysis-select'>

      <R1AutoComplete
        label={'Select Analysis'}
        aria-label={'Select Analysis'}
        //freeSolo
        forcePopupIcon={true}
        optionLabelName={'display'}
        options={allRunOptions}
        onChange={onChangeHandler}
        loading={loading}
        value={selectedOption || null}
        renderOption={(props, option) => {
          return (
            <RunOption
              key={option.id}
              autocompleteProps={props}
              option={option}
            />
          );
        }}
      />

    </AnalysisSelectContainerStyled>
  );
};

const RunOption = ({
  autocompleteProps,
  option
}) => {

  // Nested Run
  if (option.nested) {
    return (
      <div
        {...autocompleteProps}
        style={{
          display: 'flex',
          gap: '8px',
          alignItems: 'flex-start',
          paddingLeft: '24px'
        }}>

        <div>
          -
        </div>

        <div>
          {option.display}
        </div>
        
      </div>
    );
  }

  // Parent Run
  return (
    <div
      {...autocompleteProps}
      style={{
        fontWeight: 'bold'
      }}>

      {option.display}

    </div>
  );
};

const getSelectAnalysisProps = (
  selectedFeature = {},
  mmmRuns = [],
  adImpactRuns = [],
  selectedMMMId,
  selectedAdImpactId,
) => {
  const DropdownProducts = [PRODUCT.MMM, PRODUCT.ADIMPACT];
  const ExcludeFeatures = [
    FEATURES.MANAGE_DATA,
    FEATURES.MMM_ANALYSIS,
    FEATURES.MMM_SELECTED,
    FEATURES.ADIMPACT_ANALYSIS
  ];

  if(ExcludeFeatures.some(e => e === selectedFeature)) {
    return false;
  }

  const featureParentProduct = getProductByFeatureKey(selectedFeature.key) || {};
  const filteredMMM = mmmRuns
        .filter(run => run.runStatus === 'FINAL')
        .map(mmm => ({
          ...mmm,
          product: PRODUCT.MMM,
          feature: FEATURES.MMM_DASHBOARDS,
          nested: (!!mmm.parentId && mmm.parentId !== mmm.id)
        }));
  const filteredAdImpact = adImpactRuns
        .filter(run => run.run_status === RUN_STATUS.COMPLETED)
        .map(a => ({
          ...a,
          id: a.wavecast_id,
          parentId: a.mca_run_id,
          display: a.wavecast_name,
          product: PRODUCT.ADIMPACT,
          feature: FEATURES.ADIMPACT_DASHBOARDS,
          runDate: a.run_date,
          parentName: a.mca_run_name,
          nested: true,
        }));

  const allRunOptions = formatNestedRunOptions(
    filteredMMM,
    filteredAdImpact
  );

  const selectedMMMOption = allRunOptions.find(r => r.id === selectedMMMId) || null;
  const selectedAdImpactOption = allRunOptions.find(a => a.id === selectedAdImpactId) || null;

  return {
    parentProduct: featureParentProduct,
    isDisplay: DropdownProducts.some(d => d === featureParentProduct),
    filteredMMM,
    filteredAdImpact,
    selectedMMMOption,
    selectedAdImpactOption,
    allRunOptions
  };
};

/**
 * Sort options my Parent runs
 * Parent runs act as a stack header,
 *   child runs display underneath the parent
 */
const formatNestedRunOptions = (
  mmmRuns = [],
  adImpactRuns = [],
) => {
  const allRuns = [...mmmRuns, ...adImpactRuns];


  // Create parent run object for quick map lookup
  const parentRunMap = allRuns
        .filter(r => !r.nested)
        .reduce((prev, current) => {
          return {
            ...prev,
            [current.id]: {
              ...current,
              childRuns: []
            }
          };
        }, {});

  // Populate children to parent run map
  allRuns
    .filter(r => r.nested)
    .forEach(r => {
      parentRunMap[r.parentId]
        ?.childRuns?.push(r);
    });

  // Concat child runs with parent runs
  const allRunOptions = Object.values(parentRunMap)
    .reduce((prev, current) => {
      const {
        childRuns,
        ...currentRun
      } = current;
      
      return [
        ...prev,
        currentRun,
        ...childRuns,
      ];
    }, []);

  // Sometimes a child run won't have a parent available
  const orphanChildRuns = allRuns
        .filter(r => r.nested)
        .filter(r => (!parentRunMap[r.parentId]))
        .map(r => ({
          ...r,
          nested: false
        })) || [];

  return [
    ...allRunOptions,
    ...orphanChildRuns
  ];
};

const AnalysisSelectContainerStyled = styled('div')(({
  theme
}) => ({
  display: 'grid',
  alignItems: 'center',
  minWidth: 300,
  maxWidth: 400,
  marginRight: 26,
}));
