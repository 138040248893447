import React, {Component} from 'react';
import LogRocket from 'logrocket';
import {Provider} from 'react-redux';
import {BrowserRouter as Router} from 'react-router-dom';
import { NavigationProvider } from './hooks/NavigationProvider';
import { SnackbarProvider } from 'notistack';

import Snackbar from './components/snackbar';
import Modal from './components/modal';
import Dialog from './components/dialog';
import Persistance from './components/persistance.jsx';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import theme from "./theme";

import {globalStyles} from './app.styles.js';
import { Leavened } from './Leavened';

import {Config} from './config/ConfigFactory';
import {ImagePopoverProvider} from "./components/images/context/ImagePopoverContext";
import {YeastyProvider} from "./components/yeasty/context/YeastyContext";
const config = Config.getConfig() || {};

const parsedEnv = config.BASE_URL ? config.BASE_URL.match(/^https?:\/\/([^\-:]+).*/i) : '';
if (parsedEnv[1] && ['prod','beta'].includes(parsedEnv[1])) {
  LogRocket.init('at9gjo/leavened');
}

globalStyles();

export default class App extends Component {
  render() {
    if (this.props.authState !== "signedIn") {
      return null;
    }
    this.props.store.getState().platform.cognito = this.props.authData;
    return (
      <Provider store={this.props.store}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Router>
              <Persistance>

                <SnackbarProvider
                  autoHideDuration={2000}
                  hideIconVariant={true}
                >
                  <YeastyProvider>
                  <ImagePopoverProvider>
                    <NavigationProvider>
                      <Leavened/>
                    </NavigationProvider>

                  </ImagePopoverProvider>
                  </YeastyProvider>
                </SnackbarProvider>

                <Modal />
                <Dialog />
                <Snackbar />
              </Persistance>
            </Router>
          </ThemeProvider>
        </StyledEngineProvider>
      </Provider>
    );
  }
}
