import React from 'react';
import PropTypes from 'prop-types';
import {styles} from './TabContentLV.styles';

/**
 * Wrapper for Primary tab content
 * Supports a Call To Action button on the top left
 */
export default function TabContentLV({className, callToAction, children}) {
  const classNames = [styles.root, className].join(' ');
  const contentClasses = [styles.tabContent, 'tab-content'].join(' ');

  return (
    <div data-test={'tab-root'}
         className={classNames} >

      <div data-test='call-to-action'
           className={styles.ctaWrapper}>
        {callToAction}
      </div>

      <div data-test='tab-content'
           className={contentClasses} >

        {children}

      </div>
    </div>
  );
}

TabContentLV.propTypes = {
  /**
   * Will display this content on the top-right of the page
   * Designed to house a Call to Action button
   */
  callToAction: PropTypes.object
};

export {TabContentLV};
