import React from 'react';
import {GridStatusCell} from "../../../components/data-grid/GridStatusCell";
import {RUN_STATUS} from "../../../constants/mca-run-statuses";
import {useGetPolling} from "../../../hooks/usePolling";
import {urlBuilders} from "../../../js/constants";

/**
 * Display normal cell status if File Row is not pending
 * If pending, Poll the file endpoint and refresh table on complete
 */
export const AdImpactStatusCell = ({
                                   cell,
                                   fetchAdImpacts
                               }) => {

    if(cell?.row?.status === RUN_STATUS.INITIATED.toUpperCase()) {
        return (
            <AdImpactPollingStatusCell
                cell={cell}
                fetchAdImpacts={fetchAdImpacts}
            />
        );
    }

    return (
        <GridStatusCell
            dataTest={`ad-impact-table-status-cell-${cell.row.runName}`}
            id={cell.row.id}
            status={`AD_IMPACT_${cell.row.status}`}
            name={cell.row.runName}
            onClick={() => {}}
        />
    );
};


const AdImpactPollingStatusCell = ({
                                   cell,
                                   fetchAdImpacts = () => {}
                               }) => {
    const {
        id,
        campaignId,
        status,
        runName
    } = cell?.row;
    const pollUrl = urlBuilders.getWavecastRunsByCampaignId(campaignId);

    const {data=[], clearIntervalInstance} = useGetPolling({
        interval: 5000,
        url: pollUrl
    });

    const statusFromData = React.useMemo(() => {
        return data.find(d => d.wavecast_id === id)?.run_status;
    }, [data]);

    React.useEffect(() => {
        if(!statusFromData) {
            return;
        }

        if (statusFromData !== RUN_STATUS.INITIATED) {
            clearIntervalInstance();
            fetchAdImpacts();
        }

    }, [statusFromData]);

    return (
        <GridStatusCell
            dataTest={`ad-impact-table-status-cell-${runName}`}
            id={cell.row.id}
            status={`AD_IMPACT_${status}`}
            name={cell.row.runName}
            onClick={() => {}}
        />
    );

};
