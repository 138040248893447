import React from 'react';
import {urlBuilders} from '../../../js/constants';
import {useGet} from '../../../hooks/useRest';
import {useCampaignManagement} from '../../../hooks/useCampaignManagement';
import moment from 'moment';
import _ from 'lodash';

/**
 * Gets all info from scoring endpoint
 */
export const useScoringByCampaignId = () => {
  const {campaignId, mcaRunId, scoringId} = useCampaignManagement();
  const [{data, loading}, getScoringByCampaignId] = useGet({
    url: urlBuilders.getScoreCalculationsByCampaignId(campaignId),
    manual: true
  });

  React.useEffect(() => {
    if(!campaignId) {
      return;
    }
    getScoringByCampaignId();
  }, [campaignId, getScoringByCampaignId]);

  const sortedData = React.useMemo(
    () => sortScoresByTimestamp(data),
    [data]
  );

  const scoresByMcaRunId = React.useMemo(
    () => filterScoresByMcaRunId(sortedData, mcaRunId),
    [sortedData, mcaRunId]
  );

  const selectedScore = React.useMemo(
    () => findSelectedScore(sortedData, scoringId),
    [sortedData, scoringId]
  );

  return {
    getScoringByCampaignId,
    allScores: sortedData,
    scoresByMcaRunId,
    selectedScore,
    loading
  };
};

const sortScoresByTimestamp = (data=[]) => {
  const mapped = data.map(d => ({
    ...d,
    scoringTimestamp: moment(d.scoringDate).format('X'),
  }));
  return _.sortBy(mapped, 'scoringTimestamp').reverse();
};

const filterScoresByMcaRunId = (data=[], mcaRunId='') => {
  return data.filter(d => d.mcaRunId === mcaRunId);
};

const findSelectedScore = (data=[], scoreId='') => {
  return data.find(d => d.id === scoreId);
};
