import React from 'react';
import namor from 'namor';
import {GraphCandyBar} from './GraphCandyBar';
import {GRAPH_OPTIONS} from './graph-options.constant';
import {styles} from './GraphPanel.styles';

/**
 * Display panel for a Graph
 * Contains its own Control Panel "Candy Bar" if there are
 * more than one selectable graphs
 */
export const GraphPanel = ({
  data, fileData, dropdowns,
  graphOptions, imageElementId,
  includeGraphCandyBarInDownload,
  ...props
}) => {
  // display Dropdowns from parent
  const {TopDropdown,
         LeftDropdown,
         BottomDropdown} = getDropdowns(dropdowns);
  let defaultGraph = props.selected;

  // Select first graph option by default
  if(!defaultGraph &&
     Array.isArray(graphOptions) &&
     graphOptions.length > 0) {
    defaultGraph = graphOptions[0].value;
  }

  // Pie by default if no other graphs available
  if(!defaultGraph) {
    defaultGraph = GRAPH_OPTIONS.PIE.value;
  }

  let fullElementId = namor.generate(
    { words: 0, saltLength: 16 }
  );
  if(imageElementId) {
    fullElementId = imageElementId;
  }

  let fileDataWithIds;
  if(fileData){
    fileDataWithIds = giveEachDataAnId(fileData);
  }
  const [selectedGraph, setSelectedGraph] = React.useState(defaultGraph);
  const CurrentSelectedGraph = GRAPH_OPTIONS[selectedGraph].graph;
  const graphType = GRAPH_OPTIONS[selectedGraph].value;
  const classNames = [styles.panel, 'graph-panel'].join(' ');
  const title = props.title || '';

  // Update default selected graph if order changed by parent
  React.useEffect(() => {
    setSelectedGraph(defaultGraph);
  }, [defaultGraph]);

  return (
    <div id={fullElementId}
         data-test='graph-panel'
         className={classNames}>

      {title ? <div className={styles.label}>{title}</div> : null}
      
      <GraphCandyBar
        data={data}
        fileData={fileDataWithIds}
        selectedGraph={selectedGraph}
        setSelectedGraph={setSelectedGraph}
        TopDropdown={TopDropdown}
        graphOptions={graphOptions}
        elementId={fullElementId}
        includeInDownload={includeGraphCandyBarInDownload}
        {...props} />

      <div className={styles.graphsContainer}
           data-test='graphs-container'>

        {LeftDropdown}

        <CurrentSelectedGraph
          data={data}
          type={graphType}
          {...props} />

      </div>

      <div className={styles.belowGraph}>
        {BottomDropdown}
      </div>
    </div>
  );
};

const LOCATION = {
  TOP: 'top',
  LEFT: 'left',
  BOTTOM: 'bottom'
};

/**
 * dropDowns will be an object or array
 * return the 3 dropdowns rom parent to show on chart
 * Put single dropdown on top
 */
const getDropdowns = (drop) => {
  let dropDowns = drop;

  // No Dropdowns provided
  if(!drop) {
    dropDowns = [];
  }

  // Dropdown provided as non-array, add at first index
  if(!Array.isArray(drop)) {
    dropDowns = [drop];
  }

  return {
    TopDropdown: getDropdownContainer(LOCATION.TOP, dropDowns[0]),
    LeftDropdown: getDropdownContainer(LOCATION.LEFT, dropDowns[1]),
    BottomDropdown: getDropdownContainer(LOCATION.BOTTOM, dropDowns[2])
  };
};

/**
 * Get child dropdown with parent container
 */
const getDropdownContainer = (locale, children) => {
  if(!children) {
    return null;
  }

  const className = `${locale}-graph-dropdown`;
  return (
    <div
      className={className}
      data-test={className}>

      {children}

    </div>);
};

const giveEachDataAnId = (fileData) => {
  if(!Array.isArray(fileData)){
    return [];
  }
  fileData.forEach(elem => {
    let dataId = namor.generate(
      { words: 0, saltLength: 16 }
    );
    elem['id'] = dataId;
  });
  return fileData;
}
