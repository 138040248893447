import React from 'react';
import {ChicletLV} from '../../../components/chiclet/ChicletLV';
import {TableLV} from '../../../components/data-table/TableLV';
import {NumericCell} from '../../../components/data-table/cells/NumericCell';
import {urlBuilders} from '../../../js/constants';
import {useGet} from '../../../hooks/useRest';
import {R1TextBox} from '@rainagency/rain-one-soggy-loaf';
import {camelToNormal, forceNumeric} from '../../../js/utils';
import {getDummyData} from '../../../components/data-table/getDummyData';

export const PlacementTable = ({
                                   adImpactRun = {},
                                   isLoading
                               }) => {

    const query = {
        queryType: 'STATION_DAYPART_REPORT'
    };

    const [{data: dbData = [], loading}, getResults] = useGet({
        url: urlBuilders.getWavecastAnalysisResults(adImpactRun.wavecast_id, query),
        manual: true
    });

    const [totalCPM, setTotalCPM] = React.useState(adImpactRun.average_cpm || 0);

    React.useEffect(() => {
        if (adImpactRun.wavecast_id) {
            getResults();
            setTotalCPM(adImpactRun.average_cpm?.toFixed(0));
        }
    }, [
        adImpactRun.wavecast_id,
        getResults
    ]);

    const override = {
        media_cleared: {
            component: <NumericCell
                isCurrency={true}
                isInline={true}
                decimals={0}
                align='left'
            />,
            align: 'left'
        },
        impressions: {
            component: <NumericCell
                isInline={true}
                decimals={0}
                align='left'
            />,
            align: 'left'
        },
        cpm: {
            component: <NumericCell
                isCurrency={true}
                isInline={true}
                decimals={2}
                align='left'
            />,
            align: 'left'
        },
        spots: {
            component: <NumericCell
                isInline={true}
                decimals={0}
                align='left'
            />,
            align: 'left'
        },
        impact_index: {
            component: <NumericCell
                isInline={true}
                decimals={2}
                align='left'
            />,
            align: 'left'
        },
        confidence: {
            component: <NumericCell
                isInline={true}
                decimals={2}
                align='left'
            />,
            align: 'left'
        }
    };

    const chartLoading = [loading, isLoading].some(s => s);

    let placementData = React.useMemo(() => dbData?.map(
        placementEntry => ({
            station: placementEntry.station,
            airing_day_part: placementEntry.airing_day_part,
            program_placement: placementEntry.program_analysis == "1" ? "Yes" : "No",
            media_cleared: forceNumeric(placementEntry.media_cost),
            impressions: forceNumeric(placementEntry.impressions),
            cpm: forceNumeric(placementEntry.cpm),
            spots: forceNumeric(placementEntry.spots),
            impact_index: forceNumeric(placementEntry.impact_index),
            confidence: forceNumeric(placementEntry.confidence),
            significant: placementEntry.significant,
            action: camelToNormal(placementEntry.action),
            price_index: calcPriceIndex(totalCPM, placementEntry.cpm)
        })
    ), [dbData, totalCPM]);

    if (!dbData?.length) {
        placementData = getDummyData(tableSchema, 5).map(dummy => ({...dummy}));
    }

    const defaultValueMessage = adImpactRun.average_cpm ? `Default value: ${adImpactRun.average_cpm?.toFixed(0)}` : '';

    return (
        <div className='placement-table-container'>
            <ChicletLV
                imageFileName='PlacementTable.png'
                chicletImageId='placement-table'
                imageTooltip='Download PlacementTable.png'
                fileTooltip='Download PlacementTable.csv'
                csvFileName='PlacementTable.csv'
                downloadFileData={placementData}
                title='Placements'
            >
                <div style={{fontSize: '12px', color: '#737373', marginBottom: '10px'}}>Pricing Structure</div>
                <R1TextBox sx={{marginBottom: '24px'}}
                           label={`${defaultValueMessage}`}
                           customStyles={{width: '180px'}}
                           value={totalCPM}
                           isThousands={true}
                           onChange={setTotalCPM}
                />
                <TableLV
                    data={placementData}
                    isLoading={chartLoading}
                    dataTest='placement-table'
                    override={override}
                    hasData={dbData?.length}
                />
            </ChicletLV>
        </div>
    );
};

const calcPriceIndex = (total, rowVal) => {
    let returnValue = 'N/A';
    if (forceNumeric(rowVal) > 0) {
        returnValue = forceNumeric(total / rowVal) * 100;
        returnValue = returnValue.toFixed(2);
    }
    return returnValue;
};

const tableSchema = {
    "station": "",
    "airing_day_part": "",
    "media_cost": "",
    "impressions": "",
    "rating": "",
    "spots": "",
    "program_analysis": "",
    "impact_index": "",
    "confidence": "",
    "significant": "",
    "action": "",
    "price_index": ""
};
