import React from 'react';
import {GraphIconOption} from './GraphIconOption';

export const OptionWaterfall = ({children}) => {
  return(
    <GraphIconOption>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="4" y="15" width="3" height="6" rx="1.5" fill="#4F4F4F"/>
        <rect x="10" y="9" width="3" height="6" rx="1.5" fill="#4F4F4F"/>
        <rect x="16" y="3" width="3" height="6" rx="1.5" fill="#4F4F4F"/>
      </svg>
      {children}
    </GraphIconOption>
  );
};
