import React from 'react';
import {
  getProductFeatures,
} from '../../hooks/useProductFeatures';
import { LeftNavigation, NAVIGATION_OPEN_STATE } from './navigation/LeftNavigation';
import { TopNavigation } from './navigation/TopNavigation';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { R1Dialog } from '@rainagency/rain-one-soggy-loaf';
import { HomePage } from './navigation/HomePage';
import { FeatureLV } from '../pages/FeatureLV';
import {
  R1AddButton
} from '@rainagency/rain-one-soggy-loaf';
import { USER_GROUPS } from '../../constants/userGroups';
import ImpersonateUserModal from "../impersonation/ImpersonateUserModal";
export { NAVIGATION_OPEN_STATE };

/**
 * Base application layout
 */
export const ApplicationLayout = ({
  group = [],
  onFeatureSelect = () => {},
  onUserNavigationSelect = () => {},
  isOpen = false,
  isColumn = false,
  loading = false,
  breadcrumbs = [],
  recentActivity,
  email,
  firstName,
  lastName,
  adImpactRuns,
  mmmRuns,
  onSelectAnalysis,
  selectedAdImpactRun,
  selectedMMMRun,
  currentSelectedFeature,
  isRecentActivityLoading,
  isHidden,
  children
}) => {

  const products = getProductFeatures(group);
  const [selectedFeature, setFeature] = React.useState(currentSelectedFeature);

  // Update feature if provided by parent
  React.useEffect(() => {
    if(!currentSelectedFeature) {
      return;
    }
    
    setFeature(currentSelectedFeature);
  }, [currentSelectedFeature]);

  const setSelectedFeature = ({
    feature, partner, client, campaign, query, ...rest
  }) => {
    setFeature(feature, feature?.path);
    onFeatureSelect({
      feature, partner, client, campaign, query, ...rest
    });
  };

  // Display default page for Storybook
  if(!children && selectedFeature && selectedFeature.key) {
    children = (
      <FeatureLV
        title={selectedFeature.ariaLabel}
        description={'Placeholder text for description of this feature'}
        CallToAction={<R1AddButton color='secondary'>Call To Action</R1AddButton>}
      />
    );
  }

  return (
    <ApplicationLayoutStyled
      id='lv-application-layout'
      className='lv-application-layout'>

      <LeftNavigation
        products={products}
        selectedFeature={selectedFeature}
        setSelectedFeature={setSelectedFeature}
        isOpen={isOpen}
        isHidden={isHidden}
      />

      <ApplicationMainStyled className='lv-main'>

        <TopNavigation
          products={products}
          firstName={firstName}
          lastName={lastName}
          email={email}
          selectedFeature={selectedFeature}
          onUserNavigationSelect={onUserNavigationSelect}
          adImpactRuns={adImpactRuns}
          mmmRuns={mmmRuns}
          loading={loading}
          selectedAdImpactRun={selectedAdImpactRun}
          selectedMMMRun={selectedMMMRun}
          onSelectAnalysis={onSelectAnalysis}
          breadcrumbs={breadcrumbs}
        />

        <ApplicationBodyStyled className='application-body'>
          
          <HomePage
            firstName={firstName}
            lastName={lastName}
            selectedFeature={selectedFeature}
            setSelectedFeature={setSelectedFeature}
            recentActivity={recentActivity}
            isRecentActivityLoading={isRecentActivityLoading}
            isColumn={isColumn}
          />

          {children}

          <TrialUserInfoDialog group={group}/>

          <ImpersonateUserModal/>
        </ApplicationBodyStyled>

      </ApplicationMainStyled>


    </ApplicationLayoutStyled>
  );
};

/**
 * Trial Account gets a one-time Dialog box explaining limitations
 */
const TrialUserInfoDialog = ({group = []}) => {

  const [open, setOpen] = React.useState();

  React.useEffect(() => {

    if(group.includes(USER_GROUPS.Company_Trial_Group)) {
      setOpen(true);
      return;
    }

    setOpen(false);

  }, [group]);

  const description = `
          Welcome to Leavened’s MMM Trial Product.  
          You are able to upload your own data with one KPI and two business drivers to run one analysis.  
          You will have access to the full functionality of the product with the exception of scoring.
        `;

  return (
    <R1Dialog
      title='Trial Version'
      description={description}
      cancelLabel={''}
      open={open}
      ActionComponent={null}
    />
  );
};

const ApplicationLayoutStyled = styled('div')(({
  theme,
}) => ({
  display: 'flex',
  height: '100%',
}));

const ApplicationMainStyled = styled('div')(({
  theme,
}) => ({
  display: 'grid',
  flexGrow: 1,
  gridTemplateRows: '84px 1fr',
  marginLeft: '-16px',
  height: '100%',
  width: '100%',
}));

const ApplicationBodyStyled = styled('div')(({
  theme,
}) => ({
  display: 'flex',
  height: 'inherit',
  width: 'inherit',
  overflowY: 'hidden'
}));

ApplicationLayout.propTypes = {
  /**
   * Set Navitation Menu open or closed
   */
  isOpen: PropTypes.bool,
  /**
   * Homepage sections show in columns when true
   */
  isColumn: PropTypes.bool,
  /**
   * Feature from /src/constants/features.js that user currently is located
   */
  currentSelectedFeature: PropTypes.object,
  /**
   * List of cognito groups a user is part of
   */
  group: PropTypes.array,
  /**
   * Handler when user selects a feature
   * Sends selected feature and optional path
   */
  onFeatureSelect: PropTypes.func,
  /**
   * When user selects user navigation item
   */
  onUserNavigationSelect: PropTypes.func,
  /**
   * Email to display in navigation name tab
   */
  email: PropTypes.string,
  /**
   * First Name to display in navigation name tab
   */
  firstName: PropTypes.string,
  /**
   * Last Name to display in navigation name tab
   */
  lastName: PropTypes.string,
  /**
   * List of MMM Runs
   * Will display run selection in top navigation within an MMM feature
   */
  mmmRuns: PropTypes.arrayOf(Object),
  /**
   * List of AdImpact Runs
   * Will display run selection in top navigation within an AdImpact feature
   */
  adImpactRuns: PropTypes.arrayOf(Object),
  /**
   * Selected MMM run to display by default in the drop-down
   */
  selectedMMMRun: PropTypes.object,
  /**
   * Selected AdImpact run to display by default in the drop-down
   */
  selectedAdImpactRun: PropTypes.object,
  /**
   * Function run when a user selects an analysis
   * Returns selected analysis and product it originates from
   */
  onSelectAnalysis: PropTypes.func,
  /**
   * Redirect links to other parts of the application
   * Displays as buttons in the Homepage
   * EXAMPLE:
   * const recentActivity = [
   *   {
   *     title: 'My MMM Run',
   *     path: 'campaign/mca/?mcaId=1234',
   *     client: 'My Client',
   *     campaign: 'My Campaign',
   *     date: '',
   *     timestamp: 12345,
   *     query: {mcaRunId: 567890}
   *   },
   *   {
   *     title: 'My MMM Run2',
   *     path: 'campaign/mca/?mcaId=4567',
   *     client: 'My Client',
   *     campaign: 'My Campaign',
   *     date: '',
   *     timestamp: 12345,
   *     query: {mcaRunId: 567890}
   *   }
   * ];
   *
   */
  recentActivity: PropTypes.array,
  /**
   * If recent activity is in loading state
   */
  isRecentActivityLoading: PropTypes.bool,
  /**
   * Breadcrumb path used in Navigation
   * EXAMPLE:
   * const breadcrumbStub = [
   *   {
   *     label: 'My Client',
   *     color: '',
   *     onClick: () => {console.log('Link Clicked')}
   *   },
   *   {
   *     label: 'My Campaign',
   *     color: '',
   *     onClick: () => {console.log('Link Clicked')}
   *   }
   * ];
   *
   */
  breadcrumbs: PropTypes.array,
  /**
   * Navigation loading status
   */
  loading: PropTypes.bool,
  /**
   * Hide all buttons except Home and Campaigns
   */
  isHidden: PropTypes.bool,
};
