import {useEffect, useState, useRef} from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

import {useGet} from '../../hooks/useRest';
import {useQueryRequest} from '../../hooks/useQueryRequest';

import {urlBuilders} from '../../js/constants';

//Getting run metrics including total count, ranges and variables
export default function useMcaRunMetrics(mcaRun={}) {
    const mcaRunId = mcaRun.id;
    const partition = mcaRun.partition;
    const encoding = mcaRun.encoding;

    /** (1) GET MCA RUN RANGES */
    const ranges = useRef([
        {name: 'rsq', min: 0, max: 1},
        {name: 'pval', min: 0, max: 1}
    ]);

    const rangesPayload = {mcaRunId, partition, encoding, columnNames: ["rsq", "pval"]};
    const rangesQueryUrl = urlBuilders.getMcaRanges(mcaRunId);
    const rangesResultUrl = urlBuilders.getResultRanges('query_uuid');
    const [{result: rangesResult = {data: []}, loading: rangesLoading, error: rangesError}, fetchRanges] = 
        useQueryRequest(rangesQueryUrl, rangesResultUrl, rangesPayload, 'Filter Ranges');
    
    useDeepCompareEffect(() => {
      //fetch only for runs without saved metrics
        if (mcaRun.minRsq && mcaRun.maxRsq && mcaRun.minConfidence && mcaRun.maxConfidence) {
            ranges.current = [
                {name: 'rsq', min: mcaRun.minRsq, max: mcaRun.maxRsq},
                {name: 'pval', min: 1-mcaRun.maxConfidence, max: 1-mcaRun.minConfidence}
            ];
        } else if (mcaRun.id) {
          fetchRanges();
        }
    }, [rangesPayload]);

    useEffect(() => {
        if (rangesResult.data) {
            ranges.current = rangesResult.data;
        }
    }, [rangesResult]);
    
    /** (2) GET MCA RUN TOTAL COUNT */
    const [totalCount, setTotalCount] = useState();
    const totalCountPayload = {mcaRunId, partition, encoding};
    const totalCountQueryUrl = urlBuilders.getMcaResultCount(mcaRunId);
    const totalCountResultUrl = urlBuilders.getResultCount('query_uuid');

    const [{result: totalCountResult = {count: -1}, loading: totalCountLoading, error: totalCountError}, fetchTotalCount] = 
        useQueryRequest(totalCountQueryUrl, totalCountResultUrl, totalCountPayload, 'Total Models Count');
    
    useDeepCompareEffect(() => {
        //fetch only for runs without saved metrics
        if (mcaRun.totalCount) {
            setTotalCount(mcaRun.totalCount);
        } else if (mcaRun.id) {
            fetchTotalCount();
        }
    }, [totalCountPayload, mcaRun.id, fetchTotalCount]);

    useEffect(() => {
        if (totalCountResult.count > -1) setTotalCount(totalCountResult.count);
    }, [totalCountResult.count]);

    /** (3) GET MCA VARIABLES */
  const [{data: mcaVariables=[]}, fetchMcaVariables] = useGet({
    url: urlBuilders.getMcaVariables(mcaRunId),
    manual: true
  });

  useEffect(() => {
    if(!mcaRunId) {
      return;
    }
    fetchMcaVariables();
  }, [mcaRunId, fetchMcaVariables]);

    return {
        totalCount, totalCountLoading, totalCountError,
        ranges: ranges.current, rangesLoading, rangesError,
        mcaVariables
    };
}
