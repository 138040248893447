import React from 'react';

/**
 * If chart data is empty, create dummy chart data to display
 */
export const useValidateChartData = (data, count=10, xKey='x', yKey='y') => {
  const [dummyData, setDummyData] = React.useState();

  // Return data if already populated
  if(Array.isArray(data) && data.length > 0) {
    return data;
  }

  // Return generated data if already set
  if(dummyData) {
    return dummyData;
  }

  // Set dummy data
  const createdDummy = createDataSet(count, xKey, yKey, newSimpleRow);
  setDummyData(createdDummy);
  return createdDummy;
};

/**
 * Create random data rows
 */
function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

/**
 * Dummy chart data schema
 */
const newSimpleRow = (x, y) => {
  let dummyRows = {};
  dummyRows['name'] = 'name' + String(getRandomInt(1000));
  dummyRows[x] = getRandomInt(1000);
  dummyRows[y] = getRandomInt(1000);
  return dummyRows;
};

/**
 * If chart data is empty, return dummy chart data
 */
function createDataSet(count, xKey, yKey, rowCreator) {
  let dataSet = [];
  for(let i=0; i<count; i++) {
    dataSet.push(rowCreator(xKey, yKey));
  }
  return dataSet;
}
