import React from 'react';

import Button from '@mui/material/Button';

import { styles } from './complete-modal.styles.js';


const CompleteModalPresentation = ({icon, title, body, handleClose}) => {        
    return (
        <div className={styles.container}>
            <div className={styles.completeContainer}>
                <img src={icon} alt='checkmark' />
                <div className={styles.completeTitle}>{title}</div>
                <div className={styles.completeOuterBox}>
                    {body}
                </div>
                <Button variant='contained' className={styles.closeButton} onClick={handleClose}> CLOSE </Button>
            </div>
        </div>
    );
};

export default CompleteModalPresentation;
