import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {getStyles} from './DatePickerLV.styles';
import {FormElementWrapper} from '../wrapper/FormElementWrapper';
import {FormUtilLV} from '../util/FormUtilLV';
import MaskedInput from 'react-text-mask';

/**
 * Two DatePickers for a Datepicker Range
 * Reference: https://reactdatepicker.com/#example-date-range
 */
export const DateRangePickerLV = ({
  startLabel, startDescription, endLabel, endDescription,
  register,
  setValue=()=>{},
  defaultStartDate, defaultEndDate,
  customSetStartDate=()=>{},
  customSetEndDate=()=>{},
  isDisabled=false,
  minDate,
  maxDate,
  ...props
}) => {

  const [startDate, setStartDate] = React.useState(parseStringToDate(defaultStartDate));
  const [endDate, setEndDate] = React.useState(parseStringToDate(defaultEndDate));
  const styles = getStyles(props);
  const width = props.width || 315;

  // Register date inputs to form
  if(register){
    register({name: 'startDate', type: 'custom'});
    register({name: 'endDate', type: 'custom'});
  }

  // Update date changes to form after render
  React.useEffect(() => {
    setValue('startDate', startDate);
    setValue('endDate', endDate);

    customSetStartDate(startDate);
    customSetEndDate(endDate);
  },[startDate, endDate, setValue, customSetStartDate, customSetEndDate]);

  // Populate dates if parent has provided default dates
  React.useEffect(() => {
    setStartDate(parseStringToDate(defaultStartDate));
    setEndDate(parseStringToDate(defaultEndDate));
  },[defaultStartDate, defaultEndDate]);

  return (
    <div
      data-test='date-range-picker'
      className={styles.rangeContainer}
      style={FormUtilLV.addWidth(width)}>

      <FormElementWrapper
        data-test='date-range-start-container'
        name='startDate'
        className={styles.startContainer}
        label={startLabel}
        description={startDescription}
        width={0}
        {...props}>

        <div
          className={styles.container}
          data-test='datepicker-container'>
          <DatePicker
            popperPlacement="bottom-end"
            name='startDate'
            selectsStart
            showPopperArrow={true}
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            minDate={minDate}
            maxDate={maxDate}
            onChange={setStartDate}
            placeholderText='mm/dd/yyyy'
            dateFormat='MM/dd/yyyy'
            disabled={isDisabled}
            customInput={
              <MaskedInput
                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
              />
            }
            {...props}
          />

        </div>
      </FormElementWrapper>

      <FormElementWrapper
        name='endDate'
        label={endLabel}
        description={endDescription}
        width={0}
        {...props}>

        <div
          className={styles.container}
          data-test='datepicker-container'>

          <DatePicker
            popperPlacement="bottom-end"
            name='endDate'
            selectsEnd
            showPopperArrow={true}
            selected={endDate}
            startDate={startDate}
            endDate={endDate}
            minDate={minDate}
            maxDate={maxDate}
            onChange={setEndDate}
            placeholderText='mm/dd/yyyy'
            dateFormat='MM/dd/yyyy'
            disabled={isDisabled}
            customInput={
              <MaskedInput
                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
              />
            }
            {...props}
          />

        </div>
      </FormElementWrapper>
    </div>
  );
};

// String date to Date Object EX: 2019-09-30
export const parseStringToDate = dateStr => {
  if(!dateStr) {
    return undefined;
  }

  // Already a Date object
  if(typeof dateStr === 'object') {
    return dateStr;
  }

  // Validate time before sending
  const timeStamp = Date.parse(dateStr);
  if(!(new Date(timeStamp)).getTime() > 0) {
    return undefined;
  }

  return new Date(timeStamp);
};

DateRangePickerLV.propTypes = {
  /**
   * Standard label for the Start Date input
   */
  startLabel: PropTypes.string,
  /**
   * Standard description for the Start Date
   */
  startDescription: PropTypes.string,
  /**
   * Standard label for the End Date input
   */
  endLabel: PropTypes.string,
  /**
   * Standard description for the End Date
   */
  endDescription: PropTypes.string,
  /**
   * String date to populate by default EX: 2019-09-01
   */
  defaultStartDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * String date to populate by default EX: 2019-09-30
   */
  defaultEndDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Disable Edit Functionality
   */
  isDisabled: PropTypes.bool,
  /**
   * Registration to form, and validation of form element
   * https://react-hook-form.com/api#register
   */
  register: PropTypes.func,
  /**
   * Set Values to the React Hook Form
   * https://react-hook-form.com/api/#setValue
   */
  setValue: PropTypes.func,
};
