import React from 'react';
import {connect} from 'react-redux';
import { Auth } from 'aws-amplify';

/**
 * [fetchData wraps a component with the funcionality to kick off an action to
 * fetch data from the backend]
 * @param  {React Component} WrappedComponent [a component to be wrapped by this hoc]
 * @return {React Component}                  [the wrapped component with props passed through]
 */
export default function withHTTP(WrappedComponent) {
    class WithHttpHOC extends React.Component {
        constructor(props) {
            super(props);
            this.httpCall = this.httpCall.bind(this);
        }

        /**
         * given an action type, payload and params will dispatch an action with the base url and token added on
         * can also add -request to the action type for the loading reducer
         * @param  {string}  actionType      the action to be dispatched
         * @param  {Object}  [payload={}]    the object to be put in the calls payload, usually empty for gets
         * @param  {Object}  [params={}]     the object that will be put in as query string paramters
         * @param  {Boolean} [request=false] if true will add -request to the end of the actionType
         * @param  {[type]}  useAltBaseUrl   used for local testing
         */
        httpCall(actionType, payload = {}, params = {}, request = false, useAltBaseUrl) {
            const {config, dispatch} = this.props;
            let baseUrl = config.BASE_URL;
            if (useAltBaseUrl) {
                baseUrl = config.ALT_BASE_URL;
            }
            if (request) {
                actionType = actionType + '-request';
            }
            Auth.currentSession()
                .then(data => {
                    dispatch({
                        type: actionType,
                        baseUrl: baseUrl,
                        token: data.idToken.jwtToken,
                        payload,
                        params
                    });
                })
                .catch(err => console.log(err));

        }
        render() {
            return <WrappedComponent httpCall={this.httpCall} {...this.props}/>;
        }
    }
    WithHttpHOC.displayName = `With HTTP HOC`;
    const mapStateToProps = (state) => {
        return {
            config: state.platform.config
        }
    };

    return connect(mapStateToProps)(WithHttpHOC);
}
