import React from 'react';
import { useMcaRuns } from '../hooks/useMcaRuns';
import { useAdImpactRuns } from '../hooks/useAdImpactRuns';
import { NavigationContext } from '../hooks/NavigationProvider';
import { PRODUCT } from '../constants/features';
import {
  getProductByFeaturePath
} from '../hooks/useProductFeatures';
import { useLocation } from 'react-router-dom';

/**
 * Get MMM and AdImpact runs for the Navigation
 * Update runs on changing page navigation
 */
export const useRuns = () => {
  const location = useLocation() || {};
  const currentProduct = getProductByFeaturePath(location.pathname);
  const currentProductKey = currentProduct?.key;

  const {
    navigationCounter
  } = React.useContext(NavigationContext);

  const {
    mcaRuns,
    selectedMcaRun,
    loading: mcaLoading,
    getMcaRuns = () => { }
  } = useMcaRuns() || {};

  const {
    adImpactRuns,
    adImpactRun,
    loading: adImpactLoading,
    fetchRuns: fetchAdImpact = () => { }
  } = useAdImpactRuns() || {};

  // Forces refetch of runs if navigation counter is incremented
  React.useEffect(() => {
    if(!navigationCounter) {
      return;
    }

    fetchAdImpact();
    getMcaRuns();

  }, [navigationCounter]);

  // Reload MMM Runs
  React.useEffect(() => {
    if(currentProductKey === PRODUCT.MMM.key) {
      getMcaRuns();
    }
  }, [currentProductKey, location]);

  // Reload AdImpact Runs
  React.useEffect(() => {
    if(currentProductKey === PRODUCT.ADIMPACT.key) {
      fetchAdImpact();
    }
  }, [currentProductKey, location]);

  return {
    mcaRuns,
    selectedMcaRun,
    mcaLoading,
    adImpactRuns,
    adImpactRun,
    adImpactLoading,
    getMcaRuns,
    fetchAdImpact
  };
};
