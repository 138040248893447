import React from 'react';
import {ReferenceLine} from 'recharts';
import {
  eachWeekOfInterval,
  eachMonthOfInterval,
  eachQuarterOfInterval,
} from 'date-fns';
import moment from 'moment';
import {getMinMaxDates} from '../js/utils';
import {DATE_SPAN} from '../constants/dates';

/**
 * Return list of first date on week/month/or quarter
 * https://date-fns.org/v2.19.0/docs/startOfWeek
 *
 * @param data Object array with date property
 * @param selectedDateSpan date span to show EX(QUARTERLY, MONTHLY)
 * @param dateColumnKey Name of the date property
 * @param isBroadcastDates Disable usage is this is true
 * @param isLoading Disable any calc if loading is occuring
 * @returns weeks List of broadcast weeks in timespan
 * @returns months List of broadcast months in timespan
 * @returns quarterly List of broadcast quarters in timespan
 * @returns selectedBroadcastDates Selected list of dates
 */
export const useBroadcastDates = ({
  data=[],
  selectedDateSpan=DATE_SPAN.QUARTERLY,
  dateColumnKey='date',
  isLoading=false,
  isBroadcastDates=false
}) => {
  const {allDates, weeks, months, quarters} = React.useMemo(() => {

    if(isLoading || !isBroadcastDates || !Array.isArray(data) || !data.length) {
      return {
        getBroadcastDates: () => {}
      };
    }

    const {start, end} = getMinMaxDates(data, dateColumnKey);
    if(!start || !end) {
      return {
        getBroadcastDates: () => {}
      };
    }

    return {
      allDates: data.map(row => row.date).map(dateToTimestamp),
      weeks: eachWeekOfInterval({start, end}, {weekStartsOn: 1}).map(dateToTimestamp),
      months: eachMonthOfInterval({start, end}).map(dateToTimestamp),
      quarters: eachQuarterOfInterval({start, end}).map(dateToTimestamp)
    };
  }, [data, dateColumnKey, isLoading, isBroadcastDates]);

  const spanMap = {
    [DATE_SPAN.WEEKLY]: weeks,
    [DATE_SPAN.MONTHLY]: months,
    [DATE_SPAN.QUARTERLY]: quarters,
    [DATE_SPAN.NONE]: allDates
  };

  const selectedBroadcastDates = spanMap[selectedDateSpan] || [];
  const min = Math.min(selectedBroadcastDates);
  const max = Math.max(selectedBroadcastDates);

  // Add timestamps to dataset
  const dataWithTimestamps = React.useMemo(() => {
    return data.map(({...row}) => ({
      ...row,
      timestamp: dateToTimestamp(row[dateColumnKey])
    }));
  }, [data, dateColumnKey]);

  return {
    dataWithTimestamps,
    weeks,
    months,
    quarters,
    selectedBroadcastDates: spanMap[selectedDateSpan],
    min,
    max
  };
};

const dateToTimestamp = (date) => {
  return moment(date).format('X');
};

/**
 * Returns an array of Rechart Lines for Broadcast Dates
 * @param dateArr Array of Date() object
 * @param isDisplay Boolean to show or hide
 * @returns List of Recharts <ReferenceLine/>
 */
export const useBroadcastRechartsRefLines = ({dates=[], isDisplay=false}) => {
  return React.useMemo(() => {

    if(!isDisplay || !Array.isArray(dates) || !dates.length) {
      return null;
    }

    return dates.map((d, idx) => {
      return (
        <ReferenceLine
          key={`bcast-${idx}`}
          className='broadcast-reference-lv'
          x={d}
          stroke='#254B7D'
          inFront={true}
        />);
    });
  }, [dates, isDisplay]);
};
