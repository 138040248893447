import React from 'react';
import {styles} from './FormRowLV.styles';
import PropTypes from 'prop-types';

export const DEFAULT_WIDTH = 24;
export const DEFAULT_MARGIN = 24;

/**
 * Auto-fits child elements into a single row
 * @param children Child elements
 * @param width space between elements
 * @param margin top/bottom margin between elements
 * @param hidden Remove element
 * @param center Bool center all items
 * @param className Extended css class name
 * @param style inline style extended
 * @param flexChildren array of flex values for custom widths of children in row
 * @param expand Set width of children to 100% to expand to entire row
 * @return list of wrapped child elements with margin-right
 */
export const FormRowLV = ({children, width, margin, hidden, center, className, style={}, flexChildren=[], expand}) => {
  if(hidden) {
    return null;
  }
  
  const wrappedChildren = wrapChildren(children, width, margin, flexChildren, expand);
  const customStyle = {...style};

  if(center) {
    customStyle.justifyContent = 'center';
  }

  if(expand) {
    customStyle.width = 'inherit';
  }

  const classNames = [className, styles.parent].join(' ');
  return (
    <div className={classNames} style={customStyle}>
      {wrappedChildren}
    </div>
  );
};

/**
 * Add a right margin to children except for last one
 * @param children Child elements
 * @param width space between elements
 * @param margin top/bottom margin between elements
 * @param expand Set width of children to 100%
 * @return list of wrapped child elements with margin-right
 */
const wrapChildren = (children, width=DEFAULT_WIDTH, margin=DEFAULT_MARGIN, flexChildren, expand) => {
  if(!Array.isArray(children)) {
    children = [children];
  }
  if(flexChildren.length > 0 && flexChildren.length !== children.length){
    flexChildren = children.map((child, index) => {
      if(flexChildren[index]){
        return flexChildren[index];
      }
      else return 1;
    })
  }
  const lastIndex = children.length - 1;
  
  return children.map((child, index) => {
    const currentWidth = (index < lastIndex) ? width : 0;
    return (
      <div
        key={index}
        data-test={`form-element-wrap${index}`}
        style={
          {
            margin: `${margin}px ${currentWidth}px ${margin}px 0px`,
            width: (expand) ? '100%' : 'auto',
            flex: `${flexChildren[index]}`
          }
        }>
        {child}
      </div>
    );
  });
};

FormRowLV.propTypes = {
  /**
   * Width between elements
   */
  width: PropTypes.number,
  /**
   * Top/Bottom margin around element
   */
  margin: PropTypes.number
};
