import React from 'react';
import {ScoringContributionDashboard} from "./ScoringContributionDashboard";
import {ScoringDashboardContext} from "../ScoringDashboardContext";
import {ScoringFitChart} from "./ScoringFitChart";

export const ScoredReport = () => {

    const {
        isCombined
    } = React.useContext(ScoringDashboardContext);

    const CombinedDashboard = React.useMemo(() => {
        return () => {
            if (!isCombined) {
                return null;
            }
            return (
                <div >
                    <ScoringContributionDashboard isCombined={true} />
                </div>
            );
        }
    }, [isCombined]);

    return (
        <div>
            <CombinedDashboard />

            <ScoringContributionDashboard />

            <ScoringFitChart />
        </div>
    );
};
