import React from 'react';
import Chip from '@mui/material/Chip';

import {styles} from './chip.styles.js';

/**
 * [FilterChip takes two parameteres for displaying text and selecting style 
 * and returns spinner while action is in progress]
 * @param {bool} isError will show the style for error values
 * @param {string} label text displayed in chip
 */
export default class FilterChip extends React.Component {
  
    render() {
      const style = this.props.isError ? styles.errorChip : styles.goodChip;
      return <Chip variant={this.props.variant} color='primary' className={style} label={this.props.label} onDelete={this.props.onDelete}/>
    }    
}
