import {css} from 'emotion';

export const styles = {
    filterChip: css`
        padding-right: 8px;
    `,
    chipContainer: css`
        margin-right: 240px;
    `
};
