import React, {useState, useRef, useEffect, useMemo} from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

import {useForm} from 'react-hook-form';

import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';

import _ from 'lodash';

import {TitleLV} from '../../../../components/form/title/TitleLV';
import {FormRowLV} from '../../../../components/form/row/FormRowLV';
import {CheckboxLV} from '../../../../components/form/CheckboxLV';
import {SelectChipsLV} from '../../../../components/form/select/SelectChipsLV';
import {TextInputLV} from '../../../../components/form/text/TextInputLV';
import {OrangeButtonLV} from '../../../../components/form/buttons/OrangeButtonLV';
import {GreyButtonLV} from '../../../../components/form/buttons/GreyButtonLV';
import MultipleSelectLV from '../../../../components/form/select/MultipleSelectLV';

import {bigIntLimit, urlBuilders, memoryDecays, saturations} from '../../../../js/constants'; 
import {useGet} from '../../../../hooks/useRest';

import {filterRange} from '../../../../js/validation';
import downloadIcon from '../../../../assets/download_csv_16px.svg';

import {MAX_MODEL_DOWNLOAD, MAX_MODEL_ANALYSIS} from '../../../../js/constants';
import Loading from '../../../../components/loading';

import {styles} from './model-filter-form.styles';

export default function ModelFilterForm(props) {
    const { handleSubmit, register, control, getValues, setValue, reset, errors } = useForm();

    const {
        mcaRun={},
        ranges,
        loading, 
        count, 
        total, 
        modelLimit, 
        filters, 
        mcaVariables, 
        narrowResults,
        viewModels,
        isDownloading,
        downloadModelAnalysis,
        isDownloadingModelAnalysis
    } = props;

    const includeIntercept = mcaRun && mcaRun['includeIntercept'] !== null ?  
        mcaRun['includeIntercept'] : true; //account for old runs before includeIntercept field
    const isModelAnalysisMaxed = !count || count > MAX_MODEL_ANALYSIS;

    const [{data: mediaGrouping=[], loading: mediaGroupingLoading}, fetchMediaGrouping] = useGet({
        url: urlBuilders.getMediaGroupingConfigs(null, mcaRun.id),
        manual: true
    });

    useEffect(() => {
        if (mcaRun.id) {
            fetchMediaGrouping();
        }
    }, [mcaRun.id]);

    const groupConfigs = useMemo(() => {
        let mediaGroups = mediaGrouping.filter((media) => media.Analyze === "yes");
        mediaGroups = mediaGroups.map(media => (
            {...media, 
                MemoryDecay: media.MemoryDecay.split('/'),
                Saturation: media.Saturation.split('/')
            }
        ));
        const groups = mediaGroups.map(media => {
            const groupVariables = mediaGroups.filter(group => group.VariableGroup === media.VariableGroup);
            const variableGroup = {
                    groupName: media.VariableGroup,
                    variableNames: groupVariables.map(variable => variable.VariableName),
                    minContributions: null,
                    maxContributions: null,
                    decays: [... new Set(groupVariables.map(variable => variable.MemoryDecay).flat().sort())],
                    saturations: [... new Set(groupVariables.map(variable => variable.Saturation).flat())]
            }
            //Must change 'no' to upper case to be selected as a value along with others
            let noIndex = variableGroup.decays.indexOf('no');
            if (~noIndex) {
                variableGroup.decays[noIndex] = 'NO';
            }
            
            noIndex = variableGroup.saturations.indexOf('no');
            if (~noIndex) {
                variableGroup.saturations[noIndex] = 'NO';
            }
            
            return variableGroup;
        });

        return _.uniqBy(groups, 'groupName');
    }, [mediaGrouping]);

    const mcaVariablesNames =mcaVariables.map(variable => variable.name);
    const uniqueVariableNames = [...new Set(mcaVariablesNames)];
    const drivers = ['confidenceExceptions', 'negativeDrivers', 'positiveDrivers', 'mandatoryVariables', 'excludeVariables',
        'mandatoryModels'];

    const defaultFilters = {
        intercept: includeIntercept,
        confidence: null,
        confidenceExceptions: [],
        rsq: null,
        minDurbinWatson: null,
        maxDurbinWatson: null,
        negativeDrivers: [],
        positiveDrivers: [],
        mandatoryVariables: [],
        mandatoryModels: [],
        excludeVariables: [],
        variableGroups: []
    };

    //retains values after render
    let currentFilters = Object.assign({}, defaultFilters, filters);
    const confidenceExceptionsCheckbox = currentFilters['confidence'] && currentFilters['confidenceExceptions'].length > 0;
    const negativeDriversCheckbox = currentFilters['negativeDrivers'].length > 0;
    const positiveDriversCheckbox = currentFilters['positiveDrivers'].length > 0;
    const mandatoryVariablesCheckbox = currentFilters['mandatoryVariables'].length > 0;
    const excludeVariablesCheckbox = currentFilters['excludeVariables'].length > 0;
    const mandatoryModelsCheckbox = currentFilters['mandatoryModels'].length > 0;
    const customVariableGroupsCheckbox = currentFilters['variableGroups'].length > 0;

    const checkboxes = {
        confidenceExceptionsCheckbox: !confidenceExceptionsCheckbox ? false : confidenceExceptionsCheckbox,
        negativeDriversCheckbox,
        positiveDriversCheckbox,
        mandatoryVariablesCheckbox,
        excludeVariablesCheckbox,
        mandatoryModelsCheckbox,
        customVariableGroupsCheckbox
    };
    const [tempCheckboxes, setTempCheckboxes] = useState(checkboxes);
    const updatedCheckboxes = useRef(checkboxes);

    const [tempFilters, setTempFilters] = useState(currentFilters);
    const updatedFilters = useRef(currentFilters);
    useDeepCompareEffect(() => {
        if(!mcaRun){
            return;
        }
        setTempFilters(currentFilters);
        updatedFilters.current = tempFilters;
    }, [mcaRun, currentFilters]);

    const [disableNarrowResults, setDisableNarrowResults] = useState(true);
    const [disableViewModels, setDisableViewModels] = useState(false);
    const [submit, setSubmit] = useState(false);

    const [mandatoryModelsField, setMandatoryModelsField] = useState('');
    let mandatoryModels = tempFilters.mandatoryModels || [];
    
    const validateConfidence = (value) => {
        const confidence = Number(value.toString().replace('%', ''));
        let err = '';
        if  (isNaN(confidence)) {
            err = `Must be a number. `;
        }
        if (confidence && !Number.isInteger(confidence)) {
            err = `Must be a whole number without decimals. `;
        }
        if (confidence < filterRange.minConfidence) {
            err = err + `Must be at least ${filterRange.minConfidence}. `;
        }
        if (confidence > filterRange.maxConfidence) {
            err = err + `Must be less than or equal to ${filterRange.maxConfidence}. `;
        }
        return !err.length || err;
    }

    const validateRsq = (value) => {
        const rsq = Number(value.toString().replace('%', ''));
        let err = '';
        if  (isNaN(rsq)) {
            err = `Must be a number. `;
        }
        if (rsq !== Number(rsq.toFixed(2))) {
            err = `Cannot contain more than 2 decimals. `;
        }
        if (rsq < filterRange.minRsq) {
            err = err + `Must be at least ${filterRange.minRsq}. `;
        }
        if (rsq > filterRange.maxRsq) {
            err = err + `Must be less than or equal to ${filterRange.maxRsq}. `;
        }
        return !err.length || err;
    }

    const validateMinDurbinWatson = (value) => {
        const minDurbinWatson = Number(value);
        const maxDurbinWatson = Number(tempFilters.maxDurbinWatson) || filterRange.maxDurbinWatson;
        let err = '';
        if  (isNaN(minDurbinWatson)) {
            err = `Must be a number. `;
        }
        if (minDurbinWatson) {
            if (minDurbinWatson < filterRange.minDurbinWatson) {
                err = `Must be at least ${filterRange.minDurbinWatson}.`;
            }
            if ((minDurbinWatson > maxDurbinWatson) && minDurbinWatson > filterRange.minDurbinWatson) {
                err = `Min must be less than or equal to the max. `;
            }
            if (minDurbinWatson > filterRange.maxDurbinWatson) {
                err = `Must be less than or equal to ${filterRange.maxDurbinWatson}.`;
            }
            if (minDurbinWatson !== Number(minDurbinWatson.toFixed(2))) {
                err = err + `Cannot contain more than 2 decimals. `;
            }
        }
        return !err.length || err;
    }

    const validateMaxDurbinWatson = (value) => {
        const minDurbinWatson = Number(tempFilters.minDurbinWatson) || filterRange.minDurbinWatson;
        const maxDurbinWatson = Number(value);
        let err = '';
        if  (isNaN(maxDurbinWatson)) {
            err = `Must be a number. `;
        }
        if (maxDurbinWatson) {
            if (maxDurbinWatson < filterRange.minDurbinWatson) {
                err = `Must be at least ${filterRange.minDurbinWatson}. `;
            }
            if (maxDurbinWatson < minDurbinWatson && maxDurbinWatson < filterRange.maxDurbinWatson) {
                err = `Max must be greater than or equal to the min. `;
            }
            if (maxDurbinWatson > filterRange.maxDurbinWatson) {
                err = `Must be less than or equal to ${filterRange.maxDurbinWatson}. `;
            }
            if (maxDurbinWatson !== Number(maxDurbinWatson.toFixed(2))) {
                err = err + `Cannot contain more than 2 decimals. `;
            }  
        }
        return !err.length || err;
    }
    
    const validateMinContributions = (value, maxContributions) => {
        const minContributions = Number(value);
        let err = '';
        if  (isNaN(minContributions)) {
            err = `Must be a number. `;
        } else if (minContributions) {
            if (minContributions !== Number(minContributions.toFixed(1))) {
                err = `Cannot contain more than 1 decimal. `;
            }
            if (minContributions < filterRange.minContributions) {
                err = `Must be at least ${filterRange.minContributions}.`;
            }
            if (maxContributions && minContributions > Number(maxContributions)) {
                err = `Min must be less than or equal to the max. `;
            }
            if (minContributions > filterRange.maxContributions) {
                err = `Must be less than or equal to ${filterRange.maxContributions}.`;
            }
        }
        return !err.length || err;
    }

    const validateMaxContributions = (value, minContributions) => {
        const maxContributions = Number(value);
        let err = '';
        if  (isNaN(maxContributions)) {
            err = `Must be a number. `;
        } else if (maxContributions) {
            if (maxContributions !== Number(maxContributions.toFixed(1))) {
                err = `Cannot contain more than 1 decimal. `;
            }
            if (maxContributions < filterRange.minContributions) {
                err = `Must be at least ${filterRange.minContributions}. `;
            }
            if (minContributions && maxContributions < Number(minContributions)) {
                err = `Max must be greater than or equal to the min. `;
            }
            if (maxContributions > filterRange.maxContributions) {
                err = `Must be less than or equal to ${filterRange.maxContributions}. `;
            }
        }
        return !err.length || err;
    }

    const validateMandatoryModels = (value) => {
        const mandatoryModelsString = value ? value.trim() : '';
        let err = '';

        if (tempCheckboxes['mandatoryModelsCheckbox'] && !mandatoryModels.length && !mandatoryModelsString.length) {
            err ='Enter a valid model number and separate models with a comma.'
        }

        if (mandatoryModelsString.length) {
            // split by commas and trim spaces
            const currentModelIds = mandatoryModelsString.split(',').map(modelId => modelId.trim());
            // check for invalid characters
            currentModelIds.forEach(modelId => (/[^0-9]/.test(modelId)) || modelId > bigIntLimit || !modelId ?
                err = 'Enter a valid model number and separate models with a comma.' : null);
            if (!err.length) {
                //add the current to the previous model ids
                let tempMandatoryModels = mandatoryModels.concat(currentModelIds);
                //remove duplicates and check limit
                tempMandatoryModels = tempMandatoryModels.filter((modelId, index, self) => self.indexOf(modelId) === index && modelId !== 0);
                if (tempMandatoryModels.length > modelLimit) {
                    err = 'Number of selected models exceeds limit of ' + modelLimit;
                } else {
                    mandatoryModels = tempMandatoryModels.slice();
                }
            }
        }
        return !err.length || err;
    }

    const addMandatoryModels = () => {
        const formFilters = Object.assign({}, tempFilters, {mandatoryModels});
        delete formFilters.mandatoryModelsField;
        setTempFilters(formFilters);
        setMandatoryModelsField('');
        
    }

    const deleteModel = (modelId) => {
        const newMandatoryModels = tempFilters.mandatoryModels.filter(item => item !== modelId);
        mandatoryModels = newMandatoryModels.slice();
        setTempFilters(Object.assign({}, tempFilters, {mandatoryModels}));
    }

    useEffect(() => {
        if (submit) {
            //remove values for unchecked boxes
            const formFilters = Object.assign({}, tempFilters);
            drivers.forEach(name => {
                if (!tempCheckboxes[name + 'Checkbox']) {
                    delete formFilters[name];
                }
            });
            //remove values for null fields
            Object.entries(formFilters).forEach(field => {
                const name = field[0];
                const value = field[1];
                if (value === null) {
                    delete formFilters[name];
                }
            })
            narrowResults(formFilters);
            updatedCheckboxes.current = Object.assign({}, tempCheckboxes);
            updatedFilters.current = Object.assign({}, tempFilters);
            setSubmit(false);
        }
    }, [submit, narrowResults, tempFilters, tempCheckboxes, drivers]);

    const formSubmit = () => {
        addMandatoryModels();
        setSubmit(true);
    }

    const onBlur = event => {
        if (event.stopPropagation) { 
            event.stopPropagation();
        }

        const name = event.target.name;
        const value = Math.round(Number(event.target.value.toString().replace('%', ''))*100)/100;
        if (name === 'confidence') {
            const confidence = Math.round(value) === 0 ? '' : value.toFixed(0) + '%';
            setTempFilters({...tempFilters, confidence});
            setValue('confidence', confidence);
        }
        if (name === 'rsq') {
            const rsq = value === 0 ? '' : value.toFixed(2) + '%';
            setTempFilters({...tempFilters, rsq});
            setValue('rsq', rsq);
        }
    }

    const onChange = (event, groupName) => {
        if (event.stopPropagation) { 
            event.stopPropagation();
        }
        let name = event.target.name;
        let value = event.target.value;

        //ignore saturation and decay checkbox updates until menu is closed
        if (memoryDecays.includes(name) || saturations.includes(name) || name === 'NO') {
            return;
        }

        if (drivers.includes(name)) { 
            setTempCheckboxes(Object.assign({}, tempCheckboxes, {[name + 'Checkbox']: event.target.checked}));
            return;
        }

        if (name === 'mandatoryModelsField') { 
            setMandatoryModelsField(value);
        }

        if (name === 'intercept') {
            value = event.target.checked;
        }

        let newFilter = {[name]: value};
        if (name === 'customVariableGroups') {
            setTempCheckboxes({...tempCheckboxes, customVariableGroupsCheckbox: event.target.checked});
            if (!event.target.checked) {
                newFilter = {variableGroups: []};
            } else {
                return;
            }
        }

        if (groupName) {
            if (name === 'decays' || name === 'saturations') {
                value = value.split('/');
                value = !value.length || value[0] === '' ? null : value;
                value = value && value[0] === 'All' ? [] : value;
            }
            let variableGroups = tempFilters.variableGroups.slice();
            let variableGroupIndex = variableGroups.findIndex(group => group.groupName === groupName);
            if (variableGroupIndex === -1) {
                variableGroupIndex = variableGroups.length;
            }
            
            let variableGroup = variableGroups[variableGroupIndex] || 
                {...groupConfigs.find(group => group.groupName === groupName), decays: null, saturations: null};

            
            if (name.endsWith('_' + groupName)) {
                name = name.replace('_' + groupName, '');
                value = value ? parseFloat(value).toFixed(1) : null;
            }
            variableGroup = {...variableGroup, [name]: value};
            variableGroups[variableGroupIndex] = variableGroup;
            variableGroups = variableGroups.filter(group => 
                group.decays || group.saturations || group.minContributions || group.maxContributions
            );

            newFilter = {variableGroups};
        }

        //Setup New values
        const newValues = Object.assign({}, tempFilters, newFilter);

        //check if filters have changed to enable view models
        setTempFilters(newValues);
    }

    const updateSelectedDrivers = (name, value) => {
        if (drivers.includes(name)) {
            setTempFilters(Object.assign({}, tempFilters, {[name]: value}));  
        }
    }

    const resetField = (name) => {
        const formValues = getValues();
        reset(Object.assign({}, formValues, {[name]: null}));
    }

    // filter fields
    const confidenceExceptions = () => {
        return <SelectChipsLV
            name='confidenceExceptions' 
            label='Confidence Filter Exceptions'
            disabled={(!tempFilters.confidence && tempFilters.confience !== 0) || loading}
            checked={tempCheckboxes.confidenceExceptionsCheckbox}
            placeholder='Select Drivers'
            options={uniqueVariableNames}
            values={tempFilters.confidenceExceptions}
            control={control}
            reset={() => resetField('confidenceExceptions')}
            rules={{required: tempCheckboxes['confidenceExceptionsCheckbox'] && !tempFilters.confidenceExceptions.length}}
            errors={errors}
            updateValues={value => updateSelectedDrivers('confidenceExceptions', value)}
            dataTest={'confidence-filter-exceptions-checkbox'}
        />
    }

    const negativeDrivers = () => {
        return <SelectChipsLV
            name='negativeDrivers' 
            label='Define Negative Drivers'
            disabled={loading}
            checked={tempCheckboxes.negativeDriversCheckbox}
            placeholder='Select Drivers'
            options={uniqueVariableNames}
            values={tempFilters.negativeDrivers}
            control={control}
            reset={() => resetField('negativeDrivers')}
            rules={{required: tempCheckboxes['negativeDriversCheckbox'] && !tempFilters.negativeDrivers.length}}  
            errors={errors}
            updateValues={value => updateSelectedDrivers('negativeDrivers', value)}
            dataTest={'negative-drivers-checkbox'}
        />
    }

    const positiveDrivers = () => {
        return <SelectChipsLV
            name='positiveDrivers' 
            label='Define Positive Drivers'
            disabled={loading}
            checked={tempCheckboxes.positiveDriversCheckbox}
            placeholder='Select Drivers'
            options={uniqueVariableNames}
            values={tempFilters.positiveDrivers}
            control={control}
            reset={() => resetField('positiveDrivers')}
            rules={{required: tempCheckboxes['positiveDriversCheckbox'] && !tempFilters.positiveDrivers.length}}
            errors={errors}
            updateValues={value => updateSelectedDrivers('positiveDrivers', value)}
            dataTest={'positive-drivers-checkbox'}
        />
    }

    const mandatoryVariables = () => {
        return <SelectChipsLV
            name='mandatoryVariables' 
            label='Define Mandatory Variables'
            disabled={loading}
            checked={tempCheckboxes.mandatoryVariablesCheckbox}
            placeholder='Select Variable'
            options={uniqueVariableNames}
            values={tempFilters.mandatoryVariables}
            control={control}
            reset={() => resetField('mandatoryVariables')}
            rules={{required: tempCheckboxes['mandatoryVariablesCheckbox'] && !tempFilters.mandatoryVariables.length}}
            errors={errors}
            updateValues={value => updateSelectedDrivers('mandatoryVariables', value)}
            dataTest={'mandatory-variables-checkbox'}
        />
    }

    const excludeVariables = () => {
        return <SelectChipsLV
            name='excludeVariables' 
            label='Define Excluded Variables'
            disabled={loading}
            checked={tempCheckboxes.excludeVariablesCheckbox}
            placeholder='Select Variable'
            options={uniqueVariableNames}
            values={tempFilters.excludeVariables}
            control={control}
            reset={() => resetField('excludeVariables')}
            rules={{required: tempCheckboxes['excludeVariablesCheckbox'] && !tempFilters.excludeVariables.length}}
            errors={errors}
            updateValues={value => updateSelectedDrivers('excludeVariables', value)}
            dataTest={'exclude-variables-checkbox'}
        />
    }
    
    const mandatoryModelIds = () => {
        const mandatoryModelsFormField = tempCheckboxes.mandatoryModelsCheckbox ?
            <div className={styles.innerField}>
                <TextInputLV 
                    name='mandatoryModelsField' 
                    placeholder='Model #' 
                    description='Separate With Commas' 
                    width={180}
                    value={mandatoryModelsField}
                    onChange={()=>setMandatoryModelsField(null)}
                    control={control}
                    className={styles.formField}  
                    dataTest='mandatoryModels-filter-form' 
                    register={register({
                        validate: value => validateMandatoryModels(value)
                    })}
                    errors={errors}
                />
            </div> : null;
        const mandatoryModelsChips = tempCheckboxes.mandatoryModelsCheckbox && tempFilters.mandatoryModels? 
            tempFilters.mandatoryModels.map((modelId, index) =>
                <Chip className={styles.modelsChip} 
                    disabled={loading}
                    key={index} 
                    label={modelId} 
                    onDelete={() => (deleteModel(modelId))} 
                />
            ) : null;
        return (
            <div>
                 <CheckboxLV 
                    name={'mandatoryModels'} 
                    disabled={loading}
                    label={'Define Mandatory Models'} 
                    checked={tempCheckboxes.mandatoryModelsCheckbox}
                    dataTest='mandatory-models-checkbox' 
                    darkTheme={true}
                />
                <div className={styles.fieldBox} data-test='mandatoryModelsTextField-modelFilterForm'>
                    {mandatoryModelsFormField}
                    <div className={styles.modelChipContainer} 
                        data-test='mandatoryModelsChips-modelFilterForm'>
                            {mandatoryModelsChips}
                    </div>
                </div>
            </div>
        );
    }

    const customVariableGroups = () => {
        const groupOptions = groupConfigs.map((group, index) => {
            //restore values when updating filters
            const filterGroup = tempFilters['variableGroups'].find(item => item.groupName === group.groupName) || {};
            const decayValues = filterGroup.decays || [];
            const saturationValues = filterGroup.saturations || [];
            const isGroupChecked = tempCheckboxes.customVariableGroupsCheckbox;
            return (
                <div 
                    key={index}
                    className={styles.groupStyle}
                    disabled={loading}>
                    <FormRowLV margin={1}>
                        <Tooltip title={group.groupName}>
                            <div className={styles.groupTitle}>{group.groupName}</div>
                        </Tooltip>  
                        <div className={styles.paddedError}>
                            <MultipleSelectLV 
                                name='decays' 
                                value={decayValues}
                                allowNoSelection={true}
                                placeholder='Decays'
                                className={styles.selectStyle} 
                                items={group.decays} 
                                onChange={(event) => onChange(event, group.groupName)}
                                disabled={!isGroupChecked} 
                                data-test='memoryDecay-Field'
                            />
                        </div>
                        <MultipleSelectLV 
                                name='saturations' 
                                value={saturationValues}
                                allowNoSelection={true}
                                placeholder='Saturations'
                                className={styles.selectStyle} 
                                items={group.saturations} 
                                onChange={(event) => onChange(event, group.groupName)}
                                disabled={!isGroupChecked} 
                                data-test='saturation-Field'
                            />
                        <div className={styles.paddedError}>
                            <TextInputLV 
                                name={'minContributions_' + group.groupName} 
                                placeholder='Min Contributions' 
                                description='≤ 100.0%'
                                className={`${styles.formField} ${styles.inputField}`} 
                                width={100}
                                defaultValue={filterGroup.minContributions}
                                onChange={(event) => onChange(event, group.groupName)}
                                register={register({
                                    validate: value => validateMinContributions(value, filterGroup.maxContributions)
                                })}
                                errors={errors}
                                disabled={!isGroupChecked}
                                data-test='minContributions-model-filter-form' 
                            />
                        </div>
                        <div className={styles.paddedError}>
                            <TextInputLV 
                                name={'maxContributions_' + group.groupName} 
                                placeholder='Max Contributions' 
                                description='≤ 100.0%' 
                                className={`${styles.formField} ${styles.inputField}`} 
                                width={100}
                                defaultValue={filterGroup.maxContributions}
                                onChange={(event) => onChange(event, group.groupName)}
                                register={register({
                                    validate: value => validateMaxContributions(value, filterGroup.minContributions)
                                })}
                                errors={errors}
                                disabled={!isGroupChecked}
                                data-test='maxContributions-model-filter-form'
                            />
                        </div>
                    </FormRowLV>
                </div>
            )
        });

        const variableGroupsFormField = !mediaGroupingLoading && tempCheckboxes.customVariableGroupsCheckbox ?
            <div className={styles.innerField}>
                {groupOptions}
            </div> : null;
        
        return (
            <div>
                 <CheckboxLV 
                    name='customVariableGroups'
                    disabled={loading}
                    label='Define Variable Group Parameters'
                    checked={tempCheckboxes.customVariableGroupsCheckbox}
                    dataTest='custom-variable-groups-checkbox' 
                    darkTheme={true}
                />
                <div className={styles.fieldBox} data-test='custom-variable-Form'>
                    {variableGroupsFormField}
                </div>
            </div>
        );
    }

    const rsqRange = (mcaRun) => {
        let result = '';
        const rsq = ranges.find(range => range.name === 'rsq');
        let min, max;

        if (mcaRun && mcaRun.minRsq && mcaRun.maxRsq) {
            const min = (mcaRun.minRsq * 100).toFixed(2) + '%' || '0.00%';
            const max = (mcaRun.maxRsq * 100).toFixed(2) + '%' || '100.00%';
            result = '  (Data Range: ~ ' + min + ' - ' + max + ')';
        } else if (rsq && rsq.min && rsq.max) {
            min = (rsq.min * 100).toFixed(2) + '%' || '0.00%';
            max = (rsq.max * 100).toFixed(2) + '%' || '100.00%';
            result = '  (Data Range: ~ ' + min + ' - ' + max + ')';
        }
        return result;
    }

    const confidenceRange = (mcaRun) => {
        let result = '';
        const pval = ranges.find(range => range.name === 'pval');
        let min, max;

        if (mcaRun && mcaRun.minConfidence && mcaRun.maxConfidence) {
            min = (mcaRun.minConfidence * 100).toFixed(0) + '%' || '0%';
            max = (mcaRun.maxConfidence * 100).toFixed(0) + '%' || '100%';
            result = '  (Data Range: ~ ' + min + ' - ' + max + ')';
        } else if (pval && pval.min >= 0 && pval.max >= 0) {
            min = ((1 - pval.max) * 100).toFixed(0) + '%' || '0%';
            max = ((1 - pval.min) * 100).toFixed(0) + '%' || '100%';
            result = '  (Data Range: ~ ' + min + ' - ' + max + ')';
        }

        return result;
    }

    const rsqHint = rsqRange(mcaRun);
    const confidenceHint = confidenceRange(mcaRun);  
    useDeepCompareEffect(() => {
        let parsedUpdatedFilters = Object.assign({}, defaultFilters, updatedFilters.current, updatedCheckboxes.current);
        parsedUpdatedFilters.confidence = parsedUpdatedFilters.confidence?.toString().replace('%', '');
        parsedUpdatedFilters.rsq = parsedUpdatedFilters.rsq?.toString().replace('%', '');
        let parsedTempFilters = Object.assign({}, defaultFilters, tempFilters, tempCheckboxes);
        parsedTempFilters.confidence = parsedTempFilters.confidence?.toString().replace('%', '');
        parsedTempFilters.rsq = parsedTempFilters.rsq?.toString().replace('%', '');
        delete parsedUpdatedFilters['customVariableGroupsCheckbox'];
        delete parsedTempFilters['customVariableGroupsCheckbox'];
        const filtersChanged = !_.isEqual(parsedTempFilters, parsedUpdatedFilters);
        const formHasErrors = Object.entries(errors).length > 0;
        setDisableNarrowResults(!filtersChanged || total < 0 || count < 0 || loading);
        setDisableViewModels(filtersChanged || count > modelLimit || total < 1 || count < 1 || formHasErrors || loading);
    }, [submit, defaultFilters, tempFilters, tempCheckboxes, errors, count, total, loading, modelLimit]);

  return (
    <div className='model-filter-form-container'>
        <form onSubmit={handleSubmit(formSubmit)} onChange={onChange}>
            <div className={styles.title}><TitleLV>Filter Results</TitleLV></div>
            <div className={styles.formGroup}>
                <FormRowLV>
                    <div className={styles.paddedError}>
                        <TextInputLV 
                            name='confidence' 
                            placeholder='Minimum Confidence %' 
                            description={'0% - 100%' + confidenceHint}
                            className={styles.formField} 
                            width={320}
                            value={tempFilters.confidence || ''}
                            onChange={()=>{}}
                            onBlur={onBlur}
                            register={register({
                                validate: value => validateConfidence(value)
                            })}
                            errors={errors}
                            data-test='confidence-model-filter-form'
                            dataTest='confidence-model-filter-field'
                        />
                    </div>
                    <span className={styles.cssGridBlock}/>
                    <div className={styles.paddedError}>
                        <TextInputLV 
                            name='rsq' 
                            placeholder='Minimum R Squared %' 
                            description={'0.00% - 100.00%' + rsqHint} 
                            className={styles.formField} 
                            width={320}
                            value={tempFilters.rsq || ''}
                            onChange={()=>{}}
                            onBlur={onBlur}
                            register={register({
                                validate: value => validateRsq(value)
                            })}
                            errors={errors}
                            data-test='rsq-model-filter-form'
                            dataTest='rsq-model-filter-field' 
                        />
                    </div>
                </FormRowLV>
            </div>
            {confidenceExceptions()}
            <div className={styles.formGroup}>
                <FormRowLV margin={1}>
                    <div className={styles.paddedError}>
                        <TextInputLV 
                            name='minDurbinWatson' 
                            placeholder='Min Durbin Watson' 
                            description='0.00 - 4.00'
                            className={styles.formField} 
                            width={320}
                            defaultValue={tempFilters.minDurbinWatson}
                            register={register({
                                validate: value => validateMinDurbinWatson(value)
                            })}
                            errors={errors}
                            data-test='minDurbinWatson-model-filter-form' 
                        />
                    </div>
                    <span className={styles.cssGridBlock}/>
                    <div className={styles.paddedError}>
                        <TextInputLV 
                            name='maxDurbinWatson' 
                            placeholder='Max Durbin Watson' 
                            description='0.00 - 4.00' 
                            className={styles.formField} 
                            width={320}
                            defaultValue={tempFilters.maxDurbinWatson}
                            register={register({
                                validate: value => validateMaxDurbinWatson(value)
                            })}
                            errors={errors}
                            data-test='maxDurbinWatson-model-filter-form' 
                        />
                    </div>
                </FormRowLV>
            </div>
            <div className={styles.interceptField}>
                <CheckboxLV name='intercept' dataTest='intercept-model-filter-form' 
                    label='Only Show Intercept ≥ 0' darkTheme={true}
                    disabled={!includeIntercept || loading} checked={tempFilters.intercept}/>
            </div>
            {negativeDrivers()}
            {positiveDrivers()}
            {mandatoryVariables()}
            {excludeVariables()}
            {mandatoryModelIds()}
            {customVariableGroups()}
            <div className={styles.buttonContainer}>
                <OrangeButtonLV variant='contained' dataTest='narrowResults-model-filter-form' color='primary' 
                    type='submit'
                    className={disableNarrowResults ? styles.narrowResultsButtonDisabled : styles.narrowResultsButton} 
                    isDisabled={disableNarrowResults} > Narrow Results </OrangeButtonLV>
                <span className={styles.cssGridBlock}/>
                <GreyButtonLV variant='contained' dataTest='viewModels-model-filter-form' color='primary' 
                    className={disableViewModels ? styles.viewModelsButtonDisabled : styles.viewModelsButton} 
                    isDisabled={disableViewModels || isDownloading} onClick={() => viewModels()}> View Models </GreyButtonLV>
            </div>
            <Loading
                loading={isDownloading || isDownloadingModelAnalysis}
                test-data='model-filter-form-download-text'
                children={(
                    <span className={styles.downloadContainer}>
                        {isModelAnalysisMaxed ? null : 
                            <div onClick={() => downloadModelAnalysis()}>
                                <img src={downloadIcon} alt="download csv icon"/><span className={styles.downloadText}> DOWNLOAD MODEL ANALYSIS</span>
                            </div>
                        }
                    </span>
                )}
            />
        </form>
    </div>
  );
}

export {ModelFilterForm}
