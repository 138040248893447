import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Box, CircularProgress} from '@mui/material';
import { R1Button } from '@rainagency/rain-one-soggy-loaf';
import { usePost, urlWithDomain } from '../../../../hooks/useRest';
import { useUserRoleFromGroup } from '../../../../hooks/useUserRoleFromGroup';
import { useFeatureSelect } from '../../../../hooks/useFeatureSelect';
import { useCampaignManagement } from '../../../../hooks/useCampaignManagement';
import DownloadCSVFactory from '../../../../js/download-csv-factory';
import { uploadFileToS3 } from '../../../../js/storage';
import { urlBuilders } from '../../../../js/constants';
import { FEATURES } from '../../../../constants/features';

import { styles } from './run-analysis.styles';

const MEDIA_GROUPING_FILE_NAME = 'media_grouping.csv';
const MEDIA_GROUPING_FILE_TYPE = 'mediaGrouping';

export const RunAnalysis = ({
  analysisValues,
  configValues
}) => {
  const { campaignId } = useCampaignManagement();
  const { onFeatureSelect } = useFeatureSelect();
  const { isTrial } = useUserRoleFromGroup();

  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [runMMMFlag, setRunMMMFlag] = useState(false);

  /* Call to start ad-Impact run */
  const [{data: adImpactResult, error: adImpactErrors}, runAdImpact] = usePost({
    url: urlBuilders.postWavecast(),
    data: analysisValues
  });

  /* Call to upload configurations file - provides no data in response */
  const [{error: uploadErrors}, uploadMediaGrouping] = usePost({
    url: urlBuilders.completeUpload(),
    data: {},
    manual: true
  });

  /* Call to start MCA RUN V3 */
  const [{data: runResult, error: runErrors}, runMca] = usePost({
    url: '',
    data: {},
    manual: true
  });

  useEffect(() => {
    if (adImpactResult || runResult) {
      setLoading(false);
    }
    }, [adImpactResult, runResult]);

  //Error handling
  useEffect(() => {
    if (adImpactErrors || runErrors || uploadErrors) {
      setIsError(true);
      setLoading(false);
    }
  }, [adImpactErrors, runErrors, uploadErrors]);

  useEffect(() => {
    if (runMMMFlag) {
      const url = isTrial ?  urlBuilders.trialRunMcaV3() : urlBuilders.runMcaV3();
      runMca({
        url: urlWithDomain(url),
        data: analysisValues
      });
    }
  }, [runMMMFlag]);
  
  //Start MMM/Nested-MMM runs
  useEffect(() => {
    const uploadConfig = async (configValues) => {
      //upload media grouping configs and wait for upload complete
      DownloadCSVFactory.setReportProperties();
      DownloadCSVFactory.createDownload(MEDIA_GROUPING_FILE_TYPE, configValues, MEDIA_GROUPING_FILE_NAME);
      const report = DownloadCSVFactory.getDownload(MEDIA_GROUPING_FILE_TYPE);
      const blob = report.download(false);
      const response = await uploadFileToS3(blob, MEDIA_GROUPING_FILE_NAME);

      if (response && !response.error) {
        const {
          status
        } = await uploadMediaGrouping({
          data:  {
            key: response,
            dataType: MEDIA_GROUPING_FILE_TYPE,
            campaignId
          }
        })

        if (status === 204) {
          setRunMMMFlag(true);
        }
      }
    }

    if (configValues) {
      uploadConfig(configValues)
          .catch((err) => {
            console.error(err);
            setIsError(true);
            setLoading(false);
          });
    } else {
      runAdImpact();
    }
  }, [configValues, analysisValues]);

  
  const handleClick = () => {
    if (configValues) {
      onFeatureSelect({feature: FEATURES.MMM_ANALYSIS});
    } else {
      onFeatureSelect({feature: FEATURES.ADIMPACT_ANALYSIS});
    }
  };

  const textProps = isError ? statusToTextProps.ERROR : statusToTextProps.RUNNING;

  return (
    <Box 
      sx={{
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
        width: '360px',
        marginTop: '150px',
        marginBottom: '24px'
      }} >
      <div style={{height: '50px'}}><LoadingSpinner loading={loading && !isError} /></div>
      <Typography className={styles.title}>{textProps.title}</Typography>
      <Typography className={styles.message}>{textProps.description}</Typography>
      <R1Button
          data-testid={'new-analysis-continue-button'}
          style={{marginLeft: '-6px'}}
          color='secondary'
          size='medium'
          disabled={loading}
          onClick={handleClick}>
        GO TO ANALYSIS PAGE
      </R1Button>
    </Box>
  );
}

const statusToTextProps = {
  RUNNING: {
    title: 'Running Analysis...',
    description: 'This may take a while, go grab a coffee or check on another analysis while you wait!'
  },
  ERROR: {
    title: 'Failed to run analysis.',
    description: 'Something went wrong with running the analysis. Try again and if the issue persists, please contact tech support.'
  }
}

const LoadingSpinner = ({
  loading
}) => {
  if (!loading) {
    return null;
  }

  return (
    <CircularProgress color='secondary' size={40} thickness={2} />
  );
};