import {sortByColumnBetter} from '../../js/utils.js';
import queryString from 'query-string';
import {CAMPAIGN_ID_URL_PARAM} from '../../hooks/useCampaign';

// action types
export const FETCH_ALL_CAMPAIGNS = `platform/campaign/fetch-all-campaigns`;
export const LOAD_ALL_CAMPAIGNS = `platform/campaign/load-all-campaigns`;
export const SORT_CAMPAIGNS = `platform/campaign/sort-campaigns`;
export const SET_SELECTED_CAMPAIGN = `platform/campaign/set-selected`;
export const CREATE_CAMPAIGN = `platform/campaign/create`;
export const ADD_CAMPAIGN = `platform/campaign/add`;
export const LOAD_CAMPAIGN_ERRORS = `platform/campaign/load-errors`;
export const RESET_CREATE_CAMPAIGN = `platform/campaign/reset-create`;
export const SET_CREATE_CAMPAIGN_ROUTE = `platform/campaign/set-create-campaign-route`;
export const UPDATE_CAMPAIGN = `platform/campaign/update-campaign`;
export const UPLOAD_FILE = `platform/campaign/upload-file`;
export const SET_CAMPAIGN_TO_EDIT = `platform/campaign/edit`;
export const DELETE_CAMPAIGN = `platform/campaign/delete-campaign`;

// selectors
const ID = 'campaign';
const local = (state) => state['platform'][ID];

// Hack to get campaignID from url instead of store in React.Component uses
// TODO: Remove
//export const getSelectedCampaign = (state) => local(state).selectedCampaign;
export const getSelectedCampaign = () => {
  const query = queryString.parse(window.location.search);
  return query[CAMPAIGN_ID_URL_PARAM];
};

export const getCampaigns = (state) => local(state).allIds;
export const getCampaignById = (state, id) => local(state).byId[id];
export const getCampaignsByClientId = (state, id) =>
    getCampaignsByClientIdCore(local(state), id);
export const getCampaignAscendingSort = (state) => local(state).ascendingSort;
export const getColumnToSortBy = (state) => local(state).columnToSortBy;
export const getCampaignErrors = (state) => local(state).errors;
export const getCreateCampaignRoute = (state) =>
    local(state).createCampaignRoute;
export const getSelectedCampaignName = (state) =>
    getSelectedCampaignNameCore(local(state));
export const getSelectedCampaignStartDate = (state) =>
    getSelectedCampaignStartDateCore(local(state));
export const getSelectedCampaignEndDate = (state) =>
    getSelectedCampaignEndDateCore(local(state));
export const getCampaignToEdit = (state) => local(state).campaignToEdit;

export const initialState = {
    byId: {},
    allIds: [],
    columnToSortBy: '',
    ascendingSort: '',
    selectedCampaign: '',
    errors: [],
    createCampaignRoute: ''
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_ALL_CAMPAIGNS:
            let newById = {};
            let newAllIds = action.campaigns.map((campaign) => {
                newById[campaign.id] = campaign;
                return campaign.id;
            });

            if (state.columnToSortBy !== '') {
                newAllIds = sortByColumnBetter(
                    newAllIds,
                    newById,
                    state.columnToSortBy,
                    state.tableSortAscending
                );
            }
            return Object.assign({}, state, {byId: newById, allIds: newAllIds});
        case SORT_CAMPAIGNS:
            let ascendingSort = state.ascendingSort;
            if (state.columnToSortBy === action.columnToSortBy) {
                if (ascendingSort === false) {
                    ascendingSort = true;
                } else {
                    ascendingSort = false;
                }
            } else {
                ascendingSort = true;
            }
            const sortedIds = sortByColumnBetter(
                state.allIds,
                state.byId,
                action.columnToSortBy,
                ascendingSort
            );
            sessionStorage.setItem(
                `campaign/columnToSortBy`,
                action.columnToSortBy
            );
            sessionStorage.setItem(`campaign/ascendingSort`, ascendingSort);
            return Object.assign({}, state, {
                ascendingSort,
                columnToSortBy: action.columnToSortBy,
                allIds: sortedIds
            });
        case ADD_CAMPAIGN:
            const byIdWithNewCampaign = Object.assign({}, state.byId);
            byIdWithNewCampaign[action.campaign.id] = action.campaign;
            const allIdsWithNewCampaign = state.allIds;
            allIdsWithNewCampaign.unshift(action.campaign.id);
            return Object.assign({}, state, {
                byId: byIdWithNewCampaign,
                allIds: allIdsWithNewCampaign,
                errors: []
            });
        case SET_SELECTED_CAMPAIGN:
            sessionStorage.setItem(
                `platform/campaign/selectedCampaign`,
                action.selectedCampaign
            );
            return Object.assign({}, state, {
                selectedCampaign: action.selectedCampaign
            });
        case SET_CAMPAIGN_TO_EDIT:
            return Object.assign({}, state, {
                campaignToEdit: action.campaignToEdit
            });
        case LOAD_CAMPAIGN_ERRORS:
            return Object.assign({}, state, {errors: action.errors});
        case RESET_CREATE_CAMPAIGN:
            return Object.assign({}, state, {errors: []});
        case SET_CREATE_CAMPAIGN_ROUTE:
            return Object.assign({}, state, {
                createCampaignRoute: action.route
            });
        case UPLOAD_FILE:
            return state;
        default:
            return state;
    }
}

// action creators
export function sortCampaigns(columnToSortBy) {
    return {
        type: SORT_CAMPAIGNS,
        columnToSortBy
    };
}

export function fetchAllCampaigns() {
    return {
        type: FETCH_ALL_CAMPAIGNS
    };
}

export function loadAllCampaings(campaigns) {
    return {
        type: LOAD_ALL_CAMPAIGNS,
        campaigns
    };
}

export function setSelectedCampaign(selectedCampaign) {
    return {
        type: SET_SELECTED_CAMPAIGN,
        selectedCampaign
    };
}

export function addCampaign(campaign) {
    return {
        type: ADD_CAMPAIGN,
        campaign
    };
}

export function loadCampaignErrors(errors) {
    return {
        type: LOAD_CAMPAIGN_ERRORS,
        errors
    };
}

export function resetCreateCampaign() {
    return {
        type: RESET_CREATE_CAMPAIGN
    };
}

export function setCreateCampaignRoute(route) {
    return {
        type: SET_CREATE_CAMPAIGN_ROUTE,
        route
    };
}

export function setCampaignToEdit(campaignToEdit) {
    return {
        type: SET_CAMPAIGN_TO_EDIT,
        campaignToEdit
    };
}

// helper functions
function getCampaignsByClientIdCore(state, id) {
    return state.allIds.filter((campaign) => {
        return state.byId[campaign].companyId === id;
    });
}

/**
 * returns the name of the selected campaign
 * if there is no selected campaign, or no list of campaigns return an empty string
 * @param  {object} state the campaign state
 * @return {string}       the current selected campaign name
 */
function getSelectedCampaignNameCore(state) {
    if (!state.selectedCampaign || !state.byId[state.selectedCampaign]) {
        return '';
    } else {
        return state.byId[state.selectedCampaign].campaignName;
    }
}

/**
 * return the start date of the selected campaign
 * if there is no selected campaign, or no list of campaigns return an empty string
 * @param  {object} state the campaign state
 * @return {string}       the selected campaigns start date
 */
function getSelectedCampaignStartDateCore(state) {
    if (!state.selectedCampaign || !state.byId[state.selectedCampaign]) {
        return '';
    } else {
        return state.byId[state.selectedCampaign].startDate;
    }
}

/**
 * return the end date of the selected campaign
 * if there is no selected campaign, or no list of campaigns returns an empty string
 * @param  {object} state the campaign state
 * @return {string}       the selected campaigns end date
 */
function getSelectedCampaignEndDateCore(state) {
    if (!state.selectedCampaign || !state.byId[state.selectedCampaign]) {
        return '';
    } else {
        return state.byId[state.selectedCampaign].endDate;
    }
}
