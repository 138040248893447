import React from 'react';
import {useGet} from './useRest';
import {urlBuilders} from '../js/constants';
import {useCampaignManagement} from './useCampaignManagement';
import {TV_NO_CUSTOM_OPTION} from '../constants/ad-impact-run-types';
import moment from 'moment';
import _ from 'lodash';

/**
 * Get all Ad-Impact runs for a particular campaign
 * @param filterRunTypes [] Optional list of run types to filter by
 */
export const useAdImpactRuns = (filterRunTypes) => {
  const {campaignId, adImpactId} = useCampaignManagement();

  // GET ad impact runs
  const [{
    data: adImpactRuns,
    loading
  }, fetchRuns] = useGet({
    url: urlBuilders.getWavecastRunsByCampaignId(campaignId),
    manual: true
  });

  React.useEffect(() => {
    if(campaignId) {
      fetchRuns();
    }
  }, [campaignId, fetchRuns]);

  let mappedRuns = React.useMemo(() => {
    let runs = adImpactRuns?.map(run => ({
      ...run,
      analysis_type: run.analysis_type || TV_NO_CUSTOM_OPTION.value,
    }));

    if(Array.isArray(filterRunTypes)) {
      runs = runs?.filter(r => filterRunTypes.includes(r['analysis_type']));
    } 
    return runs;
  }, [adImpactRuns, filterRunTypes]);

  mappedRuns = useSortListByDate(mappedRuns);

  // Get the specific run that is selected from the list of runs
  const adImpactRun = mappedRuns?.find(
    r => (r.wavecast_id === adImpactId)) || {};

  return {
    adImpactRuns: mappedRuns,
    adImpactRun,
    loading,
    fetchRuns
  };
};

/**
 * Get all Ad-Impact runs for a particular campaign
 */
export const useAdImpactRunsByCampaignId = (campaignId) => {
  // GET ad impact runs
  const [{
    data: adImpactRunsFromRest,
    loading,
    error,
    response
  }, fetchRuns] = useGet({
    url: urlBuilders.getWavecastRunsByCampaignId(campaignId),
    manual: true
  });

  React.useEffect(() => {
    if(campaignId) {
      fetchRuns();
    }
  }, [campaignId, fetchRuns]);

  const adImpactRuns = useSortListByDate(adImpactRunsFromRest);

  return {
    adImpactRuns,
    loading,
    fetchRuns,
    error,
    response
  };
};

const useSortListByDate = (
  adImpactRuns
) => {
  return React.useMemo(() => {
    const runsTimeStampAdded = adImpactRuns?.map(
      m => ({
        runTimestamp: moment(m.run_date).format('X'),
        ...m
      })
    );

    // Sort by timestamp and remove timestamp property
    return _.sortBy(runsTimeStampAdded, 'runTimestamp')
      .reverse()
      .map(({runTimestamp, ...m}) => ({...m}));

  }, [adImpactRuns]);
};
