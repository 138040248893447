import {css} from 'emotion';

export const styles = {
  content: css`
    background-color: #FFF;

    .scoring-input-button-row {
      display: grid;
      row-gap: 12px;
      justify-content: end;
      justify-items: end;
      padding: 16px 42px 12px 0;
    }

    .scoring-file-table {
      height: 100%;
      th {
        height: 34px;
      }
    }
  `
};
