import React, { useState, useRef, useEffect } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

import {useSelector, useDispatch} from 'react-redux';
import _ from 'lodash';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import {OrangeButtonLV} from '../../../../components/form/buttons/OrangeButtonLV';
import {GreyButtonLV} from '../../../../components/form/buttons/GreyButtonLV';
import FilterListIcon from '@mui/icons-material/FilterList';

import Spinner from '../../../../components/spinner';
import Loading from '../../../../components/loading';
import {snackbar} from '../../../../modules/platform/snackbar';

import McaFilterChips from './mca-filter-chips';
import McaModelsTable from './mca-models-table';

import useMcaModels from '../../useMcaModels';
import useMcaRunMetrics from '../../useMcaRunMetrics';
import useMcaFilters from '../../useMcaFilters';

import {getModelLimit} from '../../../../modules/platform/config';

import ModalLV from '../../../../components/modal/ModalLV';
import McaModalFilter from '../../mca-filter/model-selector.jsx';

import {formatFilters, parseModels} from '../../../../js/validation';
import {numberWithCommas} from '../../../../js/utils';
import {MAX_MODEL_DOWNLOAD} from '../../../../js/constants';
import {useMcaRuns} from '../../../../hooks/useMcaRuns';

import {styles} from '../mca-models-management.styles';

export default function McaModelsManagement({
    setHasParsedModels=()=>{}, 
    setStartInsights=()=>{}, 
    setInsightsStatus=()=>{}, 
    startInsights}, props) {
    const dispatch = useDispatch();
    //redux selector - modelLimit is set in configuration
    const {modelLimit} = useSelector(state => ({
        modelLimit: getModelLimit(state)
    }));

    const {selectedMcaRun={}} = useMcaRuns();
    const kpi = selectedMcaRun.kpi || '';
    const kpiType = selectedMcaRun.kpiType || selectedMcaRun.type || 'Units';
    const intercept = selectedMcaRun.includeIntercept || false;
    const {
        ranges,
        rangesLoading,
        rangesError,
        totalCount,
        totalCountLoading,
        totalCountError,
        mcaVariables
    } = useMcaRunMetrics(selectedMcaRun);

    const formFilters = useRef({});
    useDeepCompareEffect(() => {
        if(_.isEmpty(selectedMcaRun)){
            return;
        }
        formFilters.current = Object.assign({}, {intercept});
    }, [selectedMcaRun]);

    const setFormFilters = (values) => {
        formFilters.current = values;
    };

    const {
        count,
        countLoading,
        countError
    } = useMcaFilters(selectedMcaRun, mcaVariables, formFilters.current);

    const showModels = React.useMemo(() => 
        count > 0 && count <= modelLimit
    , [count, modelLimit]);

    const [fetchMcaModels, setFetchMcaModels] = useState(false);
    const [downloadAllModels, setDownloadAllModels] = useState(false);
    const [downloadSelectedModels, setDownloadSelectedModels] = useState(false);
    const modelIds = useRef([]);

    const {
        models,
        modelsLoading,
        downloadModelsLoading,
        downloadAllModelsLoading,
        modelsError,
        queryId
    } = useMcaModels(selectedMcaRun, formatFilters(formFilters.current, mcaVariables), fetchMcaModels, 
        modelIds.current, downloadSelectedModels, downloadAllModels);

    //Fetch models on page load when models count below model limit
    useEffect(() => {
        if (showModels && totalCount > 0 && !models.length) {
            setFetchMcaModels(true);
        }
    }, [showModels, totalCount, models.length]);

    const [parsedModels, setParsedModels] = useState([]);

    //Clear parsedModels when models are loading
    useEffect(() => {
        if (modelsLoading) {
            setParsedModels([]);
        }
    }, [modelsLoading]);

    //set parsedModels when models are retrieved
    useEffect(() => {
        if (!!models.length) {
            setFetchMcaModels(false);
            setParsedModels(parseModels(models));
        }
    }, [models]);

    useEffect(() => {
        setHasParsedModels(!!parsedModels.length);
    },[parsedModels.length]);

    useEffect(() => {
        setDownloadAllModels(false);
        setDownloadSelectedModels(false);
    }, [downloadAllModels, downloadSelectedModels]);

    // Fetch and download of models
    const handleFetchMcaModels = () => {
        setInsightsStatus();
        setFetchMcaModels(true);
    };

    const handleDownloadAll = () => {
        setDownloadAllModels(true);
    };

    const downloadSelected = (values=[]) => {
        modelIds.current = values;
        setDownloadSelectedModels(values.length > 0);
    };

    const createTable = () => {
        return (
            <McaModelsTable
                downloadAll={()=>handleDownloadAll()}
                models={parsedModels}
                isDownloading={downloadModelsLoading || downloadAllModelsLoading}
                setDownloadModelIds={downloadSelected}
                mcaRunId={selectedMcaRun.id}
                runName={selectedMcaRun.name}
                includeIntercept={selectedMcaRun.includeIntercept}
                mcaVariables={mcaVariables}
                kpi={kpi}
                kpiType={kpiType}
                queryId={queryId}
                showModels={showModels}
                setInsightsStatus={setInsightsStatus}
                startInsights={startInsights}
                setStartInsights={setStartInsights}
                {...props}
            />
        );
    };

    const buildChips = () => {
        //create individual chips for mandatory model ids
        let chips = Object.assign({}, formFilters.current);
        delete chips.mcaRunId;

        if (formFilters.current.mandatoryModels) {
            formFilters.current.mandatoryModels.map(modelId => chips[modelId] = modelId);
        }

        //check if mandatory models were found in the retrieved results
        let errors = [];
        const mandatoryModelIds = parsedModels.map(model => model.modelid);
        if (count > 0 && formFilters.current.mandatoryModels) {
            formFilters.current.mandatoryModels.map(modelId => {
                const modelIdString = modelId.toString();
                if (mandatoryModelIds.indexOf(modelIdString) === -1) {
                    errors.push({field: modelIdString});
                }
                return errors;
            });
        }

        //build chips
        return Object.entries(chips).length < 1 ? null :
            <div className={styles.filterChipsContainer}>
                <McaFilterChips filters={chips} errors={errors}/>
            </div>;
    };

    const mcaModelFilterForm = () => {
        return <McaModalFilter 
            ranges={ranges} 
            modelLimit={modelLimit}
            mcaVariables={mcaVariables} 
            total={totalCount}
            initialCount={count} 
            filters={formFilters.current}
            setFilters={(values)=>setFormFilters(values)}
            fetchMcaModels={() => handleFetchMcaModels()}
            loading={countLoading} 
            isDownloading={downloadAllModelsLoading}
            dispatch={props.dispatch}
        />;
    };

    const buildMessageBox = () => {
        let message =  `No models were found with the current filters.`;
        let download;

        // Do not allow download on max model count, Athena query will time out
        if (count > modelLimit && count > MAX_MODEL_DOWNLOAD) {
            message = (<div>
                         <div>A maximum of {modelLimit} results can be compared on this screen.</div>
                         <div>No download available because the model count has exceeded {numberWithCommas(MAX_MODEL_DOWNLOAD)}.</div>
                       </div>);
        } else if (count > modelLimit) {
            message = `A maximum of ${modelLimit} results can be compared on this screen. Download the complete .csv file or filter your results.`;
            download = <div className={styles.downloadButtonContainer} >
                <GreyButtonLV variant="outlined" className={styles.downloadButton} onClick={()=>handleDownloadAll()}>DOWNLOAD MODELS</GreyButtonLV>
            </div>;
        }

        return (
            <div className={styles.msgBoxContainer}>
                <div className={styles.msgBoxScroll}>
                    <div className={styles.modelsTitle}>{numberWithCommas(Math.max(0, count))} Models</div>
                    <div className={styles.messageBox}>
                        <div>{message}</div>
                        <div className={styles.filterButtonContainer} data-test="filterButtonContainer-mcaComparisonPageFilter">
                          <ModalLV disableBackdropClick={true}>
                                <OrangeButtonLV variant="outlined" className={styles.filterButton}>Filter Results</OrangeButtonLV>
                                {mcaModelFilterForm()}
                            </ModalLV>
                        </div>
                      <div>
                        <Loading
                          loading={downloadAllModelsLoading}
                          children={download}
                        />
                      </div>
                    </div>
                </div>
            </div>
        );
    };

    const buildContents = () => {
        const loading = countLoading || modelsLoading || totalCountLoading || rangesLoading;  
        if (loading) {
            return (<div className={styles.spinnerContainer}><Spinner/></div>);
        } else if (totalCount < 0) {
            return (<div className={styles.errors}>Error: No models were found.</div>);
        }

        const showingCount = count > modelLimit ? 0 : count;
        const counts = <div className={styles.mcaModelsCountContainer} data-test="mcaModelsCountContainer-mcaComparisonPage">
                <div className={styles.mcaModelsCount}> Model Selection&nbsp;
                    <span className={styles.mcaModelsTotal}>
                        Showing {numberWithCommas(Math.max(0, showingCount))} of {numberWithCommas(Math.max(0, totalCount))}</span>
                </div>
            </div>;
        const chips = formFilters.current ? buildChips(formFilters.current) : null;
        const table = <div className={styles.tableContainer} data-test="tableContainer-mcaComparisonPage">
                    {createTable()}
                </div>;
            
        const filterButton = <div className={styles.updateButtonContainer} data-test="filterButtonContainer-mcaComparisonPageUpdate">
                <ModalLV>
                    <Button variant="contained" color='primary' className={styles.updateButton}>
                        <FilterListIcon/>&nbsp; Update Filters
                    </Button>
                    {mcaModelFilterForm()}
                </ModalLV>
            </div>;

        return (
            <div style={{width: 'inherit'}}>
                {counts}
                {chips}
                {table}
                {count < 1 || count > modelLimit ? buildMessageBox() : filterButton}
            </div>
        );
    };

    useEffect(() => {
        const errorMsg = rangesError || totalCountError || countError || modelsError;
        if (errorMsg) {
            dispatch(snackbar('error', JSON.stringify(errorMsg)));
        }
    }, [rangesError, totalCountError, countError, modelsError, dispatch]);
    
    return (
        <div className={styles.page}>
            <Paper style={{width: '100%', boxShadow: 'none'}}>
                {buildContents()}
            </Paper>
        </div>
    );

}

export {McaModelsManagement}
