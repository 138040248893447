import {combineReducers} from 'redux';
import {all} from 'redux-saga/effects';

import wavecastResult from './modules/wavecast/wavecast.js';
import platformState from './modules/platform/platform.js';
import mcaState from './modules/mca/mca.js';

import {reducer as formReducer} from 'redux-form';

// imports all watchers sagas so there is a single point to start all of them
import {watchRunWavecast} from './modules/wavecast/sagas/run-wavecast-sagas.js';
import {
    watchFetchPartners,
    watchCreatePartner
} from './modules/platform/sagas/partner-sagas.js';
import {
    watchFetchClients,
    watchCreateClient,
    watchUpdateClient,
    watchDeleteClient
} from './modules/platform/sagas/client-sagas.js';
import {
    watchRunMca,
    watchFetchMcaRunsByCampaign,
    watchExportMcaRun,
    watchFetchErrorsCsv
} from './modules/mca/sagas/run-sagas.js';
import {
    watchCreateCampaign,
    watchUpdateCampaign,
    watchDeleteCampaign
} from './modules/platform/sagas/campaign-sagas.js';
import {
    watchFetchMcaModels,
    watchApproveModel,
    watchFetchSelectedModel
} from './modules/mca/sagas/models-sagas.js';
import {
    watchMcaResultCount,
    watchMcaTotalCountCount,
    watchGetMcaVariables,
    watchMcaResultRanges
} from './modules/mca/sagas/filter-sagas.js';
import {watchFetchUploadedFiles} from './modules/platform/sagas/uploadedFiles-sagas.js';
import {watchUploadFile} from './modules/platform/sagas/upload-sagas.js';
import {
    watchGetGroupingTemplateStatus,
    watchGetGroupingTemplateUrl
} from './modules/platform/sagas/downloadTemplate-sagas.js';
import {watchGetKpisForCampaign} from './modules/mca/sagas/kpi-sagas.js';
import {watchDownloadFiles} from './modules/platform/sagas/downloadFiles-sagas.js';
import {
    watchFetchOutputsInsightsData,
    watchFetchDataReport,
    watchFetchReportUrl,
} from './modules/mca/sagas/outputs-insights-sagas.js';
import {
    watchFetchOutputsInsightsFitRequest,
    watchFetchOutputsInsightsThresholdsRequest,
    watchFetchOutputsInsightsOptimalAllocationRequest,
    watchFetchOutputsInsightsEfficiencyRequest,
    watchFetchOutputsInsightsContributionsRequest,
    watchFetchOutputsInsightsDailyContributionRequest,
    watchFetchOutputsInsightsSaturationRequest
} from './modules/mca/sagas/outputs-insights-fetch-sagas.js';
import {watchFetchMcaRunVariableNameTranslator} from './modules/mca/sagas/variable-name-translator-sagas.js';

const leavenedState = combineReducers({
    mca: mcaState,
    wavecast: wavecastResult,
    platform: platformState,
    form: formReducer
});

export default leavenedState;

export function* rootSaga() {
    yield all([
        // put watch sagas here
        watchRunWavecast(),
        watchFetchPartners(),
        watchCreatePartner(),
        watchFetchClients(),
        watchRunMca(),
        watchFetchMcaRunsByCampaign(),
        watchExportMcaRun(),
        watchCreateCampaign(),
        watchCreateClient(),
        watchUpdateCampaign(),
        watchMcaResultCount(),
        watchUpdateClient(),
        watchDeleteClient(),
        watchDeleteCampaign(),
        watchFetchMcaModels(),
        watchMcaTotalCountCount(),
        watchGetMcaVariables(),
        watchMcaResultRanges(),
        watchFetchUploadedFiles(),
        watchUploadFile(),
        watchGetGroupingTemplateStatus(),
        watchGetGroupingTemplateUrl(),
        watchGetKpisForCampaign(),
        watchApproveModel(),
        watchDownloadFiles(),
        watchFetchSelectedModel(),
        watchFetchOutputsInsightsData(),
        watchFetchDataReport(),
        watchFetchReportUrl(),
        watchFetchOutputsInsightsFitRequest(),
        watchFetchOutputsInsightsThresholdsRequest(),
        watchFetchOutputsInsightsOptimalAllocationRequest(),
        watchFetchOutputsInsightsEfficiencyRequest(),
        watchFetchErrorsCsv(),
        watchFetchOutputsInsightsContributionsRequest(),
        watchFetchOutputsInsightsDailyContributionRequest(),
        watchFetchOutputsInsightsSaturationRequest(),
        watchFetchMcaRunVariableNameTranslator(),
    ]);
}
