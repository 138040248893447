import {css} from 'emotion';

export const styles = {
    typeBoxBig: css`
      height: 90vh;
      width: 100%;
      overflow-y: scroll;
      display: flex;
      justify-content: center;
    `,
    form: css`
      margin: 42px 0 24px 0;
    `,
    waiting: css`
        cursor: wait !important;
        button {
            cursor: wait !important;
        }
    `,
    configurationTableContainer: css`
        border-radius: 15px;
        text-align: center;
        font-family: 'Roboto', sans-serif !important;
        font-size: 9px;
        line-height: 16px;
        color: #FFFFFF;        
        justify-content: center; 
        table {
            width: 1000px !important;
        };    
        th:first-child {
            text-align: left !important;
        };
    `,
    configurationTitle: css`
        top: 449px;
        font-family: 'Roboto', sans-serif;
        font-size: 34px;
        line-height: 40px;
        letter-spaceing: 0.25px;
        color: #FFFFFF;
    `,
    modelCount: css`
        height: 40px;
        left: 389px;
        padding-top: 24px;
        font-family: 'Roboto', sans-serif;
        font-size: 34px;
        line-height: 40px;        
        letter-spacing: 0.25px;        
        color: #A9D980;
    `,
    errorMsg: css`
        margin-top: 40px;
        color: #ff7762;
        text-align: center;
        height: 20px;
    `,
    countErrorMsg: css`
        padding-bottom: 40px;
        color: #ff7762;
        text-align: center;
        height: 20px;
    `,
    table: css`
        td {
            border-right: 1px solid rgba(48, 70, 88, .2);
            border-bottom: 1px solid rgba(224, 224, 224, .2);
            position: relative;
        }
    `,
    tableHeader: css`
        background: #304658 !important;
        color: white !important;
        text-align: center !important;
        height: 40px !important;
    `,
    stripe: css`
        background: rgba(255, 255, 255, 0.1)
    `,
    textColumn: css`
        height: 40px !important;
        input:disabled {
          border: 0;
          color: #FFF !important;
        }
    `,
    measureColumn: css`
        height: 40px !important;
        min-width: 97px !important;
        text-align: center !important;
    `,
    selectColumn: css`
        height: 40px !important;
        min-width: 55px !important;
        text-align: center !important;
    `,
    decaySelectColumn: css`
        height: 40px !important;
        min-width: 170px !important;
        text-align: center !important;
    `,
    satSelectColumn: css`
        height: 40px !important;
        min-width: 154px !important;
        text-align: center !important;
        &.suggested{
            color: aqua !important;
        }
    `,
    disabledSelect: css`
        border-bottom: 1px solid #A9A9A9 !important;
        .MuiSelect-select {
            color: #A9A9A9 !important;
        }
        .MuiSelect-icon {
            fill: #A9A9A9 !important;
        }
    `,
    completeContainer: css`
        text-align: center;
        margin: auto;
    `,
    completeTitle: css`
        font-weight: lighter !important;
        font-size: 48px !important;
        margin-top: 13px !important;
        margin-bottom: 4px;
        text-align: center !important;
    `,
    typeName: css`
        margin-left: auto;
        margin-right: auto;
        text-transform: initial !important;
        font-size: 16px !important;
        font-weight: bold !important;
        color: white !important;
        text-align: center !important;
        width: 400px;
    `,
    completeMsg: css`
        text-transform: initial !important;
        font-size: 16px !important;
        font-weight: lighter !important;
        width: 425px;
    `,
    closeButton: css`
        width: 184px;
        height: 48px;
        background: #ffffff;
        border: 1px solid rgba(126, 146, 146, 0.25);
        box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.15);
        font-size: 14px;
        color: #374f63;
        text-align: center;
        line-height: 20px;
        margin-top: 40px !important;
    `,
    leftRowLabel: css`
        font-family: 'Roboto', sans-serif;
        font-size: 13px !important;
        color: gray !important;
        padding: 10px 0px 10px 10px;
    `,
    suggested: css`
        color: aqua !important;
    `
};
