import moment from 'moment';
import {dateFormat} from '../../js/validation.js';

import {
    filterByDate,
    filterByDateAndGroup,
    removeNegativesFromContributionsDaily
} from './core/outputs-insights-core.js';

import {getSelectedMediaVehicle} from './media-vehicle-dashboard.js';

// actions
export const FETCH_DATA_REPORT = `app/mca/outputs-and-insights/fetch-data-report`;
export const FETCH_REPORT_URL = `app/mca/outputs-and-insights/fetch-report-url`;
export const FETCH_OUTPUTS_INSIGHTS_DATA = `app/mca/outputs-and-insights/fetch-outputs-insights-data`;
export const FETCH_INSIGHTS_ARCHIVE = `app/mca/outputs-and-insights/fetch-insights-archive`;
export const LOAD_OUTPUTS_INSIGHTS_DATA = `app/mca/outputs-and-insights/load-outputs-insights-data`;
export const SET_FILTERED_FIT_OUTPUTS = `app/mca/outputs-and-insights/set-filtered-fit-outputs`;
export const SET_FIT_DATE_RANGE = `app/mca/outputs-and-insights/set-fit-date-range`;
export const SET_CONTRIBUTION_FILTER = `app/mca/outputs-and-insights/set-contribution-filter`;
export const SPINNER = `app/mca/outputs-insights/spinner`;

export const FETCH_OUTPUTS_INSIGHTS_FIT = `app/mca/outputs-insights/fetch-outputs-insights-fit`;
export const FETCH_OUTPUTS_INSIGHTS_FIT_REQUEST = `app/mca/outputs-insights/fetch-outputs-insights-fit-request`;
export const FETCH_OUTPUTS_INSIGHTS_FIT_SUCCESS = `app/mca/outputs-insights/fetch-outputs-insights-fit-success`;

export const FETCH_OUTPUTS_INSIGHTS_THRESHOLDS = `app/mca/outputs-insights/fetch-outputs-insights-thresholds`;
export const FETCH_OUTPUTS_INSIGHTS_THRESHOLDS_REQUEST = `app/mca/outputs-insights/fetch-outputs-insights-thresholds-request`;
export const FETCH_OUTPUTS_INSIGHTS_THRESHOLDS_SUCCESS = `app/mca/outputs-insights/fetch-outputs-insights-thresholds-success`;

export const FETCH_OUTPUTS_INSIGHTS_OPTIMAL_ALLOCATION = `app/mca/outputs-insights/fetch-outputs-insights-optimal-allocation`;
export const FETCH_OUTPUTS_INSIGHTS_OPTIMAL_ALLOCATION_REQUEST = `app/mca/outputs-insights/fetch-outputs-insights-optimal-allocation-request`;
export const FETCH_OUTPUTS_INSIGHTS_OPTIMAL_ALLOCATION_SUCCESS = `app/mca/outputs-insights/fetch-outputs-insights-optimal-allocation-success`;

export const FETCH_OUTPUTS_INSIGHTS_EFFICIENCY = `app/mca/outputs-insights/fetch-outputs-insights-efficiency`;
export const FETCH_OUTPUTS_INSIGHTS_EFFICIENCY_REQUEST = `app/mca/outputs-insights/fetch-outputs-insights-efficiency-request`;
export const FETCH_OUTPUTS_INSIGHTS_EFFICIENCY_SUCCESS = `app/mca/outputs-insights/fetch-outputs-insights-efficiency-success`;

export const FETCH_OUTPUTS_INSIGHTS_CONTRIBUTIONS = `app/mca/outputs-insights/fetch-outputs-insights-contributions`;
export const FETCH_OUTPUTS_INSIGHTS_CONTRIBUTIONS_REQUEST = `app/mca/outputs-insights/fetch-outputs-insights-contributions-request`;
export const FETCH_OUTPUTS_INSIGHTS_CONTRIBUTIONS_SUCCESS = `app/mca/outputs-insights/fetch-outputs-insights-contributions-success`;

export const FETCH_OUTPUTS_INSIGHTS_DAILY_CONTRIBUTIONS = `app/mca/outputs-insights/fetch-outputs-insights-daily-contributions`;
export const FETCH_OUTPUTS_INSIGHTS_DAILY_CONTRIBUTIONS_REQUEST = `app/mca/outputs-insights/fetch-outputs-insights-daily-contributions-request`;
export const FETCH_OUTPUTS_INSIGHTS_DAILY_CONTRIBUTIONS_SUCCESS = `app/mca/outputs-insights/fetch-outputs-insights-daily-contributions-success`;

export const FETCH_OUTPUTS_INSIGHTS_SATURATION = `app/mca/outputs-insights/fetch-outputs-insights-saturation`;
export const FETCH_OUTPUTS_INSIGHTS_SATURATION_REQUEST = `app/mca/outputs-insights/fetch-outputs-insights-saturation-request`;
export const FETCH_OUTPUTS_INSIGHTS_SATURATION_SUCCESS = `app/mca/outputs-insights/fetch-outputs-insights-saturation-success`;

// Outputs and Insights types
export const FIT = 'fit';
export const THRESHOLDS = 'thresholds';
export const OPTIMAL_ALLOCATION = 'optimal-allocation';
export const EFFICIENCY = 'efficiency';
export const SATURATION = 'saturation';
export const CONTRIBUTIONS = 'contributions';
export const DAILY_CONTRIBUTIONS = 'daily-contributions';
export const TOTAL_CONTRIBUTIONS = 'total-contributions';
export const ROI = 'roi';
export const MEDIA_VEHICLE = 'mediaVehicle';
export const ARCHIVE = 'archive';
export const MODEL_SUMMARY = 'model-summary';

// Selectors
const ID = 'insights';
const local = (state) => state['mca'][ID];
export const getFitOutputs = (state) =>
    filterByDate(
        local(state).fit,
        local(state).fitDateRange.rangeDateFrom,
        local(state).fitDateRange.rangeDateTo
    );
export const getContributionsOutputs = (state) => {
    return filterByDate(
        local(state).contributions,
        local(state).fitDateRange.rangeDateFrom,
        local(state).fitDateRange.rangeDateTo
    );
};
export const getDailyContributionOutputs = (state) =>
    filterByDate(
        local(state).dailyContributions,
        local(state).fitDateRange.rangeDateFrom,
        local(state).fitDateRange.rangeDateTo
    );

export const getContributionFilter = (state) => local(state).contributionFilter;

export const dateRangeExists = (state) =>
    Object.keys(local(state).fitDateRange).length > 0;
export const getThresholdsOutputs = (state) => local(state).thresholds;
export const getOptimalAllocationOutputs = (state) =>
    local(state).optimalAllocation;
export const getEfficiencyOutputs = (state) =>
    filterByDateAndGroup(
        local(state).efficiency,
        local(state).fitDateRange.rangeDateFrom,
        local(state).fitDateRange.rangeDateTo,
        state,
        getSelectedMediaVehicle(state)
    );
export const getHasEfficiencyData = (state) =>
    local(state).efficiency.length > 0;
export const getHasSaturationData = (state) =>
    local(state).saturationValues.length > 0;
export const getFitDateRange = (state) => local(state).fitDateRange;
export const getDateRangeMinMax = (state) => local(state).dateRangeMinMax;
export const getSaturationValues = (state) =>
    filterByDateAndGroup(
        local(state).saturationValues,
        local(state).dateRangeMinMax.dateFrom,
        local(state).dateRangeMinMax.dateTo,
        state,
        getSelectedMediaVehicle(state)
    );

export const initialState = {
    contributions: [],
    dailyContributions: {},
    fit: [],
    thresholds: [],
    optimalAllocation: [],
    efficiency: [],
    contributionFilter: false,
    fitDateRange: {},
    dateRangeMinMax: {},
    saturationValues: [],
    scenarioPlanner: []
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_OUTPUTS_INSIGHTS_FIT_SUCCESS:
            return {...state, fit: action.data};
        case FETCH_OUTPUTS_INSIGHTS_THRESHOLDS_SUCCESS:
            return {...state, thresholds: action.data};
        case FETCH_OUTPUTS_INSIGHTS_OPTIMAL_ALLOCATION_SUCCESS:
            return {...state, optimalAllocation: action.data};
        case FETCH_OUTPUTS_INSIGHTS_EFFICIENCY_SUCCESS:
            return {
                ...state,
                efficiency: action.data
            };
        case FETCH_OUTPUTS_INSIGHTS_CONTRIBUTIONS_SUCCESS:
            return {...state, contributions: action.data};
        case FETCH_OUTPUTS_INSIGHTS_DAILY_CONTRIBUTIONS_SUCCESS:
            return {
                ...state,
                dailyContributions: removeNegativesFromContributionsDaily(
                    action.data
                )
            };
        case SET_FIT_DATE_RANGE:
            const dates = state.fit.map((data) =>
                moment(data.date, dateFormat)
            );
            const rangeDateFrom = moment.min(dates).format(dateFormat);
            const rangeDateTo = moment.max(dates).format(dateFormat);
            return Object.assign({}, state, {
                dateRangeMinMax: {
                    dateFrom: rangeDateFrom,
                    dateTo: rangeDateTo
                },
                fitDateRange: {
                    rangeDateFrom: rangeDateFrom,
                    rangeDateTo: rangeDateTo
                }
            });
        case SET_FILTERED_FIT_OUTPUTS:
            return {
                ...state,
                fitDateRange: {
                    rangeDateFrom: action.dateFrom,
                    rangeDateTo: action.dateTo
                }
            };
        case SET_CONTRIBUTION_FILTER:
            return {
                ...state,
                contributionFilter: action.contributionFilter
            };
        case FETCH_OUTPUTS_INSIGHTS_SATURATION_SUCCESS:
            let saturationContributions = action.saturationValues.filter(
                function(entry) {
                    return !entry.Variable.startsWith('Simulated');
                }
            );
            let saturationWithMatchingSimulatedValues = [];
            for (let entry of saturationContributions) {
                let rawVarName = entry.Variable.substr(2).replace(
                    /DEC[0-9]Acc[0-9].[0-9]*POI[0-9].*Contribution/g,
                    ''
                );
                let simulatedVarName =
                    'Simulated_' +
                    entry.Variable.substr(2).replace(
                        /Acc[0-9].[0-9]*POI[0-9].*Contribution/g,
                        ''
                    );
                let correspondingSimulatedRow = action.saturationValues.find(
                    function(row) {
                        return (
                            row.Date === entry.Date &&
                            row.Variable === simulatedVarName
                        );
                    }
                );
                entry.date = entry.Date;
                entry.rawVarName = rawVarName;
                entry.independentVariable = entry.Variable.replace(
                    'Contribution',
                    ''
                );
                entry.Value = Number(entry.Value);
                entry.simulatedValue = Number(correspondingSimulatedRow.Value);
                saturationWithMatchingSimulatedValues.push(entry);
            }
            return {
                ...state,
                saturationValues: saturationWithMatchingSimulatedValues
            };
        default:
            return state;
    }
}

// action creators
export function fetchOutputsInsightsFitRequest(runId, token, baseUrl) {
    return {
        type: FETCH_OUTPUTS_INSIGHTS_FIT_REQUEST,
        runId,
        token,
        baseUrl
    };
}

export function fetchOutputsInsightsFitSuccess(data) {
    return {
        type: FETCH_OUTPUTS_INSIGHTS_FIT_SUCCESS,
        data
    };
}

export function fetchOutputsInsightsThresholdsRequest(runId, token, baseUrl) {
    return {
        type: FETCH_OUTPUTS_INSIGHTS_THRESHOLDS_REQUEST,
        runId,
        token,
        baseUrl
    };
}

export function fetchOutputsInsightsThresholdsSuccess(data) {
    return {
        type: FETCH_OUTPUTS_INSIGHTS_THRESHOLDS_SUCCESS,
        data
    };
}

export function fetchOutputsInsightsOptimalAllocationRequest(
    runId,
    token,
    baseUrl
) {
    return {
        type: FETCH_OUTPUTS_INSIGHTS_OPTIMAL_ALLOCATION_REQUEST,
        runId,
        token,
        baseUrl
    };
}

export function fetchOutputsInsightsOptimalAllocationSuccess(data) {
    return {
        type: FETCH_OUTPUTS_INSIGHTS_OPTIMAL_ALLOCATION_SUCCESS,
        data
    };
}

export function fetchOutputsInsightsEfficiencyRequest(runId, token, baseUrl) {
    return {
        type: FETCH_OUTPUTS_INSIGHTS_EFFICIENCY_REQUEST,
        runId,
        token,
        baseUrl
    };
}

export function fetchOutputsInsightsEfficiencySuccess(data) {
    return {
        type: FETCH_OUTPUTS_INSIGHTS_EFFICIENCY_SUCCESS,
        data
    };
}

export function fetchOutputsInsightsContributionsRequest(
    runId,
    token,
    baseUrl
) {
    return {
        type: FETCH_OUTPUTS_INSIGHTS_CONTRIBUTIONS_REQUEST,
        runId,
        token,
        baseUrl
    };
}

export function fetchOutputsInsightsContributionsSuccess(data) {
    return {
        type: FETCH_OUTPUTS_INSIGHTS_CONTRIBUTIONS_SUCCESS,
        data
    };
}

export function fetchOutputsInsightsDailyContributionsRequest(
    runId,
    token,
    baseUrl
) {
    return {
        type: FETCH_OUTPUTS_INSIGHTS_DAILY_CONTRIBUTIONS_REQUEST,
        runId,
        token,
        baseUrl
    };
}

export function fetchOutputsInsightsDailyContributionsSuccess(data) {
    return {
        type: FETCH_OUTPUTS_INSIGHTS_DAILY_CONTRIBUTIONS_SUCCESS,
        data
    };
}

export function setOutputsInsightsData(insightType, insightData) {
    return {
        type: LOAD_OUTPUTS_INSIGHTS_DATA,
        insightType,
        insightData
    };
}

export function setFilteredFitOutputs(dateFrom, dateTo) {
    return {
        type: SET_FILTERED_FIT_OUTPUTS,
        dateFrom,
        dateTo
    };
}

export function setContributionFilter(contributionFilter) {
    return {
        type: SET_CONTRIBUTION_FILTER,
        contributionFilter
    };
}

export function setFitDateRange() {
    return {
        type: SET_FIT_DATE_RANGE
    };
}

export function fetchOutputsInsightsSaturationRequest(runId, token, baseUrl) {
    return {
        type: FETCH_OUTPUTS_INSIGHTS_SATURATION_REQUEST,
        runId,
        token,
        baseUrl
    };
}

export function fetchOutputsInsightsSaturationSuccess(saturationValues) {
    return {
        type: FETCH_OUTPUTS_INSIGHTS_SATURATION_SUCCESS,
        saturationValues
    };
}
