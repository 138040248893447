import React from 'react';
import PropTypes from 'prop-types';
import {styles} from './button.styles';
import {FormUtilLV} from '../util/FormUtilLV';

/**
 * Standard form submit button
 */
class ButtonLV extends React.Component {

  /**
   * Determine css classnames to add to component
   * @param isDisabled If button is in a disabled state
   * @param isSubmitting If button is in a submitting state
   * @param className Css className from parent
   * @param isInverted Bool swap background with border if true
   * @return css string with all css classnames
   */
  static getCSS(isDisabled, isSubmitting, className, isInverted) {
    const classNames = [styles.buttonLV, className];
    if(isDisabled) {
      classNames.push(styles.disabled);
    }
    if(isSubmitting) {
      classNames.push(styles.submitting);
    }
    if(isInverted) {
      classNames.push('inverted');
    }
    return classNames.join(' ');
  }

  /**
   * Determine if button should be disabled
   * We want to disable button if form is pristine or submitting,
   * but only if they were defined in the first place
   * @param props Component Props
   * @return Boolean should this button be disabled
   */
  static getIsDisabled(isDisabled, dirty, submitting) {
    if(isDisabled) {
      return true;
    }

    if(dirty === false || submitting === true) {
      return true;
    }

    return false;
  }

  render() {
    const {width, onClick, className,
           isDisabled, hidden, dirty, submitting, dataTest, isInverted, title, style={}} = this.props;
    const disabled = ButtonLV.getIsDisabled(isDisabled,
                                            dirty, submitting);
    const css = ButtonLV.getCSS(disabled, submitting, className, isInverted);
    // If no click handler is set it will submit the form on click
    const buttonType = (onClick) ? 'button' : 'submit';

    const customStyles= {
      ...FormUtilLV.addWidth(width),
      ...style
    };

    return (
      <button
        className={css}
        style={customStyles}
        onClick={onClick}
        type={buttonType}
        disabled={disabled}
        hidden={hidden}
        data-test={dataTest}
        title={title}>
        <div className={styles.text}>{this.props.children}</div>
      </button>
    );
  }
}

ButtonLV.propTypes = {
  /**
   * Will execute when click event occurs
   * if not provided, will submit the parent form
   */
  onClick: PropTypes.func,
  /**
   * Width of button
   */
  width: PropTypes.number,
  /**
   * Parent form fields have been touched
   */
  dirty: PropTypes.bool,
  /**
   * Form is in the middle of being submitted
   */
  submitting: PropTypes.bool,
  /**
   * Should button be disabled
   */
  isDisabled: PropTypes.bool,
  /**
   * Should button be hidden
   */
  isHidden: PropTypes.bool,
};

export {ButtonLV}
