import {call, put, takeEvery} from 'redux-saga/effects';

import {urlBuilders} from '../../../js/constants.js';

import {RUN_WAVECAST, WAVECAST_RESULT} from '../wavecast.js';

import {wavecastPost} from '../../../js/server';

export function* runWavecast(action) {
    const results = yield call(
        wavecastPost,
        action.token,
        action.baseUrl + urlBuilders.runWavecast(),
        action.payload
    );
    yield put({type: WAVECAST_RESULT, wavecastResults: results});
}

export function* watchRunWavecast() {
    yield takeEvery(RUN_WAVECAST, runWavecast);
}
