import React from 'react';
import {DATE_SPAN} from '../../constants/dates';
import {CHART_FORMAT} from './ChartFormat.constant';
import {CustomTooltip} from './CustomTooltip';
import {useToggleColumnLegend} from './CustomLegend';
import {getChartColor} from './chartColors';
import {formatAxis, getYAxisLabelOffset} from './chart-format.util';
import {useValidateChartData} from './useValidateChartData';
import {styles, legendContent, getChartStateCss} from './charts.styles';
import {ScrollBrush} from './ScrollBrush';
import {useBroadcastDates, useBroadcastRechartsRefLines} from '../../hooks/useBroadcastDates';
import {useDataRollup} from '../../hooks/useDataRollup';
import moment from 'moment';

import {
  ComposedChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Area, ReferenceLine
} from 'recharts';

/**
 * Builds a line chart of the fit data, actual vs predicted
 * http://recharts.org/en-US/api/LineChart
 */
export const LineChartLV = ({
  data=[], yAxisLabel='', xAxisKey='x', yAxisKey='y', isLoading=false,
  formatMap={}, colorMap={}, xTicks=20, yTicks=11, isBroadcastDates=false,
  isArea=false, referenceLine, referenceArea, scrollBar={},
  TopLeftContent, height=400, noDataMessage, secondAxisVars=[], confidenceInterval={}
}) => {
  const [dateSpan, setDateSpan] = React.useState(DATE_SPAN.NONE);

  let dataWithConfidence = data;
  if(confidenceInterval.upper && confidenceInterval.lower){
    dataWithConfidence = formatConfidenceData(data, confidenceInterval);
  }
  let chartData = useValidateChartData(dataWithConfidence, 15, xAxisKey, yAxisKey);
  const {
    legendClickHandler,
    hiddenColumnMap,
    CustomLegend
  } = useToggleColumnLegend(chartData);

  const chartStateCss = getChartStateCss(data, isLoading, noDataMessage);
  const isAnimationActive = false;
  const gradientOffset = getGradientOffset(chartData, referenceArea);

  // Vertical Lines for Broadcast Dates
  const {
    selectedBroadcastDates, dataWithTimestamps, min, max
  } = useBroadcastDates({
    data: chartData,
    selectedDateSpan: dateSpan,
    isLoading,
    isBroadcastDates
  });

  const rollupData = useDataRollup({
    data: dataWithTimestamps,
    timestamps: selectedBroadcastDates,
    isDataRollup: isBroadcastDates
  });

  let xAxisProps = {
    dataKey: xAxisKey,
    tickFormatter: formatAxis(xAxisKey, formatMap) 
  };

  // Broadcast range needs to run in timestamps so Recharts infer the date values between rows
  if(isBroadcastDates) {
    // Add timestamps and hide them on display to user
    chartData = rollupData;
    formatMap = {...formatMap, timestamp: CHART_FORMAT.HIDDEN};

    xAxisProps = {
      dataKey: 'timestamp',
      tickFormatter: (value) => (
        moment(new Date(value*1000)).format('MM/DD/YYYY')
      ),
      type: 'number',
      domain: [min, max]
    };
  }

  const BroadcastReferenceLines = useBroadcastRechartsRefLines({
    dates: selectedBroadcastDates,
    isDisplay: (isBroadcastDates && !isLoading && dateSpan !== DATE_SPAN.NONE),
  });

  // Get the Colored <Lines> in our graph
  // http://recharts.org/en-US/api/Line
  // Return an <Area> element if the key is in formatMap.area
  // http://recharts.org/en-US/api/Area
  const getLineWithColor = (key, index) => {
    const color = getChartColor(index+1, colorMap, key);
    const areaArray = formatMap.area || [];
    let lineId = undefined;

    if(secondAxisVars.length){
      lineId = secondAxisVars.includes(key) ? 'right' : 'left';
    }

    // If area flag or array provided
    if(isArea || areaArray.indexOf(key) > -1) {
      return (
        [
          <defs key={`line-chart-line-${index}`}>
            <linearGradient id={"shades-" + index} x1="0" y1="0" x2="1" y2="0">
              <stop offset={gradientOffset} stopColor={color} stopOpacity={1}/>
              <stop offset={gradientOffset} stopColor={color} stopOpacity={0.7} />
            </linearGradient>
          </defs>,
          <Area
            type="monotone"
            dataKey={key}
            hide={hiddenColumnMap[key]}
            isAnimationActive={isAnimationActive}
            stackId="1"
            stroke={color}
            strokeWidth={0}
            fillOpacity={1}
            fill={"url(#shades-" + index + ")"}
            key={index}/>
        ]
      );
    }

    return (
      <Line
        type="linear"
        yAxisId={lineId}
        isAnimationActive={isAnimationActive}
        hide={hiddenColumnMap[key]}
        dataKey={key}
        dot={false}
        stroke={color}
        strokeWidth={3}
        key={index}/>
    );
  };

  const ChartReferenceLine = referenceLine ? 
    <ReferenceLine x={referenceLine.x} stroke='red' label={{value: referenceLine.label, offset: -12, position: 'insideTopRight'}} inFront={true}/> : null;

  // Filter out non numerical fields and get graph lines
  const columnsObj = chartData[0] || {};
  const keys = Object.entries(columnsObj)
    .filter(([key, value]) => (!isNaN(value) && key !== 'timestamp'))
    .map(([key]) => (key));
  const charts = keys.map(getLineWithColor);
  const Lines = Array.isArray(charts) ? [].concat(...charts) : charts;

  const ConfidenceArea = Object.keys(columnsObj).includes("confidence") ?
    (<Area
        type="monotone"
        dataKey={"confidence"}
        isAnimationActive={isAnimationActive}
        hide={hiddenColumnMap["confidence"]}
        stroke={"red"}
        strokeWidth={0}
        fillOpacity={0.3}
        fill={"red"}/>
    ) : null;

  const classNames = [styles.chart,
                      chartStateCss,
                      legendContent()
                     ].join(' ');

  // Wrapping ReCharts component won't render in markup, so we call it like a function
  const ScrollBar = ScrollBrush({
    data: chartData,
    dataKey: xAxisKey,
    minLength: scrollBar.minLength
  });

  const SecondYAxis = secondAxisVars.length ? (<YAxis
    width={100}
    tickMargin={10}
    yAxisId="right"
    orientation="right"
    axisLine={false}
    tickSize={0}
    tickCount={yTicks}
    tickFormatter={formatAxis(yAxisKey, formatMap)}
    label={{
      value: yAxisLabel,
      angle: -90,
      offset: 0,
      position: 'right',
      style:{ textAnchor: 'middle' }
    }}/>) : null;

  return (
    <ResponsiveContainer
      width='100%'
      height={height}
      debounce={1}
      id='line-chart'
      className={classNames}>

      <ComposedChart
        margin={{top: 10, right: 16, left: 0, bottom: 0}}
        width={730}
        height={250}
        data={chartData}>

        <XAxis
          dataKey={xAxisProps.dataKey}
          tickMargin={10}
          tickSize={0}
          tickCount={xTicks}
          tickFormatter={xAxisProps.tickFormatter}
          interval={Math.floor(chartData.length / xTicks)}
          height={100}
          angle={-90}
          type={xAxisProps.type}
          domain={xAxisProps.domain}
          textAnchor="end"
        />

        <YAxis
          yAxisId={secondAxisVars.length ? 'left' : undefined}
          width={100}
          tickMargin={10}
          axisLine={false}
          tickSize={0}
          tickCount={yTicks}
          tickFormatter={formatAxis(yAxisKey, formatMap)}
          label={{
            value: yAxisLabel,
            angle: -90,
            position: 'center',
            dx: getYAxisLabelOffset(data, 30)
          }}/>

        {SecondYAxis}

        <CartesianGrid vertical={false} />

        <Tooltip
          content={
            <CustomTooltip
              map={formatMap}
              hiddenColumnMap={hiddenColumnMap}
            />
          }/>

        <Legend
          align='left'
          verticalAlign='top'
          wrapperStyle={{
            left: 0,
            top: 0,
            paddingBottom: '10px',
            display: Object.keys(keys).length ? 'flex' : 'hidden',
          }}
          hiddenColumnMap={hiddenColumnMap}
          onClick={legendClickHandler}
          isLoading={isLoading}
          isBroadcastDates={isBroadcastDates}
          dateSpan={dateSpan}
          setDateSpan={setDateSpan}
          TopLeftContent={TopLeftContent}
          content={CustomLegend}
        />

        {Lines}

        {ConfidenceArea}

        {ChartReferenceLine}

        {BroadcastReferenceLines}

        {ScrollBar}

      </ComposedChart>
    </ResponsiveContainer>
  );
};

const getGradientOffset = (chartData=[], referenceArea) => {
  if(!referenceArea || typeof referenceArea.filterFunction !== 'function' || !chartData.length) {
    return 1;
  }

  const referenceAreaLength = chartData.filter(referenceArea.filterFunction).length - 1;
  return 1 - (referenceAreaLength / chartData.length);
};

const formatConfidenceData = (data=[], confidenceInterval={}) => {
  if(data.length < 0){
    return;
  }
  return data.map((row) => {
    if(!row[confidenceInterval.lower] && !row[confidenceInterval.upper]){
      return row;
    }
    let range = [];
    if(row[confidenceInterval.lower]){
      range.push(row[confidenceInterval.lower]);
    }
    if(row[confidenceInterval.upper]){
      range.push(row[confidenceInterval.upper]);
    }
    const {[confidenceInterval.lower]: remove1, [confidenceInterval.upper]: remove2, ...newRow} = row;
    return {
      ...newRow,
      confidence: range
    };
  });
}