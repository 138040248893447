import {css, keyframes} from 'emotion';

const shake = keyframes`
    10%, 90% {
       transform: translate3d(-1px, 0, 0);
     }

     20%, 80% {
       transform: translate3d(2px, 0, 0);
     }

     30%, 50%, 70% {
       transform: translate3d(-4px, 0, 0);
     }

     40%, 60% {
       transform: translate3d(4px, 0, 0);
     }
`;

export const formStyles = {
    fieldContainer: css`
        display: flex;
        flex-direction: column;
    `,
    outlinedFieldContainer: css`
        display: flex;
        flex-direction: column;
        color: white !important;
        min-height: 16px !important;
        padding-right: 16px !important;
        min-width: 351px !important;
    `,
    fieldError: css`
        color: #ff7762;
        text-align: center;
        font-family: 'Roboto', sans-serif;
        font-size: 1em !important;
        max-width: 70%;
    `,
    outlinedFieldError: css`
        color: #ff7762;
        text-align: center;
        font-family: 'Roboto', sans-serif;
        font-size: 1em !important;
        max-width: 90%;
    `
};

export const textFieldStyles = {
    belowTheLine: css`
        display: flex;
        justify-content: space-between;
    `,
    darkField: css`
        font-size: 12px !important;
        color: rgba(255, 255, 255, 0.5) !important;
    `,
    lightField: css`
        font-size: 12px !important;
        color: rgba(0, 0, 0, 0.5) !important;
    `,
    darkHint: css`
        font-family: 'Roboto', sans-serif;
        font-size: 7px !important;
        color: rgba(255, 255, 255, 0.7) !important;
    `,
    lightHint: css`
        font-family: 'Roboto', sans-serif;
        font-size: 7px !important;
        color: rgba(0, 0, 0, 0.25) !important;
    `,
    darkInput: css`
        font-family: 'Roboto', sans-serif;
        color: #ffffff !important;
    `,
    lightInput: css`
        font-family: 'Roboto', sans-serif;
        color: #000000 !important;
    `,
    darkBorder: css`
        border-bottom-color: rgba(255, 255, 255, 0.5) !important;
        &:before {
            border-bottom-color: rgba(255, 255, 255, 0.5) !important;
        }
        &:after {
            border-bottom-color: rgba(255, 255, 255, 0.9) !important;
        }
    `,
    errorBorder: css`
        border-bottom-color: rgba(255, 119, 98, 0.3) !important;
        &:before {
            border-bottom-color: rgba(255, 119, 98, 0.3) !important;
        }
        &:after {
            border-bottom-color: rgba(255, 119, 98, 0.8) !important;
        }
    `,
    errorShake: css`
        animation: ${shake} 1s ease;
    `
};

export const checkboxStyles = {
    darkField: css`
        font-family: 'Roboto', sans-serif;
        font-weight: normal !important;
        font-size: 10px !important;
        color: white !important;
    `,
    lightField: css`
        font-family: 'Roboto', sans-serif;
        font-weight: normal !important;
        font-size: 10px !important;
        color: black !important;
    `,
    disabledField: css`
        font-family: 'Roboto', sans-serif;
        font-size: 10px !important;
        color: rgba(255, 255, 255, 0.5) !important;
    `,
    checked: css`
        color: #a7da7b !important;
    `,
    unchecked: css`
        color: rgba(255, 255, 255, 0.5) !important;
    `
};

export const fieldStyles = {
    formField: css`
        display: flex;
        font-family: Open Sans !important;
        font-size: 16px !important;
        flex-direction: column;
        margin-bottom: 1.5em;
        color: rgba(255, 255, 255, 0.5) !important;
    `,
    fieldLabel: css`
        top: -12px !important;
        color: white !important;
        padding-left: 23px !important;
    `,
    fileInputContainer: css`
        display: flex;
        align-items: center;
        height: 64px;
        width: 353px;
        background: #2b3c4b;
    `,
    fileInput: css`
        position: absolute;
        height: 56px;
        width: 353px;
        opacity: 0;
        margin-left: auto;
        margin-right: auto;
        z-index: 3;
        cursor: pointer;
    `,
    CustomfileInput: css`
        display: flex;
    `,
    fileButton: css`
        min-height: 32px !important;
        height: 32px !important;
        width: 136px !important;
        margin-left: 12px !important;
        margin-right: 16px !important;
        font-size: 12px !important;
        color: #374f63 !important;
        background-color: #ffffff !important;
        cursor: pointer !important;
    `,
    labels: css`
        padding-left: 16px;
        display: flex;
        align-items: center;
        width: 340px;
    `,
    labelsDark: css`
        padding-left: 16px;
        display: flex;
        align-items: center;
        opacity: 0.5;
    `,
    noFileLabel: css`
        padding-left: 8px;
        font-style: italic;
        width: 142px;
    `,
    fileLabel: css`
        padding-left: 8px;
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    `,
    warningMsgContainer: css`
        background-color: rgba(255,255,255,0.05);
        border-radius: 0 3px 3px 0 0 0 0;
        padding-left: 18px;
        height: 40px;
        width: 335px;
        line-height: 16px;    
    `,
    warningMsg: css`
        font-size: 12px;
        padding-left: 8px;
        color: #FF6B57;
    `
};
