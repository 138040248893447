import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, DialogTitle, TextField } from '@mui/material';
import { usePatch } from '../../hooks/useRest';
import { useMcaRuns } from '../../hooks/useMcaRuns';
import { urlBuilders } from '../../js/constants';

import { styles } from './SelectedMcaModelNotes.styles.js';

export const SelectedMcaModelNotes = ({ modelId, handleCancel }) => {
    const { selectedMcaRun = {} } = useMcaRuns();
    const {
        id,
        notes = ''
    } = selectedMcaRun;

    const [{ loading }, updateNotes] = usePatch({
        url: urlBuilders.updateMcaRun(id),
        manual: true
    });

    const { register, watch, handleSubmit, formState: { isSubmitting } } = useForm();

    const watchNotes = watch('notes', notes);
    const isDirty = watchNotes !== notes;

    const submitHandler = async ({ notes='' }) => {
        await updateNotes({
            data: {
                notes
            }
        });
        handleCancel();
    };

    const disabled = !id || loading || !isDirty || isSubmitting;
    const submitButtonStyle = disabled ? styles.button : `${styles.button} ${styles.submitButton}`;
    const dialogStyle = loading ? `${styles.dialogStyle} ${styles.waitCursor}` : styles.dialogStyle;

    return (
        <div className={dialogStyle}>
            <div>
                <DialogTitle classes={{ root: styles.title }}>Selected Model #{modelId}</DialogTitle>
            </div>
            <form onSubmit={handleSubmit(submitHandler)}>
                <TextField
                    name='notes'
                    label='Notes'
                    sx={{ width: '100%' }}
                    multiline
                    rows={4}
                    inputProps={{
                        ref: register
                    }}
                    InputLabelProps={{
                        shrink: !!watchNotes
                    }}
                    defaultValue={notes}
                />
                <div className={styles.buttonContainer}>
                    <Button
                        variant='contained' className={styles.button} onClick={handleCancel}>
                        CANCEL
                    </Button>
                    <Button
                        style={{marginLeft: '20px'}}
                        variant='contained' disabled={disabled} className={submitButtonStyle} type='submit'>
                        SAVE
                    </Button>
                </div>
            </form>
        </div>
    );
}
