import React from 'react';
import {useUserRoleFromGroup} from '../../hooks/useUserRoleFromGroup';
import {CampaignManagementContainer} from './CampaignManagementContainer';
import {ModalLV} from '../../components/modal/ModalLV';
import {AddNewCampaign} from './AddNewCampaign';
import {FeatureLV} from '../../components/pages/FeatureLV';
import {useGet} from '../../hooks/useRest';
import {urlBuilders} from '../../js/constants';
import { R1Button } from '@rainagency/rain-one-soggy-loaf';
import { Add } from '@mui/icons-material';

export const CampaignSelection = () => {
  const {isRegular, isTrial} = useUserRoleFromGroup();

  const url = urlBuilders.getAllPartners();
  const [{
    data,
    loading
  }, refetch] = useGet({url});

  let CallToAction = () => {
    return (
      <ModalLV minWidth={700} minHeight={700}>
        <R1Button
          data-test='campaignPage-headerButton'
          startIcon={<Add/>}
          loading={loading}
          color='secondary'>
          New
        </R1Button>

        <AddNewCampaign
          refetch={refetch}
        />
      </ModalLV>
    );
  };

  if (isRegular || isTrial) {
    CallToAction = () => null;
  }

  return (
    <FeatureLV
      sx={{
        '& .feature-children': {
          overflowY: 'clip'
        }
      }}
      title='Campaign Selection'
      description='Select or create a campaign'
      CallToAction={<CallToAction/>}
    >

      <CampaignManagementContainer
        data={data}
        loading={loading}
        refetch={refetch}
      />

    </FeatureLV>
  );
};
