import {css} from 'emotion';

export const styles = {
  content: css`
    width: 100%;

    .row {
      display: flex;
      padding: 12px 0;
    }

    .column1 {
      flex-grow: 1;
      padding-right: 24px;
    }

    .column2 {
      width: 380px;
      table {
        width: 380px;
      }
    }

    .button-row {
      justify-content: flex-start;
    }

    .spend {
      width: 120px;
    }

    .input {
      width: 100px;
      white-space: nowrap;
    }
  `,
  innerTables: css`
    flex: 1 2;
  `
};
