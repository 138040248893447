import React from 'react';
import {GraphIconOption} from './GraphIconOption';

export const OptionStackedBar = ({children}) => {
  return(
    <GraphIconOption>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="icon/ stackedbar">
          <g id="Group 39">
            <path id="icon/av/equalizer_24px" fillRule="evenodd" clipRule="evenodd" d="M12 20C13.1 20 14 19.1 14 18V6C14 4.9 13.1 4 12 4C10.9 4 10 4.9 10 6V18C10 19.1 10.9 20 12 20ZM6 20C7.1 20 8 19.1 8 18V14C8 12.9 7.1 12 6 12C4.9 12 4 12.9 4 14V18C4 19.1 4.9 20 6 20ZM16 18.1818V11.8182C16 10.8182 16.9 10 18 10C19.1 10 20 10.8182 20 11.8182V18.1818C20 19.1818 19.1 20 18 20C16.9 20 16 19.1818 16 18.1818Z" fill="#4F4F4F"/>
            <rect id="Rectangle 9" x="16" y="4" width="4" height="4" rx="1.9" fill="#4F4F4F"/>
            <rect id="Rectangle 10" x="4" y="4" width="4" height="6" rx="1.9" fill="#4F4F4F"/>
          </g>
        </g>
      </svg>
      {children}
    </GraphIconOption>
  );
};
