import React from 'react';
import {TableLV} from '../../../components/data-table/TableLV';
import {OptimalRange} from '../cells/OptimalRange';
import {PricingStructure} from '../cells/PricingStructure';
import {EditScenarioCell} from '../cells/EditScenarioCell';
import {getDummyData} from '../../../components/data-table/getDummyData';
import _ from "lodash";
import {EditScenarioCostPer} from '../cells/EditScenarioCostPer';
import {MaxObserved} from "../cells/MaxObserved";

export const MediaVariablesTable = ({
  data=[],
  nestedKpis={},
  loading=false,
  errors,
  register=()=>{},
  selectedRegion
}) => {
  const [costPer, setCostPer] = React.useState({});

  const grouped = data.reduce((unique, o) => {
    if(!unique.some(obj => obj.tactic === o.tactic)) {
      unique.push(o);
    }
    return unique;
  },[]);

  let unit = React.useMemo(()=>filterNonMedia(grouped, loading), [grouped, loading]);
  const hasData = !!unit.length;

  const setCost = (event={target: {}}) => {
    const tactic = event.target.name;
    const value = event.target.value;
    if (tactic) {
      setCostPer({...costPer, [tactic]: value});
    }
  };

  if(!unit.length) {
    unit = getDummyData(dummyUnitSchema, 4);
  }

  const override = {
    variableName:  {
      hidden: true
    },
    variableTactic:  {
      name: 'Media Variables'
    },
    optimalRange: {
      hidden: selectedRegion === "ALL REGIONS",
      component: <OptimalRange nestedKpis={Object.keys(nestedKpis)} costPer={costPer}/>
    },
    pricingStructure: {
      component: <PricingStructure/>
    },
    costPer: {
      name: 'Cost Per',
      isEditable: true,
      headerClassName: 'spend',
      component: (
        <ScenarioCostPerInput
          setCost={setCost}
          costPer={costPer}
          errors={errors}
          register={register}/>
      )
    },
    scenario1Spend: {
      name: 'Total Spend',
      isEditable: true,
      headerClassName: 'spend',
      component: (
        <EditScenarioCell
          nameSuffix='_volume_scenario1'
          nestedKpis={nestedKpis}
          isValidateObservedRange={false}
          isCurrency={true}
          errors={errors}
          register={register}
        />
      )
    },
    scenario2Spend: {
      name: 'Total Spend',
      isEditable: true,
      headerClassName: 'spend',
      component: (
        <EditScenarioCell
          nameSuffix='_volume_scenario2'
          nestedKpis={nestedKpis}
          isValidateObservedRange={false}
          isCurrency={true}
          errors={errors}
          register={register}
        />
      )
    },
    minOptimal: {
      hidden: true
    },
    maxOptimal: {
      hidden: true
    },
    minObserved: {
      hidden: true
    },
    maxObserved: {
      name: 'Max Observed',
      hidden: selectedRegion === "ALL REGIONS",
      component: <MaxObserved nestedKpis={Object.keys(nestedKpis)} costPer={costPer}/>
    },
    measure: {
      hidden: true
    }
  };

  const headerGroups = [{
    name: 'Scenario 1',
    columns: ['scenario1Spend']
  }, {
    name: 'Scenario 2',
    columns: ['scenario2Spend']
  }];

  return (
    <TableLV
      data={unit}
      hasData={hasData}
      isLoading={loading}
      override={override}
      groups={headerGroups}
      disableTableSort={true}
      dataTest='media-variables'
    />
  );
};

const filterNonMedia = (media, loading) => {
  const NOT_MEDIA_MEASURES = ['Binary', 'Control', 'Base'];
  if(loading) {
    return [];
  }
  return media.filter(d=>!NOT_MEDIA_MEASURES.includes(d.measure))
    .map(d=>({
      variableName: d.variableName,
      variableTactic: d.tactic,
      pricingStructure: '',
      costPer: d.costPer,
      optimalRange: '',
      scenario1Spend: d.scenario1Value,
      scenario2Spend: d.scenario2Value,
      minOptimal: d.minOptimal,
      maxOptimal: d.maxOptimal,
      minObserved: d.minObserved,
      maxObserved: Math.floor(d.maxObserved),
      measure: d.measure
    }));
};

const dummyUnitSchema = {
  variableName: 'Facebook Prospecting',
  variableTactic: 'Facebook',
  pricingStructure: 'CPM',
  costPer: 2.51,
  optimalRange: '',
  scenario1Spend: 136319,
  scenario2Spend: 175000
};

export const ScenarioCostPerInput = (({cell, setCost, costPer, errors, register}) => {
  const name = _.get(cell, 'row.values.variableTactic') + '_price';
  if(!name){
    return null;
  }

  return (<EditScenarioCostPer
    cell={cell}
    setCostPer={setCost}
    costPer={costPer[name]}
    errors={errors}
    register={register}
  />);
});
