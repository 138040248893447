import React from 'react';
import {useGet} from './useRest';
import {urlBuilders} from '../js/constants';
import _ from 'lodash';
import {useCampaignManagement} from './useCampaignManagement';

/**
 * Get all uploaded files
 * @param fileType Optional filter to provide one file object
 * @returns allUploadedFiles All uploaded files to the system
 * @returns loading REST call status
 * @returns refetchUploadedFiles Refetch list from back-end
 * @returns selectedFile File found by file type
 */
export const useGetUploadedFiles = (fileType) => {
  const {campaignId} = useCampaignManagement();

  const [{
    data, loading
  }, refetchFiles=()=>{}] = useGet({
    url: urlBuilders.getUploadedCampaignFilesMetaData(campaignId),
    manual: true
  });

  React.useEffect(() => {
    if(!campaignId) {
      return;
    }
    refetchFiles();
  }, [campaignId, refetchFiles]);

  const allUploadedFiles = _.get(data, 'data', []);
  const selectedFile = React.useMemo(
    () => findFileByType(allUploadedFiles, fileType),
    [allUploadedFiles, fileType]
  );

  return {
    allUploadedFiles,
    refetchFiles,
    loading,
    selectedFile: selectedFile || {}
  };
  
};

const findFileByType = (data, fileType) => {
  if(!data || !fileType) {
    return undefined;
  }
  return data.find(f => f['data_type'] === fileType);
};
