import React from 'react';
import {
    R1ActionIcon,
} from '@rainagency/rain-one-soggy-loaf';
import { urlBuilders } from '../../../js/constants';
import { useDelete } from '../../../hooks/useRest';
import { useSnackbarLV } from '../../../components/snackbar/useSnackbarLV';

/**
 * Delete an ad impact run
 * Displays on Data Grid Action Popover
 */
export const AdImpactDeleteActionIcon = ({
                                         cell,
                                         closePopover,
                                         hide,
                                         fetchAdImpacts
                                     }) => {

    const [{loading, response, error}, deleteAdImpact=()=>{}] = useDelete({
        url: urlBuilders.deleteAdImpactRun(cell.row.id)
    });

    const {
        enqueueSnackbar,
        enqueueCloseSnackbarLV,
    } = useSnackbarLV();

    // refresh table when response is returned
    React.useEffect(() => {
        if (!response) {
            return;
        }

        if(response.status >= 200 && response.status <= 299) {
            enqueueSnackbar(
                `Successfully deleted Ad Impact Analysis: ${cell.row.runName}`,
                {
                    variant: 'success'
                }
            );
        }

        closePopover();
        fetchAdImpacts();
    }, [response]);

    // Display error
    React.useEffect(() => {
        if(!error) {
            return;
        }

        enqueueCloseSnackbarLV(
            `Error deleting Ad Impact Analysis: ${cell.row.runName}, please contact Technical Support if this issue persists.`,
            {
                variant: 'error',
                persist: false,
                autoHideDuration: 10000
            }
        );

        closePopover();
    }, [error]);

    return (
        <R1ActionIcon
            iconType='Delete'
            label='Delete AdImpact'
            hide={hide}
            onDialogConfirm={deleteAdImpact}
            DialogProps={{
                title: `Delete ${cell.row.runName}?`,
                description: `Are you sure you want to delete ${cell.row.runName} forever?`,
                cancelLabel: 'Cancel',
                confirmLabel: 'Delete Forever',
                onCancel: closePopover,
                customStyles: {
                    cursor: loading ? 'wait' : 'pointer'
                }
            }}
        />
    );


};
