import React from 'react';
import {TAB_URL_KEY} from '../../hooks/useCampaignManagement';
import {ModalLV} from '../../components/modal/ModalLV';
import {FileTable} from './FileTable';
import {InputDataReview} from './InputDataReview';
import {FeatureLV} from '../../components/pages/FeatureLV';
import {CallToActionLV} from '../../components/tabs/CallToActionLV';
import {MultiFileUploadLV} from "../../components/file-upload/MultiFileUploadLV";
import {useCampaign} from "../../hooks/useCampaign";
import {SupersetDashboard} from "../../components/dashboards/SupersetDashboard";
import {styles} from "./ManageDataTab.styles";
import {useCampaignManagement} from '../../hooks/useCampaignManagement';
import {SupersetCreateChart} from "../../components/dashboards/SupersetCreateChart";
import {useGetUploadedFiles} from "../../hooks/useGetUploadedFiles";
import {FILE_TYPES} from "../../constants/file-types";

export const ManageDataTab = () => {
    const {id, supersetDashboardId, campaignName} = useCampaign() || {};
    const {dataTabIndex} = useCampaignManagement() || {};

    const {
        allUploadedFiles,
        refetchFiles,
        loading
    } = useGetUploadedFiles();

    const tableData = React.useMemo(() => {
        const acceptedFileTypes = [
            ...FILE_TYPES.map(type => type.value),
            'mediaGroupingTemplate'
        ];

        return allUploadedFiles.filter(file => acceptedFileTypes.includes(file.data_type));
    }, [allUploadedFiles])

    const uploadArgs = React.useMemo(() => {
        return {
            campaignId: id
        }
    }, [id])

    const tabs = [
        {
            label: 'Upload Data',
            content: (
                <FileTable
                    data={tableData}
                    loading={loading}
                    fetchFiles={refetchFiles}
                />
            ),
            cta: {
                title: 'Upload Data',
                component: MultiFileUploadLV
            }
        },
        {
            label: 'Input Data Review',
            content: <InputDataReview/>,
            cta: {
                title: 'Upload Data',
                component: MultiFileUploadLV
            }
        },
        {
            label: 'Custom Data Review',
            featureFlagKey: 'CUSTOM_DASHBOARDS',
            content: (
                <SupersetDashboard
                    dashboardId={supersetDashboardId}
                    className={styles.supersetDashboard}
                />
            ),
            cta: {
                title: 'Create Chart',
                component: SupersetCreateChart
            }
        }
    ];

    const cta = React.useMemo(() => {
        const tab = tabs[dataTabIndex || 0];

        if (!tab.cta || !tab.cta.component || !tab.cta.title) {
            return;
        }

        const Component = tab.cta.component;
        const Title = tab.cta.title;

        const ctaButton = (
            <CallToActionLV
                dataTest={'fileUpload-headerButton'}>
                {Title}
            </CallToActionLV>
        );

        return (
            <Component
                uploadArgs={uploadArgs}
                onComplete={() => {
                    setTimeout(refetchFiles, 1000);
                }}
                tableName={`'${id}'`}
                callToActionComponent={ctaButton}
            />
        )
    }, [dataTabIndex, uploadArgs]);

    return (
        <FeatureLV
            title='Manage Data'
            description='Upload and manage input data files'
            dataTest='uploaded-data-table-container'
            tabs={tabs}
            tabsUrlQueryKey={TAB_URL_KEY.DATA_DASHBOARD}
            tabHeightOffset={96}
            sx={{
                '& .uploaded-file-table': {
                    background: '#FFF',
                },
                '& .tab-body': {
                    background: '#FFF',
                }
            }}
            CallToAction={cta}
        />
    );
};