import React from 'react';
import checkMark from '../../assets/circle_check_mark.svg';
import crossMark from '../../assets/circle_cross_mark.svg';
import {styles} from './SuccessFailStatusModal.styles';

export const SuccessFailStatusModal = ({
  isError=false,
  title,
  description,
  children
}) => {
  const imgProps = {
    src: isError ? crossMark : checkMark,
    alt: isError ? 'crossmark' : 'checkmark',
  };

  return (
    <div className={styles.body}>
      <div>
        <img
          src={imgProps.src}
          alt={imgProps.alt}
          width={132}
          height={128}
        />
      </div>
      <div
        className={'title'}
        data-test='addNewComplete-title'
        hidden={!title}>
        {title}
      </div>
      <div
        className={'description'}
        hidden={!description}>
        {description}
      </div>

      {children}
    </div>
  );
};
