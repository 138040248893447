import React from 'react';
import {Route} from 'react-router-dom';
import { OstrowTab } from './OstrowTab';

export const MarketingTools = (props) => {
  const {match} = props;
  
  return (
    <div>
      <Route path={match.url + '/ostrow'} component={OstrowTab} />
    </div>
  );
};