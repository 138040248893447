import React from 'react';
import {urlBuilders} from '../js/constants';
import {useGetUploadedFiles} from '../hooks/useGetUploadedFiles';
import {useGet} from '../hooks/useRest';
import {FILE_TYPE_MAP} from '../constants/file-types';

/**
 * Get an uploaded CSV file in JSON format
 * @param fileType Uploaded file type (EX: kpi)
 * @returns uploaded csv file but as JSON
 */
export const useUploadedFileJson = (fileType=FILE_TYPE_MAP.KPI) => {
  const {
    selectedFile={},
    loading: isFilesLoading
  } = useGetUploadedFiles(fileType);

  const {isFileJsonLoading, fileJson} = useGetFileJson(selectedFile);

  return {
    isLoading: [isFilesLoading, isFileJsonLoading].some(s => s),
    fileJson
  };
};

/**
 * Uploaded CSV file as JSON
 * @param bucketName S3 bucket
 * @param location S3 Key
 * @returns S3 file converted to JSON
 */
export const useGetFileJson = ({bucket, location}) => {
  const uploadedJsonUrl = urlBuilders.getUploadedFileAsJson(
    bucket,
    location
  );

  const [{data=[], loading, error}, getFileJson] = useGet({
    url: uploadedJsonUrl,
    manual: true
  });

  React.useEffect(() => {
    if(!bucket || !location) {
      return;
    }
    getFileJson();
  }, [bucket, location, getFileJson]);
  
  return {
    isFileJsonLoading: loading,
    fileJson: data,
    fileErrors: error
  };
};
