import React from 'react';
import { 
  R1SubmitButton,
  R1Button
} from '@rainagency/rain-one-soggy-loaf';
import { useConfigAI } from './useConfigAI';
import { useFeatureSelect } from '../../../../hooks/useFeatureSelect';
import { FEATURES } from '../../../../constants/features';

export const SubmitContinue = ({
  analysisType,
  isLastStep,
  disabled,
  readOnly,
  skipSubmit,
  loading,
  analysisValues,
  configValues = {}
}) => {
  const { onFeatureSelect } = useFeatureSelect();
  const { startConfigAI, configAILoading, configAIError } = useConfigAI({analysisValues, configValues});

  if (analysisType === 'ConfigAI') {
    const errorMsg = configAIError ? 'Something went wrong with the ConfigAI run. Please contact tech support' : '';
    return (
      <div>
        <div style={{position: 'absolute', left: '300px', width: '100%'}}>{errorMsg}</div>
        <R1Button
          data-testid={'new-analysis-continue-button'}
          size='large' 
          loading={ configAILoading }
          disabled={ disabled }
          onClick={ startConfigAI } >
            SUGGEST WITH ConfigAI
        </R1Button>
      </div>
    );
  }

  //Handling submit button for MMM and AdImpact features ReadOnly/Submit
  const handleClick = () => {
    if (analysisType === 'AdImpact MMM') {
      onFeatureSelect({feature: FEATURES.ADIMPACT_ANALYSIS});
    } else {
      onFeatureSelect({feature: FEATURES.MMM_ANALYSIS});
    }
  };

  if (analysisType === 'AdImpact MMM' && readOnly || skipSubmit) {
    return (
      <R1Button
        data-testid={'new-analysis-continue-button'}
        size='large' 
        onClick={ handleClick }>
          GO TO ANALYSIS PAGE
      </R1Button>
    );
  }

  let buttonLabel;
  if (analysisType === 'AdImpact MMM' || isLastStep) {
    buttonLabel = 'RUN ANALYSIS';
  } else {
    buttonLabel = 'CONTINUE';
  }

  return (
    <R1SubmitButton
      data-testid={'new-analysis-continue-button'}
      size='large' 
      disableIfClean={ false }
      disabled={ disabled }
      loading={ loading }>
        {buttonLabel}
    </R1SubmitButton>
  );
}
