import {urlBuilders} from './constants.js';
import {getData} from './server.js';

export const checkQueryStatus = (token, baseUrl, queryId) => {
    return new Promise((resolve, reject) => {
        const checkStatusInterval = setInterval(() => {
            getData(token, baseUrl + urlBuilders.getQueryStatus(queryId)).then(
                (res) => {
                    if (res.status === 'Success' || res.status === 'Error') {
                        clearInterval(checkStatusInterval);
                        resolve(res.status);
                    }
                }
            );
        }, 1000); // 1 sec interval
    });
};
