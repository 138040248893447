export const LEAVENED = {
  label: "System",
  value: ""
};

export const PARTNER = {
  label: "Partner",
  value: "Partner_"
};

export const CLIENT = {
  label: "Client",
  value: "Company_"
};

export const TYPES = {LEAVENED, PARTNER, CLIENT};

export const isTopLevel = (types) => {
  if (!Array.isArray(types) || !types.length) {
    return false;
  }
  return !isPartnerLevel(types) && !isClientLevel(types);
};

// Partner Level displays on Partner Admin or Client Level
export const isPartnerLevel = (types) => {
  if (!Array.isArray(types) || !types.length) {
    return false;
  }
  return !!(types?.find(type => type?.startsWith(PARTNER.value)));
};

export const isClientLevel = (types) => {
  if (!Array.isArray(types) || !types.length) {
    return false;
  }
  return !!(types?.find(type =>type?.startsWith(CLIENT.value)));
};
