import {css} from 'emotion';

export const styles = {
    buttonLV: css`
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        text-align: center;
        letter-spacing: 0.75px;
        text-transform: uppercase;
        border-radius: 200px;
        height: 36px;
        line-height: 0px;
        border: 0;
        cursor: pointer;
        background: #f2f2f2;
        min-width: 145px;
        color: #304658;
        outline: none;
    `,
    darkGrey: css`
        background: #304658;
        min-width: 145px;
        color: #f2f2f2;
        outline: none;

        path {
          fill: #f2f2f2;
        }

        &.inverted {
          background: transparent;
          border: 1px solid #304658;
          color: #304658;
          font-weight: 500;

          path {
            fill: #304658;
          }
        }
    `,
    grey: css`
        background: #bdbdbd;
        min-width: 208px;
        color: #fff;
        outline: none;

        path {
          fill: #fff;
        }

        &.inverted {
          background: transparent;
          border: 2px solid #bdbdbd;
          color: #bdbdbd;
          font-weight: 500;

          path {
            fill: #bdbdbd;
          }

        }
    `,
    orange: css`
        background: #ff6b57;
        min-width: 145px;
        color: #f2f2f2;
        outline: none;

        path {
          fill: #f2f2f2;
        }

        &.inverted {
          background: transparent;
          border: 2px solid #ff6b57;
          color: #ff6b57;
          font-weight: 500;

          path {
            fill: #ff6b57;
          }
        }
        
        &.configAIButton {
          text-transform: none !important;
        }
    `,
    green: css`
        background: #a9d980;
        min-width: 68px;
        color: #fff;
        outline: none;

        path {
          fill: #fff;
        }

        &.inverted {
          background: transparent;
          border: 2px solid #a9d980;
          color: #a9d980;
          font-weight: 500;

          path {
            fill: #a9d980;
          }
        }
    `,
  transparent: css`
        background: inherit;
        min-width: 68px;
        color: #BDBDBD;
        outline: none;

        path {
          fill: #BDBDBD;
        }
    `,
    icon: css`
        background: none;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: auto;
    `,
    disabled: css`
        opacity: 0.5;
        cursor: auto;
    `,
    submitting: css`
        cursor: wait !important;
    `,
    text: css`
        padding: 0 4px 0 4px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        column-gap: 10px;
    `
};
