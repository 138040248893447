import {css} from 'emotion';
import {commonStyles} from '../../styles/commonStyles';

export const styles = {
  tabRoot: css`
    ${commonStyles.fontFamily}
    opacity: 1 !important;
    font-size: 13px;
    min-width: 160px;
    &.MuiButtonBase-root {
      color: #ffffff;
      text-transform: capitalize !important;
      font-weight: 400;
      letter-spacing: 0.02857em;
    }
  `,
  tabsIndicator: css`
    background-color: #ff7762 !important;
    height: 4px !important;
    border-radius: 20px;
  `,
  tabSelected: css`
    color: #ff7762;
    &.MuiButtonBase-root {
      color: #ff7762 !important;
    }
  `,
  spacer: css`
    background-color: #FFF !important;
    height: 5px;
    background: linear-gradient(180deg, rgba(51, 51, 51, 0.4) 0%, rgba(51, 51, 51, 0) 100%);
  `,
};

export const lightStyles = {
  tabRoot: css`
    ${commonStyles.fontFamily}
    opacity: 1 !important;
    font-size: 13px;
    min-width: 154px;
    &.MuiButtonBase-root {
      color: #828282;
      text-transform: uppercase;
      font-weight: 400;
      letter-spacing: 0.02857em;
    }
  `,
  tabsIndicator: css`
    background-color: #ff7762 !important;
    height: 4px !important;
    border-radius: 20px;
    z-index: 1 !important;
  `,
  tabSelected: css`
    color: #ff7762;
    &.MuiButtonBase-root {
      color: #ff7762 !important;
    }
  `,
  spacer: css`
    border-bottom: 4px solid #F2F2F2;
    margin-top: -4px;
  `,
};

export const container = css`
  width: 100%;
`;

export const base = css`
  &::after {
    content: '';
    background: linear-gradient(180deg, rgba(51, 51, 51, 0.4) 0%, rgba(51, 51, 51, 0) 100%);
    position: absolute;
    height: 5px;
    width: 100%;
    z-index: 1;
  }
`;

export const spaceFillerDiv = css`
  width: 100%;
  height: 30px !important;
`;
