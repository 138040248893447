import React from 'react';
import Image from "../../images/Image";

const ImageMessage = ({
    payload: {
        imageUrl
    } = {},
                      }) => {

    const image = React.useMemo(() => {
        if (imageUrl) {
            return (
                <Image imageUrl={imageUrl} alt={''} />
            );
        }
    }, [imageUrl]);

    return (
        <div>
            {image}
        </div>
    );
};

export default ImageMessage;