import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import {styles} from './ModalLV.styles';
import imageX from '../../../src/assets/clear_24px.png';
import darkImageX from '../../../src/assets/dark_x_24px.svg';

/**
 * Modal used for Leavened based off of Material UI Modal
 * https://material-ui.com/components/modal/#simple-modal
 * 
 * First Child will have onClick event added to it which opens Modal
 * Other Children will be sent a `props.closeHandler` method it can use
 *   to close the Modal
 */
export default function ModalLV({
  isOpen=false,
  onOpen=()=>{},
  onClose=()=>{},
  isDark=true,
  minWidth,
  minHeight,
  disableBackdropClick=true,
  ...props
}) {
  const [open, setOpen] = React.useState(isOpen);

  // Refresh open state from parent
  React.useEffect(() => {
    setOpen(isOpen);
    }, [isOpen]);

  if(!props.children) {
    return(<EmptyModal/>);
  }

  const handleOpen = () => {
    setOpen(true);
    onOpen();
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick' || !disableBackdropClick) {
      setOpen(false);
      onClose();
    }
  };

  let children = props.children;
  if(!Array.isArray(children)) {
    children = [children];
  }

  const triggerOverride = {onClick: handleOpen};
  const childProp = {
    closeModal: handleClose,
    key: 0
  };

  // Add/overwrite the onClick event on the first child element
  const firstElem = children[0];
  const modalTriggerEl = React.cloneElement(firstElem, triggerOverride);

  // Add closeModal function to all other children
  const otherElems = children.slice(1, children.length).map((child, index) => {
    childProp.key = index;
    return React.cloneElement(child, childProp);
  });

  let modalClassName = styles.modal;
  let bodyClassName = styles.body;
  const customStyle = getModalStyle();
  if(props.isFullscreen) {
    modalClassName += ` ${styles.modalFull}`;
    bodyClassName += ` ${styles.bodyFull}`;
  }

  if (props.className) {
    modalClassName = [modalClassName, props.className].join(' ');
  }

  let backDrop;
  if(!isDark) {
    modalClassName = [modalClassName, 'light'].join(' ');
    backDrop = {className: styles.backdrop};
  }

  if(minHeight  && !isNaN(minHeight)) {
    customStyle.minHeight = `${minHeight}px`;
  }

  if(minWidth && !isNaN(minWidth)) {
    customStyle.minWidth = `${minWidth}px`;
  }

  return (
    <div>
      {modalTriggerEl}
      <Modal
        BackdropProps={backDrop} open={open} onClose={handleClose}>
        <div className={modalClassName} style={customStyle}>
  
          <img
            src={isDark ? imageX : darkImageX}
            alt='Close'
            className={styles.x}
            onClick={handleClose}
            data-test='modal-trigger-close' />

          <div className={bodyClassName}>
            {otherElems}
          </div>
        </div>
      </Modal>      
    </div>
  );
}

export const EmptyModal = () => <div data-test='no-modal'/>;

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

ModalLV.propTypes = {
  /**
   * Display a Fullscreen version of the Modal
   */
  isFullscreen: PropTypes.bool
};

export {ModalLV};
