import {css} from 'emotion';

export const styles = {
  tabRoot: css`
    background: transparent !important;
    .MuiTabs-root {
      background: #FFFFFF
    };
  `,
  tabContent: css`
    background-color: #F2F2F2;
    display: block;
    border-radius: 0 0 20px 20px;
    width: 100%;
    height: 650px;
    overflow-x: auto; 
  `,
  buttonContainer: css`
    display: flex;
    flex-direction: column;
    margin-top: 48px;
    align-items: center;
  `,
  spinnerContainer: css`
    display: flex;
    padding-top: 100px;
    justify-content: center;
  `,
  infoMessage: css`
    margin-bottom: 24px; 
  `
};
