import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '@mui/material/Tooltip';

import {filterRange} from '../../../../js/validation.js';

import FilterChip from '../../../../components/chip';

import {styles} from './mca-models-chips.styles.js';

class McaFilterChips extends React.Component {
    createChips() {
        let filters = this.props.filters || {};
        let filterText = [];
        if (filters.intercept) {
            filterText.push({field: 'intercept', text: 'Intercept ≥ 0'});
        }

        //create chip if filter is not set to full range
        if(filters.confidence) {
            filters.confidence = Number(filters.confidence.toString().replace('%', ''));
            if (filters.confidence > filterRange.minConfidence) {
                let confidenceExceptionsText = '';
                if (filters.confidenceExceptions && filters.confidenceExceptions.length) {
                    confidenceExceptionsText = ' ~ Excluding: ' + filters.confidenceExceptions.toString();
                }
                let sign = '≥ ';
                if (filters.confidence === 100) {
                    sign = '= ';
                }
                filterText.push({field: 'confidence', text: 'Confidence ' + sign + filters.confidence + '%' + confidenceExceptionsText});
            }
        }
        if(filters.rsq) {
            filters.rsq = Number(filters.rsq.toString().replace('%', ''));
            if (filters.rsq > filterRange.minRsq) {
                if (filters.confidence === 100) {
                    filterText.push({field: 'rsq', text: 'R Squared = 100%'});
                } else {
                    filterText.push({field: 'rsq', text: 'R Squared ≥ ' + filters.rsq + '%'});
                }
            }
        }
        if ((filters.minDurbinWatson || filters.maxDurbinWatson) && 
            (filters.minDurbinWatson > filterRange.minDurbinWatson || filters.maxDurbinWatson < filterRange.maxDurbinWatson)) {
                filterText.push({field: 'durbinWatson',
                    text: 'Durbin Watson ' + (filters.minDurbinWatson || filterRange.minDurbinWatson.toFixed(2))
                    + '-' + (filters.maxDurbinWatson || filterRange.maxDurbinWatson.toFixed(2))});
        }
        

        let negativeDrivers = '';
        for (let variable in filters.negativeDrivers) {
            negativeDrivers += filters.negativeDrivers[variable] + ", ";
        }
        negativeDrivers = negativeDrivers.substring(0, negativeDrivers.length -2);
        if (negativeDrivers.length > 0) {
            filterText.push({field: 'negativeDrivers', text: 'Negative Drivers: ' + negativeDrivers});
        }

        let positiveDrivers = '';
        for (let variable in filters.positiveDrivers) {
            positiveDrivers += filters.positiveDrivers[variable] + ", ";
        }
        positiveDrivers = positiveDrivers.substring(0, positiveDrivers.length -2);
        if (positiveDrivers.length > 0) {
            filterText.push({field: 'positiveDrivers', text: 'Positive Drivers: ' + positiveDrivers});
        }

        let mandatoryVariables = '';
        for (let variable in filters.mandatoryVariables) {
            mandatoryVariables += filters.mandatoryVariables[variable] + ", ";
        }
        mandatoryVariables = mandatoryVariables.substring(0, mandatoryVariables.length -2);
        if (mandatoryVariables.length > 0) {
            filterText.push({field: 'mandatoryVariables', text: 'Mandatory Variables: ' + mandatoryVariables});
        }
        
        let excludeVariables = '';
        for (let variable in filters.excludeVariables) {
            excludeVariables += filters.excludeVariables[variable] + ", ";
        }
        excludeVariables = excludeVariables.substring(0, excludeVariables.length -2);
        if (excludeVariables.length > 0) {
            filterText.push({field: 'excludeVariables', text: 'Excluded Variables: ' + excludeVariables});
        }

        for (let key in filters) {
            if (filters[key] && (key.toString() === filters[key].toString())) {
                filterText.push({field: key, text: key});
            }
        }

        if (filters.variableGroups && filters.variableGroups.length) {
            filterText.push({field: 'variableGroups', text: 'Variable Groups: ' + filters.variableGroups.length + ' Filtered Group(s)'});
        }

        return filterText.map((filter, index) => {
                const isError = this.props.errors.find((error)=> error.field === filter.field) || false;
                const variant = isError ? 'outlined' : 'default';
                const tooltip = isError ? 'Invalid Entry' : '';
                return  <span key={index}>
                            <Tooltip title={tooltip}><span className={styles.filterChip}>
                                <FilterChip 
                                    variant={variant} 
                                    label={filter.text} 
                                    isError={isError}
                                />
                                </span>
                            </Tooltip>
                        </span>
            });
    }

    render() {
        return <div data-test='chips-mcaModelsComparison' className={styles.chipContainer}>{this.createChips()}</div>
    }
}

McaFilterChips.propTypes = {
    filters: PropTypes.object,
    errors: PropTypes.array
};

export default McaFilterChips;
