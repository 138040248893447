import React from 'react';

import {ScoringDashboardContext} from "../ScoringDashboardContext";
import {CHART_FORMAT} from "../../../components/charts/ChartFormat.constant";
import {GraphConfig} from "../../../components/dashboards/GraphConfig";
import {GRAPH_OPTIONS} from "../../../components/dashboards/graph-options.constant";
import {Chiclet} from "../../../components/dashboards/Chiclet";
import {ChicletTitle} from "../../../components/dashboards/ChicletTitle";
import {GraphContainerLV} from "../../../components/dashboards/GraphContainerLV";
import {useScoringFitChart} from "./hooks/useScoringFitChart";

    export const ScoringFitChart = () => {
    const {
        selectedMcaRun,
        selectedScoringRun,
        startDate,
        endDate
    } = React.useContext(ScoringDashboardContext);

    const {kpiType, id: mcaRunId} = selectedMcaRun;

    const {
        fit,
        isLoading
    } = useScoringFitChart({
        mcaRunId,
        scoringId: selectedScoringRun?.id,
        filters: {
            startDate,
            endDate
        }
    })

    let fitFormatMap = {};
    if(kpiType === 'Units') {
        const fitSchema = fit?.length ? fit[0] : {};
        const yAxisKeys = Object.keys(fitSchema).filter(key =>  key !== 'date');
        yAxisKeys.forEach(key => {
            fitFormatMap[key] = CHART_FORMAT.INTEGER;
        });
    }

    let referenceLine = undefined;
    const referenceDate = selectedScoringRun?.startDate || selectedMcaRun?.endDate;
    if(referenceDate) {
        referenceLine = {
            x: referenceDate,
            label: referenceDate
        };
    }

    const totalConfig = new GraphConfig({
        data: fit,
        graphOptions: [
            GRAPH_OPTIONS.LINE,
        ],
        xAxisKey: 'date',
        yAxisLabel: 'Total ' + kpiType,
        yAxisKey: 'actual',
        formatMap: fitFormatMap,
        referenceLine,
        isLoading,
        csvFileName: 'model_fit',
        imageFileName: 'Total' + kpiType,
        imageElementId: 'model-statistics-chiclet-id',
        isBroadcastDates: false,
        confidenceInterval: {lower: "ciLower", upper: "ciUpper"}
    });


    return (
        <Chiclet id='model-statistics-chiclet-id'>
            <ChicletTitle>Scored Model Fit</ChicletTitle>

            <GraphContainerLV
                graphConfig={totalConfig}
            />

        </Chiclet>
    )
}