import React from 'react';
import {TableLV} from '../../components/data-table/TableLV';
import {HeaderSummary} from '../../components/dashboards/HeaderSummary';
import {roundString} from '../../js/utils.js';
import {getDummyData} from '../../components/data-table/getDummyData';
import {ChicletLV} from '../../components/chiclet/ChicletLV';
import {NumericCell} from '../../components/data-table/cells/NumericCell';
import {GRAPH_OPTIONS} from "../../components/dashboards/graph-options.constant";
import {GraphConfig} from "../../components/dashboards/GraphConfig";
import {GraphContainerLV} from "../../components/dashboards/GraphContainerLV";

export const ScenarioResultCharts = ({
  data=[],
  kpiType='',
  loading=false,
  timeScale
}) => {

  const hasData = (Array.isArray(data) && data.length > 0);
  const timeScaleLabel = timeScale ? timeScale.charAt(0).toUpperCase() + timeScale.slice(1).toLowerCase() : 'Daily';
  
  let totalSpend = 0;
  let totalRevenue = 0;

  let projectedLabel = 'Projected Revenue';
  let isCurrency = true;
  const alignColumn = 'center';

  if(kpiType === 'Units') {
    projectedLabel = 'Projected Units';
    isCurrency = false;
  }

  if(hasData) {
    totalSpend = data.map(row =>
      parseFloat(row.spend)).reduce((prev, next) =>
        prev + next) || 0;
    totalRevenue = data.map(row => 
      parseFloat(row.revenue)).reduce((prev, next) =>
        prev + next) || 0;
    data.forEach(row => {
      if (kpiType === 'Revenue') {
        delete row['cpa'];
      } else {
        delete row['roi'];
      }
    });
  }
  else{
    data = getDummyData(tableDefaultRow, 4);
  }

  let spendGraphData = [];
  if(totalSpend > 0){
    spendGraphData = data.map(row => {
      const name = row.tactic;
      const value = parseFloat(row.spend);
      const percentage = (value/totalSpend)*100;
      return {name, value, percentage};
    });
  }

  let contributionGraphData = [];
  let contributionGraphOptions = [
    GRAPH_OPTIONS.PIE,
    GRAPH_OPTIONS.WATERFALL
  ];

  if(totalRevenue){
    contributionGraphData = data.map(row => {
      const name = row.tactic;
      const value = parseFloat(row.revenue);
      const percentage = (value/totalRevenue)*100;
      return {name, value, percentage};
    });
  }

  const hasNegative = contributionGraphData.some(row => row.value < 0);
  if(hasNegative){
    contributionGraphOptions = [
      GRAPH_OPTIONS.WATERFALL
    ];
  }

  const spendConfig = new GraphConfig({
    data: spendGraphData,
    graphOptions: [
      GRAPH_OPTIONS.PIE,
      GRAPH_OPTIONS.WATERFALL
    ],
    xAxisLabel: 'Tactic',
    yAxisLabel: 'Spend',
    xAxisKey: 'name',
    yAxisKey: 'value',
    isLoading: loading
  });

  const contributionConfig = new GraphConfig({
    data: contributionGraphData,
    graphOptions: contributionGraphOptions,
    xAxisLabel: 'Tactic',
    yAxisLabel: 'Contribution',
    xAxisKey: 'name',
    yAxisKey: 'value',
    isLoading: loading
  });

  const totalRoi = (totalRevenue / (totalSpend || 1)) || 0;
  const totalCpa = (totalSpend / (totalRevenue || 1)) || 0;

  let totals = [
    { label: 'Total Spend', value: roundString(totalSpend, 2), format: 'currency' },
    { label: 'Projected Revenue', value: roundString(totalRevenue, 2), format: 'currency' },
    { label: 'ROI', value: roundString(totalRoi, 2), format: '' }
  ];

  if (kpiType !== 'Revenue') {
    totals.pop();
    totals.pop();
    totals.push({ label: 'Total Units', value: roundString(totalRevenue, 0), format:'unit' });
    totals.push({ label: 'CPA', value: roundString(totalCpa, 2), format:'currency' });
  };

  const override = {
    tactic: {
      name: 'Marketing Tactic',
      align: 'left'
    },
    spend: {
      name: `${timeScaleLabel} Spend`,
      align: alignColumn,
      headerStyle: {
        width: '150px'
      },
      component: <NumericCell
                   isCurrency={isCurrency}
                   decimals={0}
                   isInline={true}
                   align={alignColumn}
                 />
    },
    revenue: {
      name: projectedLabel,
      align: alignColumn,
      headerStyle: {
        width: '150px'
      },
      component: <NumericCell
                   isCurrency={isCurrency}
                   decimals={0}
                   isInline={true}
                   align={alignColumn}
                 />
    },
    roi: {
      name: 'ROI',
      align: alignColumn,
      headerStyle: {
        width: '150px'
      },
      component: <NumericCell
                   isCurrency={!isCurrency}
                   center={true}
                   isInline={true}
                   align={alignColumn}
                 />
    },
    cpa: {
      name: 'CPA',
      align: alignColumn,
      headerStyle: {
        width: '150px'
      },
      component: <NumericCell
                   isCurrency={!isCurrency}
                   isInline={true}
                   align={alignColumn}
                 />
    }
  };

  return (
  	<div>
        <HeaderSummary
          items={totals}
          style={{
            marginBottom: '32px'
          }}
        />
        <div
          className='two-chiclet-wrapper'
          style={{
            display: 'flex',
            gap: '24px',
            flexGrow: 1
          }}
        >
          <ChicletLV title={'Percent of Spend'}>
            <GraphContainerLV
              graphConfig={spendConfig}
            />
          </ChicletLV>
          <ChicletLV title={'Percent of Contributions'}>
            <GraphContainerLV
              graphConfig={contributionConfig}
            />
          </ChicletLV>
        </div>

        <ChicletLV
          title={'Summary'}
          fileTooltip='Download ScenarioTable.csv'
          csvFileName={'ScenarioTable.csv'}
          downloadFileData={data}
        >
          <TableLV
            data={data}
            hasData={hasData}
            isLoading={loading}
            override={override}
            disableTableSort={false}
            dataTest={'scenario-results-table'}
          />
        </ChicletLV>
    </div>
  );
};

const tableDefaultRow = {
  tactic: '',
  spend: '',
  revenue: '',
  cpa: ''
};
