import React from 'react';
import { useGetPolling } from './usePolling';
import { Typography, Divider, Card, TextareaAutosize } from '@mui/material';
import {
  R1Button,
  R1SubmitButton,
  R1Form,
  R1TextBox,
  R1AutoComplete
} from '@rainagency/rain-one-soggy-loaf';
import namor from 'namor';

export const NavigationContext = React.createContext();

/**
 * Provider for all things Navigation
 * 
 * @param pollingDebug Displays a dashboard of current state of polling intervals
 * @param pollingExample Displays dashboard to start example polling instances
 */
export const NavigationProvider = ({
  pollingDebug,
  pollingExample,
  children,
}) => {
  const [navigationCounter, setNavigationCounter] = React.useState(0);

  const [urlMap, setUrlMap] = React.useState({});

  const updateNavigation = React.useCallback(() => {
    setNavigationCounter(navigationCounter + 1);
  }, [navigationCounter]);

  const startPollingUrl = React.useCallback(
    getStartPollingFunction(urlMap, setUrlMap),
    [urlMap]
  );

  const pollingIntervalComponents = React.useMemo(() => {
    return getIntervalComponents(urlMap, setUrlMap);
  }, [urlMap]);

  return (
    <NavigationContext.Provider
      value={{
        /**
         * Forces redraw of navigation
         */
        updateNavigation,
        /**
         * Increment value to force redraw of navigation
         */
        navigationCounter,
        startPollingUrl
      }}>

      <PollingExampleDashboard
        pollingExample={pollingExample}
        startPollingUrl={startPollingUrl}
      />

      <PollingDebugDashboard
        pollingExample={pollingExample}
        pollingDebug={pollingDebug}
        pollingIntervalComponents={pollingIntervalComponents}
      />

      {children}

    </NavigationContext.Provider>
  );
};

const getIntervalComponents = (urlMap = {}, setUrlMap) => {
  if(!Object.keys(urlMap).length) {
    return null;
  }

  return Object.values(urlMap).map(
    v => (
      <PollingComponent
        key={`polling-${v.id}`}
        urlConfig={v}
        setUrlMap={setUrlMap}
      />
    ));
};

/**
 * Component that handles current polling
 */
const PollingComponent = ({
  urlConfig = {},
  setUrlMap = () => {}
}) => {
  
  const pollingInstance = useGetPolling({
    url: urlConfig.url
  }) || {};

  const dataString = JSON.stringify(pollingInstance.data, null, 4);

  // Stop running interval and clear this component from the map
  const stopHandler = () => {
    pollingInstance.clearIntervalInstance();
    setUrlMap(previous => {
      delete previous[urlConfig.id];
      return {...previous};
    });
  };

  return (
    <div style={{
      background: 'white',
      display: 'grid',
      gap: '12px',
      padding: '12px'
    }}>

      <Typography variant='h6'>
        {urlConfig.id}
      </Typography>

      <Divider/>


      <Typography variant='body2'>
        <b>Url:</b> {urlConfig.url}
      </Typography>

      <Typography variant='body2'>
        <b>Poll Count:</b> {pollingInstance.pollCount}
      </Typography>

      <TextareaAutosize
        label="Empty"
        style={{ width: 300 }}
        minRows={6}
        maxRows={16}
        value={dataString}
      />

      <R1Button
        onClick={stopHandler}
        color='error'>
        Stop
      </R1Button>

    </div>
  );
};

/**
 * Return a function that allows users to poll a url
 */
const getStartPollingFunction = (urlMap = {}, setUrlMap = () => {}) => {

  return (props = {}) => {
    if(!props.id || !props.url) {
      console.error('No error or id set for polling');
      return;
    }

    if(urlMap.id === props.id) {
      console.info(`Polling interval for ${props.id} already exists`);
      return;
    }

    const newPollingItem = {
      ...props
    };

    setUrlMap(prev => ({
      ...prev,
      [props.id]: newPollingItem
    }));
  };
};

/**
 * Start polling example endpoints from https://reqres.in/
 */
const PollingExampleDashboard = ({
  pollingExample,
  startPollingUrl = () => {}
}) => {
  if(!pollingExample) {
    return null;
  }

  const urlOptions = [
    {
      display: 'List Users',
      value: 'https://reqres.in/api/users?page=2'
    },
    {
      display: 'Single User',
      value: 'https://reqres.in/api/users/2'
    },
    {
      display: 'Single User Not Found',
      value: 'https://reqres.in/api/users/23'
    },
  ];

  const salt = namor.generate(
    { words: 0, saltType: 'mixed', saltLength: 16 }
  );

  let defaultValues = {
    id: `polling-id-${salt}`,
    condition: 'equals',
    successMessage: 'Polling successfully completed',
    errorMessage: 'Polling failed',
  };

  const submitHandler = (values, reset) => {
    startPollingUrl(
      {
        ...values,
        url: values.url.value
      }

    );

    const newSalt = namor.generate(
      { words: 0, saltType: 'mixed', saltLength: 16 }
    );

    reset({
      id: `polling-id-${newSalt}`,
    });
  };

  return (
    <div>
      <Typography variant={'h4'}>
        Create Polling Intervals
      </Typography>

      <Divider/>

      <R1Form
        defaultValues={defaultValues}
        customStyles={{
          width: '300px',
          padding: '24px 0'
        }}
        display={'grid'}
        onSubmit={submitHandler}>

        <R1AutoComplete
          optionLabelName={'display'}
          options={urlOptions}
          label='Select a polling Url'
          name='url'
          defaultValue={urlOptions[0]}
          rules={{
            required: 'Url is required'
          }}
        />

        <R1TextBox
          label='Unique ID'
          name='id'
        />

        <R1TextBox
          label='Data object path'
          name='path'
        />

        <R1TextBox
          label='Object Key'
          name='key'
        />

        <R1TextBox
          label='Object Value'
          name='value'
        />

        <R1AutoComplete
          options={['equals', 'some']}
          label='Condition'
          name='condition'
          defaultValue={'equals'}
        />

        <R1TextBox
          label='Success message'
          name='successMessage'
        />

        <R1TextBox
          label='Error Message'
          name='errorMessage'
        />

        <R1SubmitButton
          disableIfClean={false}
          color='success'>
          Start polling an endpoint
        </R1SubmitButton>


      </R1Form>

    </div>
  );
};

const PollingDebugDashboard = ({
  pollingExample,
  pollingDebug,
  pollingIntervalComponents = []
}) => {
  if(!pollingDebug && !pollingExample) {
    return null;
  }

  let intervalCount = 0;
  if(Array.isArray(pollingIntervalComponents)) {
    intervalCount = pollingIntervalComponents.length;
  }

  return (
    <Card
      raise={'true'}
      sx={{
        position: 'absolute',
        top: 12,
        right: 0,
        padding: '12px',
        margin: '12px',
        width: '340px',
        background: 'aliceblue',
        height: '100%',
        overflowY: 'scroll'
      }}>

      <Typography variant={'h5'}>
        {intervalCount} intervals running
      </Typography>

      <Divider/>

      <div style={{
        padding: '12px 0',
        display: 'grid',
        gap: '12px'
      }}>
        {pollingIntervalComponents}
      </div>

    </Card>
  );
};
