import React from 'react';
import {numberWithCommas, roundString} from '../../../js/utils';
import _ from 'lodash';

export const OptimalRange = ({cell={}, nestedKpis=[], costPer}) => {
  const min = Number(_.get(cell, 'row.values.minOptimal')).toFixed(0);
  const max = Number(_.get(cell, 'row.values.maxOptimal')).toFixed(0);
  const variableName = _.get(cell, 'row.values.variableTactic');
  const name = variableName + '_price';
  const measure = _.get(cell, 'row.values.measure');

  if(isNaN(min) || isNaN(max) || nestedKpis.includes(variableName)) {
    return null;
  }

  const calculatedMin = roundString((measure === 'Spend') ? min : (measure === 'Impressions') ? (min * costPer[name])/ 1000 : (min * costPer[name]), 0);
  const calculatedMax = roundString((measure === 'Spend') ? max : (measure === 'Impressions') ? (max * costPer[name])/ 1000 : (max * costPer[name]), 0);

  const minOptimal = numberWithCommas(calculatedMin);
  const maxOptimal = numberWithCommas(calculatedMax);

  let range = `$${minOptimal} - $${maxOptimal}`;
  if((min === '0' && max === '0')){
    range = 'NA';
  }

  return (
    <div style={{
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    }}>
      {range}
    </div>
  );
};
