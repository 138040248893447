import {css} from 'emotion';

export const styles = {
  container: css`
    padding: 20px 10px 30px 10px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 5px;    

    th {
      border-bottom: 2px solid #828282;
    }

    td {
      border-bottom: 2px solid #E0E0E0;
    }

    .table-with-border-title {
      font-size: 20px;
      margin-bottom: 12px;
      font-weight: 500;
      color: #333;
    }
  `
};
