import {divideNumbers, subtractNumbers, addNumbers} from '../../js/utils';

export const sortedMappedScenarioData = (data=[{}], nestedKpis=[], groupMap={}, kpiType) => {
  const groupData = groupByTactic(data, groupMap, nestedKpis, kpiType);
  return calcAndMapScenarioData(groupData, groupMap, kpiType);
}
export const calcAndMapScenarioData = (data=[{}], groupMap={}, kpiType) => {
  return data.map(m => {
    const incrementalSpend = subtractNumbers(
      m.scenario2Spend,
      m.scenario1Spend);
    const incremental = subtractNumbers(
      m.scenario2Contribution,
      m.scenario1Contribution);
    let marginal = divideNumbers(
      incremental,
      incrementalSpend);

    if(kpiType === 'Units') {
      marginal = divideNumbers(
        incrementalSpend,
        incremental
      );
    }

    return {
      variableName: m.variableName,
      variableTactic: m.tactic,
      scenario1Spend: m.scenario1Spend,
      scenario1Contribution: m.scenario1Contribution,
      scenario1Ratio: m.scenario1Ratio,
      separator1: null,
      scenario2Spend: m.scenario2Spend,
      scenario2Contribution: m.scenario2Contribution,
      scenario2Ratio: m.scenario2Ratio,
      separator2: null,
      incrementalSpend,
      incremental,
      marginal
    };
  });
};

export const groupByTactic = (data=[{}], groupMap={}, nestedKpis, kpiType) => {
  const groups = Object.keys(groupMap);
  let groupedData = [];
  groups.forEach(group => {
    if(!group.length) {
      return [];
    }
    else{
      const groupVariables = groupMap[group].map(varDetails => varDetails.variableName);
      const groupData = data.filter(variable => groupVariables.includes(variable.variableName));
      groupedData.push(groupData.reduce((prev, curr) => {
        const scenario1Contribution = addNumbers(
          prev.scenario1Contribution,
          curr.scenario1Contribution);
        const scenario2Contribution = addNumbers(
          prev.scenario2Contribution,
          curr.scenario2Contribution);

        let ratio1 = divideNumbers(
          scenario1Contribution,
          curr.scenario1Spend);
        let ratio2 = divideNumbers(
          scenario2Contribution,
          curr.scenario2Spend);

        if(kpiType === 'Units') {
          ratio1 = divideNumbers(
            curr.scenario1Spend,
            scenario1Contribution
          );
          ratio2 = divideNumbers(
            curr.scenario2Spend,
            scenario2Contribution
          );
        }
        return {
          variableName: curr.variableName,
          tactic: curr.tactic,
          scenario1Spend: curr.scenario1Spend,
          scenario1Contribution: scenario1Contribution,
          scenario1Ratio: ratio1,
          scenario2Spend: curr.scenario2Spend,
          scenario2Contribution: scenario2Contribution,
          scenario2Ratio: ratio2,
        }
      }, []));
    }
  });
  const regularVariables = groupedData.filter(row => (!nestedKpis.includes(row.variableName) && row.variableName !== 'Base'));
  const nestedKpiAndBase = groupedData.filter(row => (nestedKpis.includes(row.variableName) || row.variableName === 'Base'));
  return regularVariables.concat(nestedKpiAndBase);
}
