import {sortByColumnBetter} from '../../js/utils';

// actions
export const FETCH_UPLOADED_FILES = `app/uploaded-files/fetch`;
export const LOAD_UPLOADED_FILES = `app/uploaded-files/load-uploaded-files`;
export const SORT_UPLOADED_FILES = `app/uploaded-files/sort`;
export const SPINNER = `app/uploaded-files/spinner`;
export const LOAD_UPLOADED_FILES_ERRORS = `app/uploaded-files/load-errors`;
export const CLEAR_UPLOAD_ERRORS = `app/uploaded-files/load-errors`;
export const CHANGE_UPLOAD_STEP = `app/uploaded-files/change-step`;
export const SET_UPDATE_UPLOADED_FILES = `app/uploaded-files/set-update-uploaded-files`;

// Constants
export const UPLOAD_RESET = 0;
export const UPLOAD_FORM = 1;
export const UPLOAD_COMPLETE = 2;

// selectors
const ID = 'uploadedFiles';
const local = (state) => state['platform'][ID];
export const getUploadedFiles = (state) => local(state).allIds;
export const getUploadedFilesById = (state, id) => local(state).byId[id];
export const getSortColumn = (state) => local(state).tableSortColumn;
export const getSortAscending = (state) => local(state).tableSortAscending;
export const getUploadedFilesErrors = (state) => local(state).errors;
export const getUploadStep = (state) => local(state).step;
export const getSpinner = (state) => local(state).spinner;
export const getUpdateUploadedFiles = (state) =>
    local(state).updateUploadedFiles;

export const initialState = {
    byId: {},
    allIds: [],
    tableSortColumn: '',
    tableSortAscending: false,
    errors: [],
    step: UPLOAD_FORM,
    spinner: false,
    updateUploadedFiles: false
};

export default function reducer(state = initialState, action) {
    let id = 'id';
    let newById = {};
    let newAllIds = [];
    switch (action.type) {
        case LOAD_UPLOADED_FILES:
            newAllIds = action.files.map((file) => {
                newById[file[id]] = Object.assign({}, file);
                return file[id];
            });

            if (state.tableSortColumn !== '') {
                newAllIds = sortByColumnBetter(
                    newAllIds,
                    newById,
                    state.tableSortColumn,
                    state.tableSortAscending
                );
            }
            return Object.assign({}, state, {byId: newById, allIds: newAllIds});
        case SPINNER:
            return Object.assign({}, state, {spinner: action.show});
        case SORT_UPLOADED_FILES:
            let ascendingSort = state.tableSortAscending;
            if (state.tableSortColumn === action.columnToSortBy) {
                if (ascendingSort === false) {
                    ascendingSort = true;
                } else {
                    ascendingSort = false;
                }
            } else {
                ascendingSort = true;
            }
            const sortedIds = sortByColumnBetter(
                state.allIds,
                state.byId,
                action.columnToSortBy,
                ascendingSort
            );
            return Object.assign({}, state, {
                tableSortAscending: ascendingSort,
                tableSortColumn: action.columnToSortBy,
                allIds: sortedIds
            });
        case LOAD_UPLOADED_FILES_ERRORS:
            return Object.assign({}, state, {errors: action.errors});
        case CLEAR_UPLOAD_ERRORS:
            return Object.assign({}, state, {errors: []});
        case CHANGE_UPLOAD_STEP:
            return Object.assign({}, state, {step: action.step});
        case SET_UPDATE_UPLOADED_FILES:
            return Object.assign({}, state, {
                updateUploadedFiles: action.updateUploadedFiles
            });
        default:
            return state;
    }
}

export function loadUploadedFiles(files) {
    return {
        type: LOAD_UPLOADED_FILES,
        files
    };
}

export function spinner(show) {
    return {
        type: SPINNER,
        show
    };
}

export function sortUploadedFiles(columnToSortBy) {
    return {
        type: SORT_UPLOADED_FILES,
        columnToSortBy
    };
}

export function loadUploadedFilesErrors(errors) {
    return {
        type: LOAD_UPLOADED_FILES_ERRORS,
        errors
    };
}

export function clearUploadErrors() {
    return {
        type: CLEAR_UPLOAD_ERRORS
    };
}

export function changeUploadStep(step) {
    return {
        type: CHANGE_UPLOAD_STEP,
        step
    };
}

export function updateUploadedFiles(updateUploadedFiles) {
    return {
        type: SET_UPDATE_UPLOADED_FILES,
        updateUploadedFiles
    };
}
