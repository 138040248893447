import React from 'react';
import {
    R1ActionIcon,
} from '@rainagency/rain-one-soggy-loaf';
import { urlBuilders } from '../../js/constants';
import { useDelete } from '../../hooks/useRest';
import { useSnackbarLV } from '../../components/snackbar/useSnackbarLV';

/**
 * Delete a file
 * Displays on Data Grid Action Popover
 */
export const FileDeleteActionIcon = ({
                                            cell,
                                            closePopover,
                                            hide,
                                            fetchFiles
                                        }) => {

    const [{loading, response, error}, deleteFile=()=>{}] = useDelete({
        url: urlBuilders.deleteFileByFileId(cell.row.id)
    });

    const {
        enqueueSnackbar,
        enqueueCloseSnackbarLV,
    } = useSnackbarLV();

    // refresh table when response is returned
    React.useEffect(() => {
        if (!response) {
            return;
        }

        if(response.status >= 200 && response.status <= 299) {
            enqueueSnackbar(
                `Successfully deleted file: ${cell.row.fileName}`,
                {
                    variant: 'success'
                }
            );
        }

        closePopover();
        fetchFiles();
    }, [response]);

    // Display error
    React.useEffect(() => {
        if(!error) {
            return;
        }

        enqueueCloseSnackbarLV(
            `Error deleting file: ${cell.row.fileName}, please contact Technical Support if this issue persists.`,
            {
                variant: 'error',
                persist: false,
                autoHideDuration: 10000
            }
        );

        closePopover();
    }, [error]);

    return (
        <R1ActionIcon
            iconType='Delete'
            label='Delete File'
            hide={hide}
            onDialogConfirm={deleteFile}
            DialogProps={{
                title: `Delete ${cell.row.fileName}?`,
                description: `Are you sure you want to delete ${cell.row.fileName} forever?`,
                cancelLabel: 'Cancel',
                confirmLabel: 'Delete Forever',
                onCancel: closePopover,
                customStyles: {
                    cursor: loading ? 'wait' : 'pointer'
                }
            }}
        />
    );


};
