import Typography from '@mui/material/Typography';
import { R1RadioGroup } from '@rainagency/rain-one-soggy-loaf';
import { FormRowLV } from '../../../../components/form/row/FormRowLV';

import { styles } from './create-analysis-form.styles';

export const DataIntervalField = ({disabled, selectedAnalysisType, timeScale, isError}) => {
  if (selectedAnalysisType === 'AdImpact MMM') {
    return null;
  }

  const timeScaleProps = {
    name: 'timeScale',
    row: true,
    defaultValue: timeScale || 'DAILY',
    customStyles: {
        customWrapper: {
            margin: '-5px',
            marginTop: '-28px', 
            border: '0',
            background: '#FFFFFF',
            width: '100px',
            height: '24px'
        }
    },
    radioButtons: [{
        label: 'Daily',
        value: 'DAILY',
        sx: {height: '0', marginLeft: '19px'},
        disabled
    },
    {
        label: 'Weekly',
        value: 'WEEKLY',
        sx: {height: '0', marginLeft: '19px'},
        disabled
    }]
  };

  const errorMessage = isError ? 'Error: A run cannot be initiated because the time scales for the Dependent Variable and Media Vehicle files do not match.' : '';
  return (
    <>
      <FormRowLV className={styles.formRow}>
        <Typography className={styles.fieldLabel}>Data Time Interval</Typography>
        <R1RadioGroup {...timeScaleProps}/>
      </FormRowLV>
      <Typography name='dataMismatch' className={styles.error}>{errorMessage}</Typography>
    </>   
  );
}
