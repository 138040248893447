import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {loadConfiguration} from './load-config.js';
import AppWithAuth from './AppWithAuth.jsx';
import {applyMiddleware, compose, createStore} from 'redux';
import leavenedState, {rootSaga} from './store.js';
import createSagaMiddleware from 'redux-saga';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
    leavenedState,
    composeEnhancer(applyMiddleware(sagaMiddleware))
);

// expose store when run in Cypress
if (window.Cypress) {
    window.store = store;
}

sagaMiddleware.run(rootSaga);

/*
This function configures the Amplify library to use cognito
It needs to be ran after the env configs are brought in
To do this, it gets passed into loadConfiguration as a callback
*/
function configureAmplify() {
    ReactDOM.render(
        <AppWithAuth store={store} />,
        document.getElementById('root')
    );
}

const configPath = `/leavened-config.json`;

loadConfiguration(configPath, store, configureAmplify);
