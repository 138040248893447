import {css} from 'emotion';

export const styles = {
    profileChipBackground: css`
        background: #ff7762;
        border-radius: 50%;
        height: 34px;
        width: 34px;
        border: white 3px solid;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    `,
    profileChipText: css`
        text-align: center;
        font-size: 15px;
        font-weight: bold;
        user-select: none;
        color: #FFF;
    `
};
