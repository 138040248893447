import React from 'react';

export const InsertDrive24 = () => {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.672682 1.66666C0.672682 0.933328 1.26602 0.333328 1.99935 0.333328H6.77935C7.13268 0.333328 7.47268 0.473328 7.71935 0.726662L10.9393 3.94666C11.1927 4.19333 11.3327 4.53333 11.3327 4.88666V12.3333C11.3327 13.0667 10.7327 13.6667 9.99935 13.6667H1.99268C1.25935 13.6667 0.666016 13.0667 0.666016 12.3333L0.672682 1.66666ZM6.66602 1.33333V4.33333C6.66602 4.7 6.96602 4.99999 7.33268 4.99999H10.3327L6.66602 1.33333Z"
        fill="#BDBDBD"/>
    </svg>
  );
};
