import {css} from 'emotion';

export const styles = {
  modal: css`
    position: absolute;
    min-height: 75%;
    max-height: 95%;
    min-width: 75%;
    color: #FFF;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    background: #304658;
    border-radius: 20px;
    padding: 0;
    outline: none;

    &.light {
      background: #FFF;
      color: #1B1B1B;
    }
  `,
  modalFull: css`
    min-height: 100%;
    min-width: 100%;
    padding: 0;
    border-radius: 0;
  `,
  x: css`
    cursor: pointer;
    width: 22px;
    height: 22px;
    position: absolute;
    right: 0;
    margin: 24px 24px 0 0;
    z-index: 10;
  `,
  body: css`
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
  `,
  bodyFull: css`
    align-items: flex-start;
  `,
  backdrop: css`
    background: rgba(255, 255, 255, 0.7) !important;
  `
};
