import React from 'react';
import PropTypes from 'prop-types';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import {camelToNormal} from '../../../../js/utils.js';

import MediaGroupingRow from './media-grouping-row';

import {styles} from '../start-new-modal.styles.js';

class MediaGroupingTable extends React.Component {
    buildHeader(header) {
        return (
            <TableCell className={styles.tableHeader}>
                <div>{camelToNormal(header)}</div>
            </TableCell>
        );
    }

    render () {
        const {data, readOnly, onMediaGroupingUpdate, onRequiredGroupingUpdate, mediaGroupingErrors, isSuggested, suggestedConfigMap, disabled} = this.props;
        const table = !this.props.data.length ? undefined :
                <Table size="small" style={{tableLayout: 'auto'}} className={styles.table} stickyHeader disabled={disabled} data-test='mediaGroupingTable'>
                    <TableHead>
                        <TableRow className={styles.tableHeader} data-test='tableHeaderRow-manageDataTable'>
                            {this.buildHeader('VariableName')}
                            {this.buildHeader('VariableGroup')}
                            {this.buildHeader('Measure')}
                            {this.buildHeader('Analyze')}
                            {this.buildHeader('MemoryDecay')}
                            {this.buildHeader('Saturation')}
                            {this.buildHeader('Data Grouping Required')}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row, index) => (<MediaGroupingRow key={index} 
                            row={index} 
                            data={row} 
                            readOnly={readOnly}
                            suggestedConfigMap={suggestedConfigMap}
                            onMediaGroupingUpdate={onMediaGroupingUpdate}
                            onRequiredGroupingUpdate={onRequiredGroupingUpdate}
                            mediaGroupingErrors={mediaGroupingErrors}/>))}
                    </TableBody>
                </Table>
        return (<div> {table} </div>);

    }
}

MediaGroupingTable.propTypes = {
    data: PropTypes.array,
    readOnly: PropTypes.bool,
    onMediaGroupingUpdate: PropTypes.func,
    mediaGroupingErrors: PropTypes.array,
    disabled: PropTypes.bool
};

export default (MediaGroupingTable);
