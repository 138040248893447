import React from 'react';
import {numberWithCommas, roundString} from '../../../js/utils';
import _ from 'lodash';

export const MaxObserved = ({cell={}, nestedKpis=[], costPer}) => {
  const max = Number(_.get(cell, 'row.values.maxObserved')).toFixed(0);
  const variableName = _.get(cell, 'row.values.variableTactic');
  const name = variableName + '_price';
  const measure = _.get(cell, 'row.values.measure');

  if(isNaN(max) || nestedKpis.includes(variableName)) {
    return null;
  }

  const calculatedMax = roundString((measure === 'Spend') ? max : (measure === 'Impressions') ? (max * costPer[name])/ 1000 : (max * costPer[name]), 0);

  let maxObserved = numberWithCommas(calculatedMax, true);
  if(max === '0' || maxObserved === 0){
    maxObserved = '';
  }

  return (
    <div style={{
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    }}>
      {maxObserved}
    </div>
  );
};
