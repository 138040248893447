import React from 'react';
import {GraphIconOption} from './GraphIconOption';

export const OptionLine = ({children}) => {
  return(
    <GraphIconOption>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="icon/ line">
          <path id="icon/action/trending_up_24px" d="M16.6461 7.05374L18.0861 8.49374L13.2061 13.3737L9.91609 10.0837C9.72926 9.89648 9.47561 9.79125 9.21109 9.79125C8.94657 9.79125 8.69292 9.89648 8.50609 10.0837L2.50609 16.0937C2.11609 16.4837 2.11609 17.1137 2.50609 17.5037C2.89609 17.8937 3.52609 17.8937 3.91609 17.5037L9.20609 12.2037L12.4961 15.4937C12.8861 15.8837 13.5161 15.8837 13.9061 15.4937L19.4961 9.91374L20.9361 11.3537C21.2461 11.6637 21.7861 11.4437 21.7861 11.0037V6.70374C21.7961 6.42374 21.5761 6.20374 21.2961 6.20374H17.0061C16.5561 6.20374 16.3361 6.74374 16.6461 7.05374Z" fill="#4F4F4F"/>
        </g>
      </svg>
      {children}
    </GraphIconOption>
  );
};
