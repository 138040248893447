import React from 'react';
import {uploadFileToS3} from '../../js/storage';
import {urlBuilders} from '../../js/constants';
import {urlWithDomain, useGet, usePost} from '../../hooks/useRest';
import {useCampaign} from '../../hooks/useCampaign';
import {useCampaignManagement} from '../../hooks/useCampaignManagement';
import FileUploadComplete from './file-upload-complete';
import {FileUploadForm} from './file-upload-form/file-upload-form';

import {FILE_TYPE_MAP} from '../../constants/file-types';

export const FILE_UPLOAD_FORM = 1;
export const FILE_UPLOAD_COMPLETE = 2;

export default function FileUploadModal({
    closeModal,
    uploadFileTypes,
    successHandler=()=>{},
    disableWarnings
}) {
    const [step, setStep] = React.useState(FILE_UPLOAD_FORM);
    const [values, setValues] = React.useState({});
    const waiting = React.useRef(false);

    const {
        id: campaignId
    } = useCampaign();
    const {mcaRunId, setConfigAIId} = useCampaignManagement();

    const [{
        data: campaignUploadedFiles={data:[]},
        loading
    }, refetchFiles=()=>{}] = useGet({
        url: urlBuilders.getUploadedCampaignFilesMetaData(campaignId),
        manual: true
    });

    // Only make request when campaignId is populated
    React.useEffect(() => {
        if(!campaignId) {
            return;
        }
        refetchFiles();
    },[campaignId, refetchFiles]);

    /* Call to upload file - provides no data in response */
    const [{error: uploadErrors = []}, uploadFile] = usePost({
        url: '',
        data: {},
        manual: true
    });

    const handleSubmit = async(values) => {
        waiting.current = true;
        const url = urlWithDomain(values && values.type === FILE_TYPE_MAP.SCORING_MEDIA_VEHICLE ? urlBuilders.scoringCompleteUpload() : urlBuilders.completeUpload());
        const fileUploadResponse = (await uploadFileToS3(values.file)) || {};

        if (!fileUploadResponse.error) {
            await uploadFile({
                url,
                data:  {
                    key: fileUploadResponse,
                    dataType: values.type,
                    appendFlag: values.appendFlag,
                    campaignId: campaignId,
                    runId: mcaRunId
                }
            }); 
            if (values.type === FILE_TYPE_MAP.SCORING_MEDIA_VEHICLE) {
                const kpiFile = values.kpiFile;
                values['hasKpi'] = false;
                if (kpiFile) {
                    const kpiFileUploadResponse = (await uploadFileToS3(kpiFile)) || {};
                    if (!kpiFileUploadResponse.error) {
                        await uploadFile({
                            url,
                            data:  {
                                key: kpiFileUploadResponse,
                                dataType: FILE_TYPE_MAP.SCORING_KPI,
                                campaignId: campaignId,
                                runId: mcaRunId
                            }
                        });
                        values['hasKpi'] = true;
                    }
                }
            }
        }
        setConfigAIId('');
        setValues(values);
        setStep(FILE_UPLOAD_COMPLETE);
    };

    const renderStep = () => {
        const fileUploadForm = (
            <FileUploadForm
                campaignUploadedFiles={campaignUploadedFiles.data}
                isWaiting={waiting.current || loading}
                submitForm={(values) => handleSubmit(values)} 
                handleCancel={closeModal}
                uploadFileTypes={uploadFileTypes}
                disableWarnings={disableWarnings}
            />
        );
        
        const fileUploadComplete = (
            <FileUploadComplete
                values={values}
                errors={uploadErrors}
                handleCancel={closeModal}
                successHandler={successHandler}
            />
        );

        switch (step) {
            case FILE_UPLOAD_FORM:
                return fileUploadForm;
            case FILE_UPLOAD_COMPLETE:
                waiting.current = false;
                return fileUploadComplete;
            default:
                return null;
        }
    };
    return (renderStep());
}
