import React, { useState, useEffect } from 'react';
import {useDispatch} from 'react-redux';
import _ from 'lodash';
import CircularProgress from '@mui/material/CircularProgress';
import {snackbar} from '../../../modules/platform/snackbar';

import {numberWithCommas} from '../../../js/utils';

import useMcaFilters from '../useMcaFilters';

import ModelFilterForm from './model-filter-form/model-filter-form';

import {useMcaRuns} from '../../../hooks/useMcaRuns';
import {styles} from './model-selector.styles';

export default function ModelSelector(props) {
    const {
        ranges, 
        mcaVariables,
        modelLimit,
        total, 
        initialCount,
        filters, 
        setFilters,
        fetchMcaModels,
        loading, 
        isDownloading
    } = props;

    const {selectedMcaRun} = useMcaRuns();

    // # Characters that make numbers in circle display overflow
    const MODEL_CIRCLE_MAX=10;
    const [modelsCount, setModelsCount] = useState(initialCount);
    const [formFilters, setFormFilters] = useState(filters);
    const [downloadAllModels, setDownloadAllModels] = useState(false);
    const [modelAnalysis, setModelAnalysis] = useState(false);

    const dispatch = useDispatch();
    const updateCount = !_.isEqual(filters, formFilters);
    const {
        count,
        countLoading,
        countError,
        downloadAllLoading,
        modelAnalysisLoading,
        modelAnalysisError
    } = useMcaFilters(selectedMcaRun, mcaVariables, formFilters, downloadAllModels, modelAnalysis, updateCount);
    const countIsLoading = countLoading || loading;
    
    useEffect(() => {
        if (count > -1) {
            setModelsCount(count);
        }
    }, [count]);

    const narrowResults = (values) => {
        setFormFilters(values);
    }

    const viewModels = async() => {
        await setFilters(formFilters);
        fetchMcaModels();
    }

    const downloadAll = () => {
      setDownloadAllModels(true);
    };

    useEffect(() => {
        setDownloadAllModels(false);
    }, [downloadAllModels]);

    const downloadModelAnalysis = () => {
        setModelAnalysis(true);
    };

    useEffect(() => {
        setModelAnalysis(false);
    }, [modelAnalysis]);

    const displayTotal = numberWithCommas(total);
    let displayCount = numberWithCommas(modelsCount);

    let countFontClass = styles.countHeaderLarge;
    if(displayCount.length >= MODEL_CIRCLE_MAX) {
        countFontClass = styles.countHeaderSmall;
    }

    const openSnackbar = () => {
        dispatch(snackbar('error', JSON.stringify(countError)));
    };

    const countCircleClass = [styles.count, countFontClass].join(' ');
    return (
        <div className={styles.page}>
            {countError ? () => openSnackbar() : undefined}
            <div className={styles.pageLeft}>
                <div className={styles.countContainer}>
                    <div className={countCircleClass} data-test='modelNumber-model-selector'> {displayCount} </div>
                    <p className={styles.countText} data-test='modelsOfNumber-model-selector'>Models of {displayTotal}</p>
                    {countIsLoading ? 
                        <CircularProgress 
                            size={230} 
                            thickness={1} 
                            color='secondary' 
                            className={styles.progress} 
                            classes={{colorPrimary: '#A7DA7B'}}
                        />
                    : null}
                </div>
            </div>
            <div className={styles.pageRight}>
                <ModelFilterForm 
                    mcaRun={selectedMcaRun}
                    ranges={ranges}
                    modelLimit={modelLimit}
                    mcaVariables={mcaVariables}
                    total={total}
                    loading={countIsLoading} 
                    count={Number(modelsCount)} 
                    filters={formFilters}
                    narrowResults={(values) => narrowResults(values)}
                    viewModels={() => viewModels()}
                    downloadAll={() => downloadAll()}
                    isDownloading={isDownloading || downloadAllLoading}
                    downloadModelAnalysis={() => downloadModelAnalysis()}
                    isDownloadingModelAnalysis={isDownloading || modelAnalysisLoading}
                    modelAnalysisError={modelAnalysisError}
                />
            </div>
        </div>
    );   
}

export {ModelSelector}
