import {css} from 'emotion';

export const styles = {
    loginContainer: css`
        overflow-y: scroll;
        height: 100vh;
        width: 100vw;
        background: #374f63;
    `,
    formContainer: css`
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 25%;
        left: 40%;
        color: rgba(255, 255, 255, 0.5);
        width: 280px;
        z-index: 10;
    `,
    lightField: css`
        font-size: 18px !important;
        color: rgba(255, 255, 255, 0.5) !important;
        input:-webkit-autofill {
            -webkit-text-fill-color: rgba(255, 255, 255, 0.5) !important;
        }
        margin-bottom: 16px !important;
    `,
    passwordForgotLink: css`
        font-size: 12px;
        text-align: right;
        cursor: pointer;
    `,
    submitButton: css`
        border-radius: 24px !important;
        margin-top: 16px !important;
        background: #ff7762 !important;
        border: 1px solid rgba(126, 146, 146, 0.25);
        box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.25);
        font-size: 14px;
        color: #ffffff !important;
        text-align: center;
        line-height: 18px;
        cursor: pointer;
        box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
            0px 6px 10px 0px rgba(0, 0, 0, 0.14),
            0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    `,
    logo: css`
        margin-bottom: 24px;
    `,
    topDots: css`
        position: fixed;
        top: 0;
        left: 0;
    `,
    bottomDots: css`
        position: fixed;
        bottom: 0;
        right: 0;
    `
};
