import { R1AutoComplete } from '@rainagency/rain-one-soggy-loaf';
import { FormRowLV } from '../../../../components/form/row/FormRowLV';
import { NESTED_MODEL_TYPE_OPTIONS } from '../../../../js/constants';

//Populate list of runs based on analysis type
export const AnalysisFields = ({
        disabled, 
        selectedAnalysisType,
        finalMcaRunOptions,
        mediaType,
        nestedModelType,
        adImpactMediaTypeOptions,
        selectedRun,
        setSelectedRun = () => {}
    }) => {
    const finalPrimaryRunOptions = finalMcaRunOptions.filter(run => !run.parentId);
    const primaryAnalysis = selectedRun ? finalPrimaryRunOptions.find(run => run.id === selectedRun.id) : undefined;
    if (selectedAnalysisType === 'Nested MMM') {
        return ( 
            <FormRowLV expand={ true } style={{ marginTop: '-10px' }}>
                <R1AutoComplete
                    name='nestedModelType'
                    data-testid={"nested-model-type-dropdown"}
                    disabled={ disabled }
                    label='Type'
                    aria-label='Type'
                    options={ NESTED_MODEL_TYPE_OPTIONS }
                    isOptionEqualToValue= {(option, value) => !value.length || option.value === value.value}
                    rules={{ required: true }}
                    defaultValue={ nestedModelType } />
                <R1AutoComplete
                    name='primaryAnalysis'
                    data-testid={"primary-analysis-dropdown"}
                    disabled={ disabled }
                    label='Primary MMM Analysis'
                    aria-label='Primary MMM Analysis'
                    options={ finalPrimaryRunOptions }
                    isOptionEqualToValue= {(option, value) => !value.length || option.id === value.id}
                    rules={{ required: true }}
                    defaultValue={ primaryAnalysis }
                    onChange={ setSelectedRun } />
            </FormRowLV>
        );
    }
    
    if (selectedAnalysisType === 'AdImpact MMM') {
        return ( 
            <FormRowLV expand={ true } style={{ marginTop: '-10px' }}>
                <R1AutoComplete
                    data-testid={"media-type-dropdown"}
                    name='mediaType'
                    disabled={ disabled }
                    label='Media Type'
                    aria-label='Media Type'
                    options={ adImpactMediaTypeOptions }
                    isOptionEqualToValue= {(option, value) => !value.length || option.value === value.value}
                    rules={{ required: true }}
                    defaultValue={ mediaType } />
                <R1AutoComplete
                    name='primaryAnalysis'
                    data-testid={"primary-analysis-dropdown"}
                    disabled={ disabled }
                    label='Completed MMM Analysis'
                    aria-label='Completed MMM Analysis'
                    options={ finalMcaRunOptions }
                    isOptionEqualToValue= {(option, value) => !value.length || option.id === value.id}
                    rules={{ required: true }}
                    defaultValue={ primaryAnalysis }
                    onChange={ setSelectedRun } />
            </FormRowLV>
        );
    }

    return null; //'Primary MMM'
};