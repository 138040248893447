import React from 'react';

/**
 * Cell text will have ellipsis with same width as parent <td/>
 */
export const EllipsisCell = ({cell={}}) => {
  return (
    <div
      title={cell.value}
      style={{
        width: 'inherit',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }}>
      {cell.value}
    </div>
  );
};
