import {
    R1ActionIcon,
} from '@rainagency/rain-one-soggy-loaf';
import {
    usePostTriggerDownloadUrl,
} from '../../../hooks/useTriggerDownloadUrl';
import { urlBuilders } from '../../../js/constants';

/**
 * Download transformation file on Grid Action Popover click
 */
export const ScoringArchiveDownloadActionIcon = ({
                                                    cell,
                                                    hide,
                                                    closePopover
                                                }) => {

    const {
        triggerDownload,
        loading
    } = usePostTriggerDownloadUrl({
        method: 'POST',
        requestUrl: urlBuilders.getScoringFileUrl(cell.row.id, cell.row.mcaRunId, 'scoringArchive'),
        requestData: {
            fileName: `${cell.row.name}_archive.zip`
        },
        urlObjectPath: 'url',
        onFinal: closePopover
    });

    const handleClick = () => {
        triggerDownload();
    };

    return (
        <R1ActionIcon
            iconType='File'
            label='Export Results Zip File'
            hide={hide}
            onClick={handleClick}
            customStyles={{
                cursor: loading ? 'wait' : 'pointer'
            }}
        />
    );

};
