import React from 'react';
import {GraphContainerLV} from '../../../components/dashboards/GraphContainerLV';
import {GraphConfig} from '../../../components/dashboards/GraphConfig';
import {GRAPH_OPTIONS} from '../../../components/dashboards/graph-options.constant';
import {CHART_FORMAT} from '../../../components/charts/ChartFormat.constant';
import {ChicletLV} from '../../../components/chiclet/ChicletLV';
import {StickyTableLV} from '../../../components/data-table/StickyTableLV';
import {NumericCell} from '../../../components/data-table/cells/NumericCell';
import {EditableNumericCell} from '../../../components/data-table/cells/EditableNumericCell';
import { styles } from './ScoringSpendChiclet.styles';

export const ScoringSpendChiclet = ({
  spendTableData,
  hasData,
  hasScoring2,
  isLoading,
  costPerValue,
  setCostPerValue
}) => {

  const CostPerCell = ({cell}) => {
    if (!cell?.value) {
      return null;
    }

    const variable = cell.row.values.variable;
    const scenarioColumn = cell.column.Header.slice(-1);
    const rowId = `${variable}_${scenarioColumn}`;
    return (
      <EditableNumericCell
        cell={cell}
        onChange={(event) => setCostPerValue({...costPerValue, [rowId]: event.target.value})}
        defaultValue={costPerValue[rowId]}
      />
    );
  };

  const CalculatedCell = ({cell}) => {
    const rowValues = cell.row.values || {};
    if (!rowValues.variable) {
      return (<div></div>);
    }

    let value;
    const rowId1 = `${rowValues.variable}_1`;
    const costPer1 = isNaN(costPerValue[rowId1]) ? 1 : costPerValue[rowId1];
    const rowId2 = `${rowValues.variable}_2`;
    const costPer2 = isNaN(costPerValue[rowId2]) ? 1 : costPerValue[rowId2];
    switch (cell.column.Header) {
      case 'Spend1': 
        value = costPer1 * rowValues.volume1;
        break;
      case 'Spend2':
        value = costPer2 * rowValues.volume2;
        break;
      case 'Change':
        value = (costPer2 * rowValues.volume2) - (costPer1 * rowValues.volume1);
        break;
      default:
        value = 0; 
    }
    return <NumericCell cell={{value}} decimals={0} align='center'/>
  }

  let override = {
    variable:  {
      name: 'Variable'
    },
    pricingStructure: {
      name: 'Pricing Structure',
      align: 'center'
    },
    costPer1: {
      name: 'Scenario 1 Cost Per',
      isEditable: true,
      align: 'center',
      component: <CostPerCell />
    },
    volume1: {
      hidden: true
    },
    spend1: {
      name: 'Scenario 1 Spend',
      align: 'center',
      component: <CalculatedCell />
    }
  }

  if (!hasData || hasScoring2) {
    override = {
      ...override,
      costPer2: {
        name: 'Scenario 2 Cost Per',
        align: 'center',
        isEditable: true,
        component: <CostPerCell />
      },
      volume2: {
        hidden: true
      },
      spend2: {
        name: 'Scenario 2 Spend',
        align: 'center',
        component: <CalculatedCell />
      },
      change: {
        name: 'Change',
        align: 'center',
        component: <CalculatedCell />
      }
    };
  }

  const updatedSpendTableData = spendTableData.map(row => (
    {...row, 
      spend1: row.spend1 * (costPerValue[`${row.variable}_1`] || 1),
      spend2: row.spend2 * (costPerValue[`${row.variable}_2`] || 1)
    }
  ));

  let spendChartData = [];
  if (hasData && hasScoring2) {
    spendChartData = updatedSpendTableData.map(data => ({'variable': data.variable || '-', 'Scenario 1': data.spend1, 'Scenario 2': data.spend2}));
  } else if (hasData) {
    spendChartData = updatedSpendTableData.map(data => ({'variable': data.variable || '-', 'Scenario 1': data.spend1}));
  }

  const spendConfig = new GraphConfig({
    data: spendChartData,
    graphOptions: [
      GRAPH_OPTIONS.STACKED_BAR
    ],
    formatMap: {
      'Scenario 1': CHART_FORMAT.INTEGER_CURRENCY,
      'Scenario 2': CHART_FORMAT.INTEGER_CURRENCY,
    },
    isClustered: true,
    xAxisLabel: 'variable',
    yAxisKey: 'Scenario 1',
    yAxisLabel: 'Spend',
    secondaryValue: hasScoring2 ? 'Scenario 2' : null,
    isLoading,
    colorMap: {
      'Scenario 1': '#FF7863',
      'Scenario 2': '#557999'
    }
  });

  const SpendComparisonChart = () => {
    return (
      <GraphContainerLV
        graphConfig={spendConfig}
      />
    );
  }

  return (
    <div className={styles.chickletContainer} style={hasData ? {} : {pointerEvents: 'none'}}>
      <ChicletLV
        id='spend-comparison-table'
        imageFileName='spend-comparison-table.png'
        csvFileName='spend-comparison.csv'
        chicletImageId='spend-comparison-table'
        downloadFileData={spendTableData}
        data={spendTableData}
        title='Spend'>
          <div className={styles.tableContainer}>
            <StickyTableLV
              className='scoring-spend-table'
              dataTest='scoring-spend-table'
              data={spendTableData}
              hasData={hasData}
              isLoading={isLoading}
              override={override}
              disableTableSort={true} />
          </div>
          <SpendComparisonChart />
      </ChicletLV>
    </div>
  );
}

