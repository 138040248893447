import {
    R1ActionIcon,
} from '@rainagency/rain-one-soggy-loaf';
import {
    usePostTriggerDownloadUrl,
} from '../../hooks/useTriggerDownloadUrl';
import { urlBuilders } from '../../js/constants';
import {useGet} from "../../hooks/useRest";

/**
 * Download file on Grid Action Popover click
 */
export const FileDownloadActionIcon = ({
                                                     cell,
                                                     hide,
                                                     closePopover
                                                 }) => {
    const {
        bucket, location, fileName
    } = cell.row;

    const {
        triggerDownload,
        loading
    } = usePostTriggerDownloadUrl({
        restHook: useGet,
        requestUrl: urlBuilders.getFileUrl(bucket, location, fileName),
        urlObjectPath: 'url',
        onFinal: closePopover
    });

    const handleClick = () => {
        triggerDownload();
    };

    return (
        <R1ActionIcon
            iconType='File'
            label='Download File'
            hide={hide}
            onClick={handleClick}
            customStyles={{
                cursor: loading ? 'wait' : 'pointer'
            }}
        />
    );

};