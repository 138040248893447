import {css} from 'emotion';

export const styles = {
  body: css`
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-size: 48px;
      margin-top: 14px;
      margin-bottom: 4px;
      text-align: center;
    }

    .description {
      font-size: 18px;
      text-align: center;
    }
  `
};
