import {
    R1ActionIcon,
} from '@rainagency/rain-one-soggy-loaf';
import {
    usePostTriggerDownloadUrl,
} from '../../../hooks/useTriggerDownloadUrl';
import { urlBuilders } from '../../../js/constants';
import {useGet} from "../../../hooks/useRest";

/**
 * Download Error file on Grid Action Popover click
 */
export const AdImpactDownloadErrorsActionIcon = ({
                                                      cell,
                                                      hide,
                                                      closePopover
                                                  }) => {

    const {
        triggerDownload,
        loading
    } = usePostTriggerDownloadUrl({
        restHook: useGet,
        requestUrl: urlBuilders.exportErrorsCsv(cell.row.id),
        urlObjectPath: 'data.url',
        onFinal: closePopover
    });

    const handleClick = () => {
        triggerDownload();
    };

    return (
        <R1ActionIcon
            iconType='Error'
            label='Export AdImpact Errors'
            hide={hide}
            onClick={handleClick}
            customStyles={{
                cursor: loading ? 'wait' : 'pointer'
            }}
        />
    );

};