import {css} from 'emotion';

export const commonStyles = {
  fontFamily: css`
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.75px;
  `,
  loadingSpinner: css`
    border: 5px solid #F3F3F3;
    border-top: 5px solid #BEBEBE;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `,
};

export const baseFont = 'Roboto, sans-serif';
