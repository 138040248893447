import React from 'react';
import {getStyles} from './TextInputLV.styles';
import PropTypes from 'prop-types';
import {FormElementWrapper} from '../wrapper/FormElementWrapper';

/**
 * Form text input field for Leavened
 * Uses react-hook-form for text field validation
 */  
export const TextInputLV = props => {
  const styles = getStyles(props);
  return (
    <FormElementWrapper className={styles.lastPass} {...props}>
      <input
          data-lpignore="true"
          name={props.name}
          placeholder={props.placeholder}
          type={props.type || 'text'}
          className={styles.textField}
          ref={props.register}
          defaultValue={props.defaultValue}
          value={props.value}
          disabled={props.disabled}
          readOnly={props.disabled}
          onChange={props.onChange}
          onBlur={props.onBlur}
          data-test={props.dataTest}
      />
    </FormElementWrapper>
  );
};

TextInputLV.propTypes = {
  /**
   * Field name to be reflected in form object
   */
  name: PropTypes.string.isRequired,
  /**
   * Default populated field value
   */
  defaultValue: PropTypes.string,
  /**
   * Populated field value
   */
  value: PropTypes.string,
  /**
   * HTML Input field type 
   */
  type: PropTypes.string,
  /**
   * Disable input field 
   */
  disabled: PropTypes.bool,
  /**
   * Label to display for the text input element
   */
  label: PropTypes.string,
  /**
   * Placeholder text
   */
  placeholder: PropTypes.string,
  /**
   * Description that is displayed underneath input
   */
  description: PropTypes.string,
  /**
   * Registration to form, and validation of form element
   * https://react-hook-form.com/api#register
   */
  register: PropTypes.func,
  /**
   * All form errors from parent form
   * https://react-hook-form.com/api#errors
   */
  errors: PropTypes.object,
  /**
   * If parent form has been touched
   */
  dirty: PropTypes.bool,
  /**
   * Override standard width of element
   */
  width: PropTypes.number
};

