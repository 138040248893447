import {css} from 'emotion';

export const styles = {
  content: css`
    width: 120px;
    color: #FFF;

    &.horizontal {
      width: auto;
    }

    .container-label {
      color: #000;
      font-size: 16px;
      padding: 26px 0 24px 0;
    }

  `
};
