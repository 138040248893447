import React from 'react';
import Avatar from '../../../assets/BLOBBY_LeavClrs.png';

const BotAvatar = () => {

    return (
        <div className={'react-chatbot-kit-chat-bot-avatar'}>
            <img src={Avatar} alt="BotAvatar" style={{ width: 40, height: 40 }} />
        </div>
    );
};

export default BotAvatar;