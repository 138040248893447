import {css} from 'emotion';

export const styles = {
  title: css`
    margin-top: 44px;
    margin-bottom: -16px;
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    text-align: center;
  `,
  message: css`
    margin-top: 20px;
    margin-bottom: 28px;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.54);
    text-align: center;
  `
}
