import React from 'react';
import {
  ToggleButtonGroup,
  ToggleButton,
  Switch,
  Fade,
  Tooltip
} from '@mui/material';
import { styled } from '@mui/system';
import {
  PRODUCT
} from '../../../../constants/features';

export const ProductToggle = ({
  selectedProduct = PRODUCT.MMM.key,
  setSelectedProduct = () => {},
  setSelectedFeature = () => {},
  products = {},
  open,
  isHidden,
  ...props
}) => {

  if(isHidden) {
    return null;
  }

  // Only display toggle if both products exist
  if(!products[PRODUCT.MMM.key] || !products[PRODUCT.ADIMPACT.key]) {
    return null;
  }

  const expandedChangeHandler = (event, clickedProd) => {
    if(!clickedProd) {
      return;
    }

    const defaultFeature = PRODUCT[clickedProd].features[0];
    setSelectedProduct(clickedProd);
    setSelectedFeature({ feature: defaultFeature });
  };

  const TOGGLE_MAP = {
    true: PRODUCT.ADIMPACT.key,
    false: PRODUCT.MMM.key
  };

  const collapsedToggleHandler = (event) => {
    const clickedProd = TOGGLE_MAP[event.target.checked];
    const defaultFeature = PRODUCT[clickedProd].features[0];
    setSelectedProduct(clickedProd);
    setSelectedFeature({ feature: defaultFeature });
  };

  if(open) {
    return (
      <Fade in={open}>
        <ToggleGroupStyled
          aria-label='Product feature select'
          color='primary'
          value={selectedProduct}
          exclusive={true}
          onChange={expandedChangeHandler}>

          <ToggleButton
            aria-label='Select MMM'
            value={PRODUCT.MMM.key}>
            {PRODUCT.MMM.label}
          </ToggleButton>

          <ToggleButton
            aria-label='Select AdImpact'
            value={PRODUCT.ADIMPACT.key}>
            {PRODUCT.ADIMPACT.label}
          </ToggleButton>

        </ToggleGroupStyled>
      </Fade>

    );
  }

  return (
    <Tooltip title={PRODUCT[selectedProduct].label} placement='right'>
      <SwitchStyled
        size='small'
        onClick={collapsedToggleHandler}
        checked={selectedProduct === PRODUCT.ADIMPACT.key}
      />
    </Tooltip>
  );

};

const SwitchStyled = styled(Switch)(({
  theme
}) => ({
  padding: 0,
  border: '1px solid #FFF',
  margin: 0,
  borderRadius: 12,
  color: '#FFF',
  '& .MuiSwitch-switchBase': {
    padding: 3
  },
  '& .Mui-checked': {
    color: '#FFF !important'
  },
  '&.MuiSwitch-root': {
    backgroundColor: theme.palette.primary.main
  },
  '& .MuiSwitch-track': {
    backgroundColor: `${theme.palette.primary.main} !important`,
  }
}));

const ToggleGroupStyled = styled(ToggleButtonGroup)(({
  theme
}) => ({
  height: 36,
  maxWidth: 156,
  margin: '16px 0',

  '& .MuiButtonBase-root': {
    textTransform: 'none',
    color: 'rgba(255, 255, 255, 0.6)'
  },
  '& .Mui-selected': {
    backgroundColor: '#FFF !important',
    color: theme.palette.primary.dark
  },
  '& .Mui-selected:hover': {
    backgroundColor: '#FFF !important',
    color: theme.palette.primary.dark
  }
}));
