import React from 'react';
import {TableLV} from './TableLV';
import {styles} from './TableWithBorder.styles';

/**
 * Standard TableLV with a border wrapper and optional title
 * @param title Optional Title
 * @param props All TableLV Props
 * @returns Component with Table with a title and border 
 */
export const TableWithBorder = ({title='', ...props}) => {
  return (
    <div className={`table-with-border ${styles.container}`}>

      <TitleElem title={title}/>

      <TableLV
        {...props}
      />
    </div>
  );
};

const TitleElem = ({title}) => {
  if(!title) {
    return null;
  }

  return (
    <div className='table-with-border-title'>
      {title}
    </div>
  );
};
