// actions
export const OPEN_DIALOG = `platform/dialog/open`;
export const CLOSE_DIALOG = `platform/dialog/close`;

export const initialState = {
    open: false,
    context: '',
    params: {}
};

// selectors
const ID = `dialog`;
const local = (state) => state['platform'][ID];
export const getOpen = (state) => local(state).open;
export const getContext = (state) => local(state).context;
export const getParams = (state) => local(state).params;

export default function dialogReducer(state = initialState, action) {
    switch (action.type) {
        case OPEN_DIALOG:
            return Object.assign({}, state, {
                open: true,
                context: action.context,
                params: action.params
            });
        case CLOSE_DIALOG:
            return Object.assign({}, state, {
                open: false,
                context: '',
                params: {}
            });
        default:
            return state;
    }
}

// action creators
/**
 *
 * @param {*} context The dialog context
 * @param {*} params Params is an optional params to pass to the dialog
 */
export function openDialog(context, params = {}) {
    return {
        type: OPEN_DIALOG,
        context,
        params
    };
}

export function closeDialog() {
    return {
        type: CLOSE_DIALOG
    };
}
