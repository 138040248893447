import React from 'react';
import moment from 'moment';
import {
  getQuarter,
  getYear,
  getMonth,
  getWeekOfMonth
} from 'date-fns';
import _ from 'lodash';
import {MONTH_LABELS} from '../constants/dates';
import {NumericCell} from '../components/data-table/cells/NumericCell';
import {DATE_SPAN} from '../constants/dates';

/**
 * Rolls up a dataset by week, month, or quarter and adds up all values
 * Used date-fns library
 * https://date-fns.org/v2.17.0/docs/differenceInCalendarQuarters
 *
 * @param data Data array
 * @param dateSpan Enum to determine time span MONTHLY, WEEKLY, QUARTERLY
 * @param dateColumnKey Column name representing date: DEFAULT 'date'
 * @returns Rolled up and totaled data
 */
export const useDateAndTotalsRollup = ({
  data=[],
  dateSpan=DATE_SPAN.QUARTERLY,
  dateColumnKey='date',
}) => {

  // Add Date Meta Data to All Rows
  const dataWithMeta = React.useMemo(
    () => data.map(d => addDateMetaColumn(d, dateSpan, dateColumnKey)),
    [data, dateSpan, dateColumnKey]
  );

  // Create additional rows per value in dataset
  const tableData = React.useMemo(
    () => extendRowsToTableRows(dataWithMeta, dateColumnKey),
    [dataWithMeta, dateColumnKey]
  );

  // Group data set by date meta and add all values
  const dataSumMap = React.useMemo(
    () => getTableDataSumMap(tableData),
    [tableData]
  );

  // Data sum map into rows for table display
  const parsedData = React.useMemo(
    () => sumMapToRows(dataSumMap),
    [dataSumMap]
  );

  // Data sum map into rows for table display
  const parsedDataWithGrandTotals = React.useMemo(
    () => addGrandTotalsRow(parsedData),
    [parsedData]
  );

  // Make Download File column names readable
  const downloadData = React.useMemo(
    () => readableColumnNamesForDownload(parsedDataWithGrandTotals),
    [parsedDataWithGrandTotals]
  );

  // Header group column names
  const groups = React.useMemo(
    () => getTableHeaderGroups(parsedData),
    [parsedData]
  );

  // Column name and cell component overrides
  const override = React.useMemo(
    () => getTableOverrides(parsedData),
    [parsedData]
  );

  return {
    data: parsedDataWithGrandTotals,
    downloadData,
    groups,
    override
  };
};

/**
 * Remap column names for download data
 * @param data Data set with unparsed column names
 * @returns Dataset with string names for easier reading
 */
const readableColumnNamesForDownload = (
  data
) => {
  if(!data) {
    return data;
  }
  const keysToRename = Object.keys(data[0]).filter(k => k !== 'label');
  const renamedKeyMap = keysToRename.reduce((prev, current) => {
    return {
      [current]: parseCSVColumnName(current),
      ...prev
    };
  }, {});

  const remapRow = (row={}) => {
    let next = {
      Variables: row.label
    };
    keysToRename.forEach(k => {
      next[renamedKeyMap[k]] = row[k];
    });
    return next;
  };

  return data.map(remapRow);
};

/**
 * Add row to total all columns
 * @param data Data set with numeric columns
 * @returns appended 'grandTotal' row
 */
const addGrandTotalsRow = (data) => {
  if(!data) {
    return data;
  }

  const grandTotalRow = data.reduce((prev, current) => {
    const next = {};
    const keysToTotal = Object.keys(current).filter(k => k !== 'label');
    keysToTotal.forEach(k => {
      const prevValue = prev[k] || 0;
      const currentValue = current[k] || 0;
      next[k] = Number(prevValue) + Number(currentValue);
    });

    return {
      label: 'Grand Total',
      ...next
    };
  }, {});

  return [
    ...data,
    grandTotalRow
  ];
};

/**
 * Add additional date information (quarter/month/year) to be used for later processing
 * @param row table row with a date column
 * @param dateSpan Enum to determine time span MONTHLY, WEEKLY, QUARTERLY
 * @param dateColumnKey Column name representing date: DEFAULT 'date'
 * @returns Adds dateMeta object giving extra date/week/quarter info
 */
export const addDateMetaColumn = (
  row={},
  dateSpan=DATE_SPAN.QUARTERLY,
  dateColumnKey='date'
) => {
  // Force a date to local timezone to avoid timezone offsets
  const timestampWithLocalOffset = moment(row[dateColumnKey]).format('X');
  const localizedDate = new Date(timestampWithLocalOffset * 1000);

  const quarter = getQuarter(localizedDate);
  const year = getYear(localizedDate);
  const month = getMonth(localizedDate);
  const week = getWeekOfMonth(localizedDate);

  const dateMeta = {
    week,
    month,
    quarter,
    year,
    localizedDate,
    timestamp: Number(timestampWithLocalOffset)
  };

  return {
    dateMeta,
    dateMetaKey: getColumnKey(dateSpan, week, month, quarter, year),
    ...row
  };
};

/**
 * Turns all data rows into a map with totals for the given rollup
 * @param data Data set
 * @returns Map with keys based on rollup key names and related totals
 */
const getTableDataSumMap = (
  data=[]
) => {
  return data.reduce(
    (prev, current) => {
      const {
        label, value, dateMetaKey
      } = current;
      const prevKey = `${label}.${dateMetaKey}`;
      const prevObj = _.get(prev, prevKey, {});
      const prevValue = prevObj.value || 0;
      
      const newObj = (label === 'region') ? {value} : {
        value: Number(prevValue) + Number(value)
      };

      _.set(prev, prevKey, newObj);
      return prev;
    }, {}
  );
};

export const valueRowtoTableRows = (
  row={},
  dateColumnKey='date'
) => {
  const ignoreKeys = ['dateMetaKey', 'dateMeta', dateColumnKey];
  const keys = Object.keys(row).filter(k => !ignoreKeys.includes(k));
  const extendedRows = keys.map(k => ({
    label: k,
    value: row[k],
    dateMetaKey: row.dateMetaKey,
    dateMeta: row.dateMeta,
    [dateColumnKey]: row[dateColumnKey]
  }));
  return extendedRows;
};

const extendRowsToTableRows = (
  data=[],
  dateColumnKey='date'
) => {

  // Sort data by timestamp
  const sortedByTimestamp = _.sortBy(data, d => {
    return _.get(d, 'dateMeta.timestamp', 0);
  }) || [];

  return sortedByTimestamp.reduce(
    (prev, current) => {
      return [
        ...prev,
        ...valueRowtoTableRows(current, dateColumnKey)
      ];
    },
    []
  );
};

/**
 * Create a string from meta date information
 */
const getColumnKey = (
  dateSpan=DATE_SPAN.QUARTERLY,
  week=1,
  month=0,
  quarter=1,
  year=2020
) => {
  if(dateSpan === DATE_SPAN.QUARTERLY) {
    return `${dateSpan}_${year}_${quarter}`;
  }

  if(dateSpan === DATE_SPAN.MONTHLY) {
    return `${dateSpan}_${year}_${month}`;
  }

  return `${dateSpan}_${year}_${month}_${week}`;
};

/**
 * Flatten sum objects into single-depth rows
 */
const sumMapToRows = (sumMap={}) => {
  if(Object.keys(sumMap).length < 1) {
    return undefined;
  }

  const flatRow = (row, key) => {
    return {
      [key]: row.value
    };
  };

  return Object.entries(sumMap).map(([key, value]) => {
    const levelDown = _.flatMap(
      value, flatRow
    ).reduce((prev, current) => ({
      ...prev,
      ...current
    }), {});

    return {
      label: key,
      ...levelDown
    };
  });
};

/**
 * Get table overrides to handle dynamic column names and component
 * @param data set
 * @returns Custom table overrides
 */
export const getTableOverrides = (
  data=[]
) => {
  const schema = data[0] || {};
  return Object.keys(schema)
    .filter(k => k !== 'label')
    .reduce((prev, current) => {
      return {
        ...prev,
        [current]: {
          name: parseColumnName(current),
          align: 'right',
          component: <NumericCell decimals={0}/>
        }
      };
    }, {});
};

/**
 * Get header groups to populate table
 * @param data set
 * @returns Header group array for given keys
 */
export const getTableHeaderGroups = (
  data=[]
) => {
  const schema = data[0] || {};
  return Object.keys(schema)
    .filter(k => k !== 'label')
    .map(k => {
      return {
        name: parseHeaderName(k),
        align: 'right',
        columns: [k]
      };
    });
};

/**
 * Get header group
 * @param name date meta key name EX: QUARTERLY_2018_1
 * @returns Human readable name for a given group
 */
export const parseHeaderName = (
  name=''
) => {
  
  const dateMetaArray = name.split('_');
  const dateSpan = dateMetaArray[0];

  if(!dateSpan || dateMetaArray.length < 3) {
    return name;
  }

  const year = dateMetaArray[1];
  if(dateSpan === DATE_SPAN.QUARTERLY) {
    return `${year}`;
  }

  const month = dateMetaArray[2];
  const monthName = MONTH_LABELS[month];

  if(dateSpan === DATE_SPAN.MONTHLY && dateMetaArray.length) {
    return `${year}`;
  }

  return `${monthName} ${year}`;
};


/**
 * Turn Rollup key name into human readable name
 * @param name date meta key name EX: QUARTERLY_2018_1
 * @returns Human readable name EX: Quarter 1 2018
 */
export const parseColumnName = (
  name=''
) => {
  const dateMetaArray = name.split('_');
  const dateSpan = dateMetaArray[0];

  if(!dateSpan || dateMetaArray.length < 3) {
    return name;
  }

  if(dateSpan === DATE_SPAN.QUARTERLY) {
    const quarter = dateMetaArray[2];
    return `Quarter ${quarter}`;
  }

  const month = dateMetaArray[2];
  const monthName = MONTH_LABELS[month];

  if(dateSpan === DATE_SPAN.MONTHLY && dateMetaArray.length) {
    return `${monthName}`;
  }

  const week = dateMetaArray[3];
  return `Week ${week}`;
};

/**
 * Turn Rollup key name into human readable name
 * @param name date meta key name EX: QUARTERLY_2018_1
 * @returns Human readable name EX: Quarter 1 2018
 */
export const parseCSVColumnName = (
  name=''
) => {
  const dateMetaArray = name.split('_');
  const dateSpan = dateMetaArray[0];

  if(!dateSpan || dateMetaArray.length < 3) {
    return name;
  }

  const year = dateMetaArray[1];
  if(dateSpan === DATE_SPAN.QUARTERLY) {
    const quarter = dateMetaArray[2];
    return `Quarter ${quarter} ${year}`;
  }

  const month = dateMetaArray[2];
  const monthName = MONTH_LABELS[month];

  if(dateSpan === DATE_SPAN.MONTHLY && dateMetaArray.length) {
    return `${monthName} ${year}`;
  }

  const week = dateMetaArray[3];
  return `Week ${week} ${monthName} ${year}`;
};
