/**
 * Graph configuration options for GraphContainer
 * 
 * @param data JSON dataset to populate graph
 * @param fileData List of multiple JSON datasets to download from graph's CandyBar
 *        Example: [{
 *          data: [dataset1],
 *          fileName: 'dataset1'
 *        },
 *        {
 *          data: [dataset2],
 *          fileName: 'dataset2'
 *        }]
 * @param title Text for the chart's title
 * @param xAxisLabel Text on the X Axis
 * @param yAxisLabel Text on the Y Axis
 * @param xAxisKey Column key to draw X Axis
 * @param yAxisKey Column key to draw Y Axis
 * @param ticks Array of custom ticks range
 * @param xTicks The count of X axis ticks
 * @param yTicks The count of Y axis ticks
 * @param filterKey Column key to filter each scatter element by
 *        Will create a new color for each instance of this key
 * @param formatMap How to format a key/values in the chart
 *        FROM: import {CHART_FORMAT} from './ChartFormat.constant';
 *        Example: {
 *          kpi: CHART_FORMAT.INTEGER,
 *          price: CHART_FORMAT.CURRENCY
 *        }
 * @param columnChartTypeMap Add extra sets of data to base chart type
 *        Example: {
 *          key1: [dataWithKey1AsYValue],
 *          key2: [dataWithKey2AsYValue]
 *        }
 * @param colorMap Specific color to fill a chart element
 *        Example: {
 *          kpi: 'red',
 *          price: '#FF7863'
 *        }
 * @param disableLegendColumns [] list of column names to hide in legend
 * @param graphOptions Array of GRAPH_OPTIONS for user selection
 * @param selected Default GRAPH_OPTIONS
 * @param isLoading Loading status of graph
 * @param dropdowns Drop-Down elements to display around graph
 * @param csvFileName Filename for CSV download file
 * @param imageFileName Filename for image download
 * @param imageHeader <html/> markup that will display
 *                    above the chart on image download
 * @param imageElementId Element to take snapshot of
 *        Default will be the parent element of the graph,
 *        but this can be set to anything, such as the 
 *        GraphContainerLV elementId
 * @param includeGraphCandyBarInDownload boolean whether to 
 *        include candybar in image download
 * @param isArea for Line Chart, use only Area elements
 * @param referenceAreaConfigs Display rectangles in the chart
 *        [{
 *          y1: 0,   // y-axis to start drawing box (undefined will default to bottom of chart)
 *          y2: 123, // y-axis to finish drawing box (undefined will default to top of chart)
 *          color: '#2A5588', // Hex color to display
 *          label: 'Saturation', // Text to display to User in chart and Legend
 *        }]
 * @param isBroadcastDates Displays broadcast date range selector for chart with dates on x-axis
 * @param referenceLine Displays a reference line in the Area Charts
 *        {
 *          x: '2000-01-01', // x-point where the vertical line will be shown
 *          label: 'my Point', // the label showing on top of the reference line
 *        }
 * @param referenceArea defines certain data in Area chart to have lighter shade
 *        {
 *          filterFunction: (element) => element > 0, // Function to determine the data belonging to the area
 *        } 
 * @param scrollBar Adds ReCharts Scroll Brush to chart
 *        {
 *          minLength: Minimum number of data rows to have before displaying scrollbar
 *        }
 * @param confidenceInterval for Line Chart, adds upper and lower bounds for confidence area
 *        {
 *          lower: data key for lower bound,
 *          upper: data key for upper bound
 *        }
 */
export class GraphConfig {
  constructor({
    data,
    fileData,
    title,
    xAxisLabel,
    yAxisLabel,
    xAxisKey,
    yAxisKey,
    ticks,
    xTicks=20,
    yTicks=11,
    filterKey,
    formatMap,
    columnChartTypeMap,
    colorMap={},
    graphOptions,
    selected,
    csvFileName,
    isLoading=false,
    dropdowns,
    imageFileName='image',
    imageElementId,
    imageHeader,
    includeGraphCandyBarInDownload = false,
    isArea=false,
    isBroadcastDates=false,
    referenceAreaConfigs=[],
    referenceLine,
    referenceArea,
    scrollBar,
    disableLegendColumns,
    confidenceInterval
  }) {
    // Add all props to self
    Object.entries(arguments[0]).forEach(([key, value]) => {
      this[key] = value;
    });
  }
}
