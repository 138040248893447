import React from 'react';

import {parseOptionalTableData} from '../../../src/components/data-table/TableLV';
import {
  formatDateWithNoTimezone, formatUserNameV2,
} from "../../components/data-grid/dataGridUtil";
import {R1ActionIcon, R1DataGrid} from "@rainagency/rain-one-soggy-loaf";
import {FileStatusCell} from "./FileStatusCell";
import {FileDownloadActionIcon} from "./FileDownloadActionIcon";
import {Tooltip} from "@mui/material";
import {FileDeleteActionIcon} from "./FileDeleteActionIcon";
import {FILE_TYPES} from "../../constants/file-types";

export const FileTable = ({
                                   data,
                                   loading,
                                   fetchFiles
                                 }) => {

  const tableData = React.useMemo(() => {
    const mappedData = mapScoringTableColumns(data)
    return parseOptionalTableData(mappedData);
  }, [data]);

  const columns = getFileColumns(fetchFiles);

  return (
      <R1DataGrid
          dataTestId={'file-table-data-grid-container'}
          height={'650px'}
          data={tableData}
          columns={columns}
          loading={loading}
          enablePagination={false}
          // // Action Popover with 3 dots on right of grid
          renderActionColumn={(cell) => GetActions(cell, fetchFiles)}
      />
  );
};

const getFileColumns = (fetchFiles) => {
  return [
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 224,
      maxWidth: 224,
      renderCell: (cell) => {
        return (
            <FileStatusCell
                cell={cell}
                fetchFiles={fetchFiles}
            />
        );
      }
    },
    {
      field: 'fileName',
      headerName: 'File Name',
      flex: 1,
      minWidth: 500
    },
    {
      field: 'dataType',
      headerName: 'Data Type',
      flex: 1,
      minWidth: 200,
      maxWidth: 275
    },
    {
      field: 'timeFrame',
      headerName: 'Time Frame',
      flex: 1,
      maxWidth: 100
    },
    {
      field: 'user',
      headerName: 'User',
      flex: 1,
      maxWidth: 80,
      renderCell: (cell) => {
        const {first, last, initials} = formatUserNameV2(cell.row.user);
        if (!first.length && !last.length) {
          return initials;
        }

        return (
            <Tooltip title={`${first} ${last}`} disableInteractive>
              <span>{initials}</span>
            </Tooltip>
        );
      }
    },
    {
      field: 'addedDate',
      headerName: 'Upload Date',
      maxWidth: 100,
      valueFormatter: (cell) => {
        return formatDateWithNoTimezone(cell.value);
      }
    }
  ];
};

const GetActions = (cell, fetchFiles) => {
  const processingStages = [ 'PENDING' ];
  const isProcessing = processingStages.includes(cell.row.fileStatus);

  return (
      <R1ActionIcon
          iconType='VerticalDots'
          PopoverContent={[
            <FileDownloadActionIcon
                key="1"
                cell={cell}
                hide={isProcessing}
            />,
            <FileDeleteActionIcon
                key="2"
                cell={cell}
                hide={isProcessing}
                fetchFiles={fetchFiles}
            />,
          ]}
      />
  );
};

export const mapScoringTableColumns = (data) => {
  if(!Array.isArray(data)) {
    return data;
  }

  return data.map(d => {
    const {
      id,
      added_date: addedDate,
      file_status: fileStatus,
      file_name: fileName,
      time_frame: timeFrame,
      data_type: dataType,
      user,
      bucket,
      location
    } = d;

    //const statusDisplay = scoringStatus.includes('ERROR') ? RUN_STATUS.FAILED : scoringStatus;

    return {
      addedDate,
      fileStatus,
      fileName,
      timeFrame,
      dataType: normalizeDataType(dataType),
      id,
      user,
      bucket,
      location
    };
  });
};

export const normalizeDataType = (dataType = '') => {
  const fileTypeArray = FILE_TYPES.concat([{
    value: 'mediaGroupingTemplate',
    label: 'Analysis Configuration MMM Template'
  }]);
  const fileType = fileTypeArray.find(r => (r.value === dataType)) || {};
  return "label" in fileType ? fileType.label : '';
};

/**
 * Base table keys, used to create dummy data to fill table before data loads
 */
const tableSchema = {
  scoringStatus: '',
  display: '',
  startDate: ''  ,
  endDate: '',
  scoringDate: '',
  mcaRunId: '',
  id: '',
  name: ''
};