import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import downloadIcon from '../../../../assets/download_24px.svg';
import {OrangeButtonLV} from '../../../../components/form/buttons/OrangeButtonLV';

import McaDetailsTab from './McaDetailsTab';
import McaDetailsNav from './mca-details-nav.jsx';

import {styles} from './mca-details.styles.js';

export default function McaDetails(props) {
    const {models=[], isDownloading, kpi, kpiType, queryId, includeIntercept, roiMap={}, fitData=[], setInsights=()=>{}} = props;
    const [selectedModelId, setSelectedModelId] = useState(props.selectedModelId);
    const [model, setModel] = useState({details:[]});

    useEffect(() => {
        if (models.length) {
            const selectedModel = models.find(model => model.modelid === selectedModelId);
            setModel(selectedModel);
        }
    }, [models, selectedModelId]);

    const setModelId = (modelId) => {
        setSelectedModelId(modelId);
    };
    const dependentVariableStyle = styles.modelValues;
    const interceptStyle = `${styles.modelValues} ${styles.separator}`;
    const  valuesStyle= `${styles.modelValues} ${styles.separator}`;
    return (
        <div className={styles.page}>
            <div>
                <div data-test='model-top-bar' className={styles.topBar}>
                    <div className={styles.modelTitle}>Model #{selectedModelId}</div>
                    <table className={styles.modelTable}>
                        <tbody>
                            <tr>
                                <td className={dependentVariableStyle}>{kpi}</td>
                                <td className={interceptStyle}>{model.intercept}</td>
                                <td className={valuesStyle}>{model.rsq}%</td>
                                <td className={valuesStyle}>{model.mape}%</td>
                            </tr>
                            <tr>
                                <td className={dependentVariableStyle} data-test='dependentVariableDetail-mcaModelsComponents'>DEPENDENT VARIABLE</td>
                                <td className={interceptStyle} data-test='interceptDetail-mcaModelsComponents'>INTERCEPT</td>
                                <td className={valuesStyle} data-test='rsqDetail-mcaModelsComponents'>R SQUARED</td>
                                <td className={valuesStyle} data-test='mapeDetail-mcaModelsComponents'>MAPE</td>
                            </tr>
                        </tbody>
                    </table>
                        <div className={styles.buttonContainer}>
                            <OrangeButtonLV  onClick={() => props.approveModel(selectedModelId)} dataTest='approve-model-button'> APPROVE </OrangeButtonLV>
                            <Tooltip title="Download csv" placement="right">
                                <span>
                                    <IconButton
                                        className={styles.downloadIcon}
                                        onClick={() => props.downloadSelected(selectedModelId)}
                                        disabled={isDownloading}
                                        size="large">
                                        <img src={downloadIcon} alt='Download CSV'/>
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </div>
                </div>
                <McaDetailsTab 
                    selectedModel={model}
                    roiMap={roiMap[selectedModelId]}
                    fitData={fitData}
                    kpiType={kpiType}
                    queryId={queryId}
                    includeIntercept={includeIntercept}
                    hasInsights={!!model.downloadInsights}
                    setInsights={setInsights}
                />      
            </div>
            <div className={styles.navButtons}>
                <McaDetailsNav side='Left' models={models} selectedModelId={selectedModelId} setModelId={(modelId => setModelId(modelId))}/>
                <span className={styles.buttonSeparator}/>
                <McaDetailsNav side='Right' models={models} selectedModelId={selectedModelId} setModelId={(modelId => setModelId(modelId))}/>
            </div>
        </div>
    );
}

McaDetails.propTypes = {
    models:PropTypes.array,
    selectedModelId: PropTypes.string,
    approveModel: PropTypes.func,
    downloadSelected: PropTypes.func
};

export {McaDetails};
