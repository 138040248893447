
import React from 'react';
import PropTypes from 'prop-types';

import {numberWithCommas} from '../../js/utils';

import {styles} from './HeaderSummary.styles';

/**
 * Handles all displaying and user interaction with charts
 */
export const HeaderSummary = ({
  items = [],
  style, 
  ...props
}) => {
    const formatValue = (value, format) => {
        switch (format) {
        case 'currency':
            if (Math.sign(value) > -1){
                return '$' + numberWithCommas(value);
            } else {
                return '-$' + numberWithCommas(Math.abs(value));
            }
        case 'unit':
            return numberWithCommas(value);
        case 'percent':
            return value + '%';
        default:
            return value;
        }
    };

    const Summary = (item, index) => {
        return (<div key={index} data-test='HeaderSummaryItem' className={styles.summaryItem}>
                  <div className={styles.summaryLabel}>{item.label}</div>
                  <div data-test='HeaderSummaryValue' className={styles.summaryValue}>{formatValue(item.value, item.format)}</div>
                </div>);
    };
    // Primary content
    return (
        <div className={[styles.container]} style={style}>
            {items.map((item, index) => Summary(item, index))}
        </div>
    );
};



HeaderSummary.propTypes = {
    /**
    * items for the header should be format
    * {label: 'ROI', value: 0.03, format: ['currency','unit','percent']}
    */
    items: PropTypes.array,
};
