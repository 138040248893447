import React from 'react';

/**
 * Returns an array of rolled up data by selected intervals
 * @param data Array of data object
 * @param dates Array of timestamps
 * @param isDataRollup Boolean to perform data roll up
 * @returns List of data summed by intervals of timestamps
 */
 export const useDataRollup = ({data=[], timestamps=[], isDataRollup=true}) => {
  return React.useMemo(() => {

    if(!isDataRollup || !Array.isArray(timestamps) || !timestamps.length) {
      return data;
    }

    const sortedData = data.sort((a, b) => Number(a.timestamp) - Number(b.timestamp));
    const sortedTimeStamps = timestamps.sort((a, b) => Number(a) - Number(b));
    const variables = Object.keys(sortedData[0]).filter(varKey => varKey !== 'date' && varKey !== 'timestamp');
    const rollupData = sortedTimeStamps.map((timestamp, index) => {
      const prevDate = index > 0 ? Number(sortedTimeStamps[index -1]) : 0;
      const intervalData = sortedData.filter(row => Number(row.timestamp) > prevDate && Number(row.timestamp) <= Number(timestamp));
      return intervalData.reduce((sum, current) => {
        variables.map(varKey => {
            sum[varKey] = (sum[varKey] || 0 ) + Number(current[varKey]);
        });
        sum.date = current.date;
        sum.timestamp = current.timestamp;
        return sum;
      }, {});
    });

    return rollupData.filter(value => Object.keys(value).length !== 0);
  }, [data, timestamps]);
};