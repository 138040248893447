import {urlBuilders} from '../../js/constants';
import {useGet, usePost, usePut, useDelete} from '../../hooks/useRest';

/**
 * Handle User and Partner endpoint requests for User Management
 */
export const useUserManagementRest = (userName) => {
  const usersUrl = urlBuilders.getUsers();
  const partnersUrl = urlBuilders.getAllPartners();
  const createUrl = urlBuilders.createUser();
  const updateUrl = urlBuilders.updateUser(userName);
  const deleteUrl = urlBuilders.deleteUser(userName);
  const resendUserInviteUrl = urlBuilders.resendUserInvite();

  const [{
    data: users=[],
    loading: isUsersLoading
  }] = useGet({url: usersUrl});
  
  const [{
    data: partners=[],
    loading: isPartnersLoading
  }] = useGet({url: partnersUrl});

  const [createUserResponse, postCreateUser] = usePost({url: createUrl});
  const [resendUserInviteResponse, resendUserInvite] = usePost({url: resendUserInviteUrl});
  const [updateUserResponse, putUpdateUser] = usePut({url: updateUrl});
  const [deleteUserResponse, deleteUser] = useDelete({url: deleteUrl});

  const isLoading = [
    isUsersLoading, isPartnersLoading
  ].some(x => x);

  return {
    users,
    partners,
    isLoading,
    postCreateUser,
    createUserResponse,
    deleteUser,
    deleteUserResponse,
    putUpdateUser,
    updateUserResponse,
    resendUserInvite,
    resendUserInviteResponse
  };
};
