import React from 'react';
import {ROLES} from '../js/user-roles';
import {PRODUCT} from '../constants/products';
import {useLoggedInUser} from './useLoggedInUser'

export const useUserRoleFromGroup = () => {
  const [group, setGroup] = React.useState([]);
  const [username, setUsername] = React.useState(' ');
  const [email, setEmail] = React.useState('');

  const { currentUser} = useLoggedInUser();

  React.useEffect(() => {
    if (!!currentUser && Object.keys(currentUser).length > 0) {
      setGroup(currentUser?.groups);
      setEmail(currentUser?.email);
      setUsername(currentUser?.username);
    }
  }, [currentUser]);

  const isAdmin = group.some(element => element.includes(ROLES['ADMIN'].value));
  const isAdvanced = group.some(element => element.includes(ROLES['ADVANCED_USER'].value));
  const isRegular = group.some(element => element.includes(ROLES['REGULAR_USER'].value));
  const isTrial = group.some(element => element.includes(ROLES['TRIAL_USER'].value));
  
  const hasMMM = isAdmin || group.some(element => element.includes(PRODUCT.MMM.userGroup));
  const hasAdImpact = isAdmin || group.some(element => element.includes(PRODUCT.ADIMPACT.userGroup));
  const hasWavecast = isAdmin || group.some(element => element.includes(PRODUCT.MASLOW.userGroup));
  const hasMarketingTools = isAdmin || isTrial || group.some(element => element.includes(PRODUCT.MARKETING_TOOLS));

  // kept classifier for Maslow users due to cascading effects
  // to be removed once product permissions fully established
  const isWavecast = group.some(element => element.includes(ROLES['WAVECAST_USER'].value)) && !hasMMM && !hasAdImpact;

  const productPermissions = {Wavecast: hasWavecast, MMM: hasMMM, AdImpact: hasAdImpact, MarketingTools: hasMarketingTools};
  let userType = '';
  if(group.some(element => element.includes('Partner'))){
    userType = 'Partner';
  }
  else if(group.some(element => element.includes('Company'))){
    userType = 'Company';
  }
  else{
    userType = 'System';
  }

  return {
    isAdmin,
    isAdvanced,
    isWavecast,
    isRegular,
    isTrial,
    username,
    userType,
    productPermissions,
    hasAdImpact,
    hasMMM,
    hasWavecast,
    hasMarketingTools,
    group,
    email
  };
};