import React from 'react';
import {useGet, usePost} from '../../hooks/useRest';
import {urlBuilders} from '../../js/constants';

export const useGetScenarioTable = (mcaRuns = [], selectedMcaRun={}, dashboardRegion) => {
  const finalizedRuns = mcaRuns.filter(run => run.runStatus === 'FINAL').map(run => run.id);
  const finalizedChildren = selectedMcaRun.childrenId ? selectedMcaRun.childrenId.filter(child => finalizedRuns.includes(child)) : [];

  const [{
    data: modelData={modelMap: {}, nestedKpis: {}, regionList: []},
    loading: modelMapLoading
  }, fetchModelMap] = usePost({
    url: urlBuilders.postCombineScenarios(),
    data: {primaryRunId: selectedMcaRun.id, nestedRunIds: finalizedChildren},
    manual: true
  });

  // Scenario Planner file for Optimizer
  const [{
    data: scenarioPlannerOptimizer = {data: []},
    loading: scenarioPlannerOptimizerLoading
  }, fetchScenarioPlannerOptimizer] = useGet({
    url: urlBuilders.getOutputsInsightsDataByType(selectedMcaRun.id, 'scenario-planner'),
    manual: true
  });

  // Execute GET calls only when MCARunId is populated
  React.useEffect(() => {
    if(!selectedMcaRun.id) {
      return;
    }

    fetchModelMap();
    fetchScenarioPlannerOptimizer();
  }, [selectedMcaRun.id, fetchModelMap, fetchScenarioPlannerOptimizer]);

  const optimizerMap = React.useMemo(
    ()=>(mapScenarioOptimizer(scenarioPlannerOptimizer.data)),
    [scenarioPlannerOptimizer.data]
  );

  const allRegionModelMap = addAllRegionOption(modelData.modelMap);

  const groupMap = combineByGroup(allRegionModelMap, dashboardRegion);
  return {
    modelMap: allRegionModelMap,
    groupMap,
    optimizerMap,
    nestedKpis: modelData.nestedKpis,
    regions: modelData.regionList,
    nestedRunIds: finalizedChildren,
    loading: [modelMapLoading, scenarioPlannerOptimizerLoading].some(s=>s)
  };
};

/**
 * Convert ScenarioPlanner optimizer values into a map
 */
const mapScenarioOptimizer = (data=[]) => {
  return data.reduce((prev, current) => {

    let optimizerList = [];
    if(prev[current.baseVariable]) {
      optimizerList = prev[current.baseVariable];
    }

    optimizerList.push(current);

    return {
      ...prev,
      [current.baseVariable]: optimizerList
    };
  }, {});
};

/**
 * Combine variables by variable group
 */
const combineByGroup = (modelMap, region) => {
  let groupMap = {};
  const regionMap = modelMap[region] || {};
  Object.keys(regionMap).forEach(e => {
    const varGroup = regionMap[e].tactic;
    if(groupMap[varGroup]){
      groupMap[varGroup].push(regionMap[e]);
    }
    else{
      groupMap[varGroup] = [];
      groupMap[varGroup].push(regionMap[e]);
    }
  });
  return groupMap;
};

const addAllRegionOption = (modelMap) => {
    const allRegionModelMap = {};
    allRegionModelMap['All'] = Object.values(modelMap)[0];

    return {...allRegionModelMap, ...modelMap};
}