import React from 'react';
import {Config} from '../config/ConfigFactory';

export const useZendesk = (user={}) => {
  const zendesk = typeof window.zE === 'function';

  const handleScriptLoaded = React.useMemo(() => {
    if (zendesk) {
      window.zE('webWidget', 'updateSettings', {
        webWidget: {
          offset: {
            horizontal: '100%',
            vertical: '100%'
          },
          contactForm: {
            attachments: true,
            title: {'*': 'Report an issue'},
            subject: false,
          },
        }
      });
      window.zE('webWidget', 'hide');
      window.zE('webWidget:on', 'close', function () {
        window.zE('webWidget', 'hide');
      });
    }
  }, [zendesk]);

  const handleWidget = (element) => {
    element.style.width = '60%';
    element.style.height = '80%';
  };

  React.useMemo(()=> {
    if (!zendesk) {
      const ZENDESK_KEY = Config.getConfig().ZENDESK_KEY;
      const script = document.createElement('script');
      script.async = true;
      script.id = 'ze-snippet';
      script.src = `https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_KEY}`;
      script.addEventListener('load', handleScriptLoaded);
      
      document.body.appendChild(script);
    } 
  }, [zendesk, handleScriptLoaded]);

  React.useMemo(() => {
    if (zendesk) {
      window.zE('webWidget', 'updateSettings', {
        webWidget: {
          offset: {
            horizontal: '20%',
            vertical: '90%'
          },
          contactForm: {
            attachments: true,
            title: {'*': 'Report an issue'},
            subject: false,
            fields: [
              { id: 'name', prefill: {'*': `${user.firstName} ${user.lastName}`}},
              { id: 'email', prefill: {'*': user.email} }
            ]
          },
        }
      });
      window.zE('webWidget', 'hide');
    }
  }, [user.firstName, user.lastName, user.email, zendesk]);

  const openWidget = () => {
    if (zendesk) {
      window.zE('webWidget', 'show');
      window.zE('webWidget', 'open');
    }

    // set up the mutation observer to wait until element is created 
    const observer = new MutationObserver((mutations, instance) => {
      let webWidget = document.getElementById('webWidget');
      if (webWidget) {
        handleWidget(webWidget);
        instance.disconnect();
        return;
      }
    });

    // start observing
    observer.observe(document, {
      childList: true,
      subtree: true
    });
  };

  const closeWidget = () => {
    if (zendesk) {
      window.zE('webWidget', 'hide');
      window.zE('webWidget', 'close');
    }
  }

  return {openWidget, closeWidget};
}
