import React from 'react';

import {parseOptionalTableData} from '../../../src/components/data-table/TableLV';
import {RUN_STATUS} from '../../constants/mca-run-statuses';
import { ScoringStatusCell } from './ScoringStatusCell';
import {
  formatDateWithNoTimezone,
  formatDateWithTimezoneOffset,
} from "../../components/data-grid/dataGridUtil";
import {R1ActionIcon, R1DataGrid} from "@rainagency/rain-one-soggy-loaf";
import {DeleteScoringActionIcon} from "./action-column-icons/DeleteScoringActionIcon";
import {EditScoringRunActionIcon} from "./action-column-icons/EditScoringActionIcon";
import {ScoringArchiveDownloadActionIcon} from "./action-column-icons/DownloadScoringResultsActionIcon";
import {DownloadScoringBDFileActionIcon} from "./action-column-icons/DownloadScoringBDFileActionIcon";

export const ScoringFileTable = ({
  data,
  loading,
  fetchScoringRuns
}) => {

  const tableData = React.useMemo(
    () => {
      const mappedData = mapScoringTableColumns(data)
      return parseOptionalTableData(mappedData);
    }, [data]);

  const columns = getScoringColumns(fetchScoringRuns);

  return (
        <R1DataGrid
            height={'650px'}
            data={tableData}
            columns={columns}
            loading={loading}
            enablePagination={false}
            // // Action Popover with 3 dots on right of grid
            renderActionColumn={(cell) => GetActions(cell, fetchScoringRuns)}
        />
  );
};

const getScoringColumns = (fetchScoringRuns) => {
  return [
    {
      field: 'scoringStatus',
      headerName: 'Status',
      minWidth: 224,
      maxWidth: 224,
      renderCell: (cell) => {
        return (
            <ScoringStatusCell
                cell={cell}
                fetchScoringRuns={fetchScoringRuns}
            />
        );
      }
    },
    {
      field: 'name',
      headerName: 'Run Name',
      flex: 1,
      minWidth: 176,
      hasTooltip: true,
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      maxWidth: 100,
      valueFormatter: (cell) => {
        return formatDateWithNoTimezone(cell.value);
      }
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      maxWidth: 100,
      valueFormatter: (cell) => {
        return formatDateWithNoTimezone(cell.value);
      }
    },
    {
      field: 'scoringDate',
      headerName: 'Scoring Date',
      maxWidth: 160,
      minWidth: 160,
      valueFormatter: (cell) => {
        return formatDateWithTimezoneOffset(cell.value+"+00:00", new Date().getTimezoneOffset());
      }
    },
  ];
};

const GetActions = (cell, fetchScoringRuns) => {
  const processingStages = [ 'RUNNING' ];
  const doneStages = [ 'COMPLETE' ]
  const isProcessing = processingStages.includes(cell.row.scoringStatus);
  const isDone = doneStages.includes(cell.row.scoringStatus);

  return (
      <R1ActionIcon
          iconType='VerticalDots'
          PopoverContent={[
            <ScoringArchiveDownloadActionIcon
                key="1"
                cell={cell}
                hide={!isDone}
            />,
            <DownloadScoringBDFileActionIcon
                key="2"
                cell={cell}
                hide={!isDone}
            />,
            <EditScoringRunActionIcon
                key="3"
                fetchScoringRuns={fetchScoringRuns}
                cell={cell}
                hide={isProcessing}
            />,
            <DeleteScoringActionIcon
                key="4"
                fetchScoringRuns={fetchScoringRuns}
                cell={cell}
            />,
          ]}
      />
  );
};

export const mapScoringTableColumns = (data) => {
  if(!Array.isArray(data)) {
    return data;
  }

  return data.map(d => {
    const {
      scoringStatus, display, startDate, endDate,
      scoringDate, mcaRunId, id, name, scoringFile
    } = d;

    const statusDisplay = scoringStatus.includes('ERROR') ? RUN_STATUS.FAILED : scoringStatus;

    return {
      scoringStatus: statusDisplay,
      display,
      startDate,
      endDate,
      scoringDate,
      mcaRunId,
      id,
      name,
      scoringFile
    };
  });
};

/**
 * Base table keys, used to create dummy data to fill table before data loads
 */
const tableSchema = {
  scoringStatus: '',
  display: '',
  startDate: ''  ,
  endDate: '',
  scoringDate: '',
  mcaRunId: '',
  id: '',
  name: ''
};