import {css} from 'emotion';

export const styles = {
  deleteUserModal: css`
    .title {
      font-size: 34px;
    }

    .message {
      max-width: 440px;
      text-align: center;
    }

    &.loading {
      cursor: wait;
    }
  `
};
