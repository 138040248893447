import React from 'react';
import PropTypes from 'prop-types';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import {checkboxStyles} from './CheckboxLV.styles';

export const CheckboxLV = ({
  name,
  label='',
  checked,
  onChange,
  disabled,
  dataTest,
  darkTheme,
  style={}
}) => {
  return (
    <div>
      <FormControlLabel
        style={style}
        classes={{label: disabled ? checkboxStyles.disabledField : 
                  darkTheme ? checkboxStyles.enabledFieldDark : checkboxStyles.enabledField}}
        disabled={disabled}
        control={
          <Checkbox classes={{root: checked ? checkboxStyles.checked : darkTheme ? checkboxStyles.unchecked : null}}
                    name={name}  
                    checked={checked}
                    onChange={onChange}
                    data-test={dataTest}
          />
        }
        label={label}
      />
    </div>
  );
};

CheckboxLV.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    darkTheme: PropTypes.bool
}
