import {css} from 'emotion';

export const styles = {
  container: css`
    .no-run {
      .recharts-wrapper::after {
        content: 'No Run Selected';
      }
    }
  `,
};
