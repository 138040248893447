import { useEffect, useRef, useState } from 'react';
import { useGetPolling } from '../hooks/usePolling';

import {useGet, usePost} from '../hooks/useRest';
import {urlBuilders} from '../js/constants';

/** Function to sets up an interval and clear it after unmounting */
export const useInterval = (callback, delay) => {
    const savedCallback = useRef();
    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        const tick = () => {
            savedCallback.current();
        }
        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

export default function useQueryRequest(queryUrl, resultUrl, payload, source='', fileName, maxTries=240, delay=1000) {
    const tries = useRef(0);
    const isRunning = useRef(false);
    const [runId, setRunId] = useState();
    const [modelAnalysisLoading, setModelAnalysisLoading] = useState(false);

    /** Get query id upon, manual by default */
    const [{data: query={}, loading: queryLoading, error: queryError}, startQuery] = usePost({
        url: queryUrl,
        data: payload,
        manual: true
    });

    /** Get the query status by manual fetch */
    const [{data: queryStatus={status: undefined}, loading: statusLoading, error: statusError}, getQueryStatus] = useGet({
            url: urlBuilders.getQueryStatus(query.queryId),
            manual: true
        }
    );

    isRunning.current = queryStatus.status === 'Running';

    /** Call to fetch the status upon obtaining queryId */
    useEffect(() => {
        //reset current states
        isRunning.current = false;
        tries.current = 1;
        if (query.queryId) {
            getQueryStatus();
        }
    }, [query.queryId, getQueryStatus]);

    /** Refetching the status until query finished running  or reached max number of tries */
    useInterval(() => {
        tries.current++;
        getQueryStatus();
    }, query.queryId && queryStatus.status === 'Running' && tries.current < maxTries ? delay : null);

    /** Get result of query by manual fetch */
    const [{data: result={}, loading: resultLoading, error: resultError}, getResult] = useGet({
            url: resultUrl.replace('query_uuid', query.queryId),
            manual: true
        }
    );

    /** generate model analysis file for download */
    const [{data: modelAnalysis={}, loading: startModelAnalysisLoading, error: modelAnalysisError}, startModelAnalysis] = usePost({
        url: urlBuilders.startModelAnalysis(payload.mcaRunId, query.queryId),
        data: payload
    });

    const {data: modelAnalysisResult={url: ''}, clearIntervalInstance} = useGetPolling({
        url: urlBuilders.getModelAnalysisUrl(runId, 'Model Analysis', fileName),
        interval: 2000
    });

    const stopPolling = () => {
        clearIntervalInstance();
        setRunId(undefined);
        setModelAnalysisLoading(false);
    };

    useEffect(() => {
        if (modelAnalysis.runId) {
            setRunId(modelAnalysis.runId);
        }
    }, [modelAnalysis]);

    useEffect(() => {
        if (source !== 'Download Model Analysis' || !runId) {
            stopPolling();
        } else {
            setModelAnalysisLoading(true);
        }
    }, [runId, source, clearIntervalInstance]);

    useEffect(() => {
        if (queryStatus.status === 'Success' && tries.current > 0) {
            queryStatus.status = undefined;
            if (source === 'Download Model Analysis') {
                startModelAnalysis()
            } else {
                getResult();
            }
        }
    }, [queryStatus.status, source, getResult, startModelAnalysis]);

    useEffect(() => {
        if (['DONE', 'ERROR'].includes(modelAnalysisResult.file_status)) {
            stopPolling();
        }
    }, [modelAnalysisResult]);

    let maxTryError = undefined;
    if (tries.current >= maxTries) {
        isRunning.current = false;
        maxTryError = 'Maximum number of tries exceeded when checking query: ' + source;
    }
    const firstError = [queryError, statusError, resultError, modelAnalysisError, maxTryError].find(err =>(err !== undefined && err !== null));
    const loading =  !firstError && (isRunning.current || queryLoading || statusLoading || resultLoading || startModelAnalysisLoading || modelAnalysisLoading);
    return [{query, result, modelAnalysisResult: modelAnalysisResult.url, loading, error: firstError}, startQuery];
};

export {useQueryRequest}