import React from 'react';
import _ from 'lodash';
import { Dialog } from '@mui/material';
import { SpeakerNotesOutlined } from '@mui/icons-material';
import { McaModelComparison } from './McaModelComparison'
import { StickyTableLV } from '../../components/data-table/StickyTableLV';
import { EllipsisCell } from '../../components/data-table/cells/EllipsisCell';
import { getDummyData } from '../../components/data-table/getDummyData';
import { SelectedModelDownloadDropdown } from './SelectedModelDownloadDropdown';
import { SelectedMcaModelNotes } from './SelectedMcaModelNotes';
import { useCampaignManagement } from '../../hooks/useCampaignManagement';
import { useGetMcaModels } from '../../hooks/useGetMcaModels';
import { numberWithCommas } from '../../js/utils';
import { styles } from './SelectedMcaModel.styles';

export const SelectedMcaModel = ({ magnifaiRun, setMagnifaiRun }) => {
  const { mcaRunId } = useCampaignManagement();

  const {
    model = {},
    modelList = [],
    loading,
    fetchModels
  } = useGetMcaModels(mcaRunId);

  const {
    durbinwatson = 0,
    rsq = 0,
    mape = 0,
    pval = 0
  } = model;

  const durbinWatsonDisplay = Number(durbinwatson).toFixed(2);
  const rsqDisplay = (rsq * 100).toFixed(2);
  const mapeDisplay = Math.round(mape * 100);
  const confidenceDisplay = Math.round((1 - pval) * 100);

  const mcaModelList = React.useMemo(() => mapMcaModelList(modelList), [modelList]);

  React.useEffect(() => {
    if (magnifaiRun) {
      fetchModels();
    }
  }, [magnifaiRun])

  const override = {
    variable: {
      component: <EllipsisCell />,
      style: {
        width: '220px',
        maxWidth: '250px'
      }
    },
    prctContribution: {
      name: '% Contribution'
    }
  };

  const closeDialog = (_event, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    setOpen(false);
  }
  const [open, setOpen] = React.useState(false);
  const EditNotes = () =>
    <Dialog
      open={open}
      classes={{ paper: styles.container }}
      scroll="paper"
      disableEscapeKeyDown
      onClose={closeDialog} >
      <SelectedMcaModelNotes modelId={model.modelid} handleCancel={closeDialog} />
    </Dialog>


  return (
    <div className={styles.container} data-test='mca-selected-model-table-container'>
      <EditNotes />
      <div className={styles.topBar}>
        <div className={styles.modelTitle}>
          Model #{model.modelid}
          {model.modelid ? <SpeakerNotesOutlined
            sx={{ paddingBottom: '18px', paddingLeft: '6px', cursor: 'pointer' }}
            onClick={() => setOpen(true)}
          /> : null}
        </div>

        <div className={styles.modelCalc}>
          <div>
            <div className='calculation' data-test='dependent-variable'>
              {model.dependentvariable}
            </div>
            <div>
              DEPENDENT VARIABLE
            </div>
          </div>
        </div>

        <div className={styles.modelCalc}>
          <div>
            <div className='calculation' data-test='durbin-watson'>
              {durbinWatsonDisplay}
            </div>
            <div>
              DURBIN WATSON
            </div>
          </div>
        </div>

        <div className={styles.modelCalc}>
          <div>
            <div className='calculation' data-test='rsq'>
              {rsqDisplay}%
            </div>
            <div>
              R SQUARED
            </div>
          </div>
        </div>

        <div className={styles.modelCalc}>
          <div>
            <div className='calculation' data-test='mape'>
              {mapeDisplay}%
            </div>
            <div>
              MAPE
            </div>
          </div>
        </div>

        <div className={styles.modelCalc}>
          <div>
            <div className='calculation' data-test='confidence'>
              {confidenceDisplay}%
            </div>
            <div>
              CONFIDENCE
            </div>
          </div>
        </div>

        <div className={styles.dropdown}>
          <SelectedModelDownloadDropdown />
        </div>

      </div>
      <StickyTableLV
        data={mcaModelList}
        isLoading={loading}
        hasData={mcaModelList.length}
        className={styles.tableStyles}
        override={override}
      />
      <McaModelComparison magnifaiRun={magnifaiRun} setMagnifaiRun={setMagnifaiRun} />
    </div>
  );
};

const mapMcaModelList = (list = []) => {
  if (list.length < 1) {
    return getDummyData({
      variable: '',
      decay: '',
      saturation: '',
      confidence: '',
      vif: 0,
      contribution: '',
      prctContribution: ''
    }, 5);
  }

  return list.map(row => {
    const confidence = Math.round((1 - Number(row.pval)) * 100);
    const contribution = numberWithCommas(Math.round(row.contributions));
    const prctContribution = row.prctcontributions && Math.abs(Number(row.prctcontributions)) >= 0.0005 ? (Number(row.prctcontributions) * 100).toFixed(1) : 0;
    return {
      variable: row.variablename || row.independentvariable,
      decay: _.upperFirst(_.toLower(row.variabledecay)) + (isNaN(row.variabledecay) ? '' : '%'),
      saturation: _.upperFirst(_.toLower(row.variablesaturation)),
      confidence: `${confidence}%`,
      vif: Number(row.vif).toFixed(2),
      contribution,
      prctContribution: `${prctContribution}%`
    };
  });
};
