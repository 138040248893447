import React from 'react';
import {urlBuilders} from '../../../js/constants';
import {usePost} from '../../../hooks/useRest';

export const useInsightsQuery = ({
    mcaRunId,
    insightType,
    chartType,
    filters,
    scoringId,
    manual = true,
    extraPayload = {},
                                 }) => {

    let url = ''
    if (insightType) {
        url += urlBuilders.queryInsight();
    } else if (chartType) {
        url += urlBuilders.queryChartData();
    } else {
        throw new Error('Invalid insightType or chartType');
    }

    const [{
        data: results,
        loading: isLoading
    }, fetch] = usePost({
        data: {
            runId: mcaRunId,
            scoringId,
            insightType,
            chartType,
            filters,
            ...extraPayload
        },
        url,
        manual: manual
    });

    const fetchWithFilters = (filters, props = {}) => {
        fetch({
            data: {
                runId: mcaRunId,
                insightType,
                chartType,
                filters,
                ...props
            }
        });
    }

    React.useEffect(() => {
        if(!mcaRunId || manual) {
            return;
        }
        fetch();
    },[mcaRunId, manual]);

    return {
        results,
        isLoading,
        fetch: fetchWithFilters,
    };

};