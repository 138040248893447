import React from 'react';
import _ from 'lodash';
import {
  R1DataGrid,
} from '@rainagency/rain-one-soggy-loaf';
import {formatNumber, formatString, numberWithCommas, sortPercentages} from '../../../../js/utils';
import { EllipsisCell } from '../../../../components/data-table/cells/EllipsisCell';
import {parseOptionalTableData} from "../../../../components/data-table/TableLV";

export default function McaDetailsTable({ selectedModel = { details: [] }, kpiType, hasInsights, roiMap = {} }) {

  const tableData = React.useMemo(() => {
    const mappedData = mapModelDetailsToRows(selectedModel.details, roiMap);
    return parseOptionalTableData(mappedData);
  }, [selectedModel, roiMap]);

  const columns = getColumnProps(hasInsights, kpiType);

  return (
    <R1DataGrid
      data-test='tableContainer-mcaComparisonPage'
      sx={getStyles()}
      rows={tableData}
      columns={columns}
      headerHeight={70}
      rowHeight={30}
      pagination={false}
      hideFooter
    />
  );
}

function getStyles() {
  return {
    width: '90%',
    marginLeft: '72px',
    backgroundColor: '#F2F2F2 !important',
    '& .MuiDataGrid-main': {
      '& .MuiDataGrid-columnHeaders': {
        color: '#333333',
        border: 'none',
        paddingTop: '28px',
        borderBottom: '1px solid #BDBDBD'
      },
      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: '400 !important'
      },
      '& .MuiDataGrid-columnHeader:hover': {
        color: '#FF6B57'
      },
      '& .MuiDataGrid-columnHeader--sorted': {
        color: '#FF6B57'
      },
      '& .MuiDataGrid-iconButtonContainer > button': {
        color: '#FF6B57',
      },
      '& .MuiDataGrid-row': { border: 'none' }
    },
    '& .cell': { color: '#333333', borderBottom: '1px  solid #E0E0E0' }
  }
}

function mapModelDetailsToRows(modelDetails, roiMap) {
  return modelDetails.map((detail, index) => {
    return {
      id: index,
      variable: '' + detail.variable,
      decay: detail.decay,
      saturation: detail.saturation,
      contribution: detail.contribution,
      prctContribution: detail.prctContribution,
      confidence: detail.confidence,
      vif: detail.vif,
      spend: roiMap?.[detail.variable]?.spend,
      roi: roiMap?.[detail.variable]?.roi,
      cpa: roiMap?.[detail.variable]?.costPerAcquisition
    };
  })
}

function getColumnProps(hasInsights, kpiType) {
  const columnProps = {
    headerAlign: 'left',
    cellClassName: 'cell',
    disableColumnMenu: true
  }

  return [
    {
      ...columnProps,
      minWidth: 276,
      flex: 1,
      field: 'variable',
      headerName: 'Variable',
      renderCell: (cell) => <EllipsisCell cell={cell} />
    },
    {
      ...columnProps,
      maxWidth: 144,
      flex: 1,
      field: 'decay',
      headerName: 'Decay',
      renderCell: (cell) => <WrappedStringValue cell={cell} percentage={true} firstUpper={true} />
    },
    {
      ...columnProps,
      maxWidth: 162,
      flex: 1,
      field: 'saturation',
      headerName: 'Saturation',
      renderCell: (cell) => <WrappedStringValue cell={cell} firstUpper={true} />
    },
    {
      ...columnProps,
      maxWidth: 167,
      flex: 1,
      field: 'contribution',
      headerName: 'Contribution',
      renderCell: (cell) => <WrappedNumberValue cell={cell} commas={true} />
    },
    {
      ...columnProps,
      maxWidth: 167,
      flex: 1,
      field: 'prctContribution',
      headerName: '% Contribution',
      renderCell: (cell) => <WrappedNumberValue cell={cell} percentage={true} decimal={1} />,
      sortComparator: sortPercentages
    },
    {
      ...columnProps,
      maxWidth: 159,
      flex: 1,
      field: 'confidence',
      headerName: 'Confidence',
      renderCell: (cell) => <WrappedNumberValue cell={cell} percentage={true} />,
      sortComparator: sortPercentages
    },
    {
      ...columnProps,
      maxWidth: 114,
      flex: 1,
      field: 'vif',
      headerName: 'VIF',
      renderCell: (cell) => <WrappedNumberValue cell={cell} decimal={2} />
    },
    {
      ...columnProps,
      maxWidth: 114,
      flex: 1,
      field: 'roi',
      headerName: 'ROI',
      renderCell: (cell) => <WrappedRoiCpaValue cell={cell} />,
      hide: !hasInsights || kpiType !== 'Revenue'
    },
    {
      ...columnProps,
      maxWidth: 114,
      flex: 1,
      field: 'cpa',
      renderCell: (cell) => <WrappedRoiCpaValue cell={cell} />,
      headerName: 'CPA',
      hide: !hasInsights || kpiType !== 'Units'
    },
    {
      field: 'spend',
      hide: true
    }
  ];
}

export const WrappedValue = (({ cell, percentage = false, commas = false, decimal = false}) => {
  const cellValue = _.get(cell, 'value');
  return (
    <div>{percentage && !isNaN(cellValue) ? `${cellValue}%` : cellValue}</div>
  );
}
);

export const WrappedNumberValue = (({ cell, percentage = false, commas = false, decimal = 0}) => {
      const cellValue = _.get(cell, 'value');
      return (
          <div>{formatNumber({
                value: cellValue,
                isPercentage: percentage,
                placesToRound: decimal,
                withCommas: commas
          })}</div>
      );
    }
);

export const WrappedStringValue = (({ cell, firstUpper = false, percentage = false}) => {
      const cellValue = _.get(cell, 'value');

      return (
          <div>{formatString({
            value: cellValue,
            firstUpper,
            percentage
          })}</div>
      );
    }
);

export const WrappedRoiCpaValue = (({ cell }) => {
  const cellValue = _.get(cell, 'value');
  const cellSpend = _.get(cell, 'row.spend');
  return (
    <div>{Number(cellSpend) === 0 ? 'NA' : parseFloat(cellValue).toFixed(2)}</div>
  );
});

