import React from 'react';
import {getSelectAllStyle} from './SelectLV.styles';
import {components} from 'react-select';

/**
 * Function to get Custom drop-down menu for "Select All" that selects all options on click
 * EXAMPLE implementation: https://codesandbox.io/s/vre7v?module=/example.js
 * DOCS: https://react-select.com/components
 */
export const getSelectAllMenu = ({isDark=true}) => {
  return (props) => {
    const [isHover, setIsHover] = React.useState(false);
    const selectAllClass = getSelectAllStyle(isDark, isHover);
    const optionStyles = props.getStyles('option', props);

    const mouseOver = () => (setIsHover(true));
    const mouseOut = () => (setIsHover(false));

    // Set all options as selected on click
    const clickHandler = () => {
      props.setValue(props.options);
    };

    /**
     * The actual clickable "Select All" component
     */
    const SelectAllOption = ({children}) => {
      if(!Array.isArray(children)) {
        return null;
      }

      return (
        <div data-test='select-all-container'>

          <div
            style={optionStyles}
            onMouseOver={mouseOver}
            onMouseOut={mouseOut}
            onClick={clickHandler}
            className='select-all-option'>
            Select All
          </div>

          <div className='select-all-spacer' />
        </div>
      );
    };

    /**
     * React-Select custom Menulist component
     */
    return (
      <components.MenuList
        className={selectAllClass}
        {...props} >

        <SelectAllOption {...props}/>

        {props.children}

      </components.MenuList>
    );
  };
};
