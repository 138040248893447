import React from 'react';
import {useInsightsQuery} from "../../../dashboards/hooks/useInsightsQuery";
import {filterSetDateRange} from "../../../../js/utils";

export const useScoringFitChart = ({
                                                    scoringId,
                                                    mcaRunId,
                                                    filters = {},
                                                }) => {

    // REST calls
    const {
        results: fitResults,
        fetch: fetchFit,
        isLoading: isFitLoading
    } = useInsightsQuery({
        insightType: `Fit`,
    });

    React.useEffect(() => {
        if (mcaRunId && scoringId) {
            fetchFit(null, {
                runId: mcaRunId,
                scoringId
            });
        }
    }, [mcaRunId, scoringId]);

    const isLoading = React.useMemo(() => {
        return [
            isFitLoading,
        ].some(isLoad => isLoad);
    }, [isFitLoading])

    const fitData = React.useMemo(() => {
        return filterSetDateRange(fitResults?.results, filters?.startDate, filters?.endDate) || [];
    }, [fitResults, filters.startDate, filters.endDate]);

    return {
        fit: fitData,
        isLoading
    };
}