import React from 'react';
import {GraphContainerLV} from '../../../components/dashboards/GraphContainerLV';
import {GRAPH_OPTIONS} from '../../../components/dashboards/graph-options.constant';
import {GraphConfig} from '../../../components/dashboards/GraphConfig';
import {Chiclet} from '../../../components/dashboards/Chiclet';
import {ChicletTitle} from '../../../components/dashboards/ChicletTitle';
import {CHART_FORMAT} from '../../../components/charts/ChartFormat.constant';
import {BarChartLV} from '../../../components/charts/BarChartLV';
import {parseOptionalTableData, TableLV} from '../../../components/data-table/TableLV';
import {getDummyData} from '../../../components/data-table/getDummyData';
import {DashboardContext} from '../DashboardContext';
import {urlBuilders} from '../../../js/constants';
import {useGet} from '../../../hooks/useRest';
import {useGetMcaModels} from '../../../hooks/useGetMcaModels';

import {styles} from './ModelStatistics.styles';
import {useInsightDownload} from "../hooks/useInsightDownload";

/**
 * Models Statistics Dashboard
 * @param selectedMcaRunId Selected MCA run id
 * @returns Chiclet with Total Revenue Chart
 */
export const ModelStatistics = ({
                                    selectedMcaRunId,
                                    kpiType,
                                    reportMetadata = {}
}) => {

    const {filterByDateRange, dashboardStartDate, dashboardEndDate} = React.useContext(DashboardContext);

    const [{data: fitResults = {data: []}, loading: fitLoading}, fetchFitResults] = useGet({
        url: urlBuilders.getOutputsInsightsDataByType(selectedMcaRunId, 'fit'), manual: true
    });
    const [{data: foldResults = {data: []}, loading: foldLoading}, fetchFoldResults] = useGet({
        url: urlBuilders.getOutputsInsightsDataByType(selectedMcaRunId, 'fold'), manual: true
    });

    const {
        triggerDownload,
    } = useInsightDownload({
        mcaRunId: selectedMcaRunId,
        insightType: 'FitMisses',
        downloadOptions: {
            reportMetadata,
            fileName: 'model_fit_misses.csv'
        },
        filters: {
            minDate: dashboardStartDate,
            maxDate: dashboardEndDate,
        }
    });

    const {model = {}, modelList = [], loading: modelLoading} = useGetMcaModels(selectedMcaRunId);
    const isLoading = [fitLoading, foldLoading, modelLoading].some(s => s);

    React.useEffect(() => {
        if (!selectedMcaRunId) {
            return;
        }
        fetchFitResults();
        fetchFoldResults();
    }, [selectedMcaRunId, fetchFitResults, fetchFoldResults]);

    const chartData = fitResults.data
        .map(({date, region, actual, predicted}) => ({
            date, region, actual: Number(actual), predicted: Number(predicted)
        }));

    let fitFormatMap = {};
    if (kpiType === 'Units') {
        const fitSchema = chartData.length ? chartData[0] : {};
        const yAxisKeys = Object.keys(fitSchema).filter(key => key !== 'date');
        yAxisKeys.forEach(key => {
            fitFormatMap[key] = CHART_FORMAT.INTEGER;
        });
    }

    let chartDataFiltered = chartData.map(data => {
        const {region, ...filteredData} = data;
        return filteredData;
    });

    chartDataFiltered = filterByDateRange(chartDataFiltered);

    const totalConfig = new GraphConfig({
        data: chartDataFiltered,
        graphOptions: [GRAPH_OPTIONS.LINE,],
        xAxisKey: 'date',
        yAxisLabel: 'Total ' + kpiType,
        yAxisKey: 'actual',
        formatMap: fitFormatMap,
        isLoading: isLoading,
        csvFileName: 'model_fit',
        fileData: [
            {
                data: chartDataFiltered,
                fileName: 'model_fit'
            },
            {
                triggerDownload
            }
        ],
        imageFileName: 'Total' + kpiType,
        imageElementId: 'model-statistics-chiclet-id',
        isBroadcastDates: false
    });

    if (foldResults.data.length > 0) {
        for (const [key, value] of Object.entries(foldResults.data[0])) {
            model[key.toLowerCase()] = value;
        }
    }
    ;

    let modelFolds = Object.entries(model)
        .filter(([key]) => !!foldKeys[key])
        .map(([key, value]) => {
            return {
                name: foldKeys[key],
                value: Number(value).toFixed(10),
                position: wordToNum[key.substring(4, key.length - 3)]
            };
        });
    modelFolds = modelFolds.sort(function (a, b) {
        return a.position - b.position;
    });
    modelFolds.forEach(function (item) {
        delete item.position
    });

    return (<div className='model-and-variable-stats'>
        <Chiclet id='model-statistics-chiclet-id'>
            <ChicletTitle>Model Fit</ChicletTitle>

            <GraphContainerLV
                graphConfig={totalConfig}
            />

        </Chiclet>

        <StatisticsChiclet
            model={model}
            modelList={modelList}
            folds={modelFolds}
            loading={isLoading}
        />

    </div>);
};

export const StatisticsChiclet = ({
                                      model = {}, folds = [], modelList = [], loading = false
                                  }) => {
    const modelTableData = Object.entries(model)
        .filter(([key]) => !!modelDetailKeys[key])
        .map(([key, value]) => ({
            parameter: modelDetailKeys[key],
            value: key === 'degreesoffreedom' ? Number(value).toFixed(0) : Number(value).toFixed(4)
        }));

    const variableTable = modelList.map(variable => {
        return {
            independentVariable: variable.independentvariable,
            estimate: Number(variable.estimate).toFixed(4),
            pValue: Number(variable.pval) < 0.01 ? '< 0.01' : Number(variable.pval).toFixed(2),
            VIF: Number(variable.vif).toFixed(2)
        };
    });

    const variableOverride = {
        VIF: {
            name: 'VIF', align: 'center',
        }, pValue: {
            align: 'center',
        }, estimate: {
            align: 'center',
        }
    };

    const dummyModelData = getDummyData({
        parameter: '', value: ''
    }, 5);

    const dummyVariableData = getDummyData(modelDetailKeys, 5);

    const modelLevelStatsOverride = {
        value: {
            align: 'center'
        }
    };

    return (<div>
        <Chiclet
            id='model-statistics-table-id'
            imageFileName='model-statistics.png'
            csvFileName='model-statistics.csv'
            data={modelTableData}>

            <div className={styles.statsTable}>

                <div className='model-level-stats'>
                    <ChicletTitle>Model Level Statistics</ChicletTitle>
                    <TableLV
                        data={parseOptionalTableData(modelTableData, dummyModelData)}
                        className='model-table'
                        isLoading={loading}
                        hasData={modelTableData.length}
                        override={modelLevelStatsOverride}
                        disableTableSort={true}
                    />
                </div>

                <div className='validation-results'>
                    <ChicletTitle>Validation Results</ChicletTitle>

                    <BarChartLV
                        data={folds}
                        yAxisKey={'value'}
                        isLoading={loading}
                        formatMap={{
                            'value': CHART_FORMAT.INTEGER
                        }}
                    />
                </div>

            </div>

        </Chiclet>

        <Chiclet
            id='variable-level-statistics-id'
            imageFileName='variable-level-statistics.png'
            csvFileName='variable-level-statistics.csv'
            title={'Variable Level Statistics'}
            data={variableTable}>

            <TableLV
                data={parseOptionalTableData(variableTable, dummyVariableData)}
                title='Variable Level Statistics'
                isLoading={loading}
                hasData={variableTable.length}
                disableTableSort={true}
                override={variableOverride}
                className='variable-level-statistics-table'
                dataTest={'variable-level-statistics-table'}
            />

        </Chiclet>

    </div>);
};

const modelDetailKeys = {
    rsq: 'R Squared',
    mape: 'MAPE',
    degreesoffreedom: 'Degrees of Freedom',
    intercept: 'Intercept',
    durbinwatson: 'Durbin Watson'
};

const foldKeys = {
    foldonersq: 'Fold One RSQ', foldtworsq: 'Fold Two RSQ', foldthreersq: 'Fold Three RSQ', foldfourrsq: 'Fold Four RSQ'
};

const wordToNum = {
    one: 1, two: 2, three: 3, four: 4
}
