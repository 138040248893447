import {css} from 'emotion';

export const styles = {
  container: css`
    .recharts-brush-slide {
      fill-opacity: 0.5;
      rx: 8;
    }

    .recharts-brush-traveller {
      opacity: 1;
    }

    rect:first-child {
      stroke: none;
      rx: 8;
    }
  `
};
