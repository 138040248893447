import {css} from 'emotion';

export const styles = {
  optionOverrides: css`
      width: 24px !important;
      height: 24px;

      .LV__control {
        border: 0;
        margin-top: 2px;
        cursor: pointer;

        :hover {
          path {
            fill: #FF6B57;
          }
          rect {
            fill: #FF6B57;
          }
        }
      }

      .LV__option--is-focused {
        path {
          fill: #FF6B57;
        }
        rect {
          fill: #FF6B57;
        }
      }

      .LV__option--is-selected {
        path {
          fill: #FF6B57;
        }
        rect {
          fill: #FF6B57;
        }
      }

      .LV__single-value {
        display: contents;

        .graph-label-text {
          display: none;
        }
      }
      .LV__menu {
        width: auto;
      }
      .LV__placeholder {
        display: none;
      }
      .LV__indicators {
        display: none;
      }
      .LV__input {
        display: none;
      }
      .LV__option {
        display: flex;
        align-items: center;
      }
    `
};
