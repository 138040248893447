import React from 'react';
import PropTypes from 'prop-types';

import Spinner from '../spinner';

/**
 * component to hide away the conditional on loading, show a spinner if loading is true
 * @param {element} children jsx element to render if loading is false
 * @param {boolean} loading  if true show spinner
 */
const Loading = ({children, loading}) => {
    return (
        loading ? <Spinner /> : <React.Fragment>{children}</React.Fragment>
    );
}

Loading.propTypes = {
    children: PropTypes.element,
    loading: PropTypes.bool
}

export default Loading;
