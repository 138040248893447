import moment from 'moment';

import {getMcaVariableGroup} from '../../../modules/mca/variable-name-translator.js';

/**
 * given an array of object, will return an array with only objects that are within the given date range
 * @param  {array} data      an array of objects, needs to have a date key
 * @param  {string} startDate the begninning of the date range
 * @param  {string} endDate   the end of the date range
 * @return {array}           array of objects
 */
export function filterByDate(data, startDate, endDate) {
    if (!data || !data[0] || !data[0].date) return []; // if no data or no date on data return []
    return data.filter((datum) =>
        moment(datum.date).isBetween(startDate, endDate, 'day', '[]')
    );
}

/**
 * given an array of object, will return an array with only objects that are within the given date range
 * @param  {array} data      an array of objects, needs to have a date key
 * @param  {string} startDate the begninning of the date range
 * @param  {string} endDate   the end of the date range
 * @param  {string} selectedGroup the group/media vehicle selected
 * @return {array}           array of objects
 */
export function filterByDateAndGroup(
    data,
    startDate,
    endDate,
    state,
    selectedGroup
) {
    if (!data || !data[0] || !data[0].date || !data[0].independentVariable)
        return []; // if no data or no date on data return []
    const group = selectedGroup
        ? selectedGroup
        : getMcaVariableGroup(state, data[0].independentVariable);
    return data.filter(
        (datum) =>
            moment(datum.date).isBetween(startDate, endDate, 'day', '[]') &&
            getMcaVariableGroup(state, datum.independentVariable) === group
    );
}

export function removeNegativesFromContributionsDaily(data) {
    let keysToRemove = [];
    let returnData = [];
    // check each key, not date or Base, to see if it is negative
    data.forEach((datum) => {
        Object.keys(datum).forEach((key) => {
            if (key !== 'date' || key !== 'Base') {
                if (
                    Math.sign(datum[key]) === -1 &&
                    !keysToRemove.includes(key)
                ) {
                    keysToRemove.push(key);
                }
            }
        });
    });

    // remove all negative keys
    data.forEach((datum) => {
        Object.keys(datum).forEach((key) => {
            if (keysToRemove.includes(key)) {
                delete datum[key];
            }
        });
        returnData.push(datum);
    });
    return returnData;
}
