import { FILE_TYPE_MAP } from '../../../../constants/file-types';
import { DEFAULT_VALIDATION_OPTIONS } from '../../../../components/file-upload/constants/validationTypeOptions';


export const UPLOAD_FILE_TYPES = [
  {
    label: "Scoring Business Drivers",
    value: FILE_TYPE_MAP.SCORING_MEDIA_VEHICLE,
    mappingColumns: [
      {
        inputType: "text",
        label: "Revised Name",
        name: "finalName"
      },
      {
        inputType: "autocomplete",
        label: "Data Type",
        name: "validation",
        autocompleteProps: {
          rules: {
        //required: 'Data Type is required'
          },
        },
        options: DEFAULT_VALIDATION_OPTIONS
      },
    ]
  },
  {
    label: "Scoring Dependent Variable",
    value: FILE_TYPE_MAP.SCORING_KPI,
    mappingColumns: [
      {
        inputType: "text",
        label: "Revised Name",
        name: "finalName"
      },
      {
        inputType: "autocomplete",
        label: "Data Type",
        name: "validation",
        autocompleteProps: {
          rules: {
            //required: 'Data Type is required'
          },
        },
        options: DEFAULT_VALIDATION_OPTIONS
      }
    ]
  },
];
