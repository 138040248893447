import React from 'react';
import PropTypes from 'prop-types';
import {styles} from './GraphContainerLV.styles';
import {GraphPanel} from './GraphPanel';

/**
 * Handles all displaying and user interaction with charts
 * Will create a Graph Panel from an object/array of configs given
 * Any children will be added next to a Graph in the array
 */
export const GraphContainerLV = ({
  elementId, className, graphConfig=[], children
}) => {
  let configs = graphConfig;
  if(!Array.isArray(graphConfig)){
    configs = [graphConfig];
  }

  const classNames = [styles.container, className];
  let Panels = configs.map((config, index) => {
    return <GraphPanel key={index} {...config}/>;
  });

  if(children) {
    Panels.push(children);
  }

  if(Panels.length === 2) {
    classNames.push('two-columns');
  }

  return (
    <div
      id={elementId}
      className={classNames.join(' ')}>
      <div className='graph-panels-container'>

        {Panels}

      </div>
    </div>
  );
};

GraphContainerLV.propTypes = {
  /**
   * ElementId for container can be used when we want to 
   * take an image of this entire container in a graph candy bar
   */
  elementId: PropTypes.string,
  /**
   * Custom CSS for Graph Panel
   */
  className: PropTypes.string,
  /**
   * Config object array
   * Contains all Graph Panel information
   */
  graphConfigs: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ])
};
