import React from 'react';
import {styles} from './IconLV.styles';
import PropTypes from 'prop-types';

/**
 * Handles icons in data grids
 * Give it an image and a click handler, and it will
 * display your image, add your css, and handle your click with the cell value
 */
export default function IconLV({cell, onClick, src, className, dataTest, isHidden, tooltip=''}) {
  let propClick = function(){};
  const value = cell ? cell.value : '';
  const classes = [];
  if(onClick) {
    propClick = onClick;
    classes.push(styles.pointer);
  }

  // Send the grid cell value as the second argument on click
  const handleClick = (event) => {
    propClick(event, value);
  };

  if(className) {
    classes.push(className);
  }

  const classNames = classes.join(' ');
  
  return isHidden ? null : (
    <img
      alt='icon'
      className={classNames}
      onClick={handleClick}
      src={src}
      data-test={dataTest}
      title={tooltip}
      aria-label={tooltip}
    />
  );
}
export {IconLV}

IconLV.propTypes = {
  /**
   * React-Table cell object
   */
  cell: PropTypes.object,
  /**
   * Handle onClick and add cell value to click args
   */
  onClick: PropTypes.func,
  /**
   * Image path
   */
  src: PropTypes.string,
  /**
   * Parent class properties for image
   */
  className: PropTypes.string
};
