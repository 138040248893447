import {css} from 'emotion';
import {getStyles as getTextStyles} from '../text/TextInputLV.styles';

// Borrow styles from Text Input fields
export const getStyles = ({spaceBetween, ...props}) => {
  if(!spaceBetween) {
    spaceBetween = 24;
  }
  const textStyles = getTextStyles(props);
  return {
    container: css`
      input {
        ${textStyles.textField}
      }
      .react-datepicker-wrapper {
        width: 100%;
      }
    `,
    rangeContainer: css`
      display: flex;
    `,
    startContainer: css`
      margin-right: ${spaceBetween}px;
    `
  };
};
