import {css} from 'emotion';

export const styles = {
    tableContainer: css`
        font-family: Roboto, sans-serif;
        font-size: 14px;
        color: #333333;
        height: calc(100vh - 320px);
        overflow-y: scroll;
        tr {
            border-bottom: 1px #F2F2F2;
        };
        th {
            position: sticky;
            top: 0;
            padding: 0;
            font-size: 14px;
            font-weight: normal;
            color: #828282;
            background: #FFFFFF;
            z-index: 10;
            cursor: pointer;
            padding-right: 35px;
        }
        th> : nth-child(1) {
            max-width: 100px;
        }
        .review {
            td {
                background: #FFFFFF;
                color: #333333;
            }
            .index-cell {
                text-align: center;
                height: 45px;
                line-height: 45px;
                width: 164px !important;
                background: #E0E0E0;
                cursor: pointer;
            }
            .modelId-cell {
                padding-left: 25px;
                box-shadow: none;
                cursor: pointer;
            }
            .variables-cell {
                cursor: pointer;
            }
            .value-cell {
                min-width: 141px !important;
                box-shadow: none;
                cursor: pointer !important;
            }
            .status-menu {
                font-size: 14px !important;
                color: #333333 !important;
            }
            .download-icon {
                cursor: pointer;
            }
        }
        .disabled {
            th {
                cursor: default;
                pointer-events: none;
            }
        }
    `,
    spinnerContainer: css`
        display: flex;
        width: 100vw;
        padding-top: 100px;
        justify-content: center;
    `,
    modal: css`
        background: transparent !important;
    `
};
