import {css} from 'emotion';

export const styles = {
  stepper: css`
    width: 100%;
    margin-top: 36px;
    & div[class^="r1-stepper-interface"] {
      width: 97%;
      margin-left: 24px;
      margin-right: 24px;
    }
  `
}