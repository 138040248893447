import React from 'react';
import { useGet, usePost } from './useRest';
import { urlBuilders } from '../js/constants';
import { FEATURES } from '../constants/features';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import {
  MCA_RUN_ID_URL_PARAM,
  AD_IMPACT_ID_URL_PARAM,
  SCORING_ID_URL_PARAM,
  SCORING_DASHBOARD_TAB_URL_PARAM
} from './useCampaignManagement';

export const RECENT_ACTIVITY_TYPES = {
  CONFIGAI: 'CONFIGAI',
  SCORING: 'SCORING',
  MMM: 'MMM',
  ADIMPACT: 'ADIMPACT'
};

/**
 * Find all Runs for all Campaigns for all Partners and Clients
 * @return {Array} recentActivity All Recent Activity items sorted by timestamp
 * @return {Object} activity All recent activity information
 * @return {function} refetchActivity refetch all data
 */
export const useRecentActivity = () => {

  const location = useLocation();

  // Partners
  const [{
    data: allPartners,
    loading: partnersLoading
  }, refetchPartners] = useGet({
    url: urlBuilders.getAllPartners()
  });

  const campaignMap = React.useMemo(() => {
    return flattenPartnersToCampaigns(allPartners);
  }, [allPartners]);

  const campaignIds = React.useMemo(() => {
    if(!campaignMap) {
      return undefined;
    }
    return Object.keys(campaignMap);
  }, [campaignMap]);

  // Recent Activity Endpoint
  const [{
    data: recentActivityRuns,
    loading: recentActivityLoading
  }, refetchRecentActivity, cancelRecentActivity = () => {}] = usePost({
    url: urlBuilders.postRecentActivity(),
    data: {
      campaignIds,
      limit: 5,
      runType: ["MMM", "SCORING", "CONFIGAI", "ADIMPACT"]
    }
  });

  // Get recent runs when homepage is visited
  React.useEffect(() => {
    if(!Array.isArray(campaignIds) || !campaignIds.length) {
      return;
    }

    // Cancel on page change
    if(!location.pathname === '/') {
      cancelRecentActivity();
    }

    if(recentActivityLoading) {
      return;
    }

    // Only run on homepage
    if(location.pathname === '/') {
      refetchRecentActivity();
    }

  }, [location, campaignIds]);

  const recentActivity = React.useMemo(() => {
    return parseRecentActivity(recentActivityRuns, campaignMap);
  }, [recentActivityRuns, campaignMap]);

  return {
    recentActivity,
    isRecentActivityLoading: [recentActivityLoading, partnersLoading].some(s => !!s),
    refetchRecentActivity: refetchPartners
  };

};

const parseRecentActivity = (
  activityResults = {},
  campaignMap
) => {

  const mmm = MMM(activityResults.mcaRuns, campaignMap);
  const configAI = ConfigAI(activityResults.configAIRuns, campaignMap);
  const adImpact = AdImpact(activityResults.adImpactRuns, campaignMap);
  const scoring = Scoring(activityResults.scoringRuns, campaignMap, mmm);

  const allActivity = [
    ...mmm,
    ...configAI,
    ...adImpact,
    ...scoring
  ];

  return _.sortBy(allActivity, ['timestamp']).reverse();

};

const MMM = (mcaRuns, campaignMap) => {
  if(!mcaRuns) {
    return [];
  }

  return mcaRuns?.map(mmm => {
    const campaign = campaignMap[mmm.campaignId];
    return {
      id: mmm.id,
      campaignId: mmm.campaignId,
      title: mmm.display,
      client: campaign?.client,
      campaign: campaign,
      partner: campaign?.partner,
      date: mmm?.runStartDate,
      timestamp: moment(mmm.runStartDate).format('X') * 1,
      onClick: () => { },
      feature: FEATURES.MMM_SELECTED,
      status: mmm.runStatus,
      type: RECENT_ACTIVITY_TYPES.MMM,
      //Append urlquery params to url
      query: {
        [MCA_RUN_ID_URL_PARAM]: mmm.id,
      }
    };
  });
};

const ConfigAI = (runs, campaignMap) => {
  if(!runs) {
    return [];
  }

  return runs?.map(run => {
    const campaign = campaignMap[run.campaign_id];
    const date = new Date(run.date_timestamp * 1000);
    return {
      id: run.configai_id,
      campaignId: run.campaign_id,
      title: 'ConfigAI',
      client: campaign?.client,
      campaign: campaign,
      partner: campaign?.partner,
      date: date,
      timestamp: moment(date).format('X') * 1,
      onClick: () => { },
      feature: FEATURES.MMM_ANALYSIS,
      status: run.run_status,
      type: RECENT_ACTIVITY_TYPES.CONFIGAI,
      //Append urlquery params to url
      query: {}
    };
  });
};

const AdImpact = (runs, campaignMap) => {
  if(!runs) {
    return [];
  }

  return runs?.map(run => {
    const campaign = campaignMap[run.campaign_id] || {};
    return {
      campaignId: run.campaign_id,
      title: run.wavecast_name,
      client: campaign.client,
      campaign: campaign,
      partner: campaign.partner,
      date: run.run_date,
      timestamp: moment(run.run_date).format('X') * 1,
      onClick: () => { },
      feature: FEATURES.ADIMPACT_ANALYSIS,
      type: RECENT_ACTIVITY_TYPES.ADIMPACT,
      // Append urlquery params to url
      query: {
        [MCA_RUN_ID_URL_PARAM]: run.mca_run_id,
        [AD_IMPACT_ID_URL_PARAM]: run.wavecast_id
      }
    };
  });
};

const Scoring = (runs, campaignMap, mmmRuns) => {
  if(!runs || !mmmRuns) {
    return [];
  }

  return runs?.map(run => {
    const campaign = campaignMap[run.campaignId] || {};
    return {
      campaignId: run.campaignId,
      title: run.display,
      client: campaign.client,
      campaign: campaign,
      partner: campaign.partner,
      date: run.scoringDate,
      timestamp: moment(run.scoringDate).format('X') * 1,
      onClick: () => { },
      feature: FEATURES.SCORING,
      status: run.scoringStatus,
      type: RECENT_ACTIVITY_TYPES.SCORING,
      // Append urlquery params to url
      query: {
        [MCA_RUN_ID_URL_PARAM]: run.mcaRunId,
        [SCORING_ID_URL_PARAM]: run.id,
        [SCORING_DASHBOARD_TAB_URL_PARAM]: 1,
      }
    };
  }).filter(x => !!x);
};

const flattenPartnersToCampaigns = (partners) => {
  return partners?.reduce((prev, partner) => {
    if(!partner?.companies?.length) {
      return prev;
    }

    // Nix automation campaigns
    if (partner?.name?.toLowerCase().startsWith('e2e')) {
      return prev;
    }

    const allCampaigns = flattenCompaniesToCampaigns(
      partner?.companies,
      partner
    );

    return {
      ...prev,
      ...allCampaigns
    };

  }, {});
};

const flattenCompaniesToCampaigns = (companies, partner) => {
  return companies?.reduce((prev, client) => {
    if(!client?.campaigns?.length) {
      return prev;
    }

    const campaignMap = client.campaigns.reduce((prevCampaign, campaign) => {
      return {
        ...prevCampaign,
        [campaign.id]: {
          ...campaign,
          client,
          partner
        }
      };
    }, {});

    return {
      ...prev,
      ...campaignMap
    };

  }, {});

};
