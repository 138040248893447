import React from 'react';
import {Chiclet} from '../../../components/dashboards/Chiclet';
import {TableLV} from '../../../components/data-table/TableLV';
import {SelectLV} from '../../../components/form/select/SelectLV';
import {FormRowLV} from '../../../components/form/row/FormRowLV';
import {OrangeButtonLV} from '../../../components/form/buttons/OrangeButtonLV';
import {GreyButtonLV} from '../../../components/form/buttons/GreyButtonLV';
import {getDummyData} from '../../../components/data-table/getDummyData';
import {numberWithCommas} from '../../../js/utils';
import {styles} from './OptimizerForm.styles.jsx';
import _ from 'lodash';
import {EditScenarioCell} from "../cells/EditScenarioCell";
import {useForm} from "react-hook-form";

export const OptimizerForm = ({
  data,
  formData={},
  setFormData=()=>{},
  isLoading,
  setIsOptimized=()=>{},
  selectedMcaDashboardRun={},
  optimizerMap={},
  optimizedSelection,
  setOptimizedSelection
}) => {
  const {register, handleSubmit, errors, reset} = useForm();

  const hasData = useHasData(data);
  const tableData = useMappedData(data, hasData);
  let selectorColumnName = 'ROI';

  if(selectedMcaDashboardRun.kpiType === 'Units') {
    selectorColumnName = 'CPA';
  }

  const override = {
    variableName: {
      style: {
        width: '20%'
      }
    },
    selector: {
      name: selectorColumnName,
      style: {
        width: '20%'
      },
      component: (
        <OptimizerDropDown
          selectorColumnName={selectorColumnName}
          optimizerMap={optimizerMap}
          isLoading={isLoading}
          optimizedSelection={optimizedSelection}
          setOptimizedSelection={setOptimizedSelection}
          errors={errors}
          register={register}
        />
      )
    },
    mRoi: {
      name: 'Marginal ROI',
      isEditable: true,
      hidden: (selectorColumnName === 'CPA'),
      style: {
        width: '20%'
      },
      component:(
        <EditScenarioCell
          nameSuffix='_mRoi'
          formData={formData}
          isValidateObservedRange={false}
          isCurrency={true}
          errors={errors}
          register={register}
        />)
    },
    mCpa: {
      name: 'Marginal CPA',
      isEditable: true,
      hidden: (selectorColumnName === 'ROI'),
      style: {
        width: '20%'
      },
      component:(
        <EditScenarioCell
          nameSuffix='_mCpa'
          formData={formData}
          isValidateObservedRange={false}
          isCurrency={true}
          errors={errors}
          register={register}
        />)
    },
    budget: {
      isEditable: true,
      style: {
        width: '20%'
      },
      component:(
        <EditScenarioCell
          nameSuffix='_budget'
          formData={formData}
          isValidateObservedRange={false}
          isCurrency={true}
          errors={errors}
          register={register}
        />)
    }
  };

  const clearHandler = () => {
    setOptimizedSelection({});
    setFormData({});
    reset();
  };

  const formSubmit = (formData={}) => {
    setFormData(formData);
    addFormDataToSelection(formData, optimizedSelection, setOptimizedSelection);
    setIsOptimized(true);
  };

  return (
    <div className={styles.container}>
      <Chiclet id={'optimizer-form-chiclet'}>
        <div className={`optimizer-form-container ${styles.container}`}>
          <form onSubmit={handleSubmit(formSubmit)}>
            <FormRowLV expand={true}>
              <TableLV
                data={tableData}
                hasData={hasData}
                isLoading={isLoading}
                override={override}
                disableTableSort={true}
                dataTest='optimizer-form-table'
              />
            </FormRowLV>

            <FormRowLV className='optimizer-form-button-row'>
              <GreyButtonLV onClick={clearHandler}>
                Clear Scenarios
              </GreyButtonLV>

              <OrangeButtonLV
                isDisabled={!hasData || isLoading}
                dataTest={'optimize-button'}>
                Optimize
              </OrangeButtonLV>
            </FormRowLV>
          </form>
        </div>
      </Chiclet>
    </div>
  );
};

/**
 * Drop-down of ROI/CPA values for a given table row
 * @param cell Current cell information
 * @param optimizerMap Map of ROI/CPA values
 * @param isLoading loading status
 * @param selectorColumnName Column name to read: ROI/CPA
 * @param setOptimized Handles dropdown select
 * @returns populated Drop-down components
 */
const OptimizerDropDown = ({
  cell, optimizerMap={}, isLoading, selectorColumnName,
  optimizedSelection={},
  setOptimizedSelection=()=>{},
}) => {
  const variableNameKey = _.get(cell, 'row.values.variableName');
  const options = useOptimizerOptions(optimizerMap, variableNameKey, selectorColumnName);

  return React.useMemo(() => {
    // Handle drop-down select
    // Add selected value to our optimize map
    // Delete the value from our map if the X is pressed
    const setOptimized = (selection) => {
      if(!selection) {
        Reflect.deleteProperty(optimizedSelection, variableNameKey);
        setOptimizedSelection({
          ...optimizedSelection
        });
        return;
      }

      setOptimizedSelection({
        ...optimizedSelection,
        [variableNameKey]: selection
      });
    };

    return (
      <SelectLV
        name={variableNameKey}
        placeholder={`Select a ${selectorColumnName} value for ${variableNameKey}...`}
        isDark={false}
        options={options}
        isLoading={isLoading}
        width={0}
        className={'optimizer-selection'}
        onChange={setOptimized}
        defaultValue={optimizedSelection[variableNameKey]}
      />
    );    
  }, [variableNameKey, options, selectorColumnName, isLoading, optimizedSelection, setOptimizedSelection]);
};

/**
 * Create unique options list for drop-down
 * @param optimizerMap Map of ROI/CPA values
 * @param key Property name to read from optimizerMap
 * @param selectorColumnName Column name to read: ROI/CPA
 * @returns Array of options
 */
const useOptimizerOptions = (optimizerMap={}, key, selectorColumnName) => {
  return React.useMemo(() => {

    const rows = optimizerMap[key];
    if(!rows) {
      return undefined;
    }

    const optimizeValue = `rounded${selectorColumnName}`;

    // List only distinct rounded values
    const uniqueValues = rows
          .filter(r => !isNaN(r[optimizeValue]))
          .map(r => Number(r[optimizeValue]))
          .reduce((prev, current) => {
            if(prev.indexOf(current) > -1) {
              return prev;
            }
            return [...prev, current];
          }, []);

    if(!uniqueValues.length) {
      return undefined;
    }

    const sortedValues = _.sortBy(uniqueValues);

    // Map to drop-down options
    return sortedValues.map(u => ({
      value: u,
      label: numberWithCommas(u, true),
      name: optimizeValue,
      selectorColumnName,
      key
    }));

  }, [optimizerMap, key, selectorColumnName]);
};

/**
 * Create unique options list for drop-down
 * @param formData Map of ROI/CPA values
 * @param optimizedSelection current values selected
 * @param setOptimizedSelection Handles dropdown select
 * @returns Combined map of optimizedSelection and formData
 */
const addFormDataToSelection = (
  formData={},
  optimizedSelection={},
  setOptimizedSelection=()=>{},
) => {
  const filteredKeys = Object.keys(formData).filter(key => formData[key] !== '0');
  const variableNameKeys = filteredKeys.reduce((result, key) => {
    if(key.includes('_mRoi')){
      result.push(key.slice(0, key.indexOf('_mRoi')));
    }
    else if(key.includes('_mCpa')){
      result.push(key.slice(0, key.indexOf('_mCpa')));
    }
    else if(key.includes('_budget')){
      result.push(key.slice(0, key.indexOf('_budget')));
    }
    return result;
  }, []);
  
  variableNameKeys.map(varName => {
    if(optimizedSelection[varName]){
      optimizedSelection[varName] = {...optimizedSelection[varName],
        mRoi: Number(formData[varName + '_mRoi']),
        mCpa: Number(formData[varName + '_mCpa']),
        budget: Number(formData[varName + '_budget']),
      };
    }
    else{
      optimizedSelection[varName] = {
        mRoi: Number(formData[varName + '_mRoi']),
        mCpa: Number(formData[varName + '_mCpa']),
        budget: Number(formData[varName + '_budget']),
        key: varName
      };
    }
    setOptimizedSelection(optimizedSelection);
    return null;
  });
}

/**
 * Map/Filter table data to not include Base and Binary rows
 */
const useMappedData = (data, hasData) => {
  return React.useMemo(() => {
    if(!hasData) {
      return getDummyData(dummySpendSchema, 3);
    }

    return data
      .filter(excludeMeasure)
      .map(({variableName}) => ({
        variableName,
        selector: '',
        mRoi: 0,
        mCpa: 0,
        budget: 0
      }));
  }, [data, hasData]);
};

const useHasData = (data=[]) => {
  return React.useMemo(() => {
    return data.filter(excludeMeasure).length;
  }, [data]);
};

const measureExclusionArray = ['Base', 'Binary'];
const excludeMeasure = (row={}) => {
  return !measureExclusionArray.includes(row.measure);
};

const dummySpendSchema = {
  variableName: '',
  selector: '',
  mRoi: 0,
  mCpa: 0,
  budget: 0
};
