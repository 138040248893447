import {Popover} from "@mui/material";
import React from "react";
import {ImagePopoverContext} from "./context/ImagePopoverContext";

const ImagePopover = () => {

    const {
        imageUrl,
        alt,
        style,
        closePopover: closeImagePopover,
        isOpen: isImagePopoverOpen
    } = React.useContext(ImagePopoverContext);

    return (
        <Popover
            open={isImagePopoverOpen}
            onClose={closeImagePopover}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <img alt={alt} src={imageUrl} style={{style}}/>
        </Popover>
    );
};

export default ImagePopover;