import { createTheme } from '@mui/material/styles';
import '@fontsource/roboto';

/**
 * Primary theme pallete
 * Figma Reference: https://www.figma.com/file/c479lVWuwubiJn6t1ei9Qr/Material-UI-for-Figma-v4.13.0?node-id=38%3A4557
 */
export const theme = createTheme(({
  components: {
    MuiPopover: {
      styleOverrides: {
        root: {
          '& .r1-button-text:hover': {
            background: '#EFEFEF',
            borderRadius: 0,
          },
          '& .r1-action-icon:hover': {
            background: '#EFEFEF',
            borderRadius: 0,
          }
        },
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
          fontSize: '14px',
          fontWeight: 400
        },
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '& .MuiDataGrid-columnHeader': {
            '.MuiDataGrid-columnSeparator': {
              opacity: '0.1 !important',
            }
          },
          '& .MuiDataGrid-row': {
            borderTop: '1px solid #e0e0e0'
          }
        },
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '14px',
        },
      }
    },
    MuiModal: {
      styleOverrides: {
        root: {

          // Buttons in soggy-loaf Modals
          '& .button-row ': {
            gap: '12px !important'
          },
          '& .button-row .MuiButtonBase-root': {
            fontWeight: 500,
            height: '36px',
            padding: '6px 16px'
          }
        },

      }
    },
  },
  palette: {
    typography: {
      fontFamily: 'Roboto, sans-serif'
    },
    text: {
      primary: '#191919',
      secondary: '#737373',
      disabled: '#8C8C8C'
    },
    action: {
      disabledBackground: '#D9D8D9',
      disabled: '#B0B0AF',
    },
    primary: {
      light: '#254B7D',
      main: '#465A6D',
      dark: '#21313D',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#FF6B57',
      dark: '#ba000d',
      contrastText: '#FFF',
    },
    success: {
      main: '#4CAF50',
      dark: '#4CAF50',
      light: '#4CAF50',
      contrastText: '#FFF',
    },
    info: {
      main: '#2196F3',
      dark: '#0B79D0',
      light: '#64B6F7',
      contrastText: '#FFF',
    },
    warning: {
      main: '#FF9800',
      dark: '#C77700',
      light: '#FFB547',
      contrastText: '#1B1000',
    },
    error: {
      main: '#F44336',
      dark: '#E31B0C',
      light: '#F88078',
      contrastText: '#FFFFFF',
    },
    background: {
      default: '#FFF',
      paper: '#FFF'
    },
    divider: '#E0E0E0'
  },
  typography: {
    h1: {
      fontWeight: 500
    },
    h2: {
      fontWeight: 500
    },
    h3: {
      fontWeight: 500
    },
    h4: {
      fontWeight: 400
    },
    h5: {
      fontWeight: 400
    },
    h6: {
      fontWeight: 400
    }
  }

}));

export default theme;
