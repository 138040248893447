
import React from 'react';
import {
    R1ActionIcon,
    R1Button,
    R1Form,
    R1SubmitButton,
    R1TextBox,
    useR1Form
} from '@rainagency/rain-one-soggy-loaf';
import { usePatch } from '../../../hooks/useRest';
import { urlBuilders } from '../../../js/constants';
import { useSnackbarLV } from '../../../components/snackbar/useSnackbarLV';

/**
 * Edit Scoring Run action on grid popover
 */
export const EditScoringRunActionIcon = ({
                                      fetchScoringRuns,
                                      cell,
                                      closePopover
                                  }) => {

    return (
        <R1ActionIcon
            iconType='Edit'
            label='Edit Scoring Run'
            ModalContent={
                <EditRunForm
                    closePopover={closePopover}
                    cell={cell}
                    fetchScoringRuns={fetchScoringRuns}
                />
            }
            ModalProps={{
                title: 'Edit Scoring',
                description: '',
                width: '600px',
                disableBackdropClick: true
            }}
        />
    );

};

/**
 * Edit run form
 * Display snackbar alert on success or error
 */
const EditRunForm = ({
                         cell,
                         fetchScoringRuns,
                         closePopover,
                         closeModal
                     }) => {

    const [{
        response,
        error
    }, updateScoring] = usePatch({
        url: urlBuilders.updateScoring(cell.row.id),
        data: {},
    });

    const {
        enqueueSnackbar,
        enqueueCloseSnackbarLV,
    } = useSnackbarLV();

    // Handle data returning
    React.useEffect(() => {
        if(!response) {
            return;
        }

        if(response.status >= 200 && response.status <= 299) {
            enqueueSnackbar(
                `Successfully updated scoring ${cell.row.name}`,
                {
                    variant: 'success'
                }
            );
        }

        fetchScoringRuns();
        closeModal();
        closePopover();

    }, [response]);

    // Display error
    React.useEffect(() => {
        if(!error) {
            return;
        }

        enqueueCloseSnackbarLV(
            `Error updating scoring analysis: ${cell.row.name}, please contact Technical Support`,
            {
                variant: 'error',
                persist: false,
                autoHideDuration: 10000
            }
        );

        closeModal();
        closePopover();

    }, [error]);

    // Get all run names from our grid
    const runNames = cell.api.getAllRowIds()
        .map(id => cell.api.getCellValue(id, 'name'));

    const defaultValues = {
        id: cell.row.id,
        name: cell.row.name
    };

    const handleCancel = () => {
        closeModal();
        closePopover();
    };

    const submitHandler = (values) => {
        updateScoring({
            data: {
                name: values.name
            }
        });
    };

    const {
        methods
    } = useR1Form({defaultValues});

    return (
        <R1Form
            methods={methods}
            customStyles={{
                cursor: methods.formState.isSubmitted ? 'wait' : 'auto'
            }}
            onSubmit={submitHandler}>

            <R1TextBox
                aria-label="Edit Scoring Name"
                label="Scoring Name"
                data-test={'analysisName-TextField'}
                name="name"
                rules={{
                    required: 'Scoring Name is required.',
                    maxLength: {
                        value: 250,
                        message: 'Cannot exceed 250 characters'
                    },
                    validate: value => !runNames.includes(value) ||
                        'Scoring name already exists. Please enter a unique scoring name.'
                }}
            />

            <div className="button-row">

                <R1Button
                    color={'inherit'}
                    variant={'text'}
                    onClick={handleCancel}>
                    Cancel
                </R1Button>

                <R1SubmitButton>
                    Submit
                </R1SubmitButton>

            </div>

        </R1Form>
    );
};
