export const TV_WITH_CUSTOM_OPTION = {value:'tv_with_custom', label:'Linear TV'};
export const TV_NO_CUSTOM_OPTION = {value:'tv_no_custom', label:'Linear TV'};
export const DIGITAL_VIDEO_OPTION = {value:'digital_video', label:'Digital Video'};
export const OTT_OPTION = {value:'ott', label:'OTT'};
export const FACEBOOK_OPTION = {value:'facebook', label:'Social Media'};
export const TERRESTRIAL_RADIO_OPTION = {value:'terrestrial_radio', label:'Terrestrial Radio'};
export const DIGITAL_AUDIO_OPTION = {value:'digital_audio', label:'Digital Audio'};

export const  ANALYSIS_OPTIONS = [
  TV_WITH_CUSTOM_OPTION, 
  TV_NO_CUSTOM_OPTION, 
  DIGITAL_VIDEO_OPTION, 
  OTT_OPTION,
  FACEBOOK_OPTION,
  TERRESTRIAL_RADIO_OPTION, 
  DIGITAL_AUDIO_OPTION
];

export const RUN_TYPES = {
  TV_WITH_CUSTOM: TV_WITH_CUSTOM_OPTION.value, 
  TV_NO_CUSTOM: TV_NO_CUSTOM_OPTION.value, 
  DIGITAL_VIDEO: DIGITAL_VIDEO_OPTION.value, 
  OTT: OTT_OPTION.value,
  FACEBOOK: FACEBOOK_OPTION.value,
  TERRESTRIAL_RADIO: TERRESTRIAL_RADIO_OPTION.value, 
  DIGITAL_AUDIO: DIGITAL_AUDIO_OPTION.value
};
