import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import Button from '@mui/material/Button';

import withHTTP from '../../hoc/with-http';
import checkMark from '../../assets/circle_check_mark.svg';
import crossMark from '../../assets/circle_cross_mark.svg';
import cloudDownload from '../../assets/cloud_download.svg';

import Spinner from '../../components/spinner';

import { urlBuilders } from '../../js/constants';
import { useGet } from '../../hooks/useRest';
import { useCampaignManagement } from '../../hooks/useCampaignManagement';
import { downloadFromURL } from '../../js/download';

import {getSelectedCampaign} from '../../modules/platform/campaign.js';
import {
    clearDownloadErrors,
    GET_GROUPING_TEMPLATE_STATUS,
    getGroupingTemplateStatus,
    getGroupingTemplateUrl,
    getSpinner,
    getDownloadErrors
} from '../../modules/platform/downloadTemplate.js';

import {styles} from './file-upload-modal.styles.js';

import _ from 'lodash';

class FileUploadComplete extends React.Component {
    constructor(props) {
        super(props);
        this.count = 0;
        this.state = {errors : ''};
    }

    UNSAFE_componentWillMount() {
        this.props.dispatch(clearDownloadErrors());
    }

    render() {
        let closeHandler = this.props.handleCancel;
        const errors = this.props.errors.length > 0 ? this.props.errors : this.state.errors;
        const uploadStatusIcon = this.props.errors.length > 0 ? crossMark : checkMark;
        const successTitle = "Data Uploaded";
        const errorTitle = "Error Uploading";
        const statusTitle = this.props.errors.length > 0 ? errorTitle : successTitle;
        const successMessage = this.props.values.file.name + " has been successfully uploaded.";
        const errorMessage = "There was an error uploading your file.";
        const statusMessage = this.props.errors.length > 0 ? errorMessage : successMessage;

        // Trigger optional success function if no errors
        if(this.props.errors.length <= 0) {
            closeHandler = () => {
                this.props.successHandler(this.props.values.file.name, this.props.values.hasKpi);
                this.props.handleCancel();
            };
        }

        return (
            <div className={styles.completeContainer}>
                <img src={uploadStatusIcon} alt='checkmark'/>
                <div className={styles.completeTitle}>{statusTitle}</div>
                <p>{statusMessage}</p>

                <ErrorSection
                    type={this.props.values.type}
                    errors={errors}
                />

                <Button variant='contained' className={styles.closeButton} onClick={closeHandler}> CLOSE </Button>
            </div>
        );
    }
};

const ErrorSection = ({
    type,
    errors
}) => {
    if(errors) {
        return errors;
    } else {
        return null;
    }
    ;
};

FileUploadComplete.propTypes = {
    handleCancel: PropTypes.func.isRequired,
    groupingTemplateStatus: PropTypes.string,
    groupingTemplateUrl: PropTypes.string,
    spinner: PropTypes.bool,
    errors: PropTypes.array
};

const mapStateToProps = (state) => {
    return {
        campaignId: getSelectedCampaign(state),
        groupingTemplateStatus: getGroupingTemplateStatus(state),
        groupingTemplateUrl: getGroupingTemplateUrl(state),
        spinner: getSpinner(state),
        errors: getDownloadErrors(state)
    };
};

export default withHTTP(connect(mapStateToProps)(FileUploadComplete));
