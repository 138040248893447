import React from 'react';
import {useMcaRuns} from '../../../hooks/useMcaRuns';
import {ContributionDashboard} from "./ContributionDashboard";

export const ContributionDashboardWrapper = ({}) => {

    const {selectedMcaRun = {}, mcaRuns} = useMcaRuns();
    const [nestedRunIds, setNestedRunIds] = React.useState([])
    const {id} = selectedMcaRun;


    React.useEffect(() => {
        if (mcaRuns?.length) {
            const nestedRunIds = mcaRuns
                .filter(run => run.parentId === id && run.runStatus === 'FINAL')
                .map(run => run.id) || [];

            setNestedRunIds(nestedRunIds)
        }
    }, [id, mcaRuns]);

    const CombinedContributionDashboard = React.useMemo(() => {
        if (nestedRunIds.length) {
            return (
                <ContributionDashboard
                    mcaRuns={mcaRuns}
                    selectedMcaRun={selectedMcaRun}
                    nestedRunIds={nestedRunIds}
                />
            )
        }
    }, [nestedRunIds]);

    return (
        <div>
            {CombinedContributionDashboard}
            <ContributionDashboard
                mcaRuns={mcaRuns}
                selectedMcaRun={selectedMcaRun}
            />
        </div>
    );
};