import PropTypes from 'prop-types';

/**
 * Common Utility for React Forms
 */
export default class FormUtilLV {
  /**
   * Add a width style to a react component
   * @param width width in px to add to component
   * @param style current style React object
   * @return Style object with width property
   */
  static addWidth(width, style) {
    if(!width) {
      return style;
    }

    let newStyle = style || {};
    newStyle['width'] = `${width}px`;
    return newStyle;
  }

  /**
   * Change text to title case
   * @param text text to add to component
   * @return Style object with requested property
   */
  static titleCase(text) {
    return text.toLowerCase().split(' ').map(word =>
      word.replace(word[0], word[0].toUpperCase())
    ).join(' ');
  }

}

FormUtilLV.propTypes = {
  /**
   * Override standard width of element
   */
  width: PropTypes.number,
  /**
   * React component style
   */
  style: PropTypes.object,
};

export {FormUtilLV}
