import {css} from 'emotion';
import topLeftDots from '../../../assets/top_left_dots.svg';

export const styles = {
    page: css`
        display: flex;
        align-self: stretch;
        flex-grow: 1;
        justify-content: left;
    `,
    pageLeft: css`
        display: flex;
        width: 184px;
        padding: 0 42px;
        background-color: #212E3A;
        align-items: center;
        border-radius: 20px 0 0 20px;

        background-image: url('${topLeftDots}');
        background-repeat: no-repeat;
        background-size: 511px 277px;
    `,
    pageRight: css`
        display: flex;
        background-color: #2A3E50;
        border-radius: 0 20px 20px 0;
        width: 100%;
        overflow: auto;
        .model-filter-form-container {
          width: 100%;
          display: flex;
          padding-left: 42px;
          max-height: 780px;
        }

        background: radial-gradient(
            ellipse at 118% 92%,
            #a4d47d 0%,
            #7e9292 40%,
            #374f63 95%
        );
        color: white;
    `,
    countContainer: css`
        border-radius: 50%;
        background-color: #273541;
        border: #60666A .5px solid;
        width: 184px;
        height: 184px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    `,
    count: css`
        padding-top: 18px;
    `,
    countHeaderLarge: css`
        font-size: 34px;
        line-height: 40px;
    `,
    countHeaderSmall: css`
        font-size: 14px;
        line-height: 24px;
    `,
    countText: css`
        overflow-x: visible;
        white-space: nowrap;
        color: #FFFFFF;
        margin-top: 0;
    `,
    progress: css`
        border-color: #a7da7b;
        position: absolute;
    `
};
