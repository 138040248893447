import React from 'react';
import { urlBuilders } from '../js/constants';
import { useGet } from './useRest';
import { singletonHook } from 'react-singleton-hook';

/**
 * Return current logged in user info from full user list
 * Also return authorization groups this user is subscribed to
 */
const useLoggedInUserImpl = () => {
  
  const [{data={}, error}] = useGet({
    url: urlBuilders.getUserByUsername('me'),
  });

  const user = React.useMemo(()=> {
    if (!!data) {
      return data;
    }
  },[data]);

  return {
    currentUser: user,
    group: user?.groups
  };
};

export const useLoggedInUser = singletonHook({
  loading: true
}, useLoggedInUserImpl)
