import React from 'react';
import {ButtonLV} from './ButtonLV';
import {styles} from './button.styles';

class GreenButtonLV extends React.Component {
  render() {
    return (
      <ButtonLV
        className={styles.green}
        {...this.props} />
    );
  }
}

export {GreenButtonLV};
