import React from 'react';
import PropTypes from 'prop-types';

import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import {styles} from './cancel-run.styles.js';

import {closeDialog} from '../../../../modules/platform/dialog';

import {urlBuilders} from '../../../../js/constants';
import {usePut} from '../../../../hooks/useRest';

export default function CancelRunDialog({runId, runName, runVersion, getRefreshClickHandler, dispatch}) {
    const isCancelled = React.useRef(false);
    const refreshTable = getRefreshClickHandler();

    // Manual call to cancel run. Does not receive any response.
    const [{loading}, cancelMcaRun] = usePut({
        url: runVersion === 'V3' ? urlBuilders.updateV3McaRunStatus(runId) : urlBuilders.updateV2McaRunStatus(runId),
        data: { runStatus: "CANCELLED" },
        manual: true
      });
    
    const cancelRun = () => {
        cancelMcaRun();
        isCancelled.current = true;
    };

    React.useEffect(() => {
        if (!loading && isCancelled.current) {
            refreshTable();
            dispatch(closeDialog());
        }
    }, [loading, getRefreshClickHandler, refreshTable, dispatch]);

    return (
        <div data-test="CancelRunDialog-Dialog" className={loading ? styles.waiting : ''}>
            <div>
                <DialogTitle classes={{root:styles.title}} data-test="CancelRunDialog-DialogTitle"> Cancel Run? </DialogTitle>
            </div>
            <div className={styles.body} data-test="CancelRunDialog-AreYouSureMessage">
                Are you sure you want to cancel run {runName}?
            </div>
            <div className={styles.body} data-test="CancelRunDialog-ProcessingMessage">
                The processing of this run will stop, and cannot be restarted.
            </div>
            <div className={styles.buttonContainer}>
                <Button variant='contained' 
                        className={styles.cancelButton} 
                        onClick={() => dispatch(closeDialog())}
                        data-test="CancelRunDialog-CancelButton"> KEEP RUNNING </Button>
                <Button variant='contained' 
                        className={styles.submitButtonActive} 
                        onClick={cancelRun} 
                        data-test="CancelRunDialog-CancelRunButton"> CANCEL RUN </Button>
            </div>
        </div>
    );
    
}

CancelRunDialog.propTypes = {
    mcaRunId: PropTypes.string,
    runName: PropTypes.string,
    dispatch: PropTypes.func,
    getRefreshClickHandler: PropTypes.func
};