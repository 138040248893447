import {css} from 'emotion';

export const getRadioStyles = (isDark=true) => {
  let baseColor = '#FFF';
  let checkedColor = '#a7da7b';
  let unCheckedColor = baseColor;

  if(!isDark) {
    baseColor = '#333';
    checkedColor = '#15386D';
    unCheckedColor = '#15386D';
  }

  return {
    disabledRadio: css`
        font-size: 12px !important;
        opacity: 0.5 !important;
        color: ${baseColor} !important;
    `,
    radioButton: css`
        font-size: 12px !important;
        font-weight: 400 !important;
        color: ${baseColor} !important;
    `,
    checkedRadio: css`
        color: ${checkedColor} !important;
    `,
    uncheckedRadio: css`
        font-weight: 400 !important;
        opacity: 0.5 !important;
        color: ${unCheckedColor} !important;
    `
  };
};
