import React from 'react';
import {useUploadedFileJson} from '../../../../../hooks/useUploadedFileJson';
import {FILE_TYPE_MAP} from '../../../../../constants/file-types';
import {getMinMaxDates} from '../../../../../js/utils';
import moment from 'moment';

/**
 * Get the date range from uploaded KPI file
 * @returns {start, end} start and end dates in Date() format
 */
export const useUploadedDateRange = () => {
  const {
    fileJson: kpiFileJson,
    isLoading: kpiIsLoading
  } = useUploadedFileJson(FILE_TYPE_MAP.KPI);

  const {start: kpiStart, end: kpiEnd} = React.useMemo(() => {
    return getMinMaxDates(kpiFileJson, 'Date', 'MM/DD/YYYY');
  }, [kpiFileJson]);

  const {
    fileJson: mvFileJson,
    isLoading: mvIsLoading
  } = useUploadedFileJson(FILE_TYPE_MAP.MEDIA_VEHICLE);

  const {start: mvStart, end: mvEnd} = React.useMemo(() => {
    return getMinMaxDates(mvFileJson, 'Date', 'MM/DD/YYYY');
  }, [mvFileJson]);

  const isLoading = kpiIsLoading || mvIsLoading;

  let formattedStart;
  let formattedEnd;

  const start = returnInnerDate(kpiStart, mvStart, true);
  const end = returnInnerDate(kpiEnd, mvEnd, false);

  if(start) {
    formattedStart = moment(start).format('MM/DD/YYYY').toString();
  }

  if(end) {
    formattedEnd = moment(end).format('MM/DD/YYYY').toString();
  }

  return {
    isLoading,
    start,
    end,
    formattedStart,
    formattedEnd,
  };
};
/**
 * Helper function that takes in two dates to compare and returns the earlier / latter date based on the given boolean.
 * If a date is missing, then it returns the date that is not missing. 
 * @param {string} dateOne first date to compare
 * @param {string} dateTwo second date to compare
 * @param {boolean} startDate whether the code should provide the latter date (true) or the earlier date (false)
 * @returns one of the passed in dates
 */
const returnInnerDate = (dateOne, dateTwo, startDate = false) => {
  let innerDate;
  if (dateOne && dateTwo && startDate) {
    innerDate =  moment(dateOne).isAfter(dateTwo) ? dateOne : dateTwo;
  } else if (dateOne && dateTwo && !startDate) {
    innerDate =  moment(dateOne).isAfter(dateTwo) ? dateTwo : dateOne;
  }else if (!dateTwo) {
    innerDate = dateOne;
  } else {
    innerDate = dateTwo;
  }
  return innerDate;
}