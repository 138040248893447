import {sentenceCase, capitalCase} from 'change-case';

/**
 * Takes the keys from the first data item and creates
 * column headers for a react-table
 * EXAMPLE:
 * https://github.com/tannerlinsley/react-table/blob/master/examples/basic/src/App.js#L79
 * @param data Data-set to display in grid
 * @param groups {array} Allow 2 rows of headers with one row stacked above the other
 *               REFERENCE: http://storybook.leavened.io/?path=/story/table--multiple-header-rows
 *               EXAMPLE: [{
 *                 name: 'Scenario1'  // Header text to display
 *                   columns: [scenario1CostPer, scenario1Volume] // Column keys to put below Header display text
 *               }, {
 *                 name: 'Scenario2'
 *                 columns: [scenario2CostPer, scenario2Volume]
 *               }]
 * @returns react-table columns
 */
export const createHeaderColumnsFromData = (data, groups) => {
  if(!data || !Array.isArray(data) || data.length <= 0) {
    return [];
  }
  let columns = createHeaderObjectsFromDataRow(data[0]);

  // Add Header Groups
  // EXAMPLE: https://react-table.tanstack.com/docs/examples/grouping-column
  if(Array.isArray(groups) && groups.length > 0) {
    columns = groups.concat(columns.reverse()).reduce((cols, column, index) => {
      // If this is a header group, create column group array
      if(Array.isArray(column.columns) && column.columns.length > 0) {
        return [
          ...cols,
          ...[{
            Header: `${column.name}_${index}`, // Make Header unique as duplicate headers will not display
            isMultiColumnGroup: true,
            headerGroupDisplay: column.name,
            groupAlign: column.align,
            columns: column.columns.map(keyToColumn),
            colSpan: column.columns.length
          }],
        ];
      }

      // If this column is already part of a header group, remove the column from the list
      const hasGroupColumn = cols.some(
        c => Array.isArray(c.columns) && c.columns.some(child => child.accessor === column.accessor)
      );
      if(hasGroupColumn) {
        return [
          ...cols.filter(c => c.accessor !== column.accessor)
        ];
      }

      // Turn single columns into header column so rowspans will work in 2 header rows
      return [
        ...[{
          Header: column.Header,
          isSingleColumnGroup: true,
          accessor: column.accessor,
        }],
        ...cols,
      ];
    }, []);
  }

  return columns;
};

function keyToColumn(key) {
  return {
    Header: capitalCase(sentenceCase(key)),
    accessor: key,
  };
}

function createHeaderObjectsFromDataRow(dataRow) {
  return Object.keys(dataRow).map(element => { 
    const testVal = dataRow[element] ? dataRow[element].toString().replace(/,/g, '') : null;
    if (!Number.isNaN(Number.parseFloat(testVal))) {
      return {
        Header: capitalCase(sentenceCase(element)),
        accessor: element,
        sortType: compareNumericString
      }
    } else { 
      return {
        Header: capitalCase(sentenceCase(element)),
        accessor: element,
      }
    }
  })
}
// Stolen from https://github.com/TanStack/table/issues/1793#issuecomment-981588567
function compareNumericString(rowA, rowB, id, desc) {
  const a = Number.parseFloat(String(rowA.values[id]).replace(/,/g, ''));
  const b = Number.parseFloat(String(rowB.values[id]).replace(/,/g, ''));
  if (Number.isNaN(a)) {  // Blanks and non-numeric strings to bottom
      a = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
  }
  if (Number.isNaN(b)) {
      b = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
  }
  if (a > b) return 1; 
  if (a < b) return -1;
  return 0;
}