import {css} from 'emotion';
import {commonStyles} from '../../styles/commonStyles';

export const styles = {
  modal: css`
      background: transparent !important;
    `,
  modalHeader: css`
    font-size: 24px;
    color: #374f63;
    padding: 24px;
    background: white;
    border-radius: 10px     10px      0           0;

  `,
  container: css`
    height: 100%;
    overflow: hidden; 
  `,
  stack: css`
    height: 60%;
    padding: 20px;
  `,
  separator: css`
    padding: 10px;
    color: transparent;
  `,
  gridSide: css`
    width: 50%;
  `,
  modelContainer: css`
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.3);
    background: white;
    border-top: 10px solid #80807F;
    border-radius: 10px;
    padding-bottom: 10px;
    width: 100%;
    max-height: 100%;
    overflow: scroll;
  `,
  tunedContainer: css`
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.3);
    background: white;
    border-top: 10px solid #57B95E;
    border-radius: 10px;
    padding-bottom: 10px;
    width: 100%;
    max-height: 100%;
    overflow: scroll;
  `,
  alreadyTunedContainer: css`
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.3);
    background: white;
    border-top: 10px solid #57B95E;
    border-radius: 10px;
    padding-bottom: 10px;
    width: 100%;
    max-height: 100%;
    overflow: hidden;
    filter: blur(2px) brightness(90%);
  `,
  modelCalc: css`
    font-size: 10px;
    color: #7e9292;
    text-align: left;
    padding-left: 24px;
    padding-right: 24px;
    border-right: 1px solid rgba(126, 146, 146, 0.25);
    display: flex;
    align-items: flex-end;
    width: 60px;
    .calculation {
      font-size: 14px;
      color: rgb(55, 79, 99);
    }
  `,
  dropdown: css`
    display: flex;
    align-items: flex-end;
    margin-right: 50px;
    width: 200px;
  `,
  topBar: css`
    display: flex;
    top: 0px;
    background: #FFF;
    z-index: 1;
    padding-top: 25px;
    padding-bottom: 20px;
    height: 20px;
  `,
  modelTitle: css`
    font-size: 24px;
    color: #374f63;
    align-self: flex-end;
    height: 90px;
    padding-left: 24px;
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
  `,
  tableContainer: css`
    padding: 10px;
    border-top: 1px solid rgba(126, 146, 146, 0.25);
  `,
  tableStyles: css`
    th {
      height: 40px;
    }
    td:first-child {
      padding-left: 12px;
    }
    td {
      height: 30px;
      color: rgb(55, 79, 99);
      font-size: 14px;
      border-bottom: 1px solid rgba(224, 224, 224, 1);
    }
  `,
  alreadyTunedAlert: css `
    background: #4A94EC;
    border-radius: 5px;
    width: 300px;
    padding: 10px;
    position: relative;
    left: 25%;
    top: -30%;
    text-align: center;
  `,
  buttonContainer: css `
    float: right;
    padding-top: 20px;
    padding-right: 20px;
    align-self: flex-right;
    width: 400px;
  `,
  originalButton: css `
    float: left;
    background: #304658;
  `,
  primaryOriginalButton: css `
    float: left;
    background: #EE735F;
  `,
  tunedButton: css `
    float: right;
    background: #EE735F;
  `,
  cssGridBlock: css`
    width: 20px;
    display: inline-block;
  `,
  confirmContainer: css `
    position: absolute;
    top: 40%;
    left: 40%;
    margin: auto;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.3);
    background: #F4F4F5;
    border-radius: 5px;
    width: 30%;
  `,
  confirmHeader: css `
  color: black;
  font-size: 18px;
  font-weight: bold;
  padding: 15px;
  `,
  confirmBody: css `
  color: black;
  font-size: 14px;
  padding: 15px;
  `,
  confirmCancelButton: css `
    float: left;
    background: #F4F4F5;
    font-color: primary; 
  `,
  confirmTunedButton: css `
  float: right;
  background: secondary;
  `,
  x: css`
    cursor: pointer;
    width: 22px;
    height: 22px;
    position: absolute;
    right: 0;
    margin: 24px 24px 0 0;
    z-index: 10;
  `,
};
