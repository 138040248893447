import React from 'react';
import {
  R1ActionIcon
} from '@rainagency/rain-one-soggy-loaf';
import {
  usePostTriggerDownloadUrl,
} from '../../../../hooks/useTriggerDownloadUrl';
import { urlBuilders } from '../../../../js/constants';

const MEDIA_GROUPING_FILE_NAME = 'media_grouping.csv';

/**
 * Displays action icon for MMM data grid
 * Download configuration of the run in the NewRun Modal
 */
export const DownloadRunConfigurationIcon = ({
  cell,
  closePopover
}) => {
  const {id, name} = cell.row;
  const {
    triggerDownload,
    loading
  } = usePostTriggerDownloadUrl({
    method: 'POST',
    requestUrl: urlBuilders.postMcaRunFileUrl(id),
    requestData: {fileName: MEDIA_GROUPING_FILE_NAME},
    urlObjectPath: 'url',
    onFinal: closePopover
  });

  const handleClick = () => {
    triggerDownload({
      fileName: `${name}_${MEDIA_GROUPING_FILE_NAME}`
    });
  };

  return (
    <R1ActionIcon
      iconType='View'
      label='Analysis Configuration'
      onClick={handleClick} 
      customStyles={{
        cursor: loading ? 'wait' : 'pointer'}}
    />
  );

};
