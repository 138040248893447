import React from 'react';

import {ForgotPassword} from 'aws-amplify-react';
import { Auth } from 'aws-amplify';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {snackbar} from './modules/platform/snackbar';
import Snackbar from './components/snackbar';
import {LogoLV} from './components/LogoLV';
import topDots from './assets/top_left_dots.svg';
import bottomDots from './assets/bottom_right_dots.svg';
import {styles} from './signin.styles.js';

const auth = 'forgotPassword'

class ResetPassword extends ForgotPassword {
    constructor(props) {
        super(props);
        this._validAuthStates = [auth];
        this.state = {username: "", code: "", password: "", auth};
        this.handleInputChange = this.handleInputChange.bind(this);
        this.submitForgotPassword = this.submitForgotPassword.bind(this);
        this.submitConfirmPassword = this.submitConfirmPassword.bind(this);
        this.handleClickBackToSignIn = this.handleClickBackToSignIn.bind(this);
    }
    
    submitForgotPassword(event) {
        event.preventDefault();
        Auth.forgotPassword(this.state.username)
            .then(() => {
                this.setState({auth: "confirmPassword"});
            })
            .catch(err => {
                this.props.store.dispatch(snackbar('error', err.message));
            });
    }

    submitConfirmPassword(event) {
        event.preventDefault();
        Auth.forgotPasswordSubmit(this.state.username, this.state.code, this.state.password)
            .then(() => {
                super.changeState("signIn");
            })
            .catch(err => {
                this.props.store.dispatch(snackbar('error', err.message));
        });
    }
    
    handleInputChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    handleClickBackToSignIn() {                    
        this.setState({auth});
        super.changeState('signIn');   
    }
    
    forgotPasswordForm() {
        return (
            <form className={styles.formContainer} onSubmit={this.submitForgotPassword}>
              <LogoLV
                data-test="reset-password-leavened-logo"
                className={styles.logo}
                isLarge={true}
              />
                <TextField
                    id="username"
                    key="username"
                    name="username"
                    label="Username"
                    placeholder="Username"
                    type="text"
                    InputProps={{
                        className: styles.lightField
                    }}
                    InputLabelProps={{
                        className: styles.lightField,
                        shrink: true
                    }}
                    autoFocus
                    autoComplete=''
                    onChange={this.handleInputChange}
                />
                
                <Button
                    className={styles.submitButton}
                    type="submit">
                    Send Code
                </Button>
                
                <p className={styles.passwordForgotLink}>
                    <span onClick={this.handleClickBackToSignIn}>
                        Back to Sign In
                    </span>
                </p>
            </form>
        );
    }

    confirmPasswordForm() {
        return (
            <form className={styles.formContainer} onSubmit={this.submitConfirmPassword}>
              <LogoLV
                className={styles.logo}
                isLarge={true}
              />
                <TextField
                    id="code"
                    key="code"
                    name="code"
                    label="Code"
                    placeholder="Code"
                    type="text"
                    InputProps={{
                        className: styles.lightField
                    }}
                    InputLabelProps={{
                        className: styles.lightField,
                        shrink: true
                    }}
                    autoFocus
                    autoComplete=''
                    onChange={this.handleInputChange}
                />
                <TextField
                    id="password"
                    key="password"
                    name="password"
                    label="New Password"
                    placeholder="******************"
                    type="password"
                    InputProps={{
                        className: styles.lightField
                    }}
                    InputLabelProps={{
                        className: styles.lightField,
                        shrink: true
                    }}
                    autoComplete=''
                    onChange={this.handleInputChange}
                />
                <Button
                    variant="extendedFab"
                    className={styles.submitButton}
                    type="submit">
                    Submit
                </Button>
                
                <p className={styles.passwordForgotLink}>
                    <span onClick={this.handleClickBackToSignIn}>
                        Back to Sign In
                    </span>
                </p>
            </form>
        );
    }

    showComponent() {
        const forgotPasswordForm = this.forgotPasswordForm();
        const confirmPasswordForm = this.confirmPasswordForm();
        return (
            <div className={styles.loginContainer}>
                <img className={styles.topDots} src={topDots} alt="top dots" />
                {this.state.auth === auth ? forgotPasswordForm : confirmPasswordForm}
                <img className={styles.bottomDots} src={bottomDots} alt="bottom dots" />
                <Snackbar store={this.props.store}/>
            </div>
        );
    }
}

export default ResetPassword;
