import React from 'react';
import ReactMarkdown from 'react-markdown';
import Image from "../../../components/images/Image";


const Markdown = ({
    payload: {
        text
    } = {}
                  }) => {
    return (
        <div style={{
            'max-width': '600px',
            width: '100%'
        }}>
            <ReactMarkdown
                children={text}
                components={{
                    img: (node, inline, className, children, ...props) => {
                        return (<Image imageUrl={node.src} alt={node.alt} popoverStyle={{width: '1200px', height: '100%'}}/>)
                    }
                }}
            />
        </div>
    );
};

export default Markdown;