import {combineReducers} from 'redux';

import run from './run.js';
import edit from './edit.js';
import models from './models.js';
import filter from './filter.js';
import kpi from './kpi.js';
import insights from './outputs-insights.js';
import variableNameTranslator from './variable-name-translator.js';
import mediaVehicleDashboard from './media-vehicle-dashboard.js';

const mca = combineReducers({
    filter,
    models,
    run,
    edit,
    kpi,
    insights,
    variableNameTranslator,
    mediaVehicleDashboard,
});

export default mca;
