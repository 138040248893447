import React from 'react';
import PropTypes from 'prop-types';
import {BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer} from 'recharts';
import {CustomTooltip} from './CustomTooltip';
import {EllipsesAxisTick} from './EllipsesAxisTick';
import {formatAxis} from './chart-format.util';
import {CHART_FORMAT} from './ChartFormat.constant';
import {useValidateChartData} from './useValidateChartData';
import {styles, getChartStateCss} from './charts.styles';
import _ from 'lodash';

const BAR_COLORS = {
  RED: '#FF0000',
  DEFAULT: '#8884d8',
  WHITE: '#FFF',
  GREY: '#D3D3D3'
};

/**
 * Builds a Waterfall chart of the contribution data,
 * http://recharts.org/en-US/api/BarChart
 */
export const WaterfallChartLV = ({
  data, yAxisLabel='', yAxisKey='', isLoading=false, formatMap={}
}) => {
  // Hide unique waterfall rows in tooltip
  const tooltipMap = {
    positive: CHART_FORMAT.HIDDEN,
    negative: CHART_FORMAT.HIDDEN,
    start: CHART_FORMAT.HIDDEN,
    base: CHART_FORMAT.HIDDEN,
    ...formatMap
  };

  const chartData = useValidateChartData(data, 5, 'percentage', yAxisKey);
  const chartStateCss = getChartStateCss(data, isLoading);
  const sortedData = _.sortBy(chartData, yAxisKey).reverse();
  const base = sortedData.find(d => (d.name.toLowerCase() === 'base'));

  // Add base to beginning of array
  let vehicles = sortedData;
  if(base) {
    vehicles = sortedData.filter(d => (d.name !== base.name));
    vehicles.unshift(base);    
  }

  // Adds positive/negative properties to rows to be drawn by chart
  // 'start' property is the current value for graph to start drawing that bar
  let waterMark = 0; // Track height of bar height
  const waterfallData = vehicles.map((vehicle) => {
    const waterfallVehicle = {
      name: vehicle.name,
      value: vehicle.value
    };

    if(vehicle.value < 0) {
      waterfallVehicle.negative = vehicle.value;
    } else {
      waterfallVehicle.positive = vehicle.value;
    }

    // Base Bar should be grey, so add this attribute to starting Box
    if(base ? (vehicle.name === base.name) : (waterMark === 0)) {
      waterfallVehicle.base = waterfallVehicle.positive;
      delete waterfallVehicle.positive;
    } else {
      waterfallVehicle.start = waterMark;
    }

    waterMark += vehicle.value;
    return waterfallVehicle;
  });

  const classNames = [styles.chart, chartStateCss].join(' ');

  return (
    <ResponsiveContainer
      width={'100%'}
      height={400}
      debounce={1}
      id='waterfall-chart'
      className={classNames}>

      <BarChart
        data={waterfallData}
        margin={{top: 10, right: 0, left: 0, bottom: 15}}
        barGap='0'
        barCategoryGap='0%' >

        <XAxis 
          dataKey="name" 
          angle={-90} 
          height={100}
          textAnchor="end" 
          interval={0}
          tick={<EllipsesAxisTick axis={'x'} ellipses={true}/>}
          />
        <YAxis
          width={100}
          tickMargin={10}
          axisLine={false}
          tickSize={0}
          tickCount={11}
          tickFormatter={formatAxis(yAxisKey, formatMap)}
          label={{
            value: yAxisLabel,
            angle: -90,
            position: 'insideLeft'
          }}/>

        <Tooltip
          isAnimationActive={false}
          content={<CustomTooltip map={tooltipMap} />}
          cursor={false}/>

        <Bar dataKey="start" stackId='a' fill={BAR_COLORS.WHITE}/>
        <Bar dataKey="positive" stackId='a' fill={'#2A5588'}/>
        <Bar dataKey="negative" stackId='a' fill={'#FF7863'}/>
        <Bar dataKey="base" stackId='a' fill={BAR_COLORS.GREY}/>
      </BarChart>
    </ResponsiveContainer>
  );
};

WaterfallChartLV.propTypes = {
  /**
   * EXAMPLE:
   * [{name: 'Slice1', value: 1234.0345, percentage: 45.456}]
   */
  data: PropTypes.array,
  /**
   * Text to dispay on the X Axis
   */
  xAxisLabel: PropTypes.string,
  /**
   * Map of keys and the desired format the values will be in
   */
  formatMap: PropTypes.object
};
