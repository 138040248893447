import React from 'react';
import moment from 'moment';
import {
  DashboardDatePickerBar
} from '../../../components/dashboards/DashboardDatePickerBar';

/**
 * Form within dashboard that contains Date Selector for all Dashboard Tabs
 * {Children} will display when a user clicks the "3 Dots" on the right
 */
export const AdImpactDashboardDatePickerBar = ({
  selectedAdImpactRun={},
  ...props
}) => {
  const {
    'start_date': startDate,
    'end_date': endDate
  } = selectedAdImpactRun;

  return(
    <DashboardDatePickerBar
      defaultStartDate={moment(startDate).format('YYYY-MM-DD').toString()}
      defaultEndDate={moment(endDate).format('YYYY-MM-DD').toString()}
      label='Date Range'
      {...props}
    />
  );
};
