import request from 'superagent';

import {LOAD_CONFIG} from './modules/platform/config.js';

export const loadConfiguration = (path, store, callback) => {
    request
        .get(path)
        .type('application/json')
        .set({
            'Cache-Control': 'no-cache'
        })
        .then((response) => {
            store.dispatch({type: LOAD_CONFIG, config: response.body});
            callback();
        })
        .catch((error) => {
            console.error('Error loading the run time configuration', error);
        });
};
