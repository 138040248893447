import React from 'react';
import DownloadCSVFactory from '../../js/download-csv-factory';
import {useCampaign} from '../../hooks/useCampaign';
import {useClient} from '../../hooks/useClient';
import {useMcaRuns} from '../../hooks/useMcaRuns';
import { useCampaignManagement, TAB_URL_KEY } from '../../hooks/useCampaignManagement';
import {InputScenariosTab} from './InputScenariosTab';
import {ScenarioOptimizerTab} from './optimizer/ScenarioOptimizerTab';
import {Scenario1} from './Scenario1';
import {Scenario2} from './Scenario2';
import {usePostScenarioPlanner} from './usePostScenarioPlanner';
import { FeatureLV } from '../../components/pages/FeatureLV';
import {sortedMappedScenarioData} from "./calcAndMapScenarioData";
import {DashboardRegionPickerBar} from "../../components/dashboards/DashboardRegionPickerBar";
import {DashboardContext} from "../dashboards/DashboardContext";

export const ScenarioPlannerDashboardsTab = ({tabIndex}) => {
  const {
    setDashboardRegion,
    dashboardRegion
  } = React.useContext(DashboardContext);

  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [warningOpen, setWarningOpen] = React.useState(false);
  const [isOptimized, setIsOptimized] = React.useState(false);
  const [optimizedSelection, setOptimizedSelection] = React.useState({});
  const [formData, setFormData] = React.useState({});

  const {
    scenarioPlannerTabIndex=0,
  } = useCampaignManagement();

  const {mcaRuns=[], selectedMcaRun={}} = useMcaRuns();
  const {campaignName} = useCampaign() || {};
  const {companyName: clientName} = useClient() || {};

  const selectedMcaDashboardRun = (selectedMcaRun.runStatus === 'FINAL') ? selectedMcaRun : {};
  const {
    scenarios,
    nestedKpis,
    regions,
    postScenarios=()=>{},
    loading,
    optimizerMap,
    groupMap
  } = usePostScenarioPlanner(mcaRuns, selectedMcaDashboardRun, dashboardRegion);

  // If Selected Run changes, clear data
  React.useEffect(() => {
    if(!selectedMcaDashboardRun.id) {
      return;
    }
    setIsSubmitted(false);
    setWarningOpen(false);
    setOptimizedSelection({});
    setIsOptimized(false);
    setFormData({});

    postScenarios({});
  }, [selectedMcaDashboardRun.id, postScenarios]);

  DownloadCSVFactory.setReportProperties(clientName, campaignName, selectedMcaRun.name);

  const kpiType = selectedMcaDashboardRun.kpiType;
  const mappedData = React.useMemo(
    () => sortedMappedScenarioData(scenarios, Object.keys(nestedKpis), groupMap, kpiType), [scenarios, nestedKpis, groupMap, kpiType]
  );

  const allRegions = (regions.length > 1) ? ["All", ...regions] : regions;

  const datePickerWithRegion = (isDisabled) => (
    <DashboardRegionPickerBar
      regions={allRegions}
      selectedMcaRunId={selectedMcaDashboardRun.id}
      setDashboardRegion={setDashboardRegion}
      defaultRegion={dashboardRegion || allRegions[0]}
      isDisabled={isDisabled}
    />
  );

  let tabs = [
    {
      label: 'Input Scenarios',
      nestedContent: datePickerWithRegion(isSubmitted),
      content: <InputScenariosTab
                 data={scenarios}
                 groupMap={groupMap}
                 kpiType={selectedMcaDashboardRun.kpiType}
                 selectedMcaDashboardRun={selectedMcaDashboardRun}
                 nestedKpis={nestedKpis}
                 loading={loading}
                 postScenarios={postScenarios}
                 isSubmitted={isSubmitted}
                 setIsSubmitted={setIsSubmitted}
                 warningOpen={warningOpen}
                 setWarningOpen={setWarningOpen}
                 dashboardRegion={dashboardRegion}
               />
    },
    {
      label: 'Scenario Optimizer',
      nestedContent: datePickerWithRegion(true),
      content: <ScenarioOptimizerTab
                 scenarios={scenarios}
                 formData={formData}
                 setFormData={setFormData}
                 selectedMcaDashboardRun={selectedMcaDashboardRun}
                 loading={loading}
                 isOptimized={isOptimized}
                 setIsOptimized={setIsOptimized}
                 optimizerMap={optimizerMap}
                 optimizedSelection={optimizedSelection}
                 setOptimizedSelection={setOptimizedSelection}
               />
    },
    {
      label: 'Scenario 1',
      nestedContent: datePickerWithRegion(true),
      content: <Scenario1
                 data={mappedData}
                 kpiType={selectedMcaDashboardRun.kpiType}
                 loading={loading}
                 timeScale={selectedMcaDashboardRun.timeScale}
                />
    },
    {
      label: 'Scenario 2',
      nestedContent: datePickerWithRegion(true),
      content: <Scenario2
                 data={mappedData}
                 kpiType={selectedMcaDashboardRun.kpiType}
                 loading={loading}
                 timeScale={selectedMcaDashboardRun.timeScale}
                />
    },
  ];

  // Scenario Optimizer tab should not show Scenario1, and Scenario2 tabls
  if(scenarioPlannerTabIndex === 1) {
    tabs = tabs.slice(0, 2);
  }

  return (
    <FeatureLV
      title='Scenario Planner'
      description={selectedMcaDashboardRun.id ? 'Project the impact of marketing plans and business factors' : 'Select an analysis to assess potential plans'}
      tabs={tabs}
      tabsUrlQueryKey={TAB_URL_KEY.SCENARIO_DASHBOARD}
      tabIndexOverride={tabIndex}
      isTabResetScroll={isSubmitted}
      tabHeightOffset={166}
    />
  );
};
