import React from 'react';
import {GraphContainerLV} from '../../../components/dashboards/GraphContainerLV';
import {Chiclet} from '../../../components/dashboards/Chiclet';
import {ChicletTitle} from '../../../components/dashboards/ChicletTitle';
import {CHART_FORMAT} from '../../../components/charts/ChartFormat.constant';
import {GraphConfig} from '../../../components/dashboards/GraphConfig';
import {GRAPH_OPTIONS} from '../../../components/dashboards/graph-options.constant';
import {DashboardContext} from "../DashboardContext";
import SelectLV from "../../../components/form/select/SelectLV";

export const DailyContributionDashboard = ({isRevenue, dailyResults = [], isCombined, loading}) => {
    const titlePrefix = isCombined ? 'Combined ' : '';
    const csvFileName = isCombined ? 'Combined_model_daily' : 'Model_daily';
    const imageElementId = isCombined ? 'combined-model-daily-chiclet-id' : 'model-daily-chiclet-id';

    const [defaultRegion, setDefaultRegion] = React.useState();
    const [dropdownOptions, setDropdownOptions] = React.useState([]);

    const {
        insightMetadata,
        setDashboardRegion
    } = React.useContext(DashboardContext);

    const dailyFilteredResults = dailyResults.map(({ciLower, ciUpper, ...keep}) => keep);

    let dailyContributionFormatMap = {};
    if (dailyFilteredResults.length) {
        if (!isRevenue) {
            const keysToRemove = ['region', 'date'];
            const dailySchema = dailyFilteredResults[0] || {};
            const yAxisKeys = Object.keys(dailySchema).filter(key => !keysToRemove.includes(key));
            yAxisKeys.forEach(k => {
                dailyContributionFormatMap[k] = CHART_FORMAT.INTEGER;
            });
            dailyContributionFormatMap['Actual'] = CHART_FORMAT.INTEGER;
        }
    }

    React.useEffect(() => {
        if (insightMetadata) {
            const {
                regions
            } = insightMetadata

            const options = regions.map((value) => ({
                    label: value,
                    value: value
                }));

            if (options?.length > 1) {
                options.unshift({
                    label: 'All',
                    value: 'All'
                });
            }

            setDropdownOptions(options);
            setDefaultRegion(options[0]);
        }
    }, [insightMetadata, setDropdownOptions]);

    const setRegionWrapper = (region) => {
        if (region) {
            setDashboardRegion(region.value);
        }
        setDefaultRegion(region)
    }

    const RegionDropdown = () => (<SelectLV
        label='Region'
        options={dropdownOptions}
        isDark={false}
        width={0}
        isClearable={false}
        onChange={setRegionWrapper}
        defaultValue={defaultRegion}
    />)

    const dailyContributionConfig = new GraphConfig({
        data: dailyFilteredResults,
        graphOptions: [
            GRAPH_OPTIONS.STACKED_BAR,
        ],
        xAxisLabel: 'date',
        xAxisKey: 'date',
        yAxisLabel: 'Contributions',
        yAxisKey: 'Actual',
        isLoading: loading,
        csvFileName,
        imageFileName: 'ContributionsOverTime',
        imageElementId,
        formatMap: dailyContributionFormatMap,
        isBroadcastDates: true,
        secondaryValue: 'Actual',
        dropdowns: <RegionDropdown/>
    });

    return (
        <Chiclet
            id={imageElementId}>

            <ChicletTitle>
                {titlePrefix}Contributions Over Time
            </ChicletTitle>

            <GraphContainerLV
                graphConfig={dailyContributionConfig}
            />
        </Chiclet>
    );
}
