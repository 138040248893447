import {css} from 'emotion';

export const styles = {
    body: css`
        margin: 0;
        padding: 0;
        font-family: sans-serif;
        text-align: center;
    `,
    run_button: css`
        display: block;
        margin-left: auto;
        margin-right: auto;
    `
};
