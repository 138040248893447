import React from 'react';
import {Brush} from 'recharts';
import {styles} from './ScrollBrush.styles';

/**
 * Adds scroll bar to charts
 * EXAMPLE: https://recharts.org/en-US/examples/BrushBarChart
 * @param data Same data set as chart
 * @param dataKey x-axis column key
 * @param minLength minimum number of data rows to display scroll bar
 * @param hide bool Do not display the element
 * @returns Recharts Brush element
 */
export const ScrollBrush = ({
  data=[], dataKey, minLength, hide
}) => {
  if(hide || !minLength) {
    return null;
  }

  if(!Array.isArray(data) || data.length <= minLength) {
    return null;
  }

  const startIndex = data.length - minLength - 1;
  const endIndex = data.length - 1;

  return (
    <Brush
      data={data}
      dataKey={dataKey}
      startIndex={startIndex}
      endIndex={endIndex}
      height={16}
      fill={'#F2F2F2'}
      stroke={'#DCD6D0'}
      travellerWidth={8}
      className={`scroll-brush-lv ${styles.container}`}
    />
  );
};
