import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import Modal from '@mui/material/Modal';
import makeStyles from '@mui/styles/makeStyles';

import McaModalFilter from '../../pages/mca/mca-filter/model-selector.jsx';
import McaDetails from '../../pages/mca/mca-models-comparison/mca-models-components/mca-details.jsx';
import FileUpload from '../../pages/file-upload-modal/file-upload-modal.jsx';
import CompleteModalContainer from './complete-modal/complete-modal-container.jsx';

import leavenedLogo from '../../assets/small_logo.svg';

import {changeUploadStep, UPLOAD_FORM} from '../../modules/platform/uploadedFiles.js';
import {changeEditStep, EDIT_FORM} from '../../modules/platform/edit.js';

import {
    CLOSE_MODAL,
    getOpen,
    getContext,
    getDark,
    getSized
} from '../../modules/platform/modal.js';
import {styles} from './modal.styles.js';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        width: '998px',
        height: '793px',
        flexGrow: 1
    },
    modal: {
        display: 'flex',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '50px',
        alignItems: 'center',
        justifyContent: 'center',
        width: '998px',
        height: '793px',
        color: 'white'
    },
    paper: {
        display: 'flex',
        justifyContent: 'center',
        borderRadius: '20px',
        background: '#304658',
        width: '998px',
        height: '793px',
        outline: 'none'
    },
  }));
  
const ModalContainer = ({open, context, dispatch, dark, sized}) => {
    const classes = useStyles();

    const handleClose = (e) => {
        e.preventDefault();
        dispatch(changeUploadStep(UPLOAD_FORM));
        dispatch(changeEditStep(EDIT_FORM));
        dispatch({type: CLOSE_MODAL, context: 'anything'});
    }
    const getContext = context => {
        switch (context) {
            case 'filterMca':
                return <McaModalFilter />
            case 'mcaDetails':
                return <McaDetails />
            case 'fileUpload':
                return <FileUpload />
            case 'complete':
                return <CompleteModalContainer />
            default:
                return (
                    <div> no match </div>
                );
        }
    }

    const logo = sized ? null: (<div className={styles.logo}><img src={leavenedLogo} alt='Leavened Logo'/></div>);
    const closeButton = sized ? null : (<span className={dark ? styles.modalXDark : styles.modalXLight}
        onClick={handleClose} data-test='modal-close'>X</span>);
    return (
        <div className={sized ? classes.root : null}>
            <Modal
                open={open}
                onClose={handleClose}
                className={sized ? classes.modal : null}>
                <div className={sized ? classes.paper: styles.modal}>
                    {logo} {closeButton}
                    {getContext(context)}
                </div>
            </Modal>
        </div>
    )
}

Modal.propTypes = {
    open: PropTypes.bool,
    context: PropTypes.string,
    dark: PropTypes.bool,
    sized: PropTypes.bool
};

const mapStateToProps = state => {
    return {
        open: getOpen(state),
        context: getContext(state),
        dark: getDark(state),
        sized: getSized(state)
    }
};

export default connect(mapStateToProps)(ModalContainer);
