import React from 'react';
import {styles} from './TableLV.styles';
import _ from 'lodash';

/**
 * Standard Table Cell used with React-Table
 * Displays cell content in a table cell, or passes cell info to a 
 * custom component in the 'override' object
 */
export const TableCellLV = ({cell, override, totalWidth}) => {
  const cellProps = (cell && cell.getCellProps) ? cell.getCellProps() : [];
  const content = getContent(cell, override);
  const className = getClassNames(cell, override);
  const overrideProps = getOverrideProps(cell, override, totalWidth);

  return (
    <td className={className}
        {...cellProps}
        {...overrideProps} >
      {content}
    </td>);
};

const getOverrideProps = (cell, override, totalWidth) => {
  const props = {style: {}};
  const columnId = _.get(cell, 'column.id');

  // We have a cell override after this check
  if(!override || !override[columnId]) {
    return props;
  }

  const customOverride = override[columnId];
  const percentWidth = Number(customOverride.percentWidth);

  if(totalWidth && !isNaN(percentWidth)) {
    props.style.width = (percentWidth / 100) * totalWidth;
    props.style.maxWidth = props.style.width;
  }

  // Add standard React custom style to <td/>
  if(typeof customOverride.style === 'object') {
    props.style = {
      ...props.style,
      ...customOverride.style,
    };
  }

  return props;
};

// Use Custom Component to render this cell
// Send the cell information to this custom component
const getContent = (cell, override) => {
  let content = '';
  if(!cell) {
    return content;
  }

  if(cell.render) {
    content = cell.render('Cell');
  }

  // We have a cell override after this check
  if(!override || !override[cell.column.id] || !override[cell.column.id].component) {
    return content;
  }

  const customOverride = override[cell.column.id];
  // Populate custom component and add the cell property
  return React.cloneElement(
    customOverride.component,
    {cell});    
};

// Get css for table cell and append possible overrides
const getClassNames = (cell, override) => {
  let classNames = [styles.tableCell];

  // We have a cell override after this check
  if(!override || !override[cell.column.id]) {
    return classNames.join(' ');
  }

  const customOverride = override[cell.column.id];
  classNames.push(customOverride.className);

  // Center column
  if(typeof customOverride.align === 'string') {
    classNames.push(`${customOverride.align}-cell`);
  }

  if(customOverride.isEditable) {
    classNames.push('editable');
  }

  // Separator css to display a divider
  if(customOverride.separator) {
    classNames.push('separator');
  }

  return classNames.join(' ');
};
