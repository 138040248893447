import React from 'react';
import {
  R1ActionIcon,
  R1Button
} from '@rainagency/rain-one-soggy-loaf';
import { urlBuilders } from '../../../../js/constants';
import { LinearProgress } from '@mui/material';
import {
  usePostGetPollingGetTriggerDownloadUrl
} from '../../../../hooks/useTriggerDownloadUrl';

/**
 * Trigger download of MCA Results on Action click
 * 1. Open Modal and start query
 * 2. Poll query endpoint until query completes
 * 3. Download returned URL and close Modal
 */
export const McaResultsActionIcon = ({
  cell,
  hide,
  closePopover
}) => {
  const id = cell.row.id;
  const display = cell.row.display;
  const status = cell.row.runStatus;
  const partition = cell.row.partition;
  const encoding = cell.row.encoding;
  const fileName = `${display}_results.csv`;
  
  const isRunComplete = (status === "RUN_COMPLETE");

  const {
    triggerDownload,
    loading,
    hasErrors,
    errors
  } = usePostGetPollingGetTriggerDownloadUrl({
    // URL to get the queryId
    requestUrl: urlBuilders.getMcaModels(id),
    // Return the url to poll against
    getPollingUrl: (response) => {
      return urlBuilders.getQueryStatus(response?.queryId);
    },
    // Poll until condition is met and return link to get url
    getDownloadUrl: (response, pollingResponse) => {
      if(pollingResponse?.status === 'Success') {
        return urlBuilders.exportMca(
          response.queryId,
          'csv',
          fileName
        );
      }
      return false;
    },
    downloadUrlObjectPath: 'url',
    onFinal: closePopover
  });

  const triggerDownloadHandler = () => {
    triggerDownload({
      data: {
        mcaRunId: id,
        partition,
        encoding        
      }
    });
  };

  let title = `Exporting MCA Results ...`;
  let description = `Download has started, this window will close when download is complete`;
  let modalStyles = {
    cursor: 'wait'
  };

  if(hasErrors) {
    title = `Error Exporting MCA Results`;
    description = 'An error has occured on download. Please contact technical support.';
    modalStyles = {
      '& .modal-description': {
        color: 'red'
      }
    };
  }

  return (
    <R1ActionIcon
      iconType='Download'
      label={'Export MCA Results'}
      hide={[!isRunComplete, hide].some(s => s)}
      ModalContent={
        <ModalDownloadMcaResults
          triggerDownload={triggerDownloadHandler}
          hasErrors={hasErrors}
          closePopover={closePopover}
        />
      }
      ModalProps={{
        title,
        description,
        width: '500px',
        customStyles: modalStyles
      }}
    />
  );
};

export const ModalDownloadMcaResults = ({
  closeModal,
  hasErrors,
  triggerDownload,
  closePopover
}) => {

  React.useEffect(() => {

    // Trigger download on mount
    triggerDownload();

    // Close parent popover on exit
    return () => {
      closePopover();
    };
    
  }, []);


  let buttonText = 'Cancel';
  if(hasErrors) {
    buttonText = 'Close';
  }

  return (
    <div className='mca-result-modal-button-row'>
      <div className="button-row">
        <R1Button variant="text" onClick={closeModal}>
          {buttonText}
        </R1Button>
      </div>

      <LinearProgress
        sx={{
          bottom: '-24px',
          margin: '0 -24px',
          display: hasErrors ? 'none' : 'block'
        }}
        color={'info'}
      />
    </div>
  );
};
