import React from 'react';
import {FeatureLV} from '../../components/pages/FeatureLV';
import {TAB_URL_KEY, useCampaignManagement} from '../../hooks/useCampaignManagement';
import {useScoringByCampaignId} from './hooks/useScoringByCampaignId';
import {ScoringCallToAction} from './ScoringCallToAction';
import {ScoringDataInput} from './ScoringDataInput';
import {ScoredReport} from './scored-reports/ScoredReportDashboard';
import {ScoringScenarioPicker} from './flighting-comparison/ScoringScenarioPicker';
import {FlightingComparison} from './flighting-comparison/FlightingComparison';
import {ScoringFileUpload} from "./file-upload/ScoringFileUpload";
import {HeaderContext} from "../../hooks/HeaderContext";
import {ScoringDashboardContext} from "./ScoringDashboardContext";
import {ScoringDatePickWrapper} from "./scored-reports/ScoredReportDatePicker";

export const ScoringDashboardsTab = () => {
    const {scoringTabIndex = 0} = useCampaignManagement();

    const {
        selectedScore = {},
        getScoringByCampaignId,
        scoresByMcaRunId = [],
    } = useScoringByCampaignId();

    const {selectedMcaRun, scoringInsightMetadata, setSelectedScoringRun} = React.useContext(ScoringDashboardContext);
    const {setRefreshClickHandler, getRefreshClickHandler} = React.useContext(HeaderContext);

    const [refreshHandler, setRefreshHandler] = React.useState(false);

    React.useEffect(() => {
        if (selectedScore.id) {
            setSelectedScoringRun(selectedScore);
        }
    }, [selectedScore?.id]);

    const refetchScoringRuns = React.useMemo(() => {
        return getRefreshClickHandler();
    }, [getRefreshClickHandler]);

    // Set Refresh click handler in Header
    React.useEffect(() => {
        setRefreshClickHandler(() => getScoringByCampaignId());
    }, [getScoringByCampaignId, selectedMcaRun.id]);

    React.useEffect(() => {
        if (refetchScoringRuns) {
            refetchScoringRuns();
        }
    }, [refreshHandler, refetchScoringRuns])

    const selectedIdx = isNaN(scoringTabIndex) ? 0 : scoringTabIndex;

    const callToActions = [
        <ScoringFileUpload
            mcaRunId={selectedMcaRun.id}
            onComplete={() => {
                setTimeout(getScoringByCampaignId, 3000);
            }}
        />,
        (
            <ScoringCallToAction/>
        ),
        null
    ];

    const tabHeights = [96, 178];
    const tabs = [
        {
            label: 'Data Input',
            content: <ScoringDataInput
                scores={scoresByMcaRunId}
                fetchScores={getScoringByCampaignId}
            />
        },
        {
            label: 'Scored Reports',
            content: <ScoredReport/>,
            nestedContent: (<ScoringDatePickWrapper/>)
        },
        {
            label: 'Flighting Comparison',
            content: <FlightingComparison/>,
            nestedContent: (
                <ScoringScenarioPicker
                    scoresByMcaRunId={scoresByMcaRunId}
                />
            )
        }
    ];

    let description = 'Select an analysis to base scoring on';
    if (selectedMcaRun && selectedMcaRun.runStatus === 'FINAL') {
        description = `Analysis to base scoring on: ${selectedMcaRun.name}`;
    }

    return (
            <FeatureLV
                title='Scoring'
                description={description}
                tabs={tabs}
                tabsUrlQueryKey={TAB_URL_KEY.SCORING_DASHBOARD}
                tabHeightOffset={tabHeights[selectedIdx]}
                CallToAction={callToActions[selectedIdx]}
                sx={{
                    '& .tab-body': {
                        background: '#FFF',
                    }
                }}
            />
    );
};