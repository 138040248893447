import React from 'react';
import {styles} from './icons.styles';

export const DownloadImageIcon = ({onClick, className, title}) => {
  return(
    <div className={`${styles.svg} ${className}`}
         onClick={onClick} >
      <svg
        width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>{title}</title>

        <path d="M10.4161 3.44353H9.50358V0.573922C9.50358 0.258265 9.24531 0 8.92966 0H6.63397C6.31831 0 6.06005 0.258265 6.06005 0.573922V3.44353H5.14751C4.63672 3.44353 4.37846 4.06337 4.74003 4.42494L7.37433 7.05924C7.59816 7.28307 7.95973 7.28307 8.18356 7.05924L10.8179 4.42494C11.1794 4.06337 10.9269 3.44353 10.4161 3.44353Z"
              fill="#757575"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M14 11.3333V20.6667C14 21.4 13.4 22 12.6667 22H3.33333C2.6 22 2 21.4 2 20.6667V11.3333C2 10.6 2.6 10 3.33333 10H12.6667C13.4 10 14 10.6 14 11.3333ZM7.33328 19.0067L5.93328 17.32C5.79328 17.1533 5.53995 17.16 5.41328 17.3333L3.75328 19.4667C3.57995 19.6867 3.73328 20.0067 4.01328 20.0067H12.0066C12.2799 20.0067 12.4399 19.6933 12.2733 19.4733L9.93328 16.3533C9.79995 16.1733 9.53328 16.1733 9.39995 16.3467L7.33328 19.0067Z"
              fill="#757575"/>
      </svg>
    </div>
  );
};
