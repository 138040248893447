import {css} from 'emotion';

export const styles = {
    title: css`
        font-size: 48pt !important;
        margin-top: 156px !important;
        text-align: center !important;
    `,
    formContainer: css`
        display: flex;
        margin-top: 26px !important;
        justify-content: center;
    `,
    closeButton: css`
        width: 160px;
        height: 48px;
        background: #ffffff;
        border: 1px solid rgba(126, 146, 146, 0.25);
        box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.15);
        font-size: 14px;
        color: #374f63;
        text-align: center;
        line-height: 20px;
        margin-top: 40px !important;
    `,
    fileUploadContainer: css`
        height: 100vh;
        width: 100vw;
        background: #374f63;
        color: #ffffff;
    `,
    completeContainer: css`
        text-align: center;
        margin: 0 auto;
        margin-top: 152px;
    `,
    completeTitle: css`
        font-size: 48pt !important;
        margin-top: 13px !important;
        margin-bottom: 4px;
        text-align: center !important;
    `,
    completeOuterBox: css`
        font-family: Roboto, sans-serif !important;
        text-align: center;
        margin: 0 auto;
        width: 400px;
        height: 80px;
        background: rgba(43, 60, 75, 0.54);
        border-radius: 15px;
        justify-content: center;
        color: rgba(255, 255, 255, 0.5);
        font-size: 12px;
        margin-top: 32px;
        padding-top: 32px;
        padding-bottom: 32px;
        z-index: 1;
    `,
    completeInnerBox: css`
        text-align: center !important;
        margin-top: 16px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 8px;
        padding-bottom: 16px;
        color: #ffffff;
        background: #2b3c4b;
        height: 32px;
        width: 352px;
        text-align: left;
        z-index: 2;
    `,
    spinnerContainer: css`
        text-align: center !important;
        z-index: 20 !important;
    `,
    spinner: css`
        color: white !important;
    `,
    icon: css`
        padding-right: 8px;
    `,
    link: css`
        text-transform: initial !important;
        font-size: 18.67px !important;
        color: white !important;
    `
};
