import {useForm} from "react-hook-form";
import {TitleLV} from "../../../components/form/title/TitleLV";
import React from "react";
import {FormRowLV} from "../../../components/form/row/FormRowLV";
import {styles as buttonStyles} from "../../../components/form/buttons/button.styles";
import {ButtonLV} from "../../../components/form/buttons/ButtonLV";
import moment from "moment";
import {urlBuilders} from "../../../js/constants";
import {urlWithDomain, usePost} from "../../../hooks/useRest";
import {DarkGreyButtonLV} from "../../../components/form/buttons/DarkGreyButtonLV";
import {useFeatureSelect} from "../../../hooks/useFeatureSelect";
import {FEATURES} from "../../../constants/features";

export default function StartMagnifaiModal({selectedMcaRun, closeModal}) {
  const {handleSubmit, formState} = useForm();
  const {isSubmitting} = formState;
  const [formHasSubmitted, setFormHasSubmitted] = React.useState(false);

  const { onFeatureSelect } = useFeatureSelect();

  const formTitle = 'Would you like to run MagnifAI?';

  const [{data: runResult, error: runErrors}, runMagnifAI] = usePost({
    url: urlBuilders.runMagnifAI(),
    data: {},
    manual: true
  });

  const handleCancel = () => {
    closeModal();
  }
  const formSubmit = async () => {
    if (formHasSubmitted){
      return;
    }

    setFormHasSubmitted(true);

    await runMagnifAI({data: selectedMcaRun});
    onFeatureSelect({
      feature: FEATURES.MMM_ANALYSIS,
    });
  }

  return (
    <div data-test={'RunMagnifai-Modal'}>
      <form onSubmit={handleSubmit(formSubmit)}>
        <TitleLV>{formTitle}</TitleLV>
        <FormRowLV>
          MagnifAI will tune your model to optimize the fit.
        </FormRowLV>
        <FormRowLV style={{justifyContent: "right"}}>
          <ButtonLV
            onClick={handleCancel}
            submitting={isSubmitting}
            isDisabled={isSubmitting}
            dataTest={'cancel-button'}>
            Cancel
          </ButtonLV>

          <DarkGreyButtonLV
            className={buttonStyles.darkGrey}
            submitting={formHasSubmitted || isSubmitting}
            isDisabled={formHasSubmitted || isSubmitting}
            dataTest={'submit-button'}>
            Tune
          </DarkGreyButtonLV>
        </FormRowLV>
      </form>
    </div>
  );
};