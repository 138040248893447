import {css} from 'emotion';

export const styles = {
  container: css`
    width: 100%;
    overflow-y: scroll;
    height: 100%;
    background: #FFF;
    color: #333;

    th {
      position: sticky;
      top: 0;
      height: 60px;
      background: #FFF;
      padding-left: 8px;
    };

    td {
      height: auto;
      color: #333;
      padding-left: 8px;
    }
  `,
};
