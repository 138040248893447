
import React from 'react';
import PropTypes from 'prop-types';
import {useGet} from '../../hooks/useRest';
import {urlBuilders} from "../../js/constants";
import {embedDashboard} from "@superset-ui/embedded-sdk";
import {Config} from '../../config/ConfigFactory';

/**
 * Displays a superset dashboard
 */
export const SupersetDashboard = ({
                                  dashboardId = '',
                                  className = ''
                              }) => {

    const [{data = ''}, fetchGuestToken] = useGet({
        url: urlBuilders.getSupersetGuestToken(dashboardId),
        manual: true
    });

    React.useEffect(() => {
        if (dashboardId) {
            fetchGuestToken();
        }
    },[dashboardId, fetchGuestToken]);

    React.useEffect(() => {
        if (data && data.guestToken) {
            embedDashboard({
                id: dashboardId,
                supersetDomain: Config.getConfig().SUPERSET_DOMAIN,
                mountPoint: document.getElementById(dashboardId),
                fetchGuestToken: () => data.guestToken,
                dashboardUiConfig: { hideTitle: true }
            });
        }
    }, [data]);

    return (
        <div id={dashboardId} className={className}>
        </div>
    );
};

SupersetDashboard.propTypes = {
    /**
     * Dashboard Id given from the superset dashboard.
     * Class name for styling
     */
    dashboardId: PropTypes.string,
    className: PropTypes.string
};
