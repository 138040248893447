import {useEffect, useState} from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import {useGet, usePost, urlWithDomain} from '../../hooks/useRest';
import {useGetPolling} from '../../hooks/usePolling';
import {downloadFromURL} from '../../js/download';
import {arrayContainsAll} from '../../js/utils';

import {urlBuilders} from '../../js/constants';

//Generating and downloading outputs and insights for selected models
export default function useOutputsInsights(runId, includeIntercept, queryId, models=[],
                        startInsights=false, setStartInsights=()=>{}, fetchInsights) {
    const [url, setUrl] = useState();
    const [completedInsights, setCompletedInsights] = useState([]);

    const [insightsLoading, setInsightsLoading] = useState();
    const [insightsErrors, setInsightsErrors] = useState();
    const [insightsStatus, setInsightsStatus] = useState();

    const [{data, error}, startOutputsInsights] = usePost({
        url: urlBuilders.modelOutputsInsights(),
        data: {runId, includeIntercept, queryId, models}
    });

    const resetCompletedInsights = () => {
        setCompletedInsights([]);
        setInsightsErrors();
        setInsightsLoading(true);
    };

    useEffect(() => {
        if (startInsights && !insightsLoading) {
            resetCompletedInsights();
            startOutputsInsights();
            setInsightsStatus('loading');
        }
    }, [startInsights, insightsLoading]);

    const {data: response={modelIds: []}, error: completedInsightsError, clearIntervalInstance} = useGetPolling({
        url,
        interval: 1000
    });

    //fetch completed insights on demand and after insights generated
    useDeepCompareEffect(() => {
        if (fetchInsights && runId && models.length) {
            setInsightsStatus();
        }
        
        if ((fetchInsights && runId && models.length) || (startInsights && data && !error)) {
            resetCompletedInsights();
            setUrl(urlWithDomain(urlBuilders.getCompletedOutputsInsights(runId, models)));
        } else {
            setUrl();
        }
    }, [data, error, runId, models, startInsights, fetchInsights]);

    const [{data: insights=[]}, getOutputsAndInsightsStatus] = useGet({
        url: urlBuilders.getOutputsInsightsStatus(runId),
        manual: true
    });

    useEffect(() => {
        if (runId) {
            getOutputsAndInsightsStatus();
        }
    }, [runId]);

    const [{data: roiResults, loading: roiLoading, error: roiError}, fetchRoiResults] = useGet({
        url: urlBuilders.getOutputsInsightsDataByType(runId, 'roi', runId, completedInsights.join()),
        manual: true
    });

    const [{data: fitResults={data:[]}, loading: fitLoading, error: fitError}, fetchFitResults] = useGet({
        url: urlBuilders.getOutputsInsightsDataByType(runId, 'fit', runId, completedInsights.join()),
        manual: true
    });

    //terminate polling for fetching once for initial loading or when all completed and start roi
    useEffect(() => {
        if (completedInsightsError) {
            setInsightsErrors(completedInsightsError);
        }
        const modelIds = response.modelIds || [];
        const allCompleted = (modelIds.length && arrayContainsAll(modelIds, models)) || response.status === 'COMPLETED';
        if ((response.status === 'IN_PROGRESS' && !startInsights) || allCompleted) {
            clearIntervalInstance();
            setStartInsights(false);
            setInsightsLoading(false);
            setInsightsStatus(allCompleted && modelIds.length ? 'done' : 'enabled');
            const completedModels = modelIds.filter(model => models.includes(model)) || [];
            setCompletedInsights(completedModels);
        }
    }, [response, completedInsightsError]);

    useEffect(() => {
        if (completedInsights.length) {
            fetchRoiResults();
            fetchFitResults();
        }
    }, [completedInsights])

    /** Handle error from generated insights */
    useEffect(() => {
        const errors = error || roiError || fitError;
        if (errors) {
            setInsightsErrors(errors);
        }
    }, [error, roiError, fitError]);

    // Generate archive and new presigned url for downloaded model
    const [{data: archiveResponse={}, error: archiveUrlError}, getArchiveUrl] = useGet({manual: true});
    const downloadArchive = (modelId, archiveName)=> {
        const url = urlWithDomain(urlBuilders.fetchInsightArchive(runId, modelId, archiveName || 'insights-archive.zip'));
        getArchiveUrl({url, manual: true});
    };

    // Download archive URL for single selected model
    useEffect(() => {
        if (archiveUrlError) {
            setInsightsErrors(archiveUrlError);
        }

        if (archiveResponse.url) {
            downloadFromURL(archiveResponse.url);
        }
    }, [archiveResponse.url, archiveUrlError, setInsightsErrors]);
    
    return {
        insightsLoading: insightsLoading || roiLoading || fitLoading,
        completedInsights,
        completedInsightTypes: insights,
        downloadArchive,
        insightsErrors,
        insightsStatus,
        roiResults,
        fitResults
    };
                
}