import React from 'react';
import {numberWithCommas} from '../../../js/utils';
import {styles} from './NumericCell.styles';
import _ from 'lodash';

/**
 * Show Number/Currency within a React-Table cell
 * @param cell Cell object added by React-Table
 * @param isCurrency Shows $ before number
 * @param decimals Precision of decimal places
 * @param center center test
 * @param isInline Bool will display just number text
 * @returns Component that displays number in a React Table Cell
 */
export const NumericCell = ({
  cell,
  isCurrency=false,
  decimals=2,
  align='',
  isInline=false
}) => {
  let value = Number(_.get(cell, 'value', 0));

  if(isNaN(value)) {
    value = 0;
  }

  let classNames = ['number'];
  let display = value.toFixed(decimals);

  if(typeof align === 'string') {
    classNames.push(align);
  }
  const className = classNames.join(' ');

  if(isInline) {
    display = numberWithCommas(display, isCurrency);
    return (
      <div className={styles.container}>
        <div className={className}>{display}</div>
      </div>
    );
  }

  let DollarSign = <div className='dollar'>$</div>;
  if(!isCurrency) {
    DollarSign = null;
  }

  display = numberWithCommas(display);
  return (
    <div className={styles.container}>
      {DollarSign}
      <div className={className}>{display}</div>
    </div>
  );
};
