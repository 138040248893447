import React from 'react';
import { FeatureLV } from '../../components/pages/FeatureLV';

export const UserGuide = () => {
  return (
    <FeatureLV 
      title='User Guide'
      description='User Guide'>
        <iframe src='/docsie/docsie.html'></iframe>
    </FeatureLV>);
};