import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';

import {styles} from './spinner.styles.js';

/**
 * [CircularProgress takes display status to run or stop the spinner 
 * and returns spinner while action is in progress]
 * @param {Bool}  status  
 */
 export default class Spinner extends React.Component {
     
     render() {
       const style = this.props.style ? this.props.style : styles.blueSpinner;
       return <CircularProgress className={style} size={100} thickness={2} />
     }
   
 }
