import React from 'react';
import {DATE_SPAN} from '../../../constants/dates';
import {useDateAndTotalsRollup} from '../../../hooks/useDateAndTotalsRollup';
import {IntervalRadioSelect} from '../../../components/date/IntervalRadioSelect';
import {GraphContainerLV} from '../../../components/dashboards/GraphContainerLV';
import {GraphConfig} from '../../../components/dashboards/GraphConfig';
import {GRAPH_OPTIONS} from '../../../components/dashboards/graph-options.constant';
import {CHART_FORMAT} from '../../../components/charts/ChartFormat.constant';
import {ChicletLV} from '../../../components/chiclet/ChicletLV';
import {TableWithBorder} from '../../../components/data-table/TableWithBorder';
import {NumericCell} from '../../../components/data-table/cells/NumericCell';
import {getDummyData} from '../../../components/data-table/getDummyData';

import { styles } from './ScoringContributionsChiclet.styles';

export const ScoringContributionsChiclet = ({
  kpiType,
  spendTableData,
  contributionsData1 = [],
  contributionsData2 = [],
  dailyContributionsData1 = [],
  dailyContributionsData2 = [],
  hasScoring2,
  costPerValue={},
  isLoading
}) => {
  //daily total contributions
  let dailyContributionsData = [];
  if (!!dailyContributionsData1.length) {
    dailyContributionsData = dailyContributionsData1.map(data1 => {
      const {date, region, Actual, ...variables1} = data1;
      const totalContributions1 = Object.values(variables1).reduce((sum, current) => sum += Number(current), 0);
      let contributions = {
          date,
          region,
          contributions1: (Math.round(totalContributions1)*100)/100
        };
        if (hasScoring2 && !!dailyContributionsData2.length) {
          const {date: _date, region: _region, Actual: _Actual, ...variables2} = dailyContributionsData2.find(data2 =>
            data2.date === data1.date && data2.region === data1.region) || {};
          const totalContributions2 = Object.values(variables2).reduce((sum, current) => sum += Number(current), 0);
          contributions['contributions2'] = (Math.round(totalContributions2)*100)/100;
        }
      return contributions;
    });
  }

  const [dateSpan, setDateSpan] = React.useState(DATE_SPAN.QUARTERLY);
  const rollup = useDateAndTotalsRollup({
    data: dailyContributionsData,
    dateSpan
  });
  
  //contributions and CPA/ROI
  let contributionsData = [];
  if (hasScoring2 && !!contributionsData2.length) {
    contributionsData = contributionsData1.map(contribution1 => ({
      ...contribution1,
      scenario2: contributionsData2.find(contribution2=> contribution2.variable === contribution1.variable).scenario
    }));
  } else {
    contributionsData = contributionsData1.slice();
  }
  contributionsData = contributionsData.sort((contribution1 = {}, contribution2 = {}) => contribution1.variable.localeCompare(contribution2.variable));

  //Apply costper to spends
  const updatedSpendTableData = spendTableData.map(row => (
    {...row, 
      spend1: row.spend1 * (costPerValue[`${row.variable}_1`] || 1),
      spend2: row.spend2 * (costPerValue[`${row.variable}_2`] || 1)
    }
  ));

  const roiCpaData = contributionsData.map(contribution => {
    const spendVariable = updatedSpendTableData.find(spend => spend.variable === contribution.variable);
    const scenario1 = kpiType === 'Revenue' ? contribution.scenario / (spendVariable.spend1 || 1) : spendVariable.spend1 / (contribution.scenario || 1);
    let roiCpa = {
      variable: contribution.variable,
      scenario1
    };

    if (hasScoring2 && contribution.scenario2 && spendVariable.spend2) {
      const scenario2 = kpiType === 'Revenue' ? contribution.scenario2 / (spendVariable.spend2 || 1) : spendVariable.spend2 / (contribution.scenario2 || 1);
      roiCpa = {
        ...roiCpa,
        scenario2,
        change: Math.round((scenario1 - scenario2)*100)/100
      }
    }
    return roiCpa;
  });

  let overrideContribution = {
    variable: {
      name: 'Variable'
    },
    scenario: {
      name: `Scenario 1`,
      align: 'center',
      component: <NumericCell decimals={0} align='center'/>
    }
  };

  let overrideRoi = {
    variable: {
      name: 'Variable'
    },
    scenario1: {
      name: `Scenario 1`,
      align: 'center',
      component: <NumericCell decimals={2} align='center'/>
    }
  }

  if (hasScoring2) {
    overrideContribution = {
      ...overrideContribution,
      scenario2: {
        name: 'Scenario 2',
        align: 'center',
        component: <NumericCell decimals={0} align='center'/>
      }
    };

    overrideRoi = {
      ...overrideRoi,
      scenario2: {
        name: 'Scenario 2',
        align: 'center',
        component: <NumericCell decimals={2} align='center'/>
      },
      roi: {
        name: 'Marginal CPA/ROI',
        align: 'center'
      }
    };
  }

  const getContributionsTableData = () => {
    if (!contributionsData.length) {
      const dummySchema = {
        variable: '',
        scenario1: '',
        scenario2: ''
      };
      return getDummyData(dummySchema, 4);
    }
    return contributionsData;
  }

  const getRoiCpaTableData = () => {
    if (!roiCpaData.length) {
      const dummySchema = {
        variable: '',
        scenario1: '',
        scenario2: '',
        change: ''
      };
      return getDummyData(dummySchema, 4);
    }
    return roiCpaData;
  }

  const contributionsTableData = getContributionsTableData();
  const roiCpaTableData = getRoiCpaTableData();
  const hasData = !!contributionsData.length && !!roiCpaData.length;

  const SourceOfChangeChart = () => {
    if (!hasScoring2) {
      return (<></>);
    }
    const chartData = contributionsTableData.map(data => ({'variable': data.variable, 'change': data.scenario - data.scenario2}));
    const chartConfig = new GraphConfig({
      data: chartData,
      graphOptions: [
        GRAPH_OPTIONS.STACKED_BAR
      ],
      formatMap: {
        'y': CHART_FORMAT.INTEGER,
        'change': CHART_FORMAT.INTEGER
      },
      interval: 0,
      xAxisLabel: 'variable',
      yAxisLabel: 'Contributions Variance',
      isLoading
    });
    return ( 
      <ChicletLV
          id='sourceOfChange-chart'
          chicletImageId='sourceOfChange-chart'
          imageFileName='sourceOfChange.png'
          csvFileName='changeChartData.csv'
          downloadFileData={chartData}
          data={chartData}
          title='Source of Change'>
            <GraphContainerLV
              graphConfig={chartConfig}
            />
      </ChicletLV>
    );
  }

  const ContributionsChart = () => {
    let chartData = [];
    if (rollup?.data?.length) {
      const rollupData = rollup.data.filter(data => data.label !== 'region');
      let keys = rollupData.length ? Object.keys(rollupData[0]) : [];
      keys = keys.filter(value => value !== 'label');
      chartData = keys.map(key => {
        const contribution1 = rollupData.find(data => data.label === 'contributions1') || {};
        let bar = {
          'date': key,
          'Scenario 1': contribution1[key]
        };
        if (hasScoring2) {
          const contribution2 = rollupData.find(data => data.label === 'contributions2') || {};
          bar['Scenario 2'] = contribution2[key];
        }
        return bar;
      });
    }
    const chartConfig = new GraphConfig({
      data: chartData,
      graphOptions: [
        GRAPH_OPTIONS.STACKED_BAR
      ],
      isClustered: true,
      formatMap: {
        'Scenario 1': CHART_FORMAT.INTEGER,
        'Scenario 2': CHART_FORMAT.INTEGER,
      },
      xAxisLabel: 'date',
      yAxisKey: 'Scenario 1',
      yAxisLabel: 'Contributions',
      secondaryValue: hasScoring2 ? 'Scenario 2' : null,
      isLoading,
      colorMap: {
        'Scenario 1': '#FF7863',
        'Scenario 2': '#557999'
      }
    });
    return ( 
      <ChicletLV
          id='contributions-chart'
          chicletImageId='contributions-chart'
          imageFileName='contributions-over-time.png'
          csvFileName='contributions-over-time.csv'
          downloadFileData={chartData}
          data={chartData}
          title='Contributions Over Time'>
            <IntervalRadioSelect
              dateSpan={dateSpan}
              onChecked={setDateSpan}
              isHorizontal={true}
              isDisabled={isLoading}
            />

            <GraphContainerLV
              graphConfig={chartConfig}
            />
      </ChicletLV>
    );
  }

  return (
    <div style={hasData ? {} : {pointerEvents: 'none'}}>
      <div className={styles.chickletContainer}>
        <ChicletLV
          id='contributions-comparison-table'
          imageFileName='contributions-comparison-table.png'
          csvFileName='contributions-comparison.csv'
          chicletImageId='contributions-comparison-table'
          downloadFileData={contributionsTableData}
          data={contributionsTableData}
          title='Contributions'>
          <TableWithBorder
            data={contributionsTableData}
            hasData={hasData}
            isLoading={isLoading}
            override={overrideContribution}
            disableTableSort={true}
          />
        </ChicletLV>

        <ChicletLV
          id='cpa-roi-comparison-table'
          imageFileName='cpa-roi-comparison-table.png'
          csvFileName='cpa-roi-comparison.csv'
          chicletImageId='cpa-roi-comparison-table'
          downloadFileData={roiCpaTableData}
          data={roiCpaTableData}
          title='CPA/ROI'>
          <TableWithBorder
            data={roiCpaTableData}
            hasData={hasData}
            isLoading={isLoading}
            override={overrideRoi}
            disableTableSort={true}
          />
        </ChicletLV>
      </div>
      <div className={styles.changeChartContainer}>
          <SourceOfChangeChart/>
      </div>
      <div className={styles.contributionChartContainer}>
          <ContributionsChart/>
      </div>  
    </div>
  );
};
