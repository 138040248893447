import React from 'react';
import { StatusCell } from '../../components/data-table/cells/StatusCell';
import { RUN_STATUS } from '../../constants/mca-run-statuses';
import { urlBuilders } from '../../js/constants';
import { useCampaignManagement } from '../../hooks/useCampaignManagement';
import { useGetPolling } from '../../hooks/usePolling';
import {GridStatusCell} from "../../components/data-grid/GridStatusCell";

/**
 * Display normal cell status if Scoring Row is not RUNNING
 * If RUNNING, Poll the Scoring endpoint and refresh table on complete
 */
export const ScoringStatusCell = ({
  cell,
  fetchScoringRuns
}) => {

  if(cell?.value === RUN_STATUS.RUNNING) {
    return (
      <ScoringPollingStatusCell
        cell={cell}
        fetchScoringRuns={fetchScoringRuns}
      />
    );
  }

  return (
      <GridStatusCell
          dataTest={`scoring-table-status-cell-${cell.row.name}`}
          id={cell.row.id}
          status={`SCORING_${cell.value}`}
          name={cell.row.name}
          onClick={() => {}}
      />
  );
};

const ScoringPollingStatusCell = ({
  cell,
  fetchScoringRuns = () => {}
}) => {
  const scoringRunId = cell?.row?.id;
  const {campaignId} = useCampaignManagement();
  const scoringRunsUrl = urlBuilders.getScoreCalculationsByCampaignId(campaignId);

  const {data=[], clearIntervalInstance} = useGetPolling({
    url: scoringRunsUrl
  });

  const statusFromData = React.useMemo(() => {
    return data.find(d => d.id === scoringRunId)?.scoringStatus;
  }, [data]);

  React.useEffect(() => {
    if(!statusFromData) {
      return;
    }

    if (statusFromData !== RUN_STATUS.RUNNING) {
      clearIntervalInstance();
      fetchScoringRuns();
    }

  }, [statusFromData]);

  return (
      <GridStatusCell
          dataTest={`scoring-table-status-cell-${cell.row.name}`}
          id={cell.row.id}
          status={`${cell.value}`}
          name={cell.row.name}
          onClick={() => {}}
      />
  );

};
