import React from 'react';
import {ChicletLV} from '../../components/chiclet/ChicletLV';
import {InputScenariosForm} from './InputScenariosForm';
import {InputScenariosReport} from './InputScenariosReport';
import {OrangeButtonLV} from "../../components/form/buttons/OrangeButtonLV";
import {sortedMappedScenarioData} from "./calcAndMapScenarioData";

export const InputScenariosTab = ({
  data,
  groupMap,
  loading,
  postScenarios,
  isSubmitted=false,
  setIsSubmitted,
  warningOpen=false,
  setWarningOpen,
  selectedMcaDashboardRun={},
  nestedKpis={},
  dashboardRegion
}) => {
  const title = isSubmitted ? 'Scenario Summary' : 'Input Data';
  const imageId = isSubmitted ? 'scenario-comparison-report' : null;

  const csvFileName = isSubmitted ? 'ScenarioSummaryTable.csv' : null;

  const kpiType = selectedMcaDashboardRun.kpiType;

  const mappedData = React.useMemo(
    () => sortedMappedScenarioData(data, Object.keys(nestedKpis), groupMap, kpiType), [data, nestedKpis, groupMap, kpiType]
  );

  const clickHandler = () => {
    setIsSubmitted(false);
    setWarningOpen(false);
  };

  const EditScenariosButton = () => {
    return (<OrangeButtonLV
      onClick={clickHandler}
      isInverted={true}
      dataTest='edit-scenario-button'>
      Edit Scenarios
    </OrangeButtonLV>);
  };

  const CTA = isSubmitted ? <EditScenariosButton/> : null;

  return (
    <ChicletLV
      imageFileName='ScenarioComparisonReport.png'
      chicletImageId={imageId}
      imageTooltip='Download ScenarioComparisonReport.png'
      fileTooltip='Download ScenarioSummaryTable.csv'
      csvFileName={csvFileName}
      downloadFileData={mappedData}
      title={title}
      CallToAction={CTA}
      id={'scenario-comparison-report'}
    >

      <InputScenariosForm
        data={data}
        groupMap={groupMap}
        loading={loading}
        postScenarios={postScenarios}
        isSubmitted={isSubmitted}
        setIsSubmitted={setIsSubmitted}
        warningOpen={warningOpen}
        setWarningOpen={setWarningOpen}
        selectedMcaDashboardRun={selectedMcaDashboardRun}
        nestedKpis={nestedKpis}
        dashboardRegion={dashboardRegion}
      />

      <InputScenariosReport
        data={data}
        groupMap={groupMap}
        loading={loading}
        isSubmitted={isSubmitted}
        kpiType={kpiType}
        timeScale={selectedMcaDashboardRun.timeScale}
        nestedKpis={Object.keys(nestedKpis)}
      />

    </ChicletLV>
  );
};
