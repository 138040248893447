import {css} from 'emotion';
import {styles as ButtonStyles} from '../../components/form/buttons/button.styles';
import {styles as CtaStyles} from '../../components/tabs/CallToActionLV.styles';
import downArrow from '../../assets/down-arrow-cta.svg';

/**
 * Custom Styles for React-Select
 * https://react-select.com/styles
 * @param width width override for container
 * @returns Customized styles for react-select
 */
export const customStyles = () => {
  return {
    control: provided => {
      return {
        ...provided,
        '&:hover': {borderColor: 'none'},
        boxShadow: 'none',
        border: 0,
        background: 'transparent',
        minHeight: '24px',
        display: 'flex: auto'
      };
    },
    menu: (provided) => {
      return {
        ...provided,
        margin: 0
      };
    },
    option: (provided) => {
      return {
        ...provided,
        border: 0,
        color: '#FFF',
        fontFamily: 'Roboto, sans-serif;',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '0.4px',
      };
    }
  };
};

/**
 * Custom theme for React-Select
 * https://react-select.com/styles#overriding-the-theme
 */
export const customTheme = theme => {
  return {
    ...theme,
    colors: {
      ...theme.colors,
      neutral0: '#374f63',
      neutral80: '#FFF',
      primary: 'rgba(255, 255, 255, 0.2)',
      primary25: 'rgba(255, 255, 255, 0.1)',
      primary50: 'rgba(255, 255, 255, 0.3)'
    }
  };
};

export const styles = {
  base: css`
    display: flex;
    .LV__value-container {
      ${ButtonStyles.buttonLV};
      ${ButtonStyles.orange};
      ${CtaStyles.cta};
      justify-content: center;
      padding-left: 18px;
    }
    .LV__value-container:before {
      content: url(${downArrow});
    }
    .LV__placeholder {
      color: white;
      position: initial;
      margin-left: 8px;
    }
    .LV__single-value {
      color: white;
      margin-left: 8px;
      position: initial;
      overflow: initial;
    }
    .LV__indicator-separator {
      display: none;
    }
    .LV__dropdown-indicator {
      display: none;
    }

    .LV__input {
      input {
        font-family: 'Roboto', sans-serif !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        color: white !important;
        letter-spacing: 0.75px !important;
      }
    }
    .LV__control--is-disabled {
      .LV__indicators {
        justify-content: center;
        margin-top: -22px;
      }
    }
    .LV__menu {
      min-width: 100%;
      right: 0;
      width: max-content;
    }
  `
};
