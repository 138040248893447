import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {DateRangePickerLV} from '../form/date/DateRangePickerLV';
import {mapToOptions, SelectLV} from '../form/select/SelectLV';
import threeDotLogo from '../../assets/more_vert_24px.svg';
import Fade from '@mui/material/Fade';
import {styles} from './DashboardDatePickerBar.styles';

/**
 * Form within dashboard that contains Date Selector for all Dashboard Tabs
 * {Children} will display when a user clicks the "3 Dots" on the right
 */
const DashboardDatePickerBar = ({
                                    children, isReadOnly = false, isDateOnly = false, regions, defaultRegion,
                                    dependentVariable, rsq, mape, confidence, defaultStartDate, defaultEndDate,
                                    setDashboardRegion, setDashboardStartDate, setDashboardEndDate,
                                    label = 'Date Selector', minDate, maxDate
                                }) => {
    const DEPENDENT_VARIABLE = 'Dependent Variable';
    const R_SQUARED_LABEL = 'R Squared';
    const MAPE = 'MAPE';
    const CONFIDENCE = 'CONFIDENCE';

    const rSquaredRnd = parseStringPercent(rsq);
    const mapeRnd = parseStringPercent(mape, 0);
    const confidenceRnd = parseStringPercent(confidence, 0);

    // Read-only classname hides all fields except date selector
    const readOnlyCss = (isReadOnly || isDateOnly) ? 'read-only' : '';
    const classNames = [styles.container, readOnlyCss].join(' ');
    const regionOptions = mapToOptions(regions);
    const dashboardRegion = regionOptions.find(option => option.value === defaultRegion);
    useEffect(() => {
        if (setDashboardRegion && defaultRegion) {
            setDashboardRegion(defaultRegion);
        }
    }, [defaultRegion, setDashboardRegion]);

    const SelectDashboardRegion = () => {
        if (!setDashboardRegion) {
            return null;
        }
        return (
            <div className={styles.regionContainer}>
                <SelectLV
                    label='Region'
                    isDark={false}
                    width={0}
                    isClearable={false}
                    options={regionOptions}
                    value={dashboardRegion}
                    onChange={(region = {}) => setDashboardRegion(region.value)}
                />
            </div>
        );
    }

    return (
        <div
            data-test='date-picker-dashboard-bar-container'
            className={classNames}>
            <SelectDashboardRegion/>
            <div className={styles.formContainer}>
                <div className={`${styles.dependentVariable} dependentVariable`}>
                    <div data-test='dependentVariable-dashboard-value'>
                        {dependentVariable}
                    </div>
                    <div className={styles.label}>
                        {DEPENDENT_VARIABLE}
                    </div>
                </div>
                <div className={`${styles.rSquareBox} rsquare`}>
                    <div data-test='rsquared-dashboard-value'
                         className={styles.percent}>
                        {rSquaredRnd}%
                    </div>
                    <div className={styles.label}>
                        {R_SQUARED_LABEL}
                    </div>
                </div>

                <div className={`${styles.mapeBox} mape`}>
                    <div data-test='mape-dashboard-value'
                         className={styles.percent}>
                        {mapeRnd}%
                    </div>
                    <div className={styles.label}>
                        {MAPE}
                    </div>
                </div>

                <div className={`${styles.confidenceBox} confidence`}>
                    <div data-test='confidence-dashboard-value'
                         className={styles.percent}>
                        {confidenceRnd}%
                    </div>
                    <div className={styles.label}>
                        {CONFIDENCE}
                    </div>
                </div>

                <div className={styles.dateContainer}>

                    <DateRangePickerLV
                        isDark={false}
                        startLabel={label}
                        startDescription='From'
                        endLabel='&nbsp;'
                        endDescription='To'
                        spaceBetween={8}
                        minDate={minDate}
                        maxDate={maxDate}
                        defaultStartDate={defaultStartDate || minDate}
                        defaultEndDate={defaultEndDate || maxDate}
                        customSetStartDate={setDashboardStartDate}
                        customSetEndDate={setDashboardEndDate}
                        isDisabled={isReadOnly}
                    />

                </div>

                <ThreeDotsFadeIn
                    dotChildren={children}
                />

            </div>

        </div>
    );
};

const ThreeDotsFadeIn = ({
                             dotChildren
                         }) => {
    const [fadeIn, setFadeIn] = React.useState(false);
    const fadeClickHandler = () => {
        setFadeIn(!fadeIn);
    };

    if (!dotChildren) {
        return null;
    }

    return (
        <div className={styles.dots}>

            <Fade in={fadeIn}>
                <div id='dots-fade-container'>
                    <div id='dots-content'>
                        {dotChildren}
                    </div>
                </div>
            </Fade>

            <img
                id='three-dot-image'
                src={threeDotLogo}
                onClick={fadeClickHandler}
                alt='Dots'/>
        </div>
    );
};

// Convert string of decimal to percent
export const parseStringPercent = (strPercent, depth = 2) => {
    if (!strPercent) {
        return 0;
    }
    const parsed = parseFloat(strPercent) * 100;
    return parsed.toFixed(depth);
};

DashboardDatePickerBar.propTypes = {
    /**
     * Will display when the 3 dots are clicked
     */
    children: PropTypes.node
};

export {DashboardDatePickerBar};
