import ImageMessage from './messages/Image';
import MarkdownMessage from './messages/Markdown';
import BotAvatar from "./componenets/BotAvatar";

const botName = "Doughy";

const markdownString = `
**Use ConfigAI for Optimization (Optional):**
- If unsure about Memory Decay rate or Saturation, use ConfigAI by clicking “?” next to the fields.
- Select the variables for ConfigAI and click “Suggest with ConfigAI”.
- Wait for the suggestions to populate, then choose the values for your model.
![Configuration: ConfigAI](https://cdn.docsie.io/workspace_4rBCYifqjO1wg3ED9/doc_FQjsBaYJMXMnbGV26/file_wVVgdsPhnlX97AFCs/image14.jpg)
`;

const config = {
    botName: botName,
    initialMessages: [],
    // Add custom styles if necessary
    customStyles: {
        botMessageBox: {
            backgroundColor: "#465A6D",
        },
        chatButton: {
            backgroundColor: "#ff6b57",
        },
    },
    customComponents: {
        botAvatar: (props) => <BotAvatar/>
    },
    customMessages: {
        Image: (props) => <ImageMessage {...props} />,
        Markdown: (props) => <MarkdownMessage {...props} />,
    },

};

export default config;
