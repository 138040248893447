import React from 'react';
import {Text} from 'recharts';
import {styles} from './charts.styles';

export const EllipsesAxisTick = ({ axis = 'x', ellipses = true, display = true, payload = {value: ''}, ...rest }) => {

  //Check for any components with nested rest dimensions
  if(rest.rest){
    rest = rest.rest;
  }

  let textWidth = getTextWidth(payload.value);
  const graphMargin = (axis === 'y') ? rest.width : rest.height;

  let tempText = String(payload.value);
  let originalText = '';
  let words = tempText.split(' ');

  const firstLongWord = words.find(word => getTextWidth(word) > graphMargin);
  const index = words.indexOf(firstLongWord);
  let textResult = tempText;

  // Ellipses are applied only if there exists a word that is longer than the width
  // Otherwise, recharts applies word wrapping pretty well
  if(firstLongWord){
    originalText = words.slice(0, index).join(' ');
    tempText = firstLongWord;
    textWidth = getTextWidth(tempText);
  
    const tempSuffix = (textWidth > graphMargin && ellipses) ? '…' : '';
    textResult = tempText;

    while(textWidth > graphMargin){
      tempText = tempText.slice(0, -1);
      textResult = tempText + tempSuffix;
      textWidth = getTextWidth(textResult);
    }
  }

  return (
    <g>
      <Text width={graphMargin} className={!display ? styles.hidden : null} {...rest}>
        {originalText + ' ' + textResult}
      </Text>
      <title>{payload.value}</title>
    </g>
  );
}

export const getTextWidth = (inputText) => {
  const canvas = document.createElement('canvas');
  let context = canvas.getContext('2d');
  context.font = '12px Roboto, sans-serif';
  const metrics = context.measureText(inputText);
  return metrics.width;
}