import {call, put, takeEvery} from 'redux-saga/effects';

import {urlBuilders} from '../../../js/constants.js';

import {FETCH_KPIS_BY_CAMPAIGN, loadKpis, setLoading, setErrors} from '../kpi.js';
import {getData} from '../../../js/server.js';

export function* getKpisForCampaign(action) {
    yield put(setLoading(true));
    yield put(setErrors(''));

    const response = yield call(
        getData,
        action.token,
        action.baseUrl +
            urlBuilders.getKpisForCampaignId(action.params.campaignId)
    );

    let kpis = [];
    let errors = '';
    if (!response.error) {
        kpis = response;
    } else {
        errors = response.message;
    }
    yield put(loadKpis(kpis));
    yield put(setErrors(errors));
    yield put(setLoading(false));
}

export function* watchGetKpisForCampaign() {
    yield takeEvery(FETCH_KPIS_BY_CAMPAIGN, getKpisForCampaign);
}
