import React from 'react';

import {parseOptionalTableData} from '../../../components/data-table/TableLV';
import {
    formatDate, formatDateWithTimezoneOffset, formatUserNameV2,
} from "../../../components/data-grid/dataGridUtil";
import {R1ActionIcon, R1DataGrid} from "@rainagency/rain-one-soggy-loaf";
import {Tooltip} from "@mui/material";
import {AdImpactStatusCell} from "./AdImpactStatusCell";
import {AdImpactDeleteActionIcon} from "./AdImpactDeleteActionIcon";
import {AdImpactUpdateActionIcon} from "./AdImpactUpdateActionIcon";
import {AdImpactDownloadResultsActionIcon} from "./AdImpactDownloadResultsActionIcon";
import {AdImpactDownloadErrorsActionIcon} from "./AdImpactDownloadErrorsActionIcon";
import {AdImpactConfigActionIcon} from "./AdImpactConfigActionIcon";
import {variableNameToRaw} from "../../../js/utils";

export const AdImpactTable = ({
                              data,
                              loading,
                              fetchAdImpacts
                          }) => {

    const tableData = React.useMemo(() => {
        const mappedData = mapToTableData(data)
        return parseOptionalTableData(mappedData);
    }, [data]);

    const columns = getColumns(fetchAdImpacts);

    return (
        <R1DataGrid
            //dataTestId={'ad-impact-table-data-grid-container'}
            height={'650px'}
            data={tableData}
            columns={columns}
            loading={loading}
            enablePagination={false}
            // // Action Popover with 3 dots on right of grid
            renderActionColumn={(cell) => GetActions(cell, fetchAdImpacts)}
        />
    );
};

const getColumns = (fetchAdImpacts) => {
    return [
        {
            field: 'status',
            headerName: 'Status',
            minWidth: 224,
            maxWidth: 224,
            renderCell: (cell) => {
                return (
                    <AdImpactStatusCell
                        cell={cell}
                        fetchAdImpacts={fetchAdImpacts}
                    />
                );
            }
        },
        {
            field: 'runName',
            headerName: 'Run Name',
            flex: 1,
            minWidth: 350,
            renderCell: (cell) => {
                return (
                    <Tooltip title={`${cell.value}`} disableInteractive>
                        <span>{cell.value}</span>
                    </Tooltip>
                );
            }
        },
        {
            field: 'dependentVariable',
            headerName: 'Dependent Variable',
            flex: 1,
            minWidth: 170,
            renderCell: (cell) => {
                return (
                    <Tooltip title={`${cell.value}`} disableInteractive>
                        <span>{cell.value}</span>
                    </Tooltip>
                );
            }
        },
        {
            field: 'parentMMM',
            headerName: 'Parent MMM',
            flex: 1,
            minWidth: 200,
            renderCell: (cell) => {
                return (
                    <Tooltip title={`${cell.value}`} disableInteractive>
                        <span>{cell.value}</span>
                    </Tooltip>
                );
            }
        },
        {
            field: 'collinear',
            headerName: 'Collinear',
            flex: 1,
            maxWidth: 80,
            valueFormatter: (cell) => {
                if (cell.value) {
                    return 'True';
                }
                return '';
            }
        },
        {
            field: 'startDate',
            headerName: 'Start',
            minWidth: 100,
            valueFormatter: (cell) => {
                return formatDate(cell.value, 'MM/DD/YYYY');
            }
        },
        {
            field: 'endDate',
            headerName: 'End',
            minWidth: 100,
            valueFormatter: (cell) => {
                return formatDate(cell.value, 'MM/DD/YYYY');
            }
        },
        {
            field: 'user',
            headerName: 'User',
            flex: 1,
            maxWidth: 80,
            renderCell: (cell) => {
                const {first, last, initials} = formatUserNameV2(cell.row.user);
                if (!first.length && !last.length) {
                    return initials;
                }

                return (
                    <Tooltip title={`${first} ${last}`} disableInteractive>
                        <span>{initials}</span>
                    </Tooltip>
                );
            }
        },
        {
            field: 'runDate',
            headerName: 'Run Date',
            minWidth: 170,
            valueFormatter: (cell) => {
                return formatDateWithTimezoneOffset(cell.value+"+00:00", new Date().getTimezoneOffset());
            }
        }
    ];
};

const GetActions = (cell, fetchAdImpacts) => {
    const isComplete = cell.row.status === 'COMPLETED';
    const isError = cell.row.status === 'ERRORS';

    return (
        <R1ActionIcon
            iconType='VerticalDots'
            PopoverContent={[
                <AdImpactUpdateActionIcon
                    key="1"
                    cell={cell}
                    fetchAdImpacts={fetchAdImpacts}
                />,
                <AdImpactDownloadResultsActionIcon
                    key="2"
                    cell={cell}
                    hide={!isComplete}
                />,
                <AdImpactDownloadErrorsActionIcon
                    key="3"
                    cell={cell}
                    hide={!isError}
                />,
                <AdImpactDeleteActionIcon
                    key="4"
                    cell={cell}
                    fetchAdImpacts={fetchAdImpacts}
                />,
                <AdImpactConfigActionIcon
                    key="5"
                    cell={cell}
                />,
            ]}
        />
    );
};

export const mapToTableData = (data) => {
    if(!Array.isArray(data)) {
        return data;
    }

    return data.map(d => {
        const {
            wavecast_id: id,
            wavecast_name: runName,
            run_status: status,
            analysis_variable: dependentVariable,
            mca_run_name: parentMMM,
            coliniarity_present: collinear,
            start_date: startDate,
            end_date: endDate,
            user_first_name: firstName,
            user_last_name: lastName,
            campaign_id: campaignId,
            run_date: runDate
        } = d;

        return {
            id,
            runName,
            status: status?.toUpperCase(),
            dependentVariable: variableNameToRaw(dependentVariable),
            parentMMM,
            collinear,
            startDate,
            endDate,
            user: {
                firstName,
                lastName
            },
            runDate,
            campaignId
        };
    });
};