import React from 'react';
import './Ostrow.css';
import Slider from '@mui/material/Slider';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Chiclet } from '../dashboards/Chiclet'
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import { useState } from 'react';

const OSTROW_STATE = {
    OSTROW_OPENING_PAGE: 'OSTROW_OPENING_PAGE',
    MARKET_FACTORS: 'MARKET_FACTORS',
    MESSAGE_FACTORS: 'MESSAGE_FACTORS',
    MEDIA_FACTORS: 'MEDIA_FACTORS',
    OSTROW_CALCULATION: 'OSTROW_CALCULATION'
}
const steps = ['Market Factors', 'Message Factors', 'Media Factors']

export const Ostrow = () => {
    const [page, setPage] = React.useState(OSTROW_STATE.OSTROW_OPENING_PAGE)
    const [marketFactorsState, setMarketFactorsState] = React.useState(marketFactors)
    const [messageFactorsState, setMessageFactorsState] = React.useState(messageFactors)
    const [mediaFactorsState, setMediaFactorsState] = React.useState(mediaFactors)
    const [activeStep, setActiveStep] = React.useState(0)
    var [baseFrequency, setBaseFrequency] = useState(3.0)

    const resetAll = () => {
        setMarketFactorsState(marketFactors)
        setMessageFactorsState(messageFactors)
        setMediaFactorsState(mediaFactors)
    }

    let column = (
        <OstrowOpeningPage
            start={OSTROW_STATE.MARKET_FACTORS}
            setPage={setPage}
        />
    )
    if (page === OSTROW_STATE.MARKET_FACTORS) {
        column = <SelectorColumn
            data={marketFactorsState}
            onChange={setMarketFactorsState}
            setActiveStep={setActiveStep}
            activeStep={activeStep}
            next={OSTROW_STATE.MESSAGE_FACTORS}
            back={OSTROW_STATE.OSTROW_OPENING_PAGE}
            setPage={setPage}
            baseFrequency={baseFrequency}
            setBaseFrequency={setBaseFrequency}
        />
    }
    if (page === OSTROW_STATE.MESSAGE_FACTORS) {
        column = <SelectorColumn
            data={messageFactorsState}
            onChange={setMessageFactorsState}
            setActiveStep={setActiveStep}
            activeStep={activeStep}
            next={OSTROW_STATE.MEDIA_FACTORS}
            back={OSTROW_STATE.MARKET_FACTORS}
            setPage={setPage}
            baseFrequency={baseFrequency}
            setBaseFrequency={setBaseFrequency}
        />
    }
    if (page === OSTROW_STATE.MEDIA_FACTORS) {
        column = <SelectorColumn
            data={mediaFactorsState}
            onChange={setMediaFactorsState}
            setActiveStep={setActiveStep}
            activeStep={activeStep}
            next={OSTROW_STATE.OSTROW_CALCULATION}
            back={OSTROW_STATE.MESSAGE_FACTORS}
            setPage={setPage}
            baseFrequency={baseFrequency}
            setBaseFrequency={setBaseFrequency}
        />
    }
    if (page === OSTROW_STATE.OSTROW_CALCULATION) {
        column = <OstrowCalculations
            data1={marketFactorsState}
            data2={messageFactorsState}
            data3={mediaFactorsState}
            setActiveStep={setActiveStep}
            activeStep={activeStep}
            back={OSTROW_STATE.OSTROW_OPENING_PAGE}
            setPage={setPage}
            resetAll={resetAll}
            baseFrequency={baseFrequency}
            setBaseFrequency={setBaseFrequency}
        />
    }
    return (
        <div className='ostrow-page'>
            <div className='ostrow-chiclet'>
                <Chiclet>
                    {column}
                </Chiclet>
            </div>
        </div>
    );
}

const OstrowOpeningPage = ({ start, setPage }) => {
    const startHandler = () => {
        setPage(start)
    }
    return (
        <div className='ostrow-opening-page'>
            <div className='ostrow-opening-page-header'>
                Ostrow Model of Effective Frequency
            </div>
            <div className='start-text'>
                {titleText.startText}
            </div>
            <div className='middle-text'>
                {titleText.middleText}
            </div>
            <div className='end-text'>
                {titleText.endText}
            </div>
            <div className='ostrow-start-button'>
                <button
                    className='start-button'
                    onClick={startHandler}
                >
                    LET'S GET STARTED
                </button>
            </div>
            <div className='citation-text'>
                {citationText}
            </div>
        </div>
    )
}

const Steppers = ({ activeStep }) => {
    return (
        <div className='ostrow-steppers'>
            <Stepper activeStep={activeStep}>
                {steps.map((label) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
        </div>
    )
}

const SelectorColumn = ({
    data = {}, back, next, setActiveStep, activeStep, setPage, onChange, baseFrequency, setBaseFrequency
}) => {
    const nextHandler = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
        setPage(next)
    }
    const backHandler = () => {
        if (activeStep === 0) {
            setActiveStep(activeStep)
        }
        else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1)
        }
        setPage(back)
    }
    const selectors = Object.entries(data).map(row => {
        const key = row[0]
        const value = row[1]
        return Selector({ data: value, key, onChange, fullModel: data })
    })
    return (
        <div className='ostrow-selector-page'>
            <div className='base-frequency-box'>
                <div className='frequency-label'>
                    Base <br />
                    Frequency
                    <Tooltip title={"Base frequency can be modified based on media type"} placement="bottom">
                    <button className='frequency-tool-tip-button'>
                        ?
                    </button>
                </Tooltip>
                    </div>
                    <div>
                    <TextField
                        autoComplete="off"
                        type="number"
                        step="0.1"
                        id="base-frequency"
                        defaultValue={baseFrequency}
                        variant="standard"
                        onChange={(e) => setBaseFrequency(e.target.value)} />
                </div>
            </div>
            <span className='ostrow-title-stepper'>
                <div className='Header'>
                    Ostrow Model of Effective Frequency
                </div>
                <Steppers activeStep={activeStep} />
            </span>
            <div className='ostrow-selector-column'>
                {selectors}
            </div>
            <div className='ostrow-buttons'>
                <button
                    className='ostrow-back-button'
                    onClick={backHandler}
                    disabled={!back}
                >
                    BACK
                </button>
                <button
                    className='ostrow-next-button'
                    onClick={nextHandler}
                    disabled={!next}
                >
                    NEXT
                </button>
            </div>
        </div>
    );
};

const Selector = ({ data = {}, key, onChange, fullModel }) => {
    const onChangeHandler = (event, value) => {
        const updatedModel = {
            ...fullModel,
            [key]: {
                ...data, value
            }
        }
        onChange(updatedModel)
    }
    return (
        <div className='ostrow-selector' key={key} >
            <div className='ostrow-slider-label'>
                {data.label1}
                <Tooltip title={data.toolTip} placement="right">
                    <button className='ostrow-tool-tip-button'>
                        ?
                    </button>
                </Tooltip>
            </div>
            <div className='ostrow-slider'>
                <Slider
                    onChangeCommitted={onChangeHandler}
                    defaultValue={data.value}
                    step={0.1}
                    min={-0.2}
                    max={0.2}
                    marks={data.marks || defaultMarks}
                    width={20}
                />            </div>
        </div>
    )
};

const OstrowCalculations = ({
    data1, data2, data3, setActiveStep, activeStep, back, setPage, baseFrequency, setBaseFrequency,
    resetAll = () => { }
}) => {
    var ostrowFrequency = parseFloat(baseFrequency)
    const backHandler = () => {
        resetAll()
        setActiveStep(0)
        setPage(back)
    }
    for (const [key] of Object.entries(data1)) {
        ostrowFrequency += data1[key].value
    }
    for (const [key] of Object.entries(data2)) {
        ostrowFrequency += data2[key].value
    }
    for (const [key] of Object.entries(data3)) {
        ostrowFrequency += data3[key].value

    }
    return (
        <div className='ostrow-calculation-page'>
            <div className='ostrow-title-stepper'>
                <div className='Header'>
                    Ostrow Model of Effective Frequency
                </div>
                <Steppers activeStep={activeStep} />
            </div>
            <div className='ostrow-optimal-frequency-header'>
                Four-Week Period Recommended Optimal Campaign Frequency
            </div>
            <div className='ostrow-optimal-frequency'>
                {ostrowFrequency.toFixed(1)}
            </div>
            <div className='ostrow-calculate-button'>
                <button
                    className='calculate-button'
                    onClick={backHandler}
                >
                    RECALCULATE
                </button>
            </div>
        </div>
    )
}

const defaultMarks = [
    {
        value: 0.0,
        label: '',
    },
    {
        value: -0.1,
        label: 'Moderately Low',
    },
    {
        value: -0.2,
        label: 'Low',
    },
    {
        value: 0.1,
        label: 'Moderately High',
    },
    {
        value: 0.2,
        label: 'High',
    }
];

const titleText = {
    startText: 'Joseph W. Ostrow* created a decision model to help marketers determine the optimal frequency level through assessing marketing, messaging and media factors.',
    middleText: 'Starting with a base effective frequency of three (or can be modified by media type) the model makes frequency adjustments based on a series of 20 factors within three categories of market, messaging and media. Each category includes several statements, which the user assesses by selecting an appropriate rating for the brand.',
    endText: 'After assessing the 20 factors, the tool sums the adjustments to calculate the recommended effective frequency. Users may modify the model by adding or removing statements to make the estimate more appropriate.'
}

const citationText = '*Joseph W. Ostrow (1984), "Setting Frequency Levels: An Art or a Science?" Journal of Advertising Research, 24(4), I-9 to I-11.'

const marketFactors = {
    establishedBrand: {
        label1: 'Brand Recognition',
        value: 0,
        marks: [
            { value: 0.0, label: '' }, { value: -0.1, label: 'Moderately Established' }, { value: -0.2, label: 'Established' }, { value: 0.1, label: 'Moderately New' }, { value: 0.2, label: 'New' }
        ],
        toolTip: 'How established is your brand?'
    },
    brandShare: {
        label1: 'Brand Share',
        value: 0,
        marks: [
            { value: 0.0, label: '' }, { value: -0.1, label: 'Moderately High' }, { value: -0.2, label: 'High' }, { value: 0.1, label: 'Moderately Low' }, { value: 0.2, label: 'Low' }
        ],
        toolTip: 'How much market share does the brand have?'
    },
    brandLoyalty: {
        label1: 'Brand Loyalty',
        value: 0,
        marks: [
            { value: 0.0, label: '' }, { value: -0.1, label: 'Moderately High' }, { value: -0.2, label: 'High' }, { value: 0.1, label: 'Moderately Low' }, { value: 0.2, label: 'Low' }
        ],
        toolTip: 'How much loyalty does the brand have?'
    },
    purchaseCycle: {
        label1: 'Purchase Cycle',
        value: 0,
        marks: [
            { value: 0.0, label: '' }, { value: -0.1, label: 'Moderately Long' }, { value: -0.2, label: 'Long' }, { value: 0.1, label: 'Moderately Short' }, { value: 0.2, label: 'Short' }
        ],
        toolTip: 'How long is the purchase cycle - brand is a considered purchase or impulse purchase?'
    },
    frequencyUsage: {
        label1: 'Frequent Usage',
        value: 0,
        toolTip: 'How often do consumers purchase the product?'
    },
    ShareOfVoice: {
        label1: 'Share of Voice',
        value: 0,
        toolTip: "What is the share of voice of the brand's advertising?"
    },
    TargetGroup: {
        label1: 'Target Group',
        value: 0,
        marks: [
            { value: 0.0, label: '' }, { value: 0.2, label: 'Ages 13-35' }, { value: -0.1, label: 'Ages 12 & Under' }, { value: 0.1, label: 'Ages 36-64' }, { value: -0.2, label: 'Ages 65 & Over' }
        ],
        toolTip: 'Who is your target audience?'
    }
};

const messageFactors = {
    messageComplexity: {
        label1: 'Message Complexity',
        value: 0,
        toolTip: 'Does the brand require more explanation in messaging?'
    },
    messageUniqueness: {
        label1: 'Message Uniqueness',
        value: 0,
        marks: [
            { value: 0.0, label: '' }, { value: -0.1, label: 'Moderately High' }, { value: -0.2, label: 'High' }, { value: 0.1, label: 'Moderately Low' }, { value: 0.2, label: 'Low' }
        ],
        toolTip: "How differentiated is the brand's messaging?"
    },
    campaign: {
        label1: 'Campaign Age',
        value: 0,
        marks: [
            { value: 0.0, label: '' }, { value: -0.1, label: 'Moderately Continued' }, { value: -0.2, label: 'Continued' }, { value: 0.1, label: 'Moderately New' }, { value: 0.2, label: 'New' }
        ],
        toolTip: 'How long has the campaign been in market?'
    },
    focusedMessage: {
        label1: 'Product vs Image Focus',
        value: 0,
        marks: [
            { value: 0.0, label: '' }, { value: -0.1, label: 'Moderate Product' }, { value: -0.2, label: 'Product' }, { value: 0.1, label: 'Moderate Image' }, { value: 0.2, label: 'Image' }
        ],
        toolTip: 'Is the messaging more product (rational) focused or is it more emotional/evocative messaging?'
    },
    messageVariety: {
        label1: 'Message Variety',
        value: 0,
        toolTip: 'Is there a variety of creative executions?'
    },
    wearout: {
        label1: 'Wearout',
        value: 0,
        marks: [
            { value: 0.0, label: '' }, { value: -0.1, label: 'Moderately High' }, { value: -0.2, label: 'High' }, { value: 0.1, label: 'Moderately Low' }, { value: 0.2, label: 'Low' }
        ],
        toolTip: 'Does the messaging have a high or low wear out?'
    },
    advertisingUnits: {
        label1: 'Advertising Units',
        value: 0,
        marks: [
            { value: 0.0, label: '' }, { value: -0.1, label: 'Moderately Large' }, { value: -0.2, label: 'Large' }, { value: 0.1, label: 'Moderately Small' }, { value: 0.2, label: 'Small' }
        ],
        toolTip: 'What formats will be put in market?'
    }
};

const mediaFactors = {
    clutter: {
        label1: 'Clutter',
        value: 0,
        toolTip: 'Is there a lot of advertising clutter in the media placed for this category?'
    },
    editorialSetting: {
        label1: 'Editorial Setting',
        value: 0,
        marks: [
            { value: 0.0, label: '' }, { value: -0.1, label: 'Moderately High' }, { value: -0.2, label: 'High' }, { value: 0.1, label: 'Moderately Low' }, { value: 0.2, label: 'Low' }
        ],
        toolTip: 'What is the compatibility between the ads and the content where they will be placed?'
    },
    audienceAttentiveness: {
        label1: 'Audience Attentiveness',
        value: 0,
        marks: [
            { value: 0.0, label: '' }, { value: -0.1, label: 'Moderately High' }, { value: -0.2, label: 'High' }, { value: 0.1, label: 'Moderately Low' }, { value: 0.2, label: 'Low' }
        ],
        toolTip: 'How attentive is the audience to the media?'
    },
    scheduling: {
        label1: 'Scheduling',
        value: 0,
        marks: [
            { value: 0.0, label: '' }, { value: -0.1, label: 'Moderate Continuous' }, { value: -0.2, label: 'Continuous' }, { value: 0.1, label: 'Moderate Pulse or Flight' }, { value: 0.2, label: 'Pulse or Flight' }
        ],
        toolTip: 'How will the media be flighted?'
    },
    mediaVehicles: {
        label1: 'Media Vehicles',
        value: 0,
        marks: [
            { value: 0.0, label: '' }, { value: -0.1, label: 'Moderate Few' }, { value: -0.2, label: 'Few' }, { value: 0.1, label: 'Moderate More' }, { value: 0.2, label: 'More' }
        ],
        toolTip: 'How many different media vehicles will be used in the campaign?'
    },
    repeatExposureMedia: {
        label1: 'Media Repetition Opportunity',
        value: 0,
        marks: [
            { value: 0.0, label: '' }, { value: -0.1, label: 'Moderately High' }, { value: -0.2, label: 'High' }, { value: 0.1, label: 'Moderately Low' }, { value: 0.2, label: 'Low' }
        ],
        toolTip: 'What is the opportunity to reach the same audience with the same ad?'
    }
}
