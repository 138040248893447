import {css} from 'emotion';

export const styles = {
  container: css`
    display: flex;
    justify-content: center;
    width: 100%;

    .graph-panels-container {
      display: grid;
      grid-template-columns: 12fr;
      width: 100%;
    }

    &.two-columns {
      .graph-panels-container {
        grid-template-columns: 6fr 6fr;
        gap: 24px;
      }
    }
  `,
};
