import {css} from 'emotion';

export const styles = {
    page: css`
        display: flex;
        height: 100%;
        margin: 0;
    `,
    mcaModelsCountContainer: css`
        position: sticky;
        top: 0;
        height: 65px;
        background: #FFFFFF;
        font-weight: 300;
    `,
    filterChipsContainer: css`
        position: sticky;
        top: 68px;
        padding-left: 24px;
        max-height: 96px;
        min-height: 48px;
    `,
    mcaModelsCount: css`
        padding-top: 32px;
        padding-bottom: 32px;
        padding-left: 24px;
        font-weight: 300;
        font-size: 24px;
        color: #374f63;
    `,
    mcaModelsTotal: css`
        font-size: 12px;
        color: #7e9292;
    `,
    tableContainer: css`
        padding-bottom: 112px;
        border-style: none !important;
        background: #FFFFFF;
    `,
    updateButtonContainer: css`
        position: absolute;
        top: 224px;
        right: 40px;
        height: 48px;
        color: white;
        z-index: 10;
    `,
    updateButton: css`
        border: 1px solid rgba(126, 146, 146, 0.25);
        box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        background: #FFF !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        color: #374f63 !important;
        text-align: center;
        line-height: 16px;
    `,
    spinnerContainer: css`
        display: flex;
        width: inherit;
        padding-top: 100px;
        justify-content: center;
    `,
    errors: css`
        display: flex;
        width: 100vw;
        padding-top: 100px;
        justify-content: center;
        color: red;
        text-align: center;
    `,
    leavenedLogo: css`
        max-width: 9em;
        max-height: 60px;
    `,
    msgBoxContainer: css`
        top: -2px;
        font-size: 14px;
        font-weight: 300;
        color: #374f63;
        background: #FFFFFF !important;
    `,
    msgBoxScroll: css`
        background: #FFFFFF !important;
    `,
    messageBox: css`
        width: 450px;
        padding-bottom: 100px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        font-size: 14px;
        color: #333333;
    `,
    modelsTitle: css`
        font-family: Roboto, sans-serif !important;
        font-size: 80px;
        color: #304658;
        font-weight: 300;
        text-align: center;
        padding-bottom: 16px;
        width: 100%;
    `,
    filterButtonContainer: css`
        margin-left: auto;
        margin-right: auto;
        padding-top: 24px !important;
        justify-content: center;
    `,
    filterButton: css`
        font-weight: 500 !important;
        color: #FFFFFF !important;
        font-size: 14px;
        background: #FF6B57 !important;
        border-color: #FF6B57 !important;
        border-radius: 200px !important;
        height: 36px !important;
        width: 208px !important;
    `,
    downloadButtonContainer: css`
        margin-left: auto;
        margin-right: auto;
        padding-top: 18px !important;
        padding-bottom: 24px;
        justify-content: center;
    `,
    downloadButton: css`
        font-weight: 500 !important;
        color: #FFFFFF !important;
        font-size: 14px;
        background: #BDBDBD !important;
        border-color: #BDBDBD !important;
        border-radius: 200px !important;
        height: 36px !important;
        width: 208px !important;
    `
};
