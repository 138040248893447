import React from 'react';
import {CampaignManagement} from '../../components/campaign-management/CampaignManagement';
import {useCampaignManagement} from '../../hooks/useCampaignManagement';
import {useProduct, useSetProduct} from '../../hooks/useProduct';
import {ButtonLV} from '../../components/form/buttons/ButtonLV';
import {SuccessFailStatusModal} from '../../components/modal/SuccessFailStatusModal';
import {ModalLV} from '../../components/modal/ModalLV';
import {FormRowLV} from '../../components/form/row/FormRowLV';
import {NewCampaignForm} from './NewCampaignForm';
import {NewClientForm} from './NewClientForm';
import {useDefaultIds} from '../../hooks/useDefaultIds';
import _ from 'lodash';
import {useGetUserPrivileges} from '../../hooks/useGetUserPrivileges';

/**
 * Handle getting Partner/Client/Campaign rest data and
 * setting allowing user to select it
 */
export const CampaignManagementContainer = ({
  data = [],
  loading,
  refetch
}) => {
  const selectedProduct = useProduct();
  const redirectToProduct = useSetProduct();
  const [isCampaignSelected, setIsCampaignSelected] = React.useState(false);
  const [editCampaignValues, setEditCampaignValues] = React.useState({});

  const {
    partnerId, 
    clientId, 
    campaignId,
    setPartnerId,
    setClientId,
    setCampaignId
  } = useCampaignManagement();

  // Edit Campaign/Client Modal popup
  const handleEditClick = (row, type) => {
    setEditCampaignValues({
      isOpen: true,
      type,
      ...row
    });
  };

  const handlePartnerSelect = (row) => {
    const id = _.get(row, 'id');
    setPartnerId(id);
  };

  const handleClientSelect = (row) => {
    const id = _.get(row, 'id');
    setClientId(id);
  };

  // Jump to product link when campaign is selected
  const handleCampaignSelect = (row) => {
    const id = _.get(row, 'id');
    setCampaignId(id);
    setIsCampaignSelected(true);
  };

  // Redirect to Product path if campaign is selected
  React.useEffect(() => {
    //const selections = [partnerId, clientId, campaignId, isCampaignSelected];
    const selections = [campaignId, isCampaignSelected];
    if(selections.some(selection => !selection)) {
      return;
    }
    redirectToProduct(selectedProduct.key);
  }, [campaignId, redirectToProduct, selectedProduct.key, isCampaignSelected]);

  let currentPartner = React.useMemo(
    ()=> getResponsePartnerById(data, partnerId), [data, partnerId]
  );

  let currentClient = React.useMemo(
    ()=> getResponseClientById(data, clientId), [data, clientId]
  );

  const currentCampaign = React.useMemo(
    ()=> getResponseCampaignById(data, campaignId), [data, campaignId]
  );

  /**
   * Display Partner/Client columns only if more than one are available
   * Back-end will restrict visible Partner/Clients by role 
   */
  const {
    defaultPartnerId,
    defaultClientId,
  } = useDefaultIds(data);

  const userPrivileges = useGetUserPrivileges();

  // If partners are not viewable to be selectable, set currentPartner to default
  if(!userPrivileges.canViewPartner && defaultPartnerId) {
    currentPartner = data.find(p => p.id === defaultPartnerId);
  }

  // If clients are not viewable to be selectable, set currentClient to default
  if(!userPrivileges.canViewCompany && currentPartner && defaultClientId) {
    currentClient = currentPartner.companies.find(c => c.id === defaultClientId);
  }

  const onModalCloseHandler = () => {
    setEditCampaignValues({isOpen: false});
    refetch();
  };

  /**
   * Markdown
   */
  return(
    <>

      <CampaignManagement
        data={data}
        isLoading={loading}
        onEditClick={handleEditClick}
        partnerSelect={handlePartnerSelect}
        clientSelect={handleClientSelect}
        campaignSelect={handleCampaignSelect}
        currentSelectedPartner={currentPartner}
        currentSelectedClient={currentClient}
        currentSelectedCampaign={currentCampaign}
        displayPartner={userPrivileges.canViewPartner}
        displayClient={userPrivileges.canViewCompany}
      />

      <ModalLV
        minWidth={700}
        minHeight={700}
        isOpen={editCampaignValues.isOpen}>

        <div/>
        <EditContainer
          partners={data}
          onCloseCallback={onModalCloseHandler}
          {...editCampaignValues}
        />
      </ModalLV>

    </>
  );
};

export const EditContainer = ({
  partners,
  onCloseCallback=()=>{},
  closeModal=()=>{},
  type,
  ...props
}) => {
  const [error, setError] = React.useState();

  React.useEffect(() => {
    return () => onCloseCallback({isOpen: false});
  }, [onCloseCallback]);

  if(error) {
    return (
      <SuccessFailStatusModal
        isError={true}
        title={<div data-test='editComplete-title'>{error.title}</div>}
        description={<div>
                     <p data-test='editComplete-text'>{error.text}</p>
                     <p data-test='editComplete-message'>{error.description}</p>
                   </div>}>

        <FormRowLV>
          <ButtonLV
            onClick={closeModal}
            dataTest='editComplete-closeButton'>
            CLOSE
          </ButtonLV>
        </FormRowLV>        
      </SuccessFailStatusModal>
    );
  }

  if(type === 'Campaign') {
    return (
      <FormRowLV>
        <NewCampaignForm
          formTitle={'Edit Campaign'}
          partners={partners}
          cancelClick={closeModal}
          isEdit={true}
          defaultValues={props}
          setError={setError}
          setSuccess={closeModal}
        />        
      </FormRowLV>
    );
  }

  return (
    <FormRowLV>
      <NewClientForm
        formTitle={'Edit Client'}
        partners={partners}
        cancelClick={closeModal}
        isEdit={true}
        defaultValues={props}
        setError={setError}
        setSuccess={closeModal}
      />        
    </FormRowLV>
  );
};

const getResponsePartnerById = (data=[], id) => {
  if(!Array.isArray(data) || !id) {
    return undefined;
  }
  return data.find(row => row.id === id);
};

const getResponseClientById = (data=[], id) => {
  if(!Array.isArray(data) || !id) {
    return undefined;
  }
  let clients = [];
  data.forEach(partner => {
    clients = [...clients, ...partner.companies];
  });
  return clients.find(client => client.id === id);
};

const getResponseCampaignById = (data=[], id) => {
  if(!Array.isArray(data) || !id) {
    return undefined;
  }

  let clients = [];
  data.forEach(partner => {
    clients = [...clients, ...partner.companies];
  });

  let campaigns = [];
  clients.forEach(client => {
    campaigns = [...campaigns, ...client.campaigns];
  });

  return campaigns.find(campaign => campaign.id === id);
};
