import {css} from 'emotion';
import {commonStyles} from '../../../styles/commonStyles';

// Styles based on light/dark theme
export const getStyles = ({isDark = true}) => {
    const color = isDark ? '#FFF' : '#333';
    const labelColor = isDark ? '#FFF' : '#828282';
    return {
        container: css`
          width: 100%;
        `,
        hidden: css`
          display: none;
        `,
        textField: css`
          display: block;
          width: 100%;
          ${commonStyles.fontFamily}
          letter-spacing: 0.4px;
          font-size: 12px;
          background: none;
          border: 0;
          border-bottom: 0.5px solid ${color};
          color: ${color};
          outline: none;

          ::placeholder {
            opacity: 0.8;
          }

          :disabled {
            color: #8C8C8C;
          }
        `,
        label: css`
          display: block;
          ${commonStyles.fontFamily}
          letter-spacing: 0.4px;
          font-size: 9px;
          color: ${labelColor};
        `,
        error: css`
          display: block;
          float: right;
          ${commonStyles.fontFamily}
          letter-spacing: 0.4px;
          font-size: 9px;
          color: red;
          margin-bottom: -16px;
        `,
        description: css`
          display: block;
          float: left;
          ${commonStyles.fontFamily}
          letter-spacing: 0.4px;
          font-size: 9px;
          color: ${labelColor};
          margin-bottom: -16px;
        `,
        lastPass: css`
          [data-lastpass-icon-root], [data-lastpass-root] {
            display: none !important;
          }
        `
    };
};
