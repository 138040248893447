import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  Legend,
} from 'recharts';

import {CustomTooltip} from './CustomTooltip';
import {formatAxis, getIsAnimationActive} from './chart-format.util';
import {useValidateChartData} from './useValidateChartData';
import {styles, getChartStateCss, legendContent} from './charts.styles';
import {useToggleColumnLegend} from "./CustomLegend";
import {capitalCase, sentenceCase} from "change-case";
import {getChartColor} from "./chartColors";
import {ScrollBrush} from './ScrollBrush';

/**
 * Builds a Column chart using bar chart,
 * http://recharts.org/en-US/api/BarChart
 */
export const CombChartLV = ({
  data,
  yAxisLabel='',
  xAxisKey='date',
  yAxisKey,
  yTick=11,
  filterKey ='',
  isLoading=false,
  formatMap={},
  colorMap={},
  disableLegendColumns=[],
  scrollBar={}
}) => {

  const chartData = useValidateChartData(data, 5, '', xAxisKey);
  const chartStateCss = getChartStateCss(data, isLoading);

  const {
    legendClickHandler,
    hiddenColumnMap,
    CustomLegend
  } = useToggleColumnLegend(chartData);
  const isAnimationActive = getIsAnimationActive(chartData, scrollBar.minLength);
  const Columns = getCombBars(
    chartData, filterKey, isAnimationActive, hiddenColumnMap, colorMap
  );
  const legendKey = capitalCase(sentenceCase(filterKey));
  const classNames = [styles.chart,
    chartStateCss,
    legendContent(legendKey)
  ].join(' ');

  // Wrapping ReCharts component won't render in markup, so we call it like a function
  const ScrollBar = ScrollBrush({
    data: chartData,
    dataKey: xAxisKey,
    minLength: scrollBar.minLength
  });

  const layout = 'horizontal';

  return (
    <ResponsiveContainer
      width={'100%'}
      height={400}
      debounce={1}
      id='bar-chart'
      className={classNames}>

      <BarChart
        width={600}
        height={400}
        data={chartData}
        margin={{top: 10, right: 0, left: 0, bottom: 0}}
        style={{
          marginTop: !!data ? '-24px' : '0'
        }}
        layout={layout}
        barSize={60}>

        <XAxis
          angle={-45}
          height={scrollBar.minLength ? 84 : 100}
          dataKey={xAxisKey}
          textAnchor={"end"}
          tickMargin={10}
          tickSize={0}
          tickFormatter={formatAxis(xAxisKey, formatMap)}
          type={'category'}
        />

        <YAxis
          width={100}
          tickMargin={10}
          axisLine={false}
          tickSize={0}
          tickCount={yTick}
          tickFormatter={formatAxis(yAxisKey, formatMap)}
          label={{
            value: yAxisLabel,
            angle: -90,
            position: 'insideLeft'
          }}
          type={'number'}
        />

        <CartesianGrid vertical={false} />

        <Tooltip
          isAnimationActive={false}
          content={<CustomTooltip map={formatMap} hiddenColumnMap={hiddenColumnMap}/>}
          cursor={false}/>

        <Legend
          align='left'
          verticalAlign='top'
          hiddenColumnMap={hiddenColumnMap}
          disableLegendColumns={disableLegendColumns}
          onClick={legendClickHandler}
          isLoading={isLoading}
          content={CustomLegend}
        />

        {Columns}

        {ScrollBar}

      </BarChart>
    </ResponsiveContainer>
  );
};

export const getCombBars = (
  data, filterKey, isAnimationActive, hiddenColumnMap={}, colorMap={}
) => {
  const numberOfFilters = getKeys(data, filterKey);
  return numberOfFilters.map((filter, index) => {
    const fillColor = getChartColor(index, colorMap, filter);
    return <Bar
      dataKey={filter}
      hide={hiddenColumnMap[filter]}
      key={`bar-key-${filter}`}
      name={filter}
      fill={fillColor}
      isAnimationActive={isAnimationActive}>
    </Bar>
  });
};

export const getKeys = (data, key) => {
  if(!data || !data.length) {
    return [];
  }

  const DEFAULT_KEY = 'default';
  const filterMap = {};
  data.forEach(row => {
    const filterKey = row[key] || DEFAULT_KEY;
    if(!Array.isArray(filterMap[filterKey])) {
      filterMap[filterKey] = [];
    }
    filterMap[filterKey].push(row);
  });
  return Object.keys(filterMap);
};
