import React from 'react';
import {GraphContainerLV} from '../../../../components/dashboards/GraphContainerLV';
import {GRAPH_OPTIONS} from '../../../../components/dashboards/graph-options.constant';
import {GraphConfig} from '../../../../components/dashboards/GraphConfig';
import {Chiclet} from '../../../../components/dashboards/Chiclet';
import {ChicletTitle} from '../../../../components/dashboards/ChicletTitle';
import {CHART_FORMAT} from '../../../../components/charts/ChartFormat.constant';

import {styles} from './McaDetailsChart.styles.js';

/**
 * Models Statistics Dashboard
 * @param kpiType the kpi type for the selected run
 * @param fitLoading status of loading fit data
 * @param fitResults The fit data to be shown on the chart
 * @returns Chiclet with Total Revenue Chart
 */
export const McaDetailsChart = ({ kpiType, modelId, fitLoading, fitResults=[], fitMiss=[] }) => {
  let chartData = [];
  let fileData = [];
  if (Array.isArray(fitResults) && fitResults.length) {
    chartData = fitResults
        .map(({date, actual, predicted}) => ({
          date,
          actual: Number(actual),
          predicted: Number(predicted)
        }));
    fileData = chartData.map(data => ({...data, model_id: modelId}));
  }

  let fitMissData = [];
  if (Array.isArray(fitMissData) && fitMissData.length) {
    fitMissData = fitMiss.map(data => ({...data, model_id: modelId}));
  }

  let fitFormatMap = {};
  if(kpiType === 'Units') {
    const fitSchema = chartData.length ? chartData[0] : {};
    const yAxisKeys = Object.keys(fitSchema).filter(key =>  key !== 'date');
    yAxisKeys.forEach(key => {
      fitFormatMap[key] = CHART_FORMAT.INTEGER;
    });
  }

  const totalConfig = new GraphConfig({
    data: chartData,
    fileData: [{
      data: fileData,
      fileName: `model_fit_${modelId}`
    },
    {
      data: fitMissData,
      fileName: `model_fit_misses_${modelId}`
    }
  ],
    graphOptions: [
      GRAPH_OPTIONS.LINE,
    ],
    xAxisKey: 'date',
    yAxisLabel: `Total ${kpiType}`,
    yAxisKey: 'actual',
    formatMap: fitFormatMap,
    isLoading: fitLoading,
    csvFileName: `model_fit_${modelId}`,
    imageFileName: `Total${kpiType}`,
    imageElementId: 'model-details-chiclet-id'
  });

  return (
    <div className={styles.chicletContainer}>
      <Chiclet className={styles.chiclet}
        id='model-details-chiclet-id'>
        <ChicletTitle>Model Fit</ChicletTitle>

        <GraphContainerLV
          graphConfig={totalConfig}
        />
      </Chiclet>
    </div>
  );
};