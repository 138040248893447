import { R1MultiFileUpload } from '@rainagency/rain-one-soggy-loaf';
import { UPLOAD_FILE_TYPES } from './constants/fileTypeOptions.constant';
import { validateByColumnSchema, VALIDATION_STATUS } from "@leavened/leavened-library-constants";
import React from "react";
import { urlWithDomain, useAuthHeader } from "../../hooks/useRest";
import { uploadFileToS3 } from "../../js/storage";
import uuid from 'react-uuid';

/**
 * Wrapper for Leavened specific file uploads
 */
export const MultiFileUploadLV = ({
    uploadArgs = {},
    onComplete = () => {},
    baseUrl = '',
    step1UploadProps = {},
    step2MappingProps = {},
    step3ValidationProps = {},
    callToActionComponent,
    uploadFileTypes = UPLOAD_FILE_TYPES,
    type = 'Campaign',
    ...props
}) => {

  const postUploadFileUrl = urlWithDomain(`${baseUrl}v1/file-upload`);
  const deleteFileUrl = urlWithDomain(`${baseUrl}v1/file-metadata/:fileId`);
  const putFileUrl = urlWithDomain(`${baseUrl}v1/file-metadata/:fileId`);
  const getValidationResultUrl = urlWithDomain(`${baseUrl}v1/validate/:fileId`);
  const putValidationResultUrl = urlWithDomain(`${baseUrl}v1/validate/:fileId`);
  const postValidationCompleteUrl = urlWithDomain(`${baseUrl}v1/validate/:fileId/complete`);

  const [uploadGroupId, setUploadGroupId] = React.useState(uuid())

  const getWholeFileValidationErrors = (fileMetaData = {}) => {
    return fileMetaData.errors
        ?.filter(error => error?.row === '*')
        ?.flatMap(error => error.errors)
        ?.map((error, index) => {
          return {
            id: index,
            error
          }
        });
  }

  return (
      <R1MultiFileUpload
          getHeaders={ useAuthHeader }
          fileTypeOptions={uploadFileTypes}
          ModalActionComponent={callToActionComponent}
          ModalProps={{
            closePopover: () => {
              // Reset group id when modal closes
              setUploadGroupId(uuid())
            }
          }}
          step1UploadProps={{
            uploadStepTitle: `Upload ${type} Files`,
            uploadStepDescription: 'Upload files to use for an analysis.',
            postUploadFileUrl,
            deleteFileUrl,
            isMultipartForm: baseUrl.includes('localhost'),
            getPostUploadFileArgs: () => {
              return {
                ...uploadArgs,
                uploadGroupId
              }
            },
            uploadFile: uploadFileToS3
          }}
          step2MappingProps={{
            putFileMetaUrl: putFileUrl,
            allowNextStep: (fileMetaDataList = []) => {
              return true;
            },
            fileListPageTitleStepProvider: (fileMap) => {
              return {
                title: 'File Review',
                description: 'Click on a file to review columns and data types.'
              }
            },
            editFilePageTitleStepProvider: (file) => {
              return {
                title: 'Edit File Metadata',
                description: 'Edit column names and data types.'
              }
            }
          }}
          step3ValidationProps={{
            getValidationErrorsUrl: getValidationResultUrl,
            putValidationErrorsUrl: putValidationResultUrl,
            allowNextStep: (fileMetaDataList = []) => {
              return fileMetaDataList.every((f) => f.validationStatus === 'COMPLETE');
            },
            updateFileOnGetValidation: (response = {}) => {
              const fileData = response.fileMetaData || {};
              return {
                ...fileData,
                errors: response.errors
              };
            },
            getValidationErrors: (fileMetaData = {}) => {
              return fileMetaData.errors?.filter(error => error?.row !== '*');
            },
            getWholeFileValidationErrors,
            mapOnFormSubmit: (formValues = []) => {
              const mappedValues = formValues?.errors?.filter(error => !!error);
              return {
                data: mappedValues
              };
            },
            getGridValidationColumns: (errors = [], fileMetaData = {}) => {
              const erroredColumnMap = fileMetaData.erroredColumnMap || {};
              const additionalColumns = Object.keys(erroredColumnMap).map(k => {
                    return {
                      field: k,
                      headerName: k,
                      flex: 1,
                      inputType: 'text',
                      getFormInputProps: (cell, ...allProps) => {
                        let rules = {
                          // Use validation from constants library
                          validate: async (currentValue) => {
                            try {
                              const data = {
                                [cell.field]: currentValue
                              }
                              const schema = {
                                [cell.field]: {
                                  validation: fileMetaData.columns[cell.field]?.validation
                                }
                              }
                              await validateByColumnSchema(data, schema);
                            } catch(ex) {
                              return ex.columns?.[cell.field]?.validation?.message || 'Invalid';
                            }

                            return true;
                          }
                        };

                        return {
                          name: `errors[${cell.row.row}].${cell.field}`,
                          rules,
                        };
                      }
                    };
                  });

              return [
                {
                  field: 'id',
                  headerName: 'Line Number',
                  hide: true,
                },
                {
                  field: 'row',
                  headerName: 'Row Number',
                  inputType: 'hidden',
                  valueGetter: (cell) => {
                    // Force to number
                    return cell.value * 1;
                  },
                  getFormInputProps: (cell, ...allProps) => {
                    return {
                      name: `errors[${cell.row.row}].${cell.field}`,
                    };
                  },
                },
                ...additionalColumns
              ];
            },
            getGridValidationRows: (errors, fileMetaData) => {
              return errors;
            },
            keepPollingValidation: (response) => {
              const finalStatus = [
                VALIDATION_STATUS.COMPLETE_WITH_ERRORS,
                VALIDATION_STATUS.COMPLETE
              ];
              return !finalStatus.includes(response?.fileMetaData?.validationStatus);
            },
            isStartFileValidation: (fileMetaData) => {
              if (!fileMetaData?.validationStatus) {
                return true;
              }

              if (fileMetaData?.validationStatus === 'COMPLETE') {
                return false;
              }

              const wholeFileError = fileMetaData.errors?.find(error => error?.row === '*');

              if (wholeFileError) {
                return wholeFileError.canRestartValidation
              }

              return true;
            },
            getCurrentStatus: (fileMetaData) => {
              const vMap = {
                [VALIDATION_STATUS.COMPLETE_WITH_ERRORS]: 'INVALID',
                [VALIDATION_STATUS.COMPLETE]: 'VALID',
              };
              return vMap[fileMetaData?.validationStatus] || 'PROCESSING';
            },
            fileListPageTitleStepProvider: (fileMap) => {
              const allStatuses = Object.values(fileMap).map(file => {
                return file?.backendData?.validationStatus
              }).filter(status => !!status);

              const title = 'Validate Files';

              const isValidating = allStatuses.length === 0 || allStatuses.some(status => status === 'VALIDATING');
              if (isValidating) {
                return {
                  title,
                  description: 'Hold on while we validate these files.'
                };
              }

              const isError = allStatuses.some(status => status === 'COMPLETE_WITH_ERRORS');
              if (isError) {
                return {
                  title,
                  description: 'Errors were detected. Click on a file to continue.'
                };
              }

              const isValid = !!fileMap && allStatuses.filter(status => status === 'COMPLETE').length === Object.keys(fileMap).length;
              if (isValid) {
                return {
                  title,
                  description: 'Continue to finish the upload.'
                };
              }
            },
            editFilePageTitleStepProvider: (file) => {
              const numOfErrors = file?.backendData?.errors?.filter(error => error?.row !== '*')?.length;

              let errorDescription;

              if (numOfErrors === 1) {
                errorDescription = `1 error`;
              } else {
                errorDescription = `${numOfErrors} error's`;
              }

              return {
                title: 'Edit File and Fix Errors',
                description: `Found ${errorDescription}. Fix the following to continue.`
              }
            }
          }}
          step4CompleteProps={{
            postFileCompleteUrl: postValidationCompleteUrl,
            onComplete
          }}
      />
  );
};
