
import React from 'react';
import {
    R1ActionIcon,
} from '@rainagency/rain-one-soggy-loaf';
import { urlBuilders } from '../../../js/constants';
import { useDelete } from '../../../hooks/useRest';
import { useLoggedInUser } from '../../../hooks/useLoggedInUser';
import { useSnackbarLV } from '../../../components/snackbar/useSnackbarLV';
import { USER_GROUPS } from '../../../constants/userGroups';

/**
 * Delete a run
 * Displays on Data Grid Action Popover
 */
export const DeleteScoringActionIcon = ({
                                        cell,
                                        closePopover,
                                        hide,
                                        fetchScoringRuns
                                    }) => {

    const [{loading, response, error}, deleteScoring] = useDelete({
        url: urlBuilders.deleteScoring(cell.row.id),
    });

    const {
        enqueueSnackbar,
        enqueueCloseSnackbarLV,
    } = useSnackbarLV();

    // refresh table when response is returned
    React.useEffect(() => {
        if (!response) {
            return;
        }

        if(response.status >= 200 && response.status <= 299) {
            enqueueSnackbar(
                `Successfully deleted scoring analysis: ${cell.row.name}`,
                {
                    variant: 'success'
                }
            );
        }

        closePopover();
        fetchScoringRuns();
    }, [response]);

    // Display error
    React.useEffect(() => {
        if(!error) {
            return;
        }

        enqueueCloseSnackbarLV(
            `Error deleting scoring analysis: ${cell.row.name}, please contact Technical Support`,
            {
                variant: 'error',
                persist: false,
                autoHideDuration: 10000
            }
        );

        closePopover();
    }, [error]);

    return (
        <R1ActionIcon
            iconType='Delete'
            label='Delete Scoring Analysis'
            hide={hide}
            onDialogConfirm={deleteScoring}
            DialogProps={{
                title: `Delete ${cell.row.name}?`,
                description: `Are you sure you want to delete ${cell.row.name}? All data will be deleted forever.`,
                cancelLabel: 'Cancel',
                confirmLabel: 'Delete Forever',
                onCancel: closePopover,
                customStyles: {
                    cursor: loading ? 'wait' : 'pointer'
                }
            }}
        />
    );


};
