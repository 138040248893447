import React from 'react';
import {
    R1ActionIcon,
} from '@rainagency/rain-one-soggy-loaf';
import { urlBuilders } from '../../../../js/constants';
import { usePut } from '../../../../hooks/useRest';
import SyncIcon from '@mui/icons-material/Sync';
/**
 * Regenerate OI for an mmm run
 * Action icon in data grid popover
 */
export const RegenerateInsightsActionIcon = ({
                                     cell,
                                     hide,
                                     getRuns,
                                     closePopover
                                 }) => {

    const url = urlBuilders.updateV3McaRunStatus(cell.row.id);
    const runName = cell.row.name;

    const [{loading, response}, updateStatus] = usePut({
        url
    });

    // refresh table when response is returned
    React.useEffect(() => {
        if(!response) {
            return;
        }

        closePopover();
        getRuns();
    }, [response]);

    const handleFinalizeRun = () => {
        updateStatus({
            data: {
                runStatus: 'FINALIZING'
            }
        });
    };

    return (
        <R1ActionIcon
            label='Regenerate Insights'
            icon={SyncIcon}
            hide={hide}
            onDialogConfirm={handleFinalizeRun}
            DialogProps={{
                title: `Are you sure you want to regenerate outputs and insights for ${runName}?`,
                description: 'This may help if you are experiencing issues with this analysis. Or if the analysis is old and might be missing new features.',
                cancelLabel: 'Cancel',
                confirmLabel: 'Regenerate',
                onCancel: closePopover,
                customStyles: {
                    cursor: loading ? 'wait' : 'pointer'
                }
            }}
        />
    );
};
