import {css} from 'emotion';

export const styles = {
  container: css`
    display: flex;
    background: #F2F2F2;
    margin-right: 24px;
    height: 70px;
    border-top: 8px solid #F2F2F2;
    border-bottom: 4px solid #F2F2F2;
    width: 100%;
    padding: 12px 0;
  `,
  content: css`
    display: flex;
    justify-content: space-between;
  `,
  title: css`
    margin-left: 48px;
    padding-right: 24px;
    line-height: 48px;
    white-space: nowrap;
    min-width: 200px;
    text-overflow: ellipsis;
    overflow-x: hidden;
    overflow-y: hidden;
  `,
  scoringPicker: css`
    padding-right: 24px;
    .MuiInputBase-root {
      height: 48px;
    }
  `,
  dateContainer: css`
    display: flex;
    min-width: 200px;
  `,
  regionContainer: css`
    margin-right: 48px;
    padding-left: 24px;
    min-width: 146px;
  `,
}