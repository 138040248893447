//Constants
export const RUN_WAVECAST = `wavecast/runWavecast`;
export const WAVECAST_RESULT = `wavecast/wavecastResults`;

//Selectors
const ID = 'wavecast';
const local = (state) => state[ID];
export const getWavecastResults = (state) => local(state).wavecastResults;

//Reducer
export const initialState = {
    wavecastResults: []
};

export default function wavecastReducer(state = initialState, action) {
    switch (action.type) {
        case WAVECAST_RESULT:
            return Object.assign({}, state, {
                wavecastResults: action.wavecastResults
            });

        case RUN_WAVECAST:
            return state;

        default:
            return state;
    }
}
