import {saveAs} from 'file-saver';

/**
 *  Triggers the download of a report file from JSON to CSV
 */
class DownloadCSV {
    CLIENT_NAME = 'Client Name';
    CAMPAIGN_NAME = 'Campaign Name';
    RUN_NAME = 'Run Name';

    /**
     * Add all report data on initialize
     * @param jsonReport JSON object used for graph
     * @param fileName Name of file
     * @param clientName Name of client to display in file
     * @param campaignName Name of client to display in file
     * @param runName Name of client to display in file
     */
    constructor(jsonReport, fileName, clientName, campaignName, runName) {
        this.jsonReport = jsonReport;
        this.fileName = fileName;
        this.clientName = clientName;
        this.campaignName = campaignName;
        this.runName = runName;
    }

    /**
     * Creates the CSV file contents and triggers the download
     */
    download(save = true) {
        const csv = this.reportToCSV();
        const blob = new Blob([csv], {type: 'text/plain;charset=utf-8'});
        if (save) {
            saveAs(blob, this.fileName);
        } else {
            return blob;
        }
    }

    /**
     * Converts a JSON report to CSV string to trigger download
     * Create header rows with names, then turns each row to string and adds newline
     * @param jsonReport JSON object used for graph
     * @param clientName Name of client to display in file
     * @param campaignName Name of client to display in file
     * @param runName Name of client to display in file
     */
    reportToCSV() {
        if (!Array.isArray(this.jsonReport) || this.jsonReport.length <= 0) {
            return '';
        }

        let csv = '';
        if (this.clientName) {
            const headers = Object.keys(this.jsonReport[0]);
            csv = `${this.CLIENT_NAME},${this.clientName}\n`;
            csv += `${this.CAMPAIGN_NAME},${this.campaignName}\n`;
            csv += `${this.RUN_NAME},${this.runName}\n`;
            csv += this.rowToCSV(headers);
        } else {
            //add custom headers from jsonReport
            csv += this.rowToCSV(Object.keys(this.jsonReport[0]));
        }
        this.jsonReport.forEach((x) => {
            csv += this.rowToCSV(Object.values(x));
        });
        return csv;
    }

    /**
     * @param row Array of string values
     * @return comma separated string with newline at end
     */
    rowToCSV(row) {
        return row.toString() + '\n';
    }
}

export default DownloadCSV;
