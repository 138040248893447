import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';

/**
 * Extend notistack Snackbar usage
 * https://notistack.com/getting-started
 * API: https://notistack.com/api-reference
 */
export const useSnackbarLV = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  return {
    enqueueSnackbar,
    closeSnackbar,
    enqueueCloseSnackbarLV: getEnqueueCloseSnackbarLV(enqueueSnackbar, closeSnackbar),
  };
};

/**
 * Curry function to return enqueueSnackbar that closes
 */
const getEnqueueCloseSnackbarLV = (enqueueSnackbar, closeSnackbar) => {
  return (message, options = {}) => {
    const enqOptions = {
      persist: true,
      variant: 'success',
      

      // Button that triggers close
      action: snackId => {
        return (
          <IconButton
            sx={{
              width: '16px',
              height: '16px',
              background: '#FFF',
              '&:hover': {
                background: '#FFF',
                opacity: '0.8'
              },
              '& .MuiSvgIcon-root': {
                fontSize: '14px',
              }
            }}
            onClick={() => closeSnackbar(snackId)}>
            <Close/>
          </IconButton>
        );  
      },

      ...options
    };

    return enqueueSnackbar(message, enqOptions);
  };
};
