import {css} from 'emotion';

export const styles = {
    chicletContainer: css`
      color: #333333;
      width: 100% !important;
    `,
    chiclet: css`
      height: 530px !important;
    `,
    regionContainer: css`
      margin-top: 15px;
      margin-left: 100px;
      width: 146px;
      display: flex;
      align-items: center;
      padding-right: 24px;
    `
};
