import React from 'react';
import {useGet} from './useRest';
import {urlBuilders} from '../js/constants';
import {useCampaignManagement} from './useCampaignManagement';

export const CAMPAIGN_ID_URL_PARAM = 'campaignId';

/**
 * Return the currently selected campaign's information
 * @return GET response from Rest Hook
 */
export const useCampaign = () => {
  const {campaignId, setCampaignId} = useCampaignManagement();
  const url = urlBuilders.getCampaignById(campaignId);
  const [{
    data={},
    loading,
    error,
    response={}
  }, fetch] = useGet({
    url,
    manual: true
  });

  React.useEffect(() => {
    if(!campaignId) {
      return;
    }
    fetch();
  },[campaignId, fetch]);

  // Clear campaignId from url on error
  React.useEffect(() => {
    if(loading || !campaignId) {
      return;
    }

    // Reset campaignId on error, unless response does not return a status
    // Unauthorized errors occur when rolling cognito token, but will work on next call
    if(error && response.status) {
      setCampaignId(undefined);
    }
  },[loading, error, campaignId, setCampaignId, response.status]);

  return data;
};
