import React from 'react';

import {styles} from "./contribution-dashboard.styles";
import {RoiDashboard} from "./roi-dashboard";
import {DailyContributionDashboard} from "./daily-contribution-dashboard";
import {useContributionDashboard} from "./hooks/useContribibutionsDashboard";

export const ContributionDashboard = ({
                                          mcaRuns,
                                          selectedMcaRun,
                                          nestedRunIds = []
                                      }) => {

    const titlePrefix = nestedRunIds.length ? 'Combined ' : '';

    const {id, kpiType, includeIntercept} = selectedMcaRun;

    const {
        roiGraphData,
        contributionsOverTimeGraphData,
        isLoading
    } = useContributionDashboard({
        mcaRuns,
        nestedRunIds,
        id,
        includeIntercept,
        kpiType
    });

    return (
        <div>
            <div className={styles.container}>
                <RoiDashboard
                    titlePrefix={titlePrefix}
                    roiChartData={roiGraphData}
                    isRevenue={kpiType === 'Revenue'}
                    loading={isLoading}
                />
                <DailyContributionDashboard
                    isCombined={nestedRunIds.length}
                    dailyResults={contributionsOverTimeGraphData}
                    isRevenue={kpiType === 'Revenue'}
                    loading={isLoading}
                />
            </div>
        </div>
    );
};