import {call, put, takeEvery} from 'redux-saga/effects';
import {downloadFromURL} from '../../../js/download';
import {urlBuilders} from '../../../js/constants.js';

import {
    FETCH_DATA_REPORT,
    FETCH_REPORT_URL,
    FETCH_OUTPUTS_INSIGHTS_DATA,
    fetchOutputsInsightsContributionsRequest,
    fetchOutputsInsightsDailyContributionsRequest,
    fetchOutputsInsightsFitRequest,
    fetchOutputsInsightsThresholdsRequest,
    fetchOutputsInsightsOptimalAllocationRequest,
    fetchOutputsInsightsEfficiencyRequest,
    fetchOutputsInsightsSaturationRequest,
} from '../outputs-insights.js';

import {snackbar} from '../../platform/snackbar.js';

import {getData, post} from '../../../js/server.js';

export function* watchFetchDataReport() {
    yield takeEvery(FETCH_DATA_REPORT, fetchDataReport);
}

/**
 * gets the csv behind a visualization for download
 * @param  {object}    action the instigating action
 */
export function* fetchDataReport(action) {
    const {mcaRunId, type} = action.params;
    const fileLocationURL = urlBuilders.getOutputsInsightsDataByType(
        mcaRunId,
        type
    );
    const fileLocation = yield call(
        post,
        action.token,
        action.baseUrl + fileLocationURL,
        action.payload
    );
    if (fileLocation.error) {
        yield put(
            snackbar(
                'error',
                'ERROR: \n Error while getting ' +
                    action.params.type +
                    ' for run with id: ' +
                    action.params.mcaRunId +
                    '. \n' +
                    fileLocation.message
            )
        );
    } else {
        downloadFromURL(fileLocation.body.url);
    }
}

/**
 * gets the  url for the csv behind a visualization for download
 * @param  {object}    action the instigating action
 */
export function* fetchReportUrl(action) {
    const fileLocationURL = urlBuilders.getOutputsInsightsUrl(
        action.params.id,
        action.params.fileType
    );
    const fileLocation = yield call(
        post,
        action.token,
        action.baseUrl + fileLocationURL,
        action.payload
    );
    if (fileLocation.error) {
        yield put(
            snackbar(
                'error',
                'ERROR: \n Error while getting ' +
                    action.params.fileType +
                    ' for run with id: ' +
                    action.params.id +
                    '. \n' +
                    fileLocation.message
            )
        );
    } else {
        downloadFromURL(fileLocation.body.url);
    }
}
export function* watchFetchReportUrl() {
    yield takeEvery(FETCH_REPORT_URL, fetchReportUrl);
}

export function* fetchModelEfficiency(action) {
    yield fetchOutputInsightFile(
        action,
        action.baseUrl +
            urlBuilders.getFinalModelEfficiencyLocation(action.params.mcaRunId),
        'Flighted Efficiency'
    );
}

export function* fetchOutputInsightFile(
    action,
    outputFileUrl,
    outputFileType,
    fileName = ''
) {
    const fileLocation = yield call(getData, action.token, outputFileUrl);
    if (fileLocation.error) {
        yield put(
            snackbar(
                'error',
                'ERROR: \n Error while executing ' +
                    outputFileType +
                    ' for run with id: ' +
                    action.params.mcaRunId +
                    '. \n' +
                    fileLocation.message
            )
        );
    } else {
        const fileUrl = yield call(
            getData,
            action.token,
            action.baseUrl +
                urlBuilders.getFileUrl(
                    fileLocation.bucket,
                    fileLocation.key,
                    fileName
                )
        );

        if (fileUrl.error) {
            yield put(
                snackbar(
                    'error',
                    'ERROR: \n Error while downloading ' +
                        outputFileType +
                        ' for run with id: ' +
                        action.params.mcaRunId +
                        '. \n' +
                        fileUrl.message
                )
            );
        } else {
            downloadFromURL(fileUrl.url);
        }
    }
}

/**
 * initiates calls to fetch each of the different outputs and insights
 * @param  {object}    action the instigating action
 */
export function* fetchAllOutputsInsightsData(action) {
    yield put(
        fetchOutputsInsightsFitRequest(
            action.params.runId,
            action.token,
            action.baseUrl
        )
    );
    yield put(
        fetchOutputsInsightsThresholdsRequest(
            action.params.runId,
            action.token,
            action.baseUrl
        )
    );
    yield put(
        fetchOutputsInsightsOptimalAllocationRequest(
            action.params.runId,
            action.token,
            action.baseUrl
        )
    );
    yield put(
        fetchOutputsInsightsEfficiencyRequest(
            action.params.runId,
            action.token,
            action.baseUrl
        )
    );
    yield put(
        fetchOutputsInsightsSaturationRequest(
            action.params.runId,
            action.token,
            action.baseUrl
        )
    );
    yield put(
        fetchOutputsInsightsContributionsRequest(
            action.params.runId,
            action.token,
            action.baseUrl
        )
    );
    yield put(
        fetchOutputsInsightsDailyContributionsRequest(
            action.params.runId,
            action.token,
            action.baseUrl
        )
    );
}

export function* watchFetchOutputsInsightsData() {
    yield takeEvery(FETCH_OUTPUTS_INSIGHTS_DATA, fetchAllOutputsInsightsData);
}
