import React from 'react';
import {customStyles, customTheme, styles} from './SelectCallToAction.styles';
import {SelectLV} from '../form/select/SelectLV';
import PropTypes from 'prop-types';

function SelectCallToAction({name='selected-analysis', ...props}) {
  const MAX_PLACEHOLDER_CHARS = 30;
  let placeholder = props.placeholder || '';
  if(placeholder.length > MAX_PLACEHOLDER_CHARS) {
    placeholder = props.placeholder.substring(0, MAX_PLACEHOLDER_CHARS) + '...';    
  }

  const classNames = [props.className, styles.base].join(' ');

  return(
    <SelectLV
      {...props}
      className={classNames}
      name={name}
      theme={customTheme}
      styles={customStyles()}
      isClearable={false}
      placeholder={placeholder}
    />
  );
}

export {SelectCallToAction};

SelectCallToAction.propTypes = {
  /**
   * Field name to be reflected in form object
   */
  name: PropTypes.string,
  /**
   * Label to display for the text input element
   */
  label: PropTypes.string,
  /**
   * Description to display below element
   */
  description: PropTypes.string,
  /**
   * Array of all the selectable values
   */
  options: PropTypes.array,
  /**
   * Placeholder text
   */
  placeholder: PropTypes.string,
  /**
   * Disables the field and shows default indicator of 'Loading' status
   */
  isLoading: PropTypes.bool,
  /**
   * React Hook Form integration
   * https://react-hook-form.com/api/#Controller
   */
  control: PropTypes.object,
  /**
   * Registration to form, and validation of form element
   * https://react-hook-form.com/api/#Controller
   */
  rules: PropTypes.object,
  /**
   * All form errors from parent form
   * https://react-hook-form.com/api#errors
   */
  errors: PropTypes.object,
  /**
   * Override standard width of element
   * Clears width css prop if width = 0
   */
  width: PropTypes.number
};
