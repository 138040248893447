import React from 'react';
import { useFeatureSelect } from '../../hooks/useFeatureSelect';
import { FEATURES } from '../../constants/features';
import { FeatureLV } from '../../components/pages/FeatureLV';
import { R1Button } from '@rainagency/rain-one-soggy-loaf';
import {useGet} from '../../hooks/useRest';
import {urlBuilders} from '../../js/constants';
import {styles} from './AdImpactTab.styles';
import { useCampaignManagement } from '../../hooks/useCampaignManagement';
import {AdImpactTable} from "./table/AdImpactTable";

export const AdImpactTab = () => {
  const { campaignId } = useCampaignManagement();

  const [{
    data: adImpactData = [],
    loading
  }, refetchAdImpactRuns = () => {}] = useGet({
    url: urlBuilders.getWavecastRunsByCampaignId(campaignId)
  });

  return(
    <FeatureLV
      title='AdImpact Analysis Runs'
      description='Select or create an analysis'
      CallToAction={
        <SelectAnalysis />
      }
    >
      <div data-test="ad-impact-table-container"
           className={styles.container}>
        <AdImpactTable
          data={adImpactData}
          loading={loading}
          fetchAdImpacts={refetchAdImpactRuns}
        />
      </div>
    </FeatureLV>
  );
};

const SelectAnalysis = () => {
  const { onFeatureSelect } = useFeatureSelect();

  const clickHandler = () => {
    onFeatureSelect({
      feature: FEATURES.SELECT_ANALYSIS,
      analysisStartType: 'AdImpact MMM'
    });
  };

  return (
    <R1Button
      onClick={clickHandler}
      color='secondary'>
      New Analysis
    </R1Button>
  );
}