import React from 'react';
import {
  R1ActionIcon
} from '@rainagency/rain-one-soggy-loaf';
import {usePost, useGet} from '../../../../hooks/useRest';
import { urlBuilders } from '../../../../js/constants';
import { downloadFromURL } from '../../../../js/download';
import DownloadCSVFactory from '../../../../js/download-csv-factory';

const MODEL_CONFIG_FILE_NAME = 'model_configuration.csv';

/**
 * Displays action icon for MMM data grid
 * Download configuration for the final model
 */
export const DownloadModelConfigurationIcon = ({
  cell,
  closePopover,
  hide
}) => {
  const {id} = cell.row;
  const [generateFile, setGenerateFile] = React.useState(false);

  const  [{data, error, loading}, fetchModelConfigsFile] = usePost({
    url: urlBuilders.postMcaRunFileUrl(id),
    data: {fileName: MODEL_CONFIG_FILE_NAME},
    manual: true
  });

  React.useEffect(() => {
    //trigger download
    if (data?.url) {
      downloadFromURL(data.url);
      closePopover();
    }
  }, [data]);

  //old runs compatability
  const  [{data: mediaGroupingData=[], loading: mediaGroupingLoading}, fetchMediaGroupingData] = useGet({
    url: urlBuilders.getMediaGroupingConfigs(undefined, id),
    manual: true
  });
  const  [{data: finalModelData=[], loading: finalModelLoading}, fetchFinalModelgData] = useGet({
    url: urlBuilders.getSelectedModel(id),
    manual: true
  });

  React.useEffect(() => {
    if (error) {
      setGenerateFile(true);
    }
  }, [error]);
  
  React.useEffect(() => {
    if (generateFile) {
      fetchMediaGroupingData();
      fetchFinalModelgData();
    }
  }, [generateFile]);

  if (!!mediaGroupingData.length && !!finalModelData.length && generateFile) {
    const variables = finalModelData.map(variable => variable.variablename);
    let filteredMediaGrouping = mediaGroupingData.filter(variable => variables.includes(variable.VariableName));
    filteredMediaGrouping.forEach(variable => {
      const matchedVariable = finalModelData.find(model => model.variablename === variable.VariableName);
      variable.MemoryDecay = matchedVariable.variabledecay;
      variable.Saturation = matchedVariable.variablesaturation;
    });
    //generate csv and trigger download
    DownloadCSVFactory.setReportProperties();
    DownloadCSVFactory.createDownload('model_configuration', filteredMediaGrouping, MODEL_CONFIG_FILE_NAME);
    const report = DownloadCSVFactory.getDownload('model_configuration');
    report.download();
    closePopover();
    setGenerateFile(false);
  }

  const handleClick = () => {
    fetchModelConfigsFile();
  };

  const isLoading = loading || mediaGroupingLoading || finalModelLoading;
  return (
    <R1ActionIcon
      iconType='Download'
      label='Final Model Configuration'
      hide={hide}
      onClick={handleClick} 
      customStyles={{
        cursor: isLoading ? 'wait' : 'pointer'}}
    />
  );

};
