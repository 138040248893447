import React, {Component} from 'react';
import App from './App';
import Amplify, {I18n} from 'aws-amplify';
import {SignIn, Greetings, ForgotPassword, Authenticator} from 'aws-amplify-react';
import {CustomSignIn} from './signin.jsx';
import ResetPassword from './resetPassword.jsx';
import {Config} from './config/ConfigFactory';

class AppWithAuth extends Component {
  render() {

    // Load Config
    Config.getConfig();

    const store = this.props.store;
    I18n.putVocabularies({
        en: {
            'Custom auth lambda trigger is not configured for the user pool.': 'Password cannot be empty',
        }
    })

    return (
      <Authenticator
        style={{height: '100%'}}
        hide={[SignIn, Greetings, ForgotPassword]}
        amplifyConfig={Amplify.configure({
          Auth: {
            region: store.getState().platform.config.REGION,
            userPoolId: store.getState().platform.config
              .USER_POOL_ID,
            userPoolWebClientId: store.getState().platform
              .config.USER_POOL_WEBCLIENT_ID,
            identityPoolId: store.getState().platform.config
              .IDENTITY_POOL_ID
          },
          Storage: {
            bucket: store.getState().platform.config
              .UPLOAD_BUCKET,
            region: store.getState().platform.config.REGION
          }
        })}>
        <CustomSignIn />
        <ResetPassword store={store}/>
        <App store={store} />
      </Authenticator>
    );
  }
}

export default AppWithAuth;
