import React, {useEffect} from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import {Config} from '../../../../config/ConfigFactory';
import {useForm} from 'react-hook-form';
import PropTypes from 'prop-types';
import moment from 'moment';

import {TitleLV} from '../../../../components/form/title/TitleLV';
import {FormRowLV} from '../../../../components/form/row/FormRowLV';
import {OrangeButtonLV} from '../../../../components/form/buttons/OrangeButtonLV';
import {ButtonLV} from '../../../../components/form/buttons/ButtonLV';
import {IconButtonLV} from '../../../../components/form/buttons/IconButtonLV';
import {SelectLV, mapToOptions} from '../../../../components/form/select/SelectLV';
import {TextInputLV} from '../../../../components/form/text/TextInputLV';
import {DateRangePickerLV} from '../../../../components/form/date/DateRangePickerLV';
import {CheckboxLV} from '../../../../components/form/CheckboxLV';
import Loading from '../../../../components/loading';
import RadioGroup from '@mui/material/RadioGroup';
import {RadioButtonLV} from '../../../../components/form/RadioButtonLV';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';

import {useGet, urlWithDomain} from '../../../../hooks/useRest';
import {numberWithCommas, arrayContainsAll} from '../../../../js/utils';
import {urlBuilders} from '../../../../js/constants';
import {downloadFromURL} from '../../../../js/download';
import {useMcaRuns} from '../../../../hooks/useMcaRuns';
import {useCampaignManagement} from '../../../../hooks/useCampaignManagement';
import {useUserRoleFromGroup} from '../../../../hooks/useUserRoleFromGroup';
import {useUploadedDateRange} from './hooks/useUploadedDateRange';
import {useGetFileJson} from '../../../../hooks/useUploadedFileJson'

import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

import MediaGroupingTable from './media-grouping-table';

import {styles} from '../start-new-modal.styles';
import {styles as buttonStyles} from '../../../../components/form/buttons/button.styles';

const DEFAULT_VALUES = new Map([
    ['VariableName', ''], 
    ['VariableGroup', ''], 
    ['Measure', ''], 
    ['Analyze', 'Yes'], 
    ['Data Grouping Required', 'No'], 
    ['MemoryDecay', 'No'], 
    ['Saturation', 'No']
]);
const MEMORY_DECAYS = ['10', '20', '30', '40', '50', '60', '70', '80', '90'];
const SATURATIONS = ['OPTIMAL', 'BUILD', 'SATURATED'];
const MEASURES = ['Spend', 'Impressions', 'Control', 'Ratings', 'Binary', 'Clicks'];
const YES_NO = ['Yes', 'No'];

export default function StartNewMcaForm({
        mcaRunId,
        value={
          includeIntercept: true,
          timeScale: 'DAILY'
        },
        modelCount,
        readOnly=true,
        isWaiting=false,
        handleCancel, 
        submitForm,
        isConfigAIRun=false,
        setIsConfigAIRun=()=>{},
        configAIId,
        configAIRunStatus,
        closePopover=()=>{}
    }) {
    const {handleSubmit, formState, register, errors, control, setValue} = useForm();
    const {dirty, isSubmitting} = formState;

    const {campaignId} = useCampaignManagement();
    const {mcaRuns=[]} = useMcaRuns();
    const {isTrial} = useUserRoleFromGroup();

    // Close popover on Modal exit
    React.useEffect(() => {
        return () => {
            closePopover();
        };
    },[]);

    // Date Ranges
    const {
        start: minDateRange,
        end: maxDateRange,
        isLoading: isDateRangeLoading,
        formattedStart,
        formattedEnd,
    } = useUploadedDateRange();
    const startDateDescription = formattedStart && !readOnly ? `From (Min date: ${formattedStart})` : 'From';
    const endDateDescription = formattedEnd && !readOnly ? `To (Max date: ${formattedEnd})` : 'To';

    const mcaRunNames = mcaRuns.map(run => run.name);

    const formTitle = mcaRunId ? 'Analysis Configuration' : 'New Analysis';
    const modelLimit = React.useRef();
    React.useEffect(() => {
        if (Config && Config.getConfig) {
            modelLimit.current = Config.getConfig().MAX_RUN_MODELS;
            if (isTrial) {
                modelLimit.current = Config.getConfig().MAX_TRIAL_RUN_MODELS;
            }
        }
    }, [isTrial]);

    const uploadedTypes = React.useRef([]);
    const parsedMediaGrouping = React.useRef([]);
    const [mediaGroupingErrors, setMediaGroupingErrors] = React.useState([]);
    const [uploadedFileError, setUploadedFileError] = React.useState();
    const [expectedCount, setExpectedCount] = React.useState(modelCount);
    const [formHasSubmitted, setFormHasSubmitted] = React.useState(false);

    const [includeIntercept, setIncludeIntercept] = React.useState(value.includeIntercept);

    const [timeScale, setTimeScale] = React.useState(value.timeScale);

    const [groupingFileInfo, setGroupingFileInfo] = React.useState({bucket:'', location:''});

    const [{data: downloadGrouping={url: undefined}}, fetchDownloadGrouping] = 
        useGet({
        url: urlWithDomain(urlBuilders.getFileUrl(groupingFileInfo.bucket, groupingFileInfo.location)),
        manual: true
    });

    const originalGroupingConfigUrl = configAIId ? `MCA/campaign-data-set/${campaignId}/configAI/${configAIId}/media_grouping.csv` : '';
    const {fileErrors: originalGroupingErrors, fileJson: originalGroupingConfig} = useGetFileJson({
        bucket: groupingFileInfo.bucket,
        location: originalGroupingConfigUrl
    });

    const suggestedConfigMap = React.useMemo(() => {
        if(!originalGroupingErrors && campaignId && configAIId){
            return getOriginalGroupConfigMap(originalGroupingConfig);
        }
    }, [campaignId, configAIId, originalGroupingConfig]);

    useDeepCompareEffect(() => {
        if (downloadGrouping.url) {
            downloadFromURL(downloadGrouping.url); //transformation file
        } 
        },[downloadGrouping]);

    //GET uploaded files for the given Campaign ID
    const [{data: campaignUploadedFiles={data:[]}, loading: campaignUploadedFilesLoading}] = useGet({
        url: urlBuilders.getUploadedCampaignFilesMetaData(campaignId)
    });

    const disableMediaGroupingButton = campaignUploadedFilesLoading || groupingFileInfo.location === '';

    React.useEffect(() => {
        const acceptedUploadStatus = ['SUCCESS', 'PENDING', 'WARNING'];
        uploadedTypes.current = campaignUploadedFiles.data.map(uploadedFile => {
            return  acceptedUploadStatus.includes(uploadedFile.file_status) ? uploadedFile.data_type : null;
        }).filter(item => item);
        const requiredTypes = ['kpi', 'mediaVehicle'];
        if (!arrayContainsAll(uploadedTypes.current, requiredTypes) && !readOnly) {
            setUploadedFileError('Something went wrong. Please verify you have uploaded the correct files.');
        } else {
            let mediaGroupingFileInfo = campaignUploadedFiles.data.filter(
                uploadedFile => uploadedFile.data_type === 'mediaGrouping');
            if (readOnly && mediaGroupingFileInfo.length > 0) {
                const mcaRunConfigFileLocation = {
                    location: `MCA/run-data-set/${mcaRunId}/media_grouping.csv`,
                    bucket: mediaGroupingFileInfo[0].bucket
                };
                mediaGroupingFileInfo = [mcaRunConfigFileLocation];
            }
            if (mediaGroupingFileInfo.length > 0) {
                setGroupingFileInfo(mediaGroupingFileInfo[0]);
            } 
            setUploadedFileError(undefined);
        }
    }, [campaignUploadedFiles.data, campaignUploadedFilesLoading, readOnly, mcaRunId]);
    
    //getting kpis variables and variable types

    const [{data: kpis=[], loading: kpisLoading, error: kpisErrors=[]}, fetchKpis] = useGet({
        url: urlBuilders.getKpisForCampaignId(campaignId),
        manual: true
    });
    
    const kpiOptions = () => {
        return kpis.map((kpi) => ({value: kpi, label: kpi}));
    };

    const KPI_TYPE_OPTIONS = [
        {value: 'Revenue', label: 'Revenue'},
        {value: 'Units', label: 'Units'},
    ];

    //Call to get mediaGrouping by runId or campaignId
    const [{data: mediaGrouping=[], loading: mediaGroupingLoading}, fetchMediaGrouping] = useGet({
      url: urlBuilders.getMediaGroupingConfigs(campaignId, mcaRunId, configAIId),
        manual: true
    });

    React.useEffect(() => {
        if (!uploadedFileError && !configAIId) {
            fetchKpis();
            fetchMediaGrouping();
        }
    }, [uploadedFileError, fetchKpis, fetchMediaGrouping, configAIId]);

    React.useEffect(() => {
        if(!configAIRunStatus){
            return;
        }
        if(configAIId && configAIRunStatus === 'Success' && !isWaiting){
            fetchKpis();
            fetchMediaGrouping();
        }
    }, [fetchMediaGrouping, configAIId, configAIRunStatus, isWaiting]);

    const groupingTableErrors = React.useCallback((erroredGroups, mediaErrors) => {
        // Add a validation for requiring more than one variable that's part of a group
        //all fields are required and cannot exceed max field length
        parsedMediaGrouping.current.forEach((variable, index) => {
            let variableErrors = {};
            Object.entries(variable).forEach(([name, value]) => {
                if (erroredGroups.includes(variable['VariableGroup'])){
                    if (variable['Analyze'] === 'Yes'){
                        variableErrors = Object.assign(variableErrors,
                          {'Data Grouping Required': {'message': 'Mismatched group size'}});
                    }
                }
            });
            mediaErrors[index] = variableErrors;
        });
        return mediaErrors;
    }, []);

    const validateMediaGrouping =  React.useCallback(() => {
        // Add a validation for requiring more than one variable that's part of a group
        //all fields are required and cannot exceed max field length
        let mediaErrors = [];
        let variableGroups = {};
        let analyzeGroups = {};
        let erroredGroups = [];
        parsedMediaGrouping.current.forEach(variable => {
            let variableErrors = {};
            Object.entries(variable).forEach(([name, value]) => {
                if (variable['VariableGroup'] in variableGroups){
                    if (variable['Analyze'] === 'Yes'){
                        if (name === 'Data Grouping Required' && value === 'Yes'){
                            analyzeGroups[variable['VariableGroup']] += 1;
                            variableGroups[variable['VariableGroup']] += 1;
                        }
                        if (name === 'Data Grouping Required' && value === 'No'){
                            analyzeGroups[variable['VariableGroup']] += 1;
                        }
                    }
                }
                else{
                    analyzeGroups[variable['VariableGroup']] = 0;
                    variableGroups[variable['VariableGroup']] = 0;
                }
                if (variableGroups[variable['VariableGroup']] < analyzeGroups[variable['VariableGroup']] &&
                    variableGroups[variable['VariableGroup']] !== 0){
                    erroredGroups.push(variable['VariableGroup']);
                }
                if (!value) {
                    variableErrors = Object.assign(variableErrors, {[name]: {'message': 'Required'}});
                }
            });
            mediaErrors.push(variableErrors);
        });
        if(erroredGroups.length > 0){
            mediaErrors = groupingTableErrors(erroredGroups, mediaErrors);
        }
        return mediaErrors;
    }, [groupingTableErrors]);

    /* Calculating expected model count when not provided */
    const expectedModelCount = React.useCallback(() => {
        //Validate media grouping and update
        if (!parsedMediaGrouping.current.length) {
            return;
        }
        
        const mediaErrors = validateMediaGrouping();
        setMediaGroupingErrors(mediaErrors);
        if (mediaErrors.filter(error => Object.keys(error).length !== 0).length > 0) {
            setExpectedCount(null);
            return;
        }
    
        const analyzed = parsedMediaGrouping.current.filter(item => item['Analyze'] === 'Yes');
        const recommend = analyzed.some(item => item['MemoryDecay'] === '?' || item['Saturation'] === '?');
        setIsConfigAIRun(recommend);
        let groups = new Map();
        let anyRequired = false;
        analyzed.forEach(item => {
            const currentValue = groups.get(item['VariableGroup']) || {count: 0, required: 0};
            const isRequired = !item['Data Grouping Required'] || item['Data Grouping Required'] === 'Yes' ? 0 : 1;
            let memoryDecayCount;
            if(item['MemoryDecay'] === 'All'){
                memoryDecayCount = MEMORY_DECAYS.length;
            }
            else if(item['MemoryDecay'] === '?'){
                memoryDecayCount = 3;
            }
            else{
                memoryDecayCount = item['MemoryDecay'].split('/').length || 1;
            }
            const saturationCount = (item['Saturation'] === 'All' || item['Saturation'] === '?') ? SATURATIONS.length : (item['Saturation'].split('/').length || 1);
            const groupCount = item['VariableGroup'].length ? currentValue.count + memoryDecayCount * saturationCount : 1;
            anyRequired = anyRequired || isRequired === 0;
            groups.set(item['VariableGroup'], {count: groupCount, required: currentValue.required || isRequired});
        });
        let modelCount = 1;
        for (let value of groups.values()) {
            modelCount *= value.count + value.required;
        }

        if (!anyRequired) {
            modelCount--;
        }

        setExpectedCount(modelCount);
    }, [validateMediaGrouping]);

    /* Setup initial values for media grouping configs */
    React.useEffect(() => {
        const mediaGroupingItems = [];
        mediaGrouping.forEach(item => {
            if (item['VariableName'].trim().length) {
                //format matched headers and remove unwanted ones
                const requiredHeaders = Array.from(DEFAULT_VALUES.keys());
                const lHeaders = requiredHeaders.toString().toLowerCase().split(',');
                let parsedItem = {};
                Object.keys(item).forEach(key => {
                    const headerIndex = lHeaders.indexOf(key.trim().toLowerCase());
                    if (headerIndex > -1) {
                        let value = !item[key] ? DEFAULT_VALUES.get(requiredHeaders[headerIndex]) : item[key];
                        value = requiredHeaders[headerIndex] === 'VariableGroup' || requiredHeaders[headerIndex] === 'VariableName'?
                            value : value.charAt(0).toUpperCase() + value.substr(1).toLowerCase();
                        Object.assign(parsedItem, {[requiredHeaders[headerIndex]]: value});
                    }
                });

                //add missing headers with default values
                requiredHeaders.forEach(header => {
                    if (Object.keys(parsedItem).indexOf(header) < 0 || !parsedItem[header]) {
                        parsedItem = Object.assign(parsedItem, {[header]: DEFAULT_VALUES.get(header)});
                    }
                });

                //validate values
                parsedItem['Analyze'] = !parsedItem['Analyze'] || !YES_NO.includes(parsedItem['Analyze']) ? DEFAULT_VALUES.get('Analyze') : parsedItem['Analyze'];

                parsedItem['Data Grouping Required'] = !parsedItem['Data Grouping Required'] || 
                    !YES_NO.includes(parsedItem['Data Grouping Required']) ? DEFAULT_VALUES.get('Data Grouping Required') : parsedItem['Data Grouping Required'];
                
                parsedItem['Measure'] = !parsedItem['Measure'] || !MEASURES.includes(parsedItem['Measure']) ? DEFAULT_VALUES.get('Measure') : parsedItem['Measure'];

                const decayValues = !parsedItem['MemoryDecay'] ? DEFAULT_VALUES.get('MemoryDecay') : 
                    parsedItem['MemoryDecay'].split('/').filter(decay => MEMORY_DECAYS.includes(decay)).join('/');
                parsedItem['MemoryDecay'] = !decayValues.length ? DEFAULT_VALUES.get('MemoryDecay') : decayValues;

                const saturationValues = !parsedItem['Saturation'] ? DEFAULT_VALUES.get('Saturation') : 
                    parsedItem['Saturation'].split('/').filter(saturation => SATURATIONS.includes(saturation.toUpperCase())).join('/');
                parsedItem['Saturation'] = !saturationValues.length ? DEFAULT_VALUES.get('Saturation') : saturationValues.toUpperCase();
                
                mediaGroupingItems.push(parsedItem);
            }
        });
        parsedMediaGrouping.current = mediaGroupingItems.slice();
        if (!modelCount) {
            expectedModelCount();
        }
    }, [mediaGrouping, expectedModelCount, modelCount]);

    /* Media Grouping update and validations */
    const onRequiredGroupingUpdate = ({variableGroup, name, value}) => {
        parsedMediaGrouping.current.forEach((variable, index) => {
            if (variable['Analyze'] === 'Yes' && variable['VariableGroup'] === variableGroup){
                parsedMediaGrouping.current[index] = {...variable, [name]: value};
            }
        });
        expectedModelCount();
    };

    /* Media Grouping update and validations */
    const onMediaGroupingUpdate = ({variableName, name, value}) => {
        const mediaGroupingItem = parsedMediaGrouping.current.find(item => item['VariableName'] === variableName);
        const mediaGroupingIndex = parsedMediaGrouping.current.indexOf(mediaGroupingItem);
        if (mediaGroupingIndex > -1) {
            parsedMediaGrouping.current[mediaGroupingIndex] = Object.assign(mediaGroupingItem, {[name] : value});
            const mediaErrors = mediaGroupingErrors.slice();
            if (mediaErrors[mediaGroupingIndex]) {
                delete mediaErrors[mediaGroupingIndex][name];
            }
            expectedModelCount();
        }
    };

    const modelsSuffix = mcaRunId ? '' : 'will be executed.';
    const expectedModelString = expectedCount || expectedCount === 0 ? `${numberWithCommas(expectedCount)} models ${modelsSuffix}` : null;
    const configurationTable = uploadedFileError ? null :
        (<div>
            <FormRowLV>
                <div className={styles.configurationTitle}>Configuration</div>
                <IconButtonLV
                    onClick={fetchDownloadGrouping}
                    hidden={!readOnly}
                    isDisabled={disableMediaGroupingButton}
                    dataTest={'download-grouping-button'}>
                    <DescriptionOutlinedIcon 
                    	style={!readOnly ? {display: 'none'} : null} 
                    	htmlColor={'#fff'} 
                    	titleAccess={'Download Configuration File'}
                    />
                </IconButtonLV>
            </FormRowLV>
            <Loading name='loading' loading={mediaGroupingLoading || configAIRunStatus === 'Running'}>
                <div>
                    <div className={styles.configurationTableContainer} data-test='newMcaForm-configTable'>
                        <MediaGroupingTable data={parsedMediaGrouping.current} 
                            onMediaGroupingUpdate={(values) => onMediaGroupingUpdate(values)}
                            onRequiredGroupingUpdate={(values) => onRequiredGroupingUpdate(values)}
                            mediaGroupingErrors={mediaGroupingErrors}
                            readOnly={readOnly}
                            suggestedConfigMap={suggestedConfigMap}
                            disabled={isSubmitting}/>
                    </div>
                    <div className={styles.modelCount} data-test='newMcaForm-expectedModel'>
                        {expectedModelString}
                    </div>
                </div>
            </Loading>
        </div>);

    /* Form handling for checkbox and submit */
    const handleChecked = (event, value) => {
        setIncludeIntercept(event.target.checked);
    };

    const formSubmit = (formData) => {
        if(formHasSubmitted) {
            return;
        }

        // Only allow this form to submit a MCA run once, but allow multiple configAI runs
        if(!isConfigAIRun){
            setFormHasSubmitted(true);
        }

        submitForm({
            "runName" : formData.name,
            "kpi": formData.kpi.value,
            "kpiType": formData.kpiType.value,
            "startDate": moment(formData.startDate).format('YYYY-MM-DD').toString(),
            "endDate": moment(formData.endDate).format('YYYY-MM-DD').toString(),
            includeIntercept,
            "timeScale": timeScale,
            expectedCount,
            mediaGrouping: parsedMediaGrouping.current,
            "mcaRunId": mcaRunId,
            isTrial
        });
    };

    const runType = isConfigAIRun ? 'SUGGEST WITH ConfigAI' : 'Run';
    return (
        <div className={isSubmitting || isWaiting ? `${styles.typeBoxBig} ${styles.waiting}` : styles.typeBoxBig}
        data-test={'newAnalysis-Modal'}>
            <form
              className={styles.form}
              onSubmit={handleSubmit(formSubmit)}>
                <TitleLV>{formTitle}</TitleLV>
                <FormRowLV>
                    <TextInputLV
                        name='name'
                        placeholder={'Analysis Name'}
                        dataTest={'analysisName-TextField'}
                        register={
                            register({ 
                                required: 'Analysis Name is required.',
                                maxLength : {
                                  value: 250,
                                  message: 'Cannot exceed 250 characters'
                                },
                                validate: value => !mcaRunNames.includes(value) ||
                                    'Analysis name already exists. Please enter a unique analysis name.',
                                pattern: {
                                    value: /^[0-9a-zA-Z _\-.]+$/,
                                    message: 'Valid characters are numbers, letters, dot, dash, and underscore.'
                                }
                            })
                        }
                        width={1120}
                        errors={errors}
                        disabled={readOnly}
                        defaultValue={value.name}
                    />
                </FormRowLV>
                <FormRowLV>
                    <DateRangePickerLV
                        startDescription={startDateDescription}
                        endDescription={endDateDescription}
                        setValue={setValue}
                        width={1120}
                        register={register(
                            { required: 'Date Range Required',
                            validate: (date) => {
                                const minDate = value.startDate || minDateRange;
                                const maxDate = value.endDate || maxDateRange;
                                if(moment(minDate).isAfter(moment(maxDate))){
                                    return 'Date Ranges do not overlap!';
                                }
                                return true;
                            }}
                        )}
                        errors={errors}
                        defaultStartDate={value.startDate || minDateRange}
                        defaultEndDate={value.endDate || maxDateRange}
                        isDisabled={isDateRangeLoading || readOnly}
                        minDate={minDateRange}
                        maxDate={maxDateRange}
                    />
                </FormRowLV>
                <FormRowLV>
                    <SelectLV
                        name='kpi'
                        placeholder='Dependent Variable'
                        options={kpiOptions()}
                        rules={{ required: true }}
                        isDisabled={readOnly}
                        control={control}
                        errors={errors}
                        width={1120}
                        defaultValue={value.kpi ? mapToOptions(value.kpi) : ''}
                    />
                </FormRowLV>
                <FormRowLV>
                  <CheckboxLV 
                    name='includeIntercept'
                    disabled={readOnly}
                    label='Include intercept'
                    checked={includeIntercept || false}
                    onChange={(event, value) => handleChecked(event, value)}
                    data-test='includeIntercept-checkbox'
                    darkTheme={true}
                  />
                </FormRowLV>
                <FormRowLV margin={0}>
                  <FormControlLabel 
                    classes={{label: styles.leftRowLabel}} 
                    control={<FormLabel/>} 
                    label="Data Time Interval" 
                    labelPlacement="end"
                  />
                  <RadioGroup row name='timeScale' value={timeScale} onChange={(event)=>{setTimeScale(event.target.value)}}>
                    <RadioButtonLV 
                      value={'DAILY'}
                      label={'Daily'}
                      checked={timeScale === 'DAILY'}
                      disabled={readOnly}
                    />
                    <RadioButtonLV 
                      value={'WEEKLY'}
                      label={'Weekly'}
                      checked={timeScale === 'WEEKLY'}
                      disabled={readOnly}
                    />
                  </RadioGroup>
                </FormRowLV>
                <FormRowLV>
                    <SelectLV
                        name='kpiType'
                        placeholder='Dependent Variable Type'
                        options={KPI_TYPE_OPTIONS}
                        rules={{ required: true }}
                        isDisabled={readOnly}
                        control={control}
                        errors={errors}
                        width={1120}
                        defaultValue={value.kpiType ? mapToOptions(value.kpiType) : ''}
                    />
                </FormRowLV>  
                <div className={styles.errorMsg}>{campaignUploadedFilesLoading ? null : uploadedFileError}</div>
                {kpisErrors && kpisErrors.length ? kpisErrors : configurationTable}
                <FormRowLV>
                    <OrangeButtonLV
                      className={isConfigAIRun ? [buttonStyles.orange, 'configAIButton'].join(' ') : buttonStyles.orange}
                        submitting={formHasSubmitted || isSubmitting}
                        hidden={readOnly}
                        dirty={typeof dirty != 'boolean' ? false : dirty}
                        isDisabled={
                            formHasSubmitted || isSubmitting || isWaiting || uploadedFileError !== undefined || kpisLoading || expectedCount > modelLimit.current ||
                            mediaGroupingErrors.filter(error => Object.keys(error).length !== 0).length > 0}
                        dataTest={'submit-button'}>
                        {runType}
                    </OrangeButtonLV>

                    <ButtonLV
                        onClick={handleCancel}
                        hidden={readOnly}
                        submitting={isSubmitting}
                        isDisabled={isSubmitting}
                        dataTest={'cancel-button'}>
                        Cancel
                    </ButtonLV>
                </FormRowLV>
                <div className={styles.countErrorMsg}>
                    {expectedCount > modelLimit.current ? `Models cannot exceed ${numberWithCommas(modelLimit.current)}` : null}
                    {configAIRunStatus === 'Error' ? 'Something went wrong with the ConfigAI run. Please contact tech support' : null}
                </div>
            </form>
        </div>
    );
}

const getOriginalGroupConfigMap = (originalConfig=[]) => {
    return originalConfig.reduce((prev, curr) => {
        let rowMap = {};
        const suggestedTrue = Object.keys(curr).filter((key) => {
            return (curr[key] === "?")
        });
        if(!suggestedTrue.length){
            return prev;
        }
        suggestedTrue.forEach((elem)=> {
            rowMap[elem] = true;
        })
        return {
            ...prev,
            [curr.VariableName]: rowMap
        }
    }, {});
}

StartNewMcaForm.propTypes = {
    errorMsg: PropTypes.object,
    submitForm: PropTypes.func,
    handleCancel: PropTypes.func,
    loading: PropTypes.bool
};
