// actions
export const SPINNER = `app/download-files/spinner`;
export const DOWNLOAD_FILES = `app/download-files/download-files`;

// selectors
const ID = 'downloadFiles';
const local = (state) => state['platform'][ID];
export const getSpinner = (state) => local(state).spinner;

export const initialState = {
    spinner: false
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SPINNER:
            return Object.assign({}, state, {spinner: action.show});
        default:
            return state;
    }
}

export function spinner(show) {
    return {
        type: SPINNER,
        show
    };
}
