import React from 'react';
import {
  Typography,
  IconButton
} from '@mui/material';
import { styled } from '@mui/system';
import { KeyboardArrowDown } from '@mui/icons-material';
import {
  R1Button,
  R1Popover
} from '@rainagency/rain-one-soggy-loaf';
import {
  Group,
  Logout,
  Warning,
  MenuBook
} from '@mui/icons-material';
import {
  PRODUCT
} from '../../../constants/features';

export const USER_MENU_ACTIONS = {
  LOGOUT: {
    key: 'LOGOUT',
    label: 'Logout',
    icon: <Logout/>
  },
  USER_MANAGEMENT: {
    key: 'USER_MANAGEMENT',
    label: 'User Management',
    icon: <Group/>
  },
  REPORT_AN_ISSUE: {
    key: 'REPORT_AN_ISSUE',
    label: 'Report an issue',
    icon: <Warning/>
  },
  USER_GUIDE: {
    key: 'USER_GUIDE',
    label: 'User Guide',
    icon: <MenuBook/>
  }
};

export const UserNavigation = ({
  products = {},
  onUserNavigationSelect = () => {},
  ...props
}) => {
  return (
    <R1Popover
      sx={{
        '.MuiPaper-root': {
          marginTop: '1px',
          width: 244
        }
      }}
      anchorVertical='bottom'
      anchorHorizontal='right'
      transformVertical='top'
      transformHorizontal='center'
      ActionComponent={
        <UserNavigationComponent
          {...props}
        />
      }>

      <UserMenuAction
        action={USER_MENU_ACTIONS.USER_MANAGEMENT}
        onUserNavigationSelect={onUserNavigationSelect}
        hide={!products[PRODUCT.USER_MANAGEMENT.key]}
      />

      <UserMenuAction
        action={USER_MENU_ACTIONS.USER_GUIDE}
        onUserNavigationSelect={onUserNavigationSelect}
      />

      <UserMenuAction
        action={USER_MENU_ACTIONS.REPORT_AN_ISSUE}
        onUserNavigationSelect={onUserNavigationSelect}
      />

      <UserMenuAction
        action={USER_MENU_ACTIONS.LOGOUT}
        onUserNavigationSelect={onUserNavigationSelect}
      />

    </R1Popover>
  );
};

const UserNavigationComponent = ({
  email,
  firstName,
  lastName,
  onClick = () => {}
}) => {
  return (
    <UserNavContainerStyled
      onClick={onClick}
      className='lv-user-navigation-container'>

      <UserNavigationStyled className='lv-user-navigation'>

        <Avatar
          firstName={firstName}
          lastName={lastName}
          email={email}
        />

        <NameTag
          firstName={firstName}
          lastName={lastName}
          email={email}
        />

        <ArrowButton />

      </UserNavigationStyled>

    </UserNavContainerStyled>
  );
};

const UserMenuAction = ({
  hide = false,
  action = {},
  closePopover = () => {},
  onUserNavigationSelect = () => {}
}) => {

  if(hide) {
    return null;
  }

  const handleClick = () => {
    onUserNavigationSelect(action);
    closePopover();
  };

  return (
    <R1Button
      onClick={handleClick}
      variant='text'
      aria-label={action.label}
      startIcon={action.icon}
      customStyles={{
        justifyContent: 'flex-start',
        '& .MuiButton-startIcon': {
          marginLeft: 4
        }
      }}>
      {action.label}
    </R1Button>
  );
};

const ArrowButton = ({
  onClick = ()=>{}
}) => {
  return (
    <ArrowButtonStyled>
      <IconButton  aria-label='Expand User Navigation' size='small' onClick={onClick}>
        <KeyboardArrowDown size='small'/>
      </IconButton>
    </ArrowButtonStyled>
  );
};

const Avatar = ({
  firstName = 'L',
  lastName = 'V',
}) => {
  const userInitials = firstName.substring(0, 1) + lastName.substring(0, 1);
  return (
    <AvatarStyled data-testid='avatar-initials'>
      {userInitials}
    </AvatarStyled>
  );
};

const NameTag = ({
  firstName = '',
  lastName = '',
  email = '',
}) => {
  return (
    <NameTagStyled>

      <Typography
        noWrap={true}
        className='name-tag-name'
        data-testid='first-last-tag'
        variant={'alert-title'}>

        {firstName} {lastName}

      </Typography>

      <Typography
        noWrap={true}
        className='name-tag-username'
        data-testid='email-tag'
        variant={'caption'}>

        {email}

      </Typography>

    </NameTagStyled>
  );
};

const UserNavContainerStyled = styled('div')(({
  theme
}) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  height: '100%',
  '&:hover': {
    cursor: 'pointer'
  }
}));

const UserNavigationStyled = styled('div')(({
  theme
}) => ({
  padding: 20,
  display: 'grid',
  gridTemplateColumns: '56px 1fr 36px',
  width: 'inherit',
  borderLeft: '1px solid #E0E0E0',

}));

const ArrowButtonStyled = styled('div')(({
  theme
}) => ({
  textAlign: 'center',
  justifyContent: 'center',
  alignItens: 'center',
  display: 'flex',
  height: 36,
  width: 36,
  marginTop: 4,
  '& svg': {
    color: '#E0E0E0',
  }
}));

const NameTagStyled = styled('div')(({
  theme
}) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: 4,
  maxWidth: 200,
  marginRight: 8,
  '& .name-tag-name': {
    color: theme.palette.text.primary.main,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  '& .name-tag-username': {
    color: theme.palette.text.secondary,
    marginTop: '-2px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }
}));

const AvatarStyled = styled('div')(({
  theme
}) => ({
  background: theme.palette.primary.main,
  border: '3px solid #FFF',
  boxSizing: 'border-box',
  width: 40,
  height: 40,
  borderRadius: 20,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#FFF',
  textTransform: 'uppercase'
}));
