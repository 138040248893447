import {Storage, Auth} from 'aws-amplify';

export function uploadFileToS3(file, filename = undefined) {
    return new Promise((resolve, reject) => {
        Storage.configure({level: 'private'});
        Storage.put(file.name || filename, file)
            .then((uploadResult) => {
                Auth.currentCredentials().then((credentialsResult) =>
                    resolve(
                        `private/${credentialsResult._identityId}/${uploadResult.key}`
                    )
                );
            })
            .catch((error) => resolve({error}));
    });
}
