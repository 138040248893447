import {css} from 'emotion';

export const styles = {
  form: css`
    && div[class *="MuiInputBase-root"] {
      height: 48px;
    }
  `,
  fieldTitle: css`
    margin-top: 44px;
    margin-bottom: -16px;
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
  `,
  fieldLabel: css`
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.6);
  `,
  dateRangePicker: css`
    && div[class *="MuiInputBase-root"] {
      height: 58px !important
    }
    & div[aria-label="r1-date-range-container"] {
      width: 100%
    };
    & div[aria-label="r1-start-date-range"] {
      width: 50%
    };
    & div[aria-label="r1-end-date-range"] {
      width: 50%
    };
  `,
  formRow: css`
    margin-top: -20px;
    margin-bottom: -30px;
  `,
  error: css`
    color: red;
    font-size: 14px;
    padding-top: 20px;
  `,
  configGrid: css`
    height: 900px;
  `
}
