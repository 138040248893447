import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import SnackbarContent from '@mui/material/SnackbarContent';
import Snackbar from '@mui/material/Snackbar';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import {styles} from './snackbar.styles';
import {
    closeSnackbar,
    getOpen,
    getMessage,
    getVariant
} from '../../modules/platform/snackbar.js';

const autoHideDuration = 5000;

const variantIcon = {
    success: CheckCircleIcon,
    error: ErrorIcon,
    info: InfoIcon
};

const snackbarColor = {
  success: 'green',
  error: 'red',
  info: 'black'
};

const SnackbarContainer = ({dispatch, message, open, type, variant='info'}) => {
    const Icon = variantIcon[variant];

    const handleClose = (e) => {
        dispatch(closeSnackbar());
    }
    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            open={open}
            autoHideDuration={autoHideDuration}
            onClose={handleClose}
        >
            <SnackbarContent
                data-test='snackbar'
                style={{backgroundColor:snackbarColor[variant]}}
                message={
                    <span className={styles.message}>
                        <Icon className={styles.icon}/>
                        {message}
                    </span>
                }
            />
        </Snackbar>
    );
}

SnackbarContainer.propTypes = {
    variant: PropTypes.oneOf(['success', 'error', 'info']),
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), //handle network errors
    open: PropTypes.bool
};

const mapStateToProps = state => {
    return {
        variant: getVariant(state),
        message: getMessage(state),
        open: getOpen(state)
    };
};

export default connect(mapStateToProps)(SnackbarContainer);
