import React from 'react';
import {useMcaRuns} from "../../hooks/useMcaRuns";
import {useGet} from "../../hooks/useRest";
import {urlBuilders} from "../../js/constants";
import {useScoringByCampaignId} from "./hooks/useScoringByCampaignId";

const ScoringDashboardContext = React.createContext();

/**
 * Context for Dashboard events/actions
 * Used for variables that must be persisted for all dashboards
 */
function ScoringDashboardProvider({children}) {

    const {selectedMcaRun={}, mcaRuns} = useMcaRuns();
    const {
        selectedScore
    } = useScoringByCampaignId();

    const [selectedScoringRun, setSelectedScoringRun] = React.useState({});
    const [region, setRegion] = React.useState("All");
    const [startDate, setStartDate] = React.useState();
    const [endDate, setEndDate] = React.useState();
    const [isCombined, setIsCombined] = React.useState(false);
    const [nestedRunKpis, setNestedRunKpis] = React.useState([]);
    const [scoringMetadata, setScoringMetadata] = React.useState({});
    const [mcaMetadata, setMcaMetadata] = React.useState({});

    const [scoring2, setScoring2] = React.useState({});
    const [scoring1, setScoring1] = React.useState({});

    // Insight metadata hooks
    const [{
        data: scoringInsightMetadata = {}
    }, fetchScoringInsightMetadata] = useGet({
        manual: true,
        url: urlBuilders.getInsightMetadata(selectedMcaRun?.id, selectedScoringRun?.id)
    });

    const [{
        data: mcaInsightMetadata = {}
    }, fetchMcaInsightMetadata] = useGet({
        manual: true,
        url: urlBuilders.getInsightMetadata(selectedMcaRun?.id)
    });

    React.useEffect(() => {
        if (selectedScore) {
            setSelectedScoringRun(selectedScore);
        }
    }, [selectedScore]);

    // Retrieve Scoring Insight Metadata
    React.useEffect(() => {
        if (selectedMcaRun?.id && selectedScoringRun?.id) {
            fetchScoringInsightMetadata();
        }
    }, [selectedMcaRun?.id, selectedScoringRun?.id]);

    // Retrieve Mca Insight Metadata
    React.useEffect(() => {
        if (selectedMcaRun?.id) {
            fetchMcaInsightMetadata();
        }
    }, [selectedMcaRun?.id]);

    React.useEffect(() => {
        if (scoringInsightMetadata?.scoringId) {
            setStartDate(scoringInsightMetadata.minDate);
            setEndDate(scoringInsightMetadata.maxDate);
            setScoringMetadata(scoringInsightMetadata)
        }
    }, [scoringInsightMetadata?.scoringId]);

    React.useEffect(() => {
        if (mcaInsightMetadata?.runId) {
            setMcaMetadata(mcaInsightMetadata)
        }
    }, [mcaInsightMetadata?.runId]);

    // Reset Scoring when Mca Run Changes
    React.useEffect(() => {
        if (selectedMcaRun?.id) {
            const nestedRuns = mcaRuns?.filter(run => run.parentId === selectedMcaRun.id && run.runStatus === 'FINAL') || [];
            setIsCombined(!!nestedRuns.length);
            setNestedRunKpis(nestedRuns.map(run => run.kpi));

            if (selectedScoringRun.mcaRunId !== selectedMcaRun.id) {
                setSelectedScoringRun({});
            }
        }
    }, [selectedMcaRun?.id]);

    const setRegionWrapper = (region) => {
        if (typeof region === 'string') {
            setRegion(region);
        } else {
            setRegion(region?.value);
        }
    }

    const setScoringRunWrapper = (scoring) => {
        scoring = scoring || {};
        setSelectedScoringRun(scoring);
    }

    return (
        <ScoringDashboardContext.Provider
            value={{
                selectedMcaRun,
                scoringInsightMetadata: scoringMetadata,
                mcaInsightMetadata: mcaMetadata,
                nestedRunKpis,
                setSelectedScoringRun: setScoringRunWrapper,
                selectedScoringRun,
                setScoring2,
                scoring2,
                setScoring1,
                scoring1,
                region,
                setRegion: setRegionWrapper,
                startDate,
                setStartDate,
                endDate,
                setEndDate,
                isCombined
            }}>

            {children}

        </ScoringDashboardContext.Provider>
    );

}

export {ScoringDashboardProvider, ScoringDashboardContext};

