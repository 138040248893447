import {css} from 'emotion';
import downArrow from '../../assets/down-arrow-cta-grey.svg';

export const styles = {
  base: css`
    width: auto !important;
    z-index: 100;
    .LV__value-container {
      height: 36px;
      background: #263542;
      box-shadow: none;
    }
    .LV__single-value {
      color: #828282 !important;
    }
    .LV__value-container:before {
      content: url(${downArrow});
    }
    .LV__option {
      color: white;
    }
    .LV__value-container {
      min-width: 100px;
    }
  `
};
