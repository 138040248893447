import React, { useEffect, useMemo } from 'react';
import moment from 'moment';
import { Box } from '@mui/material';
import { R1Form, R1SubmitButton } from '@rainagency/rain-one-soggy-loaf';
import { useGet } from '../../../../hooks/useRest';
import { useCampaignManagement } from '../../../../hooks/useCampaignManagement';
import { useUploadedDateRange } from '../../../new-analysis/start-new-modal/start-new-form/hooks/useUploadedDateRange';
import { urlBuilders } from '../../../../js/constants';
import { mapToOptions } from '../../../../components/form/select/SelectLV';

import {
  TV_WITH_CUSTOM_OPTION,
  TV_NO_CUSTOM_OPTION,
  DIGITAL_VIDEO_OPTION,
  OTT_OPTION,
  FACEBOOK_OPTION,
  TERRESTRIAL_RADIO_OPTION,
  DIGITAL_AUDIO_OPTION 
} from '../../../../constants/ad-impact-run-types';

export const SelectDataForm = ({
  setRunParameters,
  stepForward = ()=>{}
}) => {    
  
  const { campaignId } = useCampaignManagement();

  //Uploaded files
  const [{data: campaignUploadedFiles={data:[]}, loading: campaignUploadedFilesLoading}, getUploadedCampaignFiles] = useGet({
    url: urlBuilders.getUploadedCampaignFilesMetaData(campaignId),
    manual: true
  });

  useEffect(() => {
    if (campaignId && !campaignUploadedFilesLoading) {
      getUploadedCampaignFiles();
    }
  },[campaignId]);

  const timeFrames = useMemo(() => {
    if(campaignUploadedFiles.data.length) {
      const mvFile = campaignUploadedFiles.data.find(file => file.data_type === 'mediaVehicle');
      const kpiFile = campaignUploadedFiles.data.find(file => file.data_type === 'kpi');
      return {
        mediaVehicle: mvFile?.time_frame,
        kpi: kpiFile?.time_frame
      };
    }
  }, [campaignUploadedFiles.data])

  // Date Ranges
  const {
    start: minDate,
    end: maxDate,
    isLoading: isDateRangeLoading
  } = useUploadedDateRange();

  const handleSubmit = () => {
    setRunParameters({
      adImpactMediaTypeOptions,
      minDate: moment(minDate).utc().format('MM-DD-YYYY'),
      maxDate: moment(maxDate).utc().format('MM-DD-YYYY'),
      timeFrames
    });
    stepForward();
  }

  // AdImpact Media Types
  const createAdImpactOptions = (fileTypes=[]) => {
    let runTypeArray = [];
    if (fileTypes.includes('adInstance') && fileTypes.includes('placementCustom')) {
      runTypeArray.push(TV_WITH_CUSTOM_OPTION);
    } else if (fileTypes.includes('adInstance') && !fileTypes.includes('placementCustom')){
      runTypeArray.push(TV_NO_CUSTOM_OPTION);
    }
    if (fileTypes.includes('digitalVideo')) {
      runTypeArray.push(DIGITAL_VIDEO_OPTION);
    }
    if (fileTypes.includes('ott')) {
      runTypeArray.push(OTT_OPTION);
    }
    if (fileTypes.includes('facebook')) {
      runTypeArray.push(FACEBOOK_OPTION);
    }
    if (fileTypes.includes('terrestrialRadio')) {
      runTypeArray.push(TERRESTRIAL_RADIO_OPTION);
    }
    if (fileTypes.includes('digitalAudio')) {
      runTypeArray.push(DIGITAL_AUDIO_OPTION);
    }
    return mapToOptions(runTypeArray, 'value', 'label');
  }

  const validFiles = campaignUploadedFiles.data.filter(
    file => file.file_status === 'SUCCESS'
  );

  const validFileTypes = validFiles.map(({ data_type }) => data_type);
  const adImpactMediaTypeOptions = createAdImpactOptions(validFileTypes);

  const loading = campaignUploadedFilesLoading || isDateRangeLoading;
  const disabled = !minDate ||!maxDate;

  // return (
  //   <Box sx={{ padding: '24px' }}>
  //     <R1Form onSubmit={handleSubmit} >
  //       <Box className={'button-row'}>
  //         <R1SubmitButton disabled={disabled} loading={loading}>Continue</R1SubmitButton>
  //       </Box>
  //     </R1Form>
  //   </Box>
  // );

  /* Temporary skipping Select-Data step */
  React.useEffect(() => { 
    if (!loading && !disabled) {
      handleSubmit();
    }
  }, [loading, disabled]);
  
  return loading || disabled;
}
