import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {FileInputLV} from '../../../components/form/FileInputLV';
import {TitleLV} from '../../../components/form/title/TitleLV';
import {FormRowLV} from '../../../components/form/row/FormRowLV';
import {OrangeButtonLV} from '../../../components/form/buttons/OrangeButtonLV';
import {ButtonLV} from '../../../components/form/buttons/ButtonLV';
import {SelectLV} from '../../../components/form/select/SelectLV';
import {CheckboxLV} from '../../../components/form/CheckboxLV';
import Spinner from '../../../components/spinner';
import {usePost} from '../../../hooks/useRest'
import {useScoringByCampaignId} from '../../scoring-dashboards/hooks/useScoringByCampaignId';
import {urlBuilders, kpi_filename} from '../../../js/constants';
import downloadIcon from '../../../assets/download_csv_16px.svg';
import {downloadFromURL} from '../../../js/download';

import {FILE_TYPES, FILE_TYPE_MAP, AD_INSTANCE, TERRESTIAL_RADIO} from '../../../constants/file-types';

import {styles} from './file-upload-form.styles';

export const FileUploadForm = ({
    handleCancel, 
    submitForm,
    campaignUploadedFiles,
    isWaiting,
    uploadFileTypes,
    disableWarnings
}) => {
    const {scoresByMcaRunId = []} = useScoringByCampaignId();
    const {handleSubmit, formState, reset, errors, control} = useForm();
    const {isSubmitting} = formState;
    
    const [file, setFile] = React.useState({});
    const [warningTextObj, setWarningTextObj] = React.useState({});
    const SCORING_ERROR_OBJ = {isError: true, 
        value: 'A scoring with the same file name already exists for this run. Please change the name of the uploaded file'};
    const APPEND_WARNING_OBJ = {isAppend: true, value: 'This file will be appended to the previously uploaded file'};
    const OVERWRITE_WARNING_OBJ = {isAppend: false, value: 'This file will overwrite and delete the current file'};
    const WARNING_ARRAY = [APPEND_WARNING_OBJ, OVERWRITE_WARNING_OBJ];
    const fileChange = (event) => {
        setFile(event.target.files[0] || {});
    };

    const [appendCheckboxHidden, setAppendCheckboxHidden] = useState(true);
    const [appendCheckboxValue, setAppendCheckboxValue] = useState(false);
    const [templateDownloadHidden, setTemplateDownloadHidden] = useState(true);
    const [fileType, setFileType] = useState(true);
    const [templateLoading, setTemplateLoading] = useState(false);

    const [scoringKpiCheckboxValue, setScoringKpiCheckboxValue] = useState(false);
    const [kpiFile, setKpiFile] = React.useState({});
    const kpiFileChange = (event) => {
        const files = event.target.files || [];
        setKpiFile(files[0] || {});
    };

    const fileTypeChange = (selected) => {
        setTemplateLoading(false);
        if (!selected) {
            reset({});
            setTemplateDownloadHidden(true);
            setAppendCheckboxHidden(true);
            return;
        }

        setFileType(selected);
        setTemplateDownloadHidden(false);
        setAppendCheckboxHidden(selected.value !== AD_INSTANCE && selected.value !== TERRESTIAL_RADIO);
        if (appendCheckboxHidden) {
            setAppendCheckboxValue(false);
        }

        let uploadWarningObj = selected.value === AD_INSTANCE &&  appendCheckboxValue? 
            APPEND_WARNING_OBJ: 
            OVERWRITE_WARNING_OBJ;

        if(disableWarnings) {
            uploadWarningObj = {};
        }

        const isNewFile = !campaignUploadedFiles.filter(file => file.data_type === selected.value).length;
        setWarningTextObj(isNewFile ? {} : uploadWarningObj);
    };

    const [{data: fileTemplateUrl={url: undefined}}, getTemplateUrl] = usePost({
        url: urlBuilders.postDownloadDataTemplate(),
        data: {
            category: 'TEMPLATE',
            id: fileType.template,
            fileName: fileType.value === 'kpi' ? kpi_filename : undefined
        }
    });

    const onTemplateClick = () => {
        if (fileType.template) {
            setTemplateLoading(true);
            getTemplateUrl();
        }
    };
    
    React.useEffect(() => {
        if (fileTemplateUrl && fileTemplateUrl.url) {
            downloadFromURL(fileTemplateUrl.url);
            setTemplateLoading(false);
        }
    }, [fileTemplateUrl]);

    const handleChecked = (_event, value) => {
        setAppendCheckboxValue(value);
        if ("value" in warningTextObj) {
            setWarningTextObj(WARNING_ARRAY.find((x => x.isAppend === value)));
        }
    };

    const handleScoringKpiChecked = (_event, value) => {
        setScoringKpiCheckboxValue(value);
    };

    const formSubmit = (formData) => {
        submitForm({
            file,
            type: formData.filetype.value,
            appendFlag: appendCheckboxValue,
            kpiFile: scoringKpiCheckboxValue && kpiFile.name ? kpiFile : undefined,
        });
    };

    const waitingStyle = isWaiting ? ` ${styles.waiting}` : '';
    const submitting = isSubmitting || isWaiting;

    // Selected Drop-Down Options
    let fileTypeOptions = FILE_TYPES.filter(type => type.value !== FILE_TYPE_MAP.SCORING_MEDIA_VEHICLE);
    let defaultSelected = undefined;
    if(Array.isArray(uploadFileTypes) && uploadFileTypes.length) {
        fileTypeOptions = FILE_TYPES.filter(TYPE => uploadFileTypes.includes(TYPE.value));
    }
    if(fileTypeOptions.length === 1) {
        defaultSelected = fileTypeOptions[0];
    }

    const scoringFileType = (defaultSelected || {}).value === FILE_TYPE_MAP.SCORING_MEDIA_VEHICLE;

    const TemplateDownloadLink = () => {
        if (templateLoading) {
            return (
                <Spinner style={styles.spinner}/>
            );
        }
        return (
            <div hidden={templateDownloadHidden} onClick={onTemplateClick} className={styles.templateDownload}>
                <span style={{display: 'flex', alignItems: 'baseline'}}>
                <img src={downloadIcon} alt="download csv icon"/>
                    <div className={styles.downloadText}>Download {fileType.label} data template</div>
                    </span>
            </div>
        );
    };

    React.useEffect(() => {
        const scoringNames = scoresByMcaRunId.map(scoring => scoring.name);
        if (scoringFileType && scoringNames.includes(file?.name)) {
            setWarningTextObj(SCORING_ERROR_OBJ);
        } else {
            setWarningTextObj({});
        }
    }, [file]);

    return (
        <form 
            className={`${styles.formContainer}` + waitingStyle}
            onSubmit={handleSubmit(formSubmit)}>
            <TitleLV>Upload Data</TitleLV>
            <div className={styles.formBox}></div>
            <div className={styles.fields}>
                <FormRowLV>
                    <SelectLV
                        name='filetype'
                        placeholder='Select Type'
                        options={fileTypeOptions}
                        onChange={fileTypeChange} 
                        rules={{ required: true }}
                        control={control}
                        errors={errors}
                        width={353}
                        isLoading={isWaiting}
                        className={styles.fileType}
                        dataTest='selectType-DropDown'
                        defaultValue={defaultSelected}
                        isDisabled={fileTypeOptions.length < 2}
                    />
                </FormRowLV>
                <TemplateDownloadLink/>
                <FormRowLV hidden={appendCheckboxHidden}>
                    <CheckboxLV 
                        name={'appendDataCheckbox'} 
                        label={'Append Data to Existing File'} 
                        dataTest='append-ad-impact-data-checkbox' 
                        onChange={handleChecked}
                        darkTheme={true}
                    />
                </FormRowLV>
                <FormRowLV margin={scoringFileType ? 0 : undefined}>
                    <FileInputLV
                        name='fileInput'
                        onChange={fileChange} 
                        filename={file.name}
                        warningText={warningTextObj.value}
                    />
                </FormRowLV>

                <FormRowLV hidden={!scoringFileType}>
                    <CheckboxLV 
                        name='scoringKpiCheckbox'
                        label='Include Scoring Dependent Variable File'
                        dataTest='scoring-kpi-checkbox' 
                        onChange={handleScoringKpiChecked}
                        darkTheme={true}
                    />
                </FormRowLV>
                <FormRowLV hidden={!scoringFileType || !scoringKpiCheckboxValue} margin={-15}>
                        <FileInputLV
                            name='kpiFileInput'
                            onChange={kpiFileChange} 
                            filename={kpiFile.name}
                            warningText={warningTextObj.value}
                        />
                </FormRowLV>

            </div>
            <div className={styles.buttonContainer}>
                <FormRowLV>
                    <OrangeButtonLV
                        submitting={submitting}
                        isDisabled={submitting || !file.name || warningTextObj.isError || (!kpiFile.name && scoringKpiCheckboxValue)}
                        dataTest={'submitFile-button'}>
                        SUBMIT FILE
                    </OrangeButtonLV>
                    <span className={styles.cssGridBlock}/>
                    <ButtonLV
                        onClick={handleCancel}
                        submitting={submitting}
                        isDisabled={submitting}
                        dataTest={'cancel-button'}>
                        CANCEL
                    </ButtonLV>
                </FormRowLV>
            </div>
        </form>
    );
    
}

FileUploadForm.propTypes = {
    submitForm: PropTypes.func,
    handleCancel: PropTypes.func,
    campaignUploadedFiles: PropTypes.array,
    isWating: PropTypes.bool
};
