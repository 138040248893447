import React from 'react';
import {Chiclet} from '../../../components/dashboards/Chiclet';
import {TableLV} from '../../../components/data-table/TableLV';
import {FormRowLV} from '../../../components/form/row/FormRowLV';
import {OrangeButtonLV} from '../../../components/form/buttons/OrangeButtonLV';
import {getDummyData} from '../../../components/data-table/getDummyData';
import {NumericCell} from '../../../components/data-table/cells/NumericCell';
import {OptimizerGraphContainer} from './OptimizerGraphContainer';
import {ContributionHeaderSummary} from '../../../components/dashboards/ContributionHeaderSummary';
import {divideNumbers, addNumbers} from '../../../js/utils';
import _ from 'lodash';

export const OptimizerReport = ({
  scenarios,
  setIsOptimized=()=>{},
  isLoading,
  optimizedSelection,
  optimizerMap={},
  selectedMcaDashboardRun={},
}) => {
  let selectorColumnName = 'ROI';
  if(selectedMcaDashboardRun.kpiType === 'Units') {
    selectorColumnName = 'CPA';
  }

  // Get all optimized rows that match rounded ROI/CPA
  const optimizerMatches = useOptimizerMatches(optimizedSelection, optimizerMap);

  // Get selections with the highest spend(mediaValue) that is lower than the max optimal(simulatedContribution)
  const optimalMatches = useGetSpendOptimalMatches(optimizerMatches, scenarios, optimizedSelection);

  return (
    <div className='optimizer-report-container'>

      <Chiclet
        id={'optimizer-table-report-chiclet'}
        title={'Summary'}
        imageFileName='optimizer-report-table.png'
        csvFileName='optimizer-report-data.csv'
        data={optimalMatches}>

        <OrangeButtonLV
          isInverted={true}
          onClick={()=>setIsOptimized(false)}
          style={{
            alignSelf: 'flex-end',
            marginTop: '-124px',
            marginBottom: '64px'
          }}>
          REOPTIMIZE
        </OrangeButtonLV>

        <OptimizerReportSummary
          data={optimalMatches}
          selectedMcaDashboardRun={selectedMcaDashboardRun}
        />

        <OptimizerReportTable
          data={optimalMatches}
          isLoading={isLoading}
          selectedMcaDashboardRun={selectedMcaDashboardRun}
          selectorColumnName={selectorColumnName}
        />

      </Chiclet>


      <Chiclet id={'optimizer-chart-report-chiclet'}>

        <OptimizerGraphContainer
          data={optimalMatches}
          isLoading={isLoading}
          selectorColumnName={selectorColumnName}
        />

      </Chiclet>

    </div>
  );
};

const OptimizerReportSummary = ({
  data=[],
  selectedMcaDashboardRun={}
}) => {
  let tableData = data;
  if(!Array.isArray(data) || !data.length) {
    tableData = getDummyData(dummySpendSchema, 3);
  }

  const mappedTableData = useMapTableData(tableData);

  const kpiType = selectedMcaDashboardRun.kpiType;
  const totalsFromData = mappedTableData.map(row => ({
    spend: row.mediaValue,
    contribution: row.simulatedContribution,
  })).reduce((map, row) => ({
    totalSpend: addNumbers(map.totalSpend,
      row.spend),
    totalContribution: addNumbers(map.totalContribution,
      row.contribution),
  }), {});

  const {
    totalSpend=0,
    totalContribution=0
  } = totalsFromData;

  let totalRatio = divideNumbers(totalContribution, totalSpend);
  if(kpiType === 'Units'){
    totalRatio = divideNumbers(totalSpend, totalContribution);
  }
  return (
    <ContributionHeaderSummary
      totalSpend={totalSpend}
      totalContribution={totalContribution}
      costPer={totalRatio}
      variableType={kpiType}
    />
  );
}
const OptimizerReportTable = ({
  data=[],
  isLoading,
  selectedMcaDashboardRun={},
  selectorColumnName='ROI'
}) => {

  let tableData = data;
  if(!Array.isArray(data) || !data.length) {
    tableData = getDummyData(dummySpendSchema, 3);
  }

  const mappedTableData = useMapTableData(tableData);

  const isRevenue = (selectorColumnName !== 'ROI');

  const override = {
    baseVariable: {
      name: 'Variable Name'
    },
    ROI: {
      name: 'ROI',
      align: 'center',
      hidden: isRevenue,
      component: <NumericCell
                   isCurrency={false}
                   isInline={true}
                   align={'center'}
                 />,
    },
    CPA: {
      name: 'CPA',
      align: 'center',
      hidden: !isRevenue,
      component: <NumericCell
                   isCurrency={true}
                   isInline={true}
                   decimals={0}
                   align={'center'}
                 />,
    },
    mediaValue: {
      name: 'Spend',
      align: 'center',
      component: <NumericCell
                   isCurrency={true}
                   isInline={true}
                   decimals={0}
                   align={'center'}
                 />,
    },
    simulatedContribution: {
      name: isRevenue ? 'Projected Units' : 'Revenue',
      align: 'center',
      component: <NumericCell
                   isCurrency={!isRevenue}
                   decimals={0}
                   isInline={true}
                   align={'center'}
                 />,
    },
    marginalROI: {
      name: 'Marginal ROI',
      align: 'center',
      hidden: isRevenue,
      component: <NumericCell
        isCurrency={false}
        decimals={2}
        isInline={true}
        align={'center'}
      />
    },
    marginalCPA: {
      name: 'Marginal CPA',
      align: 'center',
      hidden: !isRevenue,
      component: <NumericCell
        isCurrency={true}
        decimals={2}
        isInline={true}
        align={'center'}
      />
    },
    budget: {
      hidden: true
    }
  };

  return (
    <FormRowLV expand={true} >
      <TableLV
        data={mappedTableData}
        hasData={data.length}
        isLoading={isLoading}
        override={override}
        disableTableSort={true}
        dataTest='optimizer-report-table'
      />
    </FormRowLV>
  );
};

/**
 * Use selected options from Optimizer Form to select from the Optimizer Map
 * the rows that match the rounded value
 * @param optimizedSelection Selections from Optimizer Form
 * @param optimizerMap Map of ScenarioPlanner.csv from back-end
 * @returns List of selected rows
 */
const useOptimizerMatches = (optimizedSelection={}, optimizerMap={}) => {
  return React.useMemo(() => {
    return Object.entries(optimizedSelection)
      .reduce((prev, [key, selection]) => {
        
        const mapSelection = optimizerMap[selection.key];

        if(!mapSelection) {
          return prev;
        }

        let matches = optimizerMap[selection.key].filter(f => {
          if(selection.mRoi && selection.mRoi !== 0){
            if(selection.budget !== 0){
              return (Number(f['marginalROI']) <= Number(selection.mRoi)) &&
                (Number(f['mediaValue']) <= Number(selection.budget));
            }
            else{
              return (Number(f['marginalROI']) <= Number(selection.mRoi));
            }
          }
          if(selection.mCpa && selection.mCpa !== 0){
            if(selection.budget !== 0){
              return (Number(f['marginalCPA']) <= Number(selection.mCpa)) &&
                  (Number(f['mediaValue']) <= Number(selection.budget));
            }
            else{
              return (Number(f['marginalCPA']) <= Number(selection.mCpa));
            }
          }
          else if(selection.budget && selection.budget !== 0){
            return (Number(f['mediaValue']) <= Number(selection.budget));
          }
          else{
            return Number(f[selection.name]) === Number(selection.value);
          }
        }) || [];
        return {
          ...prev,
          [key]: matches
        };

      }, {});
  }, [optimizedSelection, optimizerMap]);
};


/**
 * Return selected row matches based on the media value in relation to the scenario max optimal value
 */
export const useGetSpendOptimalMatches = (optimizerMatches={}, scenarios=[], optimizedSelection={}) => {
  return React.useMemo(() => {

    return Object.entries(optimizerMatches).reduce((prev, [key, matches=[]]) => {

      const scenario = scenarios.find(s => s.variableName === key);
      if(!scenario) {
        return prev;
      }
      const selection = optimizedSelection[key];

      // Filter out matches with spend higher than max optimal
      const parsedNumericFields = matches.map(m => ({
        ...m,
        mediaValue: parseFloat(m.mediaValue),
        marginalROI: parseFloat(m.marginalROI),
        marginalCPA: parseFloat(m.marginalCPA)
      }));

      let match = {};
      if((!selection.budget && selection.budget !== 0)){
        let lowerThanMaxOptimal = parsedNumericFields.filter(
          m => (m.mediaValue < parseFloat(scenario.maxOptimal))
        ) || [];
        let ordered = _.orderBy(lowerThanMaxOptimal, 'mediaValue', 'desc');

        if(!lowerThanMaxOptimal.length) {
          const higherThanMaxOptimal = parsedNumericFields.filter(
            m => (m.mediaValue >= parseFloat(scenario.maxOptimal))
          ) || [];
          ordered = _.orderBy(higherThanMaxOptimal, 'mediaValue', 'asc');
        }
        match = ordered[0];
        if(!match) {
          return prev;
        }
      }
      else if((selection.mRoi && selection.mRoi > 0) || (!selection.value && selection.mRoi === 0 && selection.budget > 0)){
        let highestMarginalROI = _.orderBy(parsedNumericFields, ['marginalROI', 'mediaValue'], ['desc', 'desc']);
        match = highestMarginalROI[0];
        if(!match) {
          return prev;
        }
      }
      else if((selection.mCpa && selection.mCpa > 0) || (!selection.value && selection.mCpa === 0 && selection.budget > 0)){
        let highestMarginalCPA = _.orderBy(parsedNumericFields, ['marginalCPA', 'mediaValue'], ['desc', 'desc']);
        match = highestMarginalCPA[0];
        if(!match) {
          return prev;
        }
      }
      else if(selection.value && (selection.mRoi === 0 || selection.mCpa === 0) && selection.budget > 0){
        let lowerThanBudget = _.orderBy(parsedNumericFields, 'mediaValue', 'desc');
        match = lowerThanBudget[0];
        if(!match) {
          return prev;
        }
      }

      return [
        ...prev,
        match
      ];
    }, []);
    
  }, [optimizerMatches, scenarios, optimizedSelection]);
};


/**
 * Map to only columns used be Optimizer Table
 */
const useMapTableData = (data=[]) => {
  return React.useMemo(() => {
    return data.map(
      ({
        baseVariable, mediaValue, simulatedContribution, ROI, CPA, marginalROI, marginalCPA
      }) => ({
        baseVariable, mediaValue, simulatedContribution, ROI, CPA, marginalROI, marginalCPA
      }));
  }, [data]);
};

const dummySpendSchema = {
  baseVariable: '',
  mediaValue: 'Display',
  simulatedContribution: '',
  ROI: '',
  CPA: '',
  marginalROI: '',
  marginalCPA: '',
  budget: ''
};
