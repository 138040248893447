import React from 'react';
import {GraphIconOption} from './GraphIconOption';

export const OptionPie = ({children}) => {
  return(
    <GraphIconOption>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="icon/ pie">
          <path id="icon/action/donut_small_24px" fillRule="evenodd" clipRule="evenodd" d="M11.083 20.82C11.083 21.46 10.493 21.94 9.87298 21.8C5.40298 20.8 2.08298 16.79 2.08298 12C2.08298 7.21002 5.40298 3.20002 9.87298 2.20002C10.493 2.06002 11.083 2.54002 11.083 3.18002V20.82ZM13.113 3.18002V9.99002C13.113 10.54 13.563 10.99 14.113 10.99H20.903C21.543 10.99 22.023 10.4 21.883 9.77002C21.033 6.01002 18.083 3.05002 14.333 2.20002C13.703 2.06002 13.113 2.54002 13.113 3.18002ZM13.113 20.82V14.01C13.113 13.46 13.563 13.01 14.123 13H20.913C21.543 13 22.033 13.6 21.893 14.22C21.043 17.98 18.093 20.95 14.333 21.8C13.703 21.94 13.113 21.46 13.113 20.82Z" fill="#4F4F4F"/>
        </g>
      </svg>
      {children}
    </GraphIconOption>
  );
};
