import React from 'react';
import {ScoredContributionsChiclet} from '../chiclets/ScoredContributionsChiclet';
import {ScoredDataIntervalsChiclet} from '../chiclets/ScoredDataIntervalsChiclet';
import {useCampaign} from '../../../hooks/useCampaign';
import {useClient} from '../../../hooks/useClient';
import DownloadCSVFactory from '../../../js/download-csv-factory';

import {useScoringContributionDashboard} from "./hooks/useScoringContributionDashboard";
import {ScoringDashboardContext} from "../ScoringDashboardContext";

export const ScoringContributionDashboard = ({
                                                 isCombined
                                             }) => {

    const {
        selectedMcaRun,
        selectedScoringRun,
        nestedRunKpis,
    } = React.useContext(ScoringDashboardContext);

    const {campaignName} = useCampaign() || {};
    const {companyName: clientName} = useClient() || {};
    const {name: runName} = selectedMcaRun;

    const {
        contributions,
        contributionsWithNegatives,
        modelData,
        isLoading
    } = useScoringContributionDashboard({
        isCombined,
        nestedRunKpis,
    })

    // Add Client, Campaign, MCA Run rows to downloaded CSVs
    DownloadCSVFactory.setReportProperties(
        clientName,
        campaignName,
        runName
    );

    return (
        <div>
            <ScoredContributionsChiclet
                selectedMcaRun={selectedMcaRun}
                selectedScoringRun={selectedScoringRun}
                data={contributions}
                modelData={modelData}
                isLoading={isLoading}
                isCombined={isCombined}
            />

            <ScoredDataIntervalsChiclet
                data={contributionsWithNegatives}
                csvData={contributions}
                isLoading={isLoading}
                isCombined={isCombined}
            />
        </div>
    );
};