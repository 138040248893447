import request from 'superagent';

/**
 * Lazy-load from the current configuration JSON which lies in /public/leavened-config.json
 * This contains the environment specific configs
 * To access this from our static app, we make a GET request to that location
 */
export default class ConfigFactory {
  CONFIG_PATH = '/leavened-config.json';
  CONFIG_KEY = 'LEAVENED_CONFIG';
  config = undefined;
  isLoading = false;

  getConfig() {
    if(!this.config) {
      this.setConfig();
      return this.getConfigFromStorage();
    }
    return this.config;
  }

  // Make a request to /leavened-config.json and set the returned JSON
  setConfig() {
    if(this.isLoading) {
      return;
    }

    this.isLoading = true;

    request
      .get(this.CONFIG_PATH)
      .type('application/json')
      .set({
        'Cache-Control': 'no-cache'
      })
      .then((response) => {
        const store = JSON.stringify(response.body);
        this.config = response.body;
        return window.localStorage.setItem(this.CONFIG_KEY, store);
      })
      .catch((error) => {
        console.error('Error loading the run time configuration', error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  getConfigFromStorage() {
    const storeConfig = window.localStorage.getItem(this.CONFIG_KEY);
    if(!storeConfig) {
      return DEFAULT_CONFIG;
    }
    return JSON.parse(storeConfig);
  }

  clear() {
    this.config = undefined;
    this.isLoading = false;
  }
}

// Temporary config file to return until async config call is complete
export const DEFAULT_CONFIG = {
  BASE_URL: undefined,
  YEASTY_URL: undefined,
  ALT_BASE_URL: undefined,
  REGION: undefined,
  USER_POOL_ID: undefined,
  USER_POOL_WEBCLIENT_ID: undefined,
  IDENTITY_POOL_ID: undefined,
  UPLOAD_BUCKET: undefined,
  MODEL_LIMIT: 50,
  MAX_RUN_MODELS: 100000000,
  MAX_TRIAL_RUN_MODELS: 10000,
  MAX_TRIAL_RUNS: 1,
  FEATURE_FLAGS: {}
};

// Singleton
export const Config = new ConfigFactory();
