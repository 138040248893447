import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {styles} from './profile-chip.styles.js';

const ProfileChip = ({customStyles, onClick, children, dataTest}) => {
    let combinedStyles = classnames(customStyles, styles.profileChipBackground);
    return (
        <div className={combinedStyles} onClick={onClick} 
          data-test={dataTest}>
            <p className={styles.profileChipText}> {children} </p>
        </div>
    );
}

ProfileChip.propTypes = {
    initials: PropTypes.string,
    handleClick: PropTypes.func
};

export default ProfileChip;
