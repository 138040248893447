import {call, put, takeEvery} from 'redux-saga/effects';
import {getData, post} from '../../../js/server';
import {urlBuilders} from '../../../js/constants.js';

import {
    FETCH_ALL_PARTNERS,
    LOAD_ALL_PARTNERS,
    CREATE_PARTNER,
    loadPartnerErrors
} from '../partner.js';
import {LOAD_ALL_CLIENTS} from '../client.js';
import {LOAD_ALL_CAMPAIGNS} from '../campaign.js';

import {
    changeAddNewStep,
    ADD_NEW_COMPLETE
} from '../../../modules/platform/addNew.js';

/**
 * [fetchPartners gets all the partners. Clients and campaigns are also in the response,
 * so those need to be broken out and sent to the right modules.]
 * @param  {object}    action the action that started the saga
 * @return {Generator}        the generator function that controls the flow of the saga
 */
export function* fetchPartners(action) {
    let partners = yield call(
        getData,
        action.token,
        action.baseUrl + urlBuilders.getAllPartners()
    );

    if (!partners.error) {
        let clients = yield call(pullOffChild, 'companies', partners);
        let campaigns = yield call(pullOffChild, 'campaigns', clients);

        partners = yield call(killChild, 'companies', partners);
        clients = yield call(killChild, 'campaigns', clients);

        yield put({type: LOAD_ALL_PARTNERS, partners});
        yield put({type: LOAD_ALL_CLIENTS, clients});
        yield put({type: LOAD_ALL_CAMPAIGNS, campaigns});
    }
}

export function* watchFetchPartners() {
    yield takeEvery(FETCH_ALL_PARTNERS, fetchPartners);
}

export function* createPartner(action) {
    const partnerResponse = yield call(
        post,
        action.token,
        action.baseUrl + urlBuilders.createPartner(),
        action.payload
    );

    if (!partnerResponse.error) {
        yield call(fetchPartners, action);
    } else {
        yield put(
            loadPartnerErrors(partnerResponse.rawError.response.body.errors)
        );
    }
    yield put(changeAddNewStep(ADD_NEW_COMPLETE));
}

export function* watchCreatePartner() {
    yield takeEvery(CREATE_PARTNER, createPartner);
}

/**
 * Takes a name of a child and then an array of objects, pulls the named child off of all the objects,
 * returing a new array made of all these children
 * @param  {String} child the key to pull off
 * @param  {array} array the array of objects
 * @return {array}       made of the children of the input array
 */
export function pullOffChild(child, array) {
    const newArray = [];
    // eslint-disable-next-line
    array.map((parentObject) => {
        // eslint-disable-next-line
        parentObject[child].map((childObject) => {
            newArray.push(Object.assign({}, childObject));
        });
    });
    return newArray;
}

/**
 * Takes a name of a child and then an array of objects and removes the key that matches the child from
 * each of the objects
 * @param  {String} child the key to remove
 * @param  {array} array array of objects to have child removed
 * @return {array}       the array of objects with the child removed
 */
export function killChild(child, array) {
    return array.map((object) => {
        delete object[child];
        return Object.assign({}, object);
    });
}
