import React from 'react';
import {SelectCallToAction} from '../../components/tabs/SelectCallToAction';
import {mapToOptions} from '../../components/form/select/SelectLV';
import {useCampaignManagement} from '../../hooks/useCampaignManagement';
import {useScoringByCampaignId} from './hooks/useScoringByCampaignId';
import {RUN_STATUS} from '../../constants/mca-run-statuses';
import {ScoringDashboardContext} from "./ScoringDashboardContext";

export const ScoringCallToAction = ({}) => {

    const {
        setSelectedScoringRun
    } = React.useContext(ScoringDashboardContext);

    const {scoringId, setScoringId} = useCampaignManagement();
    const {scoresByMcaRunId = [], loading} = useScoringByCampaignId();

    React.useEffect(() => {
        if (scoresByMcaRunId?.length && scoringId) {
            const scoringRun = completeScoresByMcaRunId.find(run => run.id === scoringId);
            setSelectedScoringRun(scoringRun);
        }
    }, [scoringId, scoresByMcaRunId]);

    const completeScoresByMcaRunId = scoresByMcaRunId.filter(run => run.scoringStatus === RUN_STATUS.COMPLETE);
    const scoringOptions = mapToOptions(completeScoresByMcaRunId, 'id', 'display');
    const selectedOption = scoringOptions.find(o => o.value === scoringId);
    const onChange = ({value}) => {
        setScoringId(value);
    };

    return (
        <SelectCallToAction
            dataTest='scored-run-cta'
            name='select-scored-file'
            placeholder={'Select Scored File'}
            width={0}
            options={scoringOptions}
            onChange={onChange}
            defaultValue={selectedOption}
            isLoading={loading}
        />
    );
};
