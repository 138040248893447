import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import {styles} from './EditableCell.styles';
import {getErrorMessage} from '../../form/wrapper/FieldErrorLV';
import _ from 'lodash';

/**
 * Add an editable table cell that works with react-hook-form
 */
export const EditableCell = ({
  cell,
  type='text',
  defaultValue=0,
  placeholder,
  name='',
  register,
  onChange=()=>{},
  onBlur=()=>{},
  onKeyDown=()=>{},
  dataTest,
  disabled,
  errors,
  className,
  min=0,
  step,
  style={}
}) => {
  const value = _.get(cell, 'value', defaultValue);
  const rowId = _.get(cell, 'row.id', '');
  let fieldName = name || rowId;
  let errClass = '';
  let isDisableTooltip = true;
  const errMessage = getErrorMessage(fieldName, errors);

  if(errMessage) {
    isDisableTooltip = false;
    errClass = 'error';
  }

  // Select field value when user enters
  const handleFocus = (event) => event.target.select();
  const classNames = [className, styles.container, errClass, 'editable-cell'].join(' ');
  return (
    <div
      className={classNames}
      style={style}>

      <Tooltip
        title={errMessage || ''}
        disableFocusListener={isDisableTooltip}
        disableHoverListener={isDisableTooltip}
        disableTouchListener={isDisableTooltip}>
        
        <input
          name={fieldName}
          type={type}
          placeholder={placeholder}
          ref={register}
          defaultValue={defaultValue || value}
          onChange={onChange}
          data-test={dataTest}
          disabled={disabled}
          autoComplete={'off'}
          min={min}
          step={step}
          onFocus={handleFocus}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
       />
      </Tooltip>
    </div>
  );
};
