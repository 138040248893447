import {css} from 'emotion';

export const styles = {
  circle: css`
    height: 40px;
    width: 40px;
    background-color: #263542;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;

    img {
      height: 20px;
      width: 20px;
    }

  `,
  pointer: css`
    cursor: pointer;
  `,
  profile: css`
    background: #ff7762;
    border-radius: 50%;
    height: 34px;
    width: 34px;
    border: white 3px solid;
    display: flex;
    justify-content: center;
    align-items: center;
  `
};
