// actions
export const LOAD_ALL_DEPARTMENTS = `platform/department/load-all-departments`;
export const SET_SELECTED_DEPARTMENT = `platform/department/set-selected`;

export const initialState = {
    byId: {},
    allIds: [],
    selectedDepartment: ''
};

// selectors
const ID = `department`;
const local = (state) => state['platform'][ID];
export const getDepartmentById = (state, id) => local(state).byId[id];
export const getDepartments = (state) => local(state).allIds;
export const getDepartmentsByClientId = (state, id) =>
    getDepartmentsByClientIdCore(local(state), id);
export const getSelectedDepartment = (state) => local(state).selectedDepartment;

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_ALL_DEPARTMENTS:
            let newById = {};
            let newAllIds = action.departments.map((department) => {
                newById[department.id] = department;
                return department.id;
            });
            return Object.assign({}, state, {byId: newById, allIds: newAllIds});
        case SET_SELECTED_DEPARTMENT:
            return Object.assign({}, state, {
                selectedDepartment: action.selectedDepartment
            });
        default:
            return state;
    }
}

// action creators
export function loadAllDepartments(departments) {
    return {
        type: LOAD_ALL_DEPARTMENTS,
        departments
    };
}

export function setSelectedDepartment(selectedDepartment) {
    return {
        type: SET_SELECTED_DEPARTMENT,
        selectedDepartment
    };
}

// helper functions
function getDepartmentsByClientIdCore(state, id) {
    return state.allIds.filter((department) => {
        return state.byId[department].clientId === id;
    });
}
