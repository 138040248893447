import React from 'react';
import moment from 'moment';
import {ChicletLV} from '../../components/chiclet/ChicletLV';
import {LineChartLV} from '../../components/charts/LineChartLV';
import { CHART_FORMAT } from '../../components/charts/ChartFormat.constant';
import {useGet} from '../../hooks/useRest';
import {urlBuilders} from '../../js/constants';
import {useCampaignManagement} from '../../hooks/useCampaignManagement';
import {R1AutoComplete} from '@rainagency/rain-one-soggy-loaf';

export const InputDataReview = ({
  isTest = false
}) => {
  const [allSelectedVariables, setAllSelectedVariables] = React.useState([]);
  const [selectedVariables, setSelectedVariables] = React.useState([]);
  const [selectedRegions, setSelectedRegions] = React.useState([]);
  const [selectedVariables2, setSelectedVariables2] = React.useState([]);

  const {
    campaignId,
  } = useCampaignManagement();

  const [{
    data: uploadedVariables,
    loading: uploadedVariablesLoading
  }, fetchUploadedVariables] = useGet({
    url: urlBuilders.getUploadedVariables(campaignId),
    manual: true
  });

  const variableQueryUrl = getVariableUrlQuery(
    selectedRegions,
    allSelectedVariables,
    urlBuilders.getUploadedVariables(campaignId)
  );

  const [{
    data: variableData,
    loading: variableDataLoading
  }, fetchVariableData] = useGet({
    url: variableQueryUrl,
    manual: true
  });

  // Trigger variable data after user selects a variable
  React.useEffect(() => {
    if(!campaignId || !allSelectedVariables.length) {
      return;
    }

    fetchVariableData();
  }, [variableQueryUrl]);

  React.useEffect(() => {
    if(!campaignId) {
      return;
    }

    fetchUploadedVariables();

  }, [campaignId]);

  const allVariableOptions = React.useMemo(() => {
    return getAllVariableOptions(uploadedVariables);
  }, [uploadedVariables]);

  const regionOptions = React.useMemo(() => {
    return getRegionOptions(uploadedVariables);
  }, [uploadedVariables]);

  const isLoading = [
    uploadedVariablesLoading,
    variableDataLoading
  ].some(l => l);

  const varsChangeHandler = (vals) => {
    setSelectedVariables(vals);
    setAllSelectedVariables([...selectedVariables2, ...vals]);
  };

  const varsDisableHandler = (option) => {
    return selectedVariables2.includes(option);
  };

  const vars2ChangeHandler = (vals) => {
    setSelectedVariables2(vals);
    setAllSelectedVariables([...selectedVariables, ...vals]);
  };

  const vars2DisableHandler = (option) => {
    return selectedVariables.includes(option);
  };

  const regionsChangeHandler = (regions) => {
    setSelectedRegions(regions);
  };

  const chartData = React.useMemo(
    () => mapChartData(variableData, allSelectedVariables),
    [variableData, allSelectedVariables, selectedRegions]
  );

  const formatMap = React.useMemo(
    () => getFormatMap(chartData),
    [chartData]
  );

  const hasVariables = (!isLoading && allVariableOptions.length);
  let noDataMessage = 'Select a Variable';

  if(!hasVariables) {
    noDataMessage = 'Upload KPI and MV data files';
  }

  const secondAxisVariables = selectedVariables2.map(variable => variable.label);

  return (
    <div className='input-data-review'>

      <ChicletLV
        imageFileName='IndependentVariableTimeSeries.png'
        chicletImageId='independent-variable-time-series'
        imageTooltip='Download IndependentVariableTimeSeries.png'
        fileTooltip='Download IndependentVariableTimeSeries.csv'
        csvFileName='IndependentVariableTimeSeries.csv'
        excelFileName='IndependentVariableTimeSeries.xlsx'
        downloadFileData={chartData}
        title='Variable Time Series'>

        <AutoComplete
          regionOptions={regionOptions}
          allVariableOptions={allVariableOptions}
          varsChangeHandler={varsChangeHandler}
          varsDisableHandler={varsDisableHandler}
          vars2ChangeHandler={vars2ChangeHandler}
          vars2DisableHandler={vars2DisableHandler}
          regionsChangeHandler={regionsChangeHandler}
          isTest={isTest}
        />

        <LineChartLV
          data={chartData}
          isLoading={isLoading}
          xAxisKey='date'
          yAxisKey='variables'
          height={500}
          xTicks={30}
          noDataMessage={noDataMessage}
          formatMap={formatMap}
          TopLeftContent={
            <AutoComplete
              regionOptions={regionOptions}
              allVariableOptions={allVariableOptions}
              varsChangeHandler={varsChangeHandler}
              varsDisableHandler={varsDisableHandler}
              vars2ChangeHandler={vars2ChangeHandler}
              vars2DisableHandler={vars2DisableHandler}
              regionsChangeHandler={regionsChangeHandler}
            />
          }
          secondAxisVars={secondAxisVariables}
        />

      </ChicletLV>

    </div>
  );
};

const getAllVariableOptions = (uploadedVariables = {}) => {
  if (!uploadedVariables) {
    return [];
  }
  const { depVars = [], indVars = [] } = uploadedVariables;

  const depVarOptions = depVars.map(dep => ({ label: dep, type: 'depVar' }));
  const indVarOptions = indVars.map(ind => ({ label: ind, type: 'indVar' }));

  return [...depVarOptions, ...indVarOptions];
};

const getRegionOptions = (uploadedVariables = {}) => {
  if (!uploadedVariables) {
    return [];
  }
  const { regions = [] } = uploadedVariables;
  const regionOptions = regions.map(region => ({ label: region, type: 'region' }));

  return regionOptions;
};

const getVariableUrlQuery = (regions = [], variables = [], baseUrl) => {
  const regionsQuery = regions.reduce((prev, next) => `${prev}${next.type}=${encodeURIComponent(next.label)}&`, `${baseUrl}?`);
  const variablesQuery = variables.reduce((prev, next) => `${prev}${next.type}=${encodeURIComponent(next.label)}&`, '').slice(0, -1);
  return `${regionsQuery}${variablesQuery}`;
};

const mapChartData = (variableData, selectedVariables = []) => {

  if (!variableData || !selectedVariables.length) {
    return [];
  }

  const { indVars = {}, depVars = {} } = variableData;

  const allVars = { ...indVars, ...depVars };

  // Set map for each location in the chart
  const allKeyMap = Object.keys(allVars).reduce((prev, curr) => ({ ...prev, [curr]: undefined }), {});

  // Map all values to date key to match what the chart will expect
  const mappedToDate = Object.entries(allVars)
    .reduce((prev, current) => {
      const [key, values] = current;

      if(!Array.isArray(values)) {
        return prev;
      }

      values.forEach(val => {

        const prevDateObj = prev[val.date] || {};
        const valueMap = { ...allKeyMap };

        // Ensure previous values are populated for this data
        Object.keys(valueMap).forEach(k => {
          valueMap[k] = prevDateObj[k] || 0;
        });
        valueMap[key] = val.value * 1;
        valueMap.date = val.date;

        prev[val.date] = valueMap;

      });

      return prev;
    }, {});

  return Object.values(mappedToDate)
    .map(({date, ...v}) => ({
      date: moment(date, "M/D/YYYY").format("YYYY-MM-DD"), // Date displays first in .csv
      ...v
    }));
};

/**
 * Format all selected variables to INTEGER in chart
 */
const getFormatMap = (chartData = []) => {
  if(!chartData.length) {
    return undefined;
  }

  const {date, ...schema} = chartData[0];
  return Object.keys(schema)
    .reduce((prev, current) => ({
      [current]: CHART_FORMAT.INTEGER, // Each selected variable
      ...prev
    }), {
      variables: CHART_FORMAT.INTEGER // Default yAxis value
    });
};

/**
 * Autocomplete in chart legend does not render in tests
 * Workaround to display AutoComplete in test
 */
const AutoComplete = ({
  regionOptions,
  regionsChangeHandler,
  allVariableOptions,
  varsChangeHandler,
  varsDisableHandler,
  vars2ChangeHandler,
  vars2DisableHandler,
  isTest
}) => {

  if(isTest === false) {
    return null;
  }

  return (
    <div>
      <R1AutoComplete
        style={{marginBottom: '12px'}}
        options={regionOptions}
        label='Select Regions'
        multiple={true}
        onChange={regionsChangeHandler}
        minWidth={300}
      />
      <div style={{width: "100%", display: "flex"}}>
        <div style={{paddingRight: '10px'}}>
          <R1AutoComplete
            options={allVariableOptions}
            label='Select Left Variables'
            multiple={true}
            onChange={varsChangeHandler}
            getOptionDisabled={varsDisableHandler}
            minWidth={300}
          />
        </div>
        <div>
          <R1AutoComplete
            options={allVariableOptions}
            label='Select Right Variables'
            multiple={true}
            onChange={vars2ChangeHandler}
            getOptionDisabled={vars2DisableHandler}
            minWidth={300}
          />
        </div>
      </div>
    </div>);
};

