import React from 'react';
import { styled } from '@mui/system';
import { UserNavigation } from './UserNavigation';
import { Link } from 'react-router-dom';
import { AnalysisRunSelect } from './components/AnalysisRunSelect';
import { Breadcrumbs, CircularProgress, Autocomplete, TextField } from '@mui/material';
import { FEATURES } from '../../../constants/features';

export const TopNavigation = ({
  email,
  firstName,
  lastName,
  onUserNavigationSelect,
  products,
  selectedFeature,
  adImpactRuns,
  mmmRuns,
  selectedAdImpactRun,
  selectedMMMRun,
  onSelectAnalysis,
  loading = false,
  breadcrumbs
}) => {

  return (
    <TopNavigationStyled
      loading={loading.toString()}
      className='lv-top-navigation'
      data-testid='lv-top-navigation'
    >

      <LoadingSpinner loading={loading}/>

      <BreadcrumbsDisplay
        breadcrumbs={breadcrumbs}
      />

      <AnalysisAndUserStyled
        className='analysis-and-user-nav'>

        <AnalysisRunSelect
          adImpactRuns={adImpactRuns}
          mmmRuns={mmmRuns}
          onSelectAnalysis={onSelectAnalysis}
          selectedFeature={selectedFeature}
          selectedAdImpactRun={selectedAdImpactRun}
          selectedMMMRun={selectedMMMRun}
          loading={loading}
        />

        <UserNavigation
          products={products}
          firstName={firstName}
          lastName={lastName}
          email={email}
          onUserNavigationSelect={onUserNavigationSelect}
        />

      </AnalysisAndUserStyled>

    </TopNavigationStyled>
  );
};

const LoadingSpinner = ({
  loading
}) => {
  if (!loading) {
    return null;
  }

  return (
    <CircularProgressStyled
      className='top-nav-spinner'
      color='inherit'/>
  );
};

const BreadcrumbsDisplay = ({
  breadcrumbs = []
}) => {

  const crumbs = breadcrumbs.map((c, index) => (
    <Crumb
      key={`crumb-${index}`}
      crumb={c}
      index={index}
    />
  ));

  return (
    <BreadcrumbsStyled separator="/" aria-label="breadcrumb" data-testid='lv-breadcrumbs'>
        {crumbs}
    </BreadcrumbsStyled>
  );
};

const Crumb = ({
  crumb = {}
}) => {

  let styles = {};
  if(!crumb.onClick) {
    styles = {
      pointerEvents: 'none'
    };
  }

  return (
    <LinkStyled
      sx={{
        color: crumb.color || '#979797',
        ...styles
      }}
      onClick={crumb.onClick}
      to={{}}
    >
        {crumb.label}

    </LinkStyled>
  );
};

const AnalysisAndUserStyled = styled('div')(({
  theme
}) => ({
  display: 'flex',
  justifyContent: 'end'
}));

const TopNavigationStyled = styled('div')(({
  theme,
  loading
}) => {
  let loadingCss = {};
  const isLoading = (loading === 'true');

  if(isLoading) {
    loadingCss = {
      opacity: '0.6',
      pointerEvents: 'none',
    };
  }

  return {
    display: 'grid',
    gridTemplateColumns: '8fr 4fr',
    borderBottom: '1px solid #E0E0E0',
    width: '100%',
    ...loadingCss
  };
});

const LinkStyled = styled(Link)(({
  theme
}) => ({
  color: '#979797',
  textDecoration: 'none',

  '&:hover': {
    textDecoration: 'underline'
  }
}));

const BreadcrumbsStyled = styled(Breadcrumbs)(({
  theme
}) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  width: '96%',
  padding: '20px 24px',
  color: '#979797',
}));

const CircularProgressStyled = styled(CircularProgress)(({
  theme
}) => ({
  position: 'absolute',
  left: '50%',
  right: '50%',
  top: 22,
  color: '#979797',
  opacity: '0.6',
}));
