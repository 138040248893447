import React from 'react';
import {connect} from 'react-redux';

import checkMark from '../../../assets/circle_check_mark.svg';
import crossMark from '../../../assets/circle_cross_mark.svg';

import {
    getCompleteModalBody, 
    getCompleteModalTitle,
    getCompleteModalSuccess,
    closeModal
} from '../../../modules/platform/modal.js';


import CompleteModalPresentation from './complete-modal-presentation.jsx';

/**
 * Generic modal for the completion modal.
 * Gets title, body, and success from state.
 */
class CompleteModalContainer extends React.Component {

    constructor(props) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose() {
        this.props.dispatch(closeModal());
    }

    render() {
        const {success, title, body} = this.props;
        const uploadStatusIcon = success ? checkMark : crossMark;

        return (
            <CompleteModalPresentation icon={uploadStatusIcon} title={title} body={body} handleClose={this.handleClose}/>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        title: getCompleteModalTitle(state),
        body: getCompleteModalBody(state),
        success: getCompleteModalSuccess(state),
    };
};

export default connect(mapStateToProps)(CompleteModalContainer);
