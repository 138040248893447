/**
 * Available navigation tabs to display for product
 */
export const TABS = {
  MASLOW: {label: 'Maslow', path: '/maslow'},
  ADIMPACT: {label: 'AdImpact', path: '/campaign/ad-impact'},
  MMM: {label: 'MMM', path:'/campaign/mca'},
  OSTROW: {label: 'Ostrow Model', path: '/marketing-tools/ostrow'},
  MANAGE_DATA: {label: 'Manage Data', path: '/campaign/data-management'},
  DASHBOARDS: {label: 'Dashboards', path: '/campaign/dashboards'},
  SCENARIO_PLANNER: {label: 'Scenario Planner', path: '/campaign/scenario-planner'},
  SCORING: {label: 'Scoring', path: '/campaign/scoring'},
  ADIMPACT_DASHBOARDS: {label: 'Dashboards', path: '/campaign/ad-impact-dashboards'}
};

/**
 * List of our products
 */
export const PRODUCT = {
  MMM: {
    key: 'MMM',
    option: {value: 'MMM', label: 'MMM'},
    path: '/campaign/mca',
    tabs: [TABS.MMM, TABS.MANAGE_DATA, TABS.DASHBOARDS, TABS.SCENARIO_PLANNER, TABS.SCORING],
    userGroup: 'MMM_Group'
  },
  ADIMPACT: {
    key: 'ADIMPACT',
    option: {value: 'ADIMPACT', label: 'ADIMPACT'},
    path: '/campaign/ad-impact',
    tabs: [TABS.ADIMPACT, TABS.MANAGE_DATA, TABS.ADIMPACT_DASHBOARDS],
    userGroup: 'AdImpact_Group'
  },
  MASLOW: {
    key: 'MASLOW',
    option: {value: 'MASLOW', label: 'MASLOW'},
    path: '/maslow',
    tabs: [],
    userGroup: 'Wavecast_Group'
  },
  MARKETING_TOOLS: {
    key: 'MARKETING_TOOLS',
    option: {value: 'MARKETING_TOOLS', label: 'MARKETING TOOLS'},
    path: '/marketing-tools/ostrow',
    tabs: [TABS.OSTROW],  
  }
};

export const LIMITED_PRODUCT = {
  MMM: {
    key: 'MMM',
    option: {value: 'MMM', label: 'MMM'},
    path: '/campaign/mca',
    tabs: [TABS.DASHBOARDS, TABS.SCENARIO_PLANNER,  TABS.SCORING],
    userGroup: 'MMM_Group'
  },
  ADIMPACT: {
    key: 'ADIMPACT',
    option: {value: 'ADIMPACT', label: 'ADIMPACT'},
    path: '/campaign/ad-impact',
    tabs: [TABS.ADIMPACT_DASHBOARDS],
    userGroup: 'AdImpact_Group'
  },
  MASLOW: {
    key: 'MASLOW',
    option: {value: 'MASLOW', label: 'MASLOW'},
    path: '/maslow',
    tabs: [],
    userGroup: 'Wavecast_Group'
  },
  MARKETING_TOOLS: {
    key: 'MARKETING_TOOLS',
    option: {value: 'MARKETING_TOOLS', label: 'MARKETING_TOOLS'},
    path: '/marketing-tools/ostrow',
    tabs: [TABS.OSTROW],
  }
};

export const TRIAL_PRODUCT = {...PRODUCT, 
  MMM: {
    key: 'MMM',
    option: {value: 'MMM', label: 'MMM'},
    path: '/campaign/mca',
    tabs: [TABS.MMM, TABS.MANAGE_DATA, TABS.DASHBOARDS, TABS.SCENARIO_PLANNER],
    userGroup: 'MMM_Group'
  }
};

// For ProductDropDown
export const PRODUCT_OPTIONS = [
  PRODUCT.MMM.option,
  PRODUCT.ADIMPACT.option,
  PRODUCT.MASLOW.option,
  PRODUCT.MARKETING_TOOLS.option
];

export const MASLOW_OPTIONS = [
  PRODUCT.MASLOW.option
];
