import React from 'react';
import {BarChart, Bar, Cell, XAxis, YAxis, Tooltip, ResponsiveContainer, ReferenceLine, CartesianGrid} from 'recharts';
import {getChartColorAtIndex} from './chartColors';

import {CustomTooltip} from './CustomTooltip';
import {EllipsesAxisTick} from './EllipsesAxisTick';
import {formatAxis} from './chart-format.util';
import {useValidateChartData} from './useValidateChartData';
import {styles, getChartStateCss} from './charts.styles';

/**
 * Builds a Column chart using bar chart,
 * http://recharts.org/en-US/api/BarChart
 * @param uniformColor Color override for all bars to be
 */
export const BarChartLV = ({
  data, yAxisLabel='', yAxisKey='', isLoading=false, formatMap={}, isVertical=false,
  uniformColor, displayXValues=true
}) => {
  const BAR_COLORS = ['#FF6B57', '#2A5588', '#A8DA7C', '#FFDD5B'];

  const tooltipMap = {
    ...formatMap
  };
  const chartData = useValidateChartData(data, 5, '', yAxisKey);
  const chartStateCss = getChartStateCss(data, isLoading);

  // Color the columns of bar chart
  // http://recharts.org/en-US/api/Cell
  const getCellWithColor = (row, index) => {
    let fillColor = chartData.length > 4 ? getChartColorAtIndex(index) : BAR_COLORS[index];
    if(uniformColor) {
      fillColor = uniformColor;
    }
    return (
      <Cell
        key={`cell-${index}`}
        fill={fillColor}
      />
    );
  };
  const Columns = chartData.map(getCellWithColor);

  const classNames = [styles.chart, chartStateCss].join(' ');
  const layout = isVertical ? 'vertical' : 'horizontal';

  // Has to be called like a function instead of component or ReCharts won't render properly
  const TopAxisLayout = TopAxis({
    isVertical,
    angle: -90, 
    height: 100,
    textAnchor: "end",
    interval: 0,
    tick: <EllipsesAxisTick axis={'x'} ellipses={true} display={displayXValues}/>
  });

  const BottomAxisLayout = BottomAxis({
    isVertical,
    width: 100,
    tickMargin: 10,
    axisLine:false,
    tickSize:0,
    tickCount: 11,
    tickFormatter: formatAxis(yAxisKey, formatMap),
    tick: <EllipsesAxisTick axis={'y'} ellipses={true}/>,
    label: {
      value: yAxisLabel,
      angle: -90,
      position: 'insideLeft'
    }
  });

  return (
    <ResponsiveContainer
      width={'100%'}
      height={400}
      debounce={1}
      id='bar-chart'
      className={classNames}>

      <BarChart
        width={600}
        height={400}
        data={chartData}
        margin={{top: 10, right: 0, left: 0, bottom: 15}}
        layout={layout}
        barSize={60}>

        {TopAxisLayout} 

        {isVertical ? <ReferenceLine x={0} stroke="#828282" /> : null}

        {BottomAxisLayout}

        <CartesianGrid vertical={false} />

        <Tooltip
          isAnimationActive={false}
          content={<CustomTooltip map={tooltipMap} />}
          cursor={false}/>

        <Bar dataKey="value" >
          {Columns}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

const TopAxis = ({isVertical=false, ...props}) => {
  let type = 'category';
  let dataKey = 'name';

  if(isVertical) {
    type = 'number';
    dataKey = undefined;
  }
  return (
    <XAxis
      {...props}
      type={type}
      dataKey={dataKey}
    />
  );
};

const BottomAxis = ({isVertical=false, ...props}) => {
  let type = 'number';
  let dataKey = undefined;

  if(isVertical) {
    type = 'category';
    dataKey = 'name';
  }

  if(isVertical) {
    return (
      <YAxis
        {...props}
        type={type}
        dataKey={dataKey}
      />
    );
  }
  return (
    <YAxis
      {...props}
      type={type}
    />
  );
};
