import React, { useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import moment from 'moment';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { 
    R1Form, 
    useR1Form,
    R1RadioGroup,
    R1TextBox, 
    R1DateRangePicker, 
    R1Button
} from '@rainagency/rain-one-soggy-loaf';

import { useCampaignManagement } from '../../../../hooks/useCampaignManagement';
import { useMcaRuns } from '../../../../hooks/useMcaRuns';
import { useAdImpactRuns } from '../../../../hooks/useAdImpactRuns';
import { variableNameToRaw } from '../../../../js/utils';
import { NESTED_MODEL_TYPE_OPTIONS } from '../../../../js/constants';

import { FormRowLV } from '../../../../components/form/row/FormRowLV';

import { useModelPlacementNames } from './useModelPlacementNames';
import { AnalysisFields } from './analysis-fields';
import { KpiFields } from './kpi-fields';
import { InterceptField } from './intercept-field';
import { DataIntervalField } from './data-interval-field';
import { SubmitContinue } from './submit-continue';

import { styles } from './create-analysis-form.styles';
import {useKpiDropdownOptions} from "../../../../hooks/useKpiDropdownOptions";

export const CreateAnalysisForm = ({
    stepForward=()=>{},
    stepBack=()=>{},
    analysisStartType,
    runId,
    adImpactId,
    disabled, 
    runParameters={},
    analysisValues,
    setAnalysisValues=()=>{},
    setHideConfigStep=()=>{}
}) => {
    const {campaignId} = useCampaignManagement();
    const {mcaRuns=[]} = useMcaRuns();
    const {adImpactRuns=[]} = useAdImpactRuns();

    const {
        adImpactMediaTypeOptions=[],
        minDate,
        maxDate,
        timeFrames={}
    } = runParameters;

    //analysis options
    const mcaRunOptions = mcaRuns.map(run => ({
        ...run,
        label: run.name
    }));
    const finalMcaRunOptions = mcaRunOptions.filter(run => run.runStatus === 'FINAL');

    // Default values for rerun, view-configs and previous entries
    const [selectedAnalysisType, setSelectedAnalysisType] = useState('Primary MMM');
    const [defaultValues, setDefaultValues] = React.useState({});
    const [selectedRun, setSelectedRun] = useState();
    const {kpiOptions} = useKpiDropdownOptions({
        runId: selectedRun?.id || runId,
        campaignId,
        analysisType: selectedAnalysisType
    });

    const {placementNames={}, independentVariables=[], loading} = useModelPlacementNames(selectedRun?.id);
    const mcaNames = mcaRuns.map(run => (run.name));
    const adImpactNames = adImpactRuns.map(run => (run.name));

    useDeepCompareEffect(() => {
        const values = getDefaultValues(
            analysisStartType, runId, adImpactId, analysisValues, 
            timeFrames.mediaVehicle, minDate, maxDate, mcaRunOptions, 
            finalMcaRunOptions, adImpactRuns, adImpactMediaTypeOptions
        )
        setDefaultValues(values);
    }, [analysisStartType, runId, adImpactId, analysisValues, runParameters, 
        mcaRunOptions, finalMcaRunOptions, adImpactRuns]);

    const {
        runName, 
        startDate,
        endDate,
        timeScale,
        kpiType, 
        includeIntercept,
        analysisType,
        primaryAnalysis,
        kpi,
        kpiVariable,
        mediaType,
        nestedModelType
    } = defaultValues;

    const { methods } = useR1Form();
    useDeepCompareEffect(() => { 
        handleSelectedAnalysisType(analysisType);
        setSelectedRun(primaryAnalysis);
    }, [defaultValues]);

    //Run names updated besed on selected radio button
    let runNames;
    if (selectedAnalysisType === 'AdImpact MMM') {
        runNames = adImpactNames;
    } else {
        runNames = mcaNames;
    }

    const isDateIntervalError = timeFrames.mediaVehicle !== timeFrames.kpi;

    // Submit for validation
    const handleSubmit = (values={}) => {
        const formValues = formatPayload(values, selectedAnalysisType, campaignId, runId, placementNames);
        setAnalysisValues(formValues);
        stepForward();
    }

    // Step backward without validation
    // const handleStepBack = (values={}) => {
    //     setAnalysisValues(values);
    //     stepBack();
    // }

    const handleSelectedAnalysisType = (value = 'Primary MMM') => {
        methods.reset(defaultValues);
        //hide config step for AdImpact
        setHideConfigStep(value === 'AdImpact MMM');
        setSelectedAnalysisType(value);
    }

    const radioButtonSx = {
        height: '0px', 
        marginLeft: '19px', 
        paddingTop: '0px'
    };

    const analysisTypeProps = {
        name: 'analysisType',
        row: true,
        defaultValue: selectedAnalysisType,
        onChange: handleSelectedAnalysisType,
        customStyles: {
            'customWrapper': {
                'marginTop': '-10px',
                'marginLeft': '0', 
                'width': 'calc(33.33vw - 98px)',
                'height': '58px',
                '&&#radio_2': {'marginRight': '0'}
            },
            'customLabel': {
                'marginTop': '-8px'
            }
        },
        radioButtons: [{
            value: 'Primary MMM',
            sx: radioButtonSx,
            disabled
        },
        {
            value: 'Nested MMM',
            sx: radioButtonSx,
            disabled
        },
        {
            value: 'AdImpact MMM',
            sx: radioButtonSx,
            disabled
        }]
    };

    return (
        <Box sx={{ padding: '24px'}}>
            <R1Form
                methods={methods}
                onSubmit={handleSubmit} >
                <div className={styles.form}>
                    <R1RadioGroup {...analysisTypeProps} />
                    <AnalysisFields
                        disabled={ disabled }
                        selectedAnalysisType={ selectedAnalysisType }
                        finalMcaRunOptions={ finalMcaRunOptions }
                        mediaType={ mediaType }
                        nestedModelType={ nestedModelType }
                        adImpactMediaTypeOptions={ adImpactMediaTypeOptions }
                        selectedRun={ selectedRun }
                        setSelectedRun={ setSelectedRun } />
                    <R1TextBox
                        name='runName'
                        data-testid={"analysis-name-text-box"}
                        style={{'width': '100%'}}
                        disabled={ disabled }
                        label='Analysis Name'
                        aria-label='Analysis Name'
                        helperText='250 Character Max'
                        defaultValue={ runName }
                        rules={{
                            required: 'Analysis Name is required.',
                            maxLength: {
                                value: 250,
                                message: 'Cannot exceed 250 characters'
                            },
                            pattern: {
                                value: /^[0-9a-zA-Z _\-.]+$/,
                                message: 'Valid characters are numbers, letters, dot, dash, and underscore.'
                            },
                            validate: value => (disabled || (!!value && !runNames.includes(value))) || 
                                'Analysis name already exists. Please enter a unique analysis name.'
                        }} />
                    
                    <Typography className={styles.fieldTitle}>Date</Typography>
                    
                    <FormRowLV expand={ true }>
                        <R1DateRangePicker
                            className={styles.dateRangePicker}
                            data-testid={'analysis-date-picker'}
                            name='dateRange'
                            disabled={ disabled }
                            minDate={ minDate }
                            maxDate={ maxDate }
                            defaultStartDate={ startDate || minDate }
                            defaultEndDate={ endDate || maxDate }
                            required={ true } />
                    </FormRowLV>

                    <DataIntervalField 
                        disabled={ disabled } 
                        selectedAnalysisType= { selectedAnalysisType }
                        timeScale={ timeScale } 
                        isError= { isDateIntervalError } />
       
                    <Typography className={styles.fieldTitle}>Dependent Variable</Typography>
                    <KpiFields
                        disabled={ disabled }
                        selectedAnalysisType={ selectedAnalysisType }
                        kpiOptions={ kpiOptions }
                        independentVariables={ independentVariables }
                        kpi={ kpi }
                        kpiType={ kpiType } 
                        kpiVariable={ kpiVariable } />
                    
                    <FormRowLV className={styles.formRow}>
                        <InterceptField 
                            disabled={ disabled }
                            selectedAnalysisType={ selectedAnalysisType }
                            includeIntercept={ includeIntercept } />
                    </FormRowLV>
                    
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-end',
                            gap: '24px',
                            marginBottom: '24px'
                        }}>
                        {/* <R1Button color='inherit' size='large' onClick={handleStepBack}>Back</R1Button> */}
                        <SubmitContinue
                            analysisType={ selectedAnalysisType }
                            loading={ loading }
                            readOnly={ disabled }
                            disabled={ isDateIntervalError } />
                    </Box>
                </div>
            </R1Form>
        </Box>
    );
    
}

/* Function to return default values values for rerun, view-configs and previous entries */
const getDefaultValues = (analysisStartType, runId, adImpactId, analysisValues, timeScale, minDate, maxDate, 
                            mcaRunOptions, finalMcaRunOptions, adImpactRuns, adImpactMediaTypeOptions) => {
    let defaultValues;
    if (analysisValues && mcaRunOptions.length) {
        const primaryAnalysis =  mcaRunOptions.find(run => run.id === analysisValues.parentId);
        defaultValues = _.cloneDeep(analysisValues);
        defaultValues.startDate = moment(analysisValues.startDate).utc().format('MM-DD-YYYY');
        defaultValues.endDate = moment(analysisValues.endDate).utc().format('MM-DD-YYYY');
        defaultValues.runName = analysisValues.runName || analysisValues.wavecastName;
        defaultValues.primaryAnalysis =  primaryAnalysis;
        defaultValues.kpi = analysisValues.analysisType === 'Primary MMM' ? {label: variableNameToRaw(analysisValues.kpi), value: analysisValues.kpi} :
            {label: `${variableNameToRaw(analysisValues.kpi)}_contributions`, value: analysisValues.kpi}
        defaultValues.nestedModelType = NESTED_MODEL_TYPE_OPTIONS.find(type => type.value === analysisValues.nestedModelType);
        defaultValues.kpiVariable = {label: variableNameToRaw(analysisValues.kpiVariable), value: analysisValues.kpiVariable};
        defaultValues.mediaType = adImpactMediaTypeOptions.find(type => type.value === analysisValues.mediaType);
    } else {
        if (runId && mcaRunOptions.length) {
            const mcaRun = mcaRunOptions.find(run => run.id === runId);
            const primaryAnalysis = finalMcaRunOptions.find(run => run.id === mcaRun.parentId) || runId;
            const analysisType = mcaRun.parentId && 'Nested MMM' || 'Primary MMM';
            defaultValues = {
                analysisType,
                primaryAnalysis,
                runName: mcaRun.name,
                startDate: moment(mcaRun.startDate).utc().format('MM-DD-YYYY'),
                endDate: moment(mcaRun.endDate).utc().format('MM-DD-YYYY'), 
                timeScale: mcaRun.timeScale,
                kpi: analysisType === 'Primary MMM' ? {label: variableNameToRaw(mcaRun.kpi), value: mcaRun.kpi} :
                    {label: `${variableNameToRaw(mcaRun.kpi)}_contributions`, value: `${variableNameToRaw(mcaRun.kpi)}_contributions`},
                kpiType: mcaRun.kpiType, 
                includeIntercept: mcaRun.includeIntercept,
                nestedModelType: NESTED_MODEL_TYPE_OPTIONS.find(type => type.value === mcaRun.nestedModelType) || NESTED_MODEL_TYPE_OPTIONS[0],
            };
        } else if (adImpactId && adImpactRuns.length) {
            const adImpactRun = adImpactRuns.find(run => run.wavecast_id === adImpactId);
            const primaryAnalysis = finalMcaRunOptions.find(run => run.id === adImpactRun.mca_run_id);
            defaultValues = {
                analysisType: 'AdImpact MMM',
                primaryAnalysis,
                runName: adImpactRun.wavecast_name,
                startDate: moment(adImpactRun.start_date).utc().format('MM-DD-YYYY'),
                endDate: moment(adImpactRun.end_date).utc().format('MM-DD-YYYY'),
                timeScale: adImpactRun.time_scale,
                kpiVariable: {label: variableNameToRaw(adImpactRun.analysis_variable), value: adImpactRun.analysis_variable},
                mediaType: adImpactMediaTypeOptions.find(type => type.value === adImpactRun.analysis_type)
            };
        } else {
            defaultValues = {
                analysisType: analysisStartType || 'Primary MMM',
                startDate: minDate,
                endDate: maxDate,
                timeScale,
                includeIntercept: true,
                primaryAnalysis: [],
                mediaType: [],
                nestedModelType: [],
                kpiVariable: [],
                kpi: []
            }
        }
    }
    return defaultValues;
}

/* Function to format received values from form for request payload */
const formatPayload = (values={}, selectedAnalysisType, campaignId, runId, placementNames={}) => {
    let allValues = _.cloneDeep(values);
    //add campaign ID and reformat dates to YYYY-MM-DD
    allValues.campaignId = campaignId;
    allValues.startDate = allValues.dateRange?.length ? moment(allValues.dateRange[0]).format('YYYY-MM-DD').toString() : null;
    allValues.endDate = allValues.dateRange?.length ? moment(allValues.dateRange[1]).format('YYYY-MM-DD').toString() : null;
    delete allValues.dateRange;
    allValues.kpi = allValues.kpi?.value || allValues.kpi;
    allValues.analysisType = selectedAnalysisType;
    //if primary analysis is filled
    if (allValues.primaryAnalysis) {
        allValues.parentId = allValues.primaryAnalysis.id; //mcaRun nested
        allValues.baseRunId = runId; //mcaRun rerun
        allValues.nestedModelType = allValues.nestedModelType?.value,
        allValues.mcaRunId = allValues.primaryAnalysis.id; //AdImpact
        allValues.mcaRunName = allValues.primaryAnalysis.name; //AdImpact
        delete allValues.primaryAnalysis;
    }

    if (selectedAnalysisType !== 'Nested MMM') {
        delete allValues.nestedModelType;
        delete allValues.parentId;
    }

    if (selectedAnalysisType === 'AdImpact MMM') {
        const kpiVariable = allValues.kpiVariable?.value;
        allValues = {
            ...allValues,
            kpiVariable,
            wavecastName: allValues.runName,
            mediaType: allValues.mediaType?.value,
            measure: placementNames[kpiVariable]?.measure,
            decayRate: '5'
        };
        delete allValues.baseRunId;
        delete allValues.runName;
        delete allValues.kpi;
        delete allValues.kpiType;
        delete allValues.includeIntercept;
        delete allValues.expectedCount
    } else {
        delete allValues.kpiVariable;
        delete allValues.wavecastName;
        delete allValues.mediaType;
        delete allValues.measure;
        delete allValues.decayRate;
    }
    return allValues;
}