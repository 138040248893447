import React from 'react';
import { useGet, usePost } from './useRest';
import { useGetPolling } from './usePolling';
import { downloadFromURL } from '../js/download';
import _ from 'lodash';

/**
 * Triggering a url download can require 2 calls
 *   1: Request that returns url link to download
 *   2: Window.open() the url to trigger download
 *
 * @param restHook hook to use from useAxios
 * @param requestUrl URL to make initial request on
 * @param requestData Additianal data to send on POST
 *                    EXAMPLE: { fileName: 'expected-filename.csv' }
 * @param urlObjectPath Path within response object url is expected
 *                      EXAMPLE: 'data.url'
 * @param onFinal Function to run after download
 */
export const useTriggerDownloadUrl = ({
  restHook,
  requestUrl,
  requestData = {},
  urlObjectPath,
  onFinal = () => {}
}) => {

  const [{ data, loading, error }, fetch] = restHook({
    url: requestUrl,
    data: requestData,
    manual: true
  });

  // On get response
  React.useEffect(() => {
    const url = typeof data === 'string' ? data : _.get(data, urlObjectPath);

    if(!url) {
      return;
    }

    downloadFromURL(url);
    onFinal();

  }, [data]);

  return {
    triggerDownload: fetch,
    loading,
    error
  };

};

/**
 * Trigger a download from the result of a GET url
 *   1: Request that returns url link to download
 *   2: Window.open() the url to trigger download
 *
 * @param requestUrl URL to make initial request on
 * @param requestData Additianal data to send on POST
 *                    EXAMPLE: { fileName: 'expected-filename.csv' }
 * @param urlObjectPath Path within response object url is expected
 *                      EXAMPLE: 'data.url'
 * @param onFinal Function to run after download
 */
export const useGetTriggerDownloadUrl = ({
  ...props
}) => {
  return useTriggerDownloadUrl({
    restHook: useGet,
    ...props
  });
};

/**
 * Trigger a download from the result of a POST url
 *   1: Request that returns url link to download
 *   2: Window.open() the url to trigger download
 *
 * @param requestUrl URL to make initial request on
 * @param requestData Additianal data to send on POST
 *                    EXAMPLE: { fileName: 'expected-filename.csv' }
 * @param urlObjectPath Path within response object url is expected
 *                      EXAMPLE: 'data.url'
 * @param onFinal Function to run after download
 */
export const usePostTriggerDownloadUrl = ({
  ...props
}) => {
  return useTriggerDownloadUrl({
    restHook: usePost,
    ...props
  });
};

/**
 * Trigger a chain of REST requests that downloads a file
 * 1. Initial Rest endpoint
 * 2. Polling endpoint
 * 3. Url request endpoint
 * 4. Trigger download of returned url in browser
 * 
 * @param requestRestHook Instance of our useRest hook (EXAMPLE: usePost)
 * @param pollingRestHook Instance of our polling hook (EXAMPLE: useGetPolling)
 * @param downloadRestHook Instance of our triggerDownload hook (EXAMPLE: useGetTriggerDownloadUrl)
 * @param requestUrl Initial URL to hit
 * @param getPollingUrl Function to populate Polling URL after 1st endpoint result
 *                      EXAMPLE: (firstResponse) => { return `http://www.fake.com/${firstResponse.id}` }
 * @param pollingInterval Duration between polling requests
 * @param getDownloadUrl Function runs on each Poll response
 *                       return false to continue polling
 *                       return url to stop polling and trigger download
 *                       EXAMPLE: (firstResponse, pollingResponse) => {
 *                                 if(!pollingResponse.complete) {
 *                                     return false;
 *                                   }
 *                                   return `http://get-my-download-link.com/${firstResponse.id}`
 *                                 }
 * @param downloadUrlObjectPath Path within response object url is expected
 *                              EXAMPLE: 'data.url'
 * @param onFinal Function run on download complete
 */
export const usePollingTriggerDownloadUrl = ({
  requestRestHook,
  pollingRestHook,
  downloadRestHook,
  requestUrl,
  getPollingUrl = () => {},
  pollingInterval = 500,
  getDownloadUrl = () => {},
  downloadUrlObjectPath,
  onFinal = () => { }
}) => {

  const [pollingUrl, setPollingUrl] = React.useState();
  const [downloadUrl, setDownloadUrl] = React.useState();

  /**
   * Step 1: Initial request and start polling on response
   */
  const [{
    data: requestData,
    loading: requestLoading,
    error: requestError
  }, requestFetch] = requestRestHook({
    url: requestUrl,
    manual: true
  });

  // Trigger polling on requestData complete
  // Use requestData to get url for polling
  React.useEffect(() => {
    if(!requestData) {
      return;
    }

    setPollingUrl(getPollingUrl(requestData));
    
  }, [requestData]);


  /**
   * Step 2: Polling until Download URL is resolved
   */
  const {
    data: pollingData,
    loading: pollingLoading,
    error: pollingError,
    clearIntervalInstance,
    timeoutInstance
  } = pollingRestHook({
    url: pollingUrl,
    interval: pollingInterval
  });

  // Set download url from polling when polling is complete
  React.useEffect(() => {
    if(!pollingData) {
      return;
    }

    const downloadUrl = getDownloadUrl(requestData, pollingData);

    // Continue polling if downloadUrl has not returned
    if(!downloadUrl) {
      return;
    }

    clearIntervalInstance();
    setDownloadUrl(downloadUrl);
  }, [pollingData]);

  /**
   * Step 3: Trigger download from returned url
   */
  const {
    triggerDownload,
    loading: downloadLoading,
    error: downloadError
  } = downloadRestHook({
    requestUrl: downloadUrl,
    urlObjectPath: downloadUrlObjectPath,
    onFinal
  });

  React.useEffect(() => {
    if(!downloadUrl) {
      return;
    }

    triggerDownload();
  }, [downloadUrl]);

  const errors = [requestError, pollingError, downloadError];

  return {
    triggerDownload: requestFetch,
    loading: [downloadLoading, pollingLoading, requestLoading, timeoutInstance].some(s => s),
    errors,
    hasErrors: errors.some(s => s),
    clearIntervalInstance
  };
  
};

/**
 * Trigger a chain of REST requests that downloads a file
 * 1. Initial POST endpoint
 * 2. GET Polling endpoint
 * 3. GET Url request endpoint
 * 4. Trigger download of returned url in browser
 * 
 * @param requestUrl Initial URL to hit
 * @param getPollingUrl Function to populate Polling URL after 1st endpoint result
 *                      EXAMPLE: (firstResponse) => { return `http://www.fake.com/${firstResponse.id}` }
 * @param pollingInterval Duration between polling requests
 * @param getDownloadUrl Function runs on each Poll response
 *                       return false to continue polling
 *                       return url to stop polling and trigger download
 *                       EXAMPLE: (firstResponse, pollingResponse) => {
 *                                 if(!pollingResponse.complete) {
 *                                     return false;
 *                                   }
 *                                   return `http://get-my-download-link.com/${firstResponse.id}`
 *                                 }
 * @param downloadUrlObjectPath Path within response object url is expected
 *                              EXAMPLE: 'data.url'
 * @param onFinal Function run on download complete
 */
export const usePostGetPollingGetTriggerDownloadUrl = ({
  ...props
}) => {
  return usePollingTriggerDownloadUrl({
    requestRestHook: usePost,
    pollingRestHook: useGetPolling,
    downloadRestHook: useGetTriggerDownloadUrl,
    ...props
  });
};
