import React from 'react';
import {EditableCell} from './EditableCell';

/**
 * Add an editable numeric input cell that works with react-hook-form
 */
export const EditableNumericCell = ({
  cell,
  isCurrency=false,
  hidden,
  ...props
}) => {
  const classNames = ['numeric'];
  let min;
  let step;

  if(isCurrency) {
    classNames.push('currency');
    step='0.01';
  }

  if(props.disabled){
    classNames.push('disabled');
  }
  
  return (
    <EditableCell
      type={hidden ? 'hidden' : 'number'}
      className={classNames.join(' ')}
      cell={cell}
      min={min}
      step={step}
      {...props}
    />
  );
};
